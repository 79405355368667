import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

const SkopicProtected = (props) => {
  const signInStatus = Cookies.get('signInStatus');
  if (signInStatus) {
    return <Route {...props} />;
  }
  return <Redirect to="/" />;
};

export default SkopicProtected;
