import React, { useEffect } from "react";
import "./Profile.css";
import ProfileDetail from "./ProfileDetails/ProfileDetails";
import ProfileImage from "src/Assets/images/ProfileImage1.png";
import { useSelector, useDispatch } from "react-redux";
import { fetchloginUser } from "../../../store/actions/SignInActions/SignInAction";

function Profile() {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  let profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchloginUser());
    }, 1000);
  }, []);

  return (
    <>
      <div className="Profile">
        {userDetails && Object.keys(userDetails).length !== 0 ? (
          userDetails.userData &&
          Object.keys(userDetails.userData).length !== 0 ? (
            <img
              src={`${profileImagePath}${userDetails.userData.tenantLogo}`}
              alt="Community Image"
              className="Community-Image"
            />
          ) : null
        ) : null}
        <ProfileDetail />
      </div>
    </>
  );
}
export default Profile;
