import React, { useEffect, useState, useRef, useCallback } from "react";
import { Typography } from "@mui/material";
import Tag from "src/Assets/images/tag.svg";
import DownArrow from "src/Assets/images/UpButton.png";
import "./TagActtivity.css";
import ActivityPost from "../../ActivityPost";
import API from "../../../../network";
// import ConfirmationDialog from "src/Components/ConfirmationDialog";

function TagActtivity(props) {
  const [isListOpen, setisListOpen] = useState(false);
  const [listData, setListData] = useState([]);
  const [breadCountLength, setBreadCountLength] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [completeTagsListData, setCompleteTagsListData] = useState({});
  const [confModalVisible, setConfModalVisible] = useState(null);

  const listRef = useRef(null);

  const toggleList = () => {
    setisListOpen(true);
  };

  const handleScroll = useCallback(() => {
    const { scrollTop, clientHeight, scrollHeight } = listRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore) {
      setPageNumber((prevPage) => prevPage + 1);
    }
  }, [hasMore]);

  const getData = async (page) => {
    try {
      const newPage = (page - 1) * 10;
      const res = await API.getUserTags(newPage);

      const hashList = res?.data?.hashList || [];

      if (hashList.length > 0) {
        setListData((prevList) => [...prevList, ...hashList]);
        setBreadCountLength(
          (prevBreadLength) => prevBreadLength + hashList.length
        );
        setHasMore(true);
      } else {
        setHasMore(false);
      }

      setCompleteTagsListData(res.data || {});
    } catch (error) {
      console.error("Error fetching user tags:", error);
      alert("Failed to load data. Please try again later.");
      setHasMore(false);
    }
  };

  useEffect(() => getData(pageNumber), [pageNumber]);

  const updateList = (id, followStatus) => {
    const newData = [...listData];
    const modifiedData = newData.find((x) => x.id === id);
    modifiedData.followStatus = followStatus.includes("0") ? "N" : "Y";
    modifiedData.followCount = followStatus.includes("0")
      ? modifiedData.followCount - 1
      : modifiedData.followCount - 1 + 2;
    setListData(newData);
  };

  const deleteActivityTagPost = (id) => {
    const newData = [...listData];
    const modifiedData = newData.filter((x) => x.id !== id);
    setListData(modifiedData);
  };

  const onClose = () => {
    setConfModalVisible(null);
  };

  const confirmationForDelete = (id) => {
    setConfModalVisible({
      open: true,
      type: "warning",
      title: "Are you sure you want to delete this TAG?",
      rejectLabel: "Cancel",
      acceptLabel: "Yes",
      onReject: onClose,
      onAccept: () => deleteTag(id),
    });
  };

  const deleteTag = (id) => {
    let formdata = new FormData();
    formdata.append("messageID", id);
    API.deleteProfilePost(formdata)
      .then((res) => {
        const newList = [...listData];
        const index = newList.findIndex((x) => x.id === id);
        if (index > -1) {
          newList.splice(index, 1);
          setListData(newList);
          setCompleteTagsListData((prevState) => ({
            ...prevState,
            hashListSize: prevState?.hashListSize - 1,
          }));
        }
        setConfModalVisible(null);
      })
      .catch((err) => { });
  };

  const updateMessage = (id, message) => {
    const newData = [...listData].map((eachItem) => {
      if (eachItem?.id === id) {
        return {
          ...eachItem,
          Message: message,
        };
      }
      return { ...eachItem };
    });
    setListData(newData);
    // const newData = [...listData];
    // const modifiedData = newData.find((x) => x.id === id);
    // let formdata = new FormData();
    // formdata.append("messageID", id);
    // formdata.append("message", message);
    // API.updateProfilePost(modifiedData.locName, formdata)
    //   .then((res) => {
    //     modifiedData.Message = message;
    //     setListData(newData);
    //   })
    //   .catch((err) => {});
  };

  return (
    <>
      {!isListOpen && (
        <div className="TagActivity" onClick={toggleList}>
          <div className="usable-container">
            <img src={Tag} alt="Ask-icon" className="askicon" />
            <Typography className="ask">TAG</Typography>
          </div>
          <div className="usable-container">
            <span className="number">
              {props.activityCountRes?.hashListSize}
            </span>
            <img src={DownArrow} alt="DownArrow" className="DownArrow" />
          </div>
        </div>
      )}

      {isListOpen && (
        <div className="AskActivity_active">
          <div
            className="color-combination"
            onClick={() => setisListOpen(false)}
          >
            <div className="usable-container">
              <img src={Tag} alt="Ask-icon" className="askicon" />
              <Typography className="ask">TAG</Typography>
            </div>
            <div className="usable-container">
              <span className="number">
                {props.activityCountRes?.hashListSize}
              </span>
              <img src={DownArrow} alt="DownArrow" className="DownArrow" />
            </div>
          </div>
          <div
            ref={listRef}
            onScroll={handleScroll}
            role="list"
            className="posts"
          >
            {listData && listData.length !== 0 ? (
              <ActivityPost
                listData={listData}
                updateMessage={updateMessage}
                delete={confirmationForDelete}
                updateList={updateList}
                filterType="activityTag"
                decreaseHashCount={props.decreaseHashCount}
                deleteActivityTagPost={deleteActivityTagPost}
                onHashTagClick={props.onHashTagClick}
                onDelete={deleteTag}
                postType={"TAG"}
              />
            ) : (
              <div className="no-data-container">
                <p>No Activity Hash Follows found</p>
              </div>
            )}
          </div>
        </div>
      )}
      {/* {confModalVisible?.open && <ConfirmationDialog {...confModalVisible} />} */}
    </>
  );
}

export default TagActtivity;
