import api_sheet from "./api_sheet";
import * as CryptoJS from "crypto-js";
import { calcMD5 } from "../md5Forgot";
import * as Cookies from "js-cookie";


class SignUpRepo {

    static requestSignUp = async (mail, pass, displayName, tenantId, tenantName) => {


        const eMail = this.encMail(mail);
        console.log("tId: " + tenantId)
        console.log("tName: " + tenantName)
        console.log(eMail)


        return await fetch(api_sheet.SendVerificationCode_URL + "?redirectTenantId=" + tenantId, {
            method: 'POST',
            body:
                "email=" + eMail +
                "&displayName=" + displayName +
                "&password=" + calcMD5(pass) +
                "&password_c=" + calcMD5(pass) +
                "&userTenant=" + tenantName,
            headers: {
                "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            }
        }).then(res => res.json()).then(data => {

            console.log("SignUpRepo>requestSignUp> res: " + data.status)
            switch (data.status) {
                case "success":
                    return { "isSuccess": true, "message": "OTP sent Successfully", "reSend": false };
                case "SkopicUser":
                    return { "isSuccess": false, "message": "Already have an account", "reSend": false };
                case "TempUser":
                    return { "isSuccess": true, "message": "OTP sent Successfully", "reSend": true };
                default:
                    return { "isSuccess": false, "message": "Failed, Retry after sometime", "reSend": false };
            }
        }).catch(error => {
            console.log("SignUpRepo>requestSignUp> Error: " + error)
            return { "isSuccess": false, "message": "Server Error" }
        })

    };

    static encMail(mail) {
        let encryptMail = CryptoJS.AES.encrypt(mail, "skopic").toString();
        if (encryptMail.includes("+")) {
            console.log("+")
            return this.encMail(mail)
        } else {
            return encryptMail;
        }

    }




}

export default SignUpRepo;