import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
// import styles from 'src/Assets/css/home/post.module.css'
import Avatar from "@material-ui/core/Avatar";
// import saysoutlineimage from 'src/Assets/images/saysoutline.png'
// import follow from 'src/Assets/images/followicon.png'
// import dropdownarrow from "src/Assets/images/dropdownarrow.png";
import location from "src/Assets/images/userlocationtag.png";
import dot from "src/Assets/images/dot.png";
import smallike from "src/Assets/images/smallike.png";
// import like from 'src/Assets/images/like.png'
import feedstyles from "src/Assets/css/home/feedpost.module.css";
// import deleteicon from "src/Assets/images/deleteicon.png";
import edit from "src/Assets/images/edit.png";
import unlike from "src/Assets/images/unlike.png";
import sendanemail from "src/Assets/images/sendanemail.png";
import ReusuableDeleteDialogmodal from "../../../Modules/Reusuablecomponents/ReusuableDeleteModal";
import styles from "src/Assets/css/home/post.module.css";
// import PostMessageValidation from "../../../Modules/Reusuablecomponents/PostMessageValidation";
import SimpleDialogDemo from "../../../Modules/Reusuablecomponents/CustomizedDialogs";
import { CircularProgress } from "@material-ui/core";
import API from "../../../network";
import ReusuableReportDialogmodal from "src/Modules/Reusuablecomponents/ReusuableReportDialogmodal";
import ReusuableSendaMailModal from "src/Modules/Reusuablecomponents/ReusuableSendaMailModal";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { MdKeyboardArrowDown } from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import AvatarBadge from "src/Components/AvatarBadge";
import ReportModal from "../../../Modules/Reusuablecomponents/reportModal";
import { ignoreSpacesAndUrlsFromMsg } from "src/Utils/commonUtils";

function ChildSay(props) {
  const {
    fetchVoteUpStatus,
    loading,
    fetchFeedData,
    deleteMessage,
    refreshFeedData,
    deleteSayCount,
    isActionDisabled,
    flow,
    sayOrAskPost,
    fetchContributors
  } = props;

  // const [isliked, setLiked] = React.useState(false)
  // var profileimagelocalPath = "http://localhost:8080/skopicimage";
  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;
  const userDetails = useSelector(
    (state) => state.SignInReducer?.userDetails || {}
  );
  const [childSaysData, setChildSaysData] = useState(null);
  const [editPostId, setEditPostId] = useState(null);
  const [updatedMsg, setUpdatedMsg] = useState("");
  const [updatedMsgCount, setUpdatedMsgCount] = useState(0);
  const [emailPopupOpen, setEmailPopupOpen] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [textCount, setTextCount] = useState(280);
  const [selectedPost, setSelectedPost] = useState({});
  const [validationErrMsg, setValidationErrMsg] = useState("");

  useEffect(() => {
    let msg = textValue || "";
    // msg = msg.replace(/\n/g, "");
    // msg = msg.replace(/\s/g, "");
    const count = msg.length;
    setTextCount(count);
  }, [textValue]);

  useEffect(() => {
    let msg = updatedMsg || "";
    // msg = msg.replace(/\n/g, "");
    // msg = msg.replace(/\s/g, "");
    const { msgLength } = ignoreSpacesAndUrlsFromMsg(msg);
    setUpdatedMsgCount(msgLength);
  }, [updatedMsg]);

  useEffect(() => {
    setChildSaysData(props.childSaysData);
  }, [props.childSaysData]);

  const likeUnlike = (id) => {
    const newUpdatedList = childSaysData.childSAYsMessageList.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          UserLikeStatus: "InActive",
          voteUp: item.voteUp - 1 + 2,
        }; // Update the message
      }
      return item; // Return unchanged items
    });
    setChildSaysData({
      ...childSaysData,
      childSAYsMessageList: newUpdatedList,
    });
  };

  const onChildLikeUnlikeSelection = (id, cache) => {
    if (isActionDisabled) return;
    likeUnlike(id);
    fetchVoteUpStatus(id, cache);
  };

  const deletePost = (id) => {
    const newUpdatedList = childSaysData.childSAYsMessageList.filter(
      (item) => item.id !== id
    );
    setChildSaysData({
      ...childSaysData,
      childSAYsMessageList: newUpdatedList,
    });
  };

  const onEditSelection = (id, msg) => {
    setEditPostId(id);
    setUpdatedMsg(msg);
  };

  const saveMessage = async (id, defaultMsg) => {
    if (defaultMsg === updatedMsg) {
      setValidationErrMsg("At least one modification required");
      return;
    }

    try {
      const newUpdatedMsg = updatedMsg.length > 0 ? updatedMsg : defaultMsg;
      const updatedMessageList = childSaysData.childSAYsMessageList.map(
        (item) => {
          if (item.id === id) {
            return { ...item, Message: newUpdatedMsg }; // Update the message
          }
          return item; // Return unchanged items
        }
      );
      setChildSaysData({
        ...childSaysData,
        childSAYsMessageList: updatedMessageList,
      });
      await API.editPost(id, newUpdatedMsg);
      setEditPostId(null);
    } catch (error) {
      console.log(error);
    }
  };

  const updateMessage = (e) => {
    setUpdatedMsg(e.target.value);
  };

  const cancelUpdateMsg = () => {
    setEditPostId(null);
    setValidationErrMsg("");
  };

  const setUpdatedText = (e) => {
    const newValue = e.target.value;
    setTextValue(newValue);
  };

  const onSendMail = async (id) => {
    if (textValue?.trim()) {
      await API.postMail(id, textValue);
      setEmailPopupOpen(false);
      setTextValue("");
      setTextCount(0);
    }
  };

  const onClickSendEmailOption = (selectedPost) => {
    setEmailPopupOpen(true);
    setSelectedPost(selectedPost);
    setTextValue("");
    setTextCount(0);
  };

  const isSendBtnEnabled = textValue && textCount > 0 && textCount <= 280;
  const isSaveBtnEnabled =
    updatedMsg && updatedMsgCount > 0 && updatedMsgCount <= 280;

  return (
    <React.Fragment>
      {!loading ? (
        childSaysData && Object.keys(childSaysData).length !== 0 ? (
          childSaysData.childSAYsMessageList ? (
            childSaysData.childSAYsMessageList.map((childresult) => (
              <div key={`childSayKey${childresult.id}`} id={childresult.id}>
                <div className={styles.postedcomment}>
                  <div className={feedstyles.avatarDetailsContainer}>
                    <AvatarBadge
                      src={`${profileImagePath}${childresult.uimage}`}
                      variant="square"
                      className={styles.childsayprofileimage}
                      isChild={true}
                      isPrimaryModerator={
                        flow === "beforeSignIn"
                          ? childresult?.isPM
                          : childresult?.mainuserdata?.isPM == 1
                      }
                      isModerator={
                        flow === "beforeSignIn"
                          ? childresult?.isModerator == 1 &&
                          childresult?.isPM == 0
                          : childresult?.mainuserdata?.isModerator == 1 &&
                          childresult?.mainuserdata?.isPM == 0
                      }
                    />
                    {
                      <div
                        style={{ top: 40 }}
                        className={feedstyles.profilehover}
                      >
                        <div className={feedstyles.profilehoverimageandcount}>
                          <AvatarBadge
                            src={`${profileImagePath}${childresult.mainuserdata.uimage}`}
                            className={styles.avatar}
                            isChild={true}
                            isPrimaryModerator={
                              flow === "beforeSignIn"
                                ? childresult?.isPM
                                : childresult?.mainuserdata?.isPM == 1
                            }
                            isModerator={
                              flow === "beforeSignIn"
                                ? childresult?.isModerator == 1 &&
                                childresult?.isPM == 0
                                : childresult?.mainuserdata?.isModerator == 1 &&
                                childresult?.mainuserdata?.isPM == 0
                            }
                          />
                          <div style={{ marginLeft: "10px" }}>
                            <p className={feedstyles.profilehovername}>
                              {childresult.mainuserdata.displayName}
                            </p>
                            <p className={feedstyles.profilecount}>
                              ASK {childresult.mainuserdata.askcount} | SAY{" "}
                              {childresult.mainuserdata.saycount} | UPDATE{" "}
                              {childresult.mainuserdata.updatecount} | TAG{" "}
                              {childresult.mainuserdata.hashtagcount}
                            </p>
                          </div>
                        </div>
                        <p className={feedstyles.profilehovername}>
                          {childresult.mainuserdata.shortBio}
                        </p>
                      </div>
                    }
                  </div>

                  <div className={styles.profileimagetext}>
                    <div className={styles.childsaysprofileandfdropdown}>
                      <span className={feedstyles.profilename}>
                        {childresult.mainuserdata.displayName}
                      </span>
                      {!isActionDisabled && (
                        <div className={feedstyles.feeddropdown}>
                          <Menu
                            menuButton={
                              <IconButton
                                style={{
                                  padding: 0,
                                  width: 30,
                                  height: 30,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <MdKeyboardArrowDown fontSize={25} />
                              </IconButton>
                            }
                            transition
                            direction="bottom"
                            arrow
                            menuStyle={{
                              backgroundColor: "white",
                              borderRadius: 20,
                            }}
                          >
                            {childresult.User_ID !==
                              userDetails?.userData?.User_ID ? (
                              <>
                                {childresult.moderatorStatus === "1" ? (
                                  <>
                                    <MenuItem>
                                      <ReusuableDeleteDialogmodal
                                        type={"SAY"}
                                        id={childresult.id}
                                        Parent_ID={childresult.Parent_ID}
                                        deleteMessage={deleteMessage}
                                        deletePost={deletePost}
                                        deleteSayCount={deleteSayCount && deleteSayCount}
                                        fetchContributors={fetchContributors}
                                      />
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        onClickSendEmailOption(childresult)
                                      }
                                    >
                                      <img
                                        src={sendanemail}
                                        style={{ marginRight: 8 }}
                                      />
                                      <span style={{ fontSize: 14 }}>
                                        {" "}
                                        Send email to {childresult.displayName}
                                      </span>
                                    </MenuItem>
                                  </>
                                ) : (
                                  <>
                                    {sayOrAskPost &&
                                      sayOrAskPost === "sayOrAskPost" ? (
                                      <MenuItem>
                                        <ReportModal
                                          id={childresult.id}
                                          param={"AskorSay"}
                                        />
                                      </MenuItem>
                                    ) : (
                                      <MenuItem>
                                        <ReusuableReportDialogmodal
                                          id={childresult.id}
                                          param={"AskorSay"}
                                        />
                                      </MenuItem>
                                    )}
                                    <MenuItem>
                                      <ReusuableSendaMailModal
                                        displayName={childresult.displayName}
                                        id={childresult.id}
                                      />
                                    </MenuItem>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <MenuItem
                                  onClick={() =>
                                    onEditSelection(
                                      childresult.id,
                                      childresult?.Message
                                    )
                                  }
                                >
                                  <img src={edit} style={{ marginRight: 8 }} />
                                  <span style={{ fontSize: 14 }}>Edit</span>
                                </MenuItem>
                                <MenuItem>
                                  <ReusuableDeleteDialogmodal
                                    type={"SAY"}
                                    id={childresult.id}
                                    Parent_ID={childresult.Parent_ID}
                                    deleteMessage={deleteMessage}
                                    deletePost={deletePost}
                                    deleteSayCount={deleteSayCount && deleteSayCount}
                                    fetchContributors={fetchContributors}
                                  />
                                </MenuItem>
                              </>
                            )}
                          </Menu>
                        </div>
                      )}
                    </div>

                    <div className={styles.childsaytextandcolorlabel}>
                      {editPostId === childresult.id ? (
                        <div>
                          <textarea
                            rows="2"
                            cols="50"
                            value={updatedMsg}
                            // defaultValue={childresult.Message}
                            onChange={(e) => updateMessage(e)}
                            onFocus={() => setValidationErrMsg("")}
                            className={styles.EditPostMessage}
                          />
                          {validationErrMsg && (
                            <div style={{ fontSize: 11, color: "red" }}>
                              {validationErrMsg}
                            </div>
                          )}
                          <div className={styles.saveCancelButtonContainer}>
                            <button
                              onClick={() =>
                                saveMessage(childresult.id, childresult.Message)
                              }
                              className={styles.editPost}
                              disabled={!isSaveBtnEnabled}
                              style={{
                                backgroundColor: !isSaveBtnEnabled
                                  ? "#60A2E0"
                                  : "#127ADD",
                              }}
                            >
                              SAVE
                            </button>
                            <button
                              onClick={cancelUpdateMsg}
                              className={styles.cancelPost}
                            >
                              Cancel
                            </button>
                            <span
                              style={{
                                color: updatedMsgCount > 280 ? "red" : "black",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {updatedMsgCount}
                              <span style={{ color: "black" }}>/280</span>
                            </span>
                          </div>
                        </div>
                      ) : (
                        <p id={`hide${childresult.id}`}>
                          {childresult.Message}
                        </p>
                      )}
                      {/* <div>
                        <p id={`hide${childresult.id}`}>
                          {childresult.Message}
                        </p>
                        <span
                          className={feedstyles.edittextarea}
                          id={`edittext${childresult.id}`}
                        >
                          <PostMessageValidation
                            id={childresult.id}
                            parentId={props.id}
                            Message={childresult.Message}
                            messageType={"feedTextArea"}
                            fetchFeedData={fetchFeedData}
                            refreshFeedData={refreshFeedData}
                          />
                        </span>
                      </div> */}
                      <div className={styles.childsaycount}>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.likeandlikecount}>
                  <span className={styles.spandisplay}>
                    <div className={styles.likeunlike}>
                      {childresult.UserLikeStatus === "Active" ? (
                        <>
                          <a
                            onClick={() =>
                              onChildLikeUnlikeSelection(
                                childresult.id,
                                "cacheUpdate"
                              )
                            }
                            id={`00${childresult.id}`}
                          >
                            <img src={smallike} alt="smalllike" />
                            Like
                          </a>
                        </>
                      ) : (
                        <a className={feedstyles.childUnlike}>
                          <img src={unlike} alt="unlike" />
                          Like
                        </a>
                      )}
                    </div>
                    <img src={dot} alt="dot" className={styles.dot} />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {childresult.voteUp !== 0 ? (
                        <SimpleDialogDemo
                          voteUp={childresult.voteUp}
                          id={childresult.id}
                          isVoteup={"true"}
                        />
                      ) : (
                        <span className={styles.followcount}>
                          {childresult.voteUp === 1
                            ? `${childresult.voteUp} like`
                            : `${childresult.voteUp} likes`}
                        </span>
                      )}
                    </div>
                    {/* <a className={styles.nooflikes}>{childresult.voteUp} like</a> */}
                  </span>

                  <span
                    className={styles.locationTimeSpan}
                    style={{
                      display: "flex",
                      paddingRight: 5,
                      justifyContent: childresult.locName
                        ? "flex-start"
                        : "flex-end",
                    }}
                  >
                    <p className={styles.childsaytime}>
                      {childresult.Message_Time}
                    </p>
                    <span className={styles.locationandtime}>
                      {childresult.locName && (
                        <>
                          <img src={dot} alt="dot" />
                          <img src={location} alt="location" />
                        </>
                      )}
                      <div className={styles.childSayLocTxt}>
                        {childresult.locName}
                      </div>
                    </span>
                  </span>
                </div>
              </div>
            ))
          ) : (
            <p>No Say Data</p>
          )
        ) : (
          <p>No Say Data</p>
        )
      ) : (
        <CircularProgress className={feedstyles.Loader} />
      )}
      <Modal
        show={emailPopupOpen}
        style={{ backgroundColor: "#00000060" }}
        contentClassName={styles.contentClass}
      >
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#fff",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomWidth: 0,
          }}
          onClick={() => setEmailPopupOpen(false)}
        >
          <Modal.Title>Send emails</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            padding: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <textarea
              rows="6"
              cols="44"
              className={styles.sendamailtextarea}
              id={`textInputMail${selectedPost?.id}`}
              // onInput={() => myFunction(props.id)}
              value={textValue}
              onChange={(e) => setUpdatedText(e)}
              placeholder={`What would you like to say to ${selectedPost?.displayName}`}
            />
            <span
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                color: textCount > 280 ? "red" : "black",
              }}
            >
              {textCount}
              <span style={{ color: "black" }}>/280</span>
            </span>
            <button
              type="button"
              id={`buttonforEdit${selectedPost?.id}`}
              className={styles.sendaMail}
              style={{
                top: 0,
                left: 0,
                backgroundColor: !isSendBtnEnabled ? "#60A2E0" : "#127ADD",
              }}
              onClick={() => onSendMail(selectedPost?.id)}
              disabled={!isSendBtnEnabled}
            >
              Send
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default ChildSay;
