import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import settingsStyles from "src/Assets/css/settings/settings.module.css";
import Sidebaroptions from "src/Components/SidebarOption";
import Notifications from "src/pages/Notifications";
import HeaderAfterSignIn from "../../Components/Header/header";
import logo from "src/Assets/images/LandingPageImages/skopic-image.png";
import Cookies from "js-cookie";
import "./index.css";

const defaultState = {
  google: false,
  safari: false,
  mozilla: false,
  opera: false,
  internetExplorer: false,
};

function CookiesComponent() {
  const googleRef = useRef(null);
  const safariRef = useRef(null);
  const mozillaRef = useRef(null);
  const operaRef = useRef(null);
  const explorerRef = useRef(null);
  const [signInStatus, setSignInStatus] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const status = Cookies.get("signInStatus");
    setSignInStatus(status);
  }, []);

  const [isActive, setIsActive] = useState({
    ...defaultState,
    google: true,
  });

  const clickHandler = (key) => {
    setIsActive({
      ...defaultState,
      [key]: true,
    });

    scrollToView(key);
  };

  const scrollToView = (key) => {
    if (key === "google" && googleRef.current) {
      googleRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "safari" && safariRef.current) {
      safariRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "mozilla" && mozillaRef.current) {
      mozillaRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "opera" && operaRef.current) {
      operaRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "internetExplorer" && explorerRef.current) {
      explorerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [isNotificationSelected, setIsNotificationSelected] = useState(false);

  const showHideNotifications = () => {
    setIsNotificationSelected(!isNotificationSelected);
  };

  return (
    <div>
      {isNotificationSelected ? (
        <div className={`col-sm-3  ${settingsStyles.notifications}`}>
          <div className={settingsStyles.notificationscroll}>
            <Notifications />
          </div>
        </div>
      ) : null}
      <div>
        {signInStatus === "true" ? (
          <HeaderAfterSignIn />
        ) : (
          <div className={settingsStyles.headerContainer}>
            <Link to={"/"}>
              <div>
                <img src={logo} alt="Skopic-Logo" width={90} height={48} />
              </div>
            </Link>
          </div>
        )}
        <div className="careers-main-container">
          <div className="page-navigation-options-container">
            {/* <h3>Cookies</h3> */}
            <div className="main-heading-container">
              <h3 className="main-heading">Cookies</h3>
            </div>
            <div className="page-navigation-options">
              <div className="page-options-content">
                <Sidebaroptions
                  active={isActive.google}
                  text="Google"
                  onSidebaroptionClick={() => clickHandler("google")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.safari}
                  text="Safari"
                  onSidebaroptionClick={() => clickHandler("safari")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.mozilla}
                  text="Mozilla"
                  onSidebaroptionClick={() => clickHandler("mozilla")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.opera}
                  text="Opera"
                  onSidebaroptionClick={() => clickHandler("opera")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.internetExplorer}
                  text="Internet Explorer"
                  onSidebaroptionClick={() => clickHandler("internetExplorer")}
                />
              </div>
            </div>
          </div>
          <div className="careers-content-container">
            <div
              ref={googleRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                }  ${isActive.google && settingsStyles.focusapperance}`}
            >
              <p>Google Chrome</p>
              <ol>
                <li>
                  On your compuer, open <strong>Chrome.</strong>
                </li>
                <li>
                  At the top right, click <strong>Settings.</strong>
                </li>
                <li>
                  At the bottom, click <strong>Preferences.</strong>
                </li>
                <li>
                  Under <strong>Privacy and Security</strong>, click{" "}
                  <strong>Content Settings</strong>
                </li>
                <li>
                  Click <strong>Preferences.</strong>
                </li>
                <li>
                  From here, you can:
                  <br />
                  Turn on cookies: Next to Blocked,turn on the switch.
                  <br />
                  Turn off cookies: Turn off Allow sites to save and read cookie
                  data.
                </li>
              </ol>
            </div>
            <div
              ref={safariRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                }  ${isActive.safari && settingsStyles.focusapperance}`}
            >
              <p>Safari</p>
              <ol>
                <li>
                  On your compuer, open <strong>Safari.</strong>
                </li>
                <li>
                  At the top right, click <strong>More</strong>and then{" "}
                  <strong>Settings</strong>
                </li>
                <li>
                  At the bottom, click <strong>Advanced.</strong>
                </li>
                <li>
                  Under <strong>Privacy</strong>
                </li>
                <li>
                  You will see <strong>Block Cookies.</strong> Section
                </li>
                <li>
                  Click on <strong>Never</strong> radio button under{" "}
                  <strong>Block Cookies.</strong>
                </li>
                <li> Reload your browser, Cookies will be Enabled</li>
              </ol>
            </div>
            <div
              ref={mozillaRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                } ${isActive.mozilla && settingsStyles.focusapperance}`}
            >
              <p>Mozilla</p>
              <ol>
                <li>
                  On your compuer, open <strong>Firefox.</strong>
                </li>
                <li>
                  Click on the <strong>Tools Menu.</strong>
                </li>
                <li>
                  Click on <strong>Options.</strong>
                </li>
                <li>
                  Select <strong>Privacy Settings</strong>
                </li>
                <li>
                  If you want no restrictions,tick{" "}
                  <strong>Accept cookies from sites</strong>
                </li>
                <li>
                  If you want to set some restrictions, unclick{" "}
                  <strong>Accept cookies from sites.</strong>
                </li>
              </ol>
            </div>
            <div
              ref={operaRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                } ${isActive.opera && settingsStyles.focusapperance}`}
            >
              <p>Opera</p>
              <ol>
                <li>
                  On your compuer, open <strong>Opera.</strong>
                </li>
                <li>
                  Click on the <strong>Tools</strong> menu{" "}
                  <strong>Opera</strong>
                </li>
                <li>
                  Click on <strong>Preferences.</strong>
                </li>
                <li>
                  Change to the <strong>Advanced</strong>tab,and then go to the{" "}
                  <strong>Cookie</strong> section.
                </li>
                <li>
                  Select{" "}
                  <strong>Accept cookies only from the site I visit</strong> or{" "}
                  <strong>Accept cookies</strong>
                </li>
                <li>
                  Ensure <strong>Delete new cookies when exiting Opera</strong>{" "}
                  is not ticked.
                </li>
              </ol>
            </div>
            <div
              ref={explorerRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                } ${isActive.internetExplorer && settingsStyles.focusapperance}`}
            >
              <p>Internet Explorer</p>
              <ol>
                <li>
                  On your compuer, open <strong>IE.</strong>
                </li>
                <li>
                  Click the <strong>Tools Button</strong>
                </li>
                <li>
                  Next click <strong>Internet Options</strong> and select the{" "}
                  <strong>Privacy</strong> tab
                </li>
                <li>
                  Under <strong>Settings</strong>
                </li>
                <li>
                  Move the slider to the top to block all cookies or to the
                  bottom to allow all cookies
                </li>
                <li>
                  Click <strong>Apply</strong>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookiesComponent;
