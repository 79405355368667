// import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  buttonContainer: () => ({
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "4px",
    "& .MuiButtonBase-root": {
      fontSize: "12px",
      background: "#EFEEED",
      color: " #4795DE",
      borderRadius: "15px",
      padding: "4px",
      boxShadow: "0px 4px 14px #9d9898",
      "&:hover": {
        fontSize: "12px",
        background: "#EFEEED",
        color: "#4795DE",
        borderRadius: "15px",
        padding: "4px",
        boxShadow: "0px 4px 14px #9d9898",
      },
    },
  }),
  defaultImageButton: () => ({
    "& .MuiButtonBase-root": {
      fontSize: "12px",
      background: "#EFEEED",
      color: " #4795DE",
      borderRadius: "15px",
      padding: "10px 20px",
      minWidth: "30px",
      boxShadow: "0px 4px 14px #9d9898",
      "&:hover": {
        fontSize: "12px",
        background: "#EFEEED",
        color: "#4795DE",
        borderRadius: "15px",
        padding: "10px 20px",
        boxShadow: "0px 4px 14px #9d9898",
      },
    },
  }),
});

export default useStyles;
