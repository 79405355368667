
import { Modal } from "react-bootstrap";
// import styles from 'src/Assets/css/settings/Modal.css';

function FBModal(props) {
  const url = process.env.REACT_APP_SERVICES_API + 'facebook/loginURLForFBPage.html';
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="SocailModals"
    >
      <Modal.Body>
        <p>You want to share on Facebook Page</p>
        <div>
          <a href={url} target="_self"><button>Continue</button></a>
        </div>
      </Modal.Body>
    </Modal >
  );
}

export default FBModal;
