class ApiSheet {
  static BASE_URL = process.env.REACT_APP_SERVICES_API;

  // -------------------------------------------------------------- Home API

  // land api
  // Post(?redirectTenantId, ?name )
  static LandApi = this.BASE_URL + "jsonuser/land";

  // -------------------------------------------------------------------------------------- SIGNUP

  // validate mail
  // POST(mail)
  static MailVerify_URL = this.BASE_URL + "jsonindex/validateTempUserEmail";

  // send verification code
  // POST(redirectTenantId?, email, displayName, password, password_c, userTenant)
  static SendVerificationCode_URL =
    this.BASE_URL + "jsonindex/send-verification-code";

  // communities and countries list for map.
  // POST(mapStatus?=IndexNear)
  static CommunitiesCountriesMap_URL = this.BASE_URL + "jsonuser/tenant-map";

  // communities and countries list for map.
  // POST(emailType?=Resend, j_username=encrypt(mail) )
  static ResendOTP_URL = this.BASE_URL + "jsonindex/resend-varification-code";

  // submit OTP.
  // POST(sixDigitCode?, sixDigitCodeStatus?=approved, j_username=<encrypt(mail)> )
  static SubmitOTP_URL = this.BASE_URL + "jsonindex/six-digit-code-user";

  // -------------------------------------------------------------------------------------------------- PHOTO TIMELINE

  // upload photo timeline.
  // POST(tenantId?, imgs, tmlPhotoDescs, tmlPhotoLoc, locName )
  static UploadPhotoTimeline_URL =
    this.BASE_URL + "index/upload-timeline-images";


  // delete photo timeline
  // DELETE(imageIds?, tenantId?)
  static DeletePhotoTimeline_URL = this.BASE_URL + "jsonuser/timeline-images";


  // -------------------------------------------------------------------------------------------MANAGE CONTENT ->MODERATOR

  // action items list -> moderator..
  // GET(/tenantId)
  static ACTION_ITEMS_LIST_MODERATOR = this.BASE_URL + "jsonuser/tid";

  // action items allow-denny -> moderator..
  // POST(approveIds)
  static ALLOW_DENNY_ACTION_ITEMS_MODERATOR = this.BASE_URL + "jsonuser/moderator-approve-deny";



}

export default ApiSheet;
