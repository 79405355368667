import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { IoCloseOutline } from "react-icons/io5";
import Popup from 'reactjs-popup';
import API from "src/network";
import RestrictionMsgModal from 'src/Components/RestrictionMsgModal';
import Post from '../components/Post';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

const SearchTagModal = ({
  open,
  onClose,
  hashTag,
}) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [childSaysLoading, setChildSaysLoading] = useState(false);
  const [childSaysData, setChildSaysData] = useState({});
  const [followData, setFollowData] = useState({});
  const [voteUpData, setVoteUpData] = useState({});
  const [hasRestrictionSays, setHasRestrictionSays] = useState(false);
  const userDetails = useSelector(state => state.SignInReducer.userDetails);
  const { tenantId } = userDetails?.userData || {};

  useEffect(() => {
    if (hashTag) {
      (async () => {
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("tag", hashTag);
          formData.append("hme", "trnd");
          formData.append("tid", tenantId);
          const res = await API.tagSearch(formData);
          setData(res.data);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [hashTag])

  useEffect(() => {
    const { messageList } = data || {};
    let list = [];
    let isPrivate = false;
    if (messageList && Array.isArray(messageList)) {
      if (messageList.length >= 2) {
        const message1 = messageList[1];
        const { followStatus, hashTagType, hashtagAccess } = message1 || {};
        isPrivate = (followStatus === "N" && ("" + hashTagType).toLowerCase() === "private" && hashtagAccess === "N");
        list = isPrivate ? [...messageList.slice(0, 1)] : [...messageList];
      } else {
        list = [...messageList];
      }
      setIsPrivate(isPrivate);
      setList(list);
    }
  }, [data]);

  const fetchChildSaysData = async (id, popCols, limit) => {
    setChildSaysLoading(true);
    try {
      const response = await API.fetchChildSaysData(id, popCols, limit);
      setChildSaysData(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setChildSaysLoading(false);
    }
  };

  const postChildSayData = async (id, msg, lnglat, locSelect) => {
    try {
      setChildSaysLoading(true);
      const formdata = new FormData();
      formdata.append("Message", msg);
      const response = await API.postChildSayData(id, formdata, lnglat, locSelect);
      const status = response?.data?.status || "";
      if (status === "New Hash") {
        return "New Hash";
      } else if (status === "E_Say" || status === "E") {
        setHasRestrictionSays(true);
      } else {
        await fetchChildSaysData(id);
        updateSaysCountInList(id, "minus");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setChildSaysLoading(false);
    }
  };

  const fetchFollowData = async (id, status) => {
    try {
      const response = await API.fetchFollowUnfollowData(id, status);
      setFollowData(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchVoteUpStatus = async (id, status) => {
    try {
      const response = await API.fetchVoteUpStatus(id, status);
      setVoteUpData(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteMessage = async (id, type, Parent_ID) => {
    try {
      const response = await API.deleteMessage(id);
      if (response) {
        if (type === "ChildSay") {
          console.log("childSaysData", childSaysData);
          const updatedData = childSaysData.childSAYsMessageList.filter(
            (each) => each.id !== id
          );
          setChildSaysData({
            ...childSaysData,
            childSAYsMessageList: updatedData,
          });

          updateSaysCountInList(Parent_ID, "minus");
        } else {
          const copiedList = [...list];
          setList(copiedList.filter((each) => each.id !== id));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateSaysCountInList = (id, operator) => {
    const copiedList = [...list];
    const updatedList = copiedList.map(item => {
      if (item.id === id) {
        return {
          ...item,
          sayCount: operator === "add" ? item.sayCount + 1 : item.sayCount - 1,
        };
      } else {
        return { ...item };
      }
    });
    setList(updatedList);
  };

  return (
    <Popup
      modal
      key={"some-key"}
      open={open}
      onClose={onClose}
      contentStyle={{ borderRadius: "10px", backgroundColor: "#E5E4E2" }}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <div>
        <Box display='flex' justifyContent='space-between' alignItems="center" pl={1.5} height={40}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {`${list?.length} Search result for ${data?.wantedH || ''}`}
          </Typography>
          <IconButton onClick={onClose} style={{ margin: 0, padding: 4 }}>
            <IoCloseOutline />
          </IconButton>
        </Box>
        {loading && (
          <div style={{ marginBottom: 10, marginTop: 10, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        )}
        <div style={{ maxHeight: '84vh', overflow: "scroll" }}>
          <Post
            loading={childSaysLoading}
            listdata={list}
            data={data}
            childSaysData={childSaysData}
            fetchChildSaysData={fetchChildSaysData}
            postChildSayData={postChildSayData}
            followData={followData}
            fetchFollowData={fetchFollowData}
            fetchVoteUpStatus={fetchVoteUpStatus}
            deleteMessage={deleteMessage}
            fetchFeedData={() => { }}
            refreshFeedData={() => { }}
            showData="tagsearch"
          />
        </div>
        {isPrivate && (
          <Box height={40} display="flex" alignItems="center" justifyContent="center">
            <Typography id="private-tag-description">
              Only following users can view the message.
            </Typography>
          </Box>
        )}
      </div>
      {hasRestrictionSays && (
        <RestrictionMsgModal
          open={hasRestrictionSays}
          onClose={() => setHasRestrictionSays(false)}
          title="Thank you"
          message="Your SAY will publish as soon as the Community Moderator approves it."
        />
      )}
    </Popup>
  );
};

export default SearchTagModal;
