import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import {
  GoogleMap,
  Polygon,
  DrawingManager,
  MarkerF,
  InfoWindowF,
  StandaloneSearchBox,
  useJsApiLoader
} from "@react-google-maps/api";
import Api from "src/network";

const containerStyle = {
  width: "100%",
  height: "80vh",
};

function PolygonMap(props = {}) {
  const history = useHistory();
  // const location = useLocation();
  // console.log(props)

  const {
    id,
    name,
    description,
    city,
    country,
    state,
    address1,
    zipcode,
    tenantLatitude,
    tenantLangitude,
    newsletterFrequency,
    markers,
    polygonCoords,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formattedList = (data) => {
    return (data || []).map((obj) => {
      return {
        lat: Number(obj.lat),
        lng: Number(obj.lng),
      };
    });
  };

  const allMarkers = formattedList(markers);

  const latArry = tenantLatitude?.split(",");
  const lngArry = tenantLangitude?.split(",");

  const latlng = latArry?.map((latItem, index) => {
    const lngItem = lngArry[index];
    return {
      lat: Number(latItem),
      lng: Number(lngItem),
    };
  });

  // Store Polygon path in state
  const [coords, setCoords] = useState([]);
  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
  const coordsRef = useRef([]);
  const [drawingState, setDrawingState] = useState({
    drawingMode: ''
  });
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const libraries = ["drawing", "places"];
  const [curLocation, setCurLocation] = useState({ lat: 0, lng: 0 });
  const [bounds, setBounds] = useState(null);
  const [searchBoxRef, setSearchBoxRef] = useState(null);
  const [searchLoc, setSearchLoc] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAXEHjz5uTArWEC0q8-zpu_xXuHXJ2zOl8',
    libraries: libraries,
    language: "en",
    region: "us"
  });

  const options = {
    drawingControl: false,
    drawingControlOptions: {
      drawingMode: window && window.google && window.google.maps && window.google.maps.drawing && window.google.maps.drawing.OverlayType.POLYGON,
    },
    polygonOptions: {
      fillColor: "#2196F3",
      strokeColor: "#2196F3",
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zindex: 1,
    },
  };

  useEffect(() => {
    if (tenantLatitude && tenantLatitude) {
      setCoords(latlng);
      coordsRef.current = latlng;
    } else {
      // setCoords([]);
    }
  }, []);


  const removeBoundary = () => {
    polygonRef.current.setMap(null);
    setCoords([]);
    coordsRef.current = [];
    if (props.getLatLng) {
      props.getLatLng({});
    }
  };
  const onSubmitBoundary = async () => {
    const latlngData = coordsRef?.current;
    let req = new FormData();
    const lat = latlngData.map((item) => {
      return item.lat;
    });
    const lng = latlngData.map((item) => {
      return item.lng;
    });
    if (props.getLatLng && props.type === "add") {
      props.getLatLng({ lat: lat, lng: lng });
      return;
    }
    setIsSubmitting(true);

    req.append("id", id);
    req.append("name", name);
    req.append("description", description);
    req.append("tenantLatitude", lat.toString());
    req.append("tenantLangitude", lng.toString());
    req.append("city", city);
    req.append("state", state);
    req.append("country", country);
    req.append("zipcode", zipcode);
    req.append("newsLetterFrequency", newsletterFrequency);
    req.append("address1", address1);

    try {
      const res = await Api.submitBoundary(req);
      if (res?.data && res?.data?.status === "success") {
        props.onMapClose();
        setIsSubmitting(false);
        history.go(0);
      }
    } catch (e) {
      console.error(e);
    }

    // axios
    //   .request({
    //     url: `https://dev.skopic.com/skopicportal/jsonuser/updateTenantById`,
    //     method: "POST",
    //     data: req,
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       "Set-Cookie": Cookies.get("JSESSIONID"),
    //     },
    //     withCredentials: true,
    //   })
    //   .then((res) => {
    //     if (res?.data && res.data.status === "success") {
    //       props.onMapClose();
    //       setIsSubmitting(false);
    //       history.go(0);
    //     }
    //   });
  };


  // Call setCoords with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setCoords(nextPath);
      coordsRef.current = nextPath;
    }
  }, [setCoords]);

  // Bind refs to current Polygon and listeners
  const onPolygonLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const onPolygonComplete = (polygon) => {
    const polyArray = polygon.getPath().getArray();
    let paths = [];
    polyArray.forEach(function (path) {
      paths.push({ lat: path.lat(), lng: path.lng() });
    });
    polygon.setMap(null);
    setCoords(paths);
    coordsRef.current = paths;
    setShowInfoWindow(true);
  };


  const onMapLoad = (map) => {
    // if (coords.length !== 0) {
    //   setCurLocation(coords[0]);
    // } else if (allMarkers.length !== 0) {
    //   setCurLocation(allMarkers[0]);
    // } 
    // else {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        setCurLocation(pos);
      }
    );
    window.google?.maps.event.addListener(map, "bounds_changed", () => {
      setBounds(map.getBounds());
    });
    // setCoords([]);
    // }
  };

  const onSearchBoxLoad = (ref) => {
    setSearchBoxRef(ref);
  };

  const onPlacesChanged = () => {
    setSearchLoc(searchBoxRef?.getPlaces());
    let results = searchBoxRef.getPlaces();
    const loc = results[0].geometry.location;
    const pos = { lat: loc.lat(), lng: loc.lng() };
    setCurLocation(pos);
  };

  const showPolygon = (obj) => {
    const polygonItem = polygonCoords.find((coord) =>
      coord.some((item) => Number(item.lat) === obj.lat)
    );
    if (polygonItem) {
      const paths = formattedList(polygonItem);
      setCoords(paths);
    }
  };

  const handleEdit = () => {
    if (drawingState?.drawingMode) {
      setDrawingState({ drawingMode: '' })
    } else {
      setDrawingState({ drawingMode: 'polygon' })
    }

  }

  // console.log('coords', coords)

  return (
    <>
      {isLoaded &&
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={curLocation}
          zoom={5}
          onLoad={onMapLoad}
        >
          {coords.length === 0 && !markers ? (
            <StandaloneSearchBox
              onLoad={onSearchBoxLoad}
              onPlacesChanged={onPlacesChanged}
              bounds={bounds}
            >
              <input
                type="text"
                placeholder="Search location"
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `240px`,
                  height: `40px`,
                  padding: `12px 12px`,
                  borderRadius: `2px`,
                  boxShadow: `rgba(0, 0, 0, 0.3) 0px 1px 4px -1px`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  position: "absolute",
                  left: "50%",
                  marginLeft: "-120px",
                  marginTop: "10px",
                }}
              />
            </StandaloneSearchBox>
          ) : null}
          {!markers ? (
            <div style={{ position: 'absolute', bottom: 0, marginBottom: '12px' }}>
              <Button
                variant="contained"
                size="small"
                onClick={onSubmitBoundary}
                disabled={coords.length === 0 || isSubmitting}
                style={{ marginLeft: "8px" }}
              >
                Submit Boundary
              </Button>
              <Button
                style={{ marginLeft: "8px" }}
                variant="contained"
                size="small"
                disabled={coords.length === 0 || isSubmitting}
                onClick={removeBoundary}
              >
                Remove Boundary
              </Button>
            </div>
          ) : null}
          {
            <div style={{ position: 'absolute', left: '70%', top: '2%' }}>

              <Button variant="contained" endIcon={<EditIcon />} onClick={handleEdit}>
                Edit
              </Button>
            </div>
          }
          <DrawingManager
            drawingMode={drawingState?.drawingMode}
            options={options}
            editable
            draggable
            onPolygonComplete={onPolygonComplete}
            onMouseUp={onEdit}
            onDragEnd={onEdit}
          />
          {coords?.length > 0 && <Polygon
            options={{
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.35,
              geodesic: true,
              editable: true,
            }}
            ref={polygonRef}
            editable
            path={coords}
            onLoad={onPolygonLoad}
            onUnmount={onUnmount}
            onMouseUp={onEdit}
            onDragEnd={onEdit}
          >
            {showInfoWindow && (
              <InfoWindowF
                // position={center}
                zIndex="10"
                onCloseclick={(e) => setShowInfoWindow(false)}
                options={{ maxWidth: 300 }}
              >
                content here
              </InfoWindowF>
            )}
          </Polygon>}
          {markers ? (
            <>
              {allMarkers.map((marker) => (
                <MarkerF
                  position={marker}
                  onClick={() => showPolygon(marker)}
                />
              ))}
            </>
          ) : (
            <MarkerF
              position={curLocation}
            // onClick={(e) => setShowInfoWindow(true)}
            />
          )}
        </GoogleMap>
      }
    </>
  );
}

export default PolygonMap;