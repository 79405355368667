import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Grid, Button, CircularProgress } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import axios from "axios";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Cookies from "js-cookie";
import Feed from "../feed";
import PhotoPreview from "../../PhotoPreview";
import TrendingTags from "../trendingTags";
import SayAskPost from "./sayAskPost";
import useStyles from "./styles";
import styles from "./ViewActBeforeSignIn.module.css";
import API from "src/network";
import SearchResultBeforeLogin from "./SearchResultBeforeSignup";
import RightSide from "./RightSide";
import Page from "src/layout/Page";
import { SignupContext } from "src/context/SignupContext/SignupContext";

const ViewActivityBeforeIn = () => {
  const {
    buttonContainer,
    feed,
    container,
    moderator,
    prospectimagesdiv,
    invitebutton,
    filterinput,
    roundcontent,
    asksayNav,
    askHoverImage,
    askSelectedImage,
    sayHoverImage,
    saySelectedImage,
    updateSelectedImage,
    updateHoverImage,
    tagHoverImage,
    tagSelectedImage,
    comunityProfileArrowIcon,
    loader,
    backDefaultCommunityBtn,
  } = useStyles();
  const imageServerURL = process.env.REACT_APP_SKOPIC_IMAGE_OTHER;
  const baseURL = process.env.REACT_APP_SERVICES_API_OTHER;
  const location = useLocation();
  const history = useHistory();

  const [images, setImages] = useState({});
  const [selectedImage, setSelectedImage] = useState({});
  const [contributors, setContributors] = useState({});
  const [communityData, setCommunityData] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [originalFeed, setOriginalFeed] = useState([]);
  const [feedData, setFeedData] = useState([]);
  const [previewModal, setPreviewModal] = useState(false);
  const [contentType, setContentType] = useState("feed");
  const [isAskData, setIsAskData] = useState([]);
  const [isAskTempData, setIsAskTempData] = useState([]);
  const [isTagData, setIsTagData] = useState([]);
  const [isTagTempData, setIsTagTempData] = useState([]);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const listRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [childSayLoading, setChildSayLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [saysId, setSaysId] = useState("");
  const [childSaysData, setChildSaysData] = useState([]);
  const [contributorBio, setContributorBio] = useState({});
  const [showBio, setShowBio] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isEnable, setIsEnable] = useState(null);
  const [isSignIn, setIsSignIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [searchData, setSearchData] = useState({});
  const [clearSearchHeader, setClearSearchHeader] = useState(false);

  const signupContext = useContext(SignupContext);

  useEffect(() => {
    setIsEnable(location?.state?.signIn);
  }, [location]);

  const fetchSearchData = async (params) => {
    setContentType("search");
    setLoading(true);
    setSearchParam(params);
    try {
      const response = await API.getSearchData(params);
      setSearchData(response.data);
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleMakeDefault = () => {
    //set signup context data
    signupContext?.setSignupState({
      community: location?.state?.community,
      tenantId: location?.state?.tenantId,
    });

    //go back to home screen
    history.push("/");
  };

  const fetchApi = async () => {
    setLoading(true);
    await axios
      .request({
        url: `${baseURL}/jsonindex/community-url?src=inviteLink&tid=${location?.state?.tenantId
          }&id=${"Z2Vmb3lpNDMzOEBmcmFuZGluLmNvbQ=="}`,
        method: "Get",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Set-Cookie": Cookies.get("JSESSIONID"),
        },
        withCredentials: true,
      })
      .then((res) => {
        setCommunityData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchApi();
    console.log("location->", location);
  }, [location]);

  useEffect(() => {
    if (communityData) {
      console.log("inside", communityData);
      setTimeout(() => {
        fetchParamApi();
        fetchFeedApi();
        fetchContributeApi();
        fetchAskApi();
        fetchTagsApi();
      }, 1000);
    }
  }, [communityData]);

  const fetchContributeApi = async () => {
    try {
      const res = await API.fetchContributors();
      setContributors(res.data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchParamApi = async () => {
    await axios
      .get(`${baseURL}/jsonindex/main?logParam=restrict`)
      .then((res) => {
        setImages(res?.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      });
  };

  const fetchFeedApi = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${baseURL}/jsonmessage/all-feed?startlimit=${page}&limiter=all`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Set-Cookie": Cookies.get("JSESSIONID"),
          },
          withCredentials: true,
        }
      );
      const newList = res?.data?.feed || [];
      setFeedData((prevState) => [...prevState, ...newList]);
      setOriginalFeed((prevState) => [...prevState, ...newList]);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchAskApi = async () => {
    await axios
      .request({
        url: `${baseURL}/jsonmessage/trending-items?task=asks&more=all&breadCountLength=${page}`,
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Set-Cookie": Cookies.get("JSESSIONID"),
        },
        withCredentials: true,
      })
      .then((res) => {
        const trendingAsks = res?.data?.trendingAsks || [];
        const newList = [...isAskData, ...trendingAsks];
        setIsAskData(newList);
        setIsAskTempData(newList?.slice(0, 2));
      });
  };

  const fetchTagsApi = async () => {
    await axios
      .request({
        url: `${baseURL}/jsonmessage/trending-items?task=tags&more=all&breadCountLength=${page}`,
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Set-Cookie": Cookies.get("JSESSIONID"),
        },
        withCredentials: true,
      })
      .then((res) => {
        const trendingTags = res?.data?.trendingTags || [];
        const newList = [...isTagData, ...trendingTags];
        setIsTagData(newList);
        setIsTagTempData(newList?.slice(0, 2));
      });
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = listRef.current;
    if (scrollTop + clientHeight === scrollHeight) {
      setIsFetching(true);
      setIsClicked(true);
      setPage(page + 10);
    }
  };

  // useEffect(() => {
  //   if (isFetching) {
  //     feedData?.length === 10 && fetchFeedApi();
  //     isAskData?.length === 10 && fetchAskApi();
  //     isTagData?.length === 10 && fetchTagsApi();
  //   }
  // }, [isFetching, page]);

  useEffect(() => {
    const obj = images?.communities?.find(
      (item) => item?.id === location?.state?.tenantId
    );
    setSelectedImage(obj);
  }, [images]);

  // useEffect(() => {
  //   if (selectedId) {
  //     fetchFeedApi();
  //   } else {
  //     fetchFeedApi();
  //   }
  // }, [selectedId]);

  useEffect(() => {
    if (selectedId) {
      const data = originalFeed?.filter(
        (item) => item?.Keyword_ID === selectedId
      );
      setFeedData(data);
    } else {
      setFeedData(originalFeed);
    }
  }, [selectedId]);

  const handleClick = (id) => {
    // setIsClicked(true);
    setPage(0);
    setSaysId("");
    if (selectedId === id) {
      setSelectedId("");
    } else {
      setSelectedId(id);
    }
  };

  const showPreview = (e) => {
    setPreviewModal(!previewModal);
  };

  const handleSeeAll = (id) => {
    setContentType(id);
    setSelectedId("");
  };

  const handleBack = () => {
    setContentType("feed");
    setPage(0);
    fetchAskApi();
    fetchTagsApi();
    setClearSearchHeader(true);
  };

  const handleBackArrow = () => {
    history.push("/defaultCommunity", {
      community: location?.state?.community,
      tenantId: location?.state?.tenantId,
      email: location?.state?.email,
      password: location?.state?.password,
      displayName: location?.state?.displayName,
    });
  };

  const handleSayClick = async (e, id) => {
    e.preventDefault();
    if (saysId === id) {
      setSaysId("");
    } else {
      setSaysId(id);
      try {
        setChildSayLoading(true);
        let formdata = new FormData();
        formdata.append("id", id);
        formdata.append("in_pop_columns", "all");
        formdata.append("in_limit", 0);
        const res = await axios.post(
          `${baseURL}/jsonuser/child-says`,
          formdata
        );
        setChildSaysData(res?.data);
      } catch (e) {
        console.error(e);
      } finally {
        setChildSayLoading(false);
      }
    }
  };

  const fetchChildSaysData = async (id) => {
    try {
      setChildSayLoading(true);
      let formdata = new FormData();
      formdata.append("id", id);
      formdata.append("in_pop_columns", "all");
      formdata.append("in_limit", 0);
      const res = await axios.post(`${baseURL}/jsonuser/child-says`, formdata);
      setChildSaysData(res?.data);
    } catch (e) {
      console.error(e);
    } finally {
      setChildSayLoading(false);
    }
  };

  const handleClickEvent = (id, showBio) => {
    const data = contributors?.contribut.find((item) => {
      return item?.id === id;
    });
    setContributorBio(data);
    setShowBio(showBio);
  };

  const onModalSelect = () => {
    setModalShow(false);
  };

  const handleFollowunfollow = (id, status) => {
    console.log("dd", id, status);
  };

  return (
    <Page
      fetchSearchData={fetchSearchData}
      clearSearchHeader={clearSearchHeader}
      isMenuActionDisabled={true}
      flow="beforeSignIn"
    >
      {isLoading ? (
        <CircularProgress className={loader} />
      ) : (
        <React.Fragment>
          <div className="container">
            <div
              className={`row ${styles.rowContainer}`}
              style={{ marginBottom: "100px" }}
            >
              <div className={`col-lg-9 ${styles.leftside}`}>
                {contentType === "search" ? (
                  <SearchResultBeforeLogin
                    childSaysData={childSaysData}
                    fetchChildSaysData={fetchChildSaysData}
                    loading={loading}
                    searchData={searchData}
                    handleBack={handleBack}
                    isActionDisabled={true}
                    flow="beforeSignIn"
                  />
                ) : contentType === "feed" ? (
                  <>
                    <div
                      className={feed}
                      style={{
                        backgroundImage: `url(${imageServerURL}${selectedImage?.logo})`,
                      }}
                    // onClick={(e) => showPreview(e)}
                    >
                      <div className={container}>
                        {selectedImage ? (
                          <div className={moderator}>
                            <p
                              onClick={(e) => showPreview(e)}
                              style={{
                                fontSize: "20px",
                                color: "#373334",
                                margin: 0,
                                cursor: "pointer",
                              }}
                            >
                              {selectedImage?.name}
                            </p>
                            <MdOutlineArrowForwardIos
                              className={comunityProfileArrowIcon}
                              onClick={(e) => showPreview(e)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        ) : (
                          <div className={moderator}>{null}</div>
                        )}

                        <div className={prospectimagesdiv}>
                          <a
                            className={invitebutton}
                            style={{ cursor: "not-allowed" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.878"
                              height="15.779"
                              viewBox="0 0 15.878 15.779"
                            >
                              <g
                                id="Group_1924"
                                data-name="Group 1924"
                                transform="translate(-6714.617 -3413.368)"
                              >
                                <path
                                  id="Path_435"
                                  data-name="Path 435"
                                  d="M34.165,26.211a3.831,3.831,0,1,0-5.059,0A6.11,6.11,0,0,0,25.5,31.795c0,.726.376,1.728,2.179,2.379a11.545,11.545,0,0,0,3.957.6c4.533,0,6.135-1.6,6.135-2.98A6.11,6.11,0,0,0,34.165,26.211ZM31.635,21a2.329,2.329,0,1,1-2.329,2.329A2.329,2.329,0,0,1,31.635,21Zm0,12.27c-2.73,0-4.633-.776-4.633-1.477a4.633,4.633,0,0,1,9.266,0C36.268,32.5,34.365,33.272,31.635,33.272Z"
                                  transform="translate(6689.117 3394.369)"
                                  fill="#373334"
                                />
                                <path
                                  id="Path_436"
                                  data-name="Path 436"
                                  d="M72.931,19.277H71.9V18.25a.751.751,0,0,0-.751-.751.726.726,0,0,0-.751.751v1.027H69.35a.751.751,0,0,0-.751.751.726.726,0,0,0,.751.751H70.4v1.052a.726.726,0,0,0,.751.751.751.751,0,0,0,.751-.751V20.779h1.027a.726.726,0,0,0,.751-.751A.751.751,0,0,0,72.931,19.277Z"
                                  transform="translate(6656.813 3395.87)"
                                  fill="#373334"
                                />
                              </g>
                            </svg>
                            Invite
                          </a>
                        </div>
                      </div>
                      <PhotoPreview
                        open={previewModal}
                        handleClose={() => setPreviewModal(false)}
                        tenantId={location?.state?.tenantId}
                      />
                    </div>
                    <div>
                      <div className={filterinput}>
                        <SayAskPost
                          isSignIn={isSignIn}
                          isEnable={isEnable}
                          isActionDisabled={true}
                        />
                        <div className={roundcontent}>
                          <div className={asksayNav}>
                            <ul>
                              <li
                                onClick={() => handleClick("OpenASK")}
                                className={
                                  selectedId === "OpenASK"
                                    ? askHoverImage
                                    : askSelectedImage
                                }
                              >
                                <a>
                                  <span>Ask</span>
                                </a>
                              </li>
                              <li
                                onClick={() => handleClick("OpenSAY")}
                                className={
                                  selectedId === "OpenSAY"
                                    ? sayHoverImage
                                    : saySelectedImage
                                }
                              >
                                <a>
                                  <span>Say</span>
                                </a>
                              </li>
                              <li
                                onClick={() => handleClick("impupdate")}
                                className={
                                  selectedId === "impupdate"
                                    ? updateHoverImage
                                    : updateSelectedImage
                                }
                              >
                                <a>
                                  <span>Update</span>
                                </a>
                              </li>
                              <li
                                onClick={() => handleClick("hashTAG")}
                                className={
                                  selectedId === "hashTAG"
                                    ? tagHoverImage
                                    : tagSelectedImage
                                }
                              >
                                <a>
                                  <span>Tag</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          background: "#E5E4E2",
                          border: "1px solid #DEE0E1",
                          borderRadius: "20px 0 0 0",
                          padding: "10px 20px 0 20px",
                          height: "calc(100vh - 90px)",
                          overflowY: "scroll",
                        }}
                        onScroll={handleScroll}
                        ref={listRef}
                      >
                        <Feed
                          feedLoading={loading}
                          data={feedData}
                          handleSayClick={handleSayClick}
                          id={saysId}
                          childSaysData={childSaysData}
                          handleFollowunfollow={handleFollowunfollow}
                          childSayLoading={childSayLoading}
                          isActionDisabled={true}
                          flow="beforeSignIn"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <TrendingTags
                    data={contentType === "asks" ? isAskData : isTagData}
                    handleBack={handleBack}
                    isAsksClicked={contentType}
                    handleSayClick={handleSayClick}
                    id={saysId}
                    childSaysData={childSaysData}
                    handleFollowunfollow={handleFollowunfollow}
                    isLoading={childSayLoading}
                    isActionDisabled={true}
                    flow="beforeSignIn"
                  />
                )}
              </div>

              <div className={`col-lg-3 ${styles.rightside}`}>
                <RightSide
                  contributors={contributors}
                  handleClickEvent={handleClickEvent}
                  contributorBio={contributorBio}
                  showBio={showBio}
                  isSignIn={isSignIn}
                  isEnable={isEnable}
                  isTagTempData={isTagTempData}
                  isAskTempData={isAskTempData}
                  handleSeeAll={handleSeeAll}
                />
              </div>
            </div>
          </div>

          <Grid
            container
            style={{
              padding: "10px",
              background: "#4795DE",
              position: "fixed",
              bottom: 0,
              zIndex: 1,
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#ffffff",
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Choose a Default Community to continue with Sign Up process
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "10px",
              }}
            >
              <div
                onClick={handleBackArrow}
                className={backDefaultCommunityBtn}
              >
                <ArrowCircleLeftIcon style={{ fill: "#ffffff" }} />
              </div>
              <div className={buttonContainer} style={{ display: "flex" }}>
                <Button variant="contained" onClick={handleMakeDefault}>
                  Make Default
                </Button>
              </div>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Page>
  );
};

export default ViewActivityBeforeIn;
