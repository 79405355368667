import React, { useState, useEffect, useMemo } from "react";
import { TextField, Select, MenuItem, ListSubheader } from "@material-ui/core";
import { CountryPicker, getPickerData } from '../../../pages/Landing/components/CountryPicker';
import "./index.css";

const SearchBox = ({
  searchValue,
  handleSearch,
  handleClear,
  defaultCountry,
  onChangeCountry,
  countryList,
  countryTenantList,
}) => {
  const [selectedCountry, setSelectedCountry] = useState({});
  const countryData = getPickerData();

  useEffect(() => {
    setSelectedCountry(defaultCountry);
  }, [defaultCountry]);

  const onCountryChange = (e) => {
    const country = e.target.value;
    if (country) {
      setSelectedCountry(country);
      onChangeCountry(country);
    }
  };

  const getCountryFlag = (countryCode) => {
    const image = countryData.find(it => it.countryCode === countryCode)?.image ?? "";
    return image;
  };

  const countriesHaveComm = useMemo(() => {
    return countryList?.filter(
      (item) => countryTenantList?.hasOwnProperty(item?.country_name) && countryTenantList[item?.country_name]?.length > 0
    );
  }, [countryTenantList, countryList]);

  const countriesHaveNoComm = useMemo(() => {
    return countryList?.filter(
      (item) => countryTenantList?.hasOwnProperty(item?.country_name) && countryTenantList[item?.country_name]?.length === 0
    );
  }, [countryTenantList, countryList]);

  return (
    <div className="searchcontainer">
      <Select
        variant="outlined"
        value={selectedCountry}
        onChange={onCountryChange}
        className="select"
        renderValue={(selected) => {
          if (Object.keys(selected).length > 0) {
            return (
              <img
                src={getCountryFlag(selected?.country_code)}
                alt={selected?.country_name?.slice(0, 1)}
                width={"26px"}
                height={"18px"}
                style={{ border: "0.5px solid lightgrey", borderRadius: "2px" }}
              />
            )
          } else {
            return null;
          }
        }}
      >
        {countriesHaveComm?.map((country) => (
          <MenuItem key={country.country_code} value={country}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={getCountryFlag(country?.country_code)}
                alt={country?.country_name?.slice(0, 1)}
                width={"24px"}
                height={"18px"}
                style={{ border: "0.5px solid lightgrey", borderRadius: "2px" }}
              />
              <div style={{ marginLeft: "8px", fontWeight: selectedCountry === country ? "bold" : "normal" }}>
                {country?.country_name}
              </div>
            </div>
          </MenuItem>
        ))}
        <MenuItem value="" disabled>
          <div style={{ backgroundColor: "black", height: "0.8px", width: "100%" }} />
        </MenuItem>
        {countriesHaveNoComm?.map((country) => (
          <MenuItem key={country.country_code} value={country}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={getCountryFlag(country?.country_code)}
                alt={country?.country_name?.slice(0, 1)}
                width={"24px"}
                height={"18px"}
                style={{ border: "0.5px solid lightgrey", borderRadius: "2px" }}
              />
              <div style={{ marginLeft: "8px", fontWeight: selectedCountry === country ? "bold" : "normal" }}>
                {country?.country_name}
              </div>
            </div>
          </MenuItem>
        ))}
      </Select>
      <TextField
        variant="outlined"
        value={searchValue}
        placeholder="Search Community"
        onChange={handleSearch}
        className="custom-text-field search-box-choose-default-community-before-signIn"
      />
    </div>
  );
};

export default SearchBox;
