import imageCompression from "browser-image-compression";

export const compressFile = async (file) => {
  if (!file) return null;
  try {
    const options = {
      maxSizeMB: 1, // Set the maximum size in MB
      maxWidthOrHeight: 1920, // Set the maximum width or height
      useWebWorker: true, // Use a web worker for better performance
    };

    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error('Error compressing image:', error);
    return null;
  }
};
