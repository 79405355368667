import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import styles from "src/Assets/css/home/post.module.css";
import { styled } from "@mui/material/styles";
import { Avatar, Divider } from "@mui/material";
import { Button } from "@mui/material";
import location from "src/Assets/images/locationpinchildpost.png";
import crown from "src/Assets/images/crown.png";
import API from "../../../network";

const Container = styled("div")({
  backgroundColor: "#fff",
  margin: "20px",
  borderRadius: "20px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
});

const CrownDiv = styled("div")({
  alignSelf: "flex-end",
  marginBottom: "1px",
});

const Status = styled("div")({
  width: "12px",
  height: "12px",
  borderRadius: "12px",
  alignSelf: "flex-end",
  marginBottom: "6px",
});

const UserName = styled("div")({
  fontSize: "16px",
  fontWeight: "700",
});

const MessageTime = styled("div")({
  fontSize: "12px",
  color: "#373334",
  opacity: "85%",
});

const NameContainer = styled("div")({
  flex: 1,
  flexDirection: "column",
  display: "flex",
});

function ActionItems(props) {
  const { tenantId } = props || {};
  const {setActionItemCount, decreaseActionItemCount} = props;
  // const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const [actionItems, setActionItems] = useState([]);

  useEffect(() => {
    getModeratorActionItems();
  }, [tenantId]);

  const getModeratorActionItems = async () => {
    // if (userDetails && Object.keys(userDetails).length !== 0) {
    if (tenantId) {
      try {
        const response = await API.getModeratorActionItems(tenantId);
        if (
          response?.data?.messageList &&
          Array.isArray(response.data.messageList)
        ) {
          setActionItems(response.data.messageList || []);
          setActionItemCount && setActionItemCount(response.data.TotalActionItems);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getFormData = (tenantId, messageId, category, requestType, userId) => {
    let approveIdsValue = "";
    if (category === "Message") {
      approveIdsValue = `msg_${messageId}`;
    } else if (category === "Request to follow community") {
      approveIdsValue = `followreq_${userId}_${tenantId}`;
    } else if (category === "Report abuse") {
      approveIdsValue = `abs_${messageId}`;
    } else if (category === "New moderator") {
      approveIdsValue = `user_${userId}_${tenantId}`;
    } else if (category === "Unblock user") {
      approveIdsValue = `unblockReq_${userId}_${tenantId}_${messageId}`;
    }

    approveIdsValue =
      requestType === "approve"
        ? `${approveIdsValue}_1`
        : `${approveIdsValue}_0`;
    console.info(approveIdsValue);

    let formData = new FormData();
    formData.append("approveIds", approveIdsValue);
    return formData;
  };

  const approveAction = (messageId, category, tenantId, userId) => {
    const formData = getFormData(
      tenantId,
      messageId,
      category,
      "approve",
      userId
    );
    API.moderatorApproveDeny(formData)
      .then((res) => {
        const updatedData = actionItems.filter(
          (each) => each.messageId !== messageId
        );
        setActionItems(updatedData);
        decreaseActionItemCount && decreaseActionItemCount();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCurrentStatusColor = (Keyword_ID) => {
    switch (Keyword_ID) {
      case "Open ASK":
      case "OpenASK":
      case "ASK":
        return "#2168b2";
      case "SAY":
      case "OpenSAY":
        return "#45a735";
      case "Important Update":
      case "impupdate":
        return "#e82727";
      case "#tag":
      case "hashTAG":
      case "H":
        return "#efba29";
      default:
        return "grey";
    }
  };

  const onReject = async (messageId, category, tenantId, userId) => {
    try {
      const formData = getFormData(
        tenantId,
        messageId,
        category,
        "reject",
        userId
      );
      await API.moderatorApproveDeny(formData);
      const updatedData = actionItems.filter(
        (each) => each.messageId !== messageId
      );
      setActionItems(updatedData);
      decreaseActionItemCount && decreaseActionItemCount();
    } catch (e) {
      console.error(e);
    }
  };

  const getKey = (item) =>
    item?.messageId
      ? `key-messageId-${item.messageId}`
      : `key-userId-${item.userId}`;

  const profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  return (
    <>
      {actionItems.length === 0 ? (
        <div
          style={{
            display: "block",
            textAlign: "center",
            paddingTop: "20px",
            margin: "auto",
          }}
        >
          No data found
        </div>
      ) : (
        actionItems.map((item) => (
          <Container key={getKey(item)}>
            <div style={{ display: "flex" }}>
              <Avatar
                src={`${profileImagePath}${item.userImage}`}
                className={styles.avatar}
                variant="square"
              />
              <NameContainer>
                <UserName>{item.displayName}</UserName>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <MessageTime>{item.createdDate}</MessageTime>
                  {item.locName && (
                    <>
                      <img
                        src={location}
                        style={{ width: 8, marginLeft: 12, marginRight: 5 }}
                        alt="location"
                      />
                      <MessageTime>{item.locName}</MessageTime>
                    </>
                  )}
                </div>
              </NameContainer>
            </div>
            <div style={{ marginTop: "6px" }}>{item.message}</div>
            {/* <div style={{ marginTop: "6px" }}>
            {item.followCount} followers. {item.hashTagType}
          </div> */}
            {String(item?.category).toLowerCase() === "new moderator" ? (
              <CrownDiv>
                <img src={crown} style={{ width: 12 }} alt="crown" />
              </CrownDiv>
            ) : (
              <Status
                style={{
                  backgroundColor: getCurrentStatusColor(item.Keyword_ID),
                  height: "13px",
                  width: "13px",
                  borderRadius: "36px",
                  marginRight: "10px",
                }}
              />
            )}
            <Divider variant="fullWidth" />
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button
                style={{
                  padding: "5px 20px",
                  borderWidth: "0px",
                  borderRadius: "20px",
                  minWidth: "40%",
                  textTransform: "none",
                  backgroundColor: "#EFEEED",
                  color: "#373334",
                  fontWeight: "400",
                }}
                onClick={() =>
                  approveAction(
                    item.messageId,
                    item.category,
                    item.tenantId,
                    item.userId
                  )
                }
              >
                Approve
              </Button>
              <Button
                style={{
                  padding: "5px 20px",
                  borderWidth: "0px",
                  borderRadius: "20px",
                  minWidth: "40%",
                  textTransform: "none",
                  backgroundColor: "#EFEEED",
                  color: "#373334",
                  fontWeight: "400",
                }}
                onClick={() =>
                  onReject(
                    item.messageId,
                    item.category,
                    item.tenantId,
                    item.userId
                  )
                }
              >
                Reject
              </Button>
            </div>
          </Container>
        ))
      )}
    </>
  );
}
export default ActionItems;
