// import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  feedContainer: () => ({
    background: "#FFFFFF",
    border: "1px solid #DEE0E1",
    borderRadius: "10px",
    padding: "10px 10px 10px 15px",
    marginBottom: "10px",
  }),
  feedMessage: () => ({
    overflow: "hidden",
  }),
  profileName: () => ({
    color: "#373334",
    marginBottom: "0px",
    fontSize: "16px",
    fontWeight: " 700",
  }),
  avatarDetails: () => ({
    justifyContent: "space-between",
    display: "flex",
    position: "relative",
  }),
  avatar: () => ({
    marginRight: "10px",
    borderRadius: "40% !important",
    width: "50px !important",
    height: "50px !important",
    "& .MuiAvatar-square": {
      borderRadius: "20% !important",
      width: "50px !important",
      height: "50px !important",
    },
  }),
  locationandtime: () => ({
    display: "flex",
    fontSize: " 12px",
    color: "#373334",
    alignItems: "center",
  }),
  feeddropdown: () => ({
    position: "relative",
    display: "inline-block",
    bottom: " 5px",
  }),
  feeddropdownbutton: () => ({
    outline: "none",
    backgroundColor: "white",
    color: "white",
    fontSize: "16px",
    border: "none",
    cursor: "pointer",
  }),
  followerandcount: () => ({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "4px",
    "& div": {
      "& div": {
        fontSize: "11px",
        color: "#373334",
      },
    },
  }),
  askcount: () => ({
    backgroundColor: "#2168b2",
    width: "14px",
    height: "14px",
    color: "white",
    fontSize: "10px",
    borderRadius: "50%",
    textAlign: "center",
  }),
  saycount: () => ({
    backgroundColor: "#45a735",
    width: "14px",
    height: "14px",
    color: "white",
    fontSize: "10px",
    borderRadius: "50%",
    textAlign: "center",
  }),
  updatecount: () => ({
    backgroundColor: "#e82727",
    width: "14px",
    height: "14px",
    color: "white",
    fontSize: "10px",
    borderRadius: "50%",
    textAlign: "center",
  }),
  tagcount: () => ({
    backgroundColor: "#efba29",
    width: "14px",
    height: "14px",
    color: "white",
    fontSize: "10px",
    borderRadius: "50%",
    textAlign: "center",
  }),
  followcount: () => ({
    color: "#878585",
    cursor: "pointer",
  }),
  saysandfollow: () => ({
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0 0 0",
  }),
  noOfsays: () => ({
    display: "flex",
    alignItems: "center",
    marginLeft: "44px",
    "& a": {
      cursor: "pointer",
      "& p": {
        margin: "0px",
      },
    },
    "& span": {
      fontSize: "14px",
      paddingLeft: "10px",
      color: "#373334",
    },
    "& img": {
      position: "relative",
      top: "-2px",
    },
  }),
  noOffollows: () => ({
    display: "flex",
    alignItems: "center",
    marginRight: "44px",
  }),
  followtext: () => ({
    margin: "0px",
    paddingLeft: "10px",
    cursor: "pointer",
    "& span": {
      fontSize: "14px",
      paddingLeft: "10px",
      color: "#373334",
    },
  }),
  priavteinvite: () => ({
    marginRight: "30px",
  }),
  likeunlike: () => ({
    display: "flex",
    justifyContent: "center",
    marginTop: "0px",
    padding: "10px 0 0 0",
    "& a": {
      cursor: "pointer",
      fontSize: "14px",
      "& img": {
        marginRight: "5px",
        position: "relative",
        top: "-1px",
      },
    },
    "& p": {
      marginBottom: "0",
      "& img": {
        marginRight: "4px",
      },
    },
  }),
  nodata: () => ({
    width: "100%",
    // paddingTop: '30px',
    textAlign: "center",
  }),
  postedcomment: () => ({
    display: "flex",
  }),
  childsayprofileimage: () => ({
    marginTop: "10px",
    borderRadius: "15px !important",
    border: "solid 1px #707070",
  }),
  profileimagetext: () => ({
    marginLeft: "10px",
    backgroundColor: "#f3f2f0",
    padding: "13px",
    borderRadius: "25px",
    width: "100%",
    // width: "-moz-available",
    "& p": {
      marginBottom: 0,
    },
  }),
  childsaysprofileandfdropdown: () => ({
    display: "flex",
    justifyContent: "space-between",
  }),
  childsaydropdowwbutton: () => ({
    background: "none !important",
  }),
  profilenamesays: () => ({
    margin: "0px 0px 0px 8px",
    "& p": {
      margin: "0 0 0 2px",
    },
  }),
  feeddropdownsays: () => ({
    position: "relative",
    display: "inline-block",
    bottom: "5px",
  }),
  feeddropdownbuttonsays: () => ({
    outline: "none",
    backgroundColor: "white",
    color: "white",
    fontSize: "16px",
    border: "none",
  }),
  childsaytextandcolorlabel: () => ({
    display: "flex",
    justifyContent: "space-between",
  }),
  childsaycount: () => ({
    width: "14px",
    height: "14px",
    backgroundColor: "#36b549",
    color: "white",
    fontSize: "11px",
    borderRadius: "50%",
    padding: "0 0 0 4px",
    position: "relative",
    top: "3px",
    right: "4px",
    bottom: "4px",
  }),
  likeandlikecount: () => ({
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "66px",
    marginTop: "7px",
    marginBottom: "8px",
    "& a": {
      "& img": {
        verticalAlign: "initial",
      },
    },
    "& p": {
      marginLeft: "10px",
    },
  }),
  spandisplay: () => ({
    display: "flex",
  }),
  dot: () => ({
    height: "fit-content",
    margin: "6px 6px 0px 6px",
  }),
  childsaytime: () => ({
    marginBottom: 0,
    fontSize: "13px",
    marginRight: "10px",
  }),
  childUnlike: () => ({
    "& img": {
      height: "12px",
      width: "12px",
    },
  }),
  childlikedisplay: () => ({
    display: "none",
    height: "12px",
    width: "12px",
  }),
  profileHover: () => ({
    display: "none",
    position: "absolute",
    left: 0,
    backgroundColor: "white",
    minWidth: "280px",
    zIndex: 2,
    background: "#ffffff 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "15px",
    padding: "15px",
  }),
  profileHoverImageCount: () => ({
    display: "flex",
  }),
  profilehovername: () => ({
    marginBottom: "0px",
    fontSize: "16px",
    fontWeight: 400,
  }),
  profilecount: () => ({
    marginBottom: "0px",
    fontSize: "12px",
    color: "#878585",
    fontWeight: 400,
  }),
  profilehovercontent: () => ({
    fontWeight: 600,
    fontSize: "12px",
    marginTop: "1rem",
    marginBottom: 0,
  }),
});

export default useStyles;
