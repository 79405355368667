import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
// import * as moderatorActions from "../../../store/actions/Moderator/moderatoractions";
import { useState } from "react";
import styles from "src/Assets/css/home/post.module.css";
import follow from "src/Assets/images/follow.svg";
import unfollow from "src/Assets/images/unfollow.svg";
// import * as childSayActions from "../../../store/actions/searchactions/childsayActionCreator";
// import * as feedactions from "../../../store/actions/feedactions/feedActionCreator";
// import PrivateTagsReducer from "../../../store/reducer/ModeratorReducer/PrivateTagsReducer";
import { styled } from "@mui/material/styles";
import { Avatar, Divider, Button } from "@mui/material";
import ChildSay from "../../Activity/Activites/ChildSay";
// import ActivityPost from "../../Activity/ActivityPost";
import sayIcon from "src/Assets/images/noofsays.svg";
import inviteIcon from "src/Assets/images/Invitemember.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import editIcon from "src/Assets/images/edit.svg";
import deleteIcon from "src/Assets/images/Delete.svg";
import API from "../../../network";
import InviteFollowers from "src/pages/HomePage/components/InviteFollowers";
import RestrictionMsgModal from "src/Components/RestrictionMsgModal";
import { Menu, MenuItem } from "@szhsin/react-menu";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import feedstyles from "src/Assets/css/home/feedpost.module.css";
import PrimaryModeratorCrown from "src/Assets/images/PrimaryModeratorCrown.svg";
import SecondaryModeratorCrown from "src/Assets/images/SecondaryModeratorCrown.svg";
import SimpleDialogDemo from "../../../Modules/Reusuablecomponents/CustomizedDialogs";
import ReusuableSendaMailModal from "../../../Modules/Reusuablecomponents/ReusuableSendaMailModal";
import AvatarBadge from "src/Components/AvatarBadge";

const Container = styled("div")({
  backgroundColor: "#fff",
  margin: "20px",
  borderRadius: "20px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
});

const Status = styled("div")({
  width: "12px",
  height: "12px",
  borderRadius: "15px",
  alignSelf: "flex-end",
  marginBottom: "6px",
  marginRight: "10px",
});

const UserName = styled("div")({
  fontSize: "16px",
  fontWeight: "700",
});

const MessageTime = styled("div")({
  fontSize: "12px",
  marginRight: "8px"
});

const NameContainer = styled("div")({
  flex: 1,
  flexDirection: "column",
  display: "flex",
  marginLeft: "10px",
});

const PrivateTags = (props) => {
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const [editId, seteditId] = useState(-10);
  // const [followingid, setfollowingId] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [editPrivateTag, setEditPrivateTag] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [editRestrictionMsg, setEditRestrictionMsg] = useState("");
  const [invitesShow, setInvitesShow] = React.useState(false);
  const [invite, setInvite] = useState(null);
  const [tenantId, setTenantId] = useState(null);
  const [msgId, setMsgId] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [emailPopupOpen, setEmailPopupOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});

  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;
  const [data, setData] = useState([]);

  const fetchPrivateTagsData = async () => {
    try {
      setData([]);
      setShowLoader(true);
      const response = await API.fetchPrivateTagsData(
        null,
        null,
        `tenantID=${props.tenantId}&limiter=all`
      );
      const privateTagsData =
        response.data?.messageList.filter(
          (each) => each.hashTagType === "private"
        ) || [];
      setData(privateTagsData);
      setShowLoader(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (props.tenantId) {
      fetchPrivateTagsData();
    }
  }, [props.tenantId]);

  const onClickSendEmailOption = (selectedPost) => {
    setEmailPopupOpen(true);
    setSelectedPost(selectedPost);
  };

  var childsayid = "";
  const onSayClick = (id, e) => {
    childsayid = `childsay${id}`;
    e.preventDefault();
    if (document.getElementById(childsayid).style.display === "block") {
      document.getElementById(childsayid).style.display = "none";
    } else {
      // dispatch(childSayActions.fetchChildSayData(`?id=${id}`));
      API.fetchChildSaysData(id);
      document.getElementById(childsayid).style.display = "block";
    }
  };

  const increaseSayCount = (id) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return { ...item, sayCount: item.sayCount + 1 };
      } else {
        return item;
      }
    });
    setData(updatedData);
  };

  const decreaseSayCount = (id) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        return { ...item, sayCount: item.sayCount - 1 };
      } else {
        return item;
      }
    });
    setData(updatedData);
  };

  const followunfollow = (id, followstatus) => {
    API.fetchFollowUnfollowData(id, followstatus)
      .then((res) => {
        const updatedData = data.map((each) => {
          if (each.id === id) {
            return {
              ...each,
              followStatus: each.followStatus === "Y" ? "N" : "Y",
              followCount:
                each.followStatus === "Y"
                  ? each.followCount - 1
                  : each.followCount + 1,
            };
          } else {
            return each;
          }
        });
        setData(updatedData);
      })
      .catch((e) => console.log(e));
  };

  const onLikeUnlikeSelection = (id, cache) => {
    // var likedid = `like${id}`;
    // var unlikedid = `unlike${id}`;
    // var countid = `uncount${id}`;
    // var likedcountid = `counted${id}`;

    // console.log(countid, "", likedcountid)

    if (cache === "cacheUpdate") {
      // document.getElementById(likedid).style.display = "none";
      // document.getElementById(unlikedid).style.display = "block";
      // document.getElementById(countid).style.display = "none"
      // document.getElementById(likedcountid).style.display = "block"
    }
    // likestatusparams = `?id=${id}&&cacheStatus=${cache}`;
    API.fetchVoteUpStatus(id, cache);
    // dispatch(feedactions.fetchVoteUpStatus(likestatusparams));
  };
  // <p>{data?.PrivateTagsData?.privateTagList[0]?.displayName}</p>
  //  <p>{data?.PrivateTagsData?.privateTagList[0]?.Message}</p>

  const currStatus = (status) => {
    switch (status) {
      case "A":
        return { color: "green", status: "Approved" };
      default:
        return { color: "red", status: "Rejected" };
    }
  };

  const handleEdit = (tag) => {
    console.info("Editing tag with messageId:", tag?.id);
    setEditPrivateTag(tag);
    setIsEditable(true);
  };

  const onSavePrivateTag = async (newMessage) => {
    try {
      const { id, locName, msgLat, msgLng } = editPrivateTag || {};
      const locationName = locName || "";
      const msgLoc = msgLat && msgLng ? `${msgLat || ""},${msgLng || ""}` : "";

      let formData = new FormData();
      formData.append("messageID", id);
      formData.append("message", newMessage);

      const resp = await API.editPrivateTag(
        formData,
        locationName,
        msgLoc,
        locationName
      );
      const status = resp?.data?.status || "";
      if (String(status).toLowerCase() === "edited successfully") {
        const copiedData = [...data];
        const updatedData = copiedData.map((each) => {
          const updatedMessage = each.id === id ? newMessage : each.Message;
          return {
            ...each,
            Message: updatedMessage,
          };
        });
        setData(updatedData);
      } else if (String(status).toLowerCase() === "new hash") {
        setEditRestrictionMsg("You can't create New #TAG");
      } else {
        //status: "Please retain #kalki"
        setEditRestrictionMsg(status);
      }

      //reset flag and id
      resetEditableFields();
    } catch (e) {
      console.error(e);
      alert("Something went wrong");
    }
  };

  const resetEditableFields = () => {
    setEditPrivateTag({});
    setIsEditable(false);
  };

  const handleDelete = async (messageId) => {
    await API.deletePrivateTag(`?messageID=${messageId}`)
      .then(() => {
        const updatedData = data.filter((each) => each.id !== messageId);
        setData(updatedData);
      })
      .catch((err) => { });
  };

  const onInviteSelection = (tenantId, id) => {
    setInvitesShow(true);
    setInvite(true);
    setTenantId(tenantId);
    setMsgId(id);
  };

  return (
    <>
      {showLoader && (
        <div className="d-flex flex-direction-row justify-content-center mt-5">
          <CircularProgress size={30} />
        </div>
      )}

      {data.length !== 0 ? (
        <div>
          {data.map((item) => (
            <Container key={item.id}>
              <div className={styles.profileMessagePopupMainContainer}>
                <div className={feedstyles.avatardetails}>
                  <AvatarBadge
                    src={`${profileImagePath}${item.uimage}`}
                    className={styles.avatar}
                    variant="square"
                    isPrimaryModerator={item?.mainuserdata?.isPM == 1}
                    isModerator={
                      item?.mainuserdata?.isModerator == 1 &&
                      item?.mainuserdata?.isPM == 0
                    }
                  />
                  <NameContainer>
                    <UserName>{item.displayName}</UserName>
                    <div style={{ display: "flex" }}>
                      <MessageTime>{item.Message_Time}</MessageTime>
                      <MessageTime>{item.locName}</MessageTime>
                    </div>
                  </NameContainer>

                  {/* Hover content */}
                  <div className={feedstyles.profileHoverContentContainer}>
                    <div className={feedstyles.profilehoverimageandcount}>
                      <div className={feedstyles.avatarWrapper}>
                        <Avatar
                          src={`${profileImagePath}${item.uimage}`}
                          className={styles.avatar}
                          variant="square"
                        />
                        {(item.isModerator === "1" || item.isPM === "1") && (
                          <img
                            src={
                              item.isModerator === "1" && item.isPM === "1"
                                ? PrimaryModeratorCrown
                                : item.isModerator === "1"
                                  ? SecondaryModeratorCrown
                                  : null
                            }
                            className={feedstyles.crown}
                            alt="Moderator Crown"
                          />
                        )}
                      </div>
                      <div>
                        <p className={feedstyles.profilehovername}>
                          {item.displayName}
                        </p>
                        <p className={feedstyles.profilecount}>
                          ASK {item?.mainuserdata?.askcount ?? 0} | SAY{" "}
                          {item?.mainuserdata?.saycount ?? 0} | UPDATE{" "}
                          {item?.mainuserdata?.updatecount ?? 0} | TAG{" "}
                          {item?.mainuserdata?.hashtagcount ?? 0}
                        </p>
                      </div>
                    </div>
                    <p className={feedstyles.profilehovername}>
                      {item.shortBio}
                    </p>
                  </div>
                </div>

                <Menu
                  menuButton={
                    <IconButton
                      style={{
                        padding: 0,
                        width: 30,
                        height: 30,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <MdKeyboardArrowDown fontSize={25} />
                    </IconButton>
                  }
                  transition
                  direction="bottom"
                  arrow
                  menuStyle={{
                    backgroundColor: "white",
                    borderRadius: 20,
                  }}
                >
                  {item.User_ID && userDetails && userDetails.userData && (
                    <>
                      {item.User_ID === userDetails.userData.User_ID ? (
                        <>
                          <MenuItem onClick={() => handleEdit(item)}>
                            <img
                              src={editIcon}
                              alt="edit"
                              style={{ marginRight: 8 }}
                            />
                            <span style={{ fontSize: 14 }}>Edit tag</span>
                          </MenuItem>
                          <MenuItem onClick={() => handleDelete(item.id)}>
                            <img
                              src={deleteIcon}
                              alt="delete"
                              style={{ marginRight: 8 }}
                            />
                            <span style={{ fontSize: 14 }}>Delete tag</span>
                          </MenuItem>
                        </>
                      ) : (
                        <>
                          <MenuItem onClick={() => handleDelete(item.id)}>
                            <img
                              src={deleteIcon}
                              alt="delete"
                              style={{ marginRight: 8 }}
                            />
                            <span style={{ fontSize: 14 }}>Delete tag</span>
                          </MenuItem>
                          <MenuItem>
                            <ReusuableSendaMailModal
                              displayName={item.mainuserdata.displayName}
                              id={item.id}
                            />
                          </MenuItem>
                        </>
                      )}
                    </>
                  )}
                </Menu>
              </div>
              {isEditable && item.id === editPrivateTag?.id ? (
                <EditPrivateTag
                  onSave={onSavePrivateTag}
                  onCancel={() => setIsEditable(false)}
                  defaultMessage={item.Message}
                />
              ) : (
                <div style={{ marginTop: "6px" }}>{item.Message}</div>
              )}
              {/* <div style={{ marginTop: "6px" }}>
                {item.followCount} followers. {item.hashTagType}
              </div> */}
              <div>
                <SimpleDialogDemo
                  followCount={item?.followCount}
                  followData={item?.follweduserprofile}
                  id={item.id}
                  // status={followData}
                  hashTagType={item?.hashTagType}
                />
              </div>
              <Status
                style={{
                  backgroundColor: currStatus(item.msg_message_status_s).color,
                }}
              />
              <Divider variant="fullWidth" />
              <div className={styles.privateTagButtonContainer}>
                <Button
                  className={styles.privateButton}
                  onClick={() => {
                    seteditId(item.id === editId ? -10 : item.id);
                  }}
                >
                  <div className={styles.privateButtonDescriptionContainer}>
                    <img
                      src={sayIcon}
                      alt="sayIcon"
                      className={styles.privateButtonIconImage}
                    />
                    <p className={styles.privateButtonParagraph}>{`${item?.sayCount
                      } ${item?.sayCount === 1 ? "SAY" : "SAYs"}`}</p>
                  </div>
                </Button>
                <Button
                  className={styles.privateButton}
                  onClick={() =>
                    followunfollow(
                      item.id,
                      item.followStatus === "Y" ? "isFollow=0" : "isFollow=1"
                    )
                  }
                >
                  <div className={styles.privateButtonDescriptionContainer}>
                    <img
                      src={item.followStatus === "Y" ? unfollow : follow}
                      alt={
                        item.followStatus === "Y"
                          ? "unfollow-icon"
                          : "follow-icon"
                      }
                      className={styles.privateButtonIconImage}
                    />
                    <p className={styles.privateButtonParagraph}>
                      {item.followStatus === "Y" ? "Unfollow" : "Follow"}
                    </p>
                  </div>
                </Button>

                <button className={styles.privateButton} onClick={() => { }}>
                  <div className={styles.privateButtonDescriptionContainer}>
                    <img
                      src={inviteIcon}
                      alt="invite-icon"
                      className={styles.privateButtonIconImage}
                    />
                    <p
                      className={styles.privateButtonParagraph}
                      onClick={() =>
                        onInviteSelection(
                          item.TenantID || item.tenantID,
                          item.id
                        )
                      }
                    >
                      Invite
                    </p>
                  </div>
                </button>
              </div>
              {item.id === editId && (
                <ChildSay
                  item={item}
                  id={item.id}
                  increaseSayCount={increaseSayCount}
                  decreaseSayCount={decreaseSayCount}
                />
              )}
              {/* {item.User_ID == followingid&& (
            <ActivityPost item={{...item,id:item.User_ID}}
          )} */}
            </Container>
          ))}
          <InviteFollowers
            show={invitesShow}
            onHide={() => setInvitesShow(false)}
            invite={invite}
            tenantid={tenantId}
            msgid={msgId}
          />
          {!!editRestrictionMsg && (
            <RestrictionMsgModal
              open={!!editRestrictionMsg}
              onClose={() => setEditRestrictionMsg("")}
              message={editRestrictionMsg}
            />
          )}
        </div>
      ) : (
        <div className="d-flex flex-direction-row justify-content-center mt-5">
          {!showLoader && <p>No Data Available</p>}
        </div>
      )}
    </>
  );
};

export default PrivateTags;

function EditPrivateTag({ defaultMessage, onSave, onCancel }) {
  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setMessage(defaultMessage);
  }, [defaultMessage]);

  useEffect(() => {
    let msg = message || "";
    // msg = msg.replace(/\n/g, "");
    // msg = msg.replace(/\s/g, "");
    const count = msg.length;
    setCharCount(count);
  }, [message]);

  const handleCancel = () => {
    setErrMessage("");
    onCancel && onCancel();
  };

  const handleSave = () => {
    if (isValid()) {
      onSave && onSave(message);
    }
  };

  const handleInputChange = (value) => {
    setMessage(value);
    setErrMessage("");
  };

  const getWordWithHash = (sentence) => {
    const regex = /(?:^|\s)(#\w+)\b/g;

    // Find all matches in the sentence
    const matches = sentence.match(regex);

    // Return the array of matched words (or an empty array if no matches)
    return matches ? matches.map((match) => match.trim()) : [];
  };

  const isValid = () => {
    if (defaultMessage === message) {
      setErrMessage("At least one modification required");
      return false;
    }

    const previousHashArr = getWordWithHash(defaultMessage || "");
    const previousHash =
      Array.isArray(previousHashArr) && previousHashArr.length > 0
        ? previousHashArr[0]
        : "";

    const currentHashArr = getWordWithHash(message || "");
    const currentHash =
      Array.isArray(currentHashArr) && currentHashArr.length > 0
        ? currentHashArr[0]
        : "";

    if (previousHash !== currentHash) {
      setErrMessage("You can't create a New hash tag");
      return false;
    }

    return true;
  };

  const enabled = message && charCount > 0 && charCount <= 280;

  return (
    <div>
      <textarea
        rows="2"
        cols="48"
        value={message}
        onChange={(e) => handleInputChange(e.target.value)}
        onFocus={() => setIsFocused(true)}
        className={styles.EditPostMessage}
      // maxLength={280}
      />
      {errMessage && (
        <span style={{ fontSize: 11, color: "red" }}>{errMessage}</span>
      )}
      {isFocused && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <button
              onClick={handleSave}
              className={styles.editPost}
              disabled={!enabled}
              style={{ opacity: enabled ? 1 : 0.6 }}
            >
              Save
            </button>
            <a onClick={handleCancel} className={styles.cancelPost}>
              Cancel
            </a>
          </div>
          <span>
            <label style={{ color: charCount > 280 ? "red" : "black" }}>
              {charCount}
            </label>
            <label>/280</label>
          </span>
        </div>
      )}
    </div>
  );
}
