// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorpop {
    color: red;
  }
  
  .resetButtonDisabled {
    cursor: none;
    opacity: none;
    color: grey !important;
    border-radius: 20px !important;
    padding: 2% 20% !important;
    background-color: #f3f2f0 !important;
    text-transform: none !important;
    font-weight: 400 !important;
    font-size: 1.063rem !important;
  }
  .resetButtonEnabled {
    background: linear-gradient(
      123deg,
      #6ca8e1 0%,
      #4795de 49%,
      #0071dd 100%
    ) !important;
    border-radius: 20px !important;
    padding: 2% 20% !important;
    color: white !important;
    text-transform: none !important;
    font-weight: 400 !important;
    font-size: 1.063rem !important;
  }
  body {
    color: black;
  }
  #parent-modal-description {
    font-size: 18px;
    color: #373334;
  }
  #standard-basic::placeholder {
    text-align: center;
  }
  #parent-modal-title {
    font-weight: 500;
    font-size: 23px;
  }
`, "",{"version":3,"sources":["webpack://./src/Modules/LandingPage/ForgotPassword/ForgotPassword.css"],"names":[],"mappings":"AAAA;IACI,UAAU;EACZ;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,0BAA0B;IAC1B,oCAAoC;IACpC,+BAA+B;IAC/B,2BAA2B;IAC3B,8BAA8B;EAChC;EACA;IACE;;;;;gBAKY;IACZ,8BAA8B;IAC9B,0BAA0B;IAC1B,uBAAuB;IACvB,+BAA+B;IAC/B,2BAA2B;IAC3B,8BAA8B;EAChC;EACA;IACE,YAAY;EACd;EACA;IACE,eAAe;IACf,cAAc;EAChB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,gBAAgB;IAChB,eAAe;EACjB","sourcesContent":[".errorpop {\r\n    color: red;\r\n  }\r\n  \r\n  .resetButtonDisabled {\r\n    cursor: none;\r\n    opacity: none;\r\n    color: grey !important;\r\n    border-radius: 20px !important;\r\n    padding: 2% 20% !important;\r\n    background-color: #f3f2f0 !important;\r\n    text-transform: none !important;\r\n    font-weight: 400 !important;\r\n    font-size: 1.063rem !important;\r\n  }\r\n  .resetButtonEnabled {\r\n    background: linear-gradient(\r\n      123deg,\r\n      #6ca8e1 0%,\r\n      #4795de 49%,\r\n      #0071dd 100%\r\n    ) !important;\r\n    border-radius: 20px !important;\r\n    padding: 2% 20% !important;\r\n    color: white !important;\r\n    text-transform: none !important;\r\n    font-weight: 400 !important;\r\n    font-size: 1.063rem !important;\r\n  }\r\n  body {\r\n    color: black;\r\n  }\r\n  #parent-modal-description {\r\n    font-size: 18px;\r\n    color: #373334;\r\n  }\r\n  #standard-basic::placeholder {\r\n    text-align: center;\r\n  }\r\n  #parent-modal-title {\r\n    font-weight: 500;\r\n    font-size: 23px;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
