import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./invitations.css";
import gmail from "src/Assets/images/gmail.svg";
import facebook from "src/Assets/images/fb.svg";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
import MessageModal from "../../../../Utils/messageModal";
import { set } from "js-cookie";
import API from "../../../../network";
// import GmailContactsModal from "./GmailContactsModal";
import FacebookPost from "./FacebookPost";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { updateIsNewUser, fetchloginUser } from "src/store/actions/SignInActions/SignInAction";
import { emailRegex } from "src/Constants/regex";

var emailsParam = "";

const ModalType = {
  Invite: "INVITE",
  // Gmail: "GMAIL",
  Facebook: "FACEBOOK",
  Message: "MESSAGE",
};

function Invitation(props) {
  const userDetails = useSelector(
    (state) => state.SignInReducer?.userDetails || {}
  );
  const isNewUser = useSelector((state) => state.SignInReducer?.isNewUser);
  const dispatch = useDispatch();
  const [mail, setMail] = useState("");
  const [subject, setSubject] = useState("");
  const [inviteMsg, setInviteMsg] = useState("");
  const [textmsg, setTextmsg] = useState("");
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState([]); // Corrected variable name
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  // const [closeInvites, setCloseInvites] = useState(null);
  // const [messageShow, setMessageShow] = useState(false);
  // const [isVisibleGContsModal, setIsVisibleGContsModal] = useState(false);
  const [fbAccessToken, setFbAccessToken] = useState("");
  // const [isVisibleFbPostModal, setIsVisibleFbPostModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const fbappId = "skopic_dev_24";

  useEffect(() => {
    dispatch(fetchloginUser());
  }, []);

  useEffect(() => {
    if (props.show) {
      setModalType(ModalType.Invite);
    } else {
      setModalType(null);
    }
  }, [props.show]);

  const emailChange = (e) => {
    setMail(e.target.value);
    setIsEmailInvalid(false);
  };

  const handleKeyDown = (evt) => {
    if ([" ", ";", ","].includes(evt.key)) {
      evt.preventDefault();

      const newEmail = mail.trim();

      if (newEmail && emailRegex.test(newEmail)) {
        setEmail((email) => [...email, newEmail]);
        setMail("");
      } else {
        setIsEmailInvalid(true);
      }
    }
  };

  useEffect(() => {
    const { tenantName = "", tenantId = "", displayName = "" } = userDetails?.userData || {};
    if (props.invite) {
      setSubject(`Invitation to join ${tenantName} via Skopic`);
      setTextmsg(
        `Hi,%0A%0AI request you to join ${tenantName} on Skopic. It is quick and easy to Sign Up.%0A%0AClick the link below to join ${tenantName}.%0A${process.env.REACT_APP_SERVICES_API}user/communityurl.html?tid=${tenantId}%0A%0AThank you,%0A${displayName}`
      );
    }
    setInviteMsg(
      `Hi,\n\nI request you to join ${tenantName} on Skopic. It is quick and easy to Sign Up.\n\nClick the link below to join ${tenantName}.\n${process.env.REACT_APP_SERVICES_API}user/communityurl.html?tid=${tenantId}\n\nThank you,\n${displayName}`
    );
  }, [props.invite, userDetails]);

  const closeHandler = () => {
    setMail("");
    setEmail([]);
    closeModal();
  };

  const closeModal = () => {
    setModalType(null);
    props?.onHide();
    dispatch(updateIsNewUser(false));
  };

  const validateForm = () => {
    if (mail && mail.trim()) { //check for last mail, which present in input field
      if (emailRegex.test(mail)) { //if mail is valid
        return true;
      } else {
        setIsEmailInvalid(true);
        return false;
      }
    } else {
      return email.length > 0;
    }
  };

  const onInviteHandler = async (e) => {
    try {
      e.preventDefault();

      if (!validateForm()) return;
      let allEmails = [...email];
      if (mail && mail.trim()) {
        allEmails = [...allEmails, mail];
      }

      emailsParam = allEmails.join(",");
      const res = await API.sendEmailInvite(emailsParam, textmsg, subject);
      setMsg(res.data);
      setModalType(ModalType.Message);
      setMail("");
      setEmail([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = (toBeRemoved) => {
    setEmail(email.filter((email) => email !== toBeRemoved));
  };

  const isSumbitHandler = () => {
    if (emailRegex.test(mail) || email.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/contacts.readonly&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=https://dev.skopic.com/Home&client_id=1096351929813-b3sqsgi9t95dfbr7jhsruvsfvh8q6iac.apps.googleusercontent.com`;

  const handleAuthRequest = () => {
    window.location.href = googleAuthUrl;
  };

  const onFetchGmailContacts = () => {
    // setModalType(ModalType.Gmail);
    handleAuthRequest();
  };

  const responseFacebook = (response) => {
    if (response?.accessToken) {
      setFbAccessToken(response.accessToken);
      setModalType(ModalType.Facebook);
    } else {
      console.error("Facebook login failed");
    }
  };

  const isEnabaled = isSumbitHandler();

  return (
    <>
      <Modal
        {...props}
        show={modalType === ModalType.Invite}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="InviteStyles"
        backdrop="static"
      >
        <div className="Invitation-modal">
          <Modal.Header closeButton onClick={closeHandler}>
            <Modal.Title
              style={{ marginTop: isNewUser ? "15px" : "0px" }}
              id="contained-modal-title-vcenter"
              className="title"
            >
              {isNewUser
                ? "You have successfully created your account on Skopic! Engage and make a difference in your favorite Communities."
                : "Invite members"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={onInviteHandler}>
              <div className="Invitaion">
                <div className="InvitationEmail">
                  <label className="mar-lft-1rem">Email:</label>
                  <input
                    type="text"
                    value={mail}
                    onChange={emailChange}
                    onKeyDown={handleKeyDown}
                    maxLength="50"
                  />
                  {isEmailInvalid && <p style={{ color: "red", fontSize: 11, padding: 0, margin: 0 }}>Invalid email address</p>}
                  {email.map((mail) => (
                    <div key={mail} className="multipleEmail">
                      {mail}
                      <button
                        type="button"
                        className="button"
                        onClick={() => handleDelete(mail)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
                <div className="InvitationSubject">
                  <label className="mar-lft-1rem">Subject:</label>
                  <input type="text" value={subject} />
                </div>
                <div className="InvitationTextaera">
                  <label className="mar-lft-1rem">Message:</label>
                  <div>
                    <textarea rows="4" cols="50" defaultValue={inviteMsg} />
                  </div>
                </div>
                <div class="invite-align-parent">
                  <button
                    type="submit"
                    className="Invite-align"
                    disabled={!isEnabaled}
                  >
                    Invite
                  </button>
                </div>
              </div>
            </form>
            <p className="Change">or</p>
            <div className="InviteOptionsContainer_align">
              {/* <a
                href="https://dev.skopic.com/skopicportal/gmail/getGmailContactRedirectUrl.html?contacts=true"
                target="_blank"
              > */}
              <button
                className="GmailContacts_align"
                onClick={onFetchGmailContacts}
              >
                <img src={gmail} alt="gmailIcon" />
                <span> Invite Gmail contacts</span>
              </button>
              {/* </a> */}
              {/* <FacebookLogin
                appId={fbappId}
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                render={(renderProps) => (
                  <button
                    className="FacebookContacts_align"
                    onClick={renderProps.onClick}
                  >
                    <img src={facebook} alt="facebookIcon" />
                    <span>Share on Facebook</span>
                  </button>
                )}
              /> */}
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {modalType === ModalType.Message && (
        <MessageModal
          show={modalType === ModalType.Message}
          onHide={closeModal}
          message={msg}
        />
      )}

      {/* {modalType === ModalType.Gmail && (
        <GmailContactsModal
          open={modalType === ModalType.Gmail}
          handleClose={closeModal}
        />
      )} */}

      {/* {modalType === ModalType.Facebook && (
        <FacebookPost
          open={modalType === ModalType.Facebook}
          handleClose={closeModal}
          accessToken={fbAccessToken}
        />
      )} */}
    </>
  );
}

export default Invitation;
