import React, { useState, useEffect, useRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import profileimage from "src/Assets/images/profileimage.png";
import styles from "src/Assets/css/home/post.module.css";
import locationpinchildpost from "src/Assets/images/locationpinchildpost.png";
import * as feedactions from "../../store/actions/feedactions/feedActionCreator";
import { useSelector, useDispatch } from "react-redux";
import * as childSayActions from "../../store/actions/searchactions/childsayActionCreator";
import LocationTag from "../Reusuablecomponents/LocationTag";
import * as SignInActions from "../../store/actions/SignInActions/SignInAction";
import API from "../../network";
import RestrictionMsgModal from "src/Components/RestrictionMsgModal";
import AvatarBadge from "src/Components/AvatarBadge";
import EditLocation from "../../Utils/editLocation";
import { ignoreSpacesAndUrlsFromMsg } from "src/Utils/commonUtils";

const PostMessageValidation = (props) => {
  const { increaseSayCount, updateSayCount, fetchContributors, latitude, longitude, locationAddress } = props;
  const [isInputData, setInputData] = React.useState(false);
  const dispatch = useDispatch();
  const [isButtonEnable, setButtonEnable] = React.useState(false);
  const [setLocationSelect, isLocationSelect] = React.useState("");
  const [isLat, setLat] = React.useState("");
  const [isLng, setLng] = React.useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [tagRestModalMsg, setTagRestModalMsg] = useState("");
  const [charCount, setCharCount] = useState(0);
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);

  const [location, setLocation] = useState({
    selectedAddress: locationAddress,
    latitude: latitude,
    longitude: longitude,
  });

  const isReadOnly = props.readOnly || false;

  let isUserLoggedIn = false;
  if (userDetails && Object.keys(userDetails).length !== 0) {
    isUserLoggedIn = true;
  }

  const inputRef = useRef(null);

  const handleInput = (e) => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${e.target.scrollHeight - 16}px`;
    }
  };

  useEffect(() => {
    const msg = props?.Message || "";
    const { msgLength } = ignoreSpacesAndUrlsFromMsg(msg);
    setCharCount(msgLength);
  }, [props.Message]);

  const handleLocationUpdate = (address, lat, lng) => {
    setLocation({
      selectedAddress: address,
      latitude: lat,
      longitude: lng,
    });
  };

  // var newLine = "\r\n\r\n"
  // // var mybr = document.createElement('br');
  // var data1 = [];
  // var data2 = [];
  var TotalLetters = 280;
  // var j = 0;
  // var strArray = [];
  var inputDataLen;

  const ignoreSpaceandUrls = (mstrData, txtId) => {
    let inputData = mstrData;

    if (inputData.length !== 0) {
      setInputData(true);
    } else {
      setInputData(false);
    }
    inputDataLen = inputData.length;
    setCharCount(inputData.length);
    // var commas;
    // var dsr = navigator.userAgent.split("MSIE");
    let inputText = document.getElementById(txtId).value;
    inputText = inputText.replace(/\n/g, " ");
    let strArray = inputText.split(" ");
    let validlettercount = 0;
    let i;
    for (i = 0; i < strArray.length; i++) {
      if (
        strArray[i].indexOf("http://") == -1 &&
        strArray[i].indexOf("www.") == -1 &&
        strArray[i].indexOf("https://") == -1 &&
        strArray[i].indexOf("HTTP://") == -1 &&
        strArray[i].indexOf("WWW.") == -1 &&
        strArray[i].indexOf("HTTPS://") == -1
      ) {
        validlettercount = validlettercount + strArray[i].length;
      } else if (
        strArray[i].indexOf("http://") &&
        strArray[i].indexOf("www.") &&
        strArray[i].indexOf("https://") &&
        strArray[i].indexOf("HTTP://") &&
        strArray[i].indexOf("WWW.") &&
        strArray[i].indexOf("HTTPS://")
      ) {
        validlettercount = validlettercount + strArray[i].length;
      }
    }
    inputDataLen = validlettercount;
  };

  const myFunction = (id) => {
    setErrorMessage("");
    let inputValue;
    let saveEditChildSay;

    let letterCounter;
    // let sayButton = `buttonforchildsay${id}`;
    let saveButton;
    // console.log(props.messageType);
    if (props.messageType === "feedTextArea") {
      inputValue = `textInput${id}`;
      letterCounter = `letterCounterEdit${id}`;
      saveButton = `buttonforEdit${id}`;
      // saveEditChildSay = document.getElementById(saveButton);
    } else if (props.messageType === "sendaMail") {
      inputValue = `textInputMail${id}`;
      letterCounter = `letterCounterMail${id}`;
      saveButton = `buttonforEdit${id}`;
    } else if (props.messageType === "editBio") {
      inputValue = `bioInput${id}`;
      letterCounter = `letterCounterBio${id}`;
      saveButton = `buttonforBioSave${id}`;
    } else {
      inputValue = `textInputPost${id}`;
      letterCounter = `letterCounterSay${id}`;
      saveButton = `buttonforchildsay${id}`;
      // saveEditChildSay = document.getElementById(saveButton);
    }
    saveEditChildSay = document.getElementById(saveButton);
    // var say_ButtonId=document.getElementById(sayButton)

    let letters = document.getElementById(inputValue).value;
    // ignoreSpaceandUrls(letters, inputValue);
    const { msgLength, hasMessage } = ignoreSpacesAndUrlsFromMsg(letters);
    setInputData(hasMessage);
    setCharCount(msgLength);
    // if (document.getElementById(letterCounter) !== null) {
    //   var label_count = document.getElementById(letterCounter);
    //   label_count.innerText = TotalLetters - inputDataLen;
    // }
    const chCount = msgLength;
    if (chCount.length != 0 && hasMessage) {
      if (document.getElementById(letterCounter) !== null) {
        document.getElementById(letterCounter).style.color =
          chCount > TotalLetters ? "red" : "black";
      }

      if (chCount > TotalLetters) {
        if (saveEditChildSay !== null) {
          saveEditChildSay.style.disabled = true;
          saveEditChildSay.style.backgroundColor = "lightgray";
        }
        setButtonEnable(false);
      } else {
        if (saveEditChildSay !== null) {
          const msgType = props.messageType;
          saveEditChildSay.style.disabled = false;
          saveEditChildSay.style.backgroundColor =
            msgType === "feedTextArea" ||
            msgType === "sendaMail" ||
            msgType === "editBio"
              ? "#2181ce"
              : "#36B549";
        }
        setButtonEnable(true);
      }
    }
  };

  const submit = async (id) => {
    try {
      increaseSayCount && increaseSayCount(id);
      let inputValue = `textInputPost${id}`;
      let lettersCount = document.getElementById(inputValue).value;
      let letterCounter = `letterCounterSay${id}`;
      // const newListData = listData.map((item) => {
      //   if (item.id === id) {
      //     return { ...item, sayCount: item.sayCount + 1 };
      //   } else {
      //     return item;
      //   }
      // });
      // setListData(newListData);

      if (isInputData && isButtonEnable) {
        let lnglat;
        if ((isLat !== "") & (isLng !== "")) {
          lnglat = isLat + "," + isLng;
        } else {
          lnglat = "";
        }
        const response = await props.postChildSayData(
          id,
          lettersCount,
          lnglat,
          setLocationSelect
        );
        if (response === "New Hash") {
          setErrorMessage("You can't create a New hash tag");
        }
        setLat("");
        setLng("");
        isLocationSelect("");

        //TODO: fetchChildSaysData should be removed once subscriber is added
        //Subscriber should add new entry to existing childSays list
        // setTimeout(function () {
        //   // dispatch(childSayActions.fetchChildSayData(`?id=${id}`));
        //   props.fetchChildSaysData(id);
        // }, 500);
        updateSayCount && updateSayCount(id);
        // dispatch(childSayActions.fetchChildSayData(`?id=${id}&in_pop_columns=all&in_limit=0`))
        document.getElementById(inputValue).value = "";
        setTimeout(() => {
          fetchContributors && fetchContributors();
          dispatch(SignInActions.fetchloginUser());
        }, 1000);
        // document.getElementById(letterCounter).innerText = TotalLetters;
        setInputData("");
        setCharCount(0);
        // console.log(setLocationSelect,isLat,isLng)
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onCancelSelection = (id) => {
    setErrorMessage("");
    let inputText;
    let letterCounter;
    let postMessage;
    let buttonforEdit;
    let buttonforCancel;
    if (props.messageType === "editBio") {
      inputText = `bioInput${id}`;
      letterCounter = `letterCounterBio${id}`;
      postMessage = `postBioMessage${id}`;
      buttonforEdit = `buttonforBioSave${id}`;
      buttonforCancel = `buttonforBioCancel${id}`;
    } else {
      inputText = `textInput${id}`;
      letterCounter = `letterCounterEdit${id}`;
      postMessage = `postMessage${id}`;
      buttonforEdit = `buttonforEdit${id}`;
      buttonforCancel = `buttonforCancel${id}`;
    }
    // let buttonforEdit = `buttonfor${id}`

    props?.onCloseEdit && props?.onCloseEdit();
    props?.setEditBio && props?.setEditBio(false);

    // if (document.getElementById(inputText).style.display === "none") {
    //   document.getElementById(inputText).style.display = "block";
    //   document.getElementById(buttonforEdit).style.display = "block";
    //   document.getElementById(buttonforCancel).style.display = "block";
    //   document.getElementById(letterCounter).style.display = "block";
    //   if (props.messageType === "feedTextArea") {
    //     document.getElementById(postMessage).style.display = "none";
    //   }
    // } else {
    //   document.getElementById(inputText).style.display = "none";
    //   document.getElementById(buttonforEdit).style.display = "none";
    //   document.getElementById(buttonforCancel).style.display = "none";
    //   document.getElementById(letterCounter).style.display = "none";
    //   if (props.messageType === "feedTextArea") {
    //     document.getElementById(postMessage).style.display = "block";
    //   }
    // }
  };

  const onSendaMail = async (id) => {
    let inputValue = `textInputMail${id}`;
    let letterCounter = `letterCounterMail${id}`;
    let lettersCount = document.getElementById(inputValue).value;
    // dispatch(
    //   feedactions.postaMailData(
    //     `?mid=${id}&comment=${lettersCount}&checkHsh=message`
    //   )
    // );
    await API.postMail(id, lettersCount);
    document.getElementById(inputValue).value = "";
    document.getElementById(letterCounter).innerText = TotalLetters;
    props.onClose();
  };

  const getWordWithHash = (sentence) => {
    const regex = /(?:^|\s)(#\w+)\b/g;

    // Find all matches in the sentence
    const matches = sentence.match(regex);

    // Return the array of matched words (or an empty array if no matches)
    return matches ? matches.map((match) => match.trim()) : [];
  };

  const updateActivityAsksTags = async (id) => {
    let inputValue = `textInput${id}`;
    let lettersCount = document.getElementById(inputValue).value;

    if (props?.Message === lettersCount) {
      setErrorMessage("At least one modification required");
      return;
    }

    //checking whether post is TAG or not
    if (props?.filtertype === "activityTag") {
      const previousHashArr = getWordWithHash(props?.Message || "");
      const previousHash =
        Array.isArray(previousHashArr) && previousHashArr.length > 0
          ? previousHashArr[0]
          : "";

      const currentHashArr = getWordWithHash(lettersCount || "");
      const currentHash =
        Array.isArray(currentHashArr) && currentHashArr.length > 0
          ? currentHashArr[0]
          : "";

      if (previousHash !== currentHash) {
        setErrorMessage("You can't create a New hash tag");
        return;
      }
    }

    try {
      let refresh = false;
      const { id: messageId, locName, msgLat, msgLng } = props?.post || {};
      const locationName = locName || "";
      const msgLoc = msgLat && msgLng ? `${msgLat || ""},${msgLng || ""}` : "";
      let formData = new FormData();
      formData.append("messageID", messageId);
      formData.append("message", lettersCount);
      const resp = await API.updateProfilePost(locName, formData);
      const status = resp?.data?.status || "";
      refresh = checkForHash(status);
      if (refresh) {
        props?.onMessagedUpdated && props?.onMessagedUpdated(id, lettersCount);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onEditPost = async (id) => {
    if (
      props?.filtertype === "activityTag" ||
      props?.filtertype === "activityAsk" ||
      props?.filtertype === "activityUpdate" ||
      props?.filtertype === "activitySay"
    ) {
      updateActivityAsksTags(id);
      return;
    }

    let refresh = true;
    let inputValue = `textInput${id}`;
    let letterCounter = `letterCounterEdit${id}`;
    let lettersCount = document.getElementById(inputValue).value;

    if (props?.Message === lettersCount && locationAddress === location?.selectedAddress) {
      setErrorMessage("At least one modification required");
      return;
    }

    const formdata = new FormData();
    if (location?.latitude && location?.longitude) {
      const lat = `${location.latitude},${location.longitude}`;
      formdata.append("msgLoc", lat);
    }
    if (location?.selectedAddress) {
      formdata.append("locName", location.selectedAddress);
    }

    formdata.append("userLoc", "");

    //checking whether post is TAG or not
    let isTag = false;
    const { message_type, Keyword_ID } = props?.post || {};
    if (
      props?.filtertype === "hashtag" ||
      message_type === "H" ||
      message_type === "X" ||
      Keyword_ID === "hashTAG"
    ) {
      isTag = true;
      const previousHashArr = getWordWithHash(props?.Message || "");
      const previousHash =
        Array.isArray(previousHashArr) && previousHashArr.length > 0
          ? previousHashArr[0]
          : "";

      const currentHashArr = getWordWithHash(lettersCount || "");
      const currentHash =
        Array.isArray(currentHashArr) && currentHashArr.length > 0
          ? currentHashArr[0]
          : "";

      if (previousHash !== currentHash) {
        setErrorMessage("You can't create a New hash tag");
        return;
      }
    }

    try {
      if (isTag) {
        const { id: messageId, locName, msgLat, msgLng } = props?.post || {};
        let locationName;
        let msgLoc;
        let userLoc = "";
          if (location?.latitude && location?.longitude) {
            const lat = `${location.latitude},${location.longitude}`;
            msgLoc = lat;
          }
          if (location?.selectedAddress) {
            locationName = location.selectedAddress;
          }
        let formData = new FormData();
        formData.append("messageID", messageId);
        formData.append("message", lettersCount);
        const resp = await API.editPrivateTag(
          formData,
          userLoc,
          msgLoc,
          locationName
        );
        const status = resp?.data?.status || "";
        refresh = checkForHash(status);
      } else if (props.filtertype === "update") {
        formdata.append("announceID", id);
        formdata.append("message", lettersCount);
        // await API.editUpdate(id, lettersCount);
        await API.editUpdateLocation(formdata);
      } else {
        formdata.append("messageID", id);
        formdata.append("message", lettersCount);
        await API.editPostLocation(formdata);
        // await API.editPost(formdata);
      }
      //TODO: Once subscriber logic is added, remove this api call
      //subscriber should modify this edited record in existing list of feed
      if (refresh) {
        props.refreshFeedData &&
          props.refreshFeedData(props.parentId ? true : false, props.parentId);
      }
      // props.fetchFeedData(props.filtertype);
    } catch (e) {
      console.log(e);
    }

    // if (props.filtertype === "&&filtertype=update") {
    //   dispatch(
    //     feedactions.editaUpdate(
    //       `?announceID=${id}&message=${lettersCount}&ident=actUpdate&argval=share&timeZone=IST`
    //     )
    //   );
    // } else {
    //   dispatch(
    //     feedactions.editaPost(`?messageID=${id}&message=${lettersCount}`)
    //   );
    // }

    // dispatch(feedactions.fetchFeedData(`?startlimit=0${props.filtertype}`));
    // document.getElementById(inputValue).value = "";
    // document.getElementById(letterCounter).innerText = TotalLetters;
  };

  const checkForHash = (status) => {
    if (String(status).toLowerCase() === "new hash") {
      setTagRestModalMsg("You can't create New #TAG");
      return false;
    } else if (String(status).includes("#")) {
      //example- status: "Please retain #sometag"
      setTagRestModalMsg(status);
      return false;
    }
    return true;
  };

  const onBioUpdate = async (id) => {
    let editBioInputValue = `bioInput${id}`;
    let editBioLetterCounter = `letterCounterBio${id}`;
    let editBioLetterCount = document.getElementById(editBioInputValue).value;
    // dispatch(feedactions.fetchEditBio(`shortBio=${editBioLetterCount}`));

    if (props?.Message === editBioLetterCount) {
      setErrorMessage("At least one modification required");
      return;
    }

    await API.editBio(editBioLetterCount);
    dispatch(SignInActions.fetchloginUser());

    document.getElementById(editBioInputValue).value = "";
    document.getElementById(editBioLetterCounter).innerText = TotalLetters;

    props?.setEditBio && props?.setEditBio(false);
  };

  return (
    <div key={props.id}>
      {/* <textarea rows="2" cols="50" id={`textInput${props.id}`} onKeyUp={() => myFunction(props.id)}></textarea> */}
      <div className={styles.sendaMailModel}>
        {props.messageType === "feedTextArea" ? (
          <>
            <div className={styles.textareaContainer}>
              <textarea
                rows="2"
                cols="50"
                id={`textInput${props.id}`}
                onInput={() => myFunction(props.id)}
                onFocus={() => myFunction(props.id)}
                defaultValue={props.Message}
                className={styles.EditPostMessage}
              ></textarea>
              <div className={styles.editLocationIcon}>
                <EditLocation
                  postLattitude={props.latitude}
                  postLongitude={props.longitude}
                  onLocationChange={handleLocationUpdate}
                />
              </div>
            </div>
            <p
              dangerouslySetInnerHTML={{ __html: props.Message }}
              className={styles.postMessage}
              id={`postMessage${props.id}`}
            ></p>
          </>
        ) : props.messageType === "sendaMail" ? (
          <textarea
            rows="4"
            cols="50"
            className={styles.sendamailtextarea}
            id={`textInputMail${props.id}`}
            onInput={() => myFunction(props.id)}
            onFocus={() => myFunction(props.id)}
            placeholder={`What would you like to say to ${props.displayName}`}
          ></textarea>
        ) : props.messageType === "editBio" ? (
          <>
            <textarea
              rows="2"
              className={`${styles.editBioTextArea} ${
                props.isEditBio ? styles.editBioInfo : styles.editBioInfoNone
              }`}
              cols="20"
              id={`bioInput${props.id}`}
              onInput={() => myFunction(props.id)}
              onFocus={() => myFunction(props.id)}
              defaultValue={props.Message}
            ></textarea>
            {/* <p id={`postBioMessage${props.id}`} className={styles.postMessage} onClick={() => onBioSelection(props.id)}>
                                        {props.Message}
                                    </p> */}
          </>
        ) : (
          <>
            {isUserLoggedIn ? (
              <>
                <AvatarBadge
                  src={props.userImageSrc}
                  variant="square"
                  isPrimaryModerator={
                    userDetails.userData.isCurrentCommunityPm == 1
                  }
                  isModerator={
                    userDetails.userData.moderatorStatus == 1 &&
                    userDetails.userData.isCurrentCommunityPm == 0
                  }
                />
                <div style={{ width: "100%" }}>
                  {/* <input
                    className={styles.commentinput}
                    placeholder="Say something..."
                    type="text"
                    id={`textInputPost${props.id}`}
                    onInput={() => myFunction(props.id)}
                  /> */}
                  <textarea
                    ref={inputRef}
                    className={styles.commentinput}
                    placeholder="Say something..."
                    id={`textInputPost${props.id}`}
                    onInput={(e) => {
                      myFunction(props.id);
                      handleInput(e);
                    }}
                    readOnly={isReadOnly}
                    style={{ cursor: isReadOnly ? "not-allowed" : "text" }}
                  />
                </div>
                {/* <a className={styles.locationpinchildpost}><img src={locationpinchildpost} /></a> */}
                <LocationTag
                  isLocationSelect={isLocationSelect}
                  setLat={setLat}
                  setLng={setLng}
                  id={props.id}
                  setIsHide={() => {}}
                />
              </>
            ) : null}
          </>
        )}
      </div>
      {errorMessage && (
        <div style={{ fontSize: 12, color: "red" }}>{errorMessage}</div>
      )}
      <div
        className={styles.postbuttonandlabel}
        style={{ minWidth: props?.minWidth }}
      >
        {props.messageType === "feedTextArea" ? (
          isInputData ? (
            <>
              <button
                className={styles.editPost}
                id={`buttonforEdit${props.id}`}
                onClick={() => onEditPost(props.id)}
              >
                SAVE
              </button>
              <a
                onClick={() => onCancelSelection(props.id)}
                id={`buttonforCancel${props.id}`}
              >
                Cancel
              </a>
              <label id={`letterCounterEdit${props.id}`}>
                {charCount}
                <label style={{ color: "black" }}>/280</label>
              </label>
            </>
          ) : (
            ""
          )
        ) : props.messageType === "sendaMail" ? (
          <>
            <button
              id={`buttonforEdit${props.id}`}
              className={styles.sendaMail}
              onClick={() => onSendaMail(props.id)}
              disabled={charCount <= 0}
            >
              Send
            </button>
            <label id={`letterCounterMail${props.id}`}>
              {charCount}
              <label style={{ color: "black" }}>/280</label>
            </label>
          </>
        ) : props.messageType === "editBio" ? (
          <>
            <button
              id={`buttonforBioSave${props.id}`}
              onClick={() => onBioUpdate(props.id)}
              className={`${styles.editbioSave} ${
                props.isEditBio ? styles.editBioInfo : styles.editBioInfoNone
              }`}
            >
              Save
            </button>
            <a
              onClick={() => onCancelSelection(props.id)}
              id={`buttonforBioCancel${props.id}`}
              className={
                props.isEditBio ? styles.editBioInfo : styles.editBioInfoNone
              }
            >
              Cancel
            </a>
            <label
              id={`letterCounterBio${props.id}`}
              className={
                props.isEditBio ? styles.editBioInfo : styles.editBioInfoNone
              }
              style={{ margin: "0px 0px 0px 5px" }}
            >
              {charCount}
              <label style={{ color: "black" }}>/280</label>
            </label>
          </>
        ) : (
          <>
            {isInputData ? (
              <button
                className={styles.childpost}
                id={`buttonforchildsay${props.id}`}
                onClick={() => submit(props.id)}
                disabled={charCount === 0}
                style={{
                  cursor: charCount === 0 ? "not-allowed" : "pointer",
                  opacity: charCount === 0 ? 0.5 : 1,
                }}
              >
                POST
              </button>
            ) : (
              <span></span>
            )}
            {isUserLoggedIn && (
              <label id={`letterCounterSay${props.id}`}>
                {charCount}
                <label style={{ color: "black" }}>/280</label>
              </label>
            )}
          </>
        )}
        {/* <label id={`letterCounter${props.id}`}>280</label> */}
      </div>
      {/* <button onClick={() => submit} id={`saybutton${props.id}`}>Say</button><br /> */}
      {/* <label id={`letterCounter${props.id}`}>280</label> */}
      {/* <p id={`content${props.id}`}></p><br /><br /> */}
      {!!tagRestModalMsg && (
        <RestrictionMsgModal
          open={!!tagRestModalMsg}
          onClose={() => setTagRestModalMsg("")}
          message={tagRestModalMsg}
        />
      )}
    </div>
  );
};

export default PostMessageValidation;
