import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import * as Cookies from "js-cookie";
// import axios from "axios";
import Edit from "src/Assets/images/edit.svg";
import { fetchProfileData } from "src/store/actions/EditProfile/EditProfileActions";
import Api from "src/network";
import { genderOptions, qualificationOptions, months } from "../constants";
import style from "./Otherinfo.module.css";

const schema = yup.object().shape({
  gender: yup.string().typeError("Select gender").nullable(),
  qualification: yup.string().typeError("Select qualification").nullable(),
  occupation: yup.string().typeError("Enter valid occupation").nullable(),
  day: yup.string().typeError("Select date").nullable(),
  month: yup.string().typeError("Select month").nullable(),
  year: yup.string().typeError("Select year").nullable(),
  interests: yup.string().typeError("Enter valid interests").nullable(),
});

const OtherInfo = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const profileInfoData = useSelector(
    (state) => state.EditProfileReducer.profileInfo
  );

  const [edit, setEdit] = useState(true);
  const [buttonStyle, setButtonStyle] = useState({
    border: 0,
    padding: "20px",
    display: "inline-block",
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      gender: "",
      qualification: "",
      occupation: "",
      day: "",
      month: "",
      year: "",
      interests: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(fetchProfileData());
  }, [dispatch]);

  useEffect(() => {
    if (profileInfoData) {
      const { gender, qualification, occupation, dateofbirth, interest } =
        profileInfoData || {};

      const dob = dateofbirth?.split("-");
      let day, month, year;
      day = month = year = "";
      if (Array.isArray(dob) && dob.length) {
        day = dob[0];
        month = dob[1];
        year = dob[2];
      }

      reset({
        gender,
        qualification,
        occupation,
        day,
        month,
        year,
        interests: interest,
      });
    }
  }, [profileInfoData, reset]);

  const onEdit = () => {
    setEdit(false);
    setButtonStyle({
      ...buttonStyle,
      display: "none",
    });
  };

  const onUpdateInfo = async (data) => {
    // Handle the save logic
    setEdit(true);
    setButtonStyle({
      border: 0,
      padding: "20px",
      display: "inline-block",
    });

    const { gender, occupation, day, month, year, qualification, interests } =
      data || {};

    // Your existing save logic here
    let formData = new FormData();
    formData.append("occupation", occupation);
    formData.append("dateOfBirth", `${day}-${month}-${year}`);
    formData.append("gender", gender);
    formData.append("interest", interests);
    formData.append("qualification", qualification);
    formData.append("imgpath", "");

    await Api.updateOptionalInfo(formData);
    dispatch(fetchProfileData());
  };
  const formattedDateOfBirth =
    profileInfoData?.dateofbirth &&
    profileInfoData.dateofbirth !== "-undefined-undefined"
      ? profileInfoData.dateofbirth
      : null;

  return (
    <div ref={ref}>
      <form onSubmit={handleSubmit(onUpdateInfo)}>
        {profileInfoData && profileInfoData.length !== 0 && (
          <>
            <div className={style.basicinfo}>
              <div className={style.infoDetals}>
                <h3 className={style.infoHead}>Other Information</h3>
                <button
                  type="button"
                  style={buttonStyle}
                  onClick={onEdit}
                  className={style.EditButton}
                >
                  <img src={Edit} alt="Edit" />
                </button>
              </div>
              {edit ? (
                <div className={style.BasicDetails}>
                  {/* Display existing data */}
                  <p>
                    <b>Gender:</b> {profileInfoData.gender}
                  </p>
                  <p>
                    <b>Qualifications:</b> {profileInfoData.qualification}
                  </p>
                  <p>
                    <b>Occupation:</b> {profileInfoData.occupation}
                  </p>
                  <p>
                    <b>Date of Birth:</b> {formattedDateOfBirth || ""}
                  </p>
                  <p>
                    <b>Interests:</b> {profileInfoData.interest}
                  </p>
                </div>
              ) : (
                <div className={style.EditDetails}>
                  <div className={style.EditInputfeild}>
                    <label className={style.EditName}>Gender:</label>
                    <Controller
                      control={control}
                      name="gender"
                      render={({ field: { onChange, value } }) => (
                        <select
                          className={style.EditSelectFeild}
                          value={value}
                          onChange={onChange}
                        >
                          {genderOptions.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {errors?.gender && (
                      <p className={style.errorMsg}>
                        {errors?.gender?.message}
                      </p>
                    )}
                  </div>
                  <div className={style.EditTextfeild}>
                    <label className={style.EditName}>Qualification:</label>
                    <Controller
                      control={control}
                      name="qualification"
                      render={({ field: { onChange, value } }) => (
                        <select
                          className={style.EditSelectFeild}
                          value={value}
                          onChange={onChange}
                        >
                          {qualificationOptions.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {errors?.qualification && (
                      <p className={style.errorMsg}>
                        {errors?.qualification?.message}
                      </p>
                    )}
                  </div>
                  <div className={style.EditInputfeild}>
                    <label className={style.EditName}>Occupation:</label>
                    <Controller
                      control={control}
                      name="occupation"
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="text"
                          placeholder="Occupation"
                          value={value}
                          onChange={onChange}
                          maxLength={50}
                        />
                      )}
                    />
                    {errors?.occupation && (
                      <p className={style.errorMsg}>
                        {errors?.occupation?.message}
                      </p>
                    )}
                  </div>
                  <div className={style.EditInputfeild}>
                    <label className={style.EditName}>Date of Birth:</label>
                    <Controller
                      control={control}
                      name="day"
                      render={({ field: { onChange, value } }) => (
                        <select
                          className={style.EditSubSelectFeild}
                          value={value}
                          onChange={onChange}
                        >
                          {Array.from({ length: 31 }, (val, index) => (
                            <option key={`${val}-${index}`} value={index + 1}>
                              {index + 1}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    <Controller
                      control={control}
                      name="month"
                      render={({ field: { onChange, value } }) => (
                        <select
                          className={style.EditSubSelectFeild}
                          value={value}
                          onChange={onChange}
                        >
                          {months.map((month, _) => (
                            <option key={month} value={month}>
                              {month}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    <Controller
                      control={control}
                      name="year"
                      render={({ field: { onChange, value } }) => (
                        <select
                          className={style.EditSubSelectFeild}
                          value={value}
                          onChange={onChange}
                        >
                          {Array.from({ length: 100 }, (val, index) => (
                            <option
                              key={`${val}-${index}`}
                              value={new Date().getFullYear() - index}
                            >
                              {new Date().getFullYear() - index}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {(errors?.day || errors?.month || errors?.year) && (
                      <p className={style.errorMsg}>
                        {errors?.day?.message} {errors?.month?.message}{" "}
                        {errors?.year?.message}
                      </p>
                    )}
                  </div>
                  <div className={style.EditTextfeild}>
                    <label className={style.EditName}>Interests:</label>
                    <Controller
                      control={control}
                      name="interests"
                      render={({ field: { onChange, value } }) => (
                        <textarea
                          rows="4"
                          cols="50"
                          value={value}
                          onChange={onChange}
                          maxLength={280}
                        />
                      )}
                    />
                    {errors?.interests && (
                      <p className={style.errorMsg}>
                        {errors?.interests?.message}
                      </p>
                    )}
                  </div>
                  <button type="submit" className={style.SaveButton}>
                    Save
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </form>
    </div>
  );
});

export default OtherInfo;
