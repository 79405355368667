import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "src/Assets/css/ReusuableComponents/AutoCompleteTextField.css";
import "src/Assets/css/ReusuableComponents/CalendarComponent.css";
import styles from "src/Assets/css/home/post.module.css";
import CloseIcon from "@mui/icons-material/Close";
import calenderIcon from "src/Assets/images/Calendaricon.png";
import calenderBlueIcon from "src/Assets/images/Path 542 blue.png";
import { outlinedInputClasses } from "@mui/material";

function CalendarComponent() {
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false); // Update state for tracking calendar open/close

  const handleDateTimeChange = (e) => {
    setSelectedDateTime(e.value);
  };

  const changeCalenderState = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const header = () => (
    <div className={styles.calendarHeaderText}>
      <p>
        Set Reminder
      </p>
    </div>
  );

  const currentDate = new Date();

  return (
    <div className="p-d-flex p-ai-start">
      {/* <h1>Select Date and Time</h1> */}
      <div
      // className="p-ml-2"
      // style={{
      //   zIndex: "1051",
      //   minWidth: "185px",
      //   transformOrigin: "center bottom",
      //   top: "0px",
      //   left: "563.75px",
      // }}
      >
        <Calendar
          headerTemplate={header}
          value={selectedDateTime}
          onChange={handleDateTimeChange}
          showTime={true}
          hourFormat="12"
          dateFormat="dd-M-yy"
          baseZIndex={1051}
          inputStyle={{ display: "none" }}
          showIcon={true}
          minDate={currentDate}
          icon={
            <img
              src={isCalendarOpen ? calenderBlueIcon : calenderIcon}
              alt="calenderIcon"
              onClick={changeCalenderState}
            />
          }
        />
      </div>
      {selectedDateTime && (
        <div className="p-d-inline-flex align-items-start">
          <p className={styles.dateTime}>
            {selectedDateTime.toLocaleString([], { day: "2-digit" })}-
            {selectedDateTime.toLocaleString([], { month: "short" })}-
            {selectedDateTime.toLocaleString([], { year: "numeric" })}{" "}
            {selectedDateTime.toLocaleString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
          <button
            type="button"
            class="close"
            onClick={() => setSelectedDateTime(null)}
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <span
              aria-hidden="true"
              style={{
                fontWeight: "500",
                fontSize: "inherit",
                color: "black",
                marginLeft: "2px",
              }}
            >
              <CloseIcon
                sx={{
                  backgroundColor: "#F3F2F0",
                  borderRadius: "50%",
                  color: "black !important",
                  fontSize: "22px",
                  marginTop: "8%",
                  marginLeft: "8px",
                  padding: "2px",
                }}
              />
            </span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default CalendarComponent;
