import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./Invitations/invitations.css";
import { Checkbox, InputAdornment } from "@mui/material";
import Searchicon from "src/Assets/images/search.svg";
import Input from "@material-ui/core/Input";
import MessageModal from "../../../Utils/messageModal";
import API from "../../../network";
import "./index.css";

function InviteFollowers(props) {
  // const {
  //   fetchPrivateTagsData,
  //   // privateTagsData: data = {},
  // } = props;
  const [isInvited, setInvite] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [messageShow, setMessageShow] = useState(false);
  const [messageId, setMessageId] = useState(null);
  const [response, setResponse] = useState("");
  const [communityMembersList, setCommunityMembersList] = useState([]);
  const [filteredCommunityMembersList, setFilteredCommunityMembersList] =
    useState([]);
  const src = process.env.REACT_APP_SKOPIC_IMAGE;

  // useEffect(() => {
  //   if (props.invite) {
  //     fetchPrivateTagsData(props.tenantid, true);
  //   }
  // }, [props.invite]);

  // useEffect(() => {
  //   const allPrivateTags = data.privateTagList;
  //   const privateTagData = allPrivateTags && allPrivateTags.length > 0 ? allPrivateTags.find((item) => item.messageId === props.msgid) : {};
  //   const msgId = privateTagData?.messageId;
  //   setMessageId(msgId);
  //   if (msgId) {
  //     let data = new FormData();
  //     data.append("messageId", msgId);
  //     getCommunityMembersAPI(data)
  //   }
  // }, [props.privateTagsData]);

  useEffect(() => {
    setMessageId(props.msgid);
  }, [props.msgid]);

  useEffect(() => {
    if (messageId) {
      let data = new FormData();
      data.append("messageId", messageId);
      getCommunityMembersAPI(data);
    }
  }, [messageId]);

  const getCommunityMembersAPI = async (request) => {
    const response = await API.getCommunityMembers(request);
    setCommunityMembersList(response.data.communityMemberList);
    setFilteredCommunityMembersList(response.data.communityMemberList);
  };

  const handleChange = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setInvite((invites) => [...invites, Number(id)]);
    } else {
      setInvite(isInvited.filter((invitedId) => invitedId !== Number(id)));
    }
  };

  const handleInviteAll = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      communityMembersList.map((member) => {
        setInvite((invites) => [...invites, member.User_ID]);
      });
    } else {
      setInvite([]);
    }
  };

  const handleSearchInput = (e) => {
    if (e.target.value === "") {
      setFilteredCommunityMembersList([...communityMembersList]);
    } else {
      const result = communityMembersList.filter((member) => {
        return member.displayName
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase());
      });
      setFilteredCommunityMembersList(result);
    }
  };

  const closeInviteHandler = () => {
    setMessageShow(false);
    props.onHide();
    setResponse("");
  };

  const onInviteHandler = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append("messageId", messageId);
    data.append("tenantId", props.tenantid);
    data.append("userIds", isInvited.toString());
    data.append("inviteType", "invite");

    API.sendInvite(data).then((res) => {
      setResponse(res.data.status);
      setMessageShow(true);
      setInvite([]);
      setFilteredCommunityMembersList([...communityMembersList]);
      setSelectAll(false);
    });
  };

  if (messageShow) {
    return (
      <MessageModal
        show={messageShow}
        onHide={() => closeInviteHandler()}
        message={response}
      />
    );
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        arialabelledby="contained-modal-title-vcenter"
        centered
        className="InviteStyles"
      >
        <div className="Invitation-modal">
          <Modal.Header
            // closeButton={!props?.hideCloseButton}
            // onClick={props?.hideCloseButton ? null : props.onHide}
          >
            <Modal.Title id="contained-modal-title-vcenter" className="title">
              <h1 className="invite-member-heading">Invite members</h1>
              <button
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "25px",
                  fontWeight: "600",
                  cursor: "pointer",
                  position: "absolute",
                  right: "15px",
                  top: "8px",
                  outline: "none", // Removes focus outline
                }}
                onClick={props?.hideCloseButton ? null : props.onHide}
              >
                &times;
              </button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {filteredCommunityMembersList &&
            Array.isArray(filteredCommunityMembersList) ? (
              <form>
                <div className="SearchInput">
                  <Input
                    id="search"
                    placeholder="search by name"
                    style={{ fontSize: "15px" }}
                    fullWidth
                    onChange={(e) => handleSearchInput(e)}
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          src={Searchicon}
                          alt="search"
                          style={{ marginLeft: "20px", height: "15px" }}
                        />
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="InviteAllMembers">
                  <label>
                    <input
                      onChange={(e) => handleInviteAll(e)}
                      type="checkbox"
                      id="select-all"
                      label="select-all"
                      checked={selectAll}
                      style={{
                        marginRight: "15px",
                        width: "18px",
                        height: "18px",
                        cursor: "pointer",
                        accentColor: "#1976d2", // Primary color for the checkbox
                        outline: "none !important",
                        borderColor: "#707070",
                        borderRadius: "10px !important",
                      }}
                    />
                    Select All
                  </label>
                </div>
                <div className="MemberList member-list-container">
                  {filteredCommunityMembersList.map((member) => (
                    <div
                      className="InviteMember invite-member-container"
                      key={`key-member-${member?.User_ID}-${member?.email}`}
                    >
                      <label key={member.User_ID}>
                        <input
                          type="checkbox"
                          onChange={(e) => handleChange(e)}
                          id={`${member.User_ID}`}
                          checked={isInvited.includes(member.User_ID)}
                          style={{
                            marginRight: "15px",
                            width: "18px",
                            height: "18px",
                            cursor: "pointer",
                            accentColor: "#1976d2", // Primary color for the checkbox
                            outline: "none !important",
                            borderColor: "#707070",
                            borderRadius: "10px !important",
                          }}
                        />
                        <img
                          src={`${src}${member.userImage}`}
                          alt={member.displayName}
                          className="userImg profile-picture"
                        />
                        {member.displayName}
                      </label>
                    </div>
                  ))}
                </div>
                <div class="invite-align-parent">
                  <button
                    type="button"
                    className={`Invite-align invite-button ${
                      isInvited.length >= 1 ? "enabled" : "disabled"
                    }`}
                    onClick={onInviteHandler}
                    disabled={isInvited.length < 1}
                  >
                    Invite
                  </button>
                </div>
              </form>
            ) : (
              <p className="nodata">No users available</p>
            )}
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default InviteFollowers;
