import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CircularProgress, IconButton } from "@mui/material";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { MdKeyboardArrowDown } from "react-icons/md";
import SimpleDialogDemo from "src/Modules/Reusuablecomponents/CustomizedDialogs";
import ReusuableDeleteDialogmodal from "src/Modules/Reusuablecomponents/ReusuableDeleteModal";
import ReusuableReportDialogmodal from "src/Modules/Reusuablecomponents/ReusuableReportDialogmodal";
import PostMessageValidation from "src/Modules/Reusuablecomponents/PostMessageValidation";
import ReusuableSendaMailModal from "src/Modules/Reusuablecomponents/ReusuableSendaMailModal";
import AvatarBadge from "src/Components/AvatarBadge";
import ChildSay from "src/pages/HomePage/components/ChildSay";
import location from "src/Assets/images/userlocationtag.png";
import saysoutlineimage from "src/Assets/images/noofsays.svg";
import follow from "src/Assets/images/follow.svg";
import unfollow from "src/Assets/images/unfollow.svg";
import like from "src/Assets/images/like.svg";
import unlike from "src/Assets/images/unlike.svg";
import NoDataFound from "src/Assets/images/data-not-found.svg";
import dotImage from "src/Assets/images/dot.png";
import dropdownImage from "src/Assets/images/downarrow.svg";
// import smallike from "src/Assets/images/smallike.png";
import edit from "src/Assets/images/edit.svg";
import share from "src/Assets/images/share.svg";
import rating from "src/Assets/images/rating.png";
import feedstyles from "src/Assets/css/home/feedpost.module.css";
import poststyles from "src/Assets/css/home/post.module.css";
import API from "src/network";
import useStyles from "./styles";
import _ from "lodash";
import { replaceTagAndUrl } from "src/Utils/commonUtils";

const Feed = ({
  data,
  handleSayClick,
  id,
  childSaysData,
  handleFollowunfollow,
  childSayLoading,
  feedLoading,
  isActionDisabled = false,
  fetchVoteUpStatus = () => { },
  fetchFeedData = () => { },
  deleteMessage = () => { },
  refreshFeedData = () => { },
  postChildSayData = () => { },
  fetchChildSaysData = () => { },
  flow,
  filterType = "",
  feedPageNumber = 1,
  onHashTagClick
}) => {
  const imageServerURL = process.env.REACT_APP_SKOPIC_IMAGE_OTHER;
  const {
    feedContainer,
    feedMessage,
    avatarDetails,
    avatar,
    locationandtime,
    followerandcount,
    askcount,
    saycount,
    updatecount,
    tagcount,
    followcount,
    saysandfollow,
    noOfsays,
    noOffollows,
    followtext,
    likeunlike,
    nodata,
    profileHoverImageCount,
    profilehovername,
    profilecount,
    profilehovercontent,
    feeddropdown,
  } = useStyles();
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [editPostId, setEditPostId] = useState(null);
  const [feedList, setFeedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(feedPageNumber);
  const loader = useRef(null);
  const listRef = useRef(null);
  const options = {
    root: null,
    rootMargin: "20px",
    threshold: 0,
  };

  useEffect(() => {
    setCurrentPage(feedPageNumber);
    setHasMore(true);
  }, [feedPageNumber]);

  useEffect(() => {
    setFeedList(data);
  }, [data]);

  const handleScroll = useCallback(() => {
    if (!hasMore || isMoreLoading) return; // indicates that there will be no more data
    const { scrollTop, clientHeight, scrollHeight } = listRef.current;

    if (scrollTop + clientHeight + 100 >= scrollHeight) {
      setIsMoreLoading(true);
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [hasMore, isMoreLoading]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) observer.observe(loader.current);

    return () => {
      if (loader.current) observer.unobserve(loader.current);
    };
  }, [loader, options]);

  useEffect(() => {
    (async () => {
      setIsMoreLoading(true);
      const pageNumber = (currentPage - 1) * 10;
      try {
        const response = await API.fetchFeedData(filterType, pageNumber);
        const { feed } = response.data;
        if (feed && Array.isArray(feed) && feed.length) {
          setFeedList((prevFeedList) =>
            _.uniqBy([...prevFeedList, ...response.data.feed], "id")
          );
        } else {
          setHasMore(false); // No more data available
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsMoreLoading(false);
      }
    })();
  }, [currentPage]);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      // if (props.data.totalfeedsizeincommunity !== props.data.breadCountLength) {
      //   if (target.isIntersecting) {
      //     // dispatch(feedactions.fetchFeedData(`?startlimit=${props.data.breadCountLength}${props.filtertype}`))
      //     // if(props.listdata)
      //     // {
      //     //     setFeed(oldFeed=>[...oldFeed,this.props.listdata])
      //     //     console.log(isFeed)
      //     // }
      //   }
      // }
    },
    [data]
  );

  const onEditSelection = (id) => {
    setEditPostId(id);
  };

  const onCloseEdit = (id) => {
    setEditPostId(null);
  };

  const onLikeUnlikeSelection = (id, cache) => {
    if (isActionDisabled) return;
    likeUnlike(id);
    fetchVoteUpStatus(id, cache);
  };

  const likeUnlike = (id) => {
    const newData = [...feedList];
    const modifiedData = newData.find((x) => x.id === id);
    modifiedData.UserLikeStatus = "InActive";
    modifiedData.voteUp = modifiedData.voteUp - 1 + 2;
    setFeedList(newData);
  };

  //to perform this follow unfollow actions
  const onFollowUnfollow = (id, followstatus) => {
    if (isActionDisabled) return;
    updateList(id, followstatus);
    handleFollowunfollow(id, followstatus);
  };

  const updateList = (id, followStatus) => {
    const newData = [...feedList];
    const modifiedData = newData.find((x) => x.id === id);
    modifiedData.followStatus = followStatus.includes("0") ? "N" : "Y";
    modifiedData.followCount = followStatus.includes("0")
      ? modifiedData.followCount - 1
      : modifiedData.followCount - 1 + 2;
    setFeedList(newData);
  };

  const showMsg = (type) => {
    console.log('showMsg invoked default feed', type);
    if (type === "ask") {
      return 'No Community ASKs available at this time';
    } else if (type === "say") {
      return 'No Community SAYs available at this time';
    } else if (type === "update") {
      return 'No Community UPDATEs available at this time';
    } else if (type === "hashtag") {
      return 'No Community Hashtags available at this time';
    } else {
      return 'No Community data available at this time';
    }
  }

  if (feedLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "100px",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div
        id="feedScroll"
        className={poststyles.allFeedMainContainer}
        ref={listRef}
        onScroll={isActionDisabled ? null : handleScroll}
        style={{ scrollbarWidth: "none" }}
      >
        {feedList?.length > 0 ? (
          feedList?.map((item, index) => {
            return (
              <div className={feedContainer} key={index}>
                <div className={feedstyles.nameAndAvatarWrapper}>
                  <div className={feedstyles.avatardetails}>
                    <div>
                      <AvatarBadge
                        src={`${imageServerURL}${item?.mainuserdata?.uimage}`}
                        alt={item?.mainuserdata?.displayName}
                        isPrimaryModerator={item?.mainuserdata?.isPM == 1}
                        isModerator={
                          item?.mainuserdata?.isModerator == 1 &&
                          item?.mainuserdata?.isPM == 0
                        }
                      />
                    </div>
                    <div style={{ paddingLeft: 5 }}>
                      <span className={feedstyles.profilename}>
                        {item?.mainuserdata?.displayName}
                        {
                          <div className={feedstyles.profilehover}>
                            <div
                              className={feedstyles.profilehoverimageandcount}
                            >
                              <AvatarBadge
                                src={`${imageServerURL}${item?.mainuserdata?.uimage}`}
                                className={avatar}
                                alt={userDetails?.userData?.displayName}
                                isPrimaryModerator={
                                  item?.mainuserdata?.isPM == 1
                                }
                                isModerator={
                                  item?.mainuserdata?.isModerator == 1 &&
                                  item?.mainuserdata?.isPM == 0
                                }
                              />
                              <div>
                                <p className={profilehovername}>
                                  {item?.mainuserdata?.displayName}
                                </p>
                                <p className={profilecount}>
                                  ASK {item?.mainuserdata?.askcount} | SAY{" "}
                                  {item?.mainuserdata?.saycount} | UPDATE{" "}
                                  {item?.mainuserdata?.updatecount} | TAG{" "}
                                  {item?.mainuserdata?.hashtagcount}
                                </p>
                              </div>
                            </div>
                            <p className={profilehovercontent}>
                              {item?.mainuserdata?.shortBio}
                            </p>
                          </div>
                        }
                      </span>
                      {item?.locName ? (
                        <div className={locationandtime}>
                          <span>{item?.Message_Time}</span>
                          <img
                            src={dotImage}
                            alt="dot"
                            style={{ marginRight: "0", marginLeft: "3px" }}
                          />
                          <span>
                            <img src={location} alt="location" />
                            {item?.locName}
                          </span>
                        </div>
                      ) : (
                        <div className={locationandtime}>
                          <span>{item?.Message_Time}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {isActionDisabled && (
                    <div className={feeddropdown}>
                      <Menu
                        menuButton={
                          <IconButton
                            style={{
                              padding: 0,
                              width: 30,
                              height: 30,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              outline: "none",
                              cursor: "not-allowed",
                            }}
                            onClick={(e) => e.preventDefault()}
                            disabled
                          >
                            <img
                              src={dropdownImage}
                              alt="down-arrow-icon"
                              style={{ height: "7px", color: "#373334" }}
                            />
                          </IconButton>
                        }
                        transition
                        direction="bottom"
                        arrow
                        menuStyle={{
                          backgroundColor: "white",
                          borderRadius: 20,
                        }}
                      >
                        {(item.message_type === "A" ||
                          item.Keyword_ID === "OpenASK" ||
                          item.message_type === "H" ||
                          item.Keyword_ID === "hashTAG" ||
                          item.message_type === "X" ||
                          item.message_type === "I" ||
                          item.message_type === "S" ||
                          item.Keyword_ID === "impupdate" ||
                          item.Keyword_ID === "OpenSAY") &&
                          (item.User_ID !== userDetails?.userData?.User_ID &&
                            userDetails?.userData?.moderatorStatus === "0" ? (
                            <>
                              <MenuItem>
                                <ReusuableReportDialogmodal
                                  id={item.id}
                                  param={"AskorSay"}
                                />
                              </MenuItem>
                              <MenuItem>
                                <ReusuableSendaMailModal
                                  displayName={item?.mainuserdata?.displayName}
                                  id={item.id}
                                />
                              </MenuItem>
                              {(item.message_type === "H" ||
                                item.Keyword_ID === "hashTAG" ||
                                item.message_type === "X") && (
                                  <MenuItem>
                                    <span>
                                      <img
                                        src={rating}
                                        style={{ marginRight: 8 }}
                                        alt="rating-icon"
                                      />
                                      <span style={{ fontSize: 14 }}>
                                        Ratings
                                      </span>
                                    </span>
                                  </MenuItem>
                                )}
                            </>
                          ) : userDetails?.userData?.moderatorStatus === "1" &&
                            item.User_ID !== userDetails?.userData?.User_ID ? (
                            <>
                              <MenuItem>
                                <ReusuableDeleteDialogmodal
                                  type={item.Keyword_ID}
                                  id={item.id}
                                  // setDeletePost={setDeletePost}
                                  // setDeletePostId={setDeletePostId}
                                  deleteMessage={deleteMessage}
                                />
                              </MenuItem>
                              <MenuItem>
                                <ReusuableSendaMailModal
                                  displayName={item?.mainuserdata?.displayName}
                                  id={item.id}
                                />
                              </MenuItem>
                              {(item.message_type === "H" ||
                                item.Keyword_ID === "hashTAG" ||
                                item.message_type === "X") && (
                                  <MenuItem>
                                    <span>
                                      <img
                                        src={rating}
                                        style={{ marginRight: 8 }}
                                        alt="rating-icon"
                                      />
                                      <span style={{ fontSize: 14 }}>
                                        Ratings
                                      </span>
                                    </span>
                                  </MenuItem>
                                )}
                            </>
                          ) : (
                            <>
                              {(item.message_type === "H" ||
                                item.Keyword_ID === "hashTAG" ||
                                item.message_type === "X") && (
                                  <MenuItem>
                                    <span>
                                      <img
                                        src={share}
                                        style={{ marginRight: 8 }}
                                        alt="share-icon"
                                      />
                                      <span style={{ fontSize: 14 }}>Share</span>
                                    </span>
                                  </MenuItem>
                                )}
                              <MenuItem
                                onClick={() => onEditSelection(item.id)}
                              >
                                <img
                                  src={edit}
                                  style={{ marginRight: 8 }}
                                  alt="edit-icon"
                                />
                                <span style={{ fontSize: 14 }}>Edit</span>
                              </MenuItem>
                              <MenuItem>
                                <ReusuableDeleteDialogmodal
                                  type={item.Keyword_ID}
                                  id={item.id}
                                  // setDeletePost={setDeletePost}
                                  // setDeletePostId={setDeletePostId}
                                  deleteMessage={deleteMessage}
                                />
                              </MenuItem>
                            </>
                          ))}
                      </Menu>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    paddingTop: "5px",
                    fontSize: "14px",
                    fontWeight: "normal",
                    paddingBottom: "5px",
                  }}
                >
                  {editPostId === item?.id ? (
                    <PostMessageValidation
                      id={item.id}
                      Message={item.Message}
                      messageType={"feedTextArea"}
                      filtertype={filterType}
                      post={item}
                      fetchFeedData={fetchFeedData}
                      refreshFeedData={() => {
                        onCloseEdit && onCloseEdit();
                        refreshFeedData && refreshFeedData();
                      }}
                      onCloseEdit={onCloseEdit}
                    />
                  ) : (
                    // <div
                    //   className={feedMessage}
                    //   dangerouslySetInnerHTML={{ __html: item?.Message }}
                    //   id={`hide${item?.id}`}
                    // />
                    <p
                      className={feedMessage}
                      id={`hide${item?.id}`}
                    >
                      {
                        replaceTagAndUrl(
                          item.Message,
                          item.msg,
                          item.tag,
                          item.url,
                          onHashTagClick,
                        )}
                    </p>
                  )}
                </div>
                <div className={followerandcount}>
                  {item?.message_type === "A" ||
                    item?.Keyword_ID === "OpenASK" ||
                    item?.message_type === "H" ||
                    item?.Keyword_ID === "hashTAG" ||
                    item?.Keyword_ID === "TrendingASK" ? (
                    <div>
                      <SimpleDialogDemo
                        followCount={item?.followCount}
                        followData={item?.follweduserprofile}
                        id={item?.id}
                      />
                    </div>
                  ) : item?.voteUp ? (
                    <div>
                      <SimpleDialogDemo
                        voteUp={item?.voteUp}
                        id={item?.id}
                        likesData={item?.likeduserprofile}
                      />
                    </div>
                  ) : (
                    <div>
                      <span className={followcount}>
                        {item?.voteUp === 1
                          ? `${item?.voteUp} like`
                          : `${item?.voteUp} likes`}
                      </span>
                    </div>
                  )}
                  {item?.message_type === "A" ||
                    item?.Keyword_ID === "OpenASK" ? (
                    <div className={askcount}>{/* {item?.followCount} */}</div>
                  ) : item?.message_type === "S" ||
                    item?.Keyword_ID === "OpenSAY" ? (
                    <div className={saycount}></div>
                  ) : item?.message_type === "I" ||
                    item?.Keyword_ID === "impupdate" ? (
                    <div className={updatecount}></div>
                  ) : item?.message_type === "H" ||
                    item?.Keyword_ID === "hashTAG" ||
                    item?.message_type === "X" ? (
                    <div className={tagcount}>{/* {item?.followCount} */}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div style={{ border: "1px solid #C4C4C4" }} />
                {item?.message_type === "A" ||
                  item?.message_type === "H" ||
                  item?.message_type === "X" ||
                  item?.Keyword_ID === "OpenASK" ||
                  item?.Keyword_ID === "hashTAG" ? (
                  <div className={saysandfollow}>
                    <div className={noOfsays}>
                      <a onClick={(e) => handleSayClick(e, item?.id)}>
                        <img src={saysoutlineimage} alt="numberof says" />
                        {item?.sayCount === 1 || item?.postCount === 1 ? (
                          <span>{item?.sayCount} SAY</span>
                        ) : item?.sayCount === 0 || item?.postCount === 0 ? (
                          <span>SAY</span>
                        ) : (
                          <span>{item?.sayCount} SAYs</span>
                        )}
                      </a>
                    </div>
                    {item?.followStatus === "Active" ||
                      item?.followStatus === "Y" ? (
                      <div className={noOffollows} id={`1${item?.id}`}>
                        <a
                          className={followtext}
                          onClick={() =>
                            onFollowUnfollow(item?.id, "isFollow=0")
                          }
                        >
                          <img
                            src={unfollow}
                            alt="unfollow"
                            style={{ marginRight: "-2px" }}
                          />
                          <span>Unfollow</span>
                        </a>
                      </div>
                    ) : (
                      <div id={`0${item?.id}`} className={noOffollows}>
                        <a
                          className={followtext}
                          onClick={() =>
                            onFollowUnfollow(item?.id, "isFollow=1")
                          }
                        >
                          <img
                            src={follow}
                            alt="follow"
                            style={{ marginRight: "-2px" }}
                          />
                          <span>Follow</span>
                        </a>
                      </div>
                    )}
                    {/* {item?.hashTagType === "private" && (
                    userDetails?.userData?.moderatorStatus === "1" || userDetails.userData.isAdmin === "1"
                  ) ? (
                    <div className={priavteinvite}>
                      <img src={privateinvite} alt="Private Invite" />
                      <span
                        onClick={() => onInviteSelection(item?.TenantID || item?.tenantID, item?.id)}
                        style={{ cursor: "pointer" }}
                      >
                        Invite
                      </span>
                    </div>
                  ) : (
                    ""
                  )} */}
                  </div>
                ) : (
                  <div className={likeunlike}>
                    {item?.UserLikeStatus === "Active" ? (
                      <>
                        <a
                          id={`like${item?.id}`}
                          onClick={() =>
                            onLikeUnlikeSelection(item.id, "cacheUpdate")
                          }
                        >
                          <img src={like} alt="like" />
                          Like
                        </a>
                      </>
                    ) : (
                      <a>
                        <img src={unlike} alt="unlike" />
                        Like
                      </a>
                    )}
                  </div>
                )}

                {item?.id === id && (
                  <div style={{ paddingTop: "10px" }}>
                    <PostMessageValidation
                      id={item?.id}
                      type={"childSay"}
                      userImageSrc={`${imageServerURL}${userDetails?.userData?.uimage}`}
                      readOnly={isActionDisabled}
                      postChildSayData={postChildSayData}
                      fetchChildSaysData={fetchChildSaysData}
                      refreshFeedData={refreshFeedData}
                    />
                    <ChildSay
                      id={item.id}
                      key={item.id}
                      loading={childSayLoading}
                      childSaysData={childSaysData || {}}
                      fetchVoteUpStatus={fetchVoteUpStatus}
                      fetchFeedData={fetchFeedData}
                      deleteMessage={deleteMessage}
                      refreshFeedData={refreshFeedData}
                      isActionDisabled={isActionDisabled}
                      flow={flow}
                    />
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className={nodata}>
            <img src={NoDataFound} alt="NoDataFound" width={"30%"} />
            <p>{showMsg(filterType)}</p>
          </div>
        )}

        <div className={poststyles.loaderContainer}>
          {isMoreLoading && <CircularProgress size={25} />}
        </div>
      </div>
      <div ref={loader} />
    </React.Fragment>
  );
};

export default Feed;
