export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="83.896"
      height="21.435"
      viewBox="0 0 83.896 21.435"
    >
      <g
        id="Group_1847"
        data-name="Group 1847"
        transform="translate(-27.524 -729.862)"
      >
        <text
          id="UPDATE"
          transform="translate(58.42 746)"
          fill="#373334"
          fontSize="14"
          fontFamily="AcuminPro-Regular, Acumin Pro"
        >
          <tspan x="0" y="0">
            UPDATE
          </tspan>
        </text>
        <g
          id="Group_1722"
          data-name="Group 1722"
          transform="translate(27.524 729.862)"
        >
          <g
            id="Group_177"
            data-name="Group 177"
            transform="translate(14.981 6.251)"
          >
            <path
              id="Path_82"
              data-name="Path 82"
              d="M374.455,264.582l-6.873,2.332-1.041-6.669"
              transform="translate(-366.541 -253.105)"
              fill="#e82727"
            />
            <path
              id="Path_83"
              data-name="Path 83"
              d="M368.561,249.207l2.758-1.779,1.892,2.479-2.759,1.778Z"
              transform="translate(-367.436 -247.428)"
              fill="#e82727"
            />
            <path
              id="Path_84"
              data-name="Path 84"
              d="M371.694,259.12l3.1,1.136-1.22,2.811-3.1-1.136Z"
              transform="translate(-368.283 -252.607)"
              fill="#e82727"
            />
            <path
              id="Path_85"
              data-name="Path 85"
              d="M371.364,252.615l3.063-.933.87,2.415-3.063.932Z"
              transform="translate(-368.677 -249.312)"
              fill="#e82727"
            />
            <path
              id="Path_86"
              data-name="Path 86"
              d="M372.455,255.568l3.224.146-.167,3.145-3.223-.144Z"
              transform="translate(-369.087 -251.034)"
              fill="#e82727"
            />
            <path
              id="Path_87"
              data-name="Path 87"
              d="M376.146,254.784c-.124-1.881-.907-3.365-1.748-3.317s-1.417,1.61-1.291,3.49.91,3.366,1.748,3.318S376.275,256.665,376.146,254.784Z"
              transform="translate(-369.442 -249.216)"
              fill="#e82727"
            />
            <path
              id="Path_88"
              data-name="Path 88"
              d="M373.868,262.122c.974-1.694,1.159-3.36.413-3.721s-2.134.719-3.107,2.413-1.159,3.36-.417,3.721S372.9,263.817,373.868,262.122Z"
              transform="translate(-368.207 -252.258)"
              fill="#e82727"
            />
            <path
              id="Path_89"
              data-name="Path 89"
              d="M374.752,249.409c-.51-.559-1.048-.919-1.2-.8s.138.665.65,1.225,1.048.919,1.2.8S375.264,249.968,374.752,249.409Z"
              transform="translate(-369.629 -247.941)"
              fill="#e82727"
            />
            <path
              id="Path_90"
              data-name="Path 90"
              d="M373.965,248.475c-.4-.458-.857-.738-1.009-.624s.048.58.451,1.039.858.738,1.011.623S374.368,248.934,373.965,248.475Z"
              transform="translate(-369.36 -247.604)"
              fill="#e82727"
            />
          </g>
          <g
            id="Group_178"
            data-name="Group 178"
            transform="translate(2.031)"
          >
            <path
              id="Path_91"
              data-name="Path 91"
              d="M358.778,236.206l5.711,4.219-5.682,4.251"
              transform="translate(-350.152 -236.206)"
              fill="#e82727"
            />
            <path
              id="Path_92"
              data-name="Path 92"
              d="M346.369,251.572l-3.076-1.258,1.337-2.765,3.077,1.259Z"
              transform="translate(-343.293 -241.23)"
              fill="#e82727"
            />
            <path
              id="Path_93"
              data-name="Path 93"
              d="M354.2,244.369l-.536-3.03,3.25-.486.535,3.031Z"
              transform="translate(-347.887 -238.265)"
              fill="#e82727"
            />
            <path
              id="Path_94"
              data-name="Path 94"
              d="M347.977,248.085l-2.446-1.934,1.8-1.929,2.446,1.934Z"
              transform="translate(-344.284 -239.757)"
              fill="#e82727"
            />
            <path
              id="Path_95"
              data-name="Path 95"
              d="M350.145,245.79l-1.523-2.616,3.019-1.487,1.524,2.616Z"
              transform="translate(-345.654 -238.634)"
              fill="#e82727"
            />
            <path
              id="Path_96"
              data-name="Path 96"
              d="M348.162,242.5c-1.687,1.068-2.669,2.449-2.193,3.087s2.23.289,3.921-.779,2.669-2.449,2.193-3.087S349.85,241.434,348.162,242.5Z"
              transform="translate(-344.425 -238.508)"
              fill="#e82727"
            />
            <path
              id="Path_97"
              data-name="Path 97"
              d="M355.229,240.873c-2.08.105-3.729.817-3.684,1.589s1.771,1.313,3.852,1.208,3.73-.817,3.683-1.589S357.31,240.768,355.229,240.873Z"
              transform="translate(-346.947 -238.268)"
              fill="#e82727"
            />
            <path
              id="Path_98"
              data-name="Path 98"
              d="M344.411,248.488c-.258.692-.317,1.3-.13,1.359s.55-.451.809-1.143.318-1.3.132-1.359S344.669,247.8,344.411,248.488Z"
              transform="translate(-343.682 -241.138)"
              fill="#e82727"
            />
            <path
              id="Path_99"
              data-name="Path 99"
              d="M343.934,249.976c-.22.553-.247,1.054-.062,1.115s.515-.335.735-.889.248-1.055.062-1.117S344.156,249.421,343.934,249.976Z"
              transform="translate(-343.495 -241.908)"
              fill="#e82727"
            />
          </g>
          <g
            id="Group_179"
            data-name="Group 179"
            transform="translate(0 11.236)"
          >
            <path
              id="Path_100"
              data-name="Path 100"
              d="M339.647,262.885l1.89-6.508,6.526,3.061"
              transform="translate(-339.647 -256.377)"
              fill="#e82727"
            />
            <rect
              id="Rectangle_329"
              data-name="Rectangle 329"
              width="3.097"
              height="3.29"
              transform="matrix(0.003, -1, 1, 0.003, 9.233, 10.086)"
              fill="#e82727"
            />
            <path
              id="Path_101"
              data-name="Path 101"
              d="M348.576,265.105l-2.8,1.674-1.795-2.535,2.8-1.676Z"
              transform="translate(-341.568 -259.119)"
              fill="#e82727"
            />
            <path
              id="Path_102"
              data-name="Path 102"
              d="M355.248,268.88l-.936,2.839-2.648-.738.937-2.84Z"
              transform="translate(-344.97 -261.588)"
              fill="#e82727"
            />
            <path
              id="Path_103"
              data-name="Path 103"
              d="M351.7,267.568l-1.987,2.34-2.7-1.94L349,265.63Z"
              transform="translate(-342.912 -260.476)"
              fill="#e82727"
            />
            <path
              id="Path_104"
              data-name="Path 104"
              d="M348.371,269.885c1.745.99,3.516,1.258,3.957.6s-.614-1.995-2.359-2.983-3.515-1.256-3.955-.6S346.625,268.9,348.371,269.885Z"
              transform="translate(-342.423 -260.88)"
              fill="#e82727"
            />
            <path
              id="Path_105"
              data-name="Path 105"
              d="M343.9,263.675c.944,1.708,2.317,2.808,3.067,2.457s.592-2.019-.353-3.727-2.32-2.807-3.069-2.456S342.95,261.968,343.9,263.675Z"
              transform="translate(-341.163 -257.93)"
              fill="#e82727"
            />
            <path
              id="Path_106"
              data-name="Path 106"
              d="M357.341,274.115c.791-.061,1.42-.258,1.4-.441s-.674-.278-1.465-.217-1.42.258-1.4.44S356.547,274.177,357.341,274.115Z"
              transform="translate(-346.836 -263.935)"
              fill="#e82727"
            />
            <path
              id="Path_107"
              data-name="Path 107"
              d="M359.051,274.041c.642-.038,1.15-.218,1.138-.4s-.545-.3-1.184-.26-1.147.217-1.136.4S358.412,274.079,359.051,274.041Z"
              transform="translate(-347.718 -263.906)"
              fill="#e82727"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}