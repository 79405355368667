import React from 'react'
// import { useSelector } from 'react-redux'
import backwardarrow from 'src/Assets/images/backwardarrow.svg'
import Post from '../components/Post';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from 'src/Assets/css/home/searchresult.module.css'

const SearchResults = (props) => {
  const { searchData, goBack, childSaysData,
    fetchChildSaysData, fetchFollowData, fetchVoteUpStatus,
    followData, loading, childSaysLoading, postChildSayData,
    fetchFeedData, deleteMessage, refreshFeedData,fetchContributors
  } = props;
  // const userSearchData = useSelector((state) => state.userReducer.userSearchData)

  if (loading) {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <React.Fragment>
      {(searchData && Object.keys(searchData).length !== 0) ? (
        (searchData?.totalList > 0) ? (
          <div className={styles.searchresult}>
            <div>
              <div className={styles.noofsearchresuts}>
                <a><img src={backwardarrow} onClick={goBack} /></a>
                <p>{searchData.totalList} Results for <b>{searchData.searchText}</b></p>
              </div>
              <Post
                loading={childSaysLoading}
                listdata={searchData.messageList}
                data={searchData}
                childSaysData={childSaysData}
                fetchChildSaysData={fetchChildSaysData}
                postChildSayData={postChildSayData}
                followData={followData}
                fetchFollowData={fetchFollowData}
                fetchVoteUpStatus={fetchVoteUpStatus}
                fetchFeedData={fetchFeedData}
                deleteMessage={deleteMessage}
                refreshFeedData={refreshFeedData}
                showData="search"
                fetchContributors={fetchContributors}
              />
            </div>
          </div>
        ) : (
          <div className={styles.searchresult}>
            <div>
              <div className={styles.noofsearchresuts}>
                <a><img src={backwardarrow} onClick={goBack} /></a>
                <p>{searchData?.totalList || 0} Results for <b>{searchData?.searchText}</b></p>
              </div>
              <p style={{ textAlign: 'center', marginTop: 30 }}>No results found matching your criteria</p>
            </div>
          </div>
        )
      ) : (
        <div className={styles.searchresult}>
          <div>
            <div className={styles.noofsearchresuts}>
              <a><img src={backwardarrow} onClick={goBack} /></a>
              <p>0 Results found!</p>
            </div>
            <p style={{ textAlign: 'center', marginTop: 30 }}>No results found matching your criteria</p>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
export default SearchResults;
