import React, { useEffect, useRef, useState } from "react";
import "./Communities.css";
// import Community from "src/Assets/images/Community-1.png"
import Link from "src/Assets/images/link.svg";
import Crown from "src/Assets/images/moderator1.svg";
import SecondaryModrator from "src/Assets/images/secondarymoderator.svg";
import Member from "src/Assets/images/crown.svg";
import MakeDefault from "src/Assets/images/Default.svg";
import setDefault from "src/Assets/images/setDefault.png";
import { useSelector, useDispatch } from "react-redux";
import * as SignInActions from "src/store/actions/SignInActions/SignInAction";
import API from "src/network";
import UserList from "./UserList";
import ConfirmationDialog from "src/Components/ConfirmationDialog";

function Communities() {
  const profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const [userData, setUserData] = useState();
  const [communityId, setCommunityId] = useState(null);
  const [openMembersModal, setOpenMembersModal] = useState(false);
  const [confModalVisible, setConfModalVisible] = useState(null);

  const changeFollowHandler = async (id, tentId) => {
    let formData = new FormData();
    formData.append("communityID", id);
    formData.append("communityName", tentId);
    formData.append("followStat", "0");
    formData.append("sourceApp", "WebApp");
    await API.followCommunity(formData);
    dispatch(SignInActions.fetchloginUser());
  };

  const makeDefaultHandler = async (id) => {
    const data = new FormData();
    data.append("userDefaultTenantId", id);
    await API.updateDefaultTenant(data);
    dispatch(SignInActions.fetchloginUser());
  };

  const onClose = () => {
    setConfModalVisible(null);
  };

  const somethingWentWrong = () => {
    setConfModalVisible({
      open: true,
      type: "error",
      title: "Something went wrong!",
      acceptLabel: "Ok",
      onAccept: onClose,
    });
  };

  const makeAsModerator = async (community) => {
    const moderatorRestrictedStatus = community?.moderatorRestrictedStatus;
    const hasPermission = String(moderatorRestrictedStatus) === "0";
    if (!hasPermission) {
      setConfModalVisible({
        open: true,
        type: "error",
        title: "This community is not adding any moderator at this time",
        acceptLabel: "Ok",
        onAccept: onClose,
      });
      return;
    }

    try {
      const { User_ID } = userDetails?.userData || {};
      const formData = new FormData();
      formData.append("tenantId", community?.id);
      formData.append("requstFor", 0);
      const response = await API.raiseModeratorRequest(formData);
      if (response?.data?.status === "Request sent successfully") {
        dispatch(SignInActions.fetchloginUser());
        setConfModalVisible({
          open: true,
          type: "success",
          title: `Your request to be a moderator in ${community?.userFollowCommunityName} was sent successfully`,
          acceptLabel: "Ok",
          onAccept: onClose,
        });
      } else {
        somethingWentWrong();
      }
    } catch (e) {
      console.error(e);
      somethingWentWrong();
    }
  };

  const confirmationForUnsubscribe = (community) => {
    setCommunityId(community?.id);
    setConfModalVisible({
      open: true,
      type: "warning",
      title: "Are you sure you want to unsubscribe from the moderator role?",
      rejectLabel: "Cancel",
      acceptLabel: "Yes",
      onReject: onClose,
      onAccept: () => unsubscribeRole(community?.id),
    });
  };

  const unsubscribeRole = async (id) => {
    try {
      onClose(null);
      const { User_ID } = userDetails?.userData || {};
      const formData = new FormData();
      formData.append("tenantId", id);
      formData.append("rejectedIds", User_ID);
      const response = await API.inviteAsModerator(formData);
      if (response?.data?.status === "Removed Successfully") {
        dispatch(SignInActions.fetchloginUser());
        setConfModalVisible({
          open: true,
          type: "success",
          title: "Removed Successfully",
          acceptLabel: "Ok",
          onAccept: onClose,
        });
      }
    } catch (e) {
      console.error(e);
      somethingWentWrong();
    }
  };

  const changePrimaryRole = (communityId) => {
    setCommunityId(communityId);
    setOpenMembersModal(true);
  };

  const onAssignPrimaryRole = async (id) => {
    setOpenMembersModal(false);
    try {
      const formData = new FormData();
      formData.append("tenantId", communityId);
      formData.append("selectedIds", id);
      formData.append("flag", "forPm");
      console.log(formData);
      const response = await API.inviteAsModerator(formData);
      setConfModalVisible({
        open: true,
        type: "success",
        title: response?.data?.status,
        acceptLabel: "Ok",
        onAccept: onClose,
      });

      // setUserData(response.data.userList);
      // setUnsubscribePrimary(true);
    } catch (e) {
      console.error(e);
      somethingWentWrong();
    }
  };

  const clickToCopy = async () => {
    const url = process.env.REACT_APP_SERVICES_API;
    try {
      // Copy the link to the clipboard
      await navigator.clipboard.writeText(
        `${url}/user/communityurl.html?tid=174`
      );
      // Show an alert
      alert("Link copied!");
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  return (
    <div className="activityCommunitiesMainContainer">
      <div className="Communities-dispaly">
        <p className="head">Communities </p>
        {userDetails && Object.keys(userDetails).length !== 0 ? (
          userDetails.userData &&
          Object.keys(userDetails.userData).length !== 0 ? (
            <div className="Communities">
              {userDetails.userData.communityFollowNames?.map((result) => (
                <div className="Community" key={`key-id-${result.id}`}>
                  <img
                    src={` ${profileImagePath}${result.thumbnailLogo}`}
                    alt="CommunityImage"
                    className="CommunityImage"
                  />
                  <div className="Community-Details">
                    <p className="title">{result.userFollowCommunityName} </p>
                    <div>
                      {result.status > 0 ? (
                        <button
                          className="Unfollow"
                          disabled={
                            result.ispm === 1
                              ? true
                              : result.ispm === 0 &&
                                result.moderatorStatus === 1
                              ? true
                              : Number(result?.status) === 2
                              ? true
                              : false
                          }
                          onClick={() =>
                            changeFollowHandler(
                              result.id,
                              result.userFollowCommunityName
                            )
                          }
                        >
                          Unfollow{" "}
                        </button>
                      ) : (
                        <button className="Unfollow">follow</button>
                      )}

                      <button className="Actions" onClick={clickToCopy}>
                        <img src={Link} alt="Link" />
                      </button>

                      {/* <div id="popUP"> */}
                      {/* <p>Click to Copy a Public URL for this community</p> */}
                      {/* </div> */}
                      {result.ispm === 1 ? (
                        <button
                          className="Actions"
                          onClick={() => changePrimaryRole(result.id)}
                        >
                          <img src={Crown} alt="Modreator" />
                        </button>
                      ) : result.ispm === 0 && result.moderatorStatus === 1 ? (
                        <button
                          className="Actions"
                          onClick={() => confirmationForUnsubscribe(result)}
                        >
                          <img src={SecondaryModrator} alt="SecondaryModrato" />
                        </button>
                      ) : (
                        <button
                          className="Actions"
                          onClick={() => makeAsModerator(result)}
                        >
                          <img src={Member} alt="Member" />
                        </button>
                      )}
                      {Number(result?.status) === 2 ? (
                        <button className="Actions">
                          <img src={MakeDefault} alt="default-community" />
                        </button>
                      ) : (
                        <button
                          className="Actions"
                          onClick={() => makeDefaultHandler(result.id)}
                        >
                          <img src={setDefault} alt="makedefault" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null
        ) : null}
      </div>

      {/* <UnFollowModal
           show={showData}
           onHide={() => setShowData(false)}
           /> */}

      {openMembersModal && (
        <UserList
          open={openMembersModal}
          tenantId={communityId}
          onAssignRole={onAssignPrimaryRole}
          handleClose={() => setOpenMembersModal(false)}
        />
      )}
      {confModalVisible?.open && <ConfirmationDialog {...confModalVisible} />}
    </div>
  );
}
export default Communities;
