// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Activites{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 15px;
    margin-bottom: 20px;
}

.Activites p {
    text-align: center;
}

@media screen and (min-width: 768px) {
    .Activites{
        margin-top: 30px;
        align-items: flex-end;
    }
    .Activites p {
        text-align: left;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Modules/Activity/Activites/Activites.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,gBAAgB;QAChB,qBAAqB;IACzB;IACA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".Activites{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    padding-top: 15px;\n    margin-bottom: 20px;\n}\n\n.Activites p {\n    text-align: center;\n}\n\n@media screen and (min-width: 768px) {\n    .Activites{\n        margin-top: 30px;\n        align-items: flex-end;\n    }\n    .Activites p {\n        text-align: left;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
