import React, { useState } from 'react';
import axios from 'axios';
import { Modal } from "react-bootstrap";
import { LoadingButton } from '@mui/lab';
import { FiShare } from "react-icons/fi";
import "./invitations.css";

const styles = {
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  textArea: {
    width: '100%',
    borderRadius: '8px',
    border: '1px solid black',
    padding: '8px',
  },
  btn: {
    border: 'none',
    borderRadius: '20px',
    backgroundColor: '#3e90e1',
    color: 'white',
    width: '110px',
    height: '33px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  success: { color: 'green', fontSize: 14 },
  error: { color: 'red', fontSize: 14 }
};

const FacebookPost = ({ open, handleClose, accessToken }) => {
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePost = async () => {
    if (!message) return;
    try {
      setLoading(true);
      const result = await axios.post(
        'https://graph.facebook.com/me/feed',
        {
          message,
          access_token: accessToken,
        }
      );
      console.log('FB Post response->', result);
      setResponse(`Successfully posted!!! Post ID: ${result?.data?.id}`);
    } catch (err) {
      const errorMsg = err?.message ? err?.message : err;
      console.error('failed to post update: ', errorMsg);
      setError('Failed to post update! ' + errorMsg);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      show={open}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="InviteStyles"
    >
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title id="contained-modal-title-vcenter" className="title">
          Share on Facebook
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={styles.modalBody}>
          <textarea
            value={message}
            placeholder="What's on your mind?"
            onChange={(e) => setMessage(e.target.value)}
            style={styles.textArea}
            rows={10}
          />
          <LoadingButton
            onClick={handlePost}
            endIcon={<FiShare color='white' style={{ fontSize: 15 }} />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
            style={styles.btn}
          >
            Post
          </LoadingButton>
          {response && <p style={styles.success}>{response}</p>}
          {error && <p style={styles.error}>{error}</p>}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FacebookPost;
