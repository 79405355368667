import React, { useState, useEffect, useContext, useMemo } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Button } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Map from "./map/map";
import CommunitiesList from "./communitiesList";
import SearchBox from "./search";
import StartCommunities from "../../Modules/Activity/Community/Start Communities/StartCommunities";
import formattedCommunitiesList from "./utils";
import logo from "src/Assets/images/skopic.png";
import AddIcon from "../../Assets/images/LandingPageImages/Add-Icon.svg";
import useStyles from "./styles";
import API from "../../network";
import Header from "./ViewActivitySignIn/header";
import { SignupContext } from "src/context/SignupContext/SignupContext";
import Page from "src/layout/Page";
import { CircularProgress } from "@material-ui/core";
import { CommunityContext } from "src/context/CommunityContext/CommunityContext.js";
import countryDialCodeData from "src/Constants/CountryDialCodes.json";

const indiaCenter = {
  lat: 20.5937,
  lng: 78.9629,
};

const usCenter = {
  lat: 39.30071338365015,
  lng: -105.22967386366022,
};

const ausCenter = {
  lat: -25.358054473007794,
  lng: 133.78014455113046,
};

const jCenter = {
  lat: 36.58555063657604,
  lng: 139.28893315677468,
};

const mCenter = {
  lat: 23.675383823109872,
  lng: -102.37291391348354,
};

const moCenter = {
  lat: 46.70860451049151,
  lng: 103.63960601861254,
};

const scCenter = {
  lat: 57.080130805793296,
  lng: -4.337505014321898,
};

const siCenter = {
  lat: 1.312163183490345,
  lng: 103.81853278581212,
};
const DefaultCommunity = ({ signIn, countryId, hideSearch }) => {
  const { searchClass, buttonContainer, searchBtn, searchOrStartBtn } = useStyles();
  const communityContext = useContext(CommunityContext);

  const location = useLocation();
  const history = useHistory();

  const [communitiesList, setCommunitiesList] = useState([]);
  const [allCommunities, setAllCommunities] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchCommunitiesList, setSearchCommunitiesList] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [map, setMap] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState("");
  const [followStatusResponse, setFollowStatusResponse] = useState({});
  const [defaultResponse, setDefaultResponse] = useState([]);
  const [openStartCommunity, setOpenStartCommunity] = useState(false);
  const [currentCountry, setChangedCountry] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [countryTenantList, setCountryTenantList] = useState([]);
  const [communityResp, setCommunityResp] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSearchBeforeSignIn, setShowSearchBeforeSignIn] = useState(false);
  const settingsData = useSelector((state) => state.voteupReducer.settingsData);

  const signupContext = useContext(SignupContext);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const resp = await API.fetchCountryWithCommunity();
        const { countryList, countryTenantList } = resp?.data || {}
        setCountryList(countryList);
        setCountryTenantList(countryTenantList || {});

        let defaultCountry = {};
        //only for after signin flow: set default country, if countryId is available
        if (signIn && countryId) {
          defaultCountry = countryList.find((country) => country.countryId === countryId);
        } else {
          defaultCountry = countryList.find((country) => country.countryId === 1);
        }
        // if found
        if (defaultCountry && defaultCountry.countryId) {
          setChangedCountry(defaultCountry);
        }

        //fetch communities list
        await fetchNearByComm();
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // useEffect(() => {
  //   if (location?.state && countryList.length) {
  //     const countryCode = location?.state?.countryCode;
  //     if (countryCode) {
  //       const defaultCountry = countryList.find(country => country.country_code === countryCode);
  //       setChangedCountry(defaultCountry);
  //     }
  //   }
  // }, [location, countryList]);

  // const getNearByUserCommunities = async () => {
  //   await API.getNearByUserCommunities().then((res) => {
  //     setAllCommunities(res.data.physicalTenantList);
  //     if (res?.data && location?.pathname === "/defaultCommunity") {
  //       setCommunitiesList(
  //         formattedCommunitiesList(res?.data, location?.state?.countryId)
  //       );
  //     } else {
  //       setCommunitiesList(formattedCommunitiesList(res?.data, ""));
  //     }
  //   });
  // };

  // useEffect(() => {
  //   if (location?.pathname !== "/defaultCommunity") {
  //     getNearByUserCommunities();
  //   }
  // }, []);

  useEffect(() => {
    // async function fetchApi(countryId) {
    //   await API.getNearByCommunities().then((res) => {
    //     // setAllCommunities(res.data.physicalTenantList);
    //     if (res?.data && location?.pathname === "/defaultCommunity") {
    //       const formattedList = formattedCommunitiesList(
    //         res?.data,
    //         countryId
    //       );
    //       setCommunitiesList(formattedList);
    //     } else {
    //       const commList = formattedCommunitiesList(res?.data, countryId || "");
    //       console.log("COMM", commList);
    //       setCommunitiesList(commList);
    //     }
    //   });
    // }
    // const countryId = countryList?.find(each => each.country_code === currentCountry.country_code)?.country_id;
    // if (countryId) {
    //   fetchApi(countryId);
    // }

    if (Object.keys(communityResp).length) {
      const formattedCommList = formattedCommunitiesList(communityResp, currentCountry?.countryId || "")
      setCommunitiesList([...formattedCommList]);
    }
  }, [currentCountry, communityResp]);

  const onChangeCountry = (country) => {
    setChangedCountry(country);
  };

  useEffect(() => {
    if (
      (location?.pathname !== "/defaultCommunity" &&
        followStatusResponse?.status === 200) ||
      (location?.pathname !== "/defaultCommunity" &&
        defaultResponse?.status === 200)
    ) {
      fetchNearByComm();
    }
  }, [followStatusResponse, defaultResponse]);

  const fetchNearByComm = async () => {
    try {
      setLoading(true);
      let res;
      if (signIn) {
        res = await API.getCommunitiesAfterSignIn();
      } else {
        res = await API.getNearByCommunities();
      }
      setCommunityResp(res.data);
      setAllCommunities(res.data.physicalTenantList);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSearchCommunitiesList(communitiesList);
  }, [communitiesList]);

  const getMapCenter = () => {
    if (currentCountry) {
      return {
        lat: !isNaN(+currentCountry?.latitude) ? +currentCountry?.latitude : 0,
        lng: !isNaN(+currentCountry?.longitude) ? +currentCountry?.longitude : 0,
      };
    }
    return indiaCenter;
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      setSearchValue(e.target.value);
      let searchResult = communitiesList?.filter((value) => {
        return value?.communityName
          ?.toString()
          ?.toLowerCase()
          .includes(e.target.value?.toString()?.toLowerCase());
      });
      setSearchCommunitiesList(searchResult);
    } else {
      setSearchValue("");
      setSelectedItem("");
      setShowTooltip(false);
      map?.panTo(getMapCenter());
      map?.setZoom(4);
    }
  };

  const handleClear = () => {
    setSearchCommunitiesList(communitiesList);
    setSearchValue("");
    setShowTooltip(false);
    setSelectedMarker("");
    setSelectedItem("");
    map?.panTo(getMapCenter());
    map?.setZoom(4);
  };

  const handleMakeDefault = (name, tenantId, countryName) => {
    // set signup context data
    signupContext?.setSignupState({
      community: name,
      tenantId: tenantId,
    });

    //going back to home screen
    history.push("/");
  };

  const handleViewActivity = async (name, tenantId, countryName) => {
    try {
      const response = await API.changeCommunity(name, tenantId);
      if (response) {
        history.push("/viewActivity", {
          community: name,
          tenantId: tenantId,
          email: location?.state?.email,
          password: location?.state?.password,
          displayName: location?.state?.displayName,
          signIn: signIn,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleListItem = (id, location) => {
    if (selectedItem === id) {
      setSelectedItem("");
      setShowTooltip(false);
      map?.panTo(getMapCenter());
      map?.setZoom(4);
      setSelectedMarker("");
    } else {
      setSelectedItem(id);
      map?.panTo(location);
      map?.setZoom(7);
      setShowTooltip(true);
      setSelectedMarker(id);
    }
  };

  const handleMouseOver = (id, location) => {
    // setSelectedItem(id);
    // map?.panTo(location);
    // map?.setZoom(7);
  };

  const handleMouseOut = () => {
    // setSelectedItem('');
    // map?.panTo(getMapCenter());
    // map?.setZoom(4);
  };

  const handleMakeDefaultAfterSignIn = async (id) => {
    let formdata = new FormData();
    formdata.append("userDefaultTenantId", id);
    await API.setDefaultTenant(formdata)
      .then((res) => {
        setDefaultResponse(res);
      })
      .catch((err) => { });
  };

  const handleMakeFollowUnfollow = async (id, name, status) => {
    let formdata = new FormData();
    formdata.append("communityID", id);
    formdata.append("communityName", name);
    formdata.append("followStat", status === 0 ? 1 : 0);
    formdata.append("sourceApp", "WebApp");
    await API.followUnfollowCommunity(formdata)
      .then((res) => {
        setFollowStatusResponse(res);
      })
      .catch((err) => { });
  };

  const handleStartCommunity = () => {
    setOpenStartCommunity(true);
  };

  const handleClose = () => {
    setOpenStartCommunity(false);
  };

  const onClickSearchOrStartComm = (key) => {
    if (key === "start") {
      handleStartCommunity();
    }
    setShowSearchBeforeSignIn(true);
  };

  const getLoader = () => {
    if (loading) {
      return (
        <div style={{ marginBottom: "10px", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <CircularProgress />
        </div>
      );
    }
    return null;
  };

  const hasCountryCommunity = countryTenantList?.hasOwnProperty(currentCountry?.country_name)
    ? countryTenantList[currentCountry?.country_name]?.length > 0
    : false;

  const onSkopicClick = async () => {
    try {
      const response = await API.changeCommunity(settingsData?.userTenant, settingsData?.userTenantId);
      if (response) {
        communityContext?.onChangeCommunity({
          tenantId: settingsData?.userTenantId,
          tenantName: settingsData?.userTenant,
        });
      };
    } catch (e) {
      console.error(e);
    }
  };

  const dialCode = useMemo(() => {
    return countryDialCodeData?.find((it) => it?.name === currentCountry?.country_name)?.dialCode ?? "1";
  }, [currentCountry]);

  return (
    <>
      {signIn ? (
        <Page hideSearch onSkopicClick={onSkopicClick}>
          <Grid container style={{ padding: "10px 10px 10px 20px" }}>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                flexDirection: "column",
                height: "calc(100vh - 120px)",
              }}
            >
              <div className={searchClass}>
                <SearchBox
                  searchValue={searchValue}
                  handleSearch={handleSearch}
                  handleClear={handleClear}
                  defaultCountry={currentCountry}
                  onChangeCountry={onChangeCountry}
                  countryList={countryList}
                  countryTenantList={countryTenantList}
                />
              </div>
              {getLoader()}
              <div
                style={{
                  // height: "calc(100vh - 190px)",
                  // overflowY: "scroll",
                  paddingRight: "10px",
                  flex: 1,
                  overflow: "auto",
                }}
              >
                <CommunitiesList
                  data={searchCommunitiesList}
                  handleMakeDefault={handleMakeDefault}
                  handleListItem={handleListItem}
                  selectedItem={selectedItem}
                  country={currentCountry?.country_name}
                  handleMouseOver={handleMouseOver}
                  handleMouseOut={handleMouseOut}
                  handleViewActivity={handleViewActivity}
                  handleMakeDefaultAfterSignIn={handleMakeDefaultAfterSignIn}
                  handleMakeFollowUnfollow={handleMakeFollowUnfollow}
                  signIn={signIn}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={9}
              style={{
                paddingLeft: "10px",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                height: "calc(100vh - 120px)",
              }}
            >
              <div style={{ flex: 1 }}>
                <Map
                  markers={searchCommunitiesList}
                  country={currentCountry}
                  countryCode={currentCountry?.country_code}
                  selectedItem={selectedItem}
                  setMap={setMap}
                  map={map}
                  key={currentCountry?.country_code}
                  showTooltip={showTooltip}
                  selectedMarker={selectedMarker}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "absolute",
                  top: "20px",
                  left: "40px",
                }}
              >
                <div className={buttonContainer}>
                  <Button
                    variant={"outlined"}
                    style={{ borderRadius: "15px" }}
                    onClick={handleStartCommunity}
                  >
                    <img src={AddIcon} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        alignItems: "baseline",
                        marginLeft: "20px",
                      }}
                    >
                      <p style={{ marginBottom: "2px" }}>Start</p>
                      <p style={{ marginBottom: "2px" }}>Community</p>
                    </div>
                  </Button>
                </div>
              </div>
            </Grid>
            {openStartCommunity && (
              <StartCommunities
                open={openStartCommunity}
                close={handleClose}
                allCommunities={allCommunities}
                signIn={signIn}
                defaultCountryName={currentCountry?.country_name}
                defaultCountryDialCode={dialCode}
              />
            )}
          </Grid>
        </Page>
      ) : (
        <div style={{ backgroundColor: "#F3F2F0" }}>
          <Grid container style={{ padding: "0px 30px", height: "80px", background: "#fff" }}>
            <Grid item xs={3.5} style={{ display: "flex", alignItems: "center" }}>
              <Link to={"/"}>
                <img
                  style={{
                    cursor: "pointer",
                    objectFit: "cover",
                    width: "90px",
                    height: "48px",
                  }}
                  src={logo}
                  alt="Skopic Logo"
                />
              </Link>
            </Grid>
            <Grid item xs={8.5} style={{ display: "flex", alignItems: "flex-end", paddingLeft: "30px" }}>
              <div style={{ display: "flex", alignItems: "center" }} >
                <p style={{ fontSize: "18px", color: "#373334" }}>
                  {hasCountryCommunity ? (
                    <span>Choose or Start your default community</span>
                  ) : (
                    <>
                      <span>Start your community in&nbsp;</span>
                      <span style={{ fontWeight: 'bold' }}>{currentCountry?.country_name}</span>
                    </>
                  )}
                </p>
                <p style={{ paddingLeft: "5px" }}>
                  <InfoOutlinedIcon style={{ fontSize: "18px" }} />
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid container style={{ padding: "10px 10px 10px 20px" }}>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                flexDirection: "column",
                height: "calc(100vh - 110px)",
              }}
            >
              <div className={searchClass}>
                {!showSearchBeforeSignIn ? (
                  <div className={searchOrStartBtn}>
                    <button className={searchBtn} onClick={() => onClickSearchOrStartComm("search")}>Search</button>
                    &nbsp;or&nbsp;
                    <button className={searchBtn} onClick={() => onClickSearchOrStartComm("start")}>Start</button>
                    &nbsp;Community
                  </div>
                ) : (
                  <SearchBox
                    searchValue={searchValue}
                    handleSearch={handleSearch}
                    handleClear={handleClear}
                    defaultCountry={currentCountry}
                    onChangeCountry={onChangeCountry}
                    countryList={countryList}
                    countryTenantList={countryTenantList}
                  />
                )}
              </div>
              {getLoader()}
              <div
                style={{
                  // height: "calc(100vh - 190px)",
                  // overflowY: "scroll",
                  paddingRight: "10px",
                  flex: 1,
                  overflow: "auto",
                }}
              >
                <CommunitiesList
                  data={searchCommunitiesList}
                  handleMakeDefault={handleMakeDefault}
                  handleListItem={handleListItem}
                  selectedItem={selectedItem}
                  country={currentCountry?.country_name}
                  handleMouseOver={handleMouseOver}
                  handleMouseOut={handleMouseOut}
                  handleViewActivity={handleViewActivity}
                  handleMakeDefaultAfterSignIn={handleMakeDefaultAfterSignIn}
                  handleMakeFollowUnfollow={handleMakeFollowUnfollow}
                  signIn={signIn}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={9}
              style={{
                paddingLeft: "10px",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                height: "calc(100vh - 110px)",
              }}
            >
              <div style={{ flex: 1 }}>
                <Map
                  markers={searchCommunitiesList}
                  country={currentCountry}
                  countryCode={currentCountry?.country_code}
                  selectedItem={selectedItem}
                  setMap={setMap}
                  map={map}
                  key={currentCountry?.country_code}
                  showTooltip={showTooltip}
                  selectedMarker={selectedMarker}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "absolute",
                  top: "20px",
                  left: "40px",
                }}
              >
                <div className={buttonContainer} style={{ display: showSearchBeforeSignIn ? "block" : "none" }}>
                  <Button
                    variant={"outlined"}
                    style={{
                      borderRadius: "15px",
                      padding: "10px 17px",
                      textTransform: "none",
                    }}
                    onClick={handleStartCommunity}
                  >
                    <img src={AddIcon} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        alignItems: "baseline",
                        marginLeft: "20px",
                      }}
                    >
                      <p style={{ marginBottom: "-3px" }}>Start</p>
                      <p style={{ marginBottom: "2px" }}>Community</p>
                    </div>
                  </Button>
                </div>
              </div>
            </Grid>
            {openStartCommunity && (
              <StartCommunities
                open={openStartCommunity}
                close={handleClose}
                allCommunities={allCommunities}
                defaultCountryName={currentCountry?.country_name}
                defaultCountryDialCode={dialCode}
              />
            )}
          </Grid>
        </div>
      )}
    </>
  );
};

export default DefaultCommunity;
