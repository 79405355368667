import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal as BootstrapModal } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import sampledialogcancel from "src/Assets/images/Add.png";
import feedstyles from "src/Assets/css/home/feedpost.module.css";
import report from "src/Assets/images/report.svg";
import API from "../../network";

const defaultState = {
  OffensiveorInappropriate: false,
  Unrelated: false,
  duplicate: false,
  other: false,
};

function ReportDialog({ open, onClose, statusdata, id }) {
  const [state, setState] = useState({ ...defaultState });
  const [charCount, setCharCount] = useState(0);
  const [reason, setReason] = useState("");

  useEffect(() => {
    setCharCount(0);
    setState({ ...defaultState });
    setReason("");
  }, []);

  useEffect(() => {
    setCharCount(reason.length);
  }, [reason]);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setState({ ...defaultState, [name]: checked });
    setReason(name !== "other" ? name : "");
  };

  const onTextChange = (value) => setReason(value);

  const onReportSubmit = async () => {
    await API.reportPost(id, reason);
    onClose();
  };

  const isEnabled = () =>
    state.other
      ? charCount > 0 && charCount <= 280
      : state.OffensiveorInappropriate || state.Unrelated || state.duplicate;

  return (
    <BootstrapModal
      show={open}
      onHide={onClose}
      centered
      backdrop="static"
      style={{
        backdropFilter: "blur(0px)", // Apply backdrop blur effect
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark backdrop
      }}
    >
      <div className={feedstyles.reportedModal}>
        {statusdata === "notAbused" ? (
          <>
            <div className={feedstyles.sampledialogcountandcancel}>
              <p className={feedstyles.reportheading}>
                <b>Report this post</b>
              </p>
              <button
                onClick={() => {
                  onClose();
                  setReason("");
                  setState({ ...defaultState });
                }}
                className={feedstyles.closeButton}
              >
                <img src={sampledialogcancel} alt="Close" />
              </button>
            </div>

            <div>
              {[
                "OffensiveorInappropriate",
                "Unrelated",
                "duplicate",
                "other",
              ].map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <Checkbox
                      checked={state[type]}
                      onChange={handleChange}
                      name={type}
                      color="primary"
                    />
                  }
                  label={<p className={feedstyles.reportoption}>{type}</p>}
                />
              ))}

              {state.other && (
                <div>
                  <textarea
                    className={feedstyles.reporttextarea}
                    value={reason}
                    onChange={(e) => onTextChange(e.target.value)}
                  />
                  <div className="d-flex justify-content-end">
                    <label style={{ color: charCount > 280 ? "red" : "black" }}>
                      {charCount}/280
                    </label>
                  </div>
                </div>
              )}
            </div>
            <button
              type="button"
              className={feedstyles.reportsubmit}
              onClick={onReportSubmit}
              disabled={!isEnabled()}
              style={{
                backgroundColor: isEnabled() ? "#127ADD" : "#60A2E0",
              }}
            >
              Submit
            </button>
          </>
        ) : (
          <div className={feedstyles.sampledialogcountandcancel}>
            <p>This post has already been flagged abusive</p>
            <a onClick={onClose}>
              <img src={sampledialogcancel} alt="Close" />
            </a>
          </div>
        )}
      </div>
    </BootstrapModal>
  );
}

ReportDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  statusdata: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default function ReusableReportDialogModal(props) {
  const [open, setOpen] = useState(false);
  const [reportStatusData, setReportStatusData] = useState("");

  const handleClickOpen = async (id, param) => {
    const response = await API.getReportPostStatus(id, param);
    setReportStatusData(response.data);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <div>
      <a onClick={() => handleClickOpen(props.id, props.param)} key={props.id}>
        <img src={report} style={{ marginRight: 8 }} alt="Report" />
        <span style={{ fontSize: 14 }}>Report this post</span>
      </a>
      {reportStatusData && (
        <ReportDialog
          open={open}
          onClose={handleClose}
          statusdata={reportStatusData}
          id={props.id}
        />
      )}
    </div>
  );
}
