import React, { useEffect, useState } from "react";
import rightArrow from "src/Assets/images/rightArrow.svg";
import styles from "src/Assets/css/home/post.module.css";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import downarrow from "src/Assets/images/downarrow.png";
import backbutton from "src/Assets/images/locationtagbackbutton.svg";
import { Divider, Avatar, CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import * as feedactions from "../../store/actions/feedactions/feedActionCreator";
import API from '../../network';

const useStyles = makeStyles({
  paperWidthSm: {
    borderRadius: 25,
    width: 560,
    overflow: "hidden",
    maxWidth: "none",
  },
  rounded: {
    borderRadius: 12,
  },
});
function SimpleDialog(props) {
  // const communitiesData = useSelector(
  //   (state) => state.followReducer.communitiesData
  // );
  const { communitiesData } = props;
  const isLoading = useSelector((state) => state.followReducer.isLoaded);

  const classes = useStyles();

  const { onClose, open } = props;

  const [value, setValue] = React.useState(null);

  const [ischecked, setChecked] = React.useState([]);

  const handleChange = (event) => {
    setValue(event.target.value);
    // console.log(event.target.value)
  };

  const onCheckboxChecked = (event, id) => {
    // setChecked(event.target.checked)

    if (event.target.checked) {
      setChecked([...ischecked, id]);
    } else {
      setChecked((prev) => prev.filter((curritem) => curritem !== id));
    }
  };

  const handleClose = () => {
    onClose(false);
  };

  const onPostOptionSubmit = () => {
    // props.setPostingOption(value)
    handleClose();
    setValue(null);
    props.setCommunitysSelect(ischecked.length);
    props.setTenantId(ischecked);
    setChecked([]);
    // console.log(ischecked)
  };

  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      key={`simpleDialog2${props.id}`}
      classes={{ paperWidthSm: classes.paperWidthSm }}
      PaperProps={{ style: { maxHeight: "58%", overflow: "auto", scrollbarWidth: "none" } }}
    >
      <div className={styles.mapcontainer}>
        <div className={styles.addlocation}>
          <a onClick={handleClose}>
            <img src={backbutton} alt="backbutton" />
          </a>
          <h5 className={styles.locationheading}>Select Communities to Post</h5>
          <button
            className={
              ischecked.length > 0
                ? styles.locationSubmitAfterSelect
                : styles.locationSubmitBeforeSelect
            }
            onClick={onPostOptionSubmit}
          >
            Done
          </button>
        </div>
        <Divider />
        {/* <span>{ischecked.length>0?ischecked.length:null}</span> */}
        <div className={styles.moderatorcommunityparentdiv}>
          {!isLoading ? (
            communitiesData && Object.keys(communitiesData).length !== 0 ? (
              communitiesData.restrictions.map((community) => (
                <div className={styles.moderatorcommunitychilddiv}>
                  <div className={styles.moderatorcommunity}>
                    <img
                      src={`${profileImagePath}${community.thumbnailLogo}`}
                    />
                    <p>{community.tenantName}</p>
                  </div>
                  <div>
                    <input
                      className={styles.inputCheckbox}
                      type="checkbox"
                      id={`communityId${community.tenantId}`}
                      defaultChecked={
                        props.tenantName === community.tenantName ? true : false
                      }
                      onChange={(event) =>
                        onCheckboxChecked(event, community.tenantId)
                      }
                    />
                  </div>
                </div>
              ))
            ) : (
              <p> No Data</p>
            )
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function Multiselect(props) {
  // const dispatch = useDispatch();
  const [communities, setCommunities] = useState();

  useEffect(async () => {
    const response = await API.getModeratingCommunities();
    setCommunities(response.data);
  }, []);

  const [open, setOpen] = React.useState(false);
  const [isCommunitysSelect, setCommunitysSelect] = React.useState(null);

  useEffect(() => {
    props.setCommunitySelectCount(isCommunitysSelect);
  }, [isCommunitysSelect]);

  const handleClickOpen = () => {
    setOpen(true);
    // dispatch(feedactions.getModeratingCommunitys());
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* <div
        onClick={handleClickOpen}
        className={styles.selectmultiplecommunitys}
      >
        <p>
          {isCommunitysSelect > 0 ? (
            <>
              ,<span className={styles.selectCount}>+{isCommunitysSelect}</span>
            </>
          ) : null}
          <span className={styles.selectCommunityBtn}>
            (Select Communities)
          </span>
        </p>
        <img src={rightArrow} alt="rightarrow" />
      </div> */}
      <SimpleDialog
        open={props.open}
        onClose={props.closePopup}
        tenantName={props.tenantName}
        setCommunitysSelect={setCommunitysSelect}
        setTenantId={props.setTenantId}
        communitiesData={communities}
      />
    </>
  );
}

export default Multiselect;
