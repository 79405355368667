// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
  }
  
  .google-map {
    width: 100%;
    height: 60vh;
  }
  
  .pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: var(--main-blue);
  }
  
  .pin-icon {
    font-size: 4rem;
  }
  
  .pin-text {
    font-size: 1.3em;
  }

  .ContactAddress{
      position: absolute;
      /* width: 100%; */
      z-index: 3;
      width: 95%;
      top: 10.5%;
min-width: 600px;
min-height: 45px;
border-radius: 25px;
outline: none;
border: none;
box-sizing: border-box;
box-shadow: 2px 2px 2px 0px #888;
padding-left: 15px;
  }
  .Address{
    color: #4795DE !important;
  }
  
  @media screen and (min-width: 799px) {
    .google-map {
      height: 60vh;

    }
  
    .map-h2 {
      font-size: 1.3rem;
      font-weight: 400;
    }
  
    .pin {
      width: 15vw;
    }
  
    .pin-icon {
      font-size: 10vw;
    }
  }`, "",{"version":3,"sources":["webpack://./src/Assets/css/ReusuableComponents/aboutMap.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;EACzB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;MACI,kBAAkB;MAClB,iBAAiB;MACjB,UAAU;MACV,UAAU;MACV,UAAU;AAChB,gBAAgB;AAChB,gBAAgB;AAChB,mBAAmB;AACnB,aAAa;AACb,YAAY;AACZ,sBAAsB;AACtB,gCAAgC;AAChC,kBAAkB;EAChB;EACA;IACE,yBAAyB;EAC3B;;EAEA;IACE;MACE,YAAY;;IAEd;;IAEA;MACE,iBAAiB;MACjB,gBAAgB;IAClB;;IAEA;MACE,WAAW;IACb;;IAEA;MACE,eAAe;IACjB;EACF","sourcesContent":[".map-h2 {\n    text-transform: uppercase;\n    font-size: 1rem;\n    padding: 20px;\n    padding-left: 10px;\n    text-align: center;\n  }\n  \n  .google-map {\n    width: 100%;\n    height: 60vh;\n  }\n  \n  .pin {\n    display: flex;\n    align-items: center;\n    width: 180px;\n    color: var(--main-blue);\n  }\n  \n  .pin-icon {\n    font-size: 4rem;\n  }\n  \n  .pin-text {\n    font-size: 1.3em;\n  }\n\n  .ContactAddress{\n      position: absolute;\n      /* width: 100%; */\n      z-index: 3;\n      width: 95%;\n      top: 10.5%;\nmin-width: 600px;\nmin-height: 45px;\nborder-radius: 25px;\noutline: none;\nborder: none;\nbox-sizing: border-box;\nbox-shadow: 2px 2px 2px 0px #888;\npadding-left: 15px;\n  }\n  .Address{\n    color: #4795DE !important;\n  }\n  \n  @media screen and (min-width: 799px) {\n    .google-map {\n      height: 60vh;\n\n    }\n  \n    .map-h2 {\n      font-size: 1.3rem;\n      font-weight: 400;\n    }\n  \n    .pin {\n      width: 15vw;\n    }\n  \n    .pin-icon {\n      font-size: 10vw;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
