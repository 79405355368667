import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import sampledialogcancel from 'src/Assets/images/Add.png'
import feedstyles from 'src/Assets/css/home/feedpost.module.css'
import sendanemail from 'src/Assets/images/sendemail.svg'
import PostMessageValidation from './PostMessageValidation';

function SendEmailDialog(props) {
    const { onClose, open } = props;
    const handleClose = () => {
        onClose();
    };
    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} >
            <div className={feedstyles.reportdialog}>

                <div className={feedstyles.sampledialogcountandcancel}>
                    <p className={feedstyles.reportheading}><b>Send email </b></p>
                    <a onClick={handleClose}><img src={sampledialogcancel} /></a>
                </div>

                <div>
                    <PostMessageValidation messageType={"sendaMail"} displayName={props.displayName} id={props.id} onClose={handleClose}/>
                </div>

            </div>

        </Dialog>
    );
}

SendEmailDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default function ReusuableSendaMailModal(props) {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (id) => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>

            <span style={{ marginLeft: 0 }} onClick={() => handleClickOpen(props.id)} key={props.id}>
                <img src={sendanemail} style={{ marginRight: 8 }} />
                <span style={{ fontSize: 14 }}>Send email to {props.displayName}</span>
            </span>

            <SendEmailDialog open={open} onClose={handleClose} id={props.id} displayName={props.displayName} />

        </div>
    );
}
