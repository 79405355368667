import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Cookies from "js-cookie";
import { Button, IconButton } from "@mui/material";

import {
  GoogleMap,
  Polygon,
  DrawingManager,
  MarkerF,
  InfoWindowF,
  useJsApiLoader,
} from "@react-google-maps/api";

import EditIcon from "../../../Assets/images/LandingPageImages/editIcon.svg";
import EditActiveIcon from "../../../Assets/images/LandingPageImages/editActiveIcon.svg";
import ArrowRightAltTwoToneIcon from "@mui/icons-material/ArrowRightAltTwoTone";
import LiveLocationIcon from "../../../Assets/images/liveLocationIcon.png";
import customMapStyles from "../map/customMapStyles";

const containerStyle = {
  width: "100%",
  height: "80vh",
  borderRadius: "20px",
};

const StartMap = (props = {}) => {
  const history = useHistory();

  const {
    id,
    name,
    description,
    city,
    country,
    state,
    address1,
    zipcode,
    tenantLatitude,
    tenantLangitude,
    newsletterFrequency,
    markers,
    polygonCoords,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formattedList = (data) => {
    return (data || []).map((obj) => {
      return {
        lat: Number(obj.lat),
        lng: Number(obj.lng),
      };
    });
  };

  const allMarkers = formattedList(markers);

  const latArry = tenantLatitude?.split(",");
  const lngArry = tenantLangitude?.split(",");

  const latlng = latArry?.map((latItem, index) => {
    const lngItem = lngArry[index];
    return {
      lat: Number(latItem),
      lng: Number(lngItem),
    };
  });

  // Store Polygon path in state
  const [coords, setCoords] = useState([]);
  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
  const coordsRef = useRef([]);
  const [drawingState, setDrawingState] = useState({
    drawingMode: "",
  });
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const libraries = ["drawing", "places"];
  const [curLocation, setCurLocation] = useState({ lat: 0, lng: 0 });
  const [bounds, setBounds] = useState(null);
  const [searchBoxRef, setSearchBoxRef] = useState(null);
  const [searchLoc, setSearchLoc] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAXEHjz5uTArWEC0q8-zpu_xXuHXJ2zOl8",
    libraries: libraries,
    language: "en",
    region: "us",
  });

  const options = {
    drawingControl: false,
    drawingControlOptions: {
      drawingMode:
        window &&
        window.google &&
        window.google.maps &&
        window.google.maps.drawing &&
        window.google.maps.drawing.OverlayType.POLYGON,
    },
    polygonOptions: {
      strokeColor: "#373334",
      fillColor: "##707070",
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zindex: 1,
    },
  };

  useEffect(() => {
    if (tenantLatitude && tenantLatitude) {
      setCoords(latlng);
      coordsRef.current = latlng;
    } else {
      // setCoords([]);
    }
  }, []);

  const removeBoundary = () => {
    polygonRef.current.setMap(null);
    setCoords([]);
    coordsRef.current = [];
    if (props.getLatLng) {
      props.getLatLng({});
    }
  };
  const onSubmitBoundary = () => {
    props.onMapClose();
    setIsSubmitting(false);
    const latlngData = coordsRef?.current;
    const lat = latlngData.map((item) => {
      return item.lat;
    });
    const lng = latlngData.map((item) => {
      return item.lng;
    });

    props.getLatLng({ lat: lat, lng: lng });
  };

  // Call setCoords with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setCoords(nextPath);
      coordsRef.current = nextPath;
    }
  }, [setCoords]);

  // Bind refs to current Polygon and listeners
  const onPolygonLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const onPolygonComplete = (polygon) => {
    const polyArray = polygon.getPath().getArray();
    let paths = [];
    polyArray.forEach(function (path) {
      paths.push({ lat: path.lat(), lng: path.lng() });
    });
    polygon.setMap(null);
    setCoords(paths);
    coordsRef.current = paths;
    setShowInfoWindow(true);
  };

  const getCurrentLocation = async () => {
    const res = await axios.get("http://ip-api.com/json");
    if (res.status === 200)
      setCurLocation({ lat: res.data?.lat, lng: res.data?.lon });
  };

  const success = (position) => {
    const lat = position?.coords?.latitude;
    const lng = position?.coords?.longitude;
    setCurLocation({ lat, lng });
  };

  const error = () => {
    getCurrentLocation();
  };

  const onMapLoad = (map) => {
    // if (coords.length !== 0) {
    //   setCurLocation(coords[0]);
    // } else if (allMarkers.length !== 0) {
    //   setCurLocation(allMarkers[0]);
    // }
    // else {
    if (navigator?.geolocation) {
      navigator?.geolocation.getCurrentPosition(success, error);
    }
    window.google?.maps.event.addListener(map, "bounds_changed", () => {
      setBounds(map.getBounds());
    });
    // setCoords([]);
    // }
  };

  const onSearchBoxLoad = (ref) => {
    setSearchBoxRef(ref);
  };

  const onPlacesChanged = () => {
    setSearchLoc(searchBoxRef?.getPlaces());
    let results = searchBoxRef.getPlaces();
    const loc = results[0].geometry.location;
    const pos = { lat: loc.lat(), lng: loc.lng() };
    setCurLocation(pos);
  };

  const showPolygon = (obj) => {
    const polygonItem = polygonCoords.find((coord) =>
      coord.some((item) => Number(item.lat) === obj.lat)
    );
    if (polygonItem) {
      const paths = formattedList(polygonItem);
      setCoords(paths);
    }
  };

  const handleEdit = () => {
    if (drawingState?.drawingMode) {
      setDrawingState({ drawingMode: "" });
      setIsEdit(false);
    } else {
      setDrawingState({ drawingMode: "polygon" });
      setIsEdit(true);
    }
  };

  const getMapTypeControls = () => {
    const defaultMapOptions = {
      styles: customMapStyles,
    };

    return {
      ...defaultMapOptions,
      mapTypeControl: false,
      rotateControl: false,
      fullscreenControl: false,
      zoomControl: true,
      streetViewControl: false,
      disableDefaultUI: false,
    };
  };

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={curLocation}
          zoom={12}
          onLoad={onMapLoad}
          options={getMapTypeControls()}
        >
          {isEdit ? (
            <div
              style={{ position: "absolute", bottom: 0, marginBottom: "12px" }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={onSubmitBoundary}
                disabled={coords.length === 0}
                style={{
                  marginLeft: "8px",
                  backgroundColor: coords.length === 0 ? "#D8D8D8" : "#EFEEED",
                  color: "#4795DE",
                  fontSize: "12px",
                  fontWeight: "normal",
                  borderRadius: "20px",
                }}
              >
                Submit Boundary
              </Button>
              <Button
                style={{
                  marginLeft: "8px",
                  backgroundColor: coords.length === 0 ? "#D8D8D8" : "#EFEEED",
                  color: "#4795DE",
                  fontSize: "12px",
                  fontWeight: "normal",
                  borderRadius: "20px",
                }}
                variant="contained"
                size="small"
                disabled={coords.length === 0}
                onClick={removeBoundary}
              >
                Remove Boundary
              </Button>
            </div>
          ) : null}
          {
            <>
              {!isEdit && (
                <div
                  style={{
                    position: "absolute",
                    left: "58%",
                    top: "2%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F3F2F0",
                      color: "#373334",
                      display: "flex",
                      padding: "10px",
                      borderRadius: "10px",
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px",
                    }}
                  >
                    Begin mapping your community <ArrowRightAltTwoToneIcon />
                  </div>
                </div>
              )}
              {isEdit && coords.length === 0 && (
                <div
                  style={{
                    position: "absolute",
                    left: "57%",
                    top: "2%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F3F2F0",
                      color: "#373334",
                      display: "flex",
                      padding: "10px",
                      borderRadius: "10px",
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px",
                    }}
                  >
                    Click map to create boundary points
                  </div>
                </div>
              )}
              {isEdit && coords.length !== 0 && (
                <div
                  style={{
                    position: "absolute",
                    left: "59%",
                    top: "2%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F3F2F0",
                      color: "#373334",
                      display: "flex",
                      padding: "10px",
                      borderRadius: "10px",
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px",
                    }}
                  >
                    Submit your boundary for approval
                  </div>
                </div>
              )}
              <div
                style={{
                  position: "absolute",
                  left: "94%",
                  top: "2%",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  boxShadow: "0px 3px 6px #00000029",
                }}
              >
                <IconButton onClick={handleEdit}>
                  {/* <EditIcon /> */}
                  <img src={isEdit ? EditActiveIcon : EditIcon} />
                </IconButton>
                {/* <img src={}/> */}
              </div>
            </>
          }
          <DrawingManager
            drawingMode={drawingState?.drawingMode}
            options={options}
            editable
            draggable
            onPolygonComplete={onPolygonComplete}
            onMouseUp={onEdit}
            onDragEnd={onEdit}
          />
          {coords?.length > 0 && (
            <Polygon
              options={{
                // fillColor: "#2196F3",
                // strokeColor: "#2196F3",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                strokeColor: "#373334",
                fillColor: "##707070",
                fillOpacity: 0.35,
                geodesic: true,
                editable: true,
              }}
              ref={polygonRef}
              editable
              path={coords}
              onLoad={onPolygonLoad}
              onUnmount={onUnmount}
              onMouseUp={onEdit}
              onDragEnd={onEdit}
            >
              {showInfoWindow && (
                <InfoWindowF
                  // position={center}
                  zIndex="10"
                  onCloseclick={(e) => setShowInfoWindow(false)}
                  options={{ maxWidth: 300 }}
                >
                  content here
                </InfoWindowF>
              )}
            </Polygon>
          )}
          {markers ? (
            <>
              {allMarkers.map((marker) => (
                <MarkerF
                  position={marker}
                  onClick={() => showPolygon(marker)}
                />
              ))}
            </>
          ) : (
            <MarkerF
              position={curLocation}
              icon={{
                url: LiveLocationIcon,
                scaledSize: new window.google.maps.Size(30, 30),
              }}
            // onClick={(e) => setShowInfoWindow(true)}
            />
          )}
        </GoogleMap>
      )}
    </>
  );
};

export default StartMap;
