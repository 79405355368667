// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommunityInfo_editCommBtn__96tZX {
  display: flex;
  align-items: center;
  margin: 0;
}
.CommunityInfo_editCommImg__e72SW {
  width: 20px;
  height: 20px;
}
.CommunityInfo_button__1Z41k {
  outline: none;
  box-shadow: none;
  min-width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Moderator/EditCommunity/CommunityInfo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".editCommBtn {\r\n  display: flex;\r\n  align-items: center;\r\n  margin: 0;\r\n}\r\n.editCommImg {\r\n  width: 20px;\r\n  height: 20px;\r\n}\r\n.button {\r\n  outline: none;\r\n  box-shadow: none;\r\n  min-width: 100px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editCommBtn": `CommunityInfo_editCommBtn__96tZX`,
	"editCommImg": `CommunityInfo_editCommImg__e72SW`,
	"button": `CommunityInfo_button__1Z41k`
};
export default ___CSS_LOADER_EXPORT___;
