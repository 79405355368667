// import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  googleMapStyle: () => ({
    "& .gm-style .gm-style-cc a, .gm-style .gm-style-cc button, .gm-style .gm-style-cc span":
      {
        display: "none !important",
      },
    height: "112% !important",
    borderRadius: "20px",
    boxShadow: "0px 6px 6px #d0cbcb",
    "& button": {
      // display: "none !important",
    },
  }),
});

export default useStyles;
