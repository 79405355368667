// export const ignoreSpacesAndUrlsFromMsg = (message) => {
//   const msgStr = message ? message : "";
//   let inputDataLen = msgStr.length;
//   const inputText = msgStr?.replace(/\n/g, " ");
//   const strArray = inputText.split(" ");
//   let validLetterCount = 0;
//   let i;

//   for (i = 0; i < strArray.length; i++) {
//     if (
//       strArray[i].indexOf("http://") == -1 &&
//       strArray[i].indexOf("www.") == -1 &&
//       strArray[i].indexOf("https://") == -1 &&
//       strArray[i].indexOf("HTTP://") == -1 &&
//       strArray[i].indexOf("WWW.") == -1 &&
//       strArray[i].indexOf("HTTPS://") == -1
//     ) {
//       validLetterCount = validLetterCount + strArray[i].length;
//     } else if (
//       strArray[i].indexOf("http://") &&
//       strArray[i].indexOf("www.") &&
//       strArray[i].indexOf("https://") &&
//       strArray[i].indexOf("HTTP://") &&
//       strArray[i].indexOf("WWW.") &&
//       strArray[i].indexOf("HTTPS://")
//     ) {
//       validLetterCount = validLetterCount + strArray[i].length;
//     }
//   }

//   inputDataLen = validLetterCount;

//   return {
//     msgLength: inputDataLen,
//     hasMessage: msgStr?.length > 0,
//   };
// };

//I implemented below functionality so that user can post link also and space should count after character as per requirement

export const ignoreSpacesAndUrlsFromMsg = (message) => {
  const msgStr = message || ""; // Ensure the message is a valid string
  let firstNonSpaceFound = false; // Tracks if a non-space character has been found
  let validCharCount = 0; // Counts characters after the first non-space character

  for (let char of msgStr) {
    if (!firstNonSpaceFound && char.trim().length > 0) {
      // Found the first non-space character
      firstNonSpaceFound = true;
    }
    if (firstNonSpaceFound) {
      validCharCount++; // Count characters after the first non-space
    }
  }

  return {
    msgLength: validCharCount, // Return the count of valid characters
    hasMessage: validCharCount > 0, // Check if the message has any valid content
  };
};


export const replaceTagAndUrl = (Message, msg, tags, urls, callback) => {
  if (msg && Array.isArray(urls) && urls.length) {
    return <div>{createMarkup(msg, urls)}</div>;
  } else if (msg && Array.isArray(tags) && tags.length && callback) {

    // Create a regular expression to match the hashtags
    const regex = new RegExp(`(${tags.join("|")})`, "g");

    const handleTagClick = (tag) => {
      console.log(`Tag clicked: ${tag}`);
      callback && callback(tag);
    };

    // Split the message and map over it to replace hashtags with <a> tags
    const parts = msg.split(regex).map((part, index) => {
      if (part && tags.includes(part)) {
        return (
          <a
            key={index}
            href={`#${part.slice(1)}`}
            onClick={(e) => {
              e.preventDefault(); // Prevent default anchor behavior
              handleTagClick(part); // Call the onTagClick handler with the tag
            }}
          >
            {part}
          </a>
        );
      }
      return part;
    });

    return <div>{parts}</div>;
  } else {
    return Message;
  }
};

export const createMarkup = (msg, urls) => {
  const urlRegex = /(\bhttps?:\/\/[^\s/$.?#].[^\s]*|\bwww\.[^\s/$.?#].[^\s]*)/g;
  const msgArray = msg.split(urlRegex);
  return msgArray.map((part, index) => {
    if (urls.includes(part)) {
      // For each URL, wrap it in an anchor tag
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          related web link
        </a>
      );
    }
    return part; // If it's not a URL, return the part of the string as plain text
  });
};
