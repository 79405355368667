import ApiSheet from "./api_sheet";

class HomeRepo {

    static landApi = async (tenantId, tenantName) => {
        return await fetch(ApiSheet.LandApi + "?redirectTenantId=" + tenantId + "&name=" + tenantName, {
            method: 'POST',
        }).then(res => res.json()).then(res => {
            return res.status === "OK"
        }).catch(error => {
            console.log("HomeRepo>landApi Error:" + error)
            return false;
        })
    }

    static userData = async () => {

    }

}

export default HomeRepo;