import React from "react";
import { TextField, IconButton, InputAdornment } from "@material-ui/core";
import { Link } from "@mui/material";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
import { IoLocation } from "react-icons/io5";
import { BsInfoCircle } from "react-icons/bs";
import useStyles from "./styles";

const DefaultTextBox = ({ value, handleLocation, locationIconDisabled }) => {
  const { defaultCommunity } = useStyles();
  return (
    <div className={defaultCommunity}>
      <Link
        disabled={locationIconDisabled}
        component="button"
        variant="body2"
        underline="none"
        onClick={handleLocation}
        placeholder="Default Community"
        style={{
          fontSize: "18px",
          cursor: locationIconDisabled ? "default" : "pointer",
          pointerEvents: locationIconDisabled && "none",
        }}
      >
        {value ? value : "Default Community"}
      </Link>
      {/* <TextField
        variant="standard"
        value={value}
        placeholder="Default Community"
        disabled={locationIconDisabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled>
                <InfoOutlinedIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      /> */}
      <IconButton disabled={locationIconDisabled} onClick={handleLocation}>
        <IoLocation color="#FF7B7B" fontSize={18} />
      </IconButton>
      <IconButton disabled style={{ marginLeft: "auto" }}>
        <BsInfoCircle fontSize={18} />
      </IconButton>
    </div>
  );
};

export default DefaultTextBox;
