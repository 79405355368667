import axios from "axios";
import * as Cookies from "js-cookie";
//Action imports
import * as ActivityInfoActions from "../../actions/Activity/ActivityAction"
import * as allActions from "../../actions/actions.constants";
import API from '../../../network';

const ActivityInfoService = (store) => (next) => (action) => {
  next(action);
  switch (action.type) {
    case allActions.FETCH_ACTIVITY_DATA:
      API.fetchActivity()
        .then((res) => {
          next(ActivityInfoActions.receiveActivityData(res.data));
        });
      break;

    default:
      break;
  }
};
export default ActivityInfoService;
