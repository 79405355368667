// import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  defaultCommunity: () => ({
    display: "flex",
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#4795DE !important",
      fontSize: "18px",
    },
    "& input": {
      color: "#4795DE !important",
      fontSize: "16px",
      maxWidth: "88%",
      cursor: "pointer",
      height: "35px",
    },
    "& .MuiIconButton-root": {
      padding: "0 !important",
    },
  }),
});

export default useStyles;
