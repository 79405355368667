import React, { useEffect, useState } from "react";
import "../../styles/uploadPhotoTimelineStyle.css";
// import CloseIcon from "@mui/icons-material/Close";
import PostPhotoItem from "../components/PostPhotoItem";
import PhotoTimelineRepo from "../../repo/photo_timeline_repo";
import closebutton from "src/Assets/images/CloseButton.svg";
import Api from "src/network";
import "./index.css";
import { compressFile } from "src/Utils/imageCompressionUtil";

export default function UploadPhotoTimeLineDialog({
  tenantId,
  zIndex,
  showDialog,
  onCancelDialog,
  onUploadSuccess,
}) {
  let [photosList, setPhotosList] = useState([]);
  const [isLocationSelect, setIsLocationSelect] = useState("");
  const [isLat, setLat] = useState("");
  const [isLng, setLng] = useState("");
  const [open, setOpen] = useState(false);

  const removeImageClick = (id) => {
    const list = photosList.filter((item) => item.id !== id);
    // Update the state with the new array
    setPhotosList(list);
    console.log("Id: " + id);
  };

  const onDescriptionChange = (e, id) => {
    const list = photosList.map((item) =>
      item.id === id ? { ...item, description: e.target.value } : item
    );
    // Update the state with the new list
    setPhotosList(list);
  };

  const onPostClick = async (event) => {
    event.preventDefault();

    let promises = [];

    for (let item of photosList) {
      const formData = new FormData();
      formData.append("tmlPhotoDescs", "[" + item.description + "]");
      formData.append("tmlPhotoLoc", "[" + item.coordinates + "]");
      formData.append("locName", "[" + item.location + "]");
      formData.append("imgs", item.imageFile);

      promises.push(Api.uploadImage(tenantId, formData));

      // const result = await PhotoTimelineRepo.uploadPhotoTimeline(
      //   item.id,
      //   tenantId,
      //   item.imageFile,
      //   item.description,
      //   item.coordinates,
      //   item.location
      // );
      // if (result.isSuccess) {
      //   removeImageClick(result.tempImageId)
      // }
      // await new Promise(resolve => setTimeout(resolve, 1000));
      // console.log(result);
    }

    try {
      const results = await Promise.all(promises);
      // console.log(results);
      setPhotosList([]);
      onUploadSuccess && onUploadSuccess();
      onCancelDialog && onCancelDialog();
    } catch (e) {
      console.error(e);
    }
  };

  const onAddPhotoClick = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.log("No file selected");
      return;
    }

    const compressedFile = await compressFile(file);

    const newPhoto = {
      id: photosList.length,
      description: "",
      imageFile: compressedFile,
      location: "",
      coordinates: "",
    };

    // Create a new array with the new photo added
    const updatedList = [...photosList, newPhoto];

    // Update the state with the new array
    setPhotosList(updatedList);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const onLocationSubmit = (id) => {
    const list = photosList.map((item) =>
      item.id === id
        ? {
          ...item,
          location: isLocationSelect,
          coordinates: `${isLat},${isLng}`,
        }
        : item
    );
    // Update the state with the new list
    setPhotosList(list);
  };

  return (
    <section
      className="uploadPhotoTimelineDialog popup-background active"
      style={{ zIndex: zIndex, display: showDialog ? "flex" : "none" }}
    >
      <div className="photo-dialog">
        <div className="photo-dialog-header">
          <p>Post Photo to Timeline</p>
          <div className="close-icon" onClick={onCancelDialog}>
            <img src={closebutton} alt="icon" />
          </div>
        </div>

        <div className="photo-list-container">
          {photosList.length > 0 ? (
            photosList.map((e) => (
              <PostPhotoItem
                key={`key-${e?.id}`}
                id={e.id}
                description={e.description}
                imageFile={e.imageFile}
                location={e.location}
                removeImageClick={removeImageClick}
                onDescriptionChange={onDescriptionChange}
                handleClick={handleClick}
                isLocationSelect={isLocationSelect}
                setIsLocationSelect={setIsLocationSelect}
                setLng={setLng}
                setLat={setLat}
                onLocationSubmit={onLocationSubmit}
                setOpen={setOpen}
                open={open}
              />
            ))
          ) : (
            <div className="no-data-container">
              <p>No Data Found. Please Select Photos.</p>
            </div>
          )}
        </div>

        <form
          className="uploadPhotoBottom save-button-container"
          method="post"
          encType="multipart/form-data"
          style={{ width: "100%", display: "flex" }}
        >
          <div style={{ width: "80%", display: "flex", justifyContent: "flex-start" }}>
            <input
              className="addPhotoButton"
              type="file"
              accept="image/*" // Limit to image files
              onChange={onAddPhotoClick}
            />
          </div>
          <div style={{ width: "20%", display: "flex", justifyContent: "flex-end" }}>
            <button
              type="submit"
              className="post-button"
              onClick={onPostClick}
              value="Post"
            >Post</button>
          </div>
        </form>
      </div>
    </section>
  );
}
