export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60.313"
      height="20.316"
      viewBox="0 0 60.313 20.316"
    >
      <g
        id="Group_1846"
        data-name="Group 1846"
        transform="translate(-26.063 -695)"
      >
        <text
          id="ASK"
          transform="translate(59.376 710)"
          fill="#373334"
          fontSize="14"
          fontFamily="AcuminPro-Regular, Acumin Pro"
        >
          <tspan x="0" y="0">
            ASK
          </tspan>
        </text>
        <g
          id="Group_1721"
          data-name="Group 1721"
          transform="translate(-15716 -2441.921)"
        >
          <path
            id="Path_509"
            data-name="Path 509"
            d="M270.891,256.525l8.346,10.785-8.323-2.91"
            transform="translate(15488.138 2889.927)"
            fill="#2168b2"
          />
          <path
            id="Path_510"
            data-name="Path 510"
            d="M240.435,253.575c-.087,5.054,5.509,9.283,12.5,9.447s12.725-3.8,12.812-8.852-5.509-9.282-12.5-9.448S240.52,248.522,240.435,253.575Z"
            transform="translate(15501.629 2892.205)"
            fill="#2168b2"
          />
          <text
            id="Q"
            transform="translate(15748.999 3151)"
            fill="#fff"
            fontSize="14"
            fontFamily="Helvetica"
          >
            <tspan x="0" y="0">
              Q
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}