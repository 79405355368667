import React, { useEffect, useState } from "react";
import "src/Assets/css/home/AsksModal.css";
import { Modal, Button, ModalFooter } from "react-bootstrap";
import Post from "./Post";
import { CircularProgress } from "@material-ui/core";
import style from "src/Assets/css/home/post.module.css";
import NoDataFound from "src/Assets/images/data-not-found.svg";
import styles from "src/Assets/css/home/Feed.module.css";

function AsksModal(props) {
  const {
    loading,
    childSaysLoading,
    answersToYourAsk,
    asksToBeAnswered,
    isPostOption,
    setSaysShow,
    askYourCommunity,
    fetchFeedData,
    postChildSayData,
    deleteMessage,
    filterPosts,
    childSaysData,
    fetchChildSaysData,
    fetchFollowData,
    fetchVoteUpStatus,
    deleteAnswersToAsksDialogPost,
    deleteAsksToBeAnsweredDialogPost,
    fetchContributors
  } = props;
  // const AskCanAns = useSelector(state => state.followReducer.sayData)
  // const saysCanAns = useSelector(state => state.followReducer.askData)
  // const isLoaded = useSelector(state => state.followReducer.isLoaded)

  const [modalText, setModalText] = useState("");
  const [lgShow, setLgShow] = useState(false);

  useEffect(() => {
    if (isPostOption === "ASK") {
      setModalText("Possible answers to your ASK");
    } else if (isPostOption === "SAY") {
      setModalText("A few ASKs you could answer");
    } else {
      setModalText("");
    }
  }, [isPostOption]);

  const onAskCommunitySelection = (id) => {
    if (isPostOption === "ASK") {
      if (answersToYourAsk.messageConnectionStatus === "E") {
        setLgShow(true);
      } else {
        setTimeout(() => {
          filterPosts("");
        }, 500);
      }
    }

    askYourCommunity(id);
    // dispatch(feedactions.askYourCommunity(id));

    //TODO: remove filterPosts  once subscription data is added
    //ideally when we post to comunity, subscription should add to existing list
    //no need of calling filterPosts again
    setSaysShow(false);
  };

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        className="my-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="title"
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              padding: "10px",
              paddingBottom: "0px",
            }}
          >
            {modalText}
          </Modal.Title>
        </Modal.Header>

        {/* i have commented the modal body to chcek the UI design  */}
        <Modal.Body className="ModalPosts">
          {loading ? (
            <CircularProgress className="loader" />
          ) : answersToYourAsk &&
            answersToYourAsk.messageList &&
            answersToYourAsk.messageList.length > 0 &&
            isPostOption === "ASK" ? (
            <Post
              deleteMessage={deleteMessage}
              fetchFeedData={fetchFeedData}
              postChildSayData={postChildSayData}
              loading={childSaysLoading}
              listdata={answersToYourAsk.messageList}
              data={answersToYourAsk}
              // isAskModal="isAskModal"
              isPostOption={isPostOption}
              childSaysData={childSaysData}
              fetchChildSaysData={fetchChildSaysData}
              fetchFollowData={fetchFollowData}
              fetchVoteUpStatus={fetchVoteUpStatus}
              deleteAnswersToAsksDialogPost={deleteAnswersToAsksDialogPost}
              fetchContributors={fetchContributors}
            />
          ) : asksToBeAnswered &&
            asksToBeAnswered.messageList &&
            asksToBeAnswered.messageList.length > 0 &&
            isPostOption === "SAY" ? (
            <Post
              deleteMessage={deleteMessage}
              fetchFeedData={fetchFeedData}
              postChildSayData={postChildSayData}
              loading={childSaysLoading}
              listdata={asksToBeAnswered.messageList}
              data={asksToBeAnswered}
              isAskModal="isAskModal"
              isPostOption={isPostOption}
              childSaysData={childSaysData}
              fetchChildSaysData={fetchChildSaysData}
              fetchFollowData={fetchFollowData}
              fetchVoteUpStatus={fetchVoteUpStatus}
              deleteAsksToBeAnsweredDialogPost={deleteAsksToBeAnsweredDialogPost}
              fetchContributors={fetchContributors}
            />
          ) : (
            <div className={style.notFoundImageContainer}>
              <img src={NoDataFound} alt="not found image" />
              <p className="Message">Sorry! No Results Found</p>
            </div>
          )}
        </Modal.Body>
        <ModalFooter>
          <div className={style.modalFooter}>
            {!loading ? (
              (answersToYourAsk &&
                answersToYourAsk?.messageList &&
                Object.keys(answersToYourAsk.messageList).length > 0) ||
                (asksToBeAnswered &&
                  asksToBeAnswered?.messageList &&
                  Object.keys(asksToBeAnswered.messageList).length > 0) ? (
                <div>
                  {isPostOption === "ASK" && <p>Not happy with results?</p>}
                </div>
              ) : (
                <div>{isPostOption === "ASK" && <p></p>}</div>
              )
            ) : null}

            {
              isPostOption === "ASK" ? (
                <button
                  className="PostCommunity askCommunity"
                  onClick={() =>
                    onAskCommunitySelection(
                      answersToYourAsk.messageConnectionID
                    )
                  }
                >
                  ASK Community
                </button>
              ) : null
              // <button className="PostCommunity sayCommunity">SAY Community</button>
            }
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        centered // This prop will center the modal vertically and horizontally
        className={styles.restrictedModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Thank You</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your ASK will publish as soon as the Community Moderator approves it.
        </Modal.Body>
      </Modal>
    </>
  );
}
export default AsksModal;
