const countries = [
  // { code: 0, label: "Select Country" },
  { code: 1, label: "United States" },
  { code: 7, label: "Russian Federation" },
  { code: 20, label: "Egypt" },
  { code: 27, label: "South Africa" },
  { code: 30, label: "Greece" },
  { code: 31, label: "Netherlands" },
  { code: 32, label: "Belgium" },
  { code: 33, label: "France" },
  { code: 34, label: "Spain" },
  { code: 36, label: "Hungary" },
  { code: 39, label: "Italy" },
  { code: 40, label: "Romania" },
  { code: 41, label: "Switzerland" },
  { code: 43, label: "Austria" },
  { code: 44, label: "United Kingdom" },
  { code: 45, label: "Denmark" },
  { code: 46, label: "Sweden" },
  { code: 47, label: "Norway" },
  { code: 48, label: "Poland" },
  { code: 49, label: "Germany" },
  { code: 51, label: "Peru" },
  { code: 52, label: "Mexico" },
  { code: 53, label: "Cuba" },
  { code: 54, label: "Argentina" },
  { code: 55, label: "Brazil" },
  { code: 56, label: "Chile" },
  { code: 57, label: "Colombia" },
  { code: 58, label: "Venezuela" },
  { code: 60, label: "Malaysia" },
  { code: 61, label: "Australia" },
  { code: 62, label: "Indonesia" },
  { code: 63, label: "Philippines" },
  { code: 64, label: "New Zealand" },
  { code: 65, label: "Singapore" },
  { code: 66, label: "Thailand" },
  { code: 81, label: "Japan" },
  { code: 82, label: "Korea- Republic of" },
  { code: 84, label: "Viet Nam" },
  { code: 86, label: "China" },
  { code: 90, label: "Turkey" },
  { code: 91, label: "India" },
  { code: 92, label: "Pakistan" },
  { code: 93, label: "Afghanistan" },
  { code: 94, label: "Sri Lanka" },
  { code: 95, label: "Myanmar" },
  { code: 98, label: "Iran" },
  { code: 212, label: "Morocco" },
  { code: 213, label: "Algeria" },
  { code: 216, label: "Tunisia" },
  { code: 218, label: "Libya" },
  { code: 220, label: "Gambia" },
  { code: 221, label: "Senegal" },
  { code: 222, label: "Mauritania" },
  { code: 223, label: "Mali" },
  { code: 224, label: "Guinea" },
  { code: 225, label: "Cote D'Ivoire" },
  { code: 226, label: "Burkina Faso" },
  { code: 227, label: "Niger" },
  { code: 228, label: "Togo" },
  { code: 229, label: "Benin" },
  { code: 230, label: "Mauritius" },
  { code: 231, label: "Liberia" },
  { code: 232, label: "Sierra Leone" },
  { code: 233, label: "Ghana" },
  { code: 234, label: "Nigeria" },
  { code: 235, label: "Chad" },
  { code: 236, label: "Central African Republic" },
  { code: 237, label: "Cameroon" },
  { code: 238, label: "Cape Verde" },
  { code: 239, label: "Sao Tome and Principe" },
  { code: 240, label: "Equatorial Guinea" },
  { code: 241, label: "Gabon" },
  { code: 242, label: "Congo" },
  { code: 243, label: "Congo- Democratic Republic of the" },
  { code: 244, label: "Angola" },
  { code: 245, label: "Guinea-Bissau" },
  { code: 246, label: "British Indian Ocean Territory" },
  { code: 247, label: "Ascension Island" },
  { code: 248, label: "Seychelles" },
  { code: 249, label: "Sudan" },
  { code: 250, label: "Rwanda" },
  { code: 251, label: "Ethiopia" },
  { code: 252, label: "Somalia" },
  { code: 253, label: "Djibouti" },
  { code: 254, label: "Kenya" },
  { code: 255, label: "Tanzania" },
  { code: 256, label: "Uganda" },
  { code: 257, label: "Burundi" },
  { code: 258, label: "Mozambique" },
  { code: 260, label: "Zambia" },
  { code: 261, label: "Madagascar" },
  { code: 262, label: "French Southern and Antarctic Lands" },
  { code: 263, label: "Zimbabwe" },
  { code: 264, label: "Namibia" },
  { code: 265, label: "Malawi" },
  { code: 266, label: "Lesotho" },
  { code: 267, label: "Botswana" },
  { code: 268, label: "Swaziland" },
  { code: 269, label: "Comoros" },
  { code: 290, label: "Saint Helena" },
  { code: 291, label: "Eritrea" },
  { code: 297, label: "Aruba" },
  { code: 298, label: "Faroe Islands" },
  { code: 299, label: "Greenland" },
  { code: 350, label: "Gibraltar" },
  { code: 351, label: "Portugal" },
  { code: 352, label: "Luxembourg" },
  { code: 353, label: "Ireland" },
  { code: 354, label: "Iceland" },
  { code: 355, label: "Albania" },
  { code: 356, label: "Malta" },
  { code: 357, label: "Cyprus- Republic of" },
  { code: 358, label: "Finland" },
  { code: 370, label: "Lithuania" },
  { code: 371, label: "Latvia" },
  { code: 372, label: "Estonia" },
  { code: 373, label: "Moldova" },
  { code: 374, label: "Armenia" },
  { code: 375, label: "Belarus" },
  { code: 376, label: "Andorra" },
  { code: 377, label: "Monaco" },
  { code: 378, label: "San Marino" },
  { code: 380, label: "Ukraine" },
  { code: 381, label: "Serbia" },
  { code: 382, label: "Montenegro" },
  { code: 385, label: "Croatia" },
  { code: 386, label: "Slovenia" },
  { code: 387, label: "Bosnia and Herzegovina" },
  { code: 389, label: "Macedonia" },
  { code: 420, label: "Czech Republic" },
  { code: 421, label: "Slovakia" },
  { code: 423, label: "Liechtenstein" },
  { code: 500, label: "Falkland Islands" },
  { code: 501, label: "Belize" },
  { code: 502, label: "Guatemala" },
  { code: 503, label: "El Salvador" },
  { code: 504, label: "Honduras" },
  { code: 505, label: "Nicaragua" },
  { code: 506, label: "Costa Rica" },
  { code: 507, label: "Panama" },
  { code: 508, label: "Saint Pierre and Miquelon" },
  { code: 509, label: "Haiti" },
  { code: 590, label: "Guadeloupe" },
  { code: 591, label: "Bolivia" },
  { code: 592, label: "Guyana" },
  { code: 593, label: "Ecuador" },
  { code: 594, label: "French Guiana" },
  { code: 595, label: "Paraguay" },
  { code: 596, label: "Martinique" },
  { code: 597, label: "Suriname" },
  { code: 598, label: "Uruguay" },
  { code: 599, label: "Netherlands Antilles" },
  { code: 670, label: "Timor-Leste" },
  { code: 672, label: "Norfolk Island" },
  { code: 673, label: "Brunei Darussalam" },
  { code: 674, label: "Nauru" },
  { code: 675, label: "Papua New Guinea" },
  { code: 676, label: "Tonga" },
  { code: 677, label: "Solomon Islands" },
  { code: 678, label: "Vanuatu" },
  { code: 679, label: "Fiji" },
  { code: 680, label: "Palau" },
  { code: 681, label: "Wallis and Futuna Islands" },
  { code: 682, label: "Cook Islands" },
  { code: 683, label: "Niue" },
  { code: 685, label: "Samoa" },
  { code: 686, label: "Kiribati" },
  { code: 687, label: "New Caledonia" },
  { code: 688, label: "Tuvalu" },
  { code: 689, label: "French Polynesia" },
  { code: 690, label: "Tokelau" },
  { code: 691, label: "Micronesia" },
  { code: 692, label: "Marshall Islands" },
  { code: 699, label: "United States Minor Outlying Islands" },
  { code: 850, label: "Korea- Democratic People's Republic of" },
  { code: 852, label: "Hong Kong" },
  { code: 853, label: "Macao" },
  { code: 855, label: "Cambodia" },
  { code: 856, label: "Laos" },
  { code: 872, label: "Pitcairn" },
  { code: 880, label: "Bangladesh" },
  { code: 886, label: "Taiwan" },
  { code: 960, label: "Maldives" },
  { code: 961, label: "Lebanon" },
  { code: 962, label: "Jordan" },
  { code: 963, label: "Syria" },
  { code: 964, label: "Iraq" },
  { code: 965, label: "Kuwait" },
  { code: 966, label: "Saudi Arabia" },
  { code: 967, label: "Yemen" },
  { code: 968, label: "Oman" },
  { code: 970, label: "Palestine" },
  { code: 971, label: "United Arab Emirates" },
  { code: 972, label: "Israel" },
  { code: 973, label: "Bahrain" },
  { code: 974, label: "Qatar" },
  { code: 975, label: "Bhutan" },
  { code: 976, label: "Mongolia" },
  { code: 977, label: "Nepal" },
  { code: 992, label: "Tajikistan" },
  { code: 993, label: "Turkmenistan" },
  { code: 994, label: "Azerbaijan" },
  { code: 995, label: "Georgia" },
  { code: 996, label: "Kyrgyz Republic" },
  { code: 998, label: "Uzbekistan" },
];

const genderOptions = [
  "Male",
  "Female",
  "Other",
  "Prefer not to say",
];

const qualificationOptions = [
  "Post Doctoral",
  "Ph.D",
  "Post-Masters",
  "Masters",
  "Pre-Masters",
  "Bachelors",
  "Pre-Bachelors",
  "Associate",
  "Diploma",
  "Vocational",
  "High School",
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export {
  countries,
  genderOptions,
  qualificationOptions,
  months,
};
