import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Sidebaroptions from "src/Components/SidebarOption";
import settingsStyles from "src/Assets/css/settings/settings.module.css";
import Notifications from "src/pages/Notifications";
import HeaderAfterSignIn from "../../Components/Header/header";
import logo from "src/Assets/images/LandingPageImages/skopic-image.png";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import "./index.css";

const defaultState = {
  leaderProductStrategy: false,
  engineeringManager: false,
  design: false,
  branding: false,
  cto: false,
  iphoneDeveloper: false,
};

function Careers() {
  const leaderRef = useRef(null);
  const engineeringManagerRef = useRef(null);
  const designRef = useRef(null);
  const brandingRef = useRef(null);
  const ctoRef = useRef(null);
  const iphoneDeveloperRef = useRef(null);

  const [isActive, setIsActive] = useState({
    ...defaultState,
    leaderProductStrategy: true,
  });
  const [isNotificationSelected, setIsNotificationSelected] = useState(false);
  const [signInStatus, setSignInStatus] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const status = Cookies.get("signInStatus");
    setSignInStatus(status);
  }, []);

  const clickHandler = (key) => {
    setIsActive({
      ...defaultState,
      [key]: true,
    });

    scrollToView(key);
  };

  const scrollToView = (key) => {
    if (key === "leaderProductStrategy" && leaderRef.current) {
      leaderRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "engineeringManager" && engineeringManagerRef.current) {
      engineeringManagerRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "design" && designRef.current) {
      designRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "branding" && brandingRef.current) {
      brandingRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "cto" && ctoRef.current) {
      ctoRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "iphoneDeveloper" && iphoneDeveloperRef.current) {
      iphoneDeveloperRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const showHideNotifications = () => {
    setIsNotificationSelected(!isNotificationSelected);
  };
  const history = useHistory();

  return (
    <div>
      {isNotificationSelected ? (
        <div className={`col-sm-3  ${settingsStyles.notifications}`}>
          <div className={settingsStyles.notificationscroll}>
            <Notifications />
          </div>
        </div>
      ) : null}
      <div>
        {signInStatus === "true" ? (
          <HeaderAfterSignIn />
        ) : (
          <div className={settingsStyles.headerContainer}>
            <Link to={"/"}>
              <div>
                <img src={logo} alt="Skopic-Logo" width={90} height={48} />
              </div>
            </Link>
          </div>
        )}
        <div className="careers-main-container">
          <div className="page-navigation-options-container">
            {/* <h3>Careers</h3> */}
            <div className="main-heading-container">
              <h3 className="main-heading">Careers</h3>
            </div>
            <div className="page-navigation-options">
              <div className="page-options-content">
                <Sidebaroptions
                  active={isActive.leaderProductStrategy}
                  text="Leader - Product Strategy"
                  onSidebaroptionClick={() =>
                    clickHandler("leaderProductStrategy")
                  }
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.engineeringManager}
                  text="Engineering Manager"
                  onSidebaroptionClick={() =>
                    clickHandler("engineeringManager")
                  }
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.design}
                  text="Design/UI Developer"
                  onSidebaroptionClick={() => clickHandler("design")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.branding}
                  text="Branding/Marketing Leader"
                  onSidebaroptionClick={() => clickHandler("branding")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.cto}
                  text="CTO/VP of Technology"
                  onSidebaroptionClick={() => clickHandler("cto")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.iphoneDeveloper}
                  text="iPhone Developer"
                  onSidebaroptionClick={() => clickHandler("iphoneDeveloper")}
                />
              </div>
            </div>
          </div>
          <div className="careers-content-container">
            <p className={settingsStyles.careersMail}>
              Please send resumes to{" "}
              <a href="mailto:careers@skopic.com">careers@skopic.com</a>
            </p>
            <div
              ref={leaderRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                }  ${isActive.leaderProductStrategy && settingsStyles.focusapperance
                }`}
            >
              <b>Leader - Product Strategy</b>
              <br />
              Currently we require a high impact management team member who can
              advise and direct
              <br />
              Skopic’s core team on product &market strategies and potentially
              on Revenue generating
              <br />
              opportunity
              <br />
              Job duties:
              <br />
              Participate in brainstorming and strategy sessions.
              <br />
              Identify and prioritize product rollout strategies.
              <br />
              Define and manage product roadmap.
              <br />
              Lead and direct in the areas of ownership.
              <br />
              Will move into a role of VP – Strategy when fully operational and
              funded.
              <br />
              Experience and skills:
              <br />
              Masters in engineering, science or management.
              <br />
              A good understanding of successful consumer product marketing and
              branding strategies.
              <br />
              Prior success with building and exiting a tech startup would be
              ideal.
              <br />
              Passionate to lead and excel in a startup environment.
              <br />
              Must be very proactive and market driven
            </div>
            <div
              ref={engineeringManagerRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                }  ${isActive.engineeringManager && settingsStyles.focusapperance
                }`}
            >
              <b>Engineering Manager</b>
              <br />
              Hands on individual with over 15 years of experience in software
              engineering &<br />
              development.This position demands hands-on technical skills and
              expertise. Some key duties
              <br />
              are listed below:
              <br />
              Work with product management and dev team to achieve day to day,
              mid-term and long term
              <br />
              goals.
              <br />
              Establish and follow a disciplined product engineering,
              development and support processes.
              <br />
              Design and develop scalable application architecture and data
              model.
              <br />
              Leverage all Java open source frameworks and tools for dev, build
              and deployment.
              <br />
              Code reviews for standards, scale and stability.
              <br />
              Develop and execute test strategy covering; Unit, functional, load
              & regression testing.
              <br />
              Must be hands on with Java, JSPs, Spring, Hibernate, JSON, Web
              Services, My SQL & Linux.
              <br />
              Develop SOA to address phone apps’ integration and future
              developers community.
              <br />
              Technical Skills:
              <br />
              Core: Java, JQuery, JSON, JSPs, Web Services, Hibernate, Spring,
              MY SQL, Apache Tomcat &<br />
              Ubuntu.
              <br />
              Supplementary: Jira, Jenkins, Subversion, JUnit&JMeter.
              <br />
              Preferred: Lucene search server and No SQL like Cassandra or Mongo
              DB.
              <br />
              Qualifications: Bachelors or Mastersdegree in computer sciences or
              engineering.
            </div>
            <div
              ref={designRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                } ${isActive.design && settingsStyles.focusapperance}`}
            >
              <b>Design/UI Developer</b>
              <br />
              Graphics design & Creative Art for Social Media/web applications.
              <br />
              Create themes, skins & templates for a consumer oriented web
              application.
              <br />
              Design web flows for intuitive consumer experience.
              <br />
              Provide simple and effective Usability to the web application.
              <br />
              Skills: Flash, AJAX, HTML 5.0, Java Script, JQuery& CSS 3.0
              <br />
              Engagement Type: Project Based.
              <br />
              Project Duration: 2 to 3 months.
            </div>

            <div
              ref={brandingRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                } ${isActive.branding && settingsStyles.focusapperance}`}
            >
              <b>Branding/Marketing Leader</b>
              <br />
              We are now looking for passionate and visionary individuals to
              come on board and help us with
              <br />
              the following activities that we will be focusing on in the next
              few months:
              <br />
              Investor readiness.
              <br />
              Aid in investor presentations and pitches by actively contributing
              to decks and provide market
              <br />
              perspectives.
              <br />
              Branding, marketing and positioning.
              <br />
              Some ideas on market focus to potentially develop a viral
              ecosystem or Go-to-Market strategy.
              <br />
              Embrace the concept and be an evangelist.
              <br />
              Potential Revenue strategies.
              <br />
              Education/skills required:
              <br />
              Masters in engineering, science or management.
              <br />
              A good understanding of successful consumer product marketing and
              branding strategies.
              <br />
              Prior success with building and exiting a tech startup would be
              ideal.
              <br />
              Passionate to lead and excel in a startup environment.
              <br />
              Must be very proactive and market driven.
            </div>

            <div
              ref={ctoRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                } ${isActive.cto && settingsStyles.focusapperance}`}
            >
              <b>CTO or VP of Technology</b>
              <br />
              Design technology strategy, develop system architecture and drive
              product engineering to meet
              <br />
              tactical and strategic product goals. An ideal candidate would be
              visionary & enterprising with a<br />
              passion to go through a complete business life cycle from start-up
              to exit.
              <br />
              Experience:
              <br />
              Designed and developed high volume consumer web applications.
              <br />
              Must have proven record in delivering Secure, Stable and Scalable
              Multi-Tenant applications.
              <br />
              Good understanding of data store models and information management
              techniques.
              <br />
              Either worked with or have a good underrating mobile front end
              applications development
              <br />
              involving server side integrations.
              <br />
              Understand, develop and implement search technologies and
              algorithms.
              <br />
              Implemented API based third party application integrations like
              FB, Twitter, Groupon etc…
              <br />
              Understanding of sound product engineering, development and
              database design practices.
              <br />
              Must be hands on or well familiar with setting up build and
              operational application
              <br />
              infrastructure.
              <br />
              Technical Skills:
              <br />
              Core: Java, JQuery, JSON, JSPs, Web Services, Hibernate, Spring,
              MY SQL, Apache Tomcat &<br />
              Ubuntu.
              <br />
              Supplementary: Jira, Jenkins, Subversion, JUnit&JMeter.
              <br />
              Preferred: Lucene search server and No SQL like Cassandra or Mongo
              DB.
              <br />
              Head - Technical operations & Service Infrastructure
              <br />
              <br />
              Tactical Role:
              <br />
              Linux, Network & Infrastructure support for test and development
              servers.
              <br />
              Research, Evaluate and Recommend Data Center or Cloud
              Infrastructure solutions for Skopic
              <br />
              Application deployment.
              <br />
              Coordinate with Datacenter or IAAS providers to understand their
              products and obtain the
              <br />
              pricing details as necessary.
              <br />
              Remote deployment and support of Skopic applications services in
              production.
              <br />
              <br />
              Strategic Role:
              <br />
              Plan & Recommend infrastructure solutions for 24x7 availability.
              <br />
              Design capacity for Optimal Network Response & Computing Resources
              to support Skopic
              <br />
              service for User Scaling.
              <br />
              Participate in brainstorming sessions and provide inputs on
              investor outreach, product roadmap,
              <br />
              product architecture, product marketing & revenue generation
              models.
              <br />
              Appropriate use of security protocols to protect consumer data
              moving over networks. Suggest
              <br />
              security mechanisms to store and protect consumer confidential
              data.
              <br />
              Education: Bachelor’s or Master’s in Sciences, Engineering or IS.
              <br />
            </div>

            <div
              ref={iphoneDeveloperRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                } ${isActive.iphoneDeveloper && settingsStyles.focusapperance}`}
            >
              <b>iPhone Developer</b>
              <br />
              Engagement type: Project based
              <br />
              Develop iPhone application and server side integration with Java
              web application using RESTful
              <br />
              web services via JSON.
              <br />
              Project duration: 4 to 8 weeks.
              <br />
              UI - 7 to 10 screens.
              <br />
              Workflow complexity - Simple.
              <br />
              Other iPhone feature integrations like maps, location, data
              synching etc…required.
              <br />
              Thorough understanding of Human Interface Guidelines from Apple.
              <br />
              Developed application must comply and pass Apple’s compatibility
              check.
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Careers;
