import * as allActions from "../../actions/actions.constants";
import * as EditProfileActions from "../../actions/EditProfile/EditProfileActions.js";
import { fetchProfileData } from "../../actions/EditProfile/EditProfileActions.js";

import axios from "axios";
import * as Cookies from "js-cookie";
import API from '../../../network';

const EditProfileService = (store) => (next) => (action) => {
  const { dispatch, getState } = store; // Access to getState

  next(action);
  switch (action.type) {
    case allActions.FETCH_PROFILE_DATA:
      API.getProfile()
        .then((res) => {
          next(EditProfileActions.receiveProfileData(res.data));
        });
      break;

    case allActions.UPDATE_PROFILE_INFO:
      const { newData } = action.payload;

      API.updateProfileInfo(newData)
        .then((res) => {
          next(EditProfileActions.receiveUpdatedProfileInfo(res.data.updatedProfileInfo));
          dispatch(fetchProfileData()); // Use dispatch from the store
        })
        .catch((error) => {
          // Handle errors
        });
      break;

    default:
      break;
  }
};

export default EditProfileService;
