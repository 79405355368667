import React, { useContext, useEffect, useState } from "react";
import * as Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import AppleLogin from 'react-apple-login';
import { SignupContext } from "src/context/SignupContext/SignupContext";
import Api from "src/network";
import { AppleButton } from "./SocialButtons";
import DefaultCommModal from "./DefaultCommModal";
import * as SignInActions from "src/store/actions/SignInActions/SignInAction";
import { useDispatch } from "react-redux";

const AppleClientId = 'com.skopic.stagetest.service'; // Service Id
const AppleRedirectUri = 'https://dev.skopic.com/skopicportal/jsonindex/apple-id' // Your redirect URI

const defaultState = {
  community: "Default Community",
  tenantId: ""
};

function AppleSignUp() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showDefaultCommModal, setShowDefaultCommModal] = useState(false);
  const [state, setState] = useState({ ...defaultState });
  const signupContext = useContext(SignupContext);
  const dispatch = useDispatch();

  useEffect(() => {
    const { appleSignUp, community, tenantId, } = signupContext?.signupState;
    if (appleSignUp) {
      setState((prevState) => ({
        ...prevState,
        community: community,
        tenantId: tenantId,
      }));
      setShowDefaultCommModal(true);
    }
  }, []);

  const onCloseModal = () => {
    setShowDefaultCommModal(false);
    //reset all values
    setState({ ...defaultState });
    //reset signup context data
    signupContext?.resetSignupState();
  };

  const handleMakeDefault = async (tenantId) => {
    let formdata = new FormData();
    formdata.append("userDefaultTenantId", tenantId);
    Api.setDefaultTenant(formdata)
      .then((res) => {
        console.log("handleMakeDefault res", res);
        autheticate(tenantId, state?.community, true);
      })
      .catch((err) => {
        alert("Default community selection failed!");
      });
  };

  const onSignUp = async (tenantId) => {
    // if there is no community selected
    if (state?.community === "Default Community" || loading) return;
    await handleMakeDefault(tenantId);
  };

  const autheticate = async (tenantId, community, isSignUpFlow) => {
    try {
      setLoading(true);
      await Api.getUserData(tenantId, community);
      // set cookies
      Cookies.set("signInStatus", true);
      // sessionStorage.setItem("userData", JSON.stringify(result));

      if (isSignUpFlow) { //for signup flow
        // close default community selection popup
        setShowDefaultCommModal(false);
        // reset signup context data
        signupContext?.resetSignupState();
        // set isNewUser flag to true
        dispatch(SignInActions?.updateIsNewUser(true));
      }

      dispatch(SignInActions.authUser());
      // navigate to home page
      history.push("/Home");
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onSuccess = async (response) => {
    console.log("RESPONSE->>", response);
    if (!response?.error) {
      let formdata = new FormData();
      formdata.append("oAuthCode", response?.authorization?.code);
      formdata.append("appType", 'web');
      try {
        const result = await Api.appleSignup(formdata);
        const { status } = result?.data || {};
        if (status && String(status).toLowerCase() === ("Thank you for SignUp using your appleid account, Skopic has created a profile for you and a temporary Skopic password has been emailed.").toLowerCase()) {
          signupContext?.setSignupState({
            appleSignUp: true,
            community: "",
            tenantId: "",
          });
          setShowDefaultCommModal(true);
        } else if (status && String(status).toLowerCase() === ("Thank you for Signing using your appleid account").toLowerCase()) {
          autheticate(result.data?.userTenantId, result.data?.userTenant, false);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      console.log("ERROR->>", response);
    }
  };

  return (
    <>
      <AppleLogin
        clientId={AppleClientId} // Your Services ID
        redirectURI={AppleRedirectUri} // Your redirect URI
        usePopup={true}
        callback={onSuccess} // Catch the response
        scope="email name"
        responseMode="query"
        render={renderProps => (  //Custom Apple Sign in Button
          <AppleButton onClick={renderProps.onClick} loading={loading} />
        )}
      />
      {showDefaultCommModal && (
        <DefaultCommModal
          open={showDefaultCommModal}
          onClose={onCloseModal}
          loading={loading}
          // email={state?.email}
          // password={state?.password}
          community={state?.community}
          tenantId={state?.tenantId}
          // displayName={state?.displayName}
          onSignUp={onSignUp}
        />
      )}
    </>
  );
}

export default AppleSignUp;
