import React, { useState, useRef, useEffect, useCallback } from "react";
import Avatar from "@material-ui/core/Avatar";
import saysoutlineimage from "src/Assets/images/noofsays.svg";
import follow from "src/Assets/images/follow.svg";
import unfollow from "src/Assets/images/unfollow.svg";
// import dropdownarrow from "src/Assets/images/dropdownarrow.png";
import location from "src/Assets/images/userlocationtag.png";
import dot from "src/Assets/images/dot.png";
import like from "src/Assets/images/like.svg";
import unlike from "src/Assets/images/unlike.svg";
import edit from "src/Assets/images/edit.svg";
import share from "src/Assets/images/share.svg";
// import deleteicon from "src/Assets/images/deleteicon.png";
import rating from "src/Assets/images/rating.png";
import privateinvite from "src/Assets/images/privateinvite.png";
// import sendanemail from "src/Assets/images/sendemail.svg";
// import report from "src/Assets/images/report.svg";
// import profileimage from "src/Assets/images/profileimage.png";
// import locationpinchildpost from "src/Assets/images/locationpinchildpost.png";
import styles from "src/Assets/css/home/post.module.css";
import feedstyles from "src/Assets/css/home/feedpost.module.css";
import { Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import SimpleDialogDemo from "../../../Modules/Reusuablecomponents/CustomizedDialogs";
import Childsay from "./ChildSay";
import ReusuableDeleteDialogmodal from "../../../Modules/Reusuablecomponents/ReusuableDeleteModal";
import ReusuableReportDialogmodal from "../../../Modules/Reusuablecomponents/ReusuableReportDialogmodal";
import PostMessageValidation from "../../../Modules/Reusuablecomponents/PostMessageValidation";
import ReusuableSendaMailModal from "../../../Modules/Reusuablecomponents/ReusuableSendaMailModal";
// import PrivateTags from "../../../Modules/Moderator/ManageContent/PrivateTags";
import InviteFollowers from "./InviteFollowers";
import API from "../../../network";
import CircularProgress from "@material-ui/core/CircularProgress";
import NoDataFound from "src/Assets/images/data-not-found.svg";
import { MdKeyboardArrowDown } from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import { Menu, MenuItem } from "@szhsin/react-menu";
import AvatarBadge from "src/Components/AvatarBadge";
import _ from "lodash";

const FeedPost = (props) => {
  const {
    loading,
    followData,
    privateTagsData,
    childSaysData,
    fetchChildSaysData,
    postChildSayData,
    fetchFollowData,
    fetchVoteUpStatus,
    fetchPrivateTagsData,
    fetchFeedData,
    deleteMessage,
    refreshFeedData,
    feedPageNumber,
    fetchPageFeed,
    filtertype,
    fetchContributors
  } = props;

  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const [listData, setListData] = useState([]);
  const [feedListPage, setFeedListPage] = useState(feedPageNumber);
  const [isLoading, setIsLoading] = useState(false);
  const [isFeed, setFeed] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isDeletePost, setDeletePost] = useState(false);
  const [isDeletePostId, setDeletePostId] = useState();
  const [invitesShow, setInvitesShow] = React.useState(false);
  const [invite, setInvite] = useState(null);
  const [tenantId, setTenantId] = useState(null);
  const [isRead, setIsRead] = useState(false);
  const [currentSayId, setCurrentSayId] = useState(null);
  const [msgId, setMsgId] = useState(null);
  const [editPostId, setEditPostId] = useState(null);
  const loader = useRef(null);
  const listRef = useRef(null);

  useEffect(() => {
    setFeedListPage(feedPageNumber);
    setHasMore(true);
  }, [feedPageNumber]);

  useEffect(() => {
    setListData(props.listData);
  }, [props.listData]);

  useEffect(() => {
    const fetchFeedData = async () => {
      setIsLoading(true);
      const newPageNumber = (feedListPage - 1) * 10;
      try {
        const response = await API.fetchFeedData(filtertype, newPageNumber);
        const { feed } = response.data;
        if (feed && Array.isArray(feed) && feed.length) {
          setListData((prevFeedList) =>
            _.unionBy([...prevFeedList, ...response.data.feed], "id")
          );
        } else {
          setHasMore(false); // No more data available
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    };

    fetchFeedData();
  }, [feedListPage]);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (props.data.totalfeedsizeincommunity !== props.data.breadCountLength) {
        if (target.isIntersecting) {
          // dispatch(feedactions.fetchFeedData(`?startlimit=${props.data.breadCountLength}${props.filtertype}`))
          // if(props.listdata)
          // {
          //     setFeed(oldFeed=>[...oldFeed,this.props.listdata])
          //     console.log(isFeed)
          // }
        }
      }
    },
    [props.data]
  );

  const options = {
    root: null,
    rootMargin: "20px",
    threshold: 0,
  };
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) observer.observe(loader.current);

    return () => {
      if (loader.current) observer.unobserve(loader.current);
    };
  }, [loader, options]);

  useEffect(() => {
    if (isDeletePost) {
      // console.log(isDeletePostId)
      document.getElementById(isDeletePostId).style.display = "none";
    }
  }, [isDeletePost]);

  const onSayClick = (id, e) => {
    setIsRead(true);
    setCurrentSayId(id);
    const childsayid = `childsay${id}`;
    e.preventDefault();

    const childElement = document.getElementById(childsayid);

    if (childElement) {
      // Check if the element exists
      if (childElement.style.display === "block") {
        childElement.style.display = "none";
      } else {
        // Assuming dispatch and childSayActions are defined somewhere
        fetchChildSaysData(id);

        // Display the element if the ID matches
        childElement.style.display = "block";
      }
    } else {
      console.error(`Element with id ${childsayid} not found`);
    }
  };

  const handleScroll = useCallback(() => {
    if (!hasMore || isLoading) return; // indicates that there will be no more data
    const { scrollTop, clientHeight, scrollHeight } = listRef.current;

    if (scrollTop + clientHeight + 100 >= scrollHeight) {
      setIsLoading(true);
      setFeedListPage((prevPage) => prevPage + 1);
    }
  }, [hasMore, isLoading]);

  const updateList = (id, followStatus) => {
    const newData = [...listData];
    const modifiedData = newData.find((x) => x.id === id);
    modifiedData.followStatus = followStatus.includes("0") ? "N" : "Y";
    modifiedData.followCount = followStatus.includes("0")
      ? modifiedData.followCount - 1
      : modifiedData.followCount - 1 + 2;
    setListData(newData);
  };

  const likeUnlike = (id) => {
    const newData = [...listData];
    const modifiedData = newData.find((x) => x.id === id);
    modifiedData.UserLikeStatus = "InActive";
    modifiedData.voteUp = modifiedData.voteUp - 1 + 2;
    setListData(newData);
  };

  //to perform this follow unfollow actions
  const followunfollow = (id, followstatus) => {
    updateList(id, followstatus);
    fetchFollowData(id, followstatus);
  };

  //to perform like option
  const onLikeUnlikeSelection = (id, cache) => {
    likeUnlike(id);
    fetchVoteUpStatus(id, cache);
  };

  const onEditSelection = (id) => {
    setEditPostId(id);
    // let editTextArea = `edittext${id}`;
    // let hideOriginalMessage = `hide${id}`;

    // if (document.getElementById(editTextArea).style.display === "block") {
    //   document.getElementById(editTextArea).style.display = "none";
    //   document.getElementById(hideOriginalMessage).style.display = "block";
    // } else {
    //   document.getElementById(editTextArea).style.display = "block";
    //   document.getElementById(hideOriginalMessage).style.display = "none";
    // }
  };

  const onCloseEdit = (id) => {
    setEditPostId(null);
  };

  const replaceTagAndUrl = (Message, msg, tags, urls) => {
    if (msg && Array.isArray(urls) && urls.length) {
      return <div>{createMarkup(msg, urls)}</div>;
    } else if (msg && Array.isArray(tags) && tags.length && props?.onHashTagClick) {

      // Create a regular expression to match the hashtags
      const regex = new RegExp(`(${tags.join("|")})`, "g");

      const handleTagClick = (tag) => {
        console.log(`Tag clicked: ${tag}`);
        props?.onHashTagClick(tag);
      };

      // Split the message and map over it to replace hashtags with <a> tags
      const parts = msg.split(regex).map((part, index) => {
        if (part && tags.includes(part)) {
          return (
            <a
              key={index}
              href={`#${part.slice(1)}`}
              onClick={(e) => {
                e.preventDefault(); // Prevent default anchor behavior
                handleTagClick(part); // Call the onTagClick handler with the tag
              }}
            >
              {part}
            </a>
          );
        }
        return part;
      });

      return <div>{parts}</div>;
    } else {
      return Message;
    }
  };

  const createMarkup = (msg, urls) => {
    const urlRegex = /(\bhttps?:\/\/[^\s/$.?#].[^\s]*|\bwww\.[^\s/$.?#].[^\s]*)/g;
    const msgArray = msg.split(urlRegex);
    return msgArray.map((part, index) => {
      if (urls.includes(part)) {
        // For each URL, wrap it in an anchor tag
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            related web link
          </a>
        );
      }
      return part; // If it's not a URL, return the part of the string as plain text
    });
  };

  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  const onInviteSelection = (tenantId, id) => {
    setInvitesShow(true);
    setInvite(true);
    setTenantId(tenantId);
    setMsgId(id);
  };

  const followingPosts = listData.filter(
    (item) => item.followStatus === "Y" || item.followStatus === "inactive"
  );

  if (followingPosts.length === 0 && props?.value === 1) {
    return (
      <div className={styles.notFollowingContainer} key={`nopost`}>
        <img src={NoDataFound} alt="NoDataFound" width="200" />
        <p>Sorry! You have not followed any post</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div
        id="feedScroll"
        className={styles.allFeedMainContainer}
        ref={listRef}
        onScroll={handleScroll}
      >
        {props.data &&
        Object.keys(props.data).length !== 0 &&
        userDetails &&
        Object.keys(userDetails).length !== 0 ? (
          listData ? (
            listData.map((result, index) =>
              props.value === 1 ? (
                result.followStatus === "Y" ||
                result.followStatus === "inactive" ? (
                  //this is for following data
                  <div
                    className={styles.post}
                    key={`followingfeed${result.id}${result.Update_Time}`}
                    id={`FeedDataDelete${result.id}${result.Update_Time}`}
                  >
                    <div className={feedstyles.avatarDetailsMainContainer}>
                      <div className={feedstyles.avatarDetailsContainer}>
                        <AvatarBadge
                          src={`${profileImagePath}${result.mainuserdata.uimage}`}
                          className={`${styles.avatar} ${feedstyles.profileimg}`}
                          variant="square"
                          alt={userDetails?.userData?.displayName}
                          isPrimaryModerator={result.isPM == 1}
                          isModerator={
                            result.isModerator == 1 && result.isPM == 0
                          }
                        />
                        <div style={{ marginLeft: "10px" }}>
                          <span className={feedstyles.profilename}>
                            {result.mainuserdata.displayName}
                            {
                              <div className={feedstyles.profilehover}>
                                <div
                                  className={
                                    feedstyles.profilehoverimageandcount
                                  }
                                >
                                  <AvatarBadge
                                    src={`${profileImagePath}${result.mainuserdata.uimage}`}
                                    className={styles.avatar}
                                    alt={userDetails?.userData?.displayName}
                                    isPrimaryModerator={result.isPM == 1}
                                    isModerator={
                                      result.isModerator == 1 &&
                                      result.isPM == 0
                                    }
                                  />
                                  <div style={{ marginLeft: "10px" }}>
                                    <p className={feedstyles.profilehovername}>
                                      {result.mainuserdata.displayName}
                                    </p>
                                    <p className={feedstyles.profilecount}>
                                      ASK {result.mainuserdata.askcount} | SAY{" "}
                                      {result.mainuserdata.saycount} | UPDATE{" "}
                                      {result.mainuserdata.updatecount} | TAG{" "}
                                      {result.mainuserdata.hashtagcount}
                                    </p>
                                  </div>
                                </div>
                                <p className={feedstyles.profilehovercontent}>
                                  {result.mainuserdata.shortBio}
                                </p>
                              </div>
                            }
                          </span>
                          {result.locName ? (
                            <div className={styles.locationandtime}>
                              <span className={feedstyles.profiletime}>
                                {result.Message_Time}
                              </span>
                              <img
                                src={dot}
                                alt="dot"
                                style={{ marginRight: "0", marginLeft: "3px" }}
                              />
                              <span className={styles.locationDet}>
                                <img src={location} alt="location" />
                                {result.locName}
                              </span>
                            </div>
                          ) : (
                            <div className={styles.locationandtime}>
                              <span className={feedstyles.profiletime}>
                                {result.Message_Time}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={feedstyles.feeddropdown}>
                        <Menu
                          menuButton={
                            <IconButton
                              style={{
                                padding: 0,
                                width: 30,
                                height: 30,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                outline: "none",
                              }}
                            >
                              <MdKeyboardArrowDown fontSize={25} />
                            </IconButton>
                          }
                          transition
                          direction="bottom"
                          arrow
                          menuStyle={{
                            backgroundColor: "white",
                            borderRadius: 20,
                          }}
                        >
                          {(result.message_type === "A" ||
                            result.Keyword_ID === "OpenASK" ||
                            result.message_type === "H" ||
                            result.Keyword_ID === "hashTAG" ||
                            result.message_type === "X" ||
                            result.message_type === "I" ||
                            result.message_type === "S" ||
                            result.Keyword_ID === "impupdate" ||
                            result.Keyword_ID === "OpenSAY") &&
                            (result.User_ID !== userDetails.userData.User_ID &&
                            userDetails.userData.moderatorStatus === "0" ? (
                              <>
                                <MenuItem>
                                  <ReusuableReportDialogmodal
                                    id={result.id}
                                    param={"AskorSay"}
                                  />
                                </MenuItem>
                                <MenuItem>
                                  <ReusuableSendaMailModal
                                    displayName={
                                      result.mainuserdata.displayName
                                    }
                                    id={result.id}
                                  />
                                </MenuItem>
                                {(result.message_type === "H" ||
                                  result.Keyword_ID === "hashTAG" ||
                                  result.message_type === "X") && (
                                  <MenuItem>
                                    <img
                                      src={rating}
                                      style={{ marginRight: 8 }}
                                    />
                                    <span style={{ fontSize: 14 }}>
                                      Ratings
                                    </span>
                                  </MenuItem>
                                )}
                              </>
                            ) : userDetails.userData.moderatorStatus === "1" &&
                              result.User_ID !==
                                userDetails.userData.User_ID ? (
                              <>
                                <MenuItem>
                                  <ReusuableDeleteDialogmodal
                                    type={result.Keyword_ID}
                                    id={result.id}
                                    // setDeletePost={setDeletePost}
                                    // setDeletePostId={setDeletePostId}
                                    deleteMessage={deleteMessage}
                                    fetchContributors={fetchContributors}
                                  />
                                </MenuItem>
                                <MenuItem>
                                  <ReusuableSendaMailModal
                                    displayName={
                                      result.mainuserdata.displayName
                                    }
                                    id={result.id}
                                  />
                                </MenuItem>
                                {(result.message_type === "H" ||
                                  result.Keyword_ID === "hashTAG" ||
                                  result.message_type === "X") && (
                                  <MenuItem>
                                    <img
                                      src={rating}
                                      style={{ marginRight: 8 }}
                                    />
                                    <span style={{ fontSize: 14 }}>
                                      Ratings
                                    </span>
                                  </MenuItem>
                                )}
                              </>
                            ) : (
                              <>
                                {(result.message_type === "H" ||
                                  result.Keyword_ID === "hashTAG") && (
                                  <MenuItem>
                                    <img
                                      src={share}
                                      style={{ marginRight: 8 }}
                                    />
                                    <span style={{ fontSize: 14 }}>Share</span>
                                  </MenuItem>
                                )}
                                <MenuItem
                                  onClick={() => onEditSelection(result.id)}
                                >
                                  <img src={edit} style={{ marginRight: 8 }} />
                                  <span style={{ fontSize: 14 }}>Edit</span>
                                </MenuItem>
                                <MenuItem>
                                  <ReusuableDeleteDialogmodal
                                    type={result.Keyword_ID}
                                    id={result.id}
                                    // setDeletePost={setDeletePost}
                                    // setDeletePostId={setDeletePostId}
                                    deleteMessage={deleteMessage}
                                    fetchContributors={fetchContributors}
                                  />
                                </MenuItem>
                              </>
                            ))}
                        </Menu>
                      </div>
                    </div>
                    <div>
                      {editPostId === result?.id ? (
                        <PostMessageValidation
                          id={result.id}
                          Message={result.Message}
                          messageType={"feedTextArea"}
                          filtertype={props.filtertype}
                          post={result}
                          fetchFeedData={fetchFeedData}
                          refreshFeedData={refreshFeedData}
                          onCloseEdit={onCloseEdit}
                        />
                      ) : (
                        <p
                          className={feedstyles.messagehidden}
                          // dangerouslySetInnerHTML={{ __html: result.Message }}
                          id={`hide${result.id}`}
                        >
                          {replaceTagAndUrl(
                            result.Message,
                            result.msg,
                            result.tag,
                            result.url
                          )}
                        </p>
                      )}
                    </div>
                    <div className={styles.followerandcount}>
                      <div>
                        {result.message_type === "A" ||
                        result.Keyword_ID === "OpenASK" ||
                        result.message_type === "H" ||
                        result.Keyword_ID === "hashTAG" ||
                        result.Keyword_ID === "TrendingASK" ? (
                          <div>
                            <SimpleDialogDemo
                              followCount={result.followCount}
                              followData={result.follweduserprofile}
                              id={result.id}
                            />

                            {/* <a className={styles.followcount} onClick={()=>onFollowersSelection()}>{result.followCount} followers</a> */}
                          </div>
                        ) : result.voteUp ? (
                          <div>
                            <SimpleDialogDemo
                              voteUp={result.voteUp}
                              id={result.id}
                              likesData={result.likeduserprofile}
                            />
                          </div>
                        ) : (
                          <div>
                            <span className={styles.followcount}>
                              {result.voteUp === 1
                                ? `${result.voteUp} like`
                                : `${result.voteUp} likes`}
                            </span>
                          </div>
                        )}
                      </div>
                      <div>
                        {result.message_type === "A" ||
                        result.Keyword_ID === "OpenASK" ? (
                          <div className={styles.askcount}>
                            {isRead || result.childcotentreadcount === 0
                              ? null
                              : result.childcotentreadcount}
                          </div>
                        ) : result.message_type === "S" ||
                          result.Keyword_ID === "OpenSAY" ? (
                          <div className={styles.saycount}></div>
                        ) : result.message_type === "I" ||
                          result.Keyword_ID === "impupdate" ? (
                          <div className={styles.updatecount}></div>
                        ) : result.message_type === "H" ||
                          result.Keyword_ID === "hashTAG" ||
                          result.message_type === "X" ? (
                          <div
                            className={
                              result.isNewMessage === 1 && result.sayCount === 0
                                ? styles.tagcounter
                                : styles.nullTagCount
                            }
                          >
                            {result.isNewMessage === 1 &&
                            result.sayCount === 0 ? (
                              <p>new</p>
                            ) : result.childcotentreadcount !== 0 &&
                              isRead === false ? (
                              result.childcotentreadcount
                            ) : null}
                            {/* <p>{result.childcotentreadcount}</p> */}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <Divider variant="fullWidth" />
                    {result.message_type === "A" ||
                    result.message_type === "H" ||
                    result.message_type === "X" ||
                    result.Keyword_ID === "OpenASK" ||
                    result.Keyword_ID === "hashTAG" ? (
                      <div className={styles.saysandfollow}>
                        <div className={styles.noOfsays}>
                          <a onClick={(e) => onSayClick(result.id, e)}>
                            <img src={saysoutlineimage} alt="numberof says" />
                            {/* <img src-={dots} alt="saysdots"/> */}

                            {result.sayCount === 1 || result.postCount === 1 ? (
                              <span>{result.sayCount} SAY</span>
                            ) : result.sayCount === 0 ||
                              result.postCount === 0 ? (
                              <span>SAY</span>
                            ) : (
                              <span>{result.sayCount} SAYs</span>
                            )}
                          </a>
                        </div>
                        {result.followStatus === "inactive" ? (
                          <>
                            <div
                              className={`${feedstyles.noOffollows}`}
                              id={`1${result.id}`}
                            >
                              <a
                                className={styles.followtext}
                                onClick={() =>
                                  followunfollow(result.id, "isFollow=1")
                                }
                              >
                                <img src={follow} alt="follow" />
                                <span>Follow</span>
                              </a>
                            </div>
                            <div
                              className={`${styles.noOffollows} ${feedstyles.unfollowdisplay} `}
                              id={`0${result.id}`}
                            >
                              <a
                                className={styles.followtext}
                                onClick={() =>
                                  followunfollow(result.id, "isFollow=0")
                                }
                              >
                                <img src={unfollow} alt="unfollow" />
                                <span>Unfollow</span>
                              </a>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className={`${styles.noOffollows} `}
                              id={`0${result.id}`}
                            >
                              <a
                                className={styles.followtext}
                                onClick={() =>
                                  followunfollow(result.id, "isFollow=0")
                                }
                              >
                                <img src={unfollow} alt="unfollow" />
                                <span>Unfollow</span>
                              </a>
                            </div>
                            <div
                              className={`${feedstyles.noOffollows} ${feedstyles.followdisplay}`}
                              id={`1${result.id}`}
                            >
                              <a
                                className={styles.followtext}
                                onClick={() =>
                                  followunfollow(result.id, "isFollow=1")
                                }
                              >
                                <img src={follow} alt="follow" />
                                <span>Follow</span>
                              </a>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className={styles.likeunlike}>
                        {result.UserLikeStatus === "Active" ? (
                          <>
                            <a
                              onClick={() =>
                                onLikeUnlikeSelection(result.id, "cacheUpdate")
                              }
                              id={`like${result.id}`}
                            >
                              <img src={like} alt="like" />
                              Like
                            </a>
                          </>
                        ) : (
                          <a>
                            <img src={unlike} alt="unlike" />
                            Like
                          </a>
                        )}
                      </div>
                    )}
                    {/* .......................childsaydata ......................*/}

                    <div
                      className={styles.childsayselection}
                      id={`childsay${result.id}`}
                    >
                      <PostMessageValidation
                        id={result.id}
                        type={"childSay"}
                        userImageSrc={`${profileImagePath}${userDetails?.userData?.uimage}`}
                        postChildSayData={postChildSayData}
                        fetchChildSaysData={fetchChildSaysData}
                        refreshFeedData={refreshFeedData}
                        fetchContributors={fetchContributors}
                      />

                      {/* <div className={styles.comment}>
                                                <Avatar src={profileimage} variant="square" />

                                                <input className={styles.commentinput} placeholder="Say something..." type="text" />
                                                <a><img src={locationpinchildpost} /></a>
                                            </div>
                                            <div>
                                                <button className={styles.childpost}>POST</button>
                                            </div> */}
                      <Childsay
                        id={result.id}
                        key={result.id}
                        loading={loading}
                        childSaysData={childSaysData}
                        fetchVoteUpStatus={fetchVoteUpStatus}
                        fetchFeedData={fetchFeedData}
                        deleteMessage={deleteMessage}
                        refreshFeedData={refreshFeedData}
                        fetchContributors={fetchContributors}
                      />
                    </div>
                  </div>
                ) : null
              ) : (
                //this is for all data
                <React.Fragment key={`allfeed${result.id}`}>
                  <div
                    className={styles.post}
                    key={`allfeed${result.id}`}
                    id={`FeedDataDelete${result.id}`}
                  >
                    <div className={feedstyles.avatarDetailsMainContainer}>
                      <div className={feedstyles.avatarDetailsContainer}>
                        <AvatarBadge
                          src={`${profileImagePath}${result.mainuserdata.uimage}`}
                          className={`${styles.avatar} ${feedstyles.profileimg}`}
                          variant="square"
                          alt={userDetails?.userData?.displayName}
                          isPrimaryModerator={result.isPM == 1}
                          isModerator={
                            result.isModerator == 1 && result.isPM == 0
                          }
                        />
                        <div style={{ marginLeft: "10px" }}>
                          <span className={feedstyles.profilename}>
                            {result.mainuserdata.displayName}
                            {
                              <div className={feedstyles.profilehover}>
                                <div
                                  className={
                                    feedstyles.profilehoverimageandcount
                                  }
                                >
                                  <AvatarBadge
                                    src={`${profileImagePath}${result.mainuserdata.uimage}`}
                                    className={styles.avatar}
                                    alt={userDetails?.userData?.displayName}
                                    isPrimaryModerator={result.isPM == 1}
                                    isModerator={
                                      result.isModerator == 1 &&
                                      result.isPM == 0
                                    }
                                  />
                                  <div style={{ marginLeft: "10px" }}>
                                    <p className={feedstyles.profilehovername}>
                                      {result.mainuserdata.displayName}
                                    </p>
                                    <p className={feedstyles.profilecount}>
                                      ASK {result.mainuserdata.askcount} | SAY{" "}
                                      {result.mainuserdata.saycount} | UPDATE{" "}
                                      {result.mainuserdata.updatecount} | TAG{" "}
                                      {result.mainuserdata.hashtagcount}
                                    </p>
                                  </div>
                                </div>
                                <p className={feedstyles.profilehovercontent}>
                                  {result.mainuserdata.shortBio}
                                </p>
                              </div>
                            }
                          </span>
                          {result.locName ? (
                            <div className={styles.locationandtime}>
                              <span className={feedstyles.profiletime}>
                                {result.Message_Time}
                              </span>
                              <img
                                src={dot}
                                alt="dot"
                                style={{ marginRight: "0", marginLeft: "3px" }}
                              />
                              <span className={styles.locationDet}>
                                <img src={location} alt="location" />
                                {result.locName}
                              </span>
                            </div>
                          ) : (
                            <div className={styles.locationandtime}>
                              <span className={feedstyles.profiletime}>
                                {result.Message_Time}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={feedstyles.feeddropdown}>
                        <Menu
                          menuButton={
                            <IconButton
                              style={{
                                padding: 0,
                                width: 30,
                                height: 30,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                outline: "none",
                              }}
                            >
                              <MdKeyboardArrowDown fontSize={25} />
                            </IconButton>
                          }
                          transition
                          direction="bottom"
                          arrow
                          menuStyle={{
                            backgroundColor: "white",
                            borderRadius: 20,
                          }}
                        >
                          {(result.message_type === "A" ||
                            result.Keyword_ID === "OpenASK" ||
                            result.message_type === "H" ||
                            result.Keyword_ID === "hashTAG" ||
                            result.message_type === "X" ||
                            result.message_type === "I" ||
                            result.message_type === "S" ||
                            result.Keyword_ID === "impupdate" ||
                            result.Keyword_ID === "OpenSAY") &&
                            (result.User_ID !== userDetails.userData.User_ID &&
                            userDetails.userData.moderatorStatus === "0" ? (
                              <>
                                <MenuItem>
                                  <ReusuableReportDialogmodal
                                    id={result.id}
                                    param={"AskorSay"}
                                  />
                                </MenuItem>
                                <MenuItem>
                                  <ReusuableSendaMailModal
                                    displayName={
                                      result.mainuserdata.displayName
                                    }
                                    id={result.id}
                                  />
                                </MenuItem>
                                {(result.message_type === "H" ||
                                  result.Keyword_ID === "hashTAG" ||
                                  result.message_type === "X") && (
                                  <MenuItem>
                                    <span>
                                      <img
                                        src={rating}
                                        style={{ marginRight: 8 }}
                                      />
                                      <span style={{ fontSize: 14 }}>
                                        Ratings
                                      </span>
                                    </span>
                                  </MenuItem>
                                )}
                              </>
                            ) : userDetails.userData.moderatorStatus === "1" &&
                              result.User_ID !==
                                userDetails.userData.User_ID ? (
                              <>
                                <MenuItem>
                                  <ReusuableDeleteDialogmodal
                                    type={result.Keyword_ID}
                                    id={result.id}
                                    // setDeletePost={setDeletePost}
                                    // setDeletePostId={setDeletePostId}
                                    deleteMessage={deleteMessage}
                                    fetchContributors={fetchContributors}
                                  />
                                </MenuItem>
                                <MenuItem>
                                  <ReusuableSendaMailModal
                                    displayName={
                                      result.mainuserdata.displayName
                                    }
                                    id={result.id}
                                  />
                                </MenuItem>
                                {(result.message_type === "H" ||
                                  result.Keyword_ID === "hashTAG" ||
                                  result.message_type === "X") && (
                                  <MenuItem>
                                    <span>
                                      <img
                                        src={rating}
                                        style={{ marginRight: 8 }}
                                      />
                                      <span style={{ fontSize: 14 }}>
                                        Ratings
                                      </span>
                                    </span>
                                  </MenuItem>
                                )}
                              </>
                            ) : (
                              <>
                                {(result.message_type === "H" ||
                                  result.Keyword_ID === "hashTAG" ||
                                  result.message_type === "X") && (
                                  <MenuItem>
                                    <span>
                                      <img
                                        src={share}
                                        style={{ marginRight: 8 }}
                                      />
                                      <span style={{ fontSize: 14 }}>
                                        Share
                                      </span>
                                    </span>
                                  </MenuItem>
                                )}

                                <MenuItem
                                  onClick={() => onEditSelection(result.id)}
                                >
                                  <img src={edit} style={{ marginRight: 8 }} />
                                  <span style={{ fontSize: 14 }}>Edit</span>
                                </MenuItem>
                                <MenuItem>
                                  <ReusuableDeleteDialogmodal
                                    type={result.Keyword_ID}
                                    id={result.id}
                                    // setDeletePost={setDeletePost}
                                    // setDeletePostId={setDeletePostId}
                                    deleteMessage={deleteMessage}
                                    fetchContributors={fetchContributors}
                                  />
                                </MenuItem>
                              </>
                            ))}
                        </Menu>
                      </div>
                    </div>
                    <div>
                      {editPostId === result?.id ? (
                        <PostMessageValidation
                          id={result.id}
                          Message={result.Message}
                          messageType={"feedTextArea"}
                          filtertype={props.filtertype}
                          post={result}
                          fetchFeedData={fetchFeedData}
                          refreshFeedData={refreshFeedData}
                          onCloseEdit={onCloseEdit}
                        />
                      ) : (
                        <p
                          className={feedstyles.messagehidden}
                          // dangerouslySetInnerHTML={{ __html: result.Message }}
                          id={`hide${result.id}`}
                        >
                          {replaceTagAndUrl(
                            result.Message,
                            result.msg,
                            result.tag,
                            result.url
                          )}
                        </p>
                      )}
                    </div>
                    <div className={styles.followerandcount}>
                      <div>
                        {result?.message_type === "A" ||
                        result?.Keyword_ID === "OpenASK" ||
                        result?.message_type === "H" ||
                        result?.Keyword_ID === "hashTAG" ||
                        result?.Keyword_ID === "TrendingASK" ||
                        result?.message_type === "X" ? (
                          <div>
                            <SimpleDialogDemo
                              followCount={result?.followCount}
                              followData={result?.follweduserprofile}
                              id={result.id}
                              status={followData}
                              hashTagType={result?.hashTagType}
                            />
                          </div>
                        ) : (
                          <div>
                            <SimpleDialogDemo
                              voteUp={result?.voteUp}
                              likesData={result?.likeduserprofile}
                              id={result.id}
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        {result.message_type === "A" ||
                        result.Keyword_ID === "OpenASK" ? (
                          <div className={styles.askcount}>
                            {isRead || result.childcotentreadcount === 0
                              ? null
                              : result.childcotentreadcount}
                          </div>
                        ) : result.message_type === "S" ||
                          result.Keyword_ID === "OpenSAY" ? (
                          <div className={styles.saycount}></div>
                        ) : result.message_type === "I" ||
                          result.Keyword_ID === "impupdate" ? (
                          <div className={styles.updatecount}></div>
                        ) : result.message_type === "H" ||
                          result.Keyword_ID === "hashTAG" ||
                          result.message_type === "X" ? (
                          <div
                            className={
                              result.isNewMessage === 1 && result.sayCount === 0
                                ? styles.tagcounter
                                : styles.nullTagCount
                            }
                          >
                            {result.isNewMessage === 1 &&
                            result.sayCount === 0 ? (
                              <p>new</p>
                            ) : result.childcotentreadcount !== 0 &&
                              isRead === false ? (
                              result.childcotentreadcount
                            ) : null}
                            {/* <p>{result.childcotentreadcount}</p> */}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <Divider variant="fullWidth" />
                    {result.message_type === "A" ||
                    result.message_type === "H" ||
                    result.message_type === "X" ||
                    result.Keyword_ID === "OpenASK" ||
                    result.Keyword_ID === "hashTAG" ? (
                      <div className={styles.saysandfollow}>
                        <div className={styles.noOfsays}>
                          <a onClick={(e) => onSayClick(result.id, e)}>
                            <img src={saysoutlineimage} alt="numberof says" />
                            {/* <img src-={dots} alt="saysdots"/> */}

                            {result.sayCount === 1 || result.postCount === 1 ? (
                              <span>{result.sayCount} SAY</span>
                            ) : result.sayCount === 0 ? (
                              <span>SAY</span>
                            ) : (
                              <span>{result.sayCount} SAYs</span>
                            )}
                          </a>
                        </div>
                        {result.followStatus === "Active" ||
                        result.followStatus === "Y" ? (
                          <div
                            className={`${feedstyles.noOffollows}`}
                            id={`1${result.id}`}
                          >
                            <a
                              className={styles.followtext}
                              onClick={() =>
                                followunfollow(result.id, "isFollow=0")
                              }
                            >
                              <img src={unfollow} alt="unfollow" />
                              <span>Unfollow</span>
                            </a>
                          </div>
                        ) : (
                          <div
                            id={`0${result.id}`}
                            className={styles.noOffollows}
                          >
                            <a
                              className={styles.followtext}
                              onClick={() =>
                                followunfollow(result.id, "isFollow=1")
                              }
                            >
                              <img src={follow} alt="follow" />
                              <span>Follow</span>
                            </a>
                          </div>
                        )}
                        {result.hashTagType === "private" &&
                        (userDetails.userData.moderatorStatus === "1" ||
                          userDetails.userData.isAdmin === "1") ? (
                          <div className={feedstyles.priavteinvite}>
                            <img src={privateinvite} alt="Private Invite" />
                            <span
                              onClick={() =>
                                onInviteSelection(
                                  result.TenantID || result.tenantID,
                                  result.id
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              Invite
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div className={styles.likeunlike}>
                        {result.UserLikeStatus === "Active" ? (
                          <>
                            <a
                              onClick={() =>
                                onLikeUnlikeSelection(result.id, "cacheUpdate")
                              }
                              id={`like${result.id}`}
                            >
                              <img src={like} alt="like" />
                              Like
                            </a>
                          </>
                        ) : (
                          <a>
                            <img src={unlike} alt="unlike" />
                            Like
                          </a>
                        )}
                      </div>
                    )}
                    {/* .......................childsaydata ......................*/}

                    <div
                      className={styles.childsayselection}
                      id={`childsay${result.id}`}
                    >
                      {currentSayId === result.id && (
                        <PostMessageValidation
                          id={result.id}
                          type={"childSay"}
                          userImageSrc={`${profileImagePath}${userDetails?.userData?.uimage}`}
                          postChildSayData={postChildSayData}
                          fetchChildSaysData={fetchChildSaysData}
                          refreshFeedData={refreshFeedData}
                          fetchContributors={fetchContributors}
                        />
                      )}

                      {/* <div className={styles.comment}>
                                            <Avatar src={profileimage} variant="square" />

                                            <input className={styles.commentinput} placeholder="Say something..." type="text" />
                                            <a><img src={locationpinchildpost} /></a>
                                        </div>
                                        <div>
                                            <button className={styles.childpost}>POST</button>
                                        </div> */}
                      {currentSayId === result.id && (
                        <Childsay
                          id={result.id}
                          key={result.id}
                          loading={loading}
                          childSaysData={childSaysData}
                          fetchVoteUpStatus={fetchVoteUpStatus}
                          fetchFeedData={fetchFeedData}
                          deleteMessage={deleteMessage}
                          refreshFeedData={refreshFeedData}
                          fetchContributors={fetchContributors}
                        />
                      )}
                    </div>
                  </div>
                </React.Fragment>
              )
            )
          ) : (
            <p className={feedstyles.nodata}>
              No community data available at this time
            </p>
          )
        ) : (
          ""
        )}
        {invitesShow && (
          <InviteFollowers
            show={invitesShow}
            onHide={() => setInvitesShow(false)}
            invite={invite}
            tenantid={tenantId}
            msgid={msgId}
            privateTagsData={privateTagsData}
            fetchPrivateTagsData={fetchPrivateTagsData}
          />
        )}
        <div className={styles.loaderContainer}>
          {isLoading && <CircularProgress size={25} />}
        </div>
      </div>
      <div ref={loader} />
      {/* <PrivateTags /> */}
    </React.Fragment>
  );
};

export default FeedPost;
