// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TrendingTagResults_TagResults__9NBiD{
    box-shadow:3px 3px 3px rgb(163, 177, 198, 0.6), -6px -6px 12px rgba(255, 255, 255, 0.5);
    border-radius: 20px 0px 0px 20px;
    box-sizing: border-box;
    overflow-y: scroll;
    height: 812px;
    scrollbar-width: thin;
    scrollbar-color:#6b71c9;
    width: -moz-available;
    padding:20px;
}
.TrendingTagResults_TagResultContainer__G894E{
    /* display: flex; */
    /* align-items: center; */
    width: 100%;
    padding: 10px 20px;
}
.TrendingTagResults_trendingTagImage__mzJCS{
    margin-bottom: 3px;
    margin-right: 8px;
}
.TrendingTagResults_trendingTagHeading__QBIPE{
    margin: auto;
    font-size: 20px;
    margin-left: 10px;
    color: #373334;
    font-weight: 700;
}
.TrendingTagResults_loader__a59aj{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Assets/css/home/TrendingTagResults.module.css"],"names":[],"mappings":"AAAA;IACI,uFAAuF;IACvF,gCAAgC;IAChC,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,qBAAqB;IACrB,uBAAuB;IACvB,qBAAqB;IACrB,YAAY;AAChB;AACA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".TagResults{\n    box-shadow:3px 3px 3px rgb(163, 177, 198, 0.6), -6px -6px 12px rgba(255, 255, 255, 0.5);\n    border-radius: 20px 0px 0px 20px;\n    box-sizing: border-box;\n    overflow-y: scroll;\n    height: 812px;\n    scrollbar-width: thin;\n    scrollbar-color:#6b71c9;\n    width: -moz-available;\n    padding:20px;\n}\n.TagResultContainer{\n    /* display: flex; */\n    /* align-items: center; */\n    width: 100%;\n    padding: 10px 20px;\n}\n.trendingTagImage{\n    margin-bottom: 3px;\n    margin-right: 8px;\n}\n.trendingTagHeading{\n    margin: auto;\n    font-size: 20px;\n    margin-left: 10px;\n    color: #373334;\n    font-weight: 700;\n}\n.loader{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding-top: 60px;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TagResults": `TrendingTagResults_TagResults__9NBiD`,
	"TagResultContainer": `TrendingTagResults_TagResultContainer__G894E`,
	"trendingTagImage": `TrendingTagResults_trendingTagImage__mzJCS`,
	"trendingTagHeading": `TrendingTagResults_trendingTagHeading__QBIPE`,
	"loader": `TrendingTagResults_loader__a59aj`
};
export default ___CSS_LOADER_EXPORT___;
