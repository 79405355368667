import React, { useState, useEffect } from "react";
import styles from "src/Assets/css/home/Homepagesidebar.module.css";
// import AddCommunity from "src/Assets/images/AddCommunity.png";
import { Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PostMessageValidation from "../../../Modules/Reusuablecomponents/PostMessageValidation";
// import Skeleton from "@material-ui/lab/Skeleton";
import { IoMdAdd } from "react-icons/io";
import FooterComponent from "../../../Modules/Reusuablecomponents/FooterComponent";
import AvatarBadge from "src/Components/AvatarBadge";
import primaryModerator from "src/Assets/images/PrimaryModeratorCrown.svg";
import moderator from "src/Assets/images/SecondaryModeratorCrown.svg";
import feedstyles from "src/Assets/css/home/feedpost.module.css";

const LeftSideBar = (props) => {
  const {
    onCommunityChange,
    selectedCommunity,
    setShowData,
    disableCommunityChange,
    hideFooter,
  } = props;
  let userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const communityFollowNames =
    userDetails?.userData?.communityFollowNames || [];
  const hasCommunityFollowNames =
    communityFollowNames && communityFollowNames?.length;

  const [isEditBio, setEditBio] = useState(false);
  const [isEditBioText, setEditBioText] = useState("");

  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  useEffect(() => {
    if (userDetails && Object.keys(userDetails).length !== 0) {
      if (userDetails.userData.shortBio) {
        setEditBioText(userDetails.userData.shortBio);
      } else {
        setEditBioText("");
      }
    }
  }, [userDetails]);

  const onCommunitySelection = (id, name) => {
    onCommunityChange && onCommunityChange(id, name);
    setShowData && setShowData("feed");
  };

  const oneditUpdate = () => {
    setEditBio(true);
  };

  const communitySelected = userDetails?.userData?.communityFollowNames?.find(
    (each) => each.id === userDetails?.userData?.tenantId
  );

  return (
    <React.Fragment>
      {userDetails && Object.keys(userDetails).length !== 0 ? (
        <>
          <div className={styles.Profile}>
            {
              userDetails.userData.uimage ? (
                <>
                  <div className={styles.ProfileInfo}>
                    <div>
                      <AvatarBadge
                        src={`${profileImagePath}${userDetails?.userData?.uimage}`}
                        alt={userDetails?.userData?.displayName}
                        isPrimaryModerator={communitySelected?.ispm === 1}
                        isModerator={
                          communitySelected?.moderatorStatus === 1 &&
                          communitySelected?.ispm === 0
                        }
                      />
                    </div>

                    <div className={styles.ProfileDetails}>
                      <div className={styles.profilename}>
                        <span>{userDetails.userData.shortDisplayName}</span>
                      </div>

                      <span className={styles.ProfileCounts}>
                        <p>
                          {" "}
                          {userDetails.userData.askcount} ASKS |{" "}
                          {userDetails.userData.sayCount} SAYS <br />{" "}
                          {userDetails.userData.updateCount} UPDATES |{" "}
                          {userDetails.userData.hashSayCount} TAGS
                        </p>
                      </span>
                    </div>
                  </div>
                  <Divider variant="fullWidth" />

                  <div className={styles.ProfileSummary}>
                    {userDetails.userData.shortBio && (
                      <p
                        style={{
                          cursor: "pointer",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                        onClick={() => oneditUpdate()}
                        id="hideBio"
                      >
                        {isEditBioText}
                      </p>
                    )}

                    {!userDetails.userData.shortBio && !isEditBio && (
                      <p
                        onClick={() => oneditUpdate()}
                        style={{ cursor: "pointer" }}
                      >
                        Add a short message about yourself
                      </p>
                    )}

                    {isEditBio && (
                      <PostMessageValidation
                        messageType={"editBio"}
                        Message={isEditBioText}
                        id={userDetails.userData.User_ID}
                        isEditBio={isEditBio}
                        setEditBio={setEditBio}
                        minWidth={110}
                      />
                    )}
                  </div>
                </>
              ) : null
              // <Skeleton variant="rect" height={50} />
            }
          </div>
          <div className={`row container ${styles.communitycontainer}`}>
            <>
              {hasCommunityFollowNames && (
                <h5 className={`h17 bold dark-gray ${styles.mycommunities}`}>
                  My Communities
                </h5>
              )}
              <div className={styles.cardContainer}>
                {communityFollowNames.map((result) => (
                  <div
                    className={`card-col ${styles.communitycard}`}
                    key={`key-community-${result?.id}`}
                  >
                    <div
                      className={`card ${styles.CommunitySelction} ${
                        selectedCommunity.tenantName ===
                        result.userFollowCommunityName
                          ? styles.communitySelected
                          : null
                      }`}
                      style={{
                        cursor: disableCommunityChange
                          ? "not-allowed"
                          : "pointer",
                      }}
                      onClick={() =>
                        !disableCommunityChange &&
                        onCommunitySelection(
                          result.id,
                          result.userFollowCommunityName
                        )
                      }
                      id={`selectedCommunity${result.id}`}
                    >
                      <div className="community-image-container">
                        <img
                          src={`${profileImagePath}${result.thumbnailLogo}`}
                          className="community-card-img"
                          alt="..."
                        />
                        {(result.moderatorStatus == "1" ||
                          result.ispm == "1") && (
                          <img
                            src={
                              result.moderatorStatus == "1" &&
                              result.ispm == "1"
                                ? primaryModerator
                                : result.moderatorStatus == "1" &&
                                  result.ispm == "0"
                                ? moderator
                                : null
                            }
                            className="crown-symbol-in-community-card"
                            alt="..."
                          />
                        )}
                      </div>
                      <p>{result.userFollowCommunityName}</p>
                    </div>
                  </div>
                ))}
                <div className={`card-col ${styles.addCommunitySelction}`}>
                  <div style={{ margin: "5px 1px" }}>
                    <Link to="/communities" className={`dark-gray`}>
                      <div className={styles.AddCommunityimage}>
                        <IoMdAdd style={{ fontSize: 50, color: "#00000090" }} />
                      </div>
                      <p className={styles.followCommTxt}>
                        Follow or Start community
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          </div>
        </>
      ) : (
        <p>No Data</p>
      )}
      {!hideFooter && (
        <div className={styles.footer}>
          <FooterComponent />
          <span>
            <p>Skopic @ {new Date().getFullYear()}</p>
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

export default LeftSideBar;
