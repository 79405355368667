import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, Component } from "react";
import * as Cookies from "js-cookie";
import { colors } from "@material-ui/core";
import { color, height } from "@mui/system";
import { red } from "@mui/material/colors";
import * as ForgotpassAction from "../../../store/actions/Forgotpassword/ForgotpassAction";
import "../ForgotPassword/ForgotPassword.css";
import { stepButtonClasses } from "@mui/material";
import styles from "src/Assets/css/home/Feed.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "16px",
  textAlign: "center",
  boxShadow: 2,
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 3,
};

function ForgotPassowrd(props) {
  const errormessage = useSelector(
    (state) => state.NewforgotpassReducer.message
  );

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = React.useState(false);

  // const handleChange = (e) => setEmail(e.target.email);
  const dispatch = useDispatch();

  const handleClose = () => {
    // setOpen(false);
    props.setSuccess(false);
    props.setForgotOpen(false);
    // props.setErrorMessage('')
  };
  // const setClose=()=>{
  //   setSuccess(false)
  // }

  useEffect(() => {
    if (
      props.emailvalue &&
      props.emailvalue !== "" &&
      props.emailvalue.length > 0 &&
      /^[a-zA-Z]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/.test(props.emailvalue)
    ) {
      setEmail(props.emailvalue);
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.emailvalue]);

  useEffect(() => {
    if (errormessage !== "" && errormessage) {
      if (errormessage === "Fail") {
        props.setForgotOpen(true);
      } else if (errormessage === "invalid") {
        props.setForgotOpen(true);
      }
    }
  }, [errormessage]);

  const handleOpen = () => {
    // setOpen(true);
    props.setIsForgotPswdClicked(true);
    if (props.emailvalue !== "" && props.emailvalue.length > 0) {
      if (!/^[a-zA-Z]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/.test(props.emailvalue)) {
        props.setErrorMessage("Please enter a valid Email.");
      } else {
        props.setErrorMessage("");
        dispatch(ForgotpassAction.NewForgotpass(props.emailvalue));
        let maskedEmail = maskEmail(props.emailvalue);
        setEmail(maskedEmail);
      }
    }
  };

  const maskEmail = (email) => {
    let str = email.split("");
    let finalArr = [];
    let len = str.indexOf("@");
    str.forEach((item, pos) => {
      pos >= 1 && pos <= len - 2 ? finalArr.push("*") : finalArr.push(str[pos]);
    });
    finalArr.join("");
    return finalArr;
  };

  // useEffect(() => {

  // }, [email])
  // const isSubmitHandler = () => {
  //   if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
  //     return setOpen(false)
  //   }
  //   else {
  //     return setOpen(true)
  //   }
  // }

  return (
    <React.Fragment>
      {/* <Button variant="contained" onClick={handleOpen} disabled={!isEnabled}
        className={`email===""` && email.length > 0 ? !isEnabled : null}>Reset Password</Button><br /> */}

      <Button
        // id="postSubmitButton"
        size="large"
        onClick={handleOpen}
        className="resetButtonEnabled"
      >
        Reset Password
        <hr />
      </Button>
      <br />

      <p style={{ color: "grey", marginTop: "10px" }} onClick={handleClose}>
        Cancel
      </p>

      <Modal
        hideBackdrop
        open={props.open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 410, height: 230 }}>
          <h5 id="child-modal-title">
            A temporary password
            <br /> has been sent to
          </h5>

          <p>{email}</p>
          <Button
            variant="text"
            style={{ color: "grey", fontSize: "0.938rem" }}
            onClick={handleClose}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

const NestedModal = (props) => {
  const errormessage = useSelector(
    (state) => state.NewforgotpassReducer.message
  );

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    if (errormessage !== "" && errormessage) {
      if (errormessage === "Fail") {
        setErrorMessage("There is no account associated with this Email.");
      } else if (errormessage === "invalid") {
        setErrorMessage("Please enter valid Email.");
      } else {
        setSuccess(true);
        setErrorMessage("");
      }
    }
  }, [errormessage]);

  useEffect(() => {
    if (!open) {
      setErrorMessage("");
      setEmail("");
    }
  }, [open]);

  const handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    if (e.target.value === "") {
      setErrorMessage("");
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span variant="contained" onClick={handleOpen}>
        Forgot Password?
      </span>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 380, backgroundColor: "#EFEEED", paddingTop: "52px", paddingBottom: "4px" }}>
          <h4 id="parent-modal-title">Forgot password?</h4>
          <p id="parent-modal-description">
            Enter your email to reset password
          </p>

          <TextField
            id="standard-basic"
            variant="standard"
            type="email"
            placeholder="Email"
            name="email"
            value={email}
            onChange={handleChange}
            sx={{
              borderBottom: "1px solid grey",
              width: "75%",
            }}
          />
          <p style={{ fontSize: "14px" }} className="errorpop">
            {errorMessage}{" "}
          </p>

          <ForgotPassowrd
            emailvalue={email}
            open={isSuccess}
            setSuccess={setSuccess}
            setForgotOpen={setOpen}
            setIsForgotPswdClicked={props.setIsForgotPswdClicked}
            setErrorMessage={setErrorMessage}
          />
        </Box>
      </Modal>
    </>
  );
};

export default NestedModal;
