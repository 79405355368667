import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Edit from "src/Assets/images/edit.svg";
// import * as Cookies from "js-cookie";
// import axios from "axios";
import {
  fetchProfileData,
  updateProfileInfo,
} from "src/store/actions/EditProfile/EditProfileActions";
import style from "./BasicInfo.module.css";

const schema = yup.object().shape({
  displayName: yup
    .string()
    .typeError("Enter valid display name")
    .required("Display name is required"),
  firstName: yup.string().nullable(), // Optional field
  middleName: yup.string().nullable(), // Optional field
  lastName: yup.string().nullable(), // Optional field
  publicProfile: yup.string().nullable(), // Optional field
});

const BasicInfo = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const profileInfoData = useSelector(
    (state) => state.EditProfileReducer.profileInfo
  );

  const [edit, setEdit] = useState(true);
  const [buttonStyle, setButtonStyle] = useState({
    border: 0,
    padding: "20px",
    display: "inline-block",
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      displayName: "",
      firstName: "",
      middleName: "",
      lastName: "",
      publicProfile: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // Fetch profile data when the component mounts
    dispatch(fetchProfileData());
  }, [dispatch]);

  useEffect(() => {
    if (profileInfoData) {
      const {
        userDisplayName: displayName,
        firstName,
        middleName,
        lastName,
        shortBio: publicProfile,
      } = profileInfoData || {};
      reset({
        displayName,
        firstName,
        middleName,
        lastName,
        publicProfile,
      });
    }
  }, [profileInfoData, reset]);

  const onEdit = () => {
    setEdit(false);
    setButtonStyle({
      ...buttonStyle,
      display: "none",
    });
  };

  const onUpdateInfo = (data) => {
    setEdit(true);
    setButtonStyle({
      border: 0,
      padding: "20px",
      display: "inline-block",
    });

    const { displayName, firstName, middleName, lastName, publicProfile } =
      data || {};

    let formData = new FormData();
    formData.append("displayName", displayName);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("middleName", middleName);
    formData.append("shortBio", publicProfile);

    // Dispatch the action to update profile information
    dispatch(updateProfileInfo(formData));
  };

  return (
    <div ref={ref}>
      <form onSubmit={handleSubmit(onUpdateInfo)}>
        {profileInfoData && profileInfoData.length !== 0 ? (
          <>
            <div className={style.basicinfo}>
              <div className={style.infoDetals}>
                <h3 className={style.infoHead}>Basic Information</h3>
                <button
                  type="button"
                  style={buttonStyle}
                  onClick={onEdit}
                  className={style.EditButton}
                >
                  <img src={Edit} alt="Edit" />
                </button>
              </div>
              {edit ? (
                <div className={style.BasicDetails}>
                  <p>
                    <b>Email:</b> {profileInfoData.username}
                  </p>
                  <p>
                    <b>DispalyName:</b> {profileInfoData.userDisplayName}
                  </p>
                  <p>
                    <b>Name:</b>{" "}
                    {`${profileInfoData.firstName} ${profileInfoData.middleName} ${profileInfoData.lastName}`}
                  </p>
                  <p>
                    <b>Public Profile:</b> {profileInfoData.shortBio}
                  </p>
                </div>
              ) : (
                <div className={style.EditDetails}>
                  <p className={style.EditName}>
                    Email: {profileInfoData.username}{" "}
                  </p>
                  <div className={style.EditInputfeild}>
                    <label className={style.EditName}>
                      Display Name:<span style={{ color: "red" }}>*</span>
                    </label>
                    <Controller
                      control={control}
                      name="displayName"
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="text"
                          value={value}
                          placeholder={profileInfoData.userDisplayName}
                          onChange={onChange}
                          maxLength={50}
                        />
                      )}
                    />
                    {errors?.displayName && (
                      <p className={style.errorMsg}>
                        {errors?.displayName?.message}
                      </p>
                    )}
                  </div>
                  <div className={style.EditInputfeild}>
                    <label className={style.EditName}>First Name:</label>
                    <Controller
                      control={control}
                      name="firstName"
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="text"
                          value={value}
                          placeholder={profileInfoData.firstName}
                          onChange={onChange}
                          maxLength={50}
                        />
                      )}
                    />
                    {errors?.firstName && (
                      <p className={style.errorMsg}>
                        {errors?.firstName?.message}
                      </p>
                    )}
                  </div>
                  <div className={style.EditInputfeild}>
                    <label className={style.EditName}>Middle Name:</label>
                    <Controller
                      control={control}
                      name="middleName"
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="text"
                          value={value}
                          placeholder={profileInfoData.middleName}
                          onChange={onChange}
                          maxLength={50}
                        />
                      )}
                    />
                    {errors?.middleName && (
                      <p className={style.errorMsg}>
                        {errors?.middleName?.message}
                      </p>
                    )}
                  </div>
                  <div className={style.EditInputfeild}>
                    <label className={style.EditName}>Last Name:</label>
                    <Controller
                      control={control}
                      name="lastName"
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="text"
                          value={value}
                          placeholder={profileInfoData.lastName}
                          onChange={onChange}
                          maxLength={50}
                        />
                      )}
                    />
                    {errors?.lastName && (
                      <p className={style.errorMsg}>
                        {errors?.lastName?.message}
                      </p>
                    )}
                  </div>
                  <div className={style.EditTextfeild}>
                    <label className={style.EditName}>Public Profile:</label>
                    <Controller
                      control={control}
                      name="publicProfile"
                      render={({ field: { onChange, value } }) => (
                        <textarea
                          value={value}
                          placeholder={profileInfoData.shortBio}
                          onChange={onChange}
                          rows="4"
                          cols="50"
                          style={{ resize: "none" }}
                          maxLength={140}
                        />
                      )}
                    />
                    {errors?.publicProfile && (
                      <p className={style.errorMsg}>
                        {errors?.publicProfile?.message}
                      </p>
                    )}
                  </div>
                  <button type="submit" className={style.SaveButton}>
                    Save
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <div>No Profile Information available</div>
        )}
      </form>
    </div>
  );
});

export default BasicInfo;
