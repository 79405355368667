import React, { useState } from "react";
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { BiSolidEdit } from "react-icons/bi";
import { AskSayPostDataModal, SaysPublishRestriction } from "src/pages/HomePage/components/Feed";
import AsksModal from "src/pages/HomePage/components/AsksModal";
import useStyles from "../viewActivityBeforeIn/styles";

const SayAskPost = ({
  isSignIn,
  isEnable,
  answersToYourAsk = {},
  asksToBeAnswered = {},
  childSaysData = {},
  clearAsksAndSays = () => { },
  fetchSaysDataForAsks = () => { },
  fetchAsksToAnswer = () => { },
  askYourCommunity = () => { },
  fetchFeedData = () => { },
  postChildSayData = () => { },
  deleteMessage = () => { },
  filterPosts = () => { },
  fetchFollowData = () => { },
  fetchVoteUpStatus = () => { },
  fetchChildSaysData = () => { },
  deleteAnswersToAsksDialogPost = () => { },
  deleteAsksToBeAnsweredDialogPost = () => { },
}) => {
  const { post, postinput } = useStyles();

  const [modalShow, setModalShow] = useState(false);
  const [SaysShow, setSaysShow] = useState(false);
  const [saysPublishRestrictionOpen, setSaysPublishRestriction] = useState(false);
  const [isPostOption, setPostOption] = useState("");

  const onHideModalShow = () => {
    setModalShow(false);
  };

  const handleChange = () => {
    setModalShow(true);
  };

  const setModalVisibility = (parentModal, childModal, saysPublishRest) => {
    setModalShow(parentModal || false);
    setSaysShow(childModal || false);
    setSaysPublishRestriction(saysPublishRest || false);
    if (!parentModal && !childModal) {
      clearAsksAndSays && clearAsksAndSays();
    }
  };

  const onHideAsksModal = () => {
    setSaysShow(false);
    clearAsksAndSays && clearAsksAndSays();
  };

  return (
    <div className={post}>
      <Input
        type="text"
        placeholder="ASK or SAY something..."
        className={postinput}
        onClick={handleChange}
        style={{ cursor: "pointer" }}
        startAdornment={
          <InputAdornment position="start">
            <BiSolidEdit fontSize={20} style={{ marginLeft: 10 }} />
          </InputAdornment>
        }
      />
      <AskSayPostDataModal
        show={modalShow}
        onHide={onHideModalShow}
        modalShow={modalShow}
        setModalVisibility={setModalVisibility}
        answersToYourAsk={answersToYourAsk}
        asksToBeAnswered={asksToBeAnswered}
        fetchSaysDataForAsks={fetchSaysDataForAsks}
        fetchAsksToAnswer={fetchAsksToAnswer}
        askYourCommunity={askYourCommunity}
        fetchFeedData={fetchFeedData}
        postChildSayData={postChildSayData}
        deleteMessage={deleteMessage}
        filterPosts={filterPosts}
        childSaysData={childSaysData}
        fetchChildSaysData={fetchChildSaysData}
        fetchFollowData={fetchFollowData}
        fetchVoteUpStatus={fetchVoteUpStatus}
        isPostOption={isPostOption}
        setPostOption={setPostOption}
      />

      <AsksModal
        // loading={loading}
        // childSaysLoading={childSaysLoading}
        show={SaysShow}
        onHide={onHideAsksModal}
        isPostOption={isPostOption}
        setPostOption={setPostOption}
        setSaysShow={(value) => setModalVisibility(false, value)}
        answersToYourAsk={answersToYourAsk}
        asksToBeAnswered={asksToBeAnswered}
        askYourCommunity={askYourCommunity}
        fetchFeedData={fetchFeedData}
        postChildSayData={postChildSayData}
        deleteMessage={deleteMessage}
        filterPosts={filterPosts}
        childSaysData={childSaysData}
        fetchChildSaysData={fetchChildSaysData}
        fetchFollowData={fetchFollowData}
        fetchVoteUpStatus={fetchVoteUpStatus}
        deleteAnswersToAsksDialogPost={deleteAnswersToAsksDialogPost}
        deleteAsksToBeAnsweredDialogPost={deleteAsksToBeAnsweredDialogPost}
      />

      <SaysPublishRestriction
        open={saysPublishRestrictionOpen}
        setOpen={setSaysPublishRestriction}
      />
    </div>
  );
};

export default SayAskPost;
