import React, { Component, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// import { PopperUnstyled } from "@mui/material";
// import ModeratorIcon from "src/Assets/images/ModeratorCommunityImage.svg";
import * as SignInActions from "src/store/actions/SignInActions/SignInAction";
import * as feedactions from "src/store/actions/feedactions/feedActionCreator";
import * as moderatorActions from "src/store/actions/Moderator/moderatoractions";
import ModeratorCommunityAdd from "src/Assets/images/ModeratorAddCommunity.svg";
import PrimaryModeratorCrown from "src/Assets/images/PrimaryModeratorCrown.svg";
import SecondaryModeratorCrown from "src/Assets/images/SecondaryModeratorCrown.svg";
import "src/Assets/css/Moderator/ModeratorHeader.css";
// import * as moderatorActions from "src/store/actions/Moderator/moderatoractions";
import {
  fetchPhotoTimeLineList,
  fetchTenantList,
} from "../../store/actions/Moderator/moderatoractions";
// import { ModeratorMenuItems } from "../Moderator/ModeratorMenu";

function ModeratorNav(props) {
  const history = useHistory();
  const imageServerURL = process.env.REACT_APP_SKOPIC_IMAGE;
  const moderatingCommunitysData = useSelector(
    (state) => state.ModeratorReducer.moderatingCommunitysData
  );

  // const changeCommunityData = useSelector(
  //   (state) => state.followReducer.changeCommunityData
  // );
  // const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  // const [tenantId, setTenantId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      moderatingCommunitysData &&
      Object.keys(moderatingCommunitysData).length !== 0 &&
      moderatingCommunitysData.restrictions
    ) {
      // setTenantId(moderatingCommunitysData.restrictions[0].tenantId);
      // props.onChangeCommunity(moderatingCommunitysData.restrictions[0].tenantId);

      const prevTenantId = props?.tenantId;
      let prevCommunity = {};
      if (prevTenantId) { //if there is already community selected
        prevCommunity = moderatingCommunitysData?.restrictions?.find(
          (ele) => ele?.tenantId === prevTenantId
        );
      }

      if (Object.keys(prevCommunity ?? {}).length > 0 && prevCommunity?.tenantId) {
        changeCommunityHandler(prevCommunity);
      } else {
        const community = moderatingCommunitysData.restrictions[0] || {};
        changeCommunityHandler(community);
      }
    }
  }, [moderatingCommunitysData]);

  // useEffect(() => {
  //   if (changeCommunityData && Object.keys(changeCommunityData).length !== 0) {
  //     if (changeCommunityData.status === "OK") {
  //       dispatch(SignInActions.fetchloginUser(""));
  //       dispatch(moderatorActions.fetchManageMembersData(tenantId));
  //     }
  //   }
  // }, [changeCommunityData, tenantId]);

  const changeCommunityHandler = (community) => {
    // dispatch(feedactions.changeCommunity(tenantName, tenantId));
    // dispatch(
    //   moderatorActions.fetchPhotoTimeLineList(`tenantId=${tenantId}`)
    // );
    // setTenantId(community?.tenantId);
    props.onChangeCommunity(community);
    dispatch(fetchPhotoTimeLineList(`tenantId=${community?.tenantId}`));
    dispatch(fetchTenantList());
  };

  // const selectedTenantId =
  //   userDetails && userDetails.userData ? userDetails.userData.tenantId : "";

  const onModerateNewCommunity = () => {
    history && history.push("/activity");
  };

  return (
    <React.Fragment>
      <div className="ModeratorCommunitysList">
        {moderatingCommunitysData &&
        Object.keys(moderatingCommunitysData).length !== 0
          ? moderatingCommunitysData.restrictions &&
            moderatingCommunitysData.restrictions.map((restrictions) => (
              <Card
                className="CommunityCard"
                key={restrictions.tenantId}
                onClick={() => changeCommunityHandler(restrictions)}
                style={{
                  // borderWidth:
                  //   selectedTenantId === restrictions.tenantId ? "2px" : "0px",
                  borderColor:
                    props.tenantId === restrictions.tenantId ? "#4795de" : "",
                  boxShadow:
                    props.tenantId === restrictions.tenantId
                      ? "2px 2px 9px #000000"
                      : "inherit",
                  cursor: "pointer",
                  maxWidth: "200px",
                }}
              >
                <CardMedia
                  component="img"
                  height="100"
                  image={`${imageServerURL}${restrictions.thumbnailLogo}`}
                  alt="UserFollowingCommunity"
                  className="CommunityIcon"
                />
                <CardContent className="CommunityName">
                  <span>{restrictions.tenantName}</span>
                </CardContent>
                {moderatingCommunitysData.isAdmin === "0" ? (
                  <span className="ModeratorIcon">
                    <img
                      src={`${
                        restrictions.isPm === "1"
                          ? PrimaryModeratorCrown
                          : SecondaryModeratorCrown
                      }`}
                    />
                  </span>
                ) : null}
              </Card>
            ))
          : null}
        <Card
          className="CommunityCard"
          onClick={onModerateNewCommunity}
          style={{ maxWidth: "200px" }}
        >
          <div className="CommunityIcon NewCommunity">
            <CardMedia
              component="img"
              height="46"
              image={ModeratorCommunityAdd}
              alt="UserFollowingCommunity"
              className="ModerateNewCommunity"
              style={{ cursor: "pointer" }}
            />
          </div>
          <CardContent className="CommunityName">
            <span>Moderate new community</span>
          </CardContent>
        </Card>
      </div>
    </React.Fragment>
  );
}

function LabTabs(props) {
  // const [value, setValue] = React.useState("1");
  const [open, setOpen] = useState(false);
  const isPm = props?.isPm || "0";
  const disabled = String(isPm) !== "1";

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    if (disabled) {
      setOpen(true);
      return;
    }
    props.onMenuItemChange(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={props?.menuItem}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            sx={{
              display: "flex",
              gap: 2,
              "& .MuiTabs-indicator": {
                height: "3px", // Adjust the thickness of the underline
                bottom: "0px", // Adjust this to move the underline closer to the text
              },
            }}
          >
            <Tab label="Manage Content" value="1" className="moderator-tab-1" />
            <Tab
              label="Manage Members"
              value="2"
              disableRipple={disabled}
              className="moderator-tab"
            />
            <Tab
              label="Edit Community"
              value="3"
              disableRipple={disabled}
              className="moderator-tab"
            />
          </TabList>
        </Box>
        {/* <TabPanel value="1">Manage Content</TabPanel>
          <TabPanel value="2">Manage Members</TabPanel>
          <TabPanel value="3">Edit Community</TabPanel> */}
      </TabContext>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="warning"
          variant="filled"
          sx={{ width: "100%" }}
        >
          You can't access because you are not a primary moderator
        </Alert>
      </Snackbar>
    </Box>
  );
}

function ModeratorNavItems(props) {
  return (
    <React.Fragment>
      <LabTabs
        menuItem={props.menuItem}
        onMenuItemChange={props.onMenuItemChange}
        isPm={props.isPm}
      />
    </React.Fragment>
  );
}

class ModeratorHeader extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     menuItem: this.props.menuItem,
  //   };
  // }

  render() {
    return (
      <React.Fragment>
        <div className="ModeratorHeader">
          <ModeratorNav
            onChangeCommunity={this.props.onChangeCommunity}
            tenantId={this.props.tenantId}
          />
          <ModeratorNavItems
            menuItem={this.props.menuItem}
            onMenuItemChange={this.props.onMenuItemChange}
            isPm={this.props.isPm}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default ModeratorHeader;
