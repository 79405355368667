//axios dependencies
import axios from "axios";
//Action imports
import * as moderatoractions from "../../actions/Moderator/moderatoractions";
import * as allActions from "../../actions/actions.constants";
import API from '../../../network';

const moderatorService = (store) => (next) => (action) => {
  next(action);
  const devURL = process.env.REACT_APP_SERVICES_API;

  //service for moderating community list
  switch (action.type) {
    case allActions.FETCH_MODERATING_COMMUNITYS_LIST:
      API.fetchModeratingCommunityList(action.payload)
        .then((response) => {
          next(moderatoractions.receiveModeratingCommunityList(response.data));
        })
        .catch((error) => {
          //  next({ type: "FETCH_SEARCH_DATA_ERROR", error });
          console.log(error);
        });
      break;

    //fetching timeline imagelist
    case allActions.FETCH_TIMELINE_IMAGES_LIST:
      API.getTimeLineImages(action.payload)
        .then((response) => {
          next(moderatoractions.receivePhotoTimeLineList(response.data));
        })
        .catch((error) => {
          //  next({ type: "FETCH_TIMELINE_IMAGES_ERROR", error });
          console.log(error);
        });
      break;

    //upload photo to photo timeline
    // case allActions.UPLOAD_PHOTO_TO_PHOTO_TIMELINE:
    //   axios
    //     .request({
    //       url: `${devURL}index/upload-timeline-images?tenentId=${action.payload.tenantId}`,
    //       method: "POST",
    //       data: `${action.payload.tenantId}`,
    //       headers: {
    //         "content-type": "image/png",
    //       },
    //       withCredentials: true,
    //     })
    //     .then((response) => {
    //       console.log("Photo upload", action);
    //       next(moderatoractions.receivePhotoUploadstatus(response.data));
    //     })
    //     .catch((error) => {

    //       console.log("Error: " + error);
    //     });
    //   console.log("from allmoderator services:" + action.payload.tenantId);
    //   break;

    //get moderator tenantlist
    case allActions.FETCH_TENANT_LIST:
      API.getTenantList()
        .then((response) => {
          next(moderatoractions.receiveTenantList(response.data));
        })
        .catch((error) => {
          //  next({ type: "FETCH_TIMELINE_IMAGES_ERROR", error });
          console.log(error);
        });
      break;

    case allActions.GET_TENANT_LIST:
      API.getTenantList()
        .then((response) => {
          next(moderatoractions.getTenantList(response.data));
        })
        .catch((error) => {
          //  next({ type: "FETCH_TIMELINE_IMAGES_ERROR", error });
          console.log(error);
        });
      break;

    //Delete Tenant Images
    case allActions.DELETE_TIMELINE_IMAGE:
      // Check if action.payload.ImagesList is defined before accessing its properties
      if (action.payload.ImagesList && action.payload.ImagesList.imageId) {
        axios
          .request({
            url: `${devURL}/jsonuser/deleteTimelineImage.html`,
            method: "POST",
            data: action.payload.ImagesList.imageId,
            headers: {},
            withCredentials: true,
          })
          .then((response) => {
            next(
              moderatoractions.receiveDeletedTimeLineImagesResponse(response.data)
            );
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // Handle the case when action.payload.ImagesList or imageId is not defined
        console.error("Invalid payload structure for DELETE_TIMELINE_IMAGE");
      }
      break;

    //Update Tenant Images Description
    // case allActions.UPDATE_TIMELINE_IMAGE_DESC:
    //   axios
    //     .request({
    //       url: `${devURL}/jsonuser/timeline-photo-desc`,
    //       method: "POST",
    //       data: action.payload,
    //       headers: {},

    //       withCredentials: true,
    //     })
    //     .then((response) => {
    //       next(
    //         moderatoractions.receiveTimeLineImagesUpdateResponse(response.data)
    //       );
    //     })
    //     .catch((error) => {
    //       //  next({ type: "FETCH_TIMELINE_IMAGES_ERROR", error });
    //       console.log(error);
    //     });
    //   break;

    //ManageMembers community moderator middlewear

    case allActions.FETCH_MANAGE_MEMBERS_DATA:
      API.getMembersData(action.payload)
        .then((response) => {
          //  console.log(response.data.userList)
          next(
            moderatoractions.receiveManageMembersData(response.data.userList)
          );
        })
        .catch((error) => {
          console.log(error);
        });
      break;

    // Managemembers community block member moderator middlewear

    case allActions.FETCH_BLOCK_MEMBERS_DATA:
      API.blockUser(action.payload)
        .then((response) => {
          console.log(response.data);

          // next(moderatoractions.receiveManageMembersData(response.data))
        })
        .catch((error) => {
          console.log(error);
        });
      break;

    // Manage members community getBlockUserData in members moderators actions

    case allActions.FETCH_BLOCKED_USERS_DATA:
      API.getBlockedUsers(action.payload)
        .then((response) => {
          console.log(response.data);

          // next(moderatoractions.receiveManageMembersData(response.data))
        })
        .catch((error) => {
          console.log(error);
        });
      break;

    // // Manage members community warnUserCommunity in members moderators actions

    case allActions.FETCH_WARN_USER_COMMUNITY:
      API.warnUser(action.payload)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      break;

    //  Manage members community Invite Members in submenu of ManageMembers actions

    case allActions.FETCH_INVITE_MEMBERS_DATA:
      API.fetchInviteMembersData(action.payload)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      break;

    // Manage members community Invite as Moderator in submenu of ManageMembers actions

    case allActions.FETCH_INVITE_AS_MODERATOR:
      API.inviteAsModerator(action.payload)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      break;

    // Manage members community Remove as Moderator in submenu of ManageMembers actions

    case allActions.FETCH_REMOVE_AS_MODERATOR:
      API.inviteAsModerator(action.payload)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      break;

    //////////////Manage Content Api Modearator calls starts from here ////////////

    //Manage content community ActionItems in Moderator
    case allActions.FETCH_ACTION_ITEMS_DATA:
      API.getModeratorActionItems(action.payload)
        .then((response) => {
          // console.log(response);
          next(moderatoractions.recieveActionItemsData(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
      break;

    //Manage content community PrivateTags in Moderator

    case allActions.FETCH_PRIVATE_TAG_DATA:
      // console.log("RohitRRRRRRRRRRR", action);
      API.fetchPrivateTagsData(null, null, action.payload)
        .then((response) => {
          // console.log(response)
          // return response
          next(moderatoractions.recievePrivateTagsData(response.data));
        })
        .catch((error) => {
          console.log("privatetagAction", error);
        });
      break;

    ////Manage content community ActivityLog in Moderator

    // case allActions.FETCH_ACTIVITY_LOG_DATA:
    //   axios
    //     .request({
    //       //  url: `${devURL}/skopicportal/jsonuser/report?isNewUiVersion=yes&tenantId=${tId}&type=${message}`,
    //       url: `${devURL}/jsonuser/report?${action.payload}`,

    //       method: "GET",
    //       headers: {},
    //       // data: action.payload,
    //       withCredentials: true,
    //     })
    //     .then((response) => {
    //       console.log(response);
    //       next(moderatoractions.recieveActivityLogData(response.data));
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    //   break;

    default:
      break;
  }
};
export default moderatorService;
