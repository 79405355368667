import React from "react";
import DefaultCommunity from "../defaultCommunity";
import { useSelector } from "react-redux";

const FollowCommunities = () => {
  const userDetails = useSelector((state) => state?.SignInReducer?.userDetails);
  const { countryId } = userDetails?.userData || {};

  return (
    <>
      <DefaultCommunity signIn={true} hideSearch={true} countryId={countryId} />
    </>
  );
};

export default FollowCommunities;
