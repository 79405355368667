import * as allActions from "../../actions/actions.constants";

const initialState = {
  profileInfo: [],
};

const EditProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case allActions.FETCH_PROFILE_DATA:
      return state; // Return the current state, not the action

    case allActions.RECIEVE_PROFILE_DATA:
      return {
        ...state,
        profileInfo: action.payload,
      };
      case allActions.UPDATE_PROFILE_INFO:
      // Assuming action.payload.newData contains the updated profile information
      return {
        ...state,
        profileInfo: action.payload.newData,
      };

    default:
      return state;
  }
};

export default EditProfileReducer;
