import React from "react";
import ActionItems from "./ActionItems";
import PrivateTags from "./PrivateTags";
import ActivityLog from "./ActivityLog";

const ManageContent = ({
  subMenuItem,
  tenantId,
  tenantName,
  type,
  setActionItemCount,
  decreaseActionItemCount,
}) => {
  return (
    <div>
      {subMenuItem === "Private Tags" ? (
        <PrivateTags tenantId={tenantId} tenantName={tenantName} />
      ) : subMenuItem === "Activity Log" ? (
        <ActivityLog tenantId={tenantId} tenantName={tenantName} type={type} />
      ) : (
        <ActionItems
          tenantId={tenantId}
          tenantName={tenantName}
          setActionItemCount={setActionItemCount}
          decreaseActionItemCount={decreaseActionItemCount}
        />
      )}
    </div>
  );
};

export default ManageContent;
