import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
// import * as moderatorActions from 'src/store/actions/Moderator/moderatoractions';
import style from "src/Assets/css/Moderator/Managemembes.module.css";
import API from '../../../network';

function ManagememModerator(props) {
    const { tenantId } = props || {};
    const data = useSelector(state => state.ManagemembersReducer.ManagemebersData)
    // const dispatch = useDispatch();
    // let serverImageURL = process.env.REACT_APP_SKOPIC_IMAGE;
    const [membersData, setMembersData] = useState([]);

    useEffect(() => {
        setMembersData(data);
    }, [data]);

    const onRemoveAsModerator = (userID) => {
        let formData = new FormData();
        formData.append("tenantId", tenantId)
        formData.append("rejectedIds", userID);
        API.inviteAsModerator(formData)
            .then((response) => {
                const updatedData = membersData.filter(each => each.id !== userID);
                setMembersData(updatedData);
                alert('removed as moderator');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const warnUserCommunity = (userID) => {
        let formData = new FormData();
        formData.append("tenantId", tenantId);
        formData.append("userId", userID);
        formData.append(
            "warnMessage",
            `Your community moderator has noted that the content you posted recently in crossword is not in compliance with the <a id="userguidlines" href="${process.env.REACT_APP_SERVICES_API}user/userGuide.html" tabindex="41">user guidelines</a> of Skopic. As a matter of courtesy and requirement, we ask that everyone of us maintain the decorum of our online community by following the best practices for sharing content. Please be advised that any future noncompliance shall result in blocking you from the community`
        );
        API.warnUser(formData)
            .then((response) => {
                alert("Warning sent successfully");
                console.info(response);
            })
            .catch((error) => {
                alert("Something went wrong");
                console.error(error);
            });
    };

    return (
        <div className={style.container} >
            {(membersData && membersData.length !== 0) ?
                <>
                    {membersData.map((result, i) =>
                        (result.isModerator === "1" || result.isModerator === "2")
                            ?
                            <div className={style.communityUsers} key={`key-${result?.id}`}>
                                <div className={style.avatharuser}>
                                    < Avatar className={style.Linkedinimage} src={result.uimage} alt="image" />
                                    <div>
                                        <span >{result.displayName}</span>
                                        {
                                            (result.isModerator === "1" || result.isModerator === "2")
                                                ?
                                                <p>Moderator</p>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                <div className={style.dropdown}>
                                    <span className={style.link}> <ExpandMoreIcon></ExpandMoreIcon>
                                        <ul className={style.dlist}>
                                            {
                                                // (
                                                //     result.isModerator === "1" || result.isModerator === "2"
                                                // ) ?
                                                <li
                                                    onClick={() => onRemoveAsModerator(result.id)}
                                                    className={style.moderatorList}
                                                >
                                                    <HighlightOffIcon className={style.moderatoricons} />
                                                    <p>Remove as moderator</p>
                                                </li>
                                                // :

                                                // <li>Invite as moderator</li>
                                            }
                                            <li
                                                onClick={() => warnUserCommunity(result.id)}
                                                className={style.moderatorList}
                                            >
                                                <WarningAmberRoundedIcon className={style.moderatoricons} />
                                                <p>Send warning</p>
                                            </li>
                                        </ul>
                                    </span>
                                </div>
                            </div>
                            :
                            null
                    )}
                </>
                : null
            }
        </div>
    );
};

export default ManagememModerator;
