import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Modal,
  IconButton,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemIcon,
  CircularProgress,
} from "@material-ui/core";
import { IoCloseCircleOutline } from "react-icons/io5";
import API from "src/network";
import ConfirmationDialog from "src/Components/ConfirmationDialog";
import classes from "./GmailContactsModal.module.css";

// const SCOPES = "https://www.googleapis.com/auth/contacts.readonly";
// const CLIENTID = "1096351929813-b3sqsgi9t95dfbr7jhsruvsfvh8q6iac.apps.googleusercontent.com";

const GmailContactsModal = ({ open, handleClose, authCode }) => {
  const [loading, setLoading] = useState(false);
  const [inviting, setInviting] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [error, setError] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [subject, setSubject] = useState("");
  const [inviteMessage, setInviteMessage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const userDetails = useSelector(
    (state) => state.SignInReducer?.userDetails || {}
  );

  useEffect(() => {
    const { tenantName, tenantId, displayName } = userDetails?.userData || {};
    setSubject(`Invitation to join ${tenantName} via Skopic`);
    setInviteMessage(
      `Hi,%0A%0AI request you to join ${tenantName} on Skopic. It is quick and easy to Sign Up.%0A%0AClick the link below to join ${tenantName}.%0A${process.env.REACT_APP_SERVICES_API}user/communityurl.html?tid=${tenantId}%0A%0AThank you,%0A${displayName}`
    );
  }, [userDetails]);

  useEffect(() => {
    searchContacts();
  }, [searchText, contacts]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await API.fetchGmailContacts(authCode);
        const gContacts = response?.data || {};
        setContacts(getFormattedContacts(gContacts));
      } catch (err) {
        setError("Failed to fetch contacts!");
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [authCode]);

  const getFormattedContacts = (contacts) => {
    const displayNames = contacts?.displayNames || [];
    const imageUrls = contacts?.imageUrls || [];
    const resourceIds = contacts?.resourceId || [];
    const emailStatus = contacts?.emailStatus || [];
    const gmailcontacts = contacts?.gmailcontacts || {};
    const formattedContacts = displayNames.map((eachContact, index) => {
      const rId = resourceIds?.length >= index ? resourceIds[index] : "";
      return ({
        resourceId: rId,
        displayName: eachContact,
        imageUrl: imageUrls?.length >= index ? imageUrls[index] : "",
        emailStatus: emailStatus?.length >= index ? emailStatus[index] : "",
        emailAddress: gmailcontacts[rId],
        selected: false,
      });
    });
    return formattedContacts;
  };

  // const jssionId = Cookies.get("JSESSIONID"); // Get JSESSIONID from cookies

  // const login = useGoogleLogin({
  //   onSuccess: async (tokenResponse) => {
  //     console.log("tokenResponse", tokenResponse);
  //     try {
  //       const response = await axios.get(
  //         `https://dev.skopic.com/skopicportal/gmail/redirect-gmail-contact-values?code=${tokenResponse.code}&app_type=web`,
  //         {
  //           withCredentials: true,
  //           headers: {
  //             JSESSIONID: jssionId, // Pass JSESSIONID in the headers
  //           },
  //         }
  //       );
  //       console.log(`test`);
  //       console.log(response);
  //       console.log(`test over`);
  //     } catch (err) {
  //       setError("Failed to fetch contacts!");
  //       console.error(err);
  //     }
  //   },
  //   onError: (error) => {
  //     setError("Authentication failed!");
  //     console.error(error);
  //   },
  //   flow: "auth_code",
  //   // scope: SCOPES,
  //   // redirect_uri: 'https://dev.skopic.com/Home',
  // });

  // useEffect(() => {
  //   // login && login();
  // }, []);

  const onSelectAll = () => {
    if (selectAll) {
      setContacts([...contacts.map((each) => ({
        ...each,
        selected: false,
      }))]);
    } else {
      setContacts([...contacts.map((each) => ({
        ...each,
        selected: !isEmailNotSet(each?.emailStatus),
      }))]);
    }
    setSelectAll(prevState => !prevState);
    setError("");
  };

  const onClickEachCheckbox = (resourceId) => {
    const updatedContacts = contacts.map((each) => {
      if (each.resourceId === resourceId) {
        return ({
          ...each,
          selected: !each.selected,
        });
      }
      return ({ ...each });
    });

    const hasUnselected = updatedContacts.some((each) => !each.selected && !isEmailNotSet(each?.emailStatus));
    setContacts(updatedContacts);
    setSelectAll(!hasUnselected);
    setError("");
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const searchContacts = () => {
    const filteredContacts = contacts.filter((each) => {
      const displayName = each?.displayName ? each?.displayName?.toLowerCase() : "";
      return displayName.indexOf(searchText.toLowerCase()) !== -1;
    });
    setFilteredContacts(filteredContacts);
  };

  const isEmailNotSet = (emailStatus) => {
    return emailStatus?.toLowerCase() === "can't invite as email not set";
  };

  const onInvite = async () => {
    if (inviting) return;
    const emailAddressList = contacts.filter((each) => each.selected).map(each => each.emailAddress);
    if (emailAddressList.length === 0) {
      setError("Please select contacts");
      return;
    };

    const emailsParam = emailAddressList.length > 1
      ? emailAddressList.join(",")
      : emailAddressList[0];

    try {
      setInviting(true);
      const res = await API.sendEmailInvite(emailsParam, inviteMessage, subject);
      setSuccessMsg(res?.data);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setInviting(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <div className={classes.container}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <div style={{ paddingLeft: "16px", fontWeight: 600 }}>Invite friends via Gmail contacts</div>
              <IconButton onClick={handleClose}>
                <IoCloseCircleOutline />
              </IconButton>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              // pb={3}
              pr={3}
              pl={1}
            >
              {loading && (<CircularProgress />)}
              {(!loading && contacts?.length === 0) ? (
                <div
                  style={{ display: "flex", height: "150px", alignItems: "center" }}
                >
                  No contacts found
                </div>
              ) : (
                <>
                  <div className={classes.searchWrapper}>
                    <div className={classes.selectAllContainer}>
                      <input
                        type="checkbox"
                        className={classes.checkbox}
                        checked={selectAll}
                        onClick={onSelectAll}
                      />
                      <span style={{ marginLeft: 10 }}>Select All</span>
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        placeholder="Search"
                        className={classes.searchInput}
                        value={searchText}
                        onChange={onSearch}
                      />
                    </div>
                  </div>
                  <List className={classes.list}>
                    {filteredContacts.map((contact) => (
                      <>
                        <ListItem alignItems="flex-start" key={contact?.resourceId}>
                          <ListItemIcon>
                            <input
                              type="checkbox"
                              className={classes.checkbox}
                              checked={contact?.selected}
                              onClick={() => onClickEachCheckbox(contact?.resourceId)}
                              disabled={isEmailNotSet(contact?.emailStatus)}
                            />
                          </ListItemIcon>
                          <ListItemAvatar>
                            <Avatar alt="Avatar" src={contact?.imageUrl} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={contact?.displayName}
                            secondary={contact?.emailStatus}
                            secondaryTypographyProps={{
                              color: isEmailNotSet(contact?.emailStatus) ? "error" : "textSecondary",
                            }}
                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </>
                    ))}
                  </List>
                  <div className={classes.buttonWrapper}>
                    <Button className={classes.closeBtn} onClick={handleClose}>Close</Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.inviteBtn}
                      onClick={onInvite}
                    >
                      {inviting
                        ? <CircularProgress color="white" style={{ width: 20, height: 20 }} />
                        : "Invite"
                      }
                    </Button>
                  </div>
                </>
              )}
              {error && (
                <div style={{ fontSize: "12px", color: "red" }}>{error}</div>
              )}
            </Box>
          </div>
        </Box>
      </Modal>
      {successMsg && (
        <ConfirmationDialog
          open={!!successMsg}
          handleClose={handleClose}
          title={successMsg}
          acceptLabel="Ok"
          onAccept={handleClose}
          type="info"
        />
      )}
    </>
  );
};

export default GmailContactsModal;
