import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import axios from "axios";
// import Cookies from "js-cookie";
import Edit from "src/Assets/images/edit.svg";
import { fetchProfileData } from "src/store/actions/EditProfile/EditProfileActions";
import Api from "src/network";
import { countries } from "../constants";
import style from "./ContactInfo.module.css";

const schema = yup.object().shape({
  countryCode: yup
    .string()
    .typeError("Select country code")
    .required("Please select country code"),
  country: yup
    .string()
    .typeError("Select country")
    .required("Please select country"),
  state: yup
    .string()
    .typeError("Enter valid state")
    .required("State is required"),
  address: yup
    .string()
    .typeError("Enter valid address")
    .required("Address is required"),
  city: yup.string().typeError("Enter valid city").required("City is required"),
  zipCode: yup
    .string()
    .typeError("Enter valid zip code")
    .required("Zip code is required"),
  phoneNumber: yup
    .string()
    .typeError("Enter valid phone number")
    .required("Phone number is required"),
});

const ContactInfo = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const profileInfoData = useSelector(
    (state) => state.EditProfileReducer.profileInfo
  );

  const [edit, setEdit] = useState(true);
  const [buttonStyle, setButtonStyle] = useState({
    border: 0,
    padding: "20px",
    display: "inline-block",
  });

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      countryCode: "",
      country: "",
      state: "",
      address: "",
      city: "",
      zipCode: "",
      phoneNumber: "",
    },
    resolver: yupResolver(schema),
  });

  const selectedCountry = watch("country");

  useEffect(() => {
    // Fetch profile data when the component mounts
    dispatch(fetchProfileData());
  }, [dispatch]);

  useEffect(() => {
    if (profileInfoData) {
      const {
        country,
        state,
        address,
        city,
        zipcode: zipCode,
        phone,
      } = profileInfoData || {};
      const phnArr = String(phone).split(" ");
      let countryCode = "1";
      let phoneNumber = phone;
      if (phnArr.length > 1) {
        countryCode = String(phnArr[0]).replace("+", "");
        phoneNumber = phnArr[1];
      }

      reset({
        country,
        state,
        address,
        city,
        zipCode,
        countryCode,
        phoneNumber,
      });
    }
  }, [profileInfoData, reset]);

  const onEdit = () => {
    setEdit(false);
    setButtonStyle({
      ...buttonStyle,
      display: "none",
    });
  };

  const onUpdateInfo = async (data) => {
    try {
      const {
        countryCode,
        phoneNumber,
        country,
        state,
        city,
        address,
        zipCode,
      } = data || {};
      // Combine phone prefix and number
      const fullPhoneNumber = `+${countryCode} ${phoneNumber}`;
      console.log("Full Phone Number:", fullPhoneNumber);

      const formData = new FormData();
      formData.append("phone", fullPhoneNumber);
      formData.append("zipcode", zipCode);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("country", country);
      formData.append("state", state);

      await Api.updateContactInfo(formData);

      // Update Redux state with the new data
      dispatch(fetchProfileData());

      // Toggle back to view mode
      setEdit(true);
      setButtonStyle({
        border: 0,
        padding: "20px",
        display: "inline-block",
      });
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <div ref={ref}>
      <form onSubmit={handleSubmit(onUpdateInfo)}>
        {profileInfoData && profileInfoData.length !== 0 && (
          <>
            <div className={style.basicinfo}>
              <div className={style.infoDetals}>
                <h3 className={style.infoHead}>Contact Information</h3>
                <button
                  type="button"
                  style={buttonStyle}
                  onClick={onEdit}
                  className={style.EditButton}
                >
                  <img src={Edit} alt="Edit" />
                </button>
              </div>
              {edit ? (
                <div className={style.BasicDetails}>
                  <p>
                    <b>Phone: </b>
                    {profileInfoData.phone || "N/A"}
                  </p>
                  <p>
                    <b>Country:</b> {profileInfoData.country || "N/A"}
                  </p>
                  <p>
                    <b>Address:</b> {profileInfoData.address || "N/A"}
                  </p>
                  <p>
                    <b>City:</b> {profileInfoData.city || "N/A"}
                  </p>
                  <p>
                    <b>State:</b> {profileInfoData.state || "N/A"}
                  </p>
                  <p>
                    <b>Zip Code:</b> {profileInfoData.zipcode || "N/A"}
                  </p>
                </div>
              ) : (
                <div className={style.EditDetails}>
                  <div className={style.EditInputfeild}>
                    <label className={style.EditName}>Phone:</label>
                    <Controller
                      control={control}
                      name="countryCode"
                      render={({ field: { onChange, value } }) => (
                        <select
                          className={style.EditSubSelectFeild}
                          value={value}
                          onChange={onChange}
                        >
                          <option value="">Select Country</option>
                          {countries.map((country) => (
                            <option key={country.code} value={country.code}>
                              {`+${country.code}(${country.label})`}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    <Controller
                      control={control}
                      name="phoneNumber"
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="text"
                          className={style.PhoneTextFeild}
                          value={value}
                          // onChange={onChange}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(
                              /\D/g,
                              ""
                            );
                            onChange(numericValue);
                          }}
                          maxLength={10}
                        />
                      )}
                    />
                    {(errors?.countryCode || errors?.phoneNumber) && (
                      <p className={style.errorMsg}>
                        {errors?.countryCode?.message}{" "}
                        {errors?.phoneNumber?.message}
                      </p>
                    )}
                  </div>

                  <div className={style.EditInputfeild}>
                    <label className={style.EditName}>Country:</label>
                    <Controller
                      control={control}
                      name="country"
                      render={({ field: { onChange, value } }) => (
                        <select
                          className={style.EditSelectFeild}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            setValue("state", "");
                          }}
                        >
                          {profileInfoData?.statesList &&
                            Object.keys(profileInfoData?.statesList).map(
                              (country) => (
                                <option key={country} value={country}>
                                  {country}
                                </option>
                              )
                            )}
                        </select>
                      )}
                    />
                    {errors?.country && (
                      <p className={style.errorMsg}>
                        {errors?.country?.message}
                      </p>
                    )}
                  </div>
                  <div className={style.EditInputfeild}>
                    <label className={style.EditName}>Address:</label>
                    <Controller
                      control={control}
                      name="address"
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="text"
                          value={value}
                          onChange={onChange}
                          maxLength={50}
                        />
                      )}
                    />
                    {errors?.address && (
                      <p className={style.errorMsg}>
                        {errors?.address?.message}
                      </p>
                    )}
                  </div>
                  <div className={style.EditInputfeild}>
                    <label className={style.EditName}>City:</label>
                    <Controller
                      control={control}
                      name="city"
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="text"
                          value={value}
                          onChange={onChange}
                          maxLength={50}
                        />
                      )}
                    />
                    {errors?.city && (
                      <p className={style.errorMsg}>{errors?.city?.message}</p>
                    )}
                  </div>
                  <div className={style.EditInputfeild}>
                    <label className={style.EditName}>State:</label>
                    <Controller
                      control={control}
                      name="state"
                      render={({ field: { onChange, value } }) => (
                        <select
                          className={style.EditSelectFeild}
                          value={value}
                          onChange={onChange}
                        >
                          {selectedCountry &&
                            profileInfoData.statesList[selectedCountry].map(
                              (state) => (
                                <option key={state} value={state}>
                                  {state}
                                </option>
                              )
                            )}
                        </select>
                      )}
                    />
                    {errors?.state && (
                      <p className={style.errorMsg}>{errors?.state?.message}</p>
                    )}
                  </div>
                  <div className={style.EditInputfeild}>
                    <label className={style.EditName}>Zip Code:</label>
                    <Controller
                      control={control}
                      name="zipCode"
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="text"
                          value={value}
                          maxLength={15}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors?.zipCode && (
                      <p className={style.errorMsg}>
                        {errors?.zipCode?.message}
                      </p>
                    )}
                  </div>
                  <button type="submit" className={style.SaveButton}>
                    Save
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </form>
    </div>
  );
});

export default ContactInfo;
