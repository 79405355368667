import React, { useState } from "react";
import { useEffect } from "react";
import { Typography, Avatar, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import styles from "src/Assets/css/home/post.module.css";
import crown from "src/Assets/images/crown.png";
import silvercrown from "src/Assets/images/silvercrown.png";
import API from "../../../network";
import AvatarBadge from "src/Components/AvatarBadge";

const Container = styled("div")({
  backgroundColor: "#fff",
  margin: "20px",
  borderRadius: "20px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",

  // for chrome and safari
  "&::-webkit-scrollbar": {
    width: "0",
    background: "transparent",
  },

  // Firefox
  scrollbarWidth: "none", // Remove scrollbar width for Firefox
});

const Status = styled("div")({
  width: "15px",
  height: "15px",
  borderRadius: "15px",
  alignSelf: "flex-end",
  marginBottom: "6px",
});

const UserName = styled(Typography)({
  fontSize: "16px",
  fontWeight: "500",
  color: "#373334"

});

const MessageTime = styled(Typography)({
  fontSize: "12px",
  color: "#373334"
});

const NameContainer = styled("div")({
  flex: 1,
  flexDirection: "column",
  display: "flex",
  marginLeft: "8px"
});

function ActivityLog(props) {
  const [activityLogData, setActivityLogData] = useState([]);

  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  useEffect(async () => {
    const response = await API.fetchActivityLogData(props.tenantId, props.type);
    setActivityLogData(response.data.messageList);
  }, [props.type, props.tenantId]);

  const currStatus = (status) => {
    console.log(`status: ${status}`)
    switch (status) {
      case "Y":
        return "Approved";
      case "NA":
        return "Rejected";
      case "1":
        return "Approved";
      case "0":
        return "Rejected";
      case "A":
        return "Approved"
      case "I":
        return "Rejected"
      default:
        return "";
    }
  };

  const isActive = (status) => {
    switch (status) {
      case "SAY":
        return "#45A735";
      case "UPDATE":
        return "#E82727";
      case "#TAG":
        return "#EFBA29";
      default:
        return "#2168B2";
    }
  };

  const { type } = props;

  if (activityLogData.length < 1) {
    return (
      <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
        No data found
      </div>
    );
  }

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  }

  return (
    <>
      {activityLogData.map((item) => (
        <Container style={{}} key={`key-id-${item?.id}`}>
          <div style={{ display: "flex" }}>
            <AvatarBadge
              src={`${profileImagePath}${type === "report"
                ? item.rpt_created_image_s
                : type === "message"
                  ? item.msg_usr_image_s
                  : item.image_s
                }`}
              className={styles.avatar}
              variant="square"
              isPrimaryModerator={type == "moderator" ? item.is_primary_moderator_s == 1 : false}
              isModerator={type == "moderator" ? item.moderator_status_s == 1 && item.is_primary_moderator_s == 0 : false}
            />
            <NameContainer>
              <UserName>
                {type === "report"
                  ? item.rpt_created_display_name_s
                  : type === "message"
                    ? item.msg_usr_display_name_s
                    : item.display_name_s}
              </UserName>
              <MessageTime>
                {type == "report"
                  ? item.rpt_created_date_dt
                  : type === "message"
                    ? item.msg_created_date_dt
                    : item.created_date_dt}
              </MessageTime>
            </NameContainer>
            {type === "report" && item.rpt_reason_s && (
              <div style={{ fontSize: "12px" }}>
                Reported by <b>{item.rpt_display_name_s}</b> as{" "}
                <b>{truncateText(item.rpt_reason_s, 50)}</b>
              </div>
            )}
          </div>
          <div style={{ marginTop: "10px", fontSize: "14px" }}>
            {type == "report"
              ? item.rpt_message_text_s
              : item.msg_message_text_s}
          </div>
          {type != "moderator" ? (
            <Status
              style={{
                backgroundColor: isActive(
                  type === "report"
                    ? item.rpt_message_type_s
                    : item.msg_message_type_s
                ),
              }}
            />
          ) : (
            <img
              src={silvercrown}
              height="16px"
              width="20px"
              style={{
                marginLeft: "auto",
                marginBottom: "5px",
                marginRight: "6px",
              }}
            />
          )}
          <Divider variant="fullWidth" />
          <div style={{ marginTop: "10px", fontSize: "14px" }}>
            <b>
              {type === "report"
                ? item.rpt_message_type_s
                : type === "message"
                  ? item.msg_message_type_s
                  : "Moderator Request"}
            </b>{" "}
            was{" "}
            <b>
              {currStatus(
                type === "report"
                  ? item.rpt_admin_status_s
                  : type === "message"
                    ? item.msg_message_status_s
                    : item.moderator_status_s
              )}
            </b>{" "}
            {type === "report"
              ? item.rpt_action_on_time_dt
              : type === "message"
                ? item.msg_action_on_time_dt
                : item.action_on_time_dt}{" "}
            ago by{" "}
            <b>
              {type === "report"
                ? item.rpt_a_display_name_s
                : type === "message"
                  ? item.msg_a_display_name_s
                  : item.action_by_display_name_s}
            </b>
          </div>
        </Container>
      ))}
    </>
  );
}
export default ActivityLog;
