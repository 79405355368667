import React, { useState } from 'react'
import UploadPhotoTimeLineDialog from '../dialogs/UploadPhotoTimelineDialog'

export default function TestPage() {

  let [showDialog, setShowDialog] = useState(false);

  const onCancleDialog = ()=>{
    console.log("clicked main")
    setShowDialog(!showDialog)
  }



  return (
    <div className='testStyle'>
      <p>TestPage</p>

      <UploadPhotoTimeLineDialog tenantId={112} showDialog={showDialog} onCancelDialog={onCancleDialog}/>

      <button onClick={onCancleDialog} >Open Dialog</button>

    </div>
  )
}
