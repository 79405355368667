// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebarParentoptions {
  background-color: white !important;
  border-radius: 25px !important;
  margin-left: 10%;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 5%;
}
.sidebarParentoptionsdarkTheme {
  background-color: #585858 !important;
  color: whitesmoke;
}

.sidebarParentoptions1 {
  margin-inline-start: 15px;
  margin-block-start: 10px;
  padding-bottom: 3%;
  margin-inline-end: 30px;
}
.EditProfile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  overflow: auto;
  scrollbar-width: none;
}
@media screen and (min-width: 768px) {
  .EditProfile {
    overflow: hidden;
  }
}

@media screen and (min-width: 992px) {
  .EditProfile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}
.sidebartoggleoptions {
  margin-right: 5%;
  margin-left: 10%;
  margin-block-start: 70px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EditProfile/EditProfile.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,8BAA8B;EAC9B,gBAAgB;EAChB,wBAAmB;EAAnB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,oCAAoC;EACpC,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,wBAAwB;EACxB,kBAAkB;EAClB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,mBAAmB;EACnB,cAAc;EACd,qBAAqB;AACvB;AACA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;EACzB;AACF;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,wBAAwB;AAC1B","sourcesContent":[".sidebarParentoptions {\n  background-color: white !important;\n  border-radius: 25px !important;\n  margin-left: 10%;\n  height: fit-content;\n  margin-top: 5%;\n}\n.sidebarParentoptionsdarkTheme {\n  background-color: #585858 !important;\n  color: whitesmoke;\n}\n\n.sidebarParentoptions1 {\n  margin-inline-start: 15px;\n  margin-block-start: 10px;\n  padding-bottom: 3%;\n  margin-inline-end: 30px;\n}\n.EditProfile {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: 20px;\n  overflow: auto;\n  scrollbar-width: none;\n}\n@media screen and (min-width: 768px) {\n  .EditProfile {\n    overflow: hidden;\n  }\n}\n\n@media screen and (min-width: 992px) {\n  .EditProfile {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: flex-start;\n  }\n}\n.sidebartoggleoptions {\n  margin-right: 5%;\n  margin-left: 10%;\n  margin-block-start: 70px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
