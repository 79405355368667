import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "src/Assets/css/header/header.module.css";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { Typography } from "@material-ui/core";
import { IoLocationOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import Notifications from "src/pages/Notifications";
import profileImg from "src/Assets/images/headers/profilesvg.svg";
import homeImg from "src/Assets/images/headers/homesvg.svg";
import crownImg from "src/Assets/images/headers/crown.svg";
import bellImg from "src/Assets/images/headers/notificationsvg.svg";
import settingImg from "src/Assets/images/headers/settingssvg.svg";
import logo from "src/Assets/images/skopic.png";
import humberger from "src/Assets/images/humberger.svg";
import * as mapActions from "src/store/actions/mapactions/mapAction";
import { PageEnum } from "src/layout/Page";
import AskSvg from "./AskSvg";
import SaySvg from "./SaySvg";
import UpdateSvg from "./UpdateSvg";
import TagSvg from "./TagSvg";
import ConfirmationDialog from "../ConfirmationDialog";
import profileimageicon from "src/Assets/images/ProfileImageIcon.png";

const filtersDefaultState = {
  ask: true,
  say: true,
  update: true,
  tag: true,
};

const Header = (props) => {
  const {
    fetchSearchData,
    filterPosts,
    clearSearchHeader,
    goBack,
    isMenuActionDisabled,
    flow,
    page,
    hideSearch,
  } = props;

  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(PageEnum.Home);
  const [isButtonSelect, setButtonSelect] = useState("all");
  const [isSelect, setSelect] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const [isAllCommunitysButtonClicked, setAllCommunityButtonClicked] =
    useState(true);
  const [isPresentCommunityButtonClicked, setPresentCommunityButtonClicked] =
    useState(false);
  const [isWhiteSpace, setWhiteSpace] = useState(false);
  const [content, setContent] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({ ...filtersDefaultState });
  const [filterErrMsg, setFilterErrMsg] = useState("");
  const [modalError, setModalError] = useState("");

  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    if (clearSearchHeader) {
      setContent("");
      setSelect(false);
      setWhiteSpace(true);
    }
  }, [clearSearchHeader]);

  useEffect(() => {
    if (content.length > 0) {
      setWhiteSpace(false);
    }
  }, [content]);

  const onCommunitySelection = (id) => {
    if (id === "all") {
      setAllCommunityButtonClicked(!isAllCommunitysButtonClicked);
      setPresentCommunityButtonClicked(false);
    }
    if (id === "in") {
      setPresentCommunityButtonClicked(!isPresentCommunityButtonClicked);
      setAllCommunityButtonClicked(false);
    }
    setButtonSelect(id);
    setSelect(false);
    setWhiteSpace(true);
    console.log(isButtonSelect);
  };

  const homeIconClick = () => {
    setSelect(false);
    setWhiteSpace(true);
    setContent("");
    goBack && goBack();
    filterPosts && filterPosts("");
  };

  const addContactIconClick = () => {
    setSelect(false);
    setWhiteSpace(true);
    //call this in mount of moderator page
    // dispatch(moderatorActions.fetchModeratingCommunityList(""));
  };

  const profileIconClick = () => {
    setSelect(false);
    setWhiteSpace(true);

    //TODO: Revisit this reducer
    dispatch(mapActions.fetchNearByCommunitysData("?mapStatus=IndexNear"));
  };

  const settingsIconClick = () => {
    setSelect(false);
    setWhiteSpace(true);

    //TODO: Revisit this reducer
    dispatch(mapActions.fetchNearByCommunitysData("?mapStatus=IndexNear"));
  };

  const notificationIconClick = (event) => {
    event.preventDefault();
    setSelect(false);
    setWhiteSpace(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelect(false);
    setWhiteSpace(true);
    setAnchorEl(null);
  };

  const selectOptions = () => {
    setShowSearchIcon(!showSearchIcon);
  };

  const onClickFilter = () => {
    setSelect((prevState) => !prevState);
    setFilterErrMsg("");
  };

  const onsearch = (e, scrollType) => {
    setSelect(false);
    setWhiteSpace(true);
    e.preventDefault();
  };

  const updateFilters = (key) => {
    const copiedFilters = { ...filters };
    const prevVal = filters[key];
    copiedFilters[key] = !prevVal;
    const isAtleastOneChecked =
      copiedFilters.ask ||
      copiedFilters.say ||
      copiedFilters.update ||
      copiedFilters.tag;

    if (isAtleastOneChecked) {
      setFilters((prevState) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
      setFilterErrMsg("");
    } else {
      setFilterErrMsg("Atleast one filter should be selected");
    }
  };

  const handleSearchKeyDown = (e, isSuggestion) => {
    if (isSuggestion || e?.key === "Enter") {
      var paramsSetValue = new FormData();
      setSelect(false);
      var searchContent = content;

      if (!content.trim()) {
        setModalError("Please type What you are searching for");
        return;
      }

      paramsSetValue.append("q", searchContent);
      paramsSetValue.append("comun", isButtonSelect);
      if (filters.ask) paramsSetValue.append("chose", "choseask");
      if (filters.say) paramsSetValue.append("chose", "chosesay");
      if (filters.update) paramsSetValue.append("chose", "iupdate");
      if (filters.tag) paramsSetValue.append("chose", "htag");

      console.log("paramsSetValue", paramsSetValue);
      fetchSearchData && fetchSearchData(paramsSetValue);
      setWhiteSpace(!isWhiteSpace);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <React.Fragment>
      <nav
        className={`navbar navbar-expand-md  navbar-light ${styles.navbarexpand}`}
      >
        <div className="container">
          <button
            className={`navbar-toggler ${styles.navbartoggler}`}
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <img src={humberger}></img>
          </button>
          <div>
            <Link to={flow === "beforeSignIn" ? "/" : "/Home"} role="button">
              <img
                style={{
                  cursor: "pointer",
                  objectFit: "cover",
                  width: "90px",
                  height: "48px",
                }}
                src={logo}
                alt="Skopic Logo"
              />
            </Link>
          </div>

          {!hideSearch && (
            <div className={styles.whitespace}>
              <div
                className={`${content.length > 0 ? styles.searchform : ""} ${
                  styles.subwhitespace
                } ${isWhiteSpace ? styles.noshowwhitespace : ""}`}
              >
                <div className={styles.searchbarmargin}>
                  <Input
                    id="search"
                    type="text"
                    placeholder="Search with keywords"
                    className={styles.searchInput}
                    value={content}
                    // onChange={(e) => setContent(e.target.value)}
                    onChange={(e) => {
                      // Checking if input value exceeds the character limit of 280
                      if (e.target.value.length <= 280) {
                        setContent(e.target.value); // Only updating content if within the limit i.e. less than 280 characters
                      }
                    }}
                    onKeyDown={handleSearchKeyDown}
                    startAdornment={
                      <InputAdornment position="start">
                        {/* <IconButton style={{ padding: '4px' }}>
                        <IoLocationOutline fontSize={20} />
                      </IconButton> */}
                        {/* <div className={styles.searchDivider}>|</div> */}
                        <IconButton
                          className={styles.searchIcon}
                          onClick={() => handleSearchKeyDown(null, true)}
                        >
                          <IoIosSearch fontSize={20} />
                        </IconButton>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={onClickFilter}
                          className={styles.plusIcon}
                        >
                          <FaPlus fontSize={20} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {content.length > 0 ? (
                    <p
                      className={`${styles.searchinfocontent} ${
                        isWhiteSpace ? styles.noshosearchinfocontent : ""
                      }`}
                      onClick={() => handleSearchKeyDown(null, true)}
                    >
                      <div className={styles.inputSearchContainer}>
                        <IconButton className={styles.searchIconNew}>
                          <IoIosSearch fontSize={20} />
                        </IconButton>
                        <b className={styles.inputSearchContent}>{content}</b>
                      </div>
                    </p>
                  ) : (
                    ""
                  )}
                  <div
                    className={`${styles.searchdropdown} ${
                      isSelect && styles.searchoptions
                    }`}
                  >
                    <div>
                      <button
                        id="AllCommunitys"
                        className={`${styles.allcommunitys} ${
                          isAllCommunitysButtonClicked
                            ? styles.buttonselectionstyle
                            : ""
                        }`}
                        onClick={() => onCommunitySelection("all")}
                      >
                        All Communities
                      </button>
                      <button
                        id="presentcommunity"
                        className={`${styles.thiscommunity} ${
                          isPresentCommunityButtonClicked
                            ? styles.buttonselectionstyle
                            : ""
                        }`}
                        onClick={() => onCommunitySelection("in")}
                      >
                        This Community
                      </button>
                    </div>
                    <table className={styles.filteroptions}>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              <input
                                type="checkbox"
                                className={styles.filtercheckboxes}
                                name="choseask"
                                id="choseask"
                                checked={filters.ask}
                                onChange={() => updateFilters("ask")}
                              />{" "}
                              <AskSvg />
                            </span>
                          </td>
                          <td>
                            <span>
                              <input
                                type="checkbox"
                                className={styles.filtercheckboxes}
                                name="chosesay"
                                id="chosesay"
                                checked={filters.say}
                                onChange={() => updateFilters("say")}
                              />
                              <SaySvg />
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>
                              <input
                                type="checkbox"
                                className={styles.filtercheckboxes}
                                name="iupdate"
                                id="iupdate"
                                checked={filters.update}
                                onChange={() => updateFilters("update")}
                              />
                              <UpdateSvg />
                            </span>
                          </td>
                          <td>
                            <span>
                              <input
                                type="checkbox"
                                className={styles.filtercheckboxes}
                                name="htag"
                                id="htag"
                                checked={filters.tag}
                                onChange={() => updateFilters("tag")}
                              />
                              <TagSvg />
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {filterErrMsg && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "6px",
                          marginBottom: 0,
                        }}
                      >
                        <Typography
                          style={{ marginTop: 0, marginBlockStart: 0 }}
                          color="error"
                          variant="caption"
                        >
                          {filterErrMsg}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {!isMenuActionDisabled && (
            <div
              className={`collapse navbar-collapse ${styles.collapse}`}
              id="collapsibleNavbar"
              style={{ flexDirection: "row-reverse" }}
            >
              <ul className={`navbar-nav ${styles.navbarnav}`}>
                <li className="nav-item">
                  <div className={styles.iconadjust}>
                    <Link
                      className={`${styles.iconbuttons} ${
                        currentPage === PageEnum.Profile
                          ? styles.iconbuttonsactive
                          : ""
                      }`}
                      onClick={profileIconClick}
                      to="/activity"
                      role="button"
                      style={{ padding: 0 }}
                    >
                      {userDetails &&
                      Object.keys(userDetails.userData || {}).length > 0 ? (
                        <img
                          src={`${profileImagePath}${userDetails?.userData?.uimage}`}
                          className={styles.headerProfileImg}
                          alt="User Profile"
                        />
                      ) : (
                        <img
                          src={profileimageicon}
                          className={styles.headerProfileImg}
                          alt="Default Profile Icon"
                        />
                      )}

                      <p>Me</p>
                    </Link>
                  </div>
                </li>
                <li className="nav-item">
                  <div className={styles.iconadjust}>
                    <Link
                      className={`${styles.iconbuttons} ${
                        currentPage === PageEnum.Home
                          ? styles.iconbuttonsactive
                          : ""
                      }`}
                      role="button"
                      to="/Home"
                      onClick={homeIconClick}
                    >
                      <img
                        src={homeImg}
                        className={styles.headerHomeImg}
                        alt="home"
                      />
                      <p>Home</p>
                    </Link>
                  </div>
                </li>
                {userDetails && Object.keys(userDetails).length !== 0 ? (
                  userDetails.userData.isModerator === "1" ||
                  userDetails.userData.isAdmin === "1" ? (
                    <li className="nav-item">
                      <div className={styles.iconadjust}>
                        <Link
                          role="button"
                          className={`${styles.iconbuttons} ${
                            currentPage === PageEnum.Moderator
                              ? styles.iconbuttonsactive
                              : ""
                          }`}
                          onClick={addContactIconClick}
                          to="/Moderator"
                        >
                          <img
                            src={crownImg}
                            className={styles.headerImg}
                            alt="moderator"
                          />
                          <p>Moderator</p>
                        </Link>
                      </div>
                    </li>
                  ) : null
                ) : null}
                <li className="nav-item">
                  <>
                    <div className={styles.iconadjust}>
                      <Link
                        className={`${styles.iconbuttons} ${
                          currentPage === PageEnum.Notifications
                            ? styles.iconbuttonsactive
                            : ""
                        }`}
                        onClick={notificationIconClick}
                        to="/"
                        role="button"
                      >
                        <img
                          src={bellImg}
                          className={styles.headerImg}
                          alt="Notification"
                        />
                        <p>Notifications</p>
                      </Link>
                    </div>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      sx={{
                        "& .MuiPopover-paper": {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <div className={styles.notificationWrapper}>
                        <Notifications />
                      </div>
                    </Popover>
                  </>
                </li>
                <li className="nav-item">
                  <div className={styles.iconadjust}>
                    <Link
                      className={`${styles.iconbuttons} ${
                        currentPage === PageEnum.Settings
                          ? styles.iconbuttonsactive
                          : ""
                      }`}
                      onClick={settingsIconClick}
                      to="/Settings"
                      role="button"
                    >
                      <img
                        src={settingImg}
                        className={styles.headerImg}
                        alt="settings"
                      />
                      <p>Settings</p>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>
      {modalError && (
        <ConfirmationDialog
          open={true}
          handleClose={() => setModalError("")}
          type={"warning"}
          title={modalError}
          acceptLabel={"Ok"}
          onAccept={() => setModalError("")}
        />
      )}
    </React.Fragment>
  );
};

export default Header;
