import React from 'react';
import Country from './country';
import Flags from './resources/flags';
import styles from './Country.module.css';

export const getPickerData = () => Country.getAll().map((country, index) => ({
  key: index,
  image: Flags.get(country.iso2),
  label: country.name,
  dialCode: `+${country.dialCode}`,
  iso2: country.iso2,
  countryCode: country.countryCode,
}));

const getStyledCountry = (item, noLang, handleCountrySelect) => {
  const { iso2, image, dialCode } = item;
  let { label } = item;
  const pos = label ? label.indexOf('(') : -1;
  label = noLang && pos !== -1 ? label.slice(0, pos) : label;
  return (
    <div key={iso2} className={styles.countryRow} onClick={() => handleCountrySelect(item)}>
      <div className={styles.imageWrapper}>
        <img src={image} className={styles.imageStyle} />
      </div>
      <div className={styles.dialCodeContainer}>
        <span className={styles.dialText}>{dialCode}</span>
      </div>
      <div className={styles.isoContainer}>
        <span className={styles.dialText}>{iso2 ? iso2.toUpperCase() : ''}</span>
      </div>
      <div className={styles.lableContainer}>
        <span className={styles.dialText}>{label}</span>
      </div>
    </div>
  );
};

const renderCountry = (item, { handleCountrySelect, noLang }) => (
  <div key={item.iso2}>
    {getStyledCountry(item, noLang, handleCountrySelect)}
  </div>
);

export default function CountrySelector(props) {
  const { onChange, noLang, countryOptionsStyle = {}, closeTooltip = () => { } } = props;
  const pickerData = getPickerData();
  const handleCountrySelect = selectedCountry => {
    onChange && onChange(selectedCountry);
    closeTooltip && closeTooltip();
  }
  const data = { handleCountrySelect, noLang };
  return (
    <div className={[styles.optionsContainer, countryOptionsStyle].join(' ')}>
      {pickerData.map(item => renderCountry(item, data))}
    </div>
  );
}
