import React from "react";
import { useSelector } from "react-redux";
import style from "src/Assets/css/home/TrendingTagResults.module.css";
import Post from "../home/post";
import CircularProgress from "@material-ui/core/CircularProgress";
import backwardarrow from "src/Assets/images/backwardarrow.png";

const TredingTagsReuslts = () => {
  const TredingTagResults = useSelector(
    (state) => state.TagReducer.tredingTagsResults
  );
  return (
    <>
      {TredingTagResults && Object.keys(TredingTagResults).length > 0 ? (
        <div className={style.TagResults}>
          <div className={style.TagResultContainer}>
            <a href="/Home">
              <img src={backwardarrow} />
            </a>
            <span className={style.trendingTagHeading}>Trending TAGs</span>
          </div>
          <Post listdata={TredingTagResults} data={TredingTagResults} />
        </div>
      ) : (
        <p>no records found</p>
      )}
    </>
  );
};

export default TredingTagsReuslts;
