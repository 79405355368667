import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import axios from "axios";
import Popup from "reactjs-popup";
import gmail from "src/Assets/images/gmail.svg";
import facebook from "src/Assets/images/fb.svg";
import Api from "src/network";
import "src/Assets/css/Moderator/InviteMembers.css";
import { emailRegex } from "src/Constants/regex";
import MessageModal from "src/Utils/messageModal";

const ModalType = {
  Message: "MESSAGE",
};

function Invitemembers(props) {
  const baseUrl = process.env.REACT_APP_SERVICES_API;
  const { tenantId, tenantName } = props || {};
  // const moderatingCommunitysData = useSelector(
  //   (state) => state.ModeratorReducer.moderatingCommunitysData
  // );
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const [mail, setMail] = useState("");
  const [subject, setSubject] = useState("");
  const [textmsg, setTextmsg] = useState("");
  const [msg, setMsg] = useState("");
  // const [closeInvites, setCloseInvites] = useState(null);
  // const [messageShow, setMessageShow] = useState(false);
  const [email, setemail] = useState([]);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [modalType, setModalType] = useState(null);

  const emailChange = (e) => {
    setMail(e.target.value);
    setIsEmailInvalid(false);
  };

  const handleKeyDown = (evt) => {
    if ([" ", ";", ","].includes(evt.key)) {
      evt.preventDefault();

      const newEmail = mail.trim();

      if (newEmail && emailRegex.test(newEmail)) {
        setemail((email) => [...email, newEmail]);
        setMail("");
      } else {
        setIsEmailInvalid(true);
      }
    }
  };

  useEffect(() => {
    const { displayName } = userDetails?.userData || {};
    setSubject(
      `Invitation to join ${tenantName} via Skopic`
    );

    setTextmsg(` Hi,
     I request you to join  ${tenantName}  on Skopic. It is quick and easy to Sign Up.
         Click the link below to join ${tenantName}.
         ${baseUrl}user/communityurl.html?tid=${tenantId}
        Thank you
     ${displayName}`);
  }, [userDetails, tenantId, tenantName]);

  // const closeHandler = () => {
  //   setMail("");
  //   setemail([]);
  // };

  const validateForm = () => {
    if (mail && mail.trim()) { //check for last mail, which present in input field
      if (emailRegex.test(mail)) { //if mail is valid
        return true;
      } else {
        setIsEmailInvalid(true);
        return false;
      }
    } else {
      return email.length > 0;
    }
  };

  const onInviteHandler = (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    let allEmails = [...email];
    if (mail && mail.trim()) {
      allEmails = [...allEmails, mail];
    }

    const emailsParam = allEmails.join(",");
    Api.inviteFriend(emailsParam, textmsg, subject)
      .then((res) => {
        setMsg(res.data);
        setMail("");
        setemail([]);
        setModalType(ModalType.Message);
      })
      .catch(e => console.error(e));
  };

  const handleDelete = (toBeRemoved) => {
    setemail(email.filter((email) => email !== toBeRemoved));
  };

  const isSumbitHandler = () => {
    if (emailRegex.test(mail) || email.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  // const closeInviteHandler = () => {
  //     setCloseInvites(props.onHide)
  //     setMessageShow(true)
  // }

  const generateGoogleAuthUrl = () => {
    const landingPageUrl = process.env.REACT_APP_LANDING_PAGE;
    const clientId =
      "1096351929813-b3sqsgi9t95dfbr7jhsruvsfvh8q6iac.apps.googleusercontent.com";
    const redirectUri = `${landingPageUrl}home`;
    const scope = "https://www.googleapis.com/auth/contacts.readonly";
    const accessType = "offline";
    const includeGrantedScopes = true;
    const responseType = "code";
    const state = "state_parameter_passthrough_value";

    const authUrl =
      `https://accounts.google.com/o/oauth2/v2/auth?` +
      `scope=${encodeURIComponent(scope)}&` +
      `access_type=${encodeURIComponent(accessType)}&` +
      `include_granted_scopes=${includeGrantedScopes}&` +
      `response_type=${encodeURIComponent(responseType)}&` +
      `state=${encodeURIComponent(state)}&` +
      `redirect_uri=${encodeURIComponent(redirectUri)}&` +
      `client_id=${encodeURIComponent(clientId)}`;

    return authUrl;
  };

  const closeModal = () => {
    setModalType(null);
    setMsg("");
  };

  // Example usage:
  const googleAuthUrl = generateGoogleAuthUrl();
  // console.log(googleAuthUrl);
  const isEnabaled = isSumbitHandler();

  return (
    <>
      <div className="background-for-whole">
        <form onSubmit={onInviteHandler}>
          <div className="Invitaion InviteStyles">
            <div className="InvitationEmail">
              Email:
              <input
                type="text"
                value={mail}
                onChange={emailChange}
                onKeyDown={handleKeyDown}
                maxLength="50"
              />
              {isEmailInvalid && <p style={{ color: "red", fontSize: 11, padding: 0, margin: 0 }}>Please enter a valid Email</p>}
              {email.map((mail) => (
                <div key={mail} className="multipleEmail">
                  {" "}
                  {mail}{" "}
                  <button
                    type="button"
                    className="button"
                    onClick={() => handleDelete(mail)}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
            <div className="InvitationSubject">
              Subject:
              <input type="text" value={subject} />
            </div>
            <div className="InvitationTextaera">
              <label>Message:</label>
              <div>
                <textarea
                  style={{ scrollbarWidth: "none" }}
                  rows="6"
                  cols="50"
                  defaultValue={textmsg}
                />
              </div>
            </div>
            <button
              type="submit"
              onClick={onInviteHandler}
              className="Invite"
              style={{ margin: "0 auto" }}
              disabled={!isEnabaled}
            >
              Invite
            </button>

            <div className="or-container">
              <hr />
              <p>or</p>
              <hr />
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="InviteOptionsContainer_align">
                <button
                  className="GmailContacts_align"
                  onClick={() => (window.location.href = googleAuthUrl)}
                >
                  <img src={gmail} alt="gmailIcon" />
                  <span> Invite Gmail contacts</span>
                </button>

                <div style={{ padding: 16 }}></div>

                {/* <button className="FacebookContacts_align">
                  <img src={facebook} alt="facebookIcon" />
                  <span>Share on Facebook</span>
                </button> */}
              </div>
            </div>
            {/* <button type="submit" className="Invite"  disabled={!isEnabaled}>Invite</button> */}
            {/* <button type="submit" onClick={''} className="Invite" disabled={!isEnabaled}>Invite via Gmail</button> */}
          </div>
        </form>

        {modalType === ModalType.Message && (
          <MessageModal
            show={modalType === ModalType.Message}
            onHide={closeModal}
            message={msg}
          />
        )}
      </div>
    </>
  );
}

export default Invitemembers;
