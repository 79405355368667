import React, { useState } from "react";
import style from "src/Assets/css/home/Homepagerightsidebar.module.css";
// import ContributorImage from "../assests/Images/ContributorImage.png"
import Profile from "src/Assets/images/ProfileImageIcon.png";
import contributerstick from "src/Assets/images/contributerstick.svg";
import {
  Avatar,
  Button,
  CircularProgress,
  StylesProvider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import ArrowRightAltOutlinedIcon from "@material-ui/icons/ArrowRightAltOutlined";
import UpdateTagPostDataModal from "../../../Modules/Reusuablecomponents/UpdateTagPostDataModal";
import Contributors from "./Contributors";
import styles from "src/Assets/css/home/post.module.css";
import feedstyles from "src/Assets/css/home/feedpost.module.css";
import InviteFollowers from "./InviteFollowers";
import AvatarBadge from "src/Components/AvatarBadge";

function RightSideBar(props) {
  const {
    contributorsData,
    trendingTags = [],
    trendingAsks = [],
    setShowData,
    showModal,
    modalType,
    // filterType,
    filterPosts,
    fetchSelectedTagDetails,
    onUpdateSelection,
    hideUpdateModal,
    fetchFeedData,
    showData,
    fetchContributors,
    pageType,
  } = props;
  // console.log('trendingTags RightSideBar', trendingTags);
  // const contributorsData = useSelector(
  //   (state) => state.feedReducer.contributorsData
  // );
  // const isLoaded = useSelector((state) => state.feedReducer.isLoaded);

  // const dispatch = useDispatch();
  // const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  // const [modalShow, setModalShow] = React.useState(false);
  // const [isModalType, setModalType] = React.useState(null);
  const [contributorBio, setContributorBio] = React.useState("");
  const [showBio, setShowBio] = React.useState(false);
  const [showInviteModal, setShowInviteModal] = React.useState(false);
  const [messageId, setMessageId] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [selectedTagId, setSelectedTagId] = useState(null);

  const onUpdate = (modalType) => {
    onUpdateSelection(modalType);
  };

  const moreTrendingTags = () => {
    setShowData("tag");
    setSelectedTagId(null);
  };

  const moreTrendingAsks = () => {
    setShowData("ask");
  };

  const TagDetail = (tagId) => {
    // Toggle the selected tag
    if (selectedTagId === tagId) {
      // If it's already selected (blue), reset the color to black by setting id to null
      setSelectedTagId(null);
      setShowData("feed");
    } else {
      // If it's black (unselected) or different, set the selected tag id and change color
      setSelectedTagId(tagId);

      // Call the function when changing to blue
      fetchSelectedTagDetails(tagId);
      setShowData("singleTrendingTag");
    }
    // setSelectedTagId(tagId);
    // fetchSelectedTagDetails(tagId);
    // setShowData("singleTrendingTag");
    // dispatch(TagDisplay.fecthDisplayData(tagValue));
  };

  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  const handleClickEvent = (userid, showBio) => {
    const data = contributorsData?.contribut?.find((data) => {
      return data.id === userid;
    });
    setContributorBio(data);
    setShowBio(showBio);
  };

  const showInviteModalOnPrivateTagPost = (messageId, tenantId) => {
    setShowInviteModal(true);
    setMessageId(messageId);
    setTenantId(tenantId);
  };

  const onHideInviteModal = () => {
    setShowInviteModal(false);
    setMessageId("");
    setTenantId("");
  };

  return (
    <React.Fragment>
      <div className={style.rightSidebar}>
        <span className={style.contributertick}>
          <img src={contributerstick} alt="tick" />
          Contributors
        </span>

        <div>
          <Contributors
            handleClickEvent={handleClickEvent}
            contributorsData={contributorsData}
            pageType={pageType}
          />
          {showBio && (
            <div className={style.profilehover}>
              <div className={feedstyles.profilehoverimageandcount}>
                <AvatarBadge
                  src={`${profileImagePath}${contributorBio?.uImage}`}
                  className={styles.avatar}
                  isPrimaryModerator={contributorBio.isPM == 1}
                  isModerator={
                    contributorBio.isModerator == 1 && contributorBio.isPM == 0
                  }
                />
                <div style={{ marginLeft: "10px" }}>
                  <p className={feedstyles.profilehovername}>
                    {contributorBio?.displayName}
                  </p>
                  <p className={feedstyles.profilecount}>
                    {/* ASK {contributorBio?.askcount} |  */}
                    SAY {contributorBio?.say} | UPDATE {contributorBio?.iUpdate}{" "}
                    | #SAY {contributorBio?.hashSay}
                  </p>
                </div>
              </div>
              <p className={feedstyles.profilehovercontent}>
                {contributorBio?.shortBio}
              </p>
            </div>
          )}
        </div>

        {/* <div id="demo" className={`carousel slide ${style.Contributers}`} data-ride="carousel" data-interval="false">

                    <div className="container carousel-inner">
                        <div className="carousel-item active">
                            {
                                (isLoaded)
                                    ?
                                    (contributorsData && Object.keys(contributorsData).length !== 0)
                                        ?
                                        contributorsData.contribut.slice(0,6).map((result) => (
                                            <div className={`row ${style.avatarrow}`}>
                                                <Avatar className={style.contributersavatar} src={`${profileImagePath}${result.uImage}`}></Avatar>
                                            </div>
                                        ))
                                        :
                                        <div>
                                            <p>There is no Contributors</p>

                                            <div className={`row ${style.avatarrow}`}>
                                                <Avatar alt="Contibutors" src={`${profileImagePath}`} />
                                                <Avatar alt="Contibutors" src={`${profileImagePath}`} />
                                                <Avatar alt="Contibutors" src={`${profileImagePath}`} />

                                            </div>
                                            <div className={`row ${style.avatarrow}`}>
                                                <Avatar alt="Contibutors" src={`${profileImagePath}`} />
                                                <Avatar alt="Contibutors" src={`${profileImagePath}`} />
                                                <Avatar alt="Contibutors" src={`${profileImagePath}`} />

                                            </div>

                                        </div>
                                    :
                                    <CircularProgress />

                            }

                        </div>





                    </div>

                    <a className={`carousel-control-prev`} href="#demo" role="button" data-slide="prev">
                        <ArrowBackIosOutlinedIcon className={style.Arrow} />
                    </a>
                    <a className={`carousel-control-next`} href="#demo" data-slide="next">
                        <ArrowForwardIosOutlinedIcon className={style.Arrow} />
                    </a>
                </div> */}

        <div
          className={`${style.ShareUpdate} ${
            pageType === "homePage" ? style.homePageRightSideBar : ""
          }`}
          onClick={() => onUpdate("UPDATE")}
        >
          <h5 className={`h17 bold dark-gray`}>Share UPDATE</h5>

          <a className={style.shareupdatelink}>
            <ArrowRightAltOutlinedIcon />
          </a>
        </div>

        <UpdateTagPostDataModal
          show={showModal}
          onHide={hideUpdateModal}
          modalShow={showModal}
          setModalShow={hideUpdateModal}
          isModalType={modalType}
          // filterType={filterType}
          filterPosts={filterPosts}
          fetchFeedData={fetchFeedData}
          showInviteModalOnPrivateTagPost={showInviteModalOnPrivateTagPost}
          fetchContributors={fetchContributors}
        />

        {showInviteModal && (
          <InviteFollowers
            key={messageId}
            show={showInviteModal}
            onHide={() => onHideInviteModal()}
            tenantid={tenantId}
            msgid={messageId}
            backdrop="static"
            keyboard={false}
            hideCloseButton={true}
          />
        )}

        <div
          className={`${style.CreateTag} ${
            pageType === "homePage" ? style.homePageRightSideBar : ""
          }`}
          onClick={() => onUpdate("TAG")}
        >
          <h5 className={`h17 bold dark-gray`}>Create TAG</h5>
          <a to="/" className={style.shareupdatelink}>
            <ArrowRightAltOutlinedIcon />
          </a>
        </div>
        <div
          // className={style.Trending}
          className={`${style.Trending} ${
            pageType === "homePage" ? style.homePageRightSideBar : ""
          }`}
        >
          <h2>See what's trending</h2>
          <div className={style.tagSection}>
            <h6>TAGs</h6>
            {trendingTags && Object.keys(trendingTags).length !== 0 ? (
              <div>
                {trendingTags.map((trendinglist, i) => (
                  <div className={style.trendingTagNameContainer}>
                    <p
                      onClick={() => TagDetail(trendinglist.id)}
                      key={trendinglist.id}
                      className={
                        trendinglist.id === selectedTagId && showData !== "feed"
                          ? style.tagNameClicked
                          : style.tagName
                      }
                    >
                      {" "}
                      {trendinglist.hash_tag_name}{" "}
                    </p>
                    {trendinglist.Keyword_ID === "X" && (
                      <div className={style.trendingTagPrivateStatus}>P</div>
                    )}
                  </div>
                ))}
                <a className={style.Seemore} onClick={moreTrendingTags}>
                  See All{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.093"
                    height="9.64"
                    viewBox="0 0 6.093 9.64"
                  >
                    <g
                      id="Group_2347"
                      data-name="Group 2347"
                      transform="translate(1.273 8.367) rotate(-90)"
                    >
                      <line
                        id="Line_43"
                        data-name="Line 43"
                        x2="3.547"
                        y2="3.547"
                        fill="none"
                        stroke="#373334"
                        strokeLinecap="round"
                        strokeWidth="1.8"
                      />
                      <line
                        id="Line_44"
                        data-name="Line 44"
                        x1="3.547"
                        y2="3.547"
                        transform="translate(3.547)"
                        fill="none"
                        stroke="#373334"
                        strokeLinecap="round"
                        strokeWidth="1.8"
                      />
                    </g>
                  </svg>
                </a>
              </div>
            ) : (
              <p className={style.message}>No Tags are Trending</p>
            )}
          </div>

          <div className={style.asksection}>
            <h6>ASKs</h6>
            {trendingAsks && Object.keys(trendingAsks).length !== 0 ? (
              trendingAsks?.map((trendinglist) => (
                <ul
                  className={style.asks}
                  key={`trendingask-key-${trendinglist?.id}`}
                  onClick={moreTrendingAsks}
                >
                  <li>
                    <AvatarBadge
                      variant="square"
                      src={`${profileImagePath}${trendinglist.uimage}`}
                      className={style.askavatar}
                      isPrimaryModerator={trendinglist.isPM == 1}
                      isModerator={
                        trendinglist.isModerator == 1 && trendinglist.isPM == 0
                      }
                    />
                    <div className={style.askprofilecontnet}>
                      <p className={style.askprofilecontnetName}>
                        {trendinglist.displayName}
                      </p>
                      <p className={style.askprofilecontnetMessage}>
                        {trendinglist.Message}
                      </p>
                    </div>
                  </li>
                </ul>
              ))
            ) : (
              <p>No Data</p>
            )}
            <a className={style.Seemore} onClick={moreTrendingAsks}>
              See All{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6.093"
                height="9.64"
                viewBox="0 0 6.093 9.64"
              >
                <g
                  id="Group_2347"
                  data-name="Group 2347"
                  transform="translate(1.273 8.367) rotate(-90)"
                >
                  <line
                    id="Line_43"
                    data-name="Line 43"
                    x2="3.547"
                    y2="3.547"
                    fill="none"
                    stroke="#373334"
                    strokeLinecap="round"
                    strokeWidth="1.8"
                  />
                  <line
                    id="Line_44"
                    data-name="Line 44"
                    x1="3.547"
                    y2="3.547"
                    transform="translate(3.547)"
                    fill="none"
                    stroke="#373334"
                    strokeLinecap="round"
                    strokeWidth="1.8"
                  />
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RightSideBar;
