import axios from "axios";
import * as Cookies from "js-cookie";
//Action imports
import * as TagActions from "../../actions/TrendingTags/TagActions";
import * as allActions from "../../actions/actions.constants";

const TrendingTagService = (store) => (next) => (action) => {
  const url = process.env.REACT_APP_SERVICES_API;
  next(action);
  switch (action.type) {
    case allActions.FETCH_TAG_DATA:
      axios
        .request({
          url: `${url}/jsonmessage/trending-items.html?task=tags&data=all`,
          method: "post",
          headers: {},
          withCredentials: true,
        })
        .then((res) => {
          console.log(res.data.followmsgList);
          //  receiveTagData
          next(TagActions.receiveTagData(res.data.trendingTags));
        });
      break;

    case allActions.FETCH_ASKS_DATA:
      axios
        .request({
          url: `${url}/jsonmessage/trending-items.html?task=asks&data=all`,
          method: "post",
          headers: {},
          withCredentials: true,
        })
        .then((res) => {
          console.log(res.data.followmsgList);
          //  receiveAsksData
          next(TagActions.receiveAsksData(res.data.trendingAsks));
        });
      break;

    default:
      break;
  }
};
export default TrendingTagService;
