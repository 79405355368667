import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Avatar } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import ToggleSwitch from "../../../Modules/Reusuablecomponents/ToggleSwitch";
import * as feedactions from "../../../store/actions/feedactions/feedActionCreator";
import AsksModal from "../../../Modules/home/AsksModal.jsx";
import PostingOptionsModal from "../../../Modules/Reusuablecomponents/PostingOptionsModal";
import LocationTag from "../../../Modules//Reusuablecomponents/LocationTag.jsx";
import aboutASK from "src/Assets/images/aboutask.svg";
import styles from "src/Assets/css/home/Feed.module.css";
import AvatarBadge from "src/Components/AvatarBadge";
import { ignoreSpacesAndUrlsFromMsg } from "src/Utils/commonUtils";

const AskSayPostDataModal = (props) => {
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  // const usernameData = useSelector((state) => state.followReducer.usernameData)

  const AskCanAns = useSelector((state) => state.followReducer.sayData);
  const saysCanAns = useSelector((state) => state.followReducer.askData);

  const [askorSayText, setAskorSayText] = useState("");
  const [SaysShow, setSaysShow] = useState(false);
  const [isPostOption, setPostOption] = useState("");
  const [isAdditionalCount, setAdditionalCount] = useState(false);
  const [isValidCount, setValidCount] = useState(true);
  const [isCharcount, setCharCount] = useState(0);
  const [setLocationSelect, isLocationSelect] = useState("");
  const [isLat, setLat] = useState("");
  const [isLng, setLng] = useState("");
  const [postingOption, setPostingOption] = useState("");
  const [isNewHash, setNewHashMessage] = useState("");
  const [isHide, setIsHide] = useState(false);

  // const [isUserNameData, setUserNameData] = useState([])
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.modalShow) {
      setAskorSayText("");
      setPostOption("");
      setAdditionalCount("");
      setCharCount(0);
      setNewHashMessage("");
    }
    // if (AskCanAns && Object.keys(AskCanAns).length > 0 || saysCanAns && Object.keys(saysCanAns).length > 0) {
    //     if(AskCanAns.messageList||saysCanAns.messageList){
    //         setSaysShow(true)
    //     }
    // }
  }, [props.modalShow]);

  useEffect(() => {
    if (AskCanAns && Object.keys(AskCanAns).length > 0) {
      if (AskCanAns.status === "New Hash") {
        setNewHashMessage("You can't create new #TAG here");
        props.setModalShow(true);
      } else {
        props.setModalShow(false);
        dispatch(feedactions.fetchFeedData("?startlimit=0"));
      }
      if (AskCanAns.messageList) {
        setSaysShow(true);
      }
    }
  }, [AskCanAns]);

  useEffect(() => {
    if (saysCanAns && Object.keys(saysCanAns).length > 0) {
      if (saysCanAns.status === "New Hash") {
        setNewHashMessage("You can't create new #TAG here");
        props.setModalShow(true);
      } else {
        props.setModalShow(false);
        dispatch(feedactions.fetchFeedData("?startlimit=0"));
      }
      if (saysCanAns.messageList) {
        if (saysCanAns.messageList.length > 0) {
          setSaysShow(true);
        }
      }
    }
  }, [saysCanAns]);

  var inputDataLen;

  const ignoreSpaceandUrls = (mstrData, txtId) => {
    let inputData = mstrData;
    inputDataLen = inputData.length;
    let inputText = document.getElementById(txtId).value;
    inputText = inputText.replace(/\n/g, " ");
    let strArray = inputText.split(" ");
    let validlettercount = 0;
    let inputletters;
    for (inputletters = 0; inputletters < strArray.length; inputletters++) {
      if (
        strArray[inputletters].indexOf("http://") == -1 &&
        strArray[inputletters].indexOf("www.") == -1 &&
        strArray[inputletters].indexOf("https://") == -1 &&
        strArray[inputletters].indexOf("HTTP://") == -1 &&
        strArray[inputletters].indexOf("WWW.") == -1 &&
        strArray[inputletters].indexOf("HTTPS://") == -1
      ) {
        validlettercount = validlettercount + strArray[inputletters].length;
      } else if (
        strArray[inputletters].indexOf("http://") &&
        strArray[inputletters].indexOf("www.") &&
        strArray[inputletters].indexOf("https://") &&
        strArray[inputletters].indexOf("HTTP://") &&
        strArray[inputletters].indexOf("WWW.") &&
        strArray[inputletters].indexOf("HTTPS://")
      ) {
        validlettercount = validlettercount + strArray[inputletters].length;
      }
    }
    inputDataLen = validlettercount;
  };

  const textChangeHandler = (e, id) => {
    // if(usernameData)
    // {
    //     // setUserNameData(usernameData)
    //     console.log(isUserNameData)

    // }

    let inputValue = id;
    let letterCounter;
    let letterCountAdd;

    var TotalLetters;
    if (isAdditionalCount) {
      letterCounter = "letterCounter";
      letterCountAdd = "letterCounterAdding";
      TotalLetters = 280;
    } else {
      letterCounter = "letterCounter140";
      letterCountAdd = "letterCounterAdding140";
      TotalLetters = 280;
    }

    let letters = document.getElementById(inputValue).value;

    setAskorSayText(e.target.value);
    // ignoreSpaceandUrls(letters, inputValue);
    const { msgLength } = ignoreSpacesAndUrlsFromMsg(letters);

    const chCount = msgLength;
    setCharCount(chCount);
    if (document.getElementById(letterCounter && letterCountAdd) !== null) {
      if (chCount > TotalLetters) {
        document.getElementById(letterCountAdd).style.color = "red";
        document.getElementById("postSubmitButton").disabled = true;
        document.getElementById("postSubmitButton").style.opacity = "0.4";
        setValidCount(false);
      } else {
        document.getElementById(letterCountAdd).style.color = "black";
        document.getElementById("postSubmitButton").disabled = false;
        document.getElementById("postSubmitButton").style.opacity = "2";
        setValidCount(true);
      }
      // if (document.getElementById("postSubmitButton").disabled = true) {
      //     setValidCount(false)

      // }
      // else {
      //     setValidCount(true)

      // }
    }
  };

  const postAskHandler = (e) => {
    e.preventDefault();
    // let formData = new FormData();
    let lnglat;
    let TotalLetters = 280;
    if (isPostOption === "ASK") {
      if ((isLat !== "") & (isLng !== "")) {
        lnglat = isLat + "," + isLng;
      } else {
        lnglat = "";
      }
      if (isCharcount && isCharcount <= TotalLetters && isValidCount) {
        dispatch(
          feedactions.fetchSayAskData(
            `Message=${askorSayText}&userLoc=&msgLoc=${lnglat}&locName=${setLocationSelect}&posttype=${postingOption}`
          )
        );
        setLat("");
        setLng("");
        isLocationSelect("");

        setAskorSayText("");
      }
    } else {
      let saylatlng;
      if ((isLat !== "") & (isLng !== "")) {
        saylatlng = isLat + "," + isLng;
      } else {
        saylatlng = "";
      }
      if (isCharcount && isCharcount <= TotalLetters && isValidCount) {
        dispatch(
          feedactions.fetchAskSayData(
            `Message=${askorSayText}&userLoc=&msgLoc=${saylatlng}&locName=${setLocationSelect}&onlyASK=YES&posttype=${postingOption}`
          )
        );
        // formData.append("onlyASK", "YES");
        setLat("");
        setLng("");
        isLocationSelect("");

        setAskorSayText("");
      }
    }

    // setLat("")
    // setLng("")
    // isLocationSelect("")

    // setAskorSayText("")

    // setPostOption("")

    // return () => clearTimeout(timer);
  };

  const isSubmitHandler = () => {
    if (isPostOption === "ASK" || isPostOption === "SAY") {
      if (askorSayText === "") {
        return false;
      } else {
        return true;
      }
    }
  };
  const isEnabled = isSubmitHandler();
  const onAddSelection = () => {
    if (isCharcount <= 280) {
      document.getElementById("postSubmitButton").disabled = false;
      document.getElementById("postSubmitButton").style.opacity = "2";
    }
    setAdditionalCount(true);
    setValidCount(true);
  };
  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  const handleClose = () => {
    isLocationSelect("");
  };


  return (
    <React.Fragment>
      <Modal
        {...props}
        size="md"
        className={isHide ? styles.modalHide : styles.modal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className={styles.modalbgcolor}>
          <Modal.Header onClick={handleClose} closeButton>
            {isPostOption === "ASK" ? (
              <h6 className={styles.asksayselection}>
                {isPostOption !== "ASK" && isPostOption !== "SAY"
                  ? "What would you like to ASK?"
                  : ""}
              </h6>
            ) : isPostOption === "SAY" ? (
              <h6 className={styles.asksayselection}>
                {isPostOption !== "ASK" && isPostOption !== "SAY"
                  ? "What would you like to SAY?"
                  : ""}
              </h6>
            ) : (
              <h6 className={styles.asksayselection}>
                {isPostOption !== "ASK" && isPostOption !== "SAY"
                  ? "Do you want to ASK or SAY?"
                  : ""}
              </h6>
            )}
          </Modal.Header>

          <ToggleSwitch
            leftLabel="ASK"
            rightLabel="SAY"
            setPostOption={setPostOption}
          />

          <Modal.Body className={styles.modalbody}>
            {userDetails && Object.keys(userDetails).length !== 0 ? (
              <div className={styles.profilenameandimage}>
                <div>
                  {/* <Avatar
                    src={`${profileImagePath}${userDetails.userData.uimage}`}
                    className={styles.profileavatar}
                  /> */}
                  <AvatarBadge
                    src={`${profileImagePath}${userDetails.userData.uimage}`}
                    className={styles.profileavatar}
                    alt={userDetails?.userData?.displayName}
                    isPrimaryModerator={userDetails?.userData?.isPM == 1}
                    isModerator={userDetails?.userData?.isModerator == 1 && userDetails?.userData?.isPM == 0}
                  />
                </div>
                <div className={styles.profilename}>
                  <p>{props?.userData?.userData?.shortDisplayName}</p>
                  <div className={styles.postdropdown}>
                    <PostingOptionsModal
                      tenantName={props?.userData?.userData?.tenantName}
                      pimage={props?.userData?.userData?.tenantLogoThumbnail}
                      setPostingOption={setPostingOption}
                      isPostOption={isPostOption}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <textarea
              placeholder={`${isPostOption === "ASK"
                ? "What would you like to ASK?"
                : isPostOption === "SAY"
                  ? "What would you like to SAY"
                  : "What's on your mind?"
                }`}
              className={styles.asksaytextarea}
              value={askorSayText}
              id="post"
              onChange={(e) => textChangeHandler(e, "post")}
              disabled={isPostOption !== "ASK" && isPostOption !== "SAY"}
            />

            <span className={styles.hashValidationMessage}>{isNewHash}</span>

            <div className={styles.locationtagandcharcount}>
              <div className={styles.locationtagicon}>
                <a>
                  {userDetails && Object.keys(userDetails).length !== 0 ? (
                    <>
                      <LocationTag
                        isPostings={true}
                        isLocationSelect={isLocationSelect}
                        setLat={setLat}
                        setLng={setLng}
                        id={userDetails.userData.User_ID}
                        setIsHide={setIsHide}
                        setLocationSelect={setLocationSelect}
                      />
                      <p>{userDetails.User_ID}</p>
                    </>
                  ) : null}
                </a>
              </div>
              <div>
                {isAdditionalCount ? (
                  <>
                    <label
                      id={`letterCounterAdding`}
                      className={`${isCharcount > 280 ? styles.overCount : null
                        }`}
                    >
                      {isCharcount}
                    </label>
                    <label id={`letterCounter`}>/280</label>
                  </>
                ) : (
                  <span className={styles.initialcount}>
                    <label id={`letterCounterAdding140`}>{isCharcount}</label>
                    <label id={`letterCounter140`}>/280 </label>
                  </span>
                )}
                {/* 
                  {!isAdditionalCount ? (
                    <AddCircleOutlineIcon
                      onClick={onAddSelection}
                      className={styles.additionalcountadd}
                    />
                  ) : null} */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className={styles.modalfooter}>
            <Button
              id="postSubmitButton"
              onClick={postAskHandler}
              disabled={!isEnabled}
              className={`${styles.PostSubmit} ${isPostOption === "ASK"
                ? styles.askpost
                : isPostOption === "SAY"
                  ? styles.saypost
                  : null
                } ${!isEnabled ? styles.postbuttondisable : null}`}
            >
              POST
            </Button>

            {/* <Button onClick={props.onHide}>Close</Button> */}
            <div className={styles.aboutAskInfo}>
              <img
                src={aboutASK}
                alt="aboutask"
                className={styles.aboutAskInfoicon}
              />

              {isPostOption === "ASK" ? (
                <div className={styles.askAbout}>
                  <span>Why create ASK</span>
                  <p>
                    ASK something relevant and pressing to you or your
                    community. (EX: Can I take AP college board exam without
                    taking an AP class at my school?)
                  </p>
                  <span>ASK your Community</span>
                  <p>
                    Review all matching results to see if you can find your best
                    answer, if not move it to open pool for your peers to
                    answer.
                  </p>
                </div>
              ) : isPostOption === "SAY" ? (
                <div className={styles.askAbout}>
                  <span>Why create SAY</span>
                  <p>
                    Share your knowledge or express your thoughts to benefit
                    your community. (EX: Ms. Peck teaches Calculus great. Her
                    tests and grading are tough, but she offers one make up
                    test.)
                  </p>
                  <span>Build your community knowledge</span>
                  <p>
                    Your SAY could be the best answer yours peers may be looking
                    for, so keep sharing your knowledge and thoughts.
                  </p>
                </div>
              ) : null}
            </div>
            {isPostOption !== "ASK" && isPostOption !== "SAY" && (
              <div className={styles.postSelectionWarning}>
                <p>
                  You must select ASK or SAY in order to start writing your post
                </p>
              </div>
            )}
          </Modal.Footer>
        </div>
      </Modal>

      <AsksModal
        show={SaysShow}
        onHide={() => setSaysShow(false)}
        isPostOption={isPostOption}
        setSaysShow={setSaysShow}
      />
    </React.Fragment>
  );
};

export default AskSayPostDataModal;
