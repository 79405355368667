import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "src/Assets/css/Activity/ActivityScreen.css";
import Profile from "./Profile/Profile";
import Community from "./Community/Community";
import Activites from "./Activites/Activites";
import * as mapActions from "../../store/actions/mapactions/mapAction";
import styles from "src/Assets/css/settings/settings.module.css";
import Page, { PageEnum } from "src/layout/Page";

function ActivityScreen() {
  const dispatch = useDispatch();
  const [isNotificationSelected, setIsNotificationSelected] = useState(false);

  useEffect(() => {
    dispatch(mapActions.getNearByCommunitysData());
  }, []);

  const showHideNotifications = () => {
    setIsNotificationSelected(!isNotificationSelected);
  }

  return (
    <Page page={PageEnum.Profile}>
      {/* {isNotificationSelected ? (
        <div className={`col-sm-3  ${styles.notifications}`}>
          <div className={styles.notificationscroll}>
            <Notifications />
          </div>
        </div>
      ) : null} */}
      <div>
        <Profile />
        <div className={styles.communityAndActivityMainContainer}>
          <Community />
          <Activites />
        </div>
      </div>
    </Page>
  );
}

export default ActivityScreen;
