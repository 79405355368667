import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Typography, Avatar } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { BsDot } from "react-icons/bs";
import like from "src/Assets/images/like.svg";
import unlike from "src/Assets/images/unlike.svg";
import Edit from "src/Assets/images/edit.svg";
import Delete from "src/Assets/images/Delete.svg";
import activityStyle from "src/Assets/css/Activity/activity.module.css";
import Location from "src/Assets/images/locationpin.png";
import API from "src/network";
import ReusuableSendaMailModal from "src/Modules/Reusuablecomponents/ReusuableSendaMailModal";
import ReusuableReportDialogmodal from "src/Modules/Reusuablecomponents/ReusuableReportDialogmodal";
import SimpleDialogDemo from "src/Modules/Reusuablecomponents/CustomizedDialogs";
import PostMessageValidation from "src/Modules/Reusuablecomponents/PostMessageValidation";
import PopupComponent from "./PopupComponent";
import AvatarBadge from "src/Components/AvatarBadge";
import { replaceTagAndUrl } from "src/Utils/commonUtils";
import ReusuableDeleteDialogmodal from "src/Modules/Reusuablecomponents/ReusuableDeleteModal";

function SaysActivityUI(props) {
  const profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;
  const [editId, setEditID] = useState(null);
  const [popupPostId, setPopPostId] = useState(null);
  const [popupData, setPopupData] = useState(null);
  const [open, setOpen] = useState(false);

  const userDetails = useSelector((state) => state.SignInReducer.userDetails);

  const followunfollow = (id, followStatus, followCount) => {
    const newStatus = followCount === "1" ? "N" : "Y";
    const newPopupDataList = popupData.childQ.map((item) => {
      if (item.id === id) {
        const newFollowCount =
          followCount === "1" ? item.followCount - 1 : item.followCount + 1;
        return {
          ...item,
          followStatus: newStatus,
          followCount: newFollowCount,
        };
      } else {
        return item;
      }
    });
    setPopupData((prevData) => ({ ...prevData, childQ: newPopupDataList }));
    API.fetchFollowUnfollowData(id, followStatus);
  };

  const onLikeUnlikeSelection = (id, cache) => {
    props.likeUnlike(id);
    API.fetchVoteUpStatus(id, cache);
  };

  const updateMessage = (id, message) => {
    props.updateMessage(id, message);
    cancelEdit();
  };

  const deletePopupMessage = async (id) => {
    try {
      const newPopupDataList = popupData.childQ.filter(
        (item) => item.id !== id
      );
      const formdata = new FormData();
      formdata.append("messageID", id);
      await API.deleteAskTagPostUnderSayActivity(formdata);
      setPopupData((prevData) => ({ ...prevData, childQ: newPopupDataList }));
      onClosePopup();
      props.delete && props.delete(popupPostId);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickHashTag = async (parentId, id) => {
    try {
      const formData = new FormData();
      formData.append("id", parentId);
      formData.append("openQstatus", "SAYsQchild4");
      formData.append("mostlikedrepabuse", "mostliked");
      formData.append("parantId", "mostliked");
      const response = await API.getChildHashTag(formData);
      setPopupData(response.data); // Set the response data in state
      setOpen(true);
      setPopPostId(id);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onClosePopup = () => {
    setOpen(false);
    setPopPostId(null);
  };

  const cancelEdit = () => {
    setEditID(null);
  };

  const truncateLocationName = (locationName) => {
    const maxLength = 30; // set your desired max length
    if (locationName.length > maxLength) {
      return locationName.substring(0, maxLength) + "...";
    } else {
      return locationName;
    }
  };

  return (
    <>
      {props.listData.map((result) => (
        <div className={activityStyle.activityPostCard} key={result.id}>
          <div className="profile-info">
            <div
              className={activityStyle.activityPostImageDropdownMainContainer}
            >
              <div className={activityStyle.activityPostImageProfileContainer}>
                <div className={activityStyle.profileimg}>
                  <AvatarBadge
                    src={`${profileImagePath}${result?.mainuserdata?.uimage}`}
                    alt={result?.mainuserdata?.displayName}
                    isPrimaryModerator={result?.isPM == 1}
                    isModerator={result?.isModerator == 1 && result?.isPM == 0}
                  />
                </div>
                <div style={{ flex: 1 }} className={activityStyle.profilename}>
                  <p>{result.userDetails.displayName}</p>
                  {
                    <div className={activityStyle.profilehover}>
                      <div className={activityStyle.profilehoverimageandcount}>
                        <div>
                          <AvatarBadge
                            src={`${profileImagePath}${result?.mainuserdata?.uimage}`}
                            alt={result?.mainuserdata?.displayName}
                            isPrimaryModerator={result?.isPM == 1}
                            isModerator={
                              result?.isModerator == 1 && result?.isPM == 0
                            }
                            size={35}
                          />
                        </div>
                        <div>
                          <p className={activityStyle.profilehovername}>
                            {result?.mainuserdata?.displayName}
                          </p>
                          <p className={activityStyle.profilecount}>
                            ASK {result?.mainuserdata?.askcount} | SAY{" "}
                            {result?.mainuserdata?.saycount} | UPDATE{" "}
                            {result?.mainuserdata?.updatecount} | TAG{" "}
                            {result?.mainuserdata?.hashtagcount}
                          </p>
                        </div>
                      </div>
                      <p className={activityStyle.profilehovercontent}>
                        {result?.mainuserdata?.shortBio}
                      </p>
                    </div>
                  }
                  <span className="MessgeDetils">
                    {result.Message_Time} in {result.Tenant_name}{" "}
                    {result.locName && (
                      <>
                        <img
                          src={Location}
                          style={{ margin: "4px", height: "12px" }}
                        />
                        <span>{truncateLocationName(result.locName)}</span>
                      </>
                    )}
                  </span>
                </div>
              </div>
              <div
                className={
                  activityStyle.activityPostCardImagePopupMainContainer
                }
              >
                <Menu
                  menuButton={
                    <IconButton
                      style={{
                        padding: 0,
                        width: 30,
                        height: 30,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        outline: "none",
                      }}
                      disabled={
                        result.communityFollowStatus === 0 &&
                        result.tenantType === "private"
                      }
                    >
                      <MdKeyboardArrowDown fontSize={25} />
                    </IconButton>
                  }
                  transition
                  direction="bottom"
                  arrow
                  menuStyle={{
                    backgroundColor: "white",
                    borderRadius: 20,
                  }}
                >
                  {result.userDetails.User_ID !==
                    userDetails.userData.User_ID ? (
                    <>
                      {result.moderatorStatus === "1" ? (
                        <>
                          <MenuItem>
                            <ReusuableDeleteDialogmodal
                              label={"Delete Post"}
                              type={"SAY"}
                              id={result.id}
                              deleteMessage={() => props?.onDelete && props.onDelete(result.id)}
                              fetchContributors={() => { }}
                            />
                          </MenuItem>
                          <MenuItem>
                            <ReusuableSendaMailModal
                              displayName={result.userDetails.displayName}
                              id={result.id}
                            />
                          </MenuItem>
                        </>
                      ) : (
                        <>
                          <MenuItem>
                            <ReusuableReportDialogmodal
                              id={result.id}
                              param={"AskorSay"}
                            />
                          </MenuItem>
                          <MenuItem>
                            <ReusuableSendaMailModal
                              displayName={result.userDetails.displayName}
                              id={result.id}
                            />
                          </MenuItem>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <MenuItem onClick={() => setEditID(result.id)}>
                        <img src={Edit} alt="edit" />
                        <span style={{ fontSize: 14, marginLeft: 8 }}>
                          Edit Post
                        </span>
                      </MenuItem>
                      <MenuItem>
                        <ReusuableDeleteDialogmodal
                          label={"Delete Post"}
                          type={"SAY"}
                          id={result.id}
                          deleteMessage={() => props?.onDelete && props.onDelete(result.id)}
                          fetchContributors={() => { }}
                        />
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>
          <>
            <div className={activityStyle.activityPostDescriptionContainer}>
              {editId === result?.id ? (
                <PostMessageValidation
                  id={result.id}
                  Message={result.Message}
                  messageType={"feedTextArea"}
                  filtertype={"activitySay"}
                  post={result}
                  onCloseEdit={cancelEdit}
                  onMessagedUpdated={updateMessage}
                />
              ) : (
                <Typography whiteSpace="pre-wrap">
                  {
                    replaceTagAndUrl(
                      result.Message,
                      result.msg,
                      result.tag,
                      result.url,
                      props.onHashTagClick,
                    )}
                  {/* {result?.Message} */}
                </Typography>
              )}
            </div>
          </>

          <div className="PostCounts">
            <div className={activityStyle.likeCountContainer}>
              {result.voteUp !== 0 ? (
                <SimpleDialogDemo
                  voteUp={result.voteUp}
                  id={result.id}
                  isVoteup={"true"}
                />
              ) : (
                <p>
                  {result.voteUp === 1
                    ? `${result.voteUp} Like`
                    : `${result.voteUp} Likes`}
                </p>
              )}

              {result.parentMessageType === "private" ||
                result.parentMessageType === "public" ? (
                <>
                  <BsDot className={activityStyle.dotIcon} />
                  <button
                    onClick={(e) =>
                      handleClickHashTag(result.Parent_ID, result.id)
                    }
                    className={activityStyle.likeCountContainerButton}
                    type="button"
                  >
                    {result.hashTag}
                  </button>
                </>
              ) : (
                <>
                  {result.parentMessageType === "" ? null : (
                    <>
                      <BsDot className={activityStyle.dotIcon} />
                      <button
                        onClick={(e) =>
                          handleClickHashTag(result.Parent_ID, result.id)
                        }
                        className={activityStyle.likeCountContainerButton}
                        type="button"
                      >
                        {result.parentMessageType}
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
            {result.communityFollowStatus === 0 &&
              result.tenantType === "private" && (
                <p className="warning-msg-text">
                  This is a private community, No action is permitted for non
                  member
                </p>
              )}
          </div>

          <div className="PostActions">
            <>
              <div
                style={{
                  cursor: "pointer",
                  marginTop: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    textTransform: "none",
                    color: "#000",
                    border: "none",
                  }}
                  disabled={
                    result.communityFollowStatus === 0 &&
                    result.tenantType === "private"
                  }
                  variant="outlined"
                  startIcon={
                    result.UserLikeStatus === "Active" ? (
                      <img src={like} alt="Unlike" />
                    ) : (
                      <img src={unlike} alt="Like" />
                    )
                  }
                  onClick={() =>
                    result.UserLikeStatus === "Active"
                      ? onLikeUnlikeSelection(result.id, "cacheUpdate")
                      : null
                  }
                >
                  Like
                </Button>
              </div>
            </>
          </div>
        </div>
      ))}

      {open && popupPostId && (
        <PopupComponent
          popupData={popupData}
          setPopupData={setPopupData}
          followunfollow={followunfollow}
          open={open}
          onClose={onClosePopup}
          postId={popupPostId}
          deletePopupMessage={deletePopupMessage}
          filtertype="activityTag"
        />
      )}
    </>
  );
}

export default SaysActivityUI;
