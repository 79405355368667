// import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  searchClass: () => ({
    "& .MuiFormControl-root": {
      width: "100%",
      // paddingRight: "25px",
      marginBottom: "0px",
      "& .MuiInputBase-root": {
        borderRadius: "20px",
        background: "#ffffff",
        height: "35px",
        boxShadow: "0px 6px 6px rgb(0 0 0 / 20%)",
        // '& .MuiIconButton-label': {
        //     justifyContent: 'end',
        //     alignItems: 'end'
        // }
      },
      "& .MuiIconButton-root:hover": {
        backgroundColor: "unset !important",
      },
      "& button:focus": {
        outline: "unset !important",
      },
    },
  }),
  buttonContainer: () => ({
    // display: "flex",
    // justifyContent: "space-around",
    // paddingTop: "15px",
    "& .MuiButtonBase-root": {
      fontSize: "13px",
      background: "#ffffff",
      color: "#4795DE",
      borderRadius: "15px",
      padding: "10px",
      border: "none",
      boxShadow: "rgba(0, 0, 0, 0.3) 1px 2px 6px",
      "&:hover": {
        fontSize: "13px",
        background: "#ffffff",
        color: "#4795DE",
        borderRadius: "15px",
        padding: "10px",
        border: "none",
      },
      "&:focus": {
        outline: "none",
      },
      "& .MuiSvgIcon-root": {
        color: "#4795DE !important",
        width: "40px !important",
        height: "40px !important",
      },
    },
  }),
  searchOrStartBtn: () => ({
    marginBottom: "15px",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "20px"
  }),
  searchBtn: () => ({
    backgroundColor: "transparent",
    border: "none",
    padding: 0,
    margin: 0,
    color: "rgb(71, 149, 222)",
    fontWeight: "bold"
  })
});

export default useStyles;
