import React, { useState } from 'react';
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import CountryOptions from './CountryOptions';
import styles from './Country.module.css';

export default function (props) {
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const { selectedCountry = {}, onCountryChange = () => { }, containerStyle = {}, countryOptionsStyle = {} } = props;
    const pos = selectedCountry.label ? selectedCountry.label.indexOf('(') : -1;

    const renderOverlayContent = () => {
        return (
            <>
                <CountryOptions onChange={onCountryChange} noLang countryOptionsStyle={countryOptionsStyle} closeTooltip={closeTooltip} />
            </>
        );
    };

    const closeTooltip = () => setTooltipVisible(false);

    return (
        <Tooltip
            placement="bottom"
            visible={tooltipVisible}
            overlay={renderOverlayContent()}
            trigger={"click"}
            onVisibleChange={(visible) => setTooltipVisible(visible)}
        >
            <div className={[styles.inputContainer, containerStyle].join(' ')}>
                <img src={selectedCountry.image} className={styles.flagImg} alt="country" />
                <span className={styles.countryTxt}>
                    {`${selectedCountry?.dialCode}`}
                </span>
            </div>
        </Tooltip>
    );
};