import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
// import CardContent from "@mui/material/CardContent";
import { Button, Modal } from "react-bootstrap";
import ModeratorLocationIcon from "src/Assets/images/moderatorLocationIcon.svg";
import EditPhotoDropdown from "src/Assets/images/EditPhotoDropdown.svg";
import Edit from "src/Assets/images/edit.svg";
import Delete from "src/Assets/images/Delete.svg";
// import CloseButton from "src/Assets/images/CloseButton.svg";
import Add from "src/Assets/images/Add.svg";
// import Deletephoto from "src/Assets/images/Add.png";
import * as moderatorActions from "src/store/actions/Moderator/moderatoractions";
import LocationUpdate from "src/Components/DefaultCommunity/locationUpdate";
import ModeratorAlert from "../ReusableModeratorComponents/ModeratorAlert";
import UploadPhotoTimeLineDialog from "src/views/dialogs/UploadPhotoTimelineDialog";
import Api from "src/network";
import ModeratorDescriptionValidation from "./ModeratorDescriptionValidation";
import "primeflex/primeflex.css";
import "src/Assets/css/Moderator/ModeratorHeader.css";
// import PhotoListData from "./PhotoList";
// import LocationTag from "../../Reusuablecomponents/LocationTag";
import axios from "axios";
import Cookies from "js-cookie";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p
      className={`${"photoDescriptionArea"} ${text && text !== "" ? "textColor" : null
        }`}
    >
      {text && text !== "" ? (
        isReadMore ? (
          text.slice(0, 102)
        ) : (
          text
        )
      ) : (
        <span className="initial-Description">Add description…</span>
      )}
      {text && text.length > 120 ? (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...See More" : "Show Less"}
        </span>
      ) : null}
    </p>
  );
};

export const PhotoInfo = ({
  isOverPhotoCount,
  setCheckedImage,
  isImageChecked,
  setTenantID,
  isUpdateText,
  setPhotoDescription,
}) => {

  // From here the Timeline images data is fetched

  const imageServerURL = process.env.REACT_APP_SKOPIC_IMAGE;

  const timeLineImagesList = useSelector(
    (state) => state.EditCommunityReducer.timeLineImagesList
  );

  const [isDescriptionText, setDescriptionText] = useState("");
  const [setLocationSelect, isLocationSelect] = React.useState("");
  const [isLat, setLat] = React.useState("");
  const [isLng, setLng] = React.useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(null);
  const [data, setData] = useState({});
  const [currentPhotoId, setCurrentPhotoId] = useState("");
  const [selectedImage, setSelectedImage] = useState('');
  const [open, setOpen] = useState(false);
  const [imgDescription, setImgDescription] = useState("");
  const dispatch = useDispatch("");

  // Use effects are here
  useEffect(() => {
    setData({ ...timeLineImagesList });
  }, [timeLineImagesList]);

  useEffect(() => {
    if (isDescriptionText) {
      setPhotoDescription(isDescriptionText);
    }
  }, [isDescriptionText]);

  // checkbox code is here

  const onCheckboxChecked = (event, imageId, tenantId) => {
    if (event.target.checked) {
      setCheckedImage([...isImageChecked, imageId]);
    } else {
      setCheckedImage((prev) =>
        prev.filter((curritem) => curritem !== imageId)
      );
    }
    setTenantID(tenantId);
  };

  // Ondescription change is here
  const onDescriptionTextChange = (e, currentImage, index) => {
    const updatedDescription = e.target.value;

    setData((prevData) => {
      const updatedImagesDetails = [...prevData.imagesDetails];
      currentImage.tmlPhotoDesc = updatedDescription;
      updatedImagesDetails.splice(index, 1, currentImage);

      return {
        ...prevData,
        imagesDetails: [...updatedImagesDetails],
      };
    });
  };

  // Handle save function is Here

  // const handleSave = async () => {
  //   const description = imgDescription || "";
  //   if (!currentPhotoId || !description) {
  //     console.error("data required");
  //     return;
  //   }

  //   try {
  //     // const response = await Api.updateTimelinePhotoDesc(currentPhotoId, setTenantID, description || "");

  //     //update image list
  //     const imagesDetails = data?.imagesDetails || [];
  //     let currentImage = imagesDetails?.find(image => +image.imageId === +currentPhotoId);
  //     currentImage.tmlPhotoDesc = imgDescription;
  //     const index = imagesDetails?.findIndex(image => +image.imageId === +currentPhotoId);
  //     imagesDetails?.splice(index, 1, currentImage);
  //     dispatch(moderatorActions.receivePhotoTimeLineList({
  //       ...data,
  //       imagesDetails: [...imagesDetails],
  //     }));
  //     const lat = `${isLat},${isLng}`;

  //     let formdata = new FormData();
  //     formdata.append("tenantId", setTenantID);
  //     formdata.append("imageId", currentPhotoId);
  //     formdata.append("tmlPhotoDesc", description);
  //     formdata.append("tmlPhotoLoc", lat);
  //     formdata.append("locName", setLocationSelect);
  //     const baseURL = process.env.REACT_APP_SERVICES_API_OTHER;
  //     await axios
  //       .request({
  //         url: `${baseURL}/jsonuser/timeline-photo-desc`,
  //         method: "post",
  //         data: formdata,
  //         headers: {
  //           "Access-Control-Allow-Origin": "*",
  //           "Set-Cookie": Cookies.get("JSESSIONID"),
  //         },
  //         withCredentials: true,
  //       })
  //       .then((res) => {
  //         if (res?.status == 200) {
  //           dispatch(moderatorActions.fetchPhotoTimeLineList(`tenantId=${setTenantID}`));
  //         }
  //       });

  //     // dispatch(
  //     //   moderatorActions.receiveTimeLineImagesUpdateResponse(
  //     //     updatedTimelinePhoto
  //     //   )
  //     // );

  //     // setData((prevData) => {
  //     //   const updatedImagesDetails = prevData.imagesDetails.map((image) =>
  //     //     image.imageId === currentPhotoId
  //     //       ? { ...image, tmlPhotoDesc: updatedTimelinePhoto.tmlPhotoDesc }
  //     //       : image
  //     //   );

  //     //   return {
  //     //     ...prevData,
  //     //     imagesDetails: updatedImagesDetails,
  //     //   };
  //     // });

  //     setCurrentPhotoId("");
  //     setImgDescription("");
  //   } catch (e) {
  //     console.error(e);
  //   }


  //   // axios
  //   //   .post(
  //   //     `https://dev.skopic.com/skopicportal/jsonuser/timeline-photo-desc?imageIds=${currentPhotoId}&tenantId=${setTenantID}&tmlPhotoDesc=${updatedDescription || ""}`,

  //   //     {
  //   //       withCredentials: true,
  //   //     }
  //   //   )
  //   //   .then((response) => {
  //   //     const updatedTimelinePhoto = response.data;

  //   //     dispatch(
  //   //       moderatorActions.receiveTimeLineImagesUpdateResponse(
  //   //         updatedTimelinePhoto
  //   //       )
  //   //     );

  //   //     setData((prevData) => {
  //   //       const updatedImagesDetails = prevData.imagesDetails.map((image) =>
  //   //         image.imageId === currentPhotoId
  //   //           ? { ...image, tmlPhotoDesc: updatedTimelinePhoto.tmlPhotoDesc }
  //   //           : image
  //   //       );

  //   //       return {
  //   //         ...prevData,
  //   //         imagesDetails: updatedImagesDetails,
  //   //       };
  //   //     });

  //   //     setCurrentPhotoId("");
  //   //   })
  //   //   .catch((error) => {
  //   //     console.log(error);
  //   //   });
  // };

  const handleSave = async () => {
    const description = imgDescription || "";
    if (!currentPhotoId || !description) {
      console.error("Data required");
      return;
    }
  
    try {
      // Update image list locally
      const imagesDetails = data?.imagesDetails || [];
      const currentImage = imagesDetails.find(image => +image.imageId === +currentPhotoId);
      if (currentImage) currentImage.tmlPhotoDesc = imgDescription;
  
      const index = imagesDetails.findIndex(image => +image.imageId === +currentPhotoId);
      if (index !== -1) imagesDetails.splice(index, 1, currentImage);
  
      dispatch(
        moderatorActions.receivePhotoTimeLineList({
          ...data,
          imagesDetails: [...imagesDetails],
        })
      );
  
      // Prepare FormData for API request
      const formdata = new FormData();
      formdata.append("tenantId", setTenantID);
      formdata.append("imageId", currentPhotoId);
      formdata.append("tmlPhotoDesc", description);
  
      // Append location only if it exists
      if (isLat && isLng) {
        const lat = `${isLat},${isLng}`;
        formdata.append("tmlPhotoLoc", lat);
      }
      if (setLocationSelect) {
        formdata.append("locName", setLocationSelect);
      }
  
      const baseURL = process.env.REACT_APP_SERVICES_API_OTHER;
  
      // Send the request
      const res = await axios.post(`${baseURL}/jsonuser/timeline-photo-desc`, formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Set-Cookie": Cookies.get("JSESSIONID"),
        },
        withCredentials: true,
      });
  
      if (res.status === 200) {
        dispatch(
          moderatorActions.fetchPhotoTimeLineList(`tenantId=${setTenantID}`)
        );
      }
  
      // Reset form state
      setCurrentPhotoId("");
      setImgDescription("");
    } catch (e) {
      console.error(e);
    }
  };
  


  // Delete Photo functionality is Here

  const OnDeletePhoto = async (currentPhotoId) => {
    try {
      setDropdownVisible(null);
      const response = await Api.deleteImages(currentPhotoId, setTenantID);
      // console.log(response.data);

      // Assuming response.data contains details of the deleted image
      // const deletedImageId = response.data.imageId;

      // Filter out the deleted image from the current state
      const updatedImagesList = data?.imagesDetails?.filter(
        (item) => item.imageId !== currentPhotoId
      );

      // Dispatch the action to update the Redux state
      dispatch(moderatorActions.receivePhotoTimeLineList({
        ...data,
        imagesDetails: updatedImagesList,
      }));
    } catch (e) {
      console.error(e);
    }

    // setDropdownVisible(null);
    // axios
    //   .delete(
    //     `https://dev.skopic.com/skopicportal/jsonuser/timeline-images?imageIds=${currentPhotoId}&tenantId=${setTenantID}`,
    //     {
    //       withCredentials: true,
    //     }
    //   )
    //   .then((response) => {
    //     console.log(response.data);

    //     // Assuming response.data contains details of the deleted image
    //     const deletedImageId = response.data.imageId;

    //     // Filter out the deleted image from the current state
    //     const updatedImagesList = timeLineImagesList.filter(
    //       (item) => item.imageId !== deletedImageId
    //     );

    //     // Dispatch the action to update the Redux state
    //     dispatch(moderatorActions.receivePhotoTimeLineList(updatedImagesList));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const onEditText = (id, imgDesc) => {
    setCurrentPhotoId(id);
    setImgDescription(imgDesc);
    setDropdownVisible(null);
    setOpen(false);
  };

  // const handleSave = () => {
  //   const updatedData = data.imagesDetails.map((image) =>
  //     image.imageId === currentPhotoId
  //       ? { ...image, tmlPhotoDesc: isDescriptionText }
  //       : image
  //   );

  //   setData({ ...data, imagesDetails: updatedData });

  //   setCurrentPhotoId("");
  // };

  const handleCancel = () => {
    setCurrentPhotoId("");
    setData({ ...timeLineImagesList });
  };

  const onArrowClick = (index) => {
    setDropdownVisible(isDropdownVisible === index ? null : index);
  };

  const handleLocationClick = (image) => {
    setLat(image?.tmlPhotoLat);
    setLng(image?.tmlPhotoLng);
    setOpen(true);
  }

  // console.log('setLocationSelect', setLocationSelect)

  return (
    <React.Fragment>
      {
        data && Object.keys(data).length !== 0 ? (
          data.imagesDetails.map((ImagesList, index) => (
            <div className="Phototimeline" key={ImagesList.imageId}>
              <Card className="Phototimelinecard">
                <CardMedia
                  component="img"
                  height="140"
                  image={`${imageServerURL}${ImagesList.timelineLogo}`}
                  alt="UserFollowingCommunity"
                  className="CommunityIcon "
                />

                {isOverPhotoCount ? (
                  <Checkbox
                    className="checked-delete-photo"
                    onChange={(event) =>
                      onCheckboxChecked(
                        event,
                        ImagesList.imageId,
                        ImagesList.tenantId
                      )
                    }
                  />
                ) : (
                  <>
                    <span
                      style={{
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 0,
                        margin: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => onArrowClick(index)}
                    >
                      <img src={EditPhotoDropdown} alt="arrow" />
                    </span>

                    {isDropdownVisible === index && (
                      <div
                        // className="activityDropdownOption"
                        style={{
                          display: "block",
                          position: "absolute",
                          boxSizing: "border-box",
                          // boxShadow: "1px 2px 6px",
                          backgroundColor: "#f3f2f0",
                          borderRadius: "10px",
                          minWidth: "80px", // Adjust the width as needed
                          top: "30px",
                          right: 0,
                        }}
                      >
                        <ul
                          className="menuItems"
                          style={{
                            listStyle: "none",
                            padding: 0,
                            margin: 0,
                            backgroundColor: "white",
                          }}
                        >
                          <li
                            onClick={() =>
                              onEditText(
                                ImagesList.imageId,
                                ImagesList.tmlPhotoDesc
                              )
                            }
                            style={{ padding: "10px", cursor: "pointer" }}
                          >
                            <img src={Edit} alt="Edit Icon" />
                            Edit photo
                          </li>
                          <li
                            onClick={() => OnDeletePhoto(ImagesList.imageId)}
                            style={{ padding: "10px", cursor: "pointer" }}
                          >
                            <img src={Delete} alt="Delete Icon" />
                            Delete Photo
                          </li>
                        </ul>
                      </div>
                    )}
                  </>
                )}
              </Card>
              {isOverPhotoCount ? (
                <ModeratorDescriptionValidation
                  desc={ImagesList?.tmlPhotoDesc}
                  setDescriptionText={setDescriptionText}
                  index={ImagesList.imageId}
                  isLocationSelect={isLocationSelect}
                  setLng={setLng}
                  setLat={setLat}
                />
              ) : (
                <>
                  {currentPhotoId === ImagesList.imageId ? (
                    <>
                      <TextField
                        placeholder="Add a description"
                        multiline
                        rows={2}
                        maxRows={4}
                        // onChange={(e) => onDescriptionTextChange(e, ImagesList, index)}
                        // value={ImagesList?.tmlPhotoDesc || ""}
                        onChange={(e) => setImgDescription(e.target.value)}
                        value={imgDescription}
                        variant="outlined"
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Button
                            variant="contained"
                            style={{
                              marginRight: "10px",
                              border: "none",
                              borderRadius: 36,
                              padding: "2px 4px",
                            }}
                            onClick={handleSave}
                          >
                            Save
                          </Button>
                        </div>
                        <div>
                          <Button
                            style={{ border: "none", color: "rgb(10,10,10)" }}
                            variant="outlined"
                            onClick={handleCancel}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <ReadMore>{ImagesList?.tmlPhotoDesc}</ReadMore>
                  )}

                  <div className="LocationandCountDisplay">
                    <span style={{ cursor: "pointer" }}>
                      <img
                        src={ModeratorLocationIcon}
                        onClick={() => handleLocationClick(ImagesList)}
                      />
                      {ImagesList.locName}
                    </span>
                    <span>
                      <label>{ImagesList?.tmlPhotoDesc?.length}/280</label>
                    </span>
                  </div>
                  {currentPhotoId === ImagesList.imageId ? (
                    <LocationUpdate
                      data={selectedImage}
                      open={open}
                      setOpen={setOpen}
                      isLocationSelect={isLocationSelect}
                      setLng={setLng}
                      setLat={setLat}
                      edit={true}
                      isLat={isLat}
                      isLng={isLng}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
              {isOverPhotoCount ? (
                ImagesList?.tmlPhotoDesc?.length !== 0 ? (
                  <p>{isUpdateText}</p>
                ) : null
              ) : (
                <></>
              )}
            </div>
          ))
        ) : (
          <p>No Data</p>
        )
        // <CircularProgress />
      }
    </React.Fragment>
  );
};


// const AddPhotoText = (props) => {

//     return (
//         <span className="AddPhotoLink">
//             <img src={Add} />
//             {/* Add Photo */}
//             <label for="AddPhoto" className='AddPhotoLabel'>Add Photo</label>
//             <input id="AddPhoto" className='AddPhotoInput' type="file" onChange={props.onPhotoUpload} multiple></input>
//         </span>
//     )
// }

const Phototimeline = ({ tenantId }) => {
  const moderatorTenantList = useSelector(
    (state) => state.EditCommunityReducer.moderatorTenantList
  );
  // const userDetails = useSelector((state) => state.SignInReducer.userDetails);

  const [isPhotoUpload, setPhotoUpload] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [isModeratorTenantListId, setModeratorTenantListId] = useState(false);
  const [isTenantID, setTenantID] = useState();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (moderatorTenantList && Object.keys(moderatorTenantList).length !== 0) {
      moderatorTenantList.tenantList.map(
        (tenantlist) =>
          parseInt(tenantlist.id) === tenantId
            ?
            tenantlist.count > 14
              ? setModeratorTenantListId(true)
              : setModeratorTenantListId(false)
            : null
        // console.log(userDetails.userData.tenantId)
        // if (tenantlist.id === userDetails.userData.tenantId) {
        // setModeratorTenantListId(isModeratorTenantListId => [...isModeratorTenantListId, tenantlist])
        // }
      );
    }

    setTenantID(tenantId);
  }, [moderatorTenantList, tenantId]);

  // useEffect(() => {
  //     if (isModeratorTenantListId && Object.keys(isModeratorTenantListId).length !== 0 && userDetails && Object.keys(userDetails).length !== 0) {
  //         isModeratorTenantListId.forEach(function (id) {
  //             if (parseInt(id) === userDetails.userData.tenantId) {
  //                 console.log(userDetails.userData.tenantName)
  //                 // if()
  //             }

  //         })

  //     }
  // })

  const onModalSelect = () => {
    setModalShow(false);
  };

  const onPhotoUploadSelect = () => {
    // if (isModeratorTenantListId) {
    //   setOpen(true);
    // }
  };

  const onPhotoUpload = (e) => {
    // setPhotoUpload(URL.createObjectURL(e.target.files[0]));
    // setModalShow(true);
    // console.log(URL.createObjectURL(e.target.files[0]))

    setShowDialog(!showDialog);
  };

  let [showDialog, setShowDialog] = useState(false);

  const onCancelDialog = () => {
    // console.log("clicked main");
    setShowDialog(!showDialog);
  };

  const onUploadSuccess = () => {
    //refresh image list
    dispatch(moderatorActions.fetchPhotoTimeLineList(`tenantId=${isTenantID}`));
  };

  return (
    <React.Fragment>
      <div className="AddPhoto">
        <span>
          *You may add up to 14 photos not exceeding, 50 MB in total and 5 MB
          per piece
        </span>
        {/* <AddPhotoText onPhotoUpload={onPhotoUpload} /> */}
        <span className="AddPhotoLink">
          <img src={Add} />
          {/* Add Photo */}
          <label htmlFor="AddPhoto" className="AddPhotoLabel">
            Add Photo
          </label>
          {isModeratorTenantListId ? (
            <input
              id="AddPhoto"
              className="AddPhotoInput"
              type="text"
              onClick={onPhotoUploadSelect}
            ></input>
          ) : (
            <input
              id="AddPhoto"
              className="AddPhotoInput"
              type="text"
              onClick={onPhotoUpload}
            ></input>
          )}
        </span>
        {/* <AddPhotoModal
          show={modalShow}
          onHide={() => onModalSelect()}
          modalShow={modalShow}
          setModalShow={setModalShow}
          isPhotoUpload={isPhotoUpload}
        /> */}

        <UploadPhotoTimeLineDialog
          tenantId={tenantId}
          showDialog={showDialog}
          onCancelDialog={onCancelDialog}
          onUploadSuccess={onUploadSuccess}
        />
      </div>
      <div className="photoGrid">
        {/* <PhotoListData setTenantID={isTenantID}/> */}
        <PhotoInfo setTenantID={isTenantID} />
      </div>
      <ModeratorAlert open={open} setOpen={setOpen} isTenantID={isTenantID} />
    </React.Fragment>
  );
};

export default Phototimeline;

// function AddPhotoModal(props) {
//   const userDetails = useSelector((state) => state.SignInReducer.userDetails);

//   const dispatch = useDispatch();
//   const [isFile, setFile] = useState([]);
//   const [isSingleFile, setisSingleFile] = useState(true);
//   const [isOverCountLimit, setOverCountLimit] = useState(false);
//   const [isDescriptionText, setDescriptionText] = useState("");
//   const [setLocationSelect, isLocationSelect] = React.useState(null);
//   const [isLat, setLat] = React.useState(null);
//   const [isLng, setLng] = React.useState(null);

//   useEffect(() => {
//     // console.log("TenantId:" + userDetails.userData.tenantId);
//     if (!props.modalShow) {
//       setFile([]);
//     }
//   }, [props.modalShow]);

//   useEffect(() => {
//     let singleFile = [];
//     if (props.isPhotoUpload) {
//       singleFile.push(props.isPhotoUpload);
//       setFile([...isFile, singleFile]);
//       setisSingleFile(true);
//     }
//   }, [props.isPhotoUpload]);

//   let fileObj = [];
//   let fileArray = [];
//   const uploadMultipleFiles = (e) => {
//     fileObj.push(e.target.files);
//     for (let i = 0; i < fileObj[0].length; i++) {
//       fileArray.push(URL.createObjectURL(fileObj[0][i]));
//     }
//     setFile([...isFile, fileArray]);
//     setisSingleFile(false);
//     const selectedImages = Array.from(e.target.files);
//     setFile(selectedImages);
//     // this.setState({ file: this.fileArray })
//   };
//   const uploadFiles = (e) => {
//     e.preventDefault();
//     // console.log(isFile);
//   };

//   const onPhotoRemove = (index) => {
//     let multiphotoremove = `multiphoto${index}`;
//     document.getElementById(multiphotoremove).style.display = "none";
//   };

//   let imgPreview;

//   const onPhotoUploadSubmit = (e) => {
//     console.log('onPhotoUploadSubmit invoked');
//     // console.log(isFile);
//     e.preventDefault();
//     if (userDetails && Object.keys(userDetails).length !== 0) {
//       isFile.forEach((image, index) => {
//         let formData = new FormData();
//         formData.append(`img`, image[0], "file.jpeg");
//         // formData.append('imgs0', action.payload.fileName)
//         formData.append("tmlPhotoDescs", '["' + isDescriptionText + '"]');
//         formData.append("tmlPhotoLoc", '[""]');
//         formData.append("locName", '[""]');
//         // console.log(formData);

//         dispatch(
//           moderatorActions.uploadPhototoPhototimeline({
//             tenantId: userDetails.userData.tenantId,
//             formData: formData,
//           })
//         );
//       });
//     }
//     // for(let uploadPhoto=0;)
//   };

//   if (props.isPhotoUpload) {
//     imgPreview = (
//       <img
//         src={props.isPhotoUpload}
//         alt="uploadedphoto"
//         className="single-multi-phototimelineimage"
//       />
//     );
//   }

//   return (
//     <React.Fragment>
//       <Modal
//         {...props}
//         size="md"
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       // className='Phototimeline-modal'
//       >
//         <div
//           className={` ${isSingleFile ? "Phototimeline-modal" : "Advanced-phototimelinewidth"
//             }`}
//         >
//           <Modal.Header
//             closeButton
//           // className={classes.heading}
//           >
//             <span className="post-phototimeline-heading">
//               Post Photo to Timeline
//             </span>
//           </Modal.Header>

//           <Modal.Body className="Phototimeline-modal-body">
//             <div>
//               {isSingleFile ? (
//                 <div>
//                   <div className="photo-preview">{imgPreview}</div>
//                   <div>
//                     {/* <InputTextarea placeholder="Add a description" className='photo-preview-description' /> */}
//                     <ModeratorDescriptionValidation
//                       setOverCountLimit={setOverCountLimit}
//                       postbutton="postbutton"
//                       setDescriptionText={setDescriptionText}
//                       isLocationSelect={isLocationSelect}
//                       setLat={setLat}
//                       setLng={setLng}
//                     />
//                     {/* <div className='phototimeline-location-tag'>
//                                                 <LocationTag />
//                                                 <div><label>0/200</label></div>
//                                             </div> */}
//                     <span
//                       className="Delete-Photo-icon"
//                       onClick={() => onPhotoRemove()}
//                     >
//                       <img src={CloseButton} alt="remove" />
//                     </span>
//                   </div>
//                 </div>
//               ) : (
//                 <div className="multifile-upload">
//                   {(isFile || []).map((url, index) => (
//                     <div className="multiphoto-main" id={`multiphoto${index}`}>
//                       <div className="multiphoto-preview">
//                         <img
//                           src={url}
//                           alt="..."
//                           className="single-multi-phototimelineimage"
//                         />
//                       </div>
//                       <div>
//                         <ModeratorDescriptionValidation
//                           index={index}
//                           setOverCountLimit={setOverCountLimit}
//                           postbutton="postbutton"
//                           setDescriptionText={setDescriptionText}
//                           isLocationSelect={isLocationSelect}
//                           setLat={setLat}
//                           setLng={setLng}
//                         />
//                         {/* <InputTextarea placeholder="Add a description" className='photo-preview-description multi-photopreviewdescription' /> */}

//                         <span
//                           className="multiphoto-delete"
//                           onClick={() => onPhotoRemove(index)}
//                         >
//                           <img src={Deletephoto} alt="remove" />
//                         </span>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               )}
//             </div>
//           </Modal.Body>
//           <Modal.Footer>
//             {/* <AddPhotoText onPhotoUpload={uploadMultipleFiles} /> */}
//             <span className="AddPhotoLink Multiphoto-addlink">
//               <img src={Add} />
//               {/* Add Photo */}
//               <label for="AddMultiplePhoto" className="AddPhotoLabel">
//                 Add Photo
//               </label>
//               <input
//                 id="AddMultiplePhoto"
//                 className="AddPhotoInput"
//                 type="file"
//                 onChange={uploadMultipleFiles}
//                 multiple
//               ></input>
//             </span>
//             <Button onClick={(e) => onPhotoUploadSubmit(e)} id="postbutton">
//               Post
//             </Button>
//           </Modal.Footer>
//         </div>
//       </Modal>
//     </React.Fragment>
//   );
// }

