import React, { useState } from "react";
import { Link } from "react-router-dom";
import Communities from "./Communites/Communites";
import AddCommunity from "src/Assets/images/AddCommunity.png";
import "./Community.css";
import StartCommunities from "./Start Communities/StartCommunities";

function Community() {
  const [openStartCommunity, setOpenStartCommunity] = useState(false);

  const startCommunity = () => {
    setOpenStartCommunity(true);
  };
  const close = () => {
    setOpenStartCommunity(false);
  };

  return (
    <>
      <div className="communitiesMainContainer">
        <Communities />
        <div className="CommunityActions">
          <img src={AddCommunity} alt="AddOption" />
          <Link to="/communities" className={`dark-gray`}>
            Follow more communities
          </Link>
        </div>
        <div className="CommunityActions" onClick={startCommunity}>
          <img src={AddCommunity} alt="AddOption" />
          <p className={`dark-gray`}>Start a community</p>
        </div>
      </div>
      {openStartCommunity && (
        <StartCommunities signIn={true} open={openStartCommunity} close={close} />
      )}
    </>
  );
}

export default Community;
