import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "src/Assets/images/LandingPageImages/skopic-image.png";
import settingsStyles from "src/Assets/css/settings/settings.module.css";
import ASKabout from "src/Assets/images/ASKabout.svg";
import SAYabout from "src/Assets/images/SAYabout.svg";
import possibleanswersASK from "src/Assets/images/possibleanswersASK.svg";
import possibleanswersSAY from "src/Assets/images/possibleanswersSAY.svg";
import TaggedEvents from "src/Assets/images/TaggedEvents.svg";
import ASKquestionsAbout from "src/Assets/images/ASKquestionsAbout.svg";
import Notifications from "src/pages/Notifications";
import HeaderAfterSignIn from "../../Components/Header/header";
import Cookies from "js-cookie";
import "./index.css";

function TermsofService() {
  return (
    <div className="container content-container">
      <div className="innercontainer">
        <div className="main-heading-container">
          <h3 className="main-heading">Terms Of Service</h3>
        </div>
        <div className={settingsStyles.TermsofService}>
          <b>These Services are operated and provided by Skopic Inc.</b>
          <br />
          These Terms of Service (“Terms”) govern your access to and use of the
          services and Skopic’s websites (the “Services”), and any information,
          <br />
          text, graphics, photos or other materials uploaded, downloaded or
          appearing on the Services (collectively referred to as “Content”).
          Your access
          <br />
          to and use of the Services is conditioned on your acceptance of and
          compliance with these Terms. By accessing or using the Services you
          agree <br />
          to be bound by these Terms. By “use” we mean access, use, copy,
          publicly perform or display, distribute, modify, translate, and create
          derivative
          <br />
          works of.
          <br />
          <b>Basic Terms</b>
          <br />
          You are responsible for your use of the Services, for any Content you
          post to the Services, and for any consequences thereof. The Content
          you
          <br />
          submit, post, or display will be able to be viewed by other users of
          the Services and through third party services and websites. You should
          only
          <br />
          provide Content that you are comfortable sharing with others under
          these Terms.
          <br />
          Thus, what you say on Skopic may be viewed all around the world
          instantly.
          <br />
          You may use the Services only if you can form a binding contract with
          Skopic and are not a person barred from receiving services under the
          laws
          <br />
          of the United States or other applicable jurisdiction. You may use the
          Services only in compliance with these Terms and all applicable local,
          state,
          <br />
          national, and international laws, rules and regulations.
          <br />
          The Services that Skopic provides are always evolving and the form and
          nature of the Services that Skopic provides may change from time to
          <br />
          time without prior notice to you. In addition, Skopic may stop
          (permanently or temporarily) providing the Services (or any features
          within the <br />
          Services) to you or to users generally and may not be able to provide
          you with prior notice. We also retain the right to create limits on
          use and <br />
          storage at our sole discretion at any time without prior notice to
          you.
          <br />
          The Services may include advertisements that are targeted through
          queries made through the Services, or other information. The types and{" "}
          <br />
          extent of advertising by Skopic on the Services are subject to change.
          In consideration for Skopic granting you access to and use of the{" "}
          <br />
          Services, you agree that Skopic and its third party providers and
          partners may place such advertising on the Services or in connection
          with the <br />
          display of Content or information from the Services whether submitted
          by you or others.
          <br />
          Your continued use of the Services represents full agreement with
          these Terms as they now exist and as they may be amended or changed.
          <br />
          <b>Privacy Policy:</b>
          <br />
          Any information that you provide to Skopic is subject to the Privacy
          Policy stated under “Policy” on our website, which governs our
          collection and <br />
          use of your information. You understand that through your use of the
          Services you consent to the collection and use (as set forth in the
          Privacy <br />
          Policy) of this information, including the transfer of this
          information to the United States and/or other countries for storage,
          processing and use by
          <br />
          Skopic. As part of providing you the Services, we may need to provide
          you with certain communications, such as service announcements and{" "}
          <br />
          administrative messages.These communications are considered part of
          the Services and your Skopic account, which you may not be able to{" "}
          <br />
          opt-out from receiving.
          <br />
          <b>Passwords</b>
          <br />
          You are responsible for safeguarding the password that you use to
          access the Services and for any activities or actions under your
          password. <br />
          We encourage you to use “strong” passwords (passwords that use a
          combination of upper and lower case letters, numbers and symbols) with{" "}
          <br />
          your account. Skopic cannot and will not be liable for any loss or
          damage arising from your failure to comply with the above
          requirements.
          <br />
          <b>Content on the Services</b>
          <br />
          All Content, whether publicly posted or privately transmitted, is the
          sole responsibility of the person who originated such Content. We may
          not <br />
          monitor or control the Content posted via the Services and, we cannot
          take responsibility for such Content. Any use or reliance on any
          Content <br />
          or materials posted via the Services or obtained by you through the
          Services is at your own risk.
          <br />
          We do not endorse, support, represent or guarantee the completeness,
          truthfulness, accuracy, or reliability of any Content or
          communications <br />
          posted via the Services or endorse any opinions expressed via the
          Services. You understand that by using the Services, you may be
          exposed to <br />
          Content that might be offensive, harmful,inaccurate or otherwise
          inappropriate, or in some cases, postings that have been mislabeled or
          are <br />
          otherwise deceptive. Under no circumstances will Skopic be liable in
          any way for any Content, including, but not limited to, any errors or{" "}
          <br />
          omissions in any Content, or any loss or damage of any kind incurred
          as a result of the use of any Content posted, emailed, transmitted or{" "}
          <br />
          otherwise made available via the Services or broadcast elsewhere.
          <br />
          <b>Your Rights</b>
          <br />
          You retain your rights to any Content you submit, post or display on
          or through the Services. By submitting, posting or displaying Content
          on or <br />
          through the Services,you grant us a worldwide, non-exclusive,
          royalty-free license (with the right to sublicense) to use, copy,
          reproduce, <br />
          process, adapt, modify, publish, transmit, display and distribute such
          Content in any and all media or distribution methods (now known or{" "}
          <br />
          later developed). Thus, this license authorizes us to make your
          information available to the rest of the world and to let others do
          the same.
          <br />
          You agree that this license includes the right for Skopic to make such
          Content available to other companies, organizations or individuals who{" "}
          <br />
          partner with Skopic for the syndication, broadcast, distribution or
          publication of such Content on other media and services, subject to
          our terms <br />
          and conditions for such Content use.
          <br />
          Skopic has an evolving set of rules for how ecosystem partners can
          interact with your content. Such additional uses by Skopic, or other{" "}
          <br />
          companies, organizations or individuals who partner with Skopic, may
          be made with no compensation paid to you with respect to the Content{" "}
          <br />
          that you submit, post, transmit or otherwise make available through
          the Services.
          <br />
          We may modify or adapt your Content in order to transmit, display or
          distribute it over computer networks and in various media and/or make{" "}
          <br />
          changes to your Content as are necessary to conform and adapt that
          Content to any requirements or limitations of any networks, devices,{" "}
          <br />
          services or media.
          <br />
          You are responsible for your use of the Services, for any Content you
          provide, and for any consequences thereof, including the use of your{" "}
          <br />
          Content by other users and our third party partners. You understand
          that your Content may be rebroadcasted by our partners and if you do
          not <br />
          have the right to submit Content for such use, it may subject you to
          liability. Skopic will not be responsible or liable for any use of
          your Content by <br />
          Skopic in accordance with these Terms. You represent and warrant that
          you have all the rights, power and authority necessary to grant the
          rights <br />
          granted herein to any Content that you submit.
          <br />
          Skopic gives you a personal, worldwide, royalty-free, non-assignable
          and non-exclusive license to use the software that is provided to you
          by <br />
          Skopic as part of the Services. This license is for the sole purpose
          of enabling you to use and enjoy the benefit of the Services as
          provided by <br />
          Skopic, in the manner permitted by these Terms.
          <br />
          <b>Skopic Rights</b>
          <br />
          All right, title, and interest in and to the Services (excluding
          Content provided by users) are and will remain the exclusive property
          of Skopic and <br />
          its licensors. The Services may be protected by copyright, trademark,
          and other laws of both the United States and foreign countries.
          Nothing in
          <br />
          the Terms gives you a right to use the Skopic name or any Skopic
          trademark, logo, domain name, and other distinctive brand features.
          Any <br />
          feedback, comments, or suggestions you may provide regarding Skopic,
          or the Services is entirely voluntary and we will be free to use such
          <br />
          feedback, comments or suggestions as we see fit and without any
          obligation to you.
          <br />
          <b>Restrictions on Content and Use of the Services</b>
          <br />
          We reserve the right at all times (but will not have an obligation) to
          remove or refuse to distribute any Content on the Services and to
          terminate
          <br />
          users or reclaim usernames. We also reserve the right to access, read,
          preserve, and disclose any information as we reasonably believe is
          <br />
          necessary to
          <br />
          (i) satisfy any applicable law, regulation, legal process or
          governmental request,
          <br />
          (ii) enforce the Terms, including investigation of potential
          violations hereof,
          <br />
          (iii) detect, prevent, or otherwise address fraud, security or
          technical issues,
          <br />
          (iv) respond to user support requests, or
          <br />
          (v) protect the rights, property or safety of Skopic, its users and
          the public.
          <br />
          <b>
            You may not do any of the following while accessing or using the
            Services:
          </b>
          <br />
          (i) access, tamper with, or use non-public areas of the Services,
          Skopic’s computer systems, or the technical delivery systems of
          Skopic’s
          <br />
          providers;
          <br />
          (ii) probe, scan, or test the vulnerability of any system or network
          or breach or circumvent any security or authentication measures;
          <br />
          (iii) access or search or attempt to access or search the Services by
          any means (automated or otherwise) other than through our currently{" "}
          <br />
          available, published interfaces that are provided by Skopic (and only
          pursuant to those terms and conditions), unless you have been
          specifically <br />
          allowed to do so in a separate agreement with Skopic (NOTE: crawling
          the Services is permissible if done in accordance with the provisions
          of
          <br />
          the robots.txt file, however, scraping the Services without the prior
          consent of Skopic is expressly prohibited);
          <br />
          (iv) forge any TCP/IP packet header or any part of the header
          information in any email or posting, or in any way use the Services to
          send altered,
          <br />
          deceptive or false source-identifying information; or
          <br />
          (v) interfere with, or disrupt, (or attempt to do so), the access of
          any user, host or network, including, without limitation, sending a
          virus,
          <br />
          overloading, flooding, spamming, mail-bombing the Services, or by
          scripting the creation of Content in such a manner as to interfere
          with or
          <br />
          create an undue burden on the Services.
          <br />
          <b>Copyright Policy</b>
          <br />
          Skopic respects the intellectual property rights of others and expects
          users of the Services to do the same. We may respond to notices of{" "}
          <br />
          alleged copyright infringement that comply with applicable law and are
          properly provided to us. If you believe that your Content has been
          copied
          <br />
          in a way that constitutes copyright infringement, please provide us
          with the following information:
          <br />
          (i) a physical or electronic signature of the copyright owner or a
          person authorized to act on their behalf;
          <br />
          (ii) identification of the copyrighted work claimed to have been
          infringed;
          <br />
          (iii) identification of the material that is claimed to be infringing
          or to be the subject of infringing activity and that is to be removed
          or access to
          <br />
          which is to be disabled, and information reasonably sufficient to
          permit us to locate the material;
          <br />
          (iv) your contact information, including your address, telephone
          number, and an email address;
          <br />
          (v) a statement by you that you have a good faith belief that use of
          the material in the manner complained of is not authorized by the
          copyright <br />
          owner, its agent, or the law; and
          <br />
          (vi) a statement that the information in the notification is accurate,
          and, under penalty of perjury, that you are authorized to act on
          behalf of the
          <br />
          copyright owner.
          <br />
          We reserve the right to remove Content alleged to be infringing
          without prior notice and at our sole discretion. In appropriate
          circumstances,
          <br />
          Skopic will also terminate a user’s account if the user is determined
          to be a repeat infringer.
          <br />
          <b>The Services are Available "AS-IS"</b>
          <br />
          Your access to and use of the Services or any Content is at your own
          risk. You understand and agree that the Services is provided to you on
          an
          <br />
          "AS IS" and "AS AVAILABLE" basis. Without limiting the foregoing,
          SKOPIC AND ITS PARTNERS DISCLAIM ANY WARRANTIES, EXPRESS
          <br />
          OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT. We make no warranty and
          <br />
          disclaim all responsibility and liability for the completeness,
          accuracy, availability, timeliness, security or reliability of the
          Services or any content
          <br />
          thereon. Skopic will not be responsible or liable for any harm to your
          computer system, loss of data, or other harm that results from your
          access
          <br />
          to or use of the Services, or any Content. You also agree that Skopic
          has no responsibility or liability for the deletion of, or the failure
          to store or
          <br />
          to transmit, any Content and other communications maintained by the
          Services. We make no warranty that the Services will meet your
          <br />
          requirements or be available on an uninterrupted, secure, or
          error-free basis. No advice or information, whether oral or written,
          obtained from
          <br />
          Skopic or through the Services, will create any warranty not expressly
          made herein.
          <br />
          <b>Limitation of Liability</b>
          <br />
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SKOPIC AND ITS
          SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES,
          <br />
          AGENTS, PARTNERS AND LICENSORS WILL NOT BE LIABLE FOR ANY DIRECT,
          INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL
          <br />
          OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS,
          DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE
          <br />
          LOSSES, RESULTING FROM
          <br />
          (i) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE
          SERVICES;
          <br />
          (ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES,
          INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY,
          <br />
          OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES;
          <br />
          (iii) ANY CONTENT OBTAINED FROM THE SERVICES; AND
          <br />
          (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR
          CONTENT, WHETHER BASED ON WARRANTY,
          <br />
          CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY,
          WHETHER OR NOT SKOPIC HAS BEEN INFORMED OF
          <br />
          THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN
          IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
          <br />
          PURPOSE.
          <br />
          <b>Waiver and Severability</b>
          <br />
          The failure of Skopic to enforce any right or provision of these Terms
          will not be deemed a waiver of such right or provision. In the event
          that any
          <br />
          provision of these Terms is held to be invalid or unenforceable, the
          remaining provisions of these Terms will remain in full force and
          effect.
          <br />
          <b>Links</b>
          The Services may contain links to third-party websites or resources.
          You acknowledge and agree that we are not responsible or liable for:
          <br />
          (i) the availability or accuracy of such websites or resources; or
          <br />
          (ii) the content, products, or services on or available from such
          websites or resources. Links to such websites or resources do not
          imply any
          <br />
          endorsement by Skopic of such websites or resources or the content,
          products, or services available from such websites or resources. You
          <br />
          acknowledge sole responsibility for and assume all risk arising from
          your use of any such websites or resources.
          <br />
          <b>Controlling Law and Jurisdiction</b>
          <br />
          These Terms and any action related thereto will be governed by the
          laws of the State of California without regard to or application of
          its conflict of
          <br />
          law provisions or your state or country of residence. All claims,
          legal proceedings or litigation arising in connection with the
          Services will be
          <br />
          brought solely in Santa Clara County, California, and you consent to
          the jurisdiction of and venue in such courts and waive any objection
          as to
          <br />
          inconvenient forum. If you are accepting these Terms on behalf of a
          United States federal government entity that is legally unable to
          accept the
          <br />
          controlling law, jurisdiction or venue clauses above, then those
          clauses do not apply to you but instead these Terms and any action
          related
          <br />
          thereto will be will be governed by the laws of the United States of
          America (without reference to conflict of laws) and, in the absence of
          federal
          <br />
          law and to the extent permitted under federal law, the laws of the
          State of California (excluding choice of law).
          <br />
          <b>Safety</b>
          <br />
          We do our best to keep Skopic safe, but we cannot guarantee it. We
          need your help to do that, which includes the following commitments:
          <br />
          1. You will not send or otherwise post unauthorized commercial
          communications (such as spam) on Skopic.
          <br />
          2. You will not collect users' content or information, or otherwise
          access Skopic, using automated means (such as harvesting bots, robots,
          <br />
          spiders, or scrapers) without our permission.
          <br />
          3. You will not engage in unlawful multi-level marketing, such as a
          pyramid scheme, on Skopic.
          <br />
          4. You will not upload viruses or other malicious code.
          <br />
          5. You will not solicit login information or access an account
          belonging to someone else.
          <br />
          6. You will not bully, intimidate, or harass any user.
          <br />
          7. You will not post content that: is hateful, threatening, or
          pornographic; incites violence; or contains nudity or graphic or
          gratuitous violence.
          <br />
          8. You will not develop or operate a third-party application
          containing alcohol-related or other mature content (including
          advertisements) without
          <br />
          appropriate age-based restrictions.
          <br />
          9. You will not use Skopic to do anything unlawful, misleading,
          malicious, or discriminatory.
          <br />
          10. You will not do anything that could disable, overburden, or impair
          the proper working of Skopic, such as a denial of service attack.
          <br />
          11. You will not facilitate or encourage any violations of this
          agreement.
          <br />
          <b>Registration and Account Security</b>
          <br />
          Skopic users provide their real names and information, and we need
          your help to keep it that way. Here are some commitments you make to
          us
          <br />
          relating to registering and maintaining the security of your account:
          <br />
          1. You will not provide any false personal information on Skopic, or
          create an account for anyone other than yourself without permission.
          <br />
          2. You will not create more than one personal profile.
          <br />
          3. If we disable your account, you will not create another one without
          our permission.
          <br />
          4. You will not use your personal profile for your own commercial gain
          (such as selling your status update to an advertiser).
          <br />
          5. You will not use Skopic if you are under 13.
          <br />
          6. You will not use Skopic if you are a convicted sex offender.
          <br />
          7. You will keep your contact information accurate and up-to-date.
          <br />
          8. You will not share your password, (or in the case of developers,
          your secret key), let anyone else access your account, or do anything
          else
          <br />
          that might jeopardize the security of your account.
          <br />
          9. You will not transfer your account (including any page or
          application you administer) to anyone without first getting our
          written permission.
          <br />
          10. If you select a username for your account we reserve the right to
          remove or reclaim it if we believe appropriate (such as when a
          trademark
          <br />
          owner complains about a username that does not closely relate to a
          user's actual name).
          <br />
          <b>Protecting Other People's Rights</b>
          <br />
          We respect other people's rights, and expect you to do the same.
          <br />
          1. You will not post content or take any action on Skopic that
          infringes or violates someone else's rights or otherwise violates the
          law.
          <br />
          2. We can remove any content or information you post on Skopic if we
          believe that it violates this Statement.
          <br />
          3. If you repeatedly infringe other people's intellectual property
          rights, we will disable your account when appropriate.
          <br />
          4. You will not use our intellectual property, copyrights or
          trademarks or any confusingly similar marks, without our written
          permission.
          <br />
          5. If you collect information from users, you will: obtain their
          consent, make it clear you (and not Skopic) are the one collecting
          their information,
          <br />
          and post a privacy policy explaining what information you collect and
          how you will use it.
          <br />
          6. You will not post anyone's identification documents or sensitive
          financial information on Skopic.
          <br />
          7. You will not tag users or send email invitations to non-users
          without their consent.
          <br />
          <b>Special Developers/Operators Provisions</b>
          <br />
          If you are a developer or operator of Skopic, the following additional
          terms apply to you:
          <br />
          1. You are responsible for your application and its content and all
          uses you make of Skopic. This includes ensuring your application or
          use of
          <br />
          Skopic meets the terms of this agreement.
          <br />
          2. Your access to and use of data you receive from Skopic, will be
          limited as follows:
          <br />
          1. You will only request data you need to operate your application.
          <br />
          2. You will have a privacy policy that tells users what user data you
          are going to use and how you will use, display, share, or transfer
          that data.
          <br />
          3. You will not use, display, share, or transfer a user’s data in a
          manner inconsistent with your privacy policy.
          <br />
          4. You will delete all data you receive from us concerning a user if
          the user asks you to do so, and will provide a mechanism for users to
          make
          <br />
          such a request.
          <br />
          5. You will not include data you receive from us concerning a user in
          any advertising creative.
          <br />
          6. You will not directly or indirectly transfer any data you receive
          from us to (or use such data in connection with) any ad network, ad
          exchange,
          <br />
          data broker, or other advertising related toolset, even if a user
          consents to that transfer or use.
          <br />
          7. You will not sell user data. If you are acquired by or merge with a
          third party, you can continue to use user data within your
          application, but you
          <br />
          cannot transfer user data outside of your application.
          <br />
          8. We can require you to delete user data if you use it in a way that
          we determine is inconsistent with users’ expectations.
          <br />
          9. We can limit your access to data.
          <br />
          10. You will not give us information that you independently collect
          from a user or a user's content without that user's consent.
          <br />
          11. You will make it easy for users to remove or disconnect from your
          application.
          <br />
          12. You will make it easy for users to contact you. We can also share
          your email address with users and others claiming that you have
          infringed
          <br />
          or otherwise violated their rights.
          <br />
          13. You will provide customer support for your application.
          <br />
          14. You will not show third party ads or web search boxes on Skopic.
          <br />
          15. You will not sell, transfer, or sublicense our code, APIs, or
          tools to anyone.
          <br />
          16. You will not misrepresent your relationship with Skopic to others.
          <br />
          17. We can issue a press release describing our relationship with you.
          <br />
          18. You will comply with all applicable laws. In particular you will
          (if applicable):
          <br />
          1. have a policy for removing infringing content and terminating
          repeat infringers that complies with the Digital Millennium Copyright
          Act.
          <br />
          2. comply with the Video Privacy Protection Act (VPPA), and obtain any
          opt-in consent necessary from users so that user data subject to the
          <br />
          VPPA may be shared on Skopic. You represent that any disclosure to us
          will not be incidental to the ordinary course of your business.
          <br />
          19. We do not guarantee that Skopic will always be free.
          <br />
          20. You give us all rights necessary to enable your application to
          work with Skopic, including the right to incorporate content and
          information you
          <br />
          provide to us into streams, profiles, and user action stories.
          <br />
          21. You give us the right to link to or frame your application, and
          place content, including ads, around your application.
          <br />
          22. We can analyze your application, content, and data for any
          purpose, including commercial (such as for targeting the delivery of
          <br />
          advertisements and indexing content for search).
          <br />
          23. To ensure your application is safe for users, we can audit it.
          <br />
          24. We can create applications that offer similar features and
          services to, or otherwise compete with, your application.
          <br />
          Termination If you violate the letter or spirit of this agreement, or
          otherwise create risk or possible legal exposure for us, we can stop
          providing all
          <br />
          or part of Skopic to you.
          <br />
          <b>Disputes</b>
          <br />
          1. You will resolve any claim, cause of action or dispute (claim) you
          have with us arising out of or relating to this agreement or Skopic
          exclusively
          <br />
          in a state or federal court located in Santa Clara County. The laws of
          the State of California will govern this Statement, as well as any
          claim that
          <br />
          might arise between you and us, without regard to conflict of law
          provisions. You agree to submit to the personal jurisdiction of the
          courts located
          <br />
          in Santa Clara County, California for the purpose of litigating all
          such claims.
          <br />
          2. If anyone brings a claim against us related to your actions,
          content or information on Skopic, you will indemnify and hold us
          harmless from and
          <br />
          against all damages, losses, and expenses of any kind (including
          reasonable legal fees and costs) related to such claim.
          <br />
          3. we try to keep skopic up, bug-free, and safe, but you use it at
          your own risk. we are providing skopic as is without any express or
          implied
          <br />
          warranties including, but not limited to, implied warranties of
          merchantability, fitness for a particular purpose, and
          non-infringement. we do not
          <br />
          guarantee that skopic will be safe or secure. skopic is not
          responsible for the actions, content, information, or data of third
          parties, and you
          <br />
          release us, our directors, officers, employees, and agents from any
          claims and damages, known and unknown, arising out of or in any way
          <br />
          connected with any claim you have against any such third parties. if
          you are a california resident, you waive california civil code §1542,
          which
          <br />
          says: a general release does not extend to claims which the creditor
          does not know or suspect to exist in his favor at the time of
          executing the
          <br />
          release, which if known by him must have materially affected his
          settlement with the debtor. we will not be liable to you for any lost
          profits or
          <br />
          other consequential, special, indirect, or incidental damages arising
          out of or in connection with this statement or skopic, even if we have
          been
          <br />
          advised of the possibility of such damages. our aggregate liability
          arising out of this statement or skopic will not exceed the greater of
          one
          <br />
          hundred dollars ($100) or the amount you have paid us in the past
          twelve months. applicable law may not allow the limitation or
          exclusion of
          <br />
          liability or incidental or consequential damages, so the above
          limitation or exclusion may not apply to you. in such cases, skopic's
          liability will be
          <br />
          limited to the fullest extent permitted by applicable law.
          <br />
          <b>
            Special Provisions Applicable to Users Outside the United States
          </b>
          <br />
          We strive to create a global community with consistent standards for
          everyone, but we also strive to respect local laws.
          <br />
          <b>
            The following provisions apply to users outside the United States:
          </b>
          <br />
          1. You consent to having your personal data transferred to and
          processed in the United States.
          <br />
          2. If you are located in a country embargoed by the United States, or
          are on the U.S. Treasury Department's list of Specially Designated
          <br />
          Nationals you will not engage in commercial activities on Skopic (such
          as advertising or payments) or operate an application or website.
          <br />
          <b>Change of Control</b>
          <br />
          If the ownership of our business changes, we may transfer your
          information to the new owner so they can continue to operate the
          service. But
          <br />
          they will still have to honor the commitments we have made in this
          privacy policy.
          <br />
          <b>Other</b>
          <br />
          1. You will comply with all applicable laws when using or accessing
          Skopic.
          <br />
          2. If any portion of this Statement is found to be unenforceable, the
          remaining portion will remain in full force and effect.
          <br />
          3. If we fail to enforce any of this Statement, it will not be
          considered a waiver.
          <br />
          4. Any amendment to or waiver of this Statement must be made in
          writing and signed by us.
          <br />
          5. You will not transfer any of your rights or obligations under this
          Statement to anyone else without our consent.
          <br />
          6. All of our rights and obligations under this Statement are freely
          assignable by us in connection with a merger, acquisition, or sale of
          assets, or
          <br />
          by operation of law or otherwise.
          <br />
          7. Nothing in this Statement shall prevent us from complying with the
          law.
          <br />
          <b>Entire Agreement</b>
          <br />
          These Terms and our Privacy Policy are the entire and exclusive
          agreement between Skopic and you regarding the Services (excluding any
          <br />
          services for which you have a separate agreement with Skopic that is
          explicitly in addition or in place of these Terms), and these Terms
          <br />
          supersede and replace any prior agreements between Skopic and you
          regarding the Services. Other than companies of which Skopic is the
          <br />
          parent or an affiliate, no other person or company will be third party
          beneficiaries to the Terms. We may revise these Terms from time to
          time. By
          <br />
          continuing to access or use the Services after those revisions become
          effective, you agree to be bound by the revised Terms.
        </div>
      </div>
    </div>
  );
}

function PrivacyPolicyContent() {
  return (
    <div className="container content-container">
      <div className="innercontainer">
        <div className="main-heading-container">
          <h3 className="main-heading">Privacy Policy</h3>
        </div>
        <div className={settingsStyles.TermsofService}>
          <b>Information that is always publicly available</b>
          <br />
          Your user name is always publicly available, and is treated just like
          information you decided to make public.
          <br />
          <b>How we use the information we receive</b>
          <br />
          We may use the information we receive about you in connection with the
          services and features we provide to you and other users, the
          <br />
          advertisers that purchase ads on the site, and the developers that
          build the games, applications, and websites you use.For example, we
          may
          <br />
          use the information we receive about you:
          <br />
          1. As part of our efforts to keep Skopic safe and secure;
          <br />
          2. To provide you with location features and services;
          <br />
          3. To measure or understand the effectiveness of ads you and others
          see;
          <br />
          4. To make suggestions to you and other users on Skopic.
          <br />
          <b>About ASK, SAY and Comments</b>
          <br />
          Skopic pages containing "ASK", "SAY" and comments to those statements
          are public.Companies may use those pages to share information
          <br />
          about their products.Communities may use pages to discuss topics of
          interest, everything from baseball to the opera.Because these pages
          are <br />
          public , information you share is public information.This means, for
          example, that if you post a comment, that comment may be used off of
          <br />
          Skopic, and anyone can see it.As a general rule, you should assume
          that the information will be publicly available.
          <br />
          <b>Sharing Information</b>
          <br />
          Skopic may help you share your information with the games,
          applications, and websites you use.Remember that these games,
          applications and
          <br />
          websites may be created and maintained by other businesses and
          developers who are not part of Skopic, so you should always make sure
          to
          <br />
          read their terms of service and privacy policies.
          <br />
          <b>Logging in to another site using Skopic</b>
          <br />
          Skopic may also let you log into other applications and websites using
          your Skopic account.If you already have an account on that website,
          the <br />
          site may also be able to connect that account with your Skopic
          account.
          <br />
          {/* <b>
            Logging in to Skopic using other applications (ex.Facebook, Twitter)
          </b>
          <br />
          Skopic may also let you log in using other applications such as
          Facebook, Twitter if you are a signed user of that website.In such
          situations,
          <br />
          Skopic will have access to your profile information, friends’ network
          and other posts as authorized by you and as per information access
          <br />
          guidelines of that respective service, further Skopic may also post
          and receive updates on your behalf should you choose to do so.However,
          you
          <br />
          will have choice to turn on or off such settings.
          <br /> */}
          <b>Automatic personalization</b>
          <br />
          Skopic may help partners with automatic personalization to create a
          more personalized and social experience.When you visit a site using
          <br />
          automatic personalization, it may know some information about you and
          your friends and/or acquaintances the moment you arrive.This is
          <br />
          because automatic personalization sites may have access to your User
          ID, among other things.
          <br />
          <b>Public search engines</b>
          <br />
          Your Public Search setting controls whether people who enter your name
          on a public search engine may see your public profile (including in
          <br />
          sponsored results).
          <br />
          <b>Ads</b>
          <br />
          When an advertiser creates an ad on Skopic, they may be given the
          opportunity to choose their audience by location, demographics, likes,
          <br />
          keywords, and any other information or can tell about you and other
          users.For example, an advertiser can choose to target 18 to 45
          year-old
          <br />
          women who live in the United States and like basketball.
          <br />
          If the advertiser chooses to run the ad, we may serve the ad to people
          who meet the criteria the advertiser selected.Advertisers sometimes
          <br />
          place cookies on your computer in order to make their ads more
          effective.
          <br />
          We may allow advertisers to target a category of user, like a
          "moviegoer" or a "sci-fi fan."
          <br />
          <b>Responding to legal requests and preventing harm</b>
          <br />
          We may share your information in response to a legal request (like a
          search warrant, court order or subpoena) if we have a good faith
          belief that
          <br />
          the law requires us to do so.This may include responding to legal
          requests from jurisdictions outside of the United States where we have
          a good
          <br />
          faith belief that the response is required by law in that
          jurisdiction, affects users in that jurisdiction, and is consistent
          with internationally
          <br />
          recognized standards.We may also share information when we have a good
          faith belief it is necessary to detect, prevent and address fraud and
          <br />
          other illegal activity; and to prevent death or imminent bodily harm.
          <br />
          <b>Notifications and Other Messages</b>
          <br />
          We may send you notifications and other messages using the contact
          information we have for you, like your email address.You can control
          most
          <br />
          of the notifications you receive, including ones from Pages you like
          and applications you use, using your Notifications settings.
          <br />
          <b>Cookies</b>
          <br />
          Cookies are small pieces of data that we store on your computer,
          mobile phone or other device to make Skopic easier to use, make our
          <br />
          advertising better, and to protect you (and Skopic).For example, we
          may use them to know you are logged in to Skopic, to help you use
          social
          <br />
          plugins and share buttons, or to know when you are interacting with
          our advertising partners.We may also ask advertisers to serve ads to{" "}
          <br />
          computers, mobile phones or other devices with a cookie placed by
          Skopic (although we would not share any other information with that
          <br />
          advertiser).Most companies on the web use cookies (or similar
          technological methods), including our advertising partners.You can
          always
          <br />
          remove or block cookies (such as by using the settings in your
          browser), but it may affect your ability to use Skopic.
          <br />
          <b>Service Providers</b>
          <br />
          We give your information to the people and companies that help us
          provide the services we offer.For example, we may use outside vendors
          to
          <br />
          help host our website, serve photos and videos, process payments, or
          provide search results.In some cases we provide the service jointly
          with
          <br />
          another company.
          <br />
          <b>Registration and Account Security</b>
          <br />
          Skopic users provide their real names and information, and we need
          your help to keep it that way.Here are some commitments you make to us
          <br />
          relating to registering and maintaining the security of your account:
          <br />
          1.You will not provide any false personal information on Skopic, or
          create an account for anyone other than yourself without permission.
          <br />
          2. You will not create more than one personal profile.
          <br />
          3. If we disable your account, you will not create another one without
          our permission.
          <br />
          4. You will not use your personal profile for your own commercial gain
          (such as selling your status update to an advertiser).
          <br />
          5. You will not use Skopic if you are under 13.
          <br />
          6. You will not use Skopic if you are a convicted sex offender.
          <br />
          7. You will keep your contact information accurate and up-to-date.
          <br />
          8. You will not share your password, (or in the case of developers,
          your secret key), let anyone else access your account, or do anything
          else
          <br />
          that might jeopardize the security of your account.
          <br />
          9. You will not transfer your account (including any page or
          application you administer) to anyone without first getting our
          written permission.
          <br />
          10. If you select a username for your account we reserve the right to
          remove or reclaim it if we believe appropriate (such as when a
          trademark
          <br />
          owner complains about a username that does not closely relate to a
          user's actual name).
          <br />
          <b>Protecting Other People's Rights</b>
          <br />
          We respect other people's rights, and expect you to do the same.
          <br />
          1. You will not post content or take any action on Skopic that
          infringes or violates someone else's rights or otherwise violates the
          law.
          <br />
          2. We can remove any content or information you post on Skopic if we
          believe that it violates this Statement.
          <br />
          3. If you repeatedly infringe other people's intellectual property
          rights, we will disable your account when appropriate.
          <br />
          4. You will not use our intellectual property, copyrights or
          trademarks or any confusingly similar marks, without our written
          permission.
          <br />
          5. If you collect information from users, you will: obtain their
          consent, make it clear you (and not Skopic) are the one collecting
          their information,
          <br />
          and post a privacy policy explaining what information you collect and
          how you will use it.
          <br />
          6. You will not post anyone's identification documents or sensitive
          financial information on Skopic.
          <br />
          7. You will not tag users or send email invitations to non-users
          without their consent.
          <br />
        </div>
      </div>
    </div>
  );
}

function UserGuideLines() {
  return (
    <div className="container content-container">
      <div className="innercontainer">
        <div className="main-heading-container">
          <h3 className="main-heading">User Guidelines</h3>
        </div>
        <div className={settingsStyles.TermsofService}>
          We welcome your questions, comments and concerns. Our goal is to
          maintain a social platform for online local communities, where people
          can
          <br />
          share; knowledge, thoughts and timely information and ask helpful
          information.
          <br />
          Please review, understand and comply with terms of use provided on the
          website and mobile applications
          <br />
          Take active participation to contribute on timely and essential
          matters so your communities remain more engaged and informed. The more
          <br />
          knowledge or thoughts you share, the stronger would be your community
          knowledge base. Using Skopic’s Information Networking engine, your
          <br />
          peers can almost find instant answers if your community knowledge gets
          broader and deeper.
          <br />
          Do not post casual messages or any unrelated community matters. Skopic
          communities are strictly for sharing and networking with local and
          real
          <br />
          matters, it does not facilitate any form of social networking or
          presenting out of context matters.
          <br />
          We strictly discourage users initiating any continuous conversations
          as Skopic communities are a platform to share or connect with
          microblogs
          <br />
          that are informational in nature.
          <br />
          Please use your clear discretion in classifying as to whether your
          post belongs to; Question Category [ASK], a free expression of sharing
          <br />
          Knowledge [SAY], a direct Response to any ASK [SAY], Urgent or
          Important community Update [UPDATE] or a Post for a #TAG SAY.
          <br />
          Please do remember that when you share Updates or Create a new #TAG
          for any activity or topic, Skopic notifies all your community members,
          <br />
          so exercise your discretion and caution if you have made a choice when
          doing either kind.
          <br />
          BE RESPECTFUL. We are committed to respect for the dignity of others,
          so do not oppose or directly contradict on other posts or
          contributions,
          <br />
          please only express or share your thoughts or perspectives.
          <br />
          Do not post material that infringes on the rights of any third party,
          including intellectual property, privacy or publicity rights.
          <br />
          Do not post messages that are inappropriate, unlawful, obscene,
          defamatory, threatening or harassing about any individuals, groups,
          <br />
          organizations or beliefs.
          <br />
          Do not Post the same note in a repeated manner (“spam”).
          <br />
          Do not Post any content or material that can potentially lead to
          heated exchanges or debates or arguments with other users of Skopic.
          <br />
          Do not Impersonate another person.
          <br />
          Do not Allow any other person or entity to use your identification for
          posting or viewing.
          <br />
          All your posts are visible to all Skopic users irrespective of any
          community they may belong to, so there is no restriction to view or
          flag your post
          <br />
          by any other user.
          <br />
          We reserve the right to remove posts that do not comply with our
          community guidelines.
          <br />
          Community Moderator Role:
          <br />
          In addition to reviewing Skopic privacy, terms of use and user
          guidelines, please note these additional aspects for leading to
          moderate your
          <br />
          community.
          <br />
          The most important things to adopt to be a successful moderator are
          <br />
          1.Must be a good community actor, embrace the role of a deeply
          passionate community member in the community.
          <br />
          2.Keep researching communities you are moderating, on the internet,
          social media and other traditional media so you are well informed to
          <br />
          moderate. 3.Classify and organize the content most appropriately.
          <br />
          4.Get the best message out in 140 character Microblogs. Microblogging
          off long stories and articles is a powerful skill to develop and
          benefit your
          <br />
          communities. 5. Keep the users’ interest going by keeping these
          communities live and current with positive activity.
          <br />
          6.Remove all the posts that are flagged abusive either instantly or
          periodically upon your discretion at the time.
          <br />
          7.If this is an invite only community, then please approve or reject
          user invitations in a timely manner.
          <br />
          8.Choose responsible and engaged members as additional moderators.
          <br />
          9.Periodically revisit the rules for users to post messages, because
          the very fact Skopic is a community sourced information network is
          defined
          <br />
          by how much your users are allowed or not allowed to post without any
          pre-approval.
          <br />
          Quick directions for driving user engagement and participation.
          <br />
          1.Add Questions and / or Answers (ASKs and SAYs to ASKs) on a periodic
          basis, at least 3 to 4 times a week.
          <br />
          2.Create TAGS for key issues, topics, activities or sub-groups and
          post related information, at least 10 to 15 posts per tag (spread it
          over 3 to 5<br />
          days). 3.Try and create a new TAG in each community once in 5 to 7
          days.
          <br />
          4. Post 1 UPDATE each community every day, the updates must not exceed
          3 per day as it could be intimidating to users. Do not forget to add
          <br />
          community reminders if updates involve deadlines.
          <br />
          5.Post SAYs to Express thoughts or share knowledge. This is extremely
          critical to finding instant answers to the most pressing questions
          either
          <br />
          in the current time or in the future. Try and add at least 50 SAY
          messages for this category.
          <br />
          6.Always, review, reach out and encourage your, top 1o contributors,
          top 25 people in SAY and ASK categories which are listed on your
          <br />
          Community main page.
          <br />
          7.Most importantly remember that UPDATES and #TAGS when created are
          sent out to all the community interest, so these features stickiness
          <br />
          and user engagement.
          <br />
          8.Best of all, you understand your community the best, so your
          discretion may overrule some or all of these stated guidelines.
          <br />
          Thank You.
          <br />
        </div>
      </div>
    </div>
  );
}

function About() {
  return (
    <div className="container content-container">
      <div className="innercontainer">
        <div className="main-heading-container">
          <h3 className="main-heading">About</h3>
        </div>
        <div className={settingsStyles.TermsofService}>
          Skopic Mission is to promote Knowledge Sharing and Real Matters in
          Local Communities.
          <br />
          <br />
          Skopic = Sharing Knowledge Of People In Communities
          <br />
          <br />
          Skopic vision solves the critical missing use case totally ignored by
          the clamored social media. It is a new paradigm to
          <br />
          develop more informed, engaged and real time communities.
          <br />
          <br />
          Skopic develops local information network through community sourced
          clutter-free information. It’s a community app that
          <br />
          emphasizes social learning through information sharing and knowledge
          networking rather than people networking or
          <br />
          personal matters most social applications thrive upon.
          <br />
          <br />
          On Skopic:
          <br />
          <p className={settingsStyles.aboutText}>
            1. Find instant answers or ask the community:
          </p>
          <br />
          <div className={settingsStyles.aboutImages}>
            <img src={ASKabout} alt="ASK" />
            <img src={possibleanswersASK} alt="possibleanswersASK" />
          </div>
          <p className={settingsStyles.aboutText}>
            2. Share Knowledge (SAYs) and Updates in your communities:
          </p>
          <br />
          <div className={settingsStyles.aboutImages}>
            <img src={SAYabout} alt="SAY" />
            <img src={possibleanswersSAY} alt="possibleanswersSAY" />
          </div>
          <p className={settingsStyles.aboutText}>
            3. Ask questions or follow Tagged events and issues to stay
            connected:
          </p>
          <br />
          <div className={settingsStyles.aboutImages}>
            <img src={ASKquestionsAbout} alt="ASKquestions" />
            <img src={TaggedEvents} alt="taggedevents" />
          </div>
        </div>
      </div>
    </div>
  );
}

function PrivacyPolicy(props) {
  const type = window.location.pathname.replace('/', '');
  const [signInStatus, setSignInStatus] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const status = Cookies.get("signInStatus");
    setSignInStatus(status);
  }, []);

  let Component = UserGuideLines;
  if (type === "TermsofService") {
    Component = TermsofService;
  } else if (type === "PrivacyPolicy") {
    Component = PrivacyPolicyContent;
  } else if (type === "About") {
    Component = About;
  }

  const [isNotificationSelected, setIsNotificationSelected] =
    React.useState(false);

  const showHideNotifications = () => {
    setIsNotificationSelected(!isNotificationSelected);
  };

  return (
    <div>
      {isNotificationSelected ? (
        <div className={`col-sm-3  ${settingsStyles.notifications}`}>
          <div className={settingsStyles.notificationscroll}>
            <Notifications />
          </div>
        </div>
      ) : null}
      <div>
        {signInStatus === "true" ? (
          <HeaderAfterSignIn />
        ) : (
          <div className={settingsStyles.headerContainer}>
            <Link to={"/"}>
              <div>
                <img src={logo} alt="Skopic-Logo" width={90} height={48} />
              </div>
            </Link>
          </div>
        )}
        <Component />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
