export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54.114"
      height="18.118"
      viewBox="0 0 54.114 18.118"
    >
      <g
        id="Group_1848"
        data-name="Group 1848"
        transform="translate(-28.471 -772.13)"
      >
        <text
          id="TAG"
          transform="translate(56.585 786)"
          fill="#373334"
          fontSize="14"
          fontFamily="AcuminPro-Regular, Acumin Pro"
        >
          <tspan x="0" y="0">
            TAG
          </tspan>
        </text>
        <g
          id="Group_1719"
          data-name="Group 1719"
          transform="translate(28.471 772.13)"
        >
          <path
            id="Path_77"
            data-name="Path 77"
            d="M454.357,259.2l-3,.012,1.265-17.684,3.007-.015Z"
            transform="translate(-446.6 -241.514)"
            fill="#efba29"
          />
          <path
            id="Path_78"
            data-name="Path 78"
            d="M467.6,259.95l-3.006.016,1.266-17.685,3.007-.012Z"
            transform="translate(-452.465 -241.849)"
            fill="#efba29"
          />
          <path
            id="Path_79"
            data-name="Path 79"
            d="M463.3,249.837l-.17,2.727-19.486.261.18-2.734Z"
            transform="translate(-443.186 -245.201)"
            fill="#efba29"
          />
          <path
            id="Path_80"
            data-name="Path 80"
            d="M462.488,261.314l-.192,2.725-19.475.121.189-2.731Z"
            transform="translate(-442.821 -250.284)"
            fill="#efba29"
          />
        </g>
      </g>
    </svg>
  );
}