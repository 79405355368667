import React, { useState, useRef } from "react";
import Edit from "src/Assets/images/edit.svg";
import Camera from "src/Assets/images/camera.svg";
import "./ProfileDetails.css";
import * as SignInActions from "src/store/actions/SignInActions/SignInAction";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AvatarBadge from "src/Components/AvatarBadge";
import API from "../../../../network";
import { compressFile } from "src/Utils/imageCompressionUtil";

function ProfileDetail() {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  let profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  const [file, setFile] = useState(null);
  const fileInputRef = useRef();

  const handleChange = async (event) => {
    try {
      const file = event.target.files[0];
      if (file && file.name !== null) {
        //set file
        setFile(event.target.files[0].name);

        //compressing file
        const compressedFile = await compressFile(file);

        let formdata = new FormData();
        formdata.append("id", userDetails?.userData?.User_ID);
        formdata.append("skopicURL", process.env.REACT_APP_SERVICES_API_OTHER);
        formdata.append("email", userDetails?.userData?.userEmail);
        formdata.append("img", compressedFile);
        API.updateProfilePic(formdata)
          .then((res) => {
            dispatch(SignInActions.fetchloginUser());
          })
          .catch((error) => {
            // Handle errors
            console.error(`error in uploading profile image: ${error}`);
          });
      }
    } catch (err) {
      console.error(err);
    }
  };
  // const communitySelected = userDetails?.userData?.communityFollowNames.find(each => each.id === userDetails?.userData?.tenantId);

  return (
    <>
      {userDetails && Object.keys(userDetails).length !== 0 ? (
        userDetails.userData &&
        Object.keys(userDetails.userData).length !== 0 ? (
          <div className="ProfileDetails">
            <div className="Profilepic">
              <img
                src={`${profileImagePath}${userDetails.userData.uimage}`}
                alt="Profile Iamge"
                className="profile-img"
              />
              {/* <AvatarBadge
                src={`${profileImagePath}${userDetails.userData.uimage}`}
                alt={userDetails?.userData?.displayName}
                isPrimaryModerator={communitySelected?.ispm === 1}
                isModerator={communitySelected?.moderatorStatus === 1 && communitySelected?.ispm === 0}
              /> */}
            </div>
            <button
              className="Camera"
              onClick={() => fileInputRef.current.click()}
            >
              <img src={Camera} alt="Camera" />
            </button>
            <form
              className="uploadPhotoBottom"
              method="post"
              encType="multipart/form-data"
            >
              <input
                onChange={handleChange}
                multiple={false}
                ref={fileInputRef}
                type="file"
                accept="image/*" // Limit to image files
                hidden
              />
            </form>

            <Link to={"/editprofile"}>
              <button className="Name username-button" type="button">
                <h2>{userDetails.userData.displayName} </h2>
              </button>
            </Link>

            {/* <div className="Edit" role="button">
              <Link to={"/editprofile"} className={`dark-gray`}>
                <img src={Edit} alt="Edit" />
                edit profile
              </Link>
            </div> */}
          </div>
        ) : null
      ) : null}
    </>
  );
}

export default ProfileDetail;
