import React, { useState, useEffect } from 'react';
import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import * as moderatorActions from "src/store/actions/Moderator/moderatoractions";
import ConfirmDialogDemo from "../ReusableModeratorComponents/Blockmembersmodal";
import crownImage from "src/Assets/images/crown.png";
import style from "src/Assets/css/Moderator/Managemembes.module.css";
import API from '../../../network';

const Members = (props) => {
  const { tenantId } = props;
  const data = useSelector(
    (state) => state.ManagemembersReducer.ManagemebersData
  );
  // const userDetails = useSelector((state) => state.SignInReducer.userDetails);

  const [membersData, setMembersData] = useState([]);
  useEffect(() => {
    setMembersData(data);
  }, [data])

  // const blockedUsersData = useSelector(
  //   (state) => state.ModeratorReducer.Blockedusersdata
  // );
  // const tenantValue = props.tenantId;

  const dispatch = useDispatch();
  // let serverImageURL = process.env.REACT_APP_SKOPIC_IMAGE;

  // const onBlockMemberSelect = (i) => {
  //   let id = i;
  //   if (userDetails && Object.keys(userDetails).length !== 0) {
  //     // // console.log(userDetails.userData.tenantId)
  //     // setTenantID([userDetails.userData.tenantId])
  //     // dispatch(moderatorActions.fetchBlockMembersData(`?tenantId=${userDetails.userData.tenantId}&selectedIds=${id}`))
  //   }
  //   // document.getElementById(id)
  // };

  // Manage members community Invite as Moderator in submenu of ManageMembers actions

  const OnInviteAsModerator = (userID) => {
    if (tenantId) {
      let formData = new FormData();
      formData.append("tenantId", tenantId);
      formData.append("selectedIds", userID);

      dispatch(moderatorActions.fetchInviteAsModerator(formData));
      alert("Invitation has been sent successfully");
    }
  };

  // Manage members community Remove as Moderator in submenu of ManageMembers actions

  const onRemoveAsModerator = (userID) => {
    if (tenantId) {
      let formData = new FormData();
      formData.append("tenantId", tenantId);
      formData.append("rejectedIds", userID);

      // dispatch(moderatorActions.fetchRemoveAsModerator(formData));
      API.inviteAsModerator(formData)
        .then((response) => {
          const updatedData = membersData.filter(each => each.id !== userID);
          setMembersData(updatedData);
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      alert("removed as moderator ");
    }
    // console.log(userID);
  };

  const warnUserCommunity = (userID) => {
    if (tenantId) {
      let formData = new FormData();
      formData.append("tenantId", tenantId);
      formData.append("userId", userID);
      formData.append(
        "warnMessage",
        `Your community moderator has noted that the content you posted recently in crossword is not in compliance with the <a id="userguidlines" href="${process.env.REACT_APP_SERVICES_API}user/userGuide.html" tabindex="41">user guidelines</a> of Skopic. As a matter of courtesy and requirement, we ask that everyone of us maintain the decorum of our online community by following the best practices for sharing content. Please be advised that any future noncompliance shall result in blocking you from the community`
      );
      API.warnUser(formData)
        .then((response) => {
          alert("Warning sent successfully");
          console.log(response);
        })
        .catch((error) => {
          alert("Something went wrong");
          console.log(error);
        });
    }
  };

  return (
    <div className={style.container}>
      {membersData && membersData.length !== 0 ? (
        <>
          {membersData.map((result, i) => (
            <div key={`key-${result?.id}`} className={style.communityUsers}>
              <div className={style.childimage} key={i}>
                <Avatar
                  className={style.Linkedinimage}
                  src={result.uimage}
                  alt="image"
                />
                <div>
                  <span>{result.displayName}</span>
                  {result.isModerator === "1" || result.isModerator === "2" ? (
                    <p>Moderator</p>
                  ) : null}
                </div>
              </div>
              <div className={style.dropdown}>
                <span className={style.link}>
                  {" "}
                  <ExpandMoreIcon></ExpandMoreIcon>
                  <ul className={style.dlist}>
                    {(result.isModerator === "1" || result.isModerator === "2") ? (
                      <li
                        onClick={() => onRemoveAsModerator(result.id)}
                        className={style.moderatorList}
                      >
                        <HighlightOffIcon className={style.moderatoricons} />
                        <p>Remove as moderator</p>
                      </li>
                    ) : (
                      <li
                        onClick={() => OnInviteAsModerator(result.id)}
                        className={style.moderatorList}
                      >
                        <img
                          src={crownImage}
                          className={style.moderatoricons}
                          alt="crown-image"
                        />
                        <p>Invite as moderator</p>
                      </li>
                    )}
                    <li
                      onClick={() => warnUserCommunity(result.id)}
                      className={style.moderatorList}
                    >
                      <WarningAmberRoundedIcon className={style.warningIcon} />
                      <p>Send warning</p>
                    </li>
                    <li className={style.blockUserList}>
                      <ConfirmDialogDemo
                        isBlocked={result.isBlocked}
                        id={result.id}
                        tenantId={tenantId}
                      />
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};

export default Members;
