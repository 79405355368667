import {Route, Redirect} from 'react-router-dom'
import Cookies from 'js-cookie'

const SkopicProtected = props => {
  const signInStatus = Cookies.get('signInStatus')
  if (signInStatus === undefined) {
    return <Redirect to="/" />
  }
  return <Route {...props} />
}

export default SkopicProtected