// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    margin-top: auto;
    /* background-color: aqua; */
    position: relative;
}
.Community-Image{
    width: 100%;
    height: 100px;
    border-radius: 20px;
}

@media screen and (min-width: 375px) {
    .Community-Image{
        width: 98vw;
        height: 140px;
        border-radius: 20px;
        margin: auto;
    }
}

@media screen and (min-width: 992px) {
    .Community-Image{
        width: 98vw;
        height: 160px;
        border-radius: 20px;
        margin: auto;
    }
}

@media screen and (min-width: 1024px) {
    .Community-Image{
        width: 92vw;
        height: 180px;
        border-radius: 20px;
        margin: auto;
    }
}

@media screen and (min-width: 1200px) {
    .Community-Image{
        width: 80vw;
        height: 180px;
        border-radius: 20px;
        margin: auto;
    }
}

@media screen and (min-width: 1440px) {
    .Community-Image{
        width: 70vw;
        height: 180px;
        border-radius: 20px;
        margin: auto;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Modules/Activity/Profile/Profile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,4BAA4B;IAC5B,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,mBAAmB;QACnB,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,mBAAmB;QACnB,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,mBAAmB;QACnB,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,mBAAmB;QACnB,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,mBAAmB;QACnB,YAAY;IAChB;AACJ","sourcesContent":[".Profile{\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n    width: 100%;\n    height: 100%;\n    margin-top: auto;\n    /* background-color: aqua; */\n    position: relative;\n}\n.Community-Image{\n    width: 100%;\n    height: 100px;\n    border-radius: 20px;\n}\n\n@media screen and (min-width: 375px) {\n    .Community-Image{\n        width: 98vw;\n        height: 140px;\n        border-radius: 20px;\n        margin: auto;\n    }\n}\n\n@media screen and (min-width: 992px) {\n    .Community-Image{\n        width: 98vw;\n        height: 160px;\n        border-radius: 20px;\n        margin: auto;\n    }\n}\n\n@media screen and (min-width: 1024px) {\n    .Community-Image{\n        width: 92vw;\n        height: 180px;\n        border-radius: 20px;\n        margin: auto;\n    }\n}\n\n@media screen and (min-width: 1200px) {\n    .Community-Image{\n        width: 80vw;\n        height: 180px;\n        border-radius: 20px;\n        margin: auto;\n    }\n}\n\n@media screen and (min-width: 1440px) {\n    .Community-Image{\n        width: 70vw;\n        height: 180px;\n        border-radius: 20px;\n        margin: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
