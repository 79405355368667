import React from 'react';
import { useHistory } from "react-router-dom";
import { Box, Typography, Modal, CircularProgress, IconButton } from '@material-ui/core';
import { AiOutlineCloseCircle } from "react-icons/ai"
import DefaultTextBox from "src/Components/DefaultCommunity/textField/textField.js";
import signupStyle from "./SignUp/SignUp.module.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 3,
};

export default function DefaultCommModal({
  open,
  onClose,
  loading,
  email,
  password,
  displayName,
  community,
  onSignUp,
}) {
  const history = useHistory();

  const handleClickComm = () => {
    history.push("/defaultCommunity", {
      email: email,
      password: password,
      displayName: displayName,
    });
  };

  return (
    <Modal
      open={open}
      dis
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} borderRadius={25}>
        <IconButton onClick={onClose} style={{ position: "absolute", top: 0, right: 0 }}>
          <AiOutlineCloseCircle />
        </IconButton>
        <Typography color="textSecondary" variant="h6" component="h2" align='center'>
          Complete Sign Up
        </Typography>
        <div className={signupStyle.DefaultCommunity}>
          <DefaultTextBox
            value={community}
            handleLocation={handleClickComm}
          />
        </div>
        <p>
          <div className={signupStyle.TandC}>
            By Signing Up, you accept to Skopic's{" "}
            <span> terms of service</span> and <span> privacy.</span>
          </div>
        </p>
        <button
          className={signupStyle.SignupButton}
          onClick={onSignUp}
        >
          {loading
            ? <CircularProgress style={{ width: "20px", height: "20px" }} />
            : " Sign Up"
          }
        </button>
      </Box>
    </Modal>
  );
}
