import React, { useState } from "react";
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { BiSolidEdit } from "react-icons/bi";
import AskPostModal from "../askPostModal";
import useStyles from "./styles";
import editIcon from "src/Assets/images/askorsaySomethingEditIcon.png"
import "./index.css"

const SayAskPost = ({ isSignIn, isEnable, onHashTagClick }) => {
  const { post, postinput } = useStyles();

  const [modalShow, setModalShow] = useState(false);

  const onModalSelect = () => {
    setModalShow(false);
  };

  const handleChange = () => {
    setModalShow(false);
  };

  return (
    <div className={post}>
      <Input
        type="text"
        placeholder="ASK or SAY something..."
        // className={postinput}
        className="input-container"
        onClick={handleChange}
        style={{ cursor: isSignIn && !isEnable ? "pointer" : "not-allowed" }}
        disabled={!isSignIn || !isEnable}
        startAdornment={
          <InputAdornment position="start">
            {/* <BiSolidEdit fontSize={20} style={{ marginLeft: 10 }} /> */}
            <img src={editIcon} alt="edit-icon" className="edit-icon" />
          </InputAdornment>
        }
      />
      <AskPostModal
        show={modalShow}
        onHide={() => onModalSelect()}
        modalShow={modalShow}
        setModalShow={setModalShow}
        onHashTagClick={onHashTagClick}
      />
    </div>
  );
};

export default SayAskPost;
