import React from "react";
import { useState } from "react";
import locationpinchildpost from "src/Assets/images/locationpinchildpost.png";
import "../../styles/postPhotoItemStyle.css";
import closebutton from "src/Assets/images/CloseButton.svg";
import LocationUpdate from "../../Components/DefaultCommunity/locationUpdate";

export default function PostPhotoItem({
  id,
  imageFile,
  path,
  description,
  location,
  removeImageClick,
  onDescriptionChange,
  handleClick,
  onLocationSubmit,
  isLocationSelect,
  setIsLocationSelect,
  setLng,
  setLat,
  setOpen,
  open,
}) {
  const [selectedImage, setSelectedImage] = useState(null);

  if (imageFile) {
    const reader = new FileReader();
    reader.onload = (e) => {
      setSelectedImage(e.target.result);
    };
    reader.readAsDataURL(imageFile);
  }

  const handleRemoveIconClick = () => removeImageClick(id);
  const handleDescriptionChange = (e) => onDescriptionChange(e, id);

  return (
    <div className="postPhotoItemStyle mb-3">
      <div className="closerImage">
        <img src={selectedImage} alt="selected image" className="image" />
        <div className=" closeIcon close-icon mt-2 mr-2" onClick={handleRemoveIconClick}>
          <img src={closebutton} alt="cross-icon" />
        </div>
      </div>

      <textarea
        value={description}
        maxLength={280}
        onChange={handleDescriptionChange}
        placeholder="Add a description..."
      />
      <div className="bottom">
        <div className="location">
          <img
            src={locationpinchildpost}
            alt="icon"
            className="locationIcon"
            onClick={handleClick}
          />
          <p>{location}</p>
        </div>

        <p>{description.length}/280</p>
      </div>

      <LocationUpdate
        open={open}
        setOpen={setOpen}
        isLocationSelect={setIsLocationSelect}
        setLng={setLng}
        setLat={setLat}
        onLocationSubmit={() => onLocationSubmit(id)}
      />
    </div>
  );
}
