import React, { useContext, useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useGoogleLogin } from "react-google-login";
import * as Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { SignupContext } from "src/context/SignupContext/SignupContext";
import Api from "src/network";
import SignUpRepo from "src/repo/signup_repo";
import { GoogleButton } from "./SocialButtons";
import DefaultCommModal from "./DefaultCommModal";
import * as SignInActions from "src/store/actions/SignInActions/SignInAction";
import { useDispatch } from "react-redux";
// import EmailVerificationModal from "./EmailVerification";

const clientId = "1096351929813-b3sqsgi9t95dfbr7jhsruvsfvh8q6iac.apps.googleusercontent.com";

const defaultState = {
  email: "",
  password: "",
  displayName: "",
  community: "Default Community",
  tenantId: ""
};

function GoogleSignUp() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showDefaultCommModal, setShowDefaultCommModal] = useState(false);
  const [state, setState] = useState({ ...defaultState });
  // const [verificationModal, setVerificationModal] = useState(false);
  // const [resendOtp, setResendOtp] = useState(false);

  // const dispatch = useDispatch();
  const signupContext = useContext(SignupContext);
  const dispatch = useDispatch();

  useEffect(() => {
    const { googleSignUp, email, password, displayName, community, tenantId, } = signupContext?.signupState;
    if (googleSignUp) {
      setState((prevState) => ({
        ...prevState,
        email: email,
        password: password,
        displayName: displayName,
        community: community,
        tenantId: tenantId,
      }));
      setShowDefaultCommModal(true);
    }
  }, []);

  const onSuccess = async (res) => {
    console.log(res)
    const googleResponse = {
      name: res.profileObj.name,
      email: res.profileObj.email,
      tokenId: res.tokenId,
      accessToken: res.accessToken,
      givenName: res.profileObj.givenName,
      familyName: res.profileObj.familyName,
      imageUrl: res.profileObj.imageUrl,
    };
    console.log('googleResponse->', googleResponse);

    try {
      const result = await Api.validateGoogleToken(googleResponse.tokenId);
      const { status } = result?.data || {}
      if (status && String(status).toLowerCase() === ("Thank you for SignUp using your Gmail account, Skopic has created a profile for you and a temporary Skopic password has been emailed.").toLowerCase()) {
        signupContext?.setSignupState({
          googleSignUp: true, //set true to navigation purpose
          email: googleResponse?.email,
          password: "",
          displayName: googleResponse?.name,
          community: "",
          tenantId: "",
        });
        setState({
          ...defaultState,
          email: googleResponse?.email,
          displayName: googleResponse?.name,
        });
        setShowDefaultCommModal(true);
      } else if (status && String(status).toLowerCase() === ("Thank you for Signing using your Gmail account").toLowerCase()) {
        authenticate(result?.data?.userTenantId, result?.data?.userTenant, false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onFailure = (error) => {
    console.error("login failed: ", error);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
  });

  const onCloseModal = () => {
    setShowDefaultCommModal(false);
    //reset all values
    setState({ ...defaultState });
    //reset signup context data
    signupContext?.resetSignupState();
  };

  const handleMakeDefault = async (tenantId) => {
    let formdata = new FormData();
    formdata.append("userDefaultTenantId", tenantId);
    setLoading(true);
    Api.setDefaultTenant(formdata)
      .then((res) => {
        console.log("handleMakeDefault res", res);
        setLoading(false);
        authenticate(tenantId, state?.community, true);
      })
      .catch((err) => {
        setLoading(false);
        alert("Default community selection failed!");
      });
  };

  const onSignUp = async (tenantId) => {
    // if there is no community selected
    if (state?.community === "Default Community" || loading) return;
    await handleMakeDefault(tenantId);
  };

  const authenticate = async (tenantId, community, isSignUpFlow) => {
    try {
      setLoading(true);
      await Api.getUserData(tenantId, community);
      // set cookies
      Cookies.set("signInStatus", true);
      // sessionStorage.setItem("userData", JSON.stringify(result));

      if (isSignUpFlow) { //for signup flow
        // close default community selection popup
        setShowDefaultCommModal(false);
        // reset signup context data
        signupContext?.resetSignupState();
        // set isNewUser flag to true
        dispatch(SignInActions?.updateIsNewUser(true));
      }

      dispatch(SignInActions.authUser());
      // navigate to home page
      history.push("/Home");
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <GoogleButton onClick={() => {
        console.log("CLICKED Continue with Google BUTTON")
        signIn();
      }}
        loading={loading}
      />
      {showDefaultCommModal && (
        <DefaultCommModal
          open={showDefaultCommModal}
          onClose={onCloseModal}
          loading={loading}
          email={state?.email}
          password={state?.password}
          community={state?.community}
          tenantId={state?.tenantId}
          displayName={state?.displayName}
          onSignUp={onSignUp}
        />
      )}

      {/* <EmailVerificationModal
        centered
        show={verificationModal}
        onHide={() => setVerificationModal(false)}
        dispatch={dispatch}
        email={state?.email}
        tentId={state?.tenantId}
        password={state?.password}
        tenantName={state?.community}
        resendOtp={resendOtp}
      /> */}
    </>
  );
}

export default GoogleSignUp;
