import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./invitations.css";
import gmail from "src/Assets/images/gmail.svg";
import facebook from "src/Assets/images/fb.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import MessageModal from "../../utils/messageModal";
import { set } from "js-cookie";
import API from "../../../network";
import { Api } from "@mui/icons-material";

var emailsParam = "";

function Invitation(props) {
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);

  const [mail, setMail] = useState("");
  const [subject, setSubject] = useState("");
  const [inviteMsg, setInviteMsg] = useState("");
  const [textmsg, setTextmsg] = useState("");
  const [errormsg, setErrormsg] = useState(""); // Corrected variable name
  const [email, setEmail] = useState([]); // Corrected variable name
  const [closeInvites, setCloseInvites] = useState(null);
  const [messageShow, setMessageShow] = useState(false);

  const [isInviteSuccess, setIsInviteSuccess] = useState(false);

  const emailChange = (e) => {
    setMail(e.target.value);
  };

  const handleKeyDown = (evt) => {
    if ([" ", ";", ","].includes(evt.key)) {
      evt.preventDefault();

      var newEmail = mail.trim();

      if (newEmail && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)) {
        setEmail((email) => [...email, newEmail]);
        setMail("");
      }
    }
  };

  useEffect(() => {
    if (props.invite) {
      setSubject(
        `Invitation to join ${userDetails.userData.tenantName} via Skopic`
      );
      setTextmsg(
        `Hi,%0A%0AI request you to join ${userDetails.userData.tenantName} on Skopic. It is quick and easy to Sign Up.%0A%0AClick the link below to join ${userDetails.userData.tenantName}.%0A${process.env.REACT_APP_SERVICES_API}user/communityurl.html?tid=${userDetails.userData.tenantId}%0A%0AThank you,%0A${userDetails.userData.displayName}`
      );
    }
    setInviteMsg(
      `Hi,\n\nI request you to join ${userDetails.userData.tenantName} on Skopic. It is quick and easy to Sign Up.\n\nClick the link below to join ${userDetails.userData.tenantName}.\n${process.env.REACT_APP_SERVICES_API}user/communityurl.html?tid=${userDetails.userData.tenantId}\n\nThank you,\n${userDetails.userData.displayName}`
    );
  }, [props.invite, userDetails]);

  const closeHandler = () => {
    setMail("");
    setEmail([]);
  };

  const onInviteHandler = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
    };

    if (email.length > 1) {
      emailsParam = email.join(","); // Use .join() to convert email array to comma-separated string
    } else {
      emailsParam = mail;
    }

    // axios
    //   .get(
    //     `https://dev.skopic.com/skopicportal/user/inviteFriend.html?inviteEmailID=${emailsParam}&inviteMessage=${textmsg}&inviteSubject=${subject}&reInviteActivity=&reInviteId=`,
    //     config
    //   )
    //   .then((res) => {
    //     setErrormsg(res.data);
    //     setIsInviteSuccess(true);
    //   });
    API.inviteGmailContacts(emailsParam, textmsg, subject)
      .then((res) => {
        setErrormsg(res.data);
        setIsInviteSuccess(true);
      })
      .catch((e) => {
        console.error(e);
      });

    setMail("");
    setEmail([]);
  };

  const handleDelete = (toBeRemoved) => {
    setEmail(email.filter((email) => email !== toBeRemoved));
  };

  const isSumbitHandler = () => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail) || email.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const closeInviteHandler = () => {
    setCloseInvites(props.onHide);
    setMessageShow(true);
  };

  const closeSuccessPopup = () => {
    setIsInviteSuccess(false);
  };

  const isEnabaled = isSumbitHandler();
  const refUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/contacts.readonly&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=https://dev.skopic.com/skopicportal/gmail/redirect-gmail-contact-values?app_type=web&client_id=1096351929813-b3sqsgi9t95dfbr7jhsruvsfvh8q6iac.apps.googleusercontent.com`;

  // const shareViaFbUrl = `https://www.facebook.com/dialog/share?app_id=2239633553065582
  //   &display=popup
  //   &href=${encodeURIComponent("https://dev.skopic.com")}
  //   &redirect_uri=${encodeURIComponent("https://dev.skopic.com")}`;

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="InviteStyles"
      >
        <div className="Invitation-modal">
          <Modal.Header closeButton onClick={closeHandler}>
            <Modal.Title id="contained-modal-title-vcenter" className="title">
              Invite members
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={onInviteHandler}>
              <div className="Invitaion">
                <div className="InvitationEmail">
                  <label className="mar-lft-1rem">Email:</label>
                  <input
                    type="email"
                    value={mail}
                    onChange={emailChange}
                    onKeyDown={handleKeyDown}
                    maxLength="50"
                  />
                  {email.map((mail) => (
                    <div key={mail} className="multipleEmail">
                      {mail}
                      <button
                        type="button"
                        className="button"
                        onClick={() => handleDelete(mail)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
                <div className="InvitationSubject">
                  <label className="mar-lft-1rem">Subject:</label>
                  <input type="text" value={subject} />
                </div>
                <div className="InvitationTextaera">
                  <label className="mar-lft-1rem">Message:</label>
                  <div>
                    <textarea rows="4" cols="50" defaultValue={inviteMsg} />
                  </div>
                </div>
                <div class="invite-align-parent">
                  <button
                    type="submit"
                    className="Invite-align"
                    onClick={closeInviteHandler}
                    disabled={!isEnabaled}
                  >
                    Invite
                  </button>
                </div>
              </div>
            </form>
            <p className="Change">or</p>
            <div className="InviteOptionsContainer_align">
              <a href={refUrl} target="_blank">
                <button className="GmailContacts_align">
                  <img src={gmail} alt="gmailIcon" />
                  <span> Invite Gmail contacts</span>
                </button>
              </a>
              {/* <a href={shareViaFbUrl} target="_blank">
                <button className="FacebookContacts_align">
                  <img src={facebook} alt="facebookIcon" />
                  <span>Share on Facebook</span>
                </button>
              </a> */}
            </div>
          </Modal.Body>
        </div>
      </Modal>

      <MessageModal
        show={messageShow}
        onHide={() => setMessageShow(false)}
        message={errormsg}
      />

      {/* Success Alert */}

      {/* {isInviteSuccess && (
        <div className="success-alert">
          <p>Invite sent successfully!</p>
          <button onClick={closeSuccessPopup}>Close</button>
        </div>
      )} */}
    </>
  );
}

export default Invitation;
