import React from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import Home from "./Modules/home/home.js";
import Settings from "src/pages/Settings";
import Googlemaps from "./Modules/maps/Consumer.jsx";
// import MainPage from "./Modules/MainPage/MainPage"
import LandingPage from "./pages/Landing";
import HomePage from "./pages/HomePage";
import ForgotPassword from "./Modules/LandingPage/ForgotPassword/ForgotPassowrd.jsx";
import Help from "src/pages/Help";
// import PrivacyPolicy from "./Modules/FooterComponents/PrivacyPolicy.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy/index.jsx";
import CookiesContent from "src/pages/Cookies";
import Careers from "src/pages/Careers";
import EditProfile from "src/pages/EditProfile";
import ActivityScreen from "./Modules/Activity/ActivityScreen";
import Moderator from "./Modules/Moderator/Moderator";
import Test from "./views/pages/TestPage.jsx";
import TestPage from "./views/pages/TestPage.jsx";
import LoginUserGuidelines from "./Components/userGuidlines.jsx";
import ModeratorGuidelines from "./Components/moderatorGuidlines.jsx";
import SkopicProtected from "./ProtectedRoute";

import DefaultCommunity from "./Components/DefaultCommunity";
import ViewActivity from "./Components/DefaultCommunity/viewActivity";
import FollowCommunities from "./Components/DefaultCommunity/followCommunities";

// import ChildLocationtag from './Modules/Reusuablecomponents/ChildLocationtag'
// import GooglemapsSignin from './GooglemapsSignIn/GoogleMapsSignin.js'
import NotFound from "./NotFoundPage";

export const Routes = () => {
  const signInStatus = Cookies.get("signInStatus");
  return (
    // <BrowserRouter>
    //   <Switch>
    //     <Route exact path="/" component={LandingPage} />
    //     <Route exact path="/ForgotPassword" component={ForgotPassword} />
    //     <Route exact path="/Home" component={HomePage} />
    //     <Route exact path="/Settings" component={Settings} />
    //     <Route exact path="/Googlemaps" component={Googlemaps} />
    //     <Route exact path="/Help" component={Help} />
    //     <Route exact path="/Cookies" component={CookiesContent} />
    //     <Route exact path="/Careers" component={Careers} />
    //     <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
    //     <Route exact path="/TermsofService" component={PrivacyPolicy} />
    //     <Route exact path="/UserGuideLines" component={PrivacyPolicy} />
    //     <Route exact path="/LoginUserGuidelines" component={LoginUserGuidelines} />
    //     <Route exact path="/ModeratorGuidelines" component={ModeratorGuidelines} />
    //     <Route exact path="/About" component={PrivacyPolicy} />
    //     <Route exact path="/editprofile" component={EditProfile} />
    //     <Route exact path="/activity" component={ActivityScreen} />
    //     <Route exact path="/Moderator" component={Moderator} />
    //     <Route exact path="/test" component={TestPage} />
    //     <Route exact path="/defaultCommunity" component={DefaultCommunity} />
    //     <Route exact path="/viewActivity" component={ViewActivity} />
    //     <Route exact path='/communities' component={FollowCommunities} />
    //     {/* <Route  exact path="*" component={MainPage}/> */}

    //     {/* <Route exact path="/GooglemapsSignin" component={GooglemapsSignin}/> */}
    //   </Switch>
    // </BrowserRouter>

    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LandingPage} />

        {/* Public Routes */}
        <Route exact path="/Help" component={Help} />
        <Route exact path="/Cookies" component={CookiesContent} />
        <Route exact path="/Careers" component={Careers} />
        <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
        <Route exact path="/TermsofService" component={PrivacyPolicy} />
        <Route exact path="/UserGuideLines" component={PrivacyPolicy} />
        <Route
          exact
          path="/LoginUserGuidelines"
          component={LoginUserGuidelines}
        />
        <Route
          exact
          path="/ModeratorGuidelines"
          component={ModeratorGuidelines}
        />
        <Route exact path="/About" component={PrivacyPolicy} />
        <Route exact path="/defaultCommunity" component={DefaultCommunity} />
        <Route exact path="/viewActivity" component={ViewActivity} />

        {/* Protected Routes (Only accessible if authenticated/for signed in user) */}
        <SkopicProtected exact path="/Home" component={HomePage} />
        <SkopicProtected exact path="/Settings" component={Settings} />
        <SkopicProtected exact path="/editprofile" component={EditProfile} />
        <SkopicProtected exact path="/activity" component={ActivityScreen} />
        <SkopicProtected exact path="/Moderator" component={Moderator} />
        <SkopicProtected
          exact
          path="/communities"
          component={FollowCommunities}
        />

        {/* Catch-All Route for undefined paths - and renders NotFound component */}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};
