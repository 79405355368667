import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const AlertBox = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen && setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>Removed Successfully</span>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AlertBox;
