import React from "react";
import { useSelector } from "react-redux";
import style from "src/Assets/css/home/TrendingTagResults.module.css";
import NoDataFound from "src/Assets/images/data-not-found.svg";
import Post from "../components/Post";
import CircularProgress from "@material-ui/core/CircularProgress";
import backwardarrow from "src/Assets/images/backwardarrow.png";
import Feed from "./Feed";

const TrendingTags = (props) => {
  const {
    allTags,
    goBack,
    loading,
    childSaysLoading,
    postChildSayData,
    fetchFeedData,
    deleteMessage,
    childSaysData,
    fetchChildSaysData,
    fetchFollowData,
    fetchVoteUpStatus,
    refreshFeedData,
    showData,
    hasRestrictionSays,
    fetchContributors,
    onHashTagClick,
  } = props;
  return (
    <>
      {!loading ? (
        allTags && Object.keys(allTags).length > 0 ? (
          <div className={style.TagResults}>
            <Feed showData={showData} onHashTagClick={onHashTagClick} />
            {/* <div className={style.TagResultContainer}>
              <a>
                <img src={backwardarrow} onClick={goBack} />
              </a>
            </div> */}
            <Post
              loading={childSaysLoading}
              fetchFeedData={fetchFeedData}
              deleteMessage={deleteMessage}
              listdata={allTags}
              data={allTags}
              postChildSayData={postChildSayData}
              childSaysData={childSaysData}
              fetchChildSaysData={fetchChildSaysData}
              fetchFollowData={fetchFollowData}
              fetchVoteUpStatus={fetchVoteUpStatus}
              refreshFeedData={refreshFeedData}
              showData={showData}
              hasRestrictionSays={hasRestrictionSays}
              fetchContributors={fetchContributors}
              onHashTagClick={onHashTagClick}
            />
          </div>
        ) : (
          // <div className={style.nodata}>
          //   <img src={NoDataFound} alt="NoDataFound" width="200" />
          //   <p>No Data Found</p>
          // </div>
          <div className={style.loader}>
            <CircularProgress />
          </div>
        )
      ) : (
        <div className={style.loader}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default TrendingTags;
