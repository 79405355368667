// FbAuth.js
import React, { useState } from "react";
import FacebookLogin from "react-facebook-login";
import { useHistory } from "react-router-dom";
import facebook from "src/Assets/images/facebook.svg";
import Api from "src/network";

const FbAuth = () => {
    const [isLogIn, setIsLogIn] = useState(false);
    let token = "";

    const history = useHistory();

    const responseFacebook = async (response) => {
        try {
            token = response.accessToken;
            const res = await Api.validateFBToken(token);
            if (res.data?.status === "success") {
                const response = await Api.facebookLogin();
                if (response?.data?.userTenant === "No Community") {
                    // Handle the case where the user has no community
                } else {
                    setIsLogIn(true);
                    history.push("/Home");
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const componentClicked = (data) => {
        // Handle click event
    };

    return (
        <div>
            {!isLogIn ? (
                <FacebookLogin
                    // appId="239427513420746"
                    appId="1150223116306386"
                    autoLoad={false}
                    fields="email,name,picture"
                    onClick={componentClicked}
                    callback={responseFacebook}
                    textButton=""
                    buttonStyle={{
                        all: "unset",
                        display: "inline-block",
                        marginLeft: "12px",
                        cursor: "pointer",
                        width: "48px",
                    }}
                    icon={<img src={facebook} style={{ height: 46, width: 46 }} alt="Facebook" />}
                />
            ) : (
                <navigate to="/Home" />
            )}
        </div>
    );
};

export default FbAuth;
