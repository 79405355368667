import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import feedstyles from "src/Assets/css/home/feedpost.module.css";
import PrimaryModeratorCrown from "src/Assets/images/PrimaryModeratorCrown.svg";
import SecondaryModeratorCrown from "src/Assets/images/SecondaryModeratorCrown.svg";

// import ReactDOM from "react-dom";

import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// import { Carousel } from "primereact/carousel";
// import { Button } from "primereact/button";
// import { useDispatch, useSelector } from "react-redux";

import "src/Assets/css/home/CarouselDemo.css";
// import styles from "src/Assets/css/home/post.module.css";
// import feedstyles from "src/Assets/css/home/feedpost.module.css";
// import Avatar from "@material-ui/core/Avatar";
import AvatarBadge from "src/Components/AvatarBadge";

const settings = {
  // className: "center",
  // centerMode: true,
  // infinite: true,
  // centerPadding: "60px",
  slidesToShow: 1,
  // speed: 500,
  rows: 2,
  slidesPerRow: 3,
};

const Contributors = (props) => {
  const { contributorsData, handleClickEvent } = props;
  const src = process.env.REACT_APP_SKOPIC_IMAGE;
  const [products, setProducts] = useState([]);
  const [sliderSettings, setSliderSettings] = useState({ ...settings });

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      let slidesPerRow = 3;
      if (windowWidth < 768) {
        slidesPerRow = 6;
      }
      if (windowWidth >= 768 && windowWidth <= 991) {
        slidesPerRow = 10;
      }
      setSliderSettings({ ...settings, slidesPerRow });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // productService.getProductsSmall().then(data => setProducts(data.slice(0, 6)));
    if (contributorsData) {
      if (contributorsData && Object.keys(contributorsData).length !== 0) {
        if (contributorsData.contribut) {
          setProducts(contributorsData.contribut);
          // setContributors(contributorsData.contribut.slice(7,10))
          // console.log("hi im" + contributorsData);
        }
      }
    }
  }, [contributorsData]);

  return (
    <>
      <Slider {...sliderSettings}>
        {products && Object.keys(products).length !== 0 ? (
          products.map((product, i) => (
            <div className="carousel-demo" key={`key-${product?.id}`}>
              {/* <div className="card"> */}
              <div className="product-item">
                <div className="product-item-content">
                  <img
                    src={`${src}${product.uImage}`}
                    onError={(e) =>
                    (e.target.src =
                      "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                    }
                    alt={product.displayName}
                    className="product-image"
                    onMouseEnter={() => handleClickEvent(product.id, true)}
                    onMouseLeave={() => handleClickEvent(0, false)}
                  />
                  {(product.isModerator === "1" || product.isPM === "1") && (
                    <img
                      src={
                        product.isModerator === "1" && product.isPM === "1"
                          ? PrimaryModeratorCrown
                          : product.isModerator === "1"
                            ? SecondaryModeratorCrown
                            : null
                      }
                      className={feedstyles.crown}
                      alt="Moderator Crown"
                    />
                  )}
                  {/* <div className="hover-Box">
                    <div style={{display:"flex"}}>
                    <p >
                       SAY {product.say} | UPDATE{" "}
                      {product.iUpdate} | Bio {product.shortBio}
                    </p>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* </div> */}
            </div>
          ))
        ) : (
          <p>No Data</p>
        )}
      </Slider>
    </>
  );
};

export default Contributors;
