import React from "react";
import { connect } from "react-redux";
import API from "../../network";
import ViewRenderer from "./views";
import * as SignInActions from "src/store/actions/SignInActions/SignInAction";
import * as feedActions from "src/store/actions/feedactions/feedActionCreator";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedData: {},
      contributors: [],
      trendingAsks: [],
      trendingTags: [],
      allTags: [],
      allAsks: [],
      allTagData: {},
      userSettings: {},
      filterType: "",
      isNotificationSelected: false,
      showData: "feed",
      searchData: {},
      searchParam: "",
      selectedTagDetails: {},
      showModalForUpdate: false,
      modalTypeForUpdate: "",
      selectedCommunity: {},
      childSaysData: {},
      followData: {},
      voteUpStatus: {},
      loading: false,
      answersToYourAsk: {},
      asksToBeAnswered: {},
      privateTagsData: [],
      childSaysLoading: false,
      feedPageNumber: 1,
      value: 0,
      hasRestrictionSays: false,
      clearSearchHeader: false,
    };
  }

  componentDidMount() {
    const { dispatchSettings, dispatchUserDetails, userDetails } = this.props;
    this.setState({ loading: true });
    dispatchUserDetails();
    setTimeout(() => {
      this.fetchFeedData();
      this.fetchContributors();
      this.fetchTrendingTags();
      this.fetchTrendingAsks();
    }, 1000);

    dispatchSettings();
    this.setState({
      selectedCommunity: {
        tenantName: userDetails?.userData?.tenantName || "",
        tenantId: userDetails?.userData?.tenantId
      },
    });
  }

  deleteAnswersToAsksDialogPost = (id) => {
    const { answersToYourAsk } = this.state;
    const newAnswersToYourAsksList = answersToYourAsk.messageList.filter(
      (item) => item.id !== id
    );

    // Update the state correctly
    this.setState({
      answersToYourAsk: {
        ...answersToYourAsk, // Spread the existing answersToYourAsk properties
        messageList: newAnswersToYourAsksList, // Update the messageList with the filtered list
      },
    });
  };

  deleteAsksToBeAnsweredDialogPost = (id) => {
    const { asksToBeAnswered } = this.state;
    const newAnswersToYourAsksList = asksToBeAnswered.messageList.filter(item => item.id !== id);

    // Update the state correctly
    this.setState({
      asksToBeAnswered: {
        ...asksToBeAnswered, // Spread the existing answersToYourAsk properties
        messageList: newAnswersToYourAsksList // Update the messageList with the filtered list
      }
    });
  };


  componentDidUpdate(prevProps) {
    const { userDetails: prevUserDetails } = prevProps;
    const { userDetails } = this.props;
    if (
      prevUserDetails?.userData?.tenantName !==
      userDetails?.userData?.tenantName
    ) {
      this.setState({
        selectedCommunity: {
          tenantName: userDetails?.userData?.tenantName || "",
          tenantId: userDetails?.userData?.tenantId
        },
      });
    }
  }

  fetchFeedData = async (filterType) => {
    const { feedPageNumber } = this.state;
    const newPageNumber = (feedPageNumber - 1) * 10;
    try {
      this.setState({ loading: true });
      // const startLimit = 0;
      const response = await API.fetchFeedData(filterType, newPageNumber);
      this.setState({ feedData: response.data, loading: false });
      console.log("getFeedData", response);
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  fetchPageFeed = (pageNumber, filterType) => {
    this.setState({ feedPageNumber: pageNumber }, () => {
      this.fetchFeedData(filterType);
    });
  };

  fetchContributors = async () => {
    try {
      const response = await API.fetchContributors();
      this.setState({ contributors: response.data });
      console.log("fetchContributors", response);
    } catch (e) {
      console.error(e);
    }
  };

  fetchTrendingTags = async () => {
    this.setState({ loading: true });
    try {
      const response = await API.fetchTrendingTags();
      this.setState({
        trendingTags: response.data.trendingTags,
        loading: false,
      });
      console.log("fetchTrendingTags", response);
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  fetchSelectedTagDetails = async (tagId) => {
    const { selectedCommunity } = this.state
    const tenantId = selectedCommunity.tenantId
    const response = await API.fetchTagDetails(tenantId, tagId);
    setTimeout(() => {
      this.setState({ selectedTagDetails: response.data.messageList });
    }, 1000);
  };

  fetchTrendingAsks = async () => {
    this.setState({ loading: true });
    try {
      const response = await API.fetchTrendingAsks();
      this.setState({
        trendingAsks: response.data.trendingAsks,
        loading: false,
      });
      console.log("fetchTrendingAsks", response);
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  fetchAllTags = async () => {
    this.setState({ loading: true });
    try {
      const response = await API.fetchAllTags();
      this.setState({
        allTags: response.data.trendingTags,
        allTagData: response.data,
        loading: false,
      });
      console.log("fetchAllTags", response);
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  fetchAllAsks = async () => {
    this.setState({ loading: true });
    try {
      const response = await API.fetchAllAsks();
      this.setState({ allAsks: response.data.trendingAsks, loading: false });
      console.log("fetchAllAsks", response);
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  fetchUserSettings = async () => {
    try {
      const response = await API.fetchUserSettings();
      this.setState({ userSettings: response.data });
      console.log("fetchUserSettings", response);
    } catch (e) {
      console.error(e);
    }
  };

  filterPosts = (filterType) => {
    this.setState({ filterType });
    this.fetchFeedData(filterType);
  };

  showHideNotifications = () => {
    this.setState({
      isNotificationSelected: !this.state.isNotificationSelected,
    });
  };

  //complete this method
  setShowData = (type) => {
    if (type === "ask") {
      this.fetchAllAsks();
    } else if (type === "tag") {
      this.fetchAllTags();
    }
    this.setState({ showData: type });
  };

  goBack = () => {
    this.setState({
      showData: "feed",
      searchData: {},
      clearSearchHeader: true,
    });
  };

  //related to handleSearchKeyDown method in header component
  fetchSearchData = async (params) => {
    console.log("fetchSearchData invoked");
    this.setState({ showData: "search", loading: true, searchParam: params });
    try {
      const response = await API.getSearchData(params);
      this.setState({ searchData: response.data, loading: false });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  onUpdateSelection = (type) => {
    this.setState({ modalTypeForUpdate: type, showModalForUpdate: true });
  };

  hideUpdateModal = () => {
    this.setState({ showModalForUpdate: false });
  };

  fetchLoggedInUser = async () => {
    try {
      await API.fetchLoggedInUser();
    } catch (e) {
      console.error(e);
    }
  };

  fetchSaysDataForAsks = async (msg, latLong, locSelection, postingOption) => {
    try {
      let formdata = new FormData();
      formdata.append("Message", msg);
      const response = await API.fetchAsksData(
        formdata,
        latLong,
        locSelection,
        postingOption
      );
      this.setState({ answersToYourAsk: response.data });
    } catch (e) {
      console.error(e);
    }
  };

  fetchAsksToAnswer = async (msg, latLong, locSelection, postingOption) => {
    try {
      const { filterType } = this.state;
      let formdata = new FormData();
      formdata.append("Message", msg);
      const response = await API.fetchSaysData(
        formdata,
        latLong,
        locSelection,
        postingOption
      );
      this.setState({ asksToBeAnswered: response.data });
      //TODO: This should be removed when popup for says is shown
      //on clicking say community, this should be invoked similar to asks
      //Temporary fix
      //Later when subscription logic is added, it should handle assing new record
      //we should not fetch api again
      // if (response && (filterType === '' || filterType === 'say')) {
      //   this.fetchFeedData(filterType);
      // }
      // this.filterPosts("say");
    } catch (e) {
      console.error(e);
    }
  };

  clearAsksAndSays = () => {
    this.setState({ asksToBeAnswered: {}, answersToYourAsk: {} });
  };

  onCommunityChange = async (id, name) => {
    this.setState({
      feedPageNumber: 1,
      filterType: "",
      feedData: {},
      value: 0,
    });
    const { selectedCommunity, showData, searchParam } = this.state;
    const { dispatchUserDetails } = this.props;
    if (selectedCommunity && selectedCommunity.id !== id) {
      try {
        const response = await API.changeCommunity(name, id);
        this.setState({ selectedCommunity: response.data });
        if (response) {
          this.fetchFeedData();
          if (
            showData === "tag" ||
            showData === "ask" ||
            showData === "search"
          ) {
            this.setState({ showData: "feed" });
          }
          // else if (showData === "search") {
          //   this.fetchSearchData(searchParam);
          // }
          this.fetchContributors();
          this.fetchTrendingAsks();
          this.fetchTrendingTags();
          dispatchUserDetails();
          //why should we call logged in user, although community changes, logged in user is same
          // this.fetchLoggedInUser();
        }
      } catch (e) {
        console.error(e);
      }
      window.scrollTo(0, 0);
    }
    // this.setState({ selectedCommunity: response.data });
  };

  changeValue = (newValue) => {
    this.setState({ value: newValue });
  };

  fetchChildSaysData = async (id, popCols, limit) => {
    this.setState({ childSaysLoading: true });
    try {
      const response = await API.fetchChildSaysData(id, popCols, limit);
      this.setState({ childSaysData: response.data, childSaysLoading: false });
    } catch (e) {
      this.setState({ childSaysLoading: false });
      console.error(e);
    }
  };

  postChildSayData = async (id, msg, lnglat, locSelect) => {
    try {
      let formdata = new FormData();
      formdata.append("Message", msg);
      const response = await API.postChildSayData(
        id,
        formdata,
        lnglat,
        locSelect
      );
      const status = response?.data?.status || "";
      if (status === "New Hash") {
        return "New Hash";
      } else if (status === "E_Say" || status === "E") {
        this.setState({ hasRestrictionSays: true });
      } else {
        this.fetchChildSaysData(id);
        if (
          this.state.showData === "tag" ||
          this.state.showData === "singleTrendingTag"
        ) {
          this.updateTagData(id, "add");
        } else if (this.state.showData === "ask") {
          this.updateAskData(id, "add");
        } else {
          this.updateFeedData(id, "add");
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  resetHasRestrictionSays = () => {
    this.setState({ hasRestrictionSays: false });
  };

  updateFeedData = (id, operator) => {
    // updating sayCount is the list based on operator
    // operator = 'add' | 'minus'
    const { feedData } = this.state;
    const feed = feedData?.feed || [];
    const copiedFeed = [...feed];
    const updatedFeed = copiedFeed.map((eachFeed) => {
      const sayCount =
        eachFeed?.id === id
          ? operator === "add"
            ? eachFeed?.sayCount + 1
            : eachFeed?.sayCount - 1
          : eachFeed.sayCount;
      return {
        ...eachFeed,
        sayCount,
      };
    });
    const updatedFeedData = { ...feedData, feed: updatedFeed };
    this.setState({ feedData: updatedFeedData });
  };

  updateTagData = (id, operator) => {
    // updating sayCount is the list based on operator
    // operator = 'add' | 'minus'
    const { allTags, selectedTagDetails, showData } = this.state;
    const copiedTags =
      showData === "singleTrendingTag" ? [...selectedTagDetails] : [...allTags];
    const updatedTags = copiedTags.map((eachTag) => {
      const sayCount =
        eachTag?.id === id
          ? operator === "add"
            ? eachTag?.sayCount + 1
            : eachTag?.sayCount - 1
          : eachTag.sayCount;
      return {
        ...eachTag,
        sayCount,
      };
    });

    if (showData === "singleTrendingTag") {
      this.setState({ selectedTagDetails: [...updatedTags] });
    } else {
      this.setState({ allTags: [...updatedTags] });
    }
  };

  updateAskData = (id, operator) => {
    // updating sayCount is the list based on operator
    // operator = 'add' | 'minus'
    const { allAsks } = this.state;
    const copiedAsks = [...allAsks];
    const updatedAsks = copiedAsks.map((eachAsk) => {
      const sayCount =
        eachAsk?.id === id
          ? operator === "add"
            ? eachAsk?.sayCount + 1
            : eachAsk?.sayCount - 1
          : eachAsk.sayCount;
      return {
        ...eachAsk,
        sayCount,
      };
    });
    this.setState({ allAsks: [...updatedAsks] });
  };

  //this state variable is not used anywhere
  fetchFollowData = async (id, status) => {
    try {
      const response = await API.fetchFollowUnfollowData(id, status);
      this.setState({ followData: response.data });
    } catch (e) {
      console.error(e);
    }
  };

  //this state variable is not used anywhere
  fetchVoteUpStatus = async (id, status) => {
    try {
      const response = await API.fetchVoteUpStatus(id, status);
      this.setState({ voteUpStatus: response.data });
    } catch (e) {
      console.error(e);
    }
  };

  askYourCommunity = async (id) => {
    try {
      await API.askYourCommunity(id);
    } catch (e) {
      console.error(e);
    }
  };

  fetchPrivateTagsData = async (id, limiter) => {
    try {
      const response = await API.fetchPrivateTagsData(id, limiter);
      this.setState({ privateTagsData: response.data });
    } catch (e) {
      console.error(e);
    }
  };

  //TODO: This method needs to be removed once subscription changes are added
  //The delete suscription should do something like this.
  deleteMessage = async (id, type, Parent_ID) => {
    const response = await API.deleteMessage(id);
    if (response) {
      if (type === "ChildSay") {
        const { childSaysData } = this.state;
        console.log("childSaysData", childSaysData);
        const updatedData = childSaysData.childSAYsMessageList.filter(
          (each) => each.id !== id
        );
        this.setState({
          childSaysData: {
            ...childSaysData,
            childSAYsMessageList: updatedData,
          },
        });
        if (
          this.state.showData === "tag" ||
          this.state.showData === "singleTrendingTag"
        ) {
          this.updateTagData(Parent_ID, "minus");
        } else if (this.state.showData === "ask") {
          this.updateAskData(Parent_ID, "minus");
        } else {
          this.updateFeedData(Parent_ID, "minus");
        }
      } else {
        const { showData } = this.state;
        if (showData === "feed") {
          const { feedData } = this.state;
          console.log("feedData", feedData, id, type);
          const newData = [...feedData.feed];
          const modifiedData = newData.filter((each) => each.id !== id);
          const updatedFeedData = {
            ...feedData,
            breadCountLength: modifiedData.length,
            feed: [...modifiedData],
          };
          console.log("modifiedData", modifiedData, newData, updatedFeedData);
          this.setState(
            {
              feedData: {
                ...updatedFeedData,
              },
            },
            console.log("this.state.feeddata", this.state.feedData)
          );
        } else if (showData === "ask") {
          const { allAsks } = this.state;
          const newData = [...allAsks];
          const modifiedData = newData.filter((each) => each.id !== id);
          this.setState({
            allAsks: modifiedData,
          });
        } else if (showData === "tag") {
          const { allTags } = this.state;
          const newData = [...allTags];
          const modifiedData = newData.filter((each) => each.id !== id);
          this.setState({
            allTags: modifiedData,
          });
        } else if (showData === "search") {
          const { searchData } = this.state;
          const newData = [...searchData.messageList];
          const modifiedData = newData.filter((each) => each.id !== id);
          this.setState({
            searchData: {
              ...searchData,
              totalList: modifiedData.length,
              messageList: modifiedData,
            },
          });
        }
      }
    }
  };

  refreshFeedData = (isChildPost, id) => {
    const { showData, filterType, searchParam } = this.state;
    console.log("refreshFeedData", showData, filterType, id, isChildPost);
    if (showData === "feed") {
      if (isChildPost) {
        this.fetchChildSaysData(id);
      } else {
        this.fetchFeedData(filterType);
      }
    } else if (showData === "tag") {
      if (isChildPost) {
        this.fetchChildSaysData(id);
      } else {
        this.fetchAllTags();
      }
    } else if (showData === "ask") {
      if (isChildPost) {
        this.fetchChildSaysData(id);
      } else {
        this.fetchAllAsks();
      }
    } else if (showData === "search") {
      if (isChildPost) {
        this.fetchChildSaysData(id);
      } else {
        this.fetchSearchData(searchParam);
      }
    }
  };

  getProps = () => {
    const {
      feedData,
      contributors,
      trendingAsks,
      trendingTags,
      allTags,
      allTagData,
      allAsks,
      userSettings,
      filterType,
      isNotificationSelected,
      showData,
      searchData,
      selectedTagDetails,
      showModalForUpdate,
      modalTypeForUpdate,
      selectedCommunity,
      childSaysData,
      followData,
      loading,
      answersToYourAsk,
      asksToBeAnswered,
      privateTagsData,
      childSaysLoading,
      feedPageNumber,
      value,
      hasRestrictionSays,
      clearSearchHeader,
    } = this.state;
    return {
      loading,
      feedData,
      contributors,
      trendingAsks,
      trendingTags,
      allTags,
      allTagData,
      allAsks,
      userSettings,
      filterType,
      isNotificationSelected,
      clearSearchHeader,
      showData,
      searchData,
      selectedTagDetails,
      showModalForUpdate,
      modalTypeForUpdate,
      selectedCommunity,
      childSaysData,
      followData,
      answersToYourAsk,
      asksToBeAnswered,
      privateTagsData,
      childSaysLoading,
      feedPageNumber,
      value,
      hasRestrictionSays,
      resetHasRestrictionSays: this.resetHasRestrictionSays,
      showHideNotifications: this.showHideNotifications,
      fetchUserSettings: this.fetchUserSettings,
      filterPosts: this.filterPosts,
      fetchSelectedTagDetails: this.fetchSelectedTagDetails,
      setShowData: this.setShowData,
      goBack: this.goBack,
      onUpdateSelection: this.onUpdateSelection,
      hideUpdateModal: this.hideUpdateModal,
      fetchSearchData: this.fetchSearchData,
      onCommunityChange: this.onCommunityChange,
      fetchChildSaysData: this.fetchChildSaysData,
      postChildSayData: this.postChildSayData,
      fetchFollowData: this.fetchFollowData,
      fetchVoteUpStatus: this.fetchVoteUpStatus,
      fetchSaysDataForAsks: this.fetchSaysDataForAsks,
      fetchAsksToAnswer: this.fetchAsksToAnswer,
      askYourCommunity: this.askYourCommunity,
      fetchFeedData: this.fetchFeedData,
      fetchPrivateTagsData: this.fetchPrivateTagsData,
      deleteMessage: this.deleteMessage,
      refreshFeedData: this.refreshFeedData,
      fetchPageFeed: this.fetchPageFeed,
      changeValue: this.changeValue,
      clearAsksAndSays: this.clearAsksAndSays,
      deleteAnswersToAsksDialogPost: this.deleteAnswersToAsksDialogPost,
      deleteAsksToBeAnsweredDialogPost: this.deleteAsksToBeAnsweredDialogPost,
      fetchContributors: this.fetchContributors
    };
  };

  render() {
    const viewProps = this.getProps();

    return <ViewRenderer {...viewProps} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchUserDetails: () => {
    dispatch(SignInActions.fetchloginUser());
  },
  dispatchSettings: () => {
    dispatch(feedActions.fetchSettings());
  },
  // dispatchNotifications: (data) => {
  //   dispatch(notificationActions.receiveNotificationData(data))
  // }
});

const mapStateToProps = (state) => {
  const { userDetails } = state.SignInReducer;
  return { userDetails };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
