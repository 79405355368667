import React from 'react';
import './App.css';
import { Routes } from './Routes';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import SignupContextProvider from './context/SignupContext/SignupContextProvider';
import CommunityContextProvider from './context/CommunityContext/CommunityContextProvider';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Mulish',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
});


function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider clientId='1096351929813-b3sqsgi9t95dfbr7jhsruvsfvh8q6iac.apps.googleusercontent.com'>
        <ThemeProvider theme={theme}>
          <SignupContextProvider>
            <CommunityContextProvider>
              <Routes />
            </CommunityContextProvider>
          </SignupContextProvider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
