// import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core";
import askImage from "src/Assets/images/ask.svg";
import askHoverImage from "src/Assets/images/ask-hover.svg";
import sayImage from "src/Assets/images/say.svg";
import sayHoverImage from "src/Assets/images/say-hover.svg";
import updateImage from "src/Assets/images/update.svg";
import updateHoverImage from "src/Assets/images/update-hover.svg";
import tagImage from "src/Assets/images/tag.svg";
import tagHoverImage from "src/Assets/images/tag-hover.svg";

const useStyles = makeStyles({
  buttonContainer: () => ({
    "& .MuiButtonBase-root": {
      fontSize: "12px",
      background: "#EFEEED",
      color: " #4795DE",
      borderRadius: "20px",
      // padding: '10px',
      "&:hover": {
        fontSize: "12px",
        background: "#EFEEED",
        color: "#4795DE",
        borderRadius: "20px",
        // padding: '10px',
      },
    },
  }),
  defaultButton: () => ({}),
  shareUpdate: () => ({
    margin: "20px 0px 20px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    border: "none",
    borderRadius: "20px",
    border: "solid 1px #DEE0E1",
    fontWeight: 0,
    fontSize: "16px",
    padding: "12px 26px",
    // cursor: 'pointer'
    "& h5": {
      margin: 0,
    },
  }),
  trending: () => ({
    backgroundColor: "#FFFFFF",
    border: "none",
    borderRadius: "20px 0 0 20px",
    padding: "15px 26px",
    margin: "10px 0px 0px 0px",
    borderRadius: "20px",
    border: "solid 1px #DEE0E1",
    // height: "calc(100vh - 570px)",
    // overflowY: "scroll",
    "& h2": {
      fontSize: "20px",
    },
    "& .tagSection": {},
  }),
  seemore: () => ({
    color: "#373334",
    fontSize: "10px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& svg": {
      position: "relative",
      top: "0px",
      left: "4px",
    },
  }),
  feed: () => ({
    width: "100%",
    position: "relative",
    // marginTop: "8px",
    height: "153px",
    borderRadius: "0px 0px 25px 25px",
    backgroundPosition: "left center",
    backgroundSize: "cover",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "26px",
    position: "relative",
    // marginBottom: "17px",
    //   cursor: 'pointer'
  }),
  container: () => ({
    bottom: "0",
    position: "absolute",
    display: "flex",
    borderRadius: "0px 0px 25px 25px",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "rgb(243 242 240 / 85%)",
    padding: "5px 12px",
  }),
  moderator: () => ({
    display: "flex",
    alignItems: "center",
    paddingLeft: "24px",
  }),
  prospectimagesdiv: () => ({
    display: "flex",
  }),
  invitebutton: () => ({
    border: "1px solid #DEE0E1",
    outline: "none !important",
    borderRadius: "19px",
    marginRight: "10px",
    boxShadow: "-2px -3px 6px #ffffff, 0px 3px 6px #00000029",
    background: "#ffffff",
    padding: "3px 10px 6px 13px",
    color: "#373334 !important",
    fontSize: "17px",
    padding: "5px 25px",
  }),
  filterinput: () => ({
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 0px 0px 0px",
    '@media (max-width: 767px)': {
      flexDirection: "column",
    },
  }),
  post: () => ({
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    width: "48%",
    borderRadius: "20px",
    '@media (max-width: 767px)': {
      width: "100%",
      marginBottom: "10px"
    },
    // border: 'solid 1px #dee0e1',
    // "& svg": {
    //   position: "absolute",
    //   left: "280px",
    // },
  }),
  postinput: () => ({
    outline: "none",
    padding: "5px 5px 5px 0px",
    border: "0.2px solid lightgrey",
    width: "100%",
    borderRadius: "25px",
    // position: "relative",
    fontSize: "13px",
    color: "#707070",
    backgroundColor: "#fff",
  }),
  roundcontent: () => ({
    // position: 'absolute',
    right: 0,
    bottom: "0px",
    width: "50%",
    height: "56px",
    background: "#E5E4E2",
    // zIndex: '6',
    textAlign: "center",
    borderRadius: "25px 25px 0px 0px",
    justifyContent: "space-evenly",
    display: "inline-flex",
    padding: "4px",
    border: "solid 1px #DEE0E1",
    borderBottom: "none",
    '@media (max-width: 767px)': {
      width: "100%",
      marginBottom: "10px",
      borderRadius: "25px",
    },
  }),
  asksayNav: () => ({
    width: "100%",
    "& ul": {
      listStyle: "none",
      margin: "2px 0 0 0",
      padding: 0,
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      alignContent: "space-between",
      "& li": {
        display: "inline-block",
        flexBasis: "25%",
        "& a": {
          color: "#373334",
          textTransform: "uppercase",
          fontSize: "11px",
          // width: "75px",
          display: "block",
          textAlign: "center",
          paddingTop: "24px",
          cursor: "pointer",
          borderRadius: "26px",
          paddingBottom: "4px",
        },
      },
    },
  }),
  askSelectedImage: () => ({
    background: `url(${askImage}) center 4px no-repeat`,
  }),
  askHoverImage: () => ({
    background: `#2168B2 url(${askHoverImage}) center 4px no-repeat`,
    boxShadow: "0px 3px 6px #00000029",
    color: "#fff",
    borderRadius: "26px",
  }),
  saySelectedImage: () => ({
    background: `url(${sayImage}) center 4px no-repeat`,
  }),
  sayHoverImage: () => ({
    background: `#36B549 url(${sayHoverImage}) center 4px no-repeat`,
    boxShadow: "0px 3px 6px #00000029",
    color: "#fff",
    borderRadius: "26px",
  }),
  updateSelectedImage: () => ({
    background: `url(${updateImage}) center 4px no-repeat`,
  }),
  updateHoverImage: () => ({
    background: `#EF3F37 url(${updateHoverImage}) center 4px no-repeat`,
    boxShadow: "0px 3px 6px #00000029",
    color: "#fff",
    borderRadius: "26px",
  }),
  tagSelectedImage: () => ({
    background: `url(${tagImage}) center 4px no-repeat`,
  }),
  tagHoverImage: () => ({
    background: `#EFBA29 url(${tagHoverImage}) center 4px no-repeat`,
    boxShadow: "0px 3px 6px #00000029",
    color: "#fff",
    borderRadius: "26px",
  }),
  contributeContainer: () => ({
    background: "#FFFFFF",
    fontSize: "16px",
    padding: "12px 0 0 0",
    border: "1px solid #DEE0E1",
    borderRadius: "20px",
    marginBottom: "20px",
    "& .slick-slider": {
      border: "none",
      padding: "0 20px 10px 20px",
      borderRadius: "unset",
      background: "unset",
    },
  }),
  asks: () => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    listStyleType: "none",
    padding: "0px",
    backgroundColor: "#ffffff",
    borderRadius: "15px",
    "& li": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      minHeight: "60px",

      padding: "10px",
      width: "100%",
      marginBottom: "-6px",
    },
  }),
  askprofilecontnetName: () => ({
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "15px",
    color: "#373334",
    lineHeight: "1.2",
    marginBottom: 0,
  }),
  askavatar: () => ({
    borderRadius: "12px !important",
    width: "30px !important",
    height: "30px !important",
  }),
  askprofilecontnet: () => ({
    marginLeft: "7px",
  }),
  askprofilecontnetMessage: () => ({
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "13px",
    color: "#707070",
    lineHeight: "1.2",
    marginBottom: 0,
  }),
  comunityProfileArrowIcon: () => ({}),
  loader: () => ({
    margin: "0 auto !important",
    display: "block !important",
  }),
  profileHover: () => ({
    display: "block",
    position: "absolute",
    left: "68%",
    top: "2%",
    minWidth: "240px",
    zIndex: 2,
    background: "white 0% 0% no-repeat padding-box !important",
    boxShadow: "0px 3px 6px #00000029 !important",
    borderRadius: "15px",
    padding: "15px",
    fontSize: "12px",
    fontWeight: 400,
  }),
  backDefaultCommunityBtn: () => ({
    cursor: "pointer",
    position: "absolute",
    left: 5,
    '@media (min-width: 567px)': {
      left: 120,
    },
  }),
});

export default useStyles;
