import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { styled } from "@mui/material/styles";
import Api from "src/network";

const CustomCheckbox = styled(Checkbox)({
  color: '#1976d2'
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};

function UserList({ open, handleClose, tenantId, onAssignRole }) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getMembers = async () => {
      try {
        setLoading(true);
        const response = await Api.getMembersData(tenantId);
        if (response?.data?.userList && Array.isArray(response?.data?.userList)) {
          setUsers(response?.data?.userList);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    if (tenantId) {
      getMembers();
    }
  }, [tenantId]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: 4,
            right: 4,
          }}>
          <HighlightOffIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Select user
        </Typography>
        <Box>
          {loading && (
            <Box sx={{ height: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <Members
              list={users}
              onAssignRole={onAssignRole}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default UserList;

const Members = ({ list, onAssignRole }) => {
  const [checked, setChecked] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(null);

  const onCheckedHandler = id => {
    setChecked(id);
    setErrorMsg(null);
  };

  const assignRoleHandler = () => {
    if (checked) {
      onAssignRole && onAssignRole(checked);
    } else {
      setErrorMsg("Please select user to assign role");
    }
  };

  if (list && Array.isArray(list) && list.length === 0) {
    return (
      <Box sx={{ height: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography id="desc" variant='body1' >
          No data
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <List sx={{ width: '100%', maxWidth: 360, height: '70vh', overflow: 'scroll', bgcolor: 'background.paper' }}>
        {list.map((eachItem) => {
          const labelId = `id-${eachItem.id}${eachItem.displayName}`;
          return (
            <ListItem
              key={eachItem.id}
              disablePadding
            >
              <ListItemButton onClick={() => onCheckedHandler(eachItem?.id)} dense>
                <ListItemIcon>
                  <input
                    type='checkbox'
                    checked={checked === eachItem.id}
                    style={{ cursor: 'pointer' }}
                    readOnly
                  />
                  {/* <CustomCheckbox
                    // edge="start"
                    checked={checked === eachItem.id}
                    // tabIndex={-1}
                    color='primary'
                    size='small'
                    // disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  /> */}
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${eachItem.displayName}`} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Box mt={3} width='100%' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
        <Button
          type='button'
          variant='outlined'
          onClick={assignRoleHandler}
        >
          Assign Role
        </Button>
        {errorMsg && (
          <div style={{
            fontSize: 12,
            fontWeight: 400,
            color: 'red',
            marginTop: 4,
          }}>
            {errorMsg}
          </div>
        )}
      </Box>
    </>
  );
}