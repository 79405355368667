const auth = {
  verifyEmail: '/jsonindex/validateTempUserEmail.html',
  fetchLoginUser: '/jsonuser/getUserData',
  validateTempUserEmail: '/jsonindex/validateTempUserEmail.html',
  validateActivationStatus: '/index/validateActivationStatus.html?email=',
  fetchCountry: '/jsonindex/main.html?logParam=restrict',
  sendVerificationCode: '/jsonindex/sendVerificationCode.html?redirectTenantId=',
  resendVerificationCode: '/jsonindex/resendVerificationCode.html?emailType=Resend',
  createSixdigitcodeUser: '/index/createSixdigitcodeUser.html?sixDigitCode={{sixDigitCode}}&sixDigitCodeStatus=approved',
  validateFBToken: '/jsonindex/getFBUser?accessToken=',
  facebookLogin: '/jsonindex/addFacebook?version=2',
  validateGoogleToken: '/jsonindex/GmailMobileAppURL?oAuthCode={{googleToken}}&apptype=webapp',
  getUserData: '/jsonuser/land?redirectTenantId={{userTenantId}}&name={{userTenant}}',
  getTenantDetails: '/jsonindex/community-url?',
  fetchGmailContacts: '/gmail/redirect-gmail-contact-values',
};

const profile = {
  updateContactInfo: '/user/contactProfile.html',
  updateOptionalInfo: '/jsonuser/optional-profile',
  getUserAsks: '/jsonuser/more-activity-ask?breadCountLength=',
  getUserInvitations: '/jsonuser/getMoreActivityInvitations?breadCountLength=',
  inviteFriend: '/user/inviteFriend.html?',
  getPendingInvites: '/jsonuser/activity-receive-invitations?breadCountLength=',
  joinCommunity: '/user/communityurl.html?tid=',
  getUserTags: '/jsonuser/more-activity-hash?',
  getUserUpdates: '/jsonuser/more-activity-announcements?',
  getProfileChildSays: '/jsonuser/childSays.html?',
  postProfileChildSays: '/jsonmessage/message-say-bottom?',
  getChildHashTag: '/jsonuser/child-q',
  getUserSays: '/jsonuser/user-activity-says?',
  deleteProfilePost: '/jsonmessage/deleteMessageByUser.html',
  updateProfilePost: '/jsonmessage/update-message?',
  updateProfileInfo: 'user/updatebasicProfile.html',
  getProfile: 'jsonuser/profile?profileimgerr=undefined',
  shareUnshareUpdate: 'jsonuser/shareUnshare.html?',
  updateProfilePic: 'jsonuser/upload_profile_Image',
  unfollowActivityTag: '/jsonmessage/unFollowHash'
};

const feedData = {
  fetchFeedData: '/jsonmessage/all-feed.html',
  fetchUserSettings: '/jsonuser/userSettings.html',
  fetchUserNames: '/jsonmessage/searchUserNames.html?searchChars=',
  fetchContributors: '/jsonuser/contribute.html',
  editPost: '/jsonmessage/updateMessage.html?',
  editBio: '/user/updateUserBio.html?shortBio=',
  tagSearch: '/jsonmessage/tagSearch',
};

const invite = {
  inviteGmailContacts: 'user/inviteFriend.html',
  sendInvite: '/jsonuser/follow-private-hashtag',
  sendEmailInvite: '/user/inviteFriend.html?inviteEmailID=',
  postMail: '/user/sendMessagetoUser.html',
  requestToFollow: '/user/privateCommunityFollow.html'
};

const asksData = {
  fetchAsksData: '/jsonmessage/addMessageAsk.html',
  fetchAllAsks: '/jsonmessage/trending-items.html?task=asks&data=all',
  fetchTrendingAsks: '/jsonmessage/trending-items?task=asks',
};

const saysData = {
  fetchSaysData: '/jsonmessage/addMessageSayTop.html',
  fetchChildSaysData: '/jsonuser/childSays.html',
  postChildSayData: '/jsonmessage/addMessageSayBottom.html',
};

const tagsData = {
  fetchAllTags: '/jsonmessage/trending-items.html?task=tags&data=all',
  fetchTrendingTags: '/jsonmessage/trending-items?task=tags',
  fetchTagDetails: 'jsonmessage/showHashList.html?limiter=one&tag=%23TredTest&opt=OPEN&hashCount=4&countPosition=hCount',
  fetchPrivateTagsData: '/jsonmessage/hash-list?',
  checkIfTagExists: '/message/hashCheck.html?',
  postNewTag: '/message/postTag.html?rtrValue=',
};

const notifications = {
  fetchNotifications: '/jsonuser/notifications',
  getNotificationStatus: '/jsonuser/notifications.html?viewstatus=read&&id=',
  removeNotification: '/jsonuser/notifications.html?edit=remove&&notiid=',
  unfollowNotification: '/jsonuser/notifications.html?edit=unfollow&&msgid=',
}

const searchData = {
  getSearchData: '/jsonmessage/hSearch.html',
}

const moderator = {
  fetchModeratingCommunityList: '/jsonuser/moderating-Comm',
  makeAsModerator: '/jsonuser/moderatorRequest.html',
  getFollowingUsers: '/jsonuser/getFollowingUsers',
  setModeratorPermission: '/jsonuser/restriction',
  scheduleNewsletter: '/jsonuser/newsletter-schedule',
  setCommunityType: '/jsonuser/community-type',
  updateTimelinePhotoDesc: '/jsonuser/timeline-photo-desc',
  submitBoundary: '/jsonuser/updateTenantById',
  inviteFriend: '/user/inviteFriend',
  getModeratorActionItems: '/jsonuser/tid/',
  moderatorApproveDeny: '/jsonuser/moderator-approve-deny',
  fetchActivityLogData: '/jsonuser/report?',
  itemPostApproveDeny: 'jsonuser/moderatorApproveDeny',
  getModeratingCommunities: '/jsonuser/getModeratingCommunity?flag=myCommunities',
  uploadImages: '/index/upload-timeline-images',
  getMembersData: '/jsonuser/getFollowingUsers.html?tenantId=',
  blockUser: 'user/blockUserCommunity.html',
  getBlockedUsers: 'jsonuser/getBlockUsers.html',
  warnUser: 'jsonuser/warn-user-community',
  fetchInviteMembersData: 'jsonuser/invite-friend',
  inviteAsModerator: 'jsonuser/add-remove-moderator',
  raiseModeratorRequest: 'jsonuser/moderator-request',
}

const communities = {
  fetchNearByCommunities: '',
  changeCommunity: '/jsonuser/land.html?',
  askYourCommunity: '/jsonmessage/addOpenQuestion.html?messageId=',
  getCommunityMembers: '/jsonuser/members-of-community',
  getNearByUserCommunities: '/jsonuser/tenant-map?mapStatus=',
  getNearByCommunities: '/jsonuser/tenant-map?mapStatus=IndexNear',
  getCommunitiesAfterSignIn: '/jsonuser/tenant-map',
  setDefaultTenant: '/jsonuser/default-tenant',
  followUnfollowCommunity: '/jsonuser/follow-community',
}

const follows = {
  fetchFollowUnfollowData: '/jsonmessage/follow-unfollow',
  // fetchFollowUnfollowAskData: '/jsonuser/q-follows',
  fetchFollowerCount: '/jsonuser/message-id/',
}

const votes = {
  fetchVoteUpStatus: '/message/voteUp.html?'
}

const updatesData = {
  editUpdate: '/jsonuser/updateAnnouncements.html?',
  checkIfContainsTag: '/user/hashFind.html?',
  postNewUpdate: '/jsonuser/post-on-multi-community?',
}

const posts = {
  getReportPostStatus: '/user/getReportAbuseStatus.html',
  reportPost: '/jsonuser/report-abuse',
  deleteMessage: '/jsonmessage/deleteMessageByUser.html',
  deleteAskTagPostUnderSayActivity: '/jsonmessage/deleteMessageByUser.html',
  getActivityPostCount: 'jsonuser/activity'
}

const community = {
  followCommunity: '/jsonuser/saveFollowCommunity.html',
  updateDefaultTenant: '/jsonuser/updateDefaultTenant.html',
  getCommunity: '/jsonuser/community',
  getTenantList: '/jsonuser/tenant-list',
  createNewCommunity: '/index/newCommunity.html',
}

const settings = {
  updateUserSettings: '/user/notification.html',
  updatePassword: '/jsonuser/passProfile.html',
  getTimeLineImages: '/jsonuser/timeline-images',
}

const footer = {
  postContact: '/user/userSurvey.html',
}

const activity = {
  fetchImages: '/jsonindex/main?logParam=restrict',
  fetchActivityFeed: '/jsonmessage/all-feed?',
  fetchActivityAsks: '/jsonmessage/trending-items?',
  fetchActivityTags: '/jsonmessage/trending-items?',
  fetchActivityChildSays: '/jsonuser/child-says',
  fetchActivity: 'jsonuser/activity.html',
  editMessageActivityChildQ: 'jsonmessage/updateMessage.html?'
}

const timeline = {
  deleteTimeLineImage: 'jsonuser/deleteTimelineImage.html',
  updateCommunityInfo: 'jsonuser/updateTenantById.html',
}

const privateTags = {
  deletePrivateTag: 'jsonmessage/message-by-user',
  editPrivateTag: 'jsonmessage/update-message',
}

export {
  auth,
  profile,
  invite,
  feedData,
  asksData,
  saysData,
  tagsData,
  notifications,
  searchData,
  moderator,
  communities,
  follows,
  votes,
  updatesData,
  posts,
  community,
  settings,
  footer,
  activity,
  timeline,
  privateTags,
};
