import React, { Component, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import * as moderatorActions from "src/store/actions/Moderator/moderatoractions";
import * as feedActions from "src/store/actions/feedactions/feedActionCreator";
import * as SignInActions from "src/store/actions/SignInActions/SignInAction";
import "src/Assets/css/Moderator/ModeratorHeader.css";
import ModeratorHeader from "../Moderator/ModeratorHeader";
import ModeratorMenu from "./ModeratorMenu";
import EditCommunity from "./EditCommunity/EditCommunity";
import ManageMembers from "./ManageMembers/ManageMembers";
import ManageContent from "./ManageContent/ManageContent";
import Page, { PageEnum } from "src/layout/Page";

class Moderator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItem: "1",
      subMenuItem: "Action Items",
      community: {},
      tenantId: "",
      tenantName: "",
      type: "",
      isNotificationSelected: false,
      isPm: "0",
      actionItemsCount: 0,
    };
  }

  setType = (value) => {
    this.setState({ type: value });
  };

  setActionItemCount = (count) => {
    this.setState({actionItemsCount: count})
  }

  decreaseActionItemCount = () => {
    this.setState((prevState) => ({actionItemsCount: prevState.actionItemsCount - 1}))
  }
  
  onItemChange = (itemValue) => {
    this.setState({
      menuItem: itemValue,
    });
  };

  onSubItemChange = (subItemValue) => {
    this.setState({
      subMenuItem: subItemValue,
    });
  };

  onChangeCommunity = (community) => {
    const { tenantId, isPm, tenantName } = community || {};
    const isPrimaryModerator = String(isPm) === "1";
    // if it's not primary moderator - set menuItem to manage content
    if (!isPrimaryModerator) {
      this.setState({ tenantId, tenantName, isPm, menuItem: "1" });
    } else {
      this.setState({ tenantId, tenantName, isPm });
    }
  };

  componentDidMount() {
    // this.props.feedActions.fetchFeedData("?startlimit=0");
    this.props.SignInActions.fetchloginUser("");
    // this.props.feedActions.fetchcontirbutors("");
    this.props.feedActions.fetchSettings("");
    this.props.feedActions.fetchUserNames("@");
    this.props.moderatorActions.fetchModeratingCommunityList("");
    // this.props.moderatorActions.getTenantList();
  }

  // getTenantId = () => {
  //   return this.props.moderatingCommunitysData && this.props.moderatingCommunitysData.restrictions
  //     ? this.props.moderatingCommunitysData.restrictions[0].tenantId
  //     : this.state.tenantId;
  // };

  getTenantId = () => {
    const { tenantId } = this.state;
    // Check if tenantId is set
    if (tenantId) {
      return tenantId;
    } else if (
      this.props.moderatingCommunitysData &&
      this.props.moderatingCommunitysData.restrictions
    ) {
      // Use the first tenantId from moderatingCommunitysData if available
      return this.props.moderatingCommunitysData.restrictions[0].tenantId;
    } else {
      // Default value if neither tenantId nor moderatingCommunitysData is available
      return "";
    }
  };

  showHideNotifications = () => {
    this.setState({
      isNotificationSelected: !this.state.isNotificationSelected,
    });
  };
  render() {
    const { isNotificationSelected, tenantName, actionItemsCount } = this.state;
    return (
      <Page page={PageEnum.Moderator}>
        {/* {isNotificationSelected ? (
          <div className={`col-sm-3  ${styles.notifications}`}>
            <div className={styles.notificationscroll}>
              <Notifications />
            </div>
          </div>
        ) : null} */}
        <Container>
          <Box sx={{ height: "100vh" }}>
            <ModeratorHeader
              menuItem={this.state.menuItem}
              onMenuItemChange={this.onItemChange}
              onChangeCommunity={this.onChangeCommunity}
              tenantId={this.state.tenantId}
              tenantName={tenantName}
              isPm={this.state.isPm}
            />
            <div className="MiddleContent">
              <ModeratorMenu
                menuItem={this.state.menuItem}
                onSubMenuItemChange={this.onSubItemChange}
                onTypeChange={this.setType}
                type={this.state.type}
                subMenuItem={this.state.subMenuItem}
                tenantId={this.getTenantId()}
                tenantName={tenantName}
                actionItemsCount={actionItemsCount}
              />
              <div className="MenuContent">
                {this.state.menuItem === "1" ? (
                  <ManageContent
                    subMenuItem={this.state.subMenuItem}
                    tenantId={this.getTenantId()}
                    tenantName={tenantName}
                    type={this.state.type}
                    setActionItemCount={this.setActionItemCount}
                    decreaseActionItemCount={this.decreaseActionItemCount}
                  />
                ) : this.state.menuItem === "2" ? (
                  <ManageMembers
                    subMenuItem={this.state.subMenuItem}
                    tenantId={this.getTenantId()}
                    tenantName={tenantName}
                  />
                ) : (
                  <EditCommunity
                    subMenuItem={this.state.subMenuItem}
                    tenantId={this.getTenantId()}
                    tenantName={tenantName}
                  />
                )}
              </div>
            </div>
          </Box>
        </Container>
        {/* <ModeratorMenu/>
          <ModeratorContent/> */}
      </Page>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SignInActions: bindActionCreators(SignInActions, dispatch),
    feedActions: bindActionCreators(feedActions, dispatch),
    moderatorActions: bindActionCreators(moderatorActions, dispatch),
  };
}

function mapStateToProps(state) {
  const { userDetails } = state.SignInReducer;
  const { moderatingCommunitysData } = state.ModeratorReducer;
  return { userDetails, moderatingCommunitysData };
}

export default connect(mapStateToProps, mapDispatchToProps)(Moderator);
