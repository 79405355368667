import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
// import profileimage from "src/Assets/images/profileimage.png";
import saysoutlineimage from "src/Assets/images/noofsays.svg";
import follow from "src/Assets/images/follow.svg";
import dropdownarrow from "src/Assets/images/downarrow.svg";
import location from "src/Assets/images/userlocationtag.svg";
import dot from "src/Assets/images/dot.png";
import InviteFollowers from "./InviteFollowers";
// import smalllike from "src/Assets/images/like.svg";
import like from "src/Assets/images/like.svg";
import unlike from "src/Assets/images/unlike.svg";
import edit from "src/Assets/images/edit.svg";
// import share from "src/Assets/images/share.svg";
// import deleteicon from "src/Assets/images/deleteicon.png";
// import rating from "src/Assets/images/rating.svg";
import sendanemail from "src/Assets/images/sendanemail.png";
// import report from "src/Assets/images/report.png";
import privateinvite from "src/Assets/images/privateinvite.png";
// import sampledialogcancel from "src/Assets/images/Add.png";

// import locationpinchildpost from "src/Assets/images/locationpinchildpost.png";
import styles from "src/Assets/css/home/post.module.css";
import feedstyles from "src/Assets/css/home/feedpost.module.css";

import { Divider } from "@material-ui/core";
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import unfollow from "src/Assets/images/unfollow.svg";
import SimpleDialogDemo from "../../../Modules/Reusuablecomponents/CustomizedDialogs";
import ReusuableDeleteDialogmodal from "../../../Modules/Reusuablecomponents/ReusuableDeleteModal";
import ReusuableReportDialogmodal from "../../../Modules/Reusuablecomponents/ReusuableReportDialogmodal";
import ReportModal from "../../../Modules/Reusuablecomponents/reportModal";
import PostMessageValidation from "../../../Modules/Reusuablecomponents/PostMessageValidation";
import ReusuableSendaMailModal from "../../../Modules/Reusuablecomponents/ReusuableSendaMailModal";

import Childsay from "./ChildSay";
import API from "../../../network";
// import Dialog from "@material-ui/core/Dialog";
import { Modal } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import { Menu, MenuItem } from "@szhsin/react-menu";
import AvatarBadge from "src/Components/AvatarBadge";

const Post = (props) => {
  const {
    loading,
    data,
    isAskModal,
    childSaysData,
    fetchChildSaysData,
    followData,
    fetchFollowData,
    fetchVoteUpStatus,
    postChildSayData,
    fetchFeedData,
    deleteMessage,
    refreshFeedData,
    isPostOption,
    showData,
    allTagData,
    deleteAnswersToAsksDialogPost,
    deleteAsksToBeAnsweredDialogPost,
    flow,
    hasRestrictionSays,
    fetchContributors
  } = props;

  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const [listdata, setListData] = useState([]);
  const [isRead, setIsRead] = useState(false);
  const [currentSayId, setCurrentSayId] = useState(null);
  const [openTextArea, setOpenTextArea] = useState(false);
  const [editPostId, setEditPostId] = useState(null);
  const [updatedMsg, setUpdatedMsg] = useState("");
  const [emailPopupOpen, setEmailPopupOpen] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [textCount, setTextCount] = useState(0);
  const [selectedPost, setSelectedPost] = useState({});
  const [invitesShow, setInvitesShow] = useState(false);
  const [invite, setInvite] = useState(null);
  const [tenantId, setTenantId] = useState(null);
  const [msgId, setMsgId] = useState(null);
  const [charCount, setCharCount] = useState(0);
  const [errMsg, setErrMsg] = useState(null);

  useEffect(() => {
    let msg = updatedMsg || "";
    // msg = msg.replace(/\n/g, "");
    // msg = msg.replace(/\s/g, "");
    const count = msg.length;
    setCharCount(count);
  }, [updatedMsg]);

  useEffect(() => {
    let msg = textValue || "";
    // msg = msg.replace(/\n/g, "");
    // msg = msg.replace(/\s/g, "");
    const count = msg.length;
    setTextCount(count);
  }, [textValue]);

  useEffect(() => {
    setListData(props.listdata);
  }, [props.listdata]);

  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  const onSendMail = async (id) => {
    if (textValue?.trim()) {
      await API.postMail(id, textValue);
      setEmailPopupOpen(false);
    }
  };
  const setUpdatedText = (e) => {
    const newValue = e.target.value;
    setTextValue(newValue);
  };

  const onSayClick = (id, e) => {
    setIsRead(true);
    setCurrentSayId(id);
    let sayid = `saySelection${id}`;
    e.preventDefault();
    if (document.getElementById(sayid).style.display === "block") {
      document.getElementById(sayid).style.display = "none";
    } else {
      fetchChildSaysData(id);
      document.getElementById(sayid).style.display = "block";
    }
  };

  const updateList = (id, followStatus) => {
    const newData = [...listdata];
    const modifiedData = newData.find((x) => x.id === id);
    modifiedData.followStatus = followStatus.includes("0") ? "N" : "Y";
    modifiedData.followCount = followStatus.includes("0")
      ? modifiedData.followCount - 1
      : modifiedData.followCount - 1 + 2;
    setListData(newData);
  };

  const followunfollow = (id, followstatus) => {
    updateList(id, followstatus);
    fetchFollowData(id, followstatus);
  };

  const likeUnlike = (id) => {
    const newData = [...listdata];
    const modifiedData = newData.find((x) => x.id === id);
    modifiedData.UserLikeStatus = "InActive";
    modifiedData.voteUp = modifiedData.voteUp - 1 + 2;
    setListData(newData);
  };

  const onLikeUnlikeSelection = (id, cache) => {
    if (props.isActionDisabled) return;
    likeUnlike(id);
    fetchVoteUpStatus(id, cache);
  };

  const getWordWithHash = (sentence) => {
    const regex = /(?:^|\s)(#\w+)\b/g;

    // Find all matches in the sentence
    const matches = sentence.match(regex);

    // Return the array of matched words (or an empty array if no matches)
    return matches ? matches.map((match) => match.trim()) : [];
  };

  const isValid = (oldMsg, newMsg) => {
    if (newMsg === oldMsg) {
      setErrMsg("At least one modification required");
      return false;
    }

    if (props?.isTag) {
      const previousHashArr = getWordWithHash(oldMsg || "");
      const previousHash =
        Array.isArray(previousHashArr) && previousHashArr.length > 0
          ? previousHashArr[0]
          : "";

      const currentHashArr = getWordWithHash(newMsg || "");
      const currentHash =
        Array.isArray(currentHashArr) && currentHashArr.length > 0
          ? currentHashArr[0]
          : "";

      if (previousHash !== currentHash) {
        setErrMsg("You can't create a New hash tag");
        return false;
      }
    }

    return true;
  };

  const saveMessage = async (id, defaultMsg) => {
    try {
      if (!isValid(defaultMsg, updatedMsg)) {
        return;
      }

      const newUpdatedMsg = updatedMsg.length > 0 ? updatedMsg : defaultMsg;
      const updatedMessageList = listdata.map((item) => {
        if (item.id === id) {
          return { ...item, Message: newUpdatedMsg }; // Update the message
        }
        return item; // Return unchanged items
      });
      setListData(updatedMessageList);
      await API.editPost(id, newUpdatedMsg);
      setEditPostId(null);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelUpdateMsg = () => {
    setEditPostId(null);
    setErrMsg(null);
  };

  const updateMessage = (e) => {
    setUpdatedMsg(e.target.value);
    setErrMsg(null);
  };

  const deletePost = (id) => {
    const newUpdatedList = listdata.filter((item) => item.id !== id);
    setListData(newUpdatedList);
  };

  const onEditSelection = (id, message) => {
    setOpenTextArea(true);
    setEditPostId(id);
    setUpdatedMsg(message);
  };

  const updateSayCount = (id) => {
    const updatedMessageList = listdata.map((item) => {
      if (item.id === id) {
        return { ...item, sayCount: item.sayCount + 1 }; // Update the message
      }
      return item; // Return unchanged items
    });
    if ((data && data.tenantSayStatus !== "E") || hasRestrictionSays !== true) {
      setListData(updatedMessageList);
    }
  };

  const deleteSayCount = (id) => {
    const updatedMessageList = listdata.map((item) => {
      if (item.id === id) {
        return { ...item, sayCount: item.sayCount - 1 }; // Update the message
      }
      return item; // Return unchanged items
    });
    setListData(updatedMessageList);
  };

  const onClickSendEmailOption = (selectedPost) => {
    setEmailPopupOpen(true);
    setSelectedPost(selectedPost);
  };

  const onInviteSelection = (tenantId, id) => {
    setInvitesShow(true);
    setInvite(true);
    setTenantId(tenantId);
    setMsgId(id);
  };

  const enabled = updatedMsg && charCount > 0 && charCount <= 280;
  const sendBtnEnabled = textCount > 0 && textCount <= 280;
  return (
    <React.Fragment>
      {data && Object.keys(data).length !== 0 ? (
        listdata ? (
          listdata.map((result) => (
            <div className={styles.post} key={result?.id}>
              <div className={feedstyles.avatarDetailsMainContainer}>
                <div className={feedstyles.avatarDetailsContainer}>
                  {/* <Avatar
                    src={`${profileImagePath}${result.uimage}`}
                    className={styles.avatar}
                    variant="square"
                  /> */}
                  <AvatarBadge
                    src={`${profileImagePath}${result.uimage}`}
                    className={styles.avatar}
                    variant="square"
                    alt={userDetails?.userData?.displayName}
                    isPrimaryModerator={result.isPM == 1}
                    isModerator={result.isModerator == 1 && result.isPM == 0}
                  />
                  <div style={{ marginLeft: "10px" }}>
                    {isAskModal !== "isAskModal" ? (
                      <span className={feedstyles.profilename}>
                        {result.displayName}
                        {
                          <div className={feedstyles.profilehover}>
                            <div
                              className={feedstyles.profilehoverimageandcount}
                            >
                              {/* <Avatar
                                src={`${profileImagePath}${result.uimage}`}
                                className={styles.avatar}
                              /> */}
                              <AvatarBadge
                                src={`${profileImagePath}${result.uimage}`}
                                className={styles.avatar}
                                alt={userDetails?.userData?.displayName}
                                isPrimaryModerator={result.isPM == 1}
                                isModerator={
                                  result.isModerator == 1 && result.isPM == 0
                                }
                              />
                              <div style={{ marginLeft: "10px" }}>
                                <p className={feedstyles.profilehovername}>
                                  {result.displayName}
                                </p>
                                <p className={feedstyles.profilecount}>
                                  ASK {result?.mainuserdata?.askcount ?? 0} |
                                  SAY {result?.mainuserdata?.saycount ?? 0} |
                                  UPDATE{" "}
                                  {result?.mainuserdata?.updatecount ?? 0} | TAG{" "}
                                  {result?.mainuserdata?.hashtagcount ?? 0}
                                </p>
                              </div>
                            </div>
                            <p className={feedstyles.profilehovername}>
                              {result.shortBio}
                            </p>
                          </div>
                        }
                      </span>
                    ) : (
                      <>
                        <span className={feedstyles.profilename}>
                          {result.displayName}
                        </span>
                        {
                          <div className={feedstyles.profilehover}>
                            <div
                              className={feedstyles.profilehoverimageandcount}
                            >
                              {/* <Avatar
                                src={`${profileImagePath}${result.uimage}`}
                                className={styles.avatar}
                              /> */}
                              <AvatarBadge
                                src={`${profileImagePath}${result.uimage}`}
                                className={styles.avatar}
                                alt={userDetails?.userData?.displayName}
                                isPrimaryModerator={result.isPM == 1}
                                isModerator={
                                  result.isModerator == 1 && result.isPM == 0
                                }
                              />
                              <div style={{ marginLeft: "10px" }}>
                                <p className={feedstyles.profilehovername}>
                                  {result.displayName}
                                </p>
                                <p className={feedstyles.profilecount}>
                                  ASK {result?.mainuserdata?.askcount ?? 0} |
                                  SAY {result?.mainuserdata?.saycount ?? 0} |
                                  UPDATE{" "}
                                  {result?.mainuserdata?.updatecount ?? 0} | TAG{" "}
                                  {result?.mainuserdata?.hashtagcount ?? 0}
                                </p>
                              </div>
                            </div>
                            <p className={feedstyles.profilehovername}>
                              {result.shortBio}
                            </p>
                          </div>
                        }
                      </>
                    )}
                    {result.locName ? (
                      <span className={styles.locationandtime}>
                        <p className={styles.profiletime}>
                          {result.Message_Time} {result.TenantName}
                        </p>
                        <img src={dot} alt="dot" />
                        <img src={location} alt="location" />
                        {result.locName}
                      </span>
                    ) : (
                      <span className={styles.locationandtime}>
                        <p className={styles.profiletime}>
                          {result.Message_Time} {result.TenantName}
                        </p>
                      </span>
                    )}
                  </div>
                </div>

                {/* edited te things from here  */}
                {!props?.isActionDisabled && (
                  <div className={feedstyles.feeddropdown}>
                    {/* <button className={feedstyles.feeddropdownbutton}>
                    <img src={dropdownarrow} alt="dropdown" />
                  </button> */}
                    <Menu
                      menuButton={
                        <IconButton
                          style={{
                            padding: 0,
                            width: 30,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            outline: "none",
                          }}
                        >
                          <MdKeyboardArrowDown fontSize={25} />
                        </IconButton>
                      }
                      transition
                      direction="bottom"
                      align="start"
                      position="auto"
                      viewScroll="auto"
                      arrow
                      menuStyle={{
                        backgroundColor: "white",
                        borderRadius: 20,
                      }}
                    >
                      {result.User_ID &&
                        userDetails &&
                        userDetails.userData && (
                          <>
                            {result.User_ID !== userDetails.userData.User_ID ? (
                              <>
                                {userDetails.userData.moderatorStatus ===
                                "1" ? (
                                  <>
                                    <MenuItem>
                                      <ReusuableDeleteDialogmodal
                                        type={"OpenASK"}
                                        id={result.id}
                                        deleteMessage={deleteMessage}
                                        // deletePost={deletePost}
                                        deleteAnswersToAsksDialogPost={
                                          deleteAnswersToAsksDialogPost
                                        }
                                        deleteAsksToBeAnsweredDialogPost={
                                          deleteAsksToBeAnsweredDialogPost
                                        }
                                        fetchContributors={fetchContributors}
                                      />
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        onClickSendEmailOption(result)
                                      }
                                    >
                                      <img
                                        src={sendanemail}
                                        style={{ marginRight: 8 }}
                                        alt="sendemail"
                                      />
                                      <span style={{ fontSize: 14 }}>
                                        Send email to {result.displayName}
                                      </span>
                                    </MenuItem>
                                  </>
                                ) : (
                                  <>
                                    <MenuItem>
                                      <ReportModal
                                        id={result.id}
                                        param={"AskorSay"}
                                      />
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        onClickSendEmailOption(result)
                                      }
                                    >
                                      <img
                                        src={sendanemail}
                                        style={{ marginRight: 8 }}
                                        alt="sendemail"
                                      />
                                      <span style={{ fontSize: 14 }}>
                                        Send email to {result.displayName}
                                      </span>
                                    </MenuItem>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <MenuItem
                                  onClick={() =>
                                    onEditSelection(result.id, result?.Message)
                                  }
                                >
                                  <img
                                    src={edit}
                                    style={{ marginRight: 8 }}
                                    alt="edit"
                                  />
                                  <span style={{ fontSize: 14 }}>Edit</span>
                                </MenuItem>
                                <MenuItem>
                                  <ReusuableDeleteDialogmodal
                                    type={"OpenASK"}
                                    id={result.id}
                                    deleteMessage={deleteMessage}
                                    // deletePost={deletePost}
                                    deleteAnswersToAsksDialogPost={
                                      deleteAnswersToAsksDialogPost
                                    }
                                    deleteAsksToBeAnsweredDialogPost={
                                      deleteAsksToBeAnsweredDialogPost
                                    }
                                    fetchContributors={fetchContributors}
                                  />
                                </MenuItem>
                              </>
                            )}
                          </>
                        )}
                    </Menu>
                  </div>
                )}
              </div>
              <div className={styles.avatardescription}>
                {editPostId === result.id ? (
                  <div>
                    <textarea
                      rows="2"
                      cols="50"
                      value={updatedMsg}
                      onChange={(e) => updateMessage(e)}
                      className={styles.EditPostMessage}
                    ></textarea>
                    {errMsg && (
                      <span style={{ fontSize: 11, color: "red" }}>
                        {errMsg}
                      </span>
                    )}
                    <div className={styles.saveCancelButtonContainer}>
                      <button
                        onClick={() => saveMessage(result.id, result.Message)}
                        className={styles.editPost}
                        disabled={!enabled}
                        style={{ opacity: enabled ? 1 : 0.6 }}
                      >
                        SAVE
                      </button>
                      <button
                        onClick={cancelUpdateMsg}
                        className={styles.cancelPost}
                      >
                        Cancel
                      </button>
                      <span>
                        <label
                          style={{ color: charCount > 280 ? "red" : "black" }}
                        >
                          {charCount}
                        </label>
                        <label>/280</label>
                      </span>
                    </div>
                  </div>
                ) : (
                  <p
                    dangerouslySetInnerHTML={{ __html: result.Message }}
                    id={`hide${result.id}`}
                  ></p>
                )}
              </div>
              {/* Keyword_ID	"Important Update" OpenSAY */}
              <div className={styles.followerandcount}>
                {isPostOption === "SAY" ||
                showData === "tag" ||
                showData === "singleTrendingTag" ||
                showData === "ask" ||
                ((showData === "search" || showData === "tagsearch") &&
                  (result.Keyword_ID === "OpenASK" ||
                    result.Keyword_ID === "Open ASK" ||
                    result.Keyword_ID === "hashTAG" ||
                    result.Keyword_ID === "#TAG")) ? (
                  <div className={feedstyles.followCountMainContainer}>
                    <div className={feedstyles.followCountContainer}>
                      {result?.followCount ? (
                        result.followCount !== 0 ? (
                          <div>
                            <SimpleDialogDemo
                              followCount={result.followCount}
                              id={result.id}
                              status={followData}
                            />
                          </div>
                        ) : (
                          <div>
                            <span className={styles.followcount}>
                              {result.followCount} followers
                            </span>
                          </div>
                        )
                      ) : (
                        `0 followers`
                      )}
                      {(result.hashTagType === "private" ||
                        result.Keyword_ID === "X") && (
                        <span className={feedstyles.hashTagType}>
                          <span>
                            <img src={dot} alt="dot" />
                          </span>{" "}
                          Private
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={feedstyles.followCountContainer}>
                    <div>
                      {result?.voteUp ? (
                        result.voteUp !== 0 ? (
                          <SimpleDialogDemo
                            voteUp={result.voteUp}
                            id={result.id}
                          />
                        ) : (
                          <span className={styles.followcount}>
                            {result.voteUp} likes
                          </span>
                        )
                      ) : (
                        `0 likes`
                      )}
                    </div>
                  </div>
                )}
                {/* {result.Keyword_ID === "Open ASK" ||
                result.Keyword_ID === "OpenASK" ||
                result.Keyword_ID === "ASK" ? (
                  <div className={styles.askcount}>
                    <span>{result.mainuserdata.askcount}</span>
                  </div>
                ) : result.Keyword_ID === "SAY" ||
                  result.Keyword_ID === "OpenSAY" ? (
                  <div className={styles.saycount}>
                    <span></span>
                  </div>
                ) : result.Keyword_ID === "Important Update" ||
                  result.Keyword_ID === "impupdate" ? (
                  <div className={styles.updatecount}>
                    <span></span>
                  </div>
                ) : result.Keyword_ID === "#tag" ||
                  result.Keyword_ID === "hashTAG" ||
                  result.Keyword_ID === "H" ? (
                  <div className={styles.tagcount}>
                    <span>{result.mainuserdata.hashtagcount}</span>
                  </div>
                ) : (
                  ""
                )} */}
                {showData === "tag" ||
                showData === "singleTrendingTag" ||
                ((showData === "search" || showData === "tagsearch") &&
                  (result.Keyword_ID === "hashTAG" ||
                    result.Keyword_ID === "#TAG")) ? (
                  <div className={styles.tagcount}>
                    <span>{result.childcotentreadcount}</span>
                  </div>
                ) : isPostOption === "SAY" ||
                  showData === "ask" ||
                  ((showData === "search" || showData === "tagsearch") &&
                    (result.Keyword_ID === "OpenASK" ||
                      result.Keyword_ID === "Open ASK")) ? (
                  <div className={feedstyles.readCountContainer}>
                    <p>{result.childcotentreadcount}</p>
                  </div>
                ) : (
                  <div className={styles.saycount}></div>
                )}
              </div>

              <Divider variant="fullWidth" />
              {isPostOption === "SAY" ||
              showData === "tag" ||
              showData === "singleTrendingTag" ||
              showData === "ask" ||
              ((showData === "search" || showData === "tagsearch") &&
                (result.Keyword_ID === "OpenASK" ||
                  result.Keyword_ID === "Open ASK" ||
                  result.Keyword_ID === "#TAG" ||
                  result.Keyword_ID === "hashTAG")) ? (
                <div className={styles.saysandfollow}>
                  <div className={styles.noOfsays}>
                    {/* <img src-={dots} alt="saysdots"/> */}
                    <a onClick={(e) => onSayClick(result.id, e)}>
                      <img src={saysoutlineimage} alt="numberof says" />
                      {result.sayCount === 1 || result.postCount === 1 ? (
                        <>
                          <span>{result.sayCount} SAY</span>
                        </>
                      ) : result.sayCount === 0 || result.postCount === 0 ? (
                        <span>SAY</span>
                      ) : (
                        <span>{result.sayCount} SAYs</span>
                      )}
                    </a>
                  </div>
                  <div className={styles.noOffollows}>
                    {result.followStatus === "N" ? (
                      <>
                        <div
                          className={`${feedstyles.noOffollows}`}
                          id={`1${result.id}`}
                        >
                          <a
                            className={styles.followtext}
                            onClick={() =>
                              followunfollow(result.id, "isFollow=1")
                            }
                          >
                            <img src={follow} alt="follow" />{" "}
                            <span>Follow</span>
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={`${feedstyles.noOffollows} `}
                          id={`0${result.id}`}
                        >
                          <a
                            className={styles.followtext}
                            onClick={() =>
                              followunfollow(result.id, "isFollow=0")
                            }
                          >
                            <img src={unfollow} alt="unfollow" />{" "}
                            <span>Unfollow</span>
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                  {(result.hashTagType === "private" ||
                    result.Keyword_ID === "X") &&
                  (userDetails.userData.moderatorStatus === "1" ||
                    userDetails.userData.isAdmin === "1") ? (
                    <div
                      className={feedstyles.priavteinvite}
                      onClick={() =>
                        onInviteSelection(
                          result.TenantID ||
                            result.tenantID ||
                            allTagData.tenantID,
                          result.id
                        )
                      }
                    >
                      <img src={privateinvite} alt="Private Invite" />
                      <span style={{ cursor: "pointer" }}>Invite</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className={styles.likeunlike}>
                  {result.UserLikeStatus === "Active" ? (
                    <>
                      <a
                        onClick={() =>
                          onLikeUnlikeSelection(result.id, "cacheUpdate")
                        }
                        id={`like${result.id}`}
                      >
                        <img src={like} alt="like" />
                        Like
                      </a>
                      {/* <a
                        className={feedstyles.likedisplay}
                        id={`unlike${result.id}`}
                      >
                        <img src={unlike} alt="unlike" />
                        Like
                      </a> */}
                    </>
                  ) : (
                    <a>
                      <img src={unlike} alt="unlike" />
                      Like
                    </a>
                  )}
                </div>
              )}
              {/* .......................childsaydata ......................*/}

              <div
                className={styles.childsayselection}
                id={`saySelection${result.id}`}
              >
                {/* .....when we click on say something on post ......................*/}
                {currentSayId === result.id && (
                  <PostMessageValidation
                    listdata={listdata}
                    refreshFeedData={refreshFeedData}
                    fetchChildSaysData={fetchChildSaysData}
                    id={result.id}
                    type={"childSay"}
                    userImageSrc={`${profileImagePath}${userDetails?.userData?.uimage}`}
                    postChildSayData={postChildSayData}
                    updateSayCount={updateSayCount}
                    fetchContributors={fetchContributors}
                  />
                )}

                {/* <div className={styles.comment}>
                                        <Avatar src={profileimage} variant="square" />

                                        <input className={styles.commentinput} placeholder="Say something..." type="text" />
                                        <a><img src={locationpinchildpost} /></a>
                                    </div>
                                    <div>
                                        <button className={styles.childpost}>POST</button>
                                    </div> */}
                {currentSayId === result.id && (
                  <Childsay
                    id={result.id}
                    deleteMessage={deleteMessage}
                    refreshFeedData={refreshFeedData}
                    loading={loading}
                    childSaysData={childSaysData}
                    fetchVoteUpStatus={fetchVoteUpStatus}
                    flow={flow}
                    sayOrAskPost={"sayOrAskPost"}
                    deleteSayCount={deleteSayCount}
                    fetchContributors={fetchContributors}
                  />
                )}
              </div>
            </div>
          ))
        ) : (
          <p>No data found</p>
        )
      ) : (
        ""
      )}

      {invitesShow && (
        <InviteFollowers
          show={invitesShow}
          onHide={() => setInvitesShow(false)}
          invite={invite}
          tenantid={tenantId}
          msgid={msgId}
        />
      )}

      <Modal show={emailPopupOpen} style={{ backgroundColor: "#00000060" }}>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#fff" }}
          onClick={() => setEmailPopupOpen(false)}
        >
          <Modal.Title>Send email</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <textarea
              rows="6"
              cols="44"
              className={styles.sendamailtextarea}
              id={`textInputMail${selectedPost?.id}`}
              // onInput={() => myFunction(props.id)}
              value={textValue}
              onChange={(e) => setUpdatedText(e)}
              placeholder={`What would you like to say to ${selectedPost?.displayName}`}
              // maxLength={280}
            />
            <label style={{ textAlign: "end", width: "100%" }}>
              <label style={{ color: textCount > 280 ? "red" : "black" }}>
                {textCount}
              </label>
              /280
            </label>
            <button
              type="button"
              id={`buttonforEdit${selectedPost?.id}`}
              className={styles.sendaMail}
              style={{ left: 0, opacity: sendBtnEnabled ? 1 : 0.6 }}
              onClick={() => onSendMail(selectedPost?.id)}
              disabled={!sendBtnEnabled}
            >
              Send
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Post;
