// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#MessageModal .modal-content{
    padding:0;
margin: auto;
text-align: center;
width: 800px !important;
min-height: 50px;
}
#MessageModal .title{
    font-weight: 500;
    margin: auto;
    padding: 10px;
    font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Assets/css/MessageModal/MessageModal.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb,YAAY;AACZ,kBAAkB;AAClB,uBAAuB;AACvB,gBAAgB;AAChB;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,eAAe;AACnB","sourcesContent":["#MessageModal .modal-content{\n    padding:0;\nmargin: auto;\ntext-align: center;\nwidth: 800px !important;\nmin-height: 50px;\n}\n#MessageModal .title{\n    font-weight: 500;\n    margin: auto;\n    padding: 10px;\n    font-size: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
