import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Invition from "src/Assets/images/SentInvitation.svg";
import upArrow from "src/Assets/images/UpButton.png";
import "./InvitesPending.css";
import "./InvitesPending.css";
import * as SignInActions from "src/store/actions/SignInActions/SignInAction";
import activityStyle from "src/Assets/css/Activity/activity.module.css";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import API from "../../../../network";

// const Container = styled("div")({
//   backgroundColor: "#E9FFFE",
//   padding: "10px",
//   borderRadius: "10px",
// });

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "8px",
});

const Button = styled("div")({
  backgroundColor: "#EFEEED",
  flex: 1,
  display: "flex",
  padding: "5px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "20px",
  cursor: "pointer",
  opacity: 1,
  boxShadow: "1px 3px 8px #9E9E9E",
  ":hover": {
    boxShadow: "1px 3px 1px #9E9E9E",
  },
});

const Title = styled(Typography)({
  fontSize: "14px",
  fontWeight: "500",
});

const SubTitle = styled(Typography)({
  fontSize: "14px",
  color: "#333333",
});

const InviteStatus = styled(Typography)({
  fontSize: "13px",
  fontWeight: "500",
});

const SeeMore = styled(Typography)({
  fontSize: "13px",
  fontWeight: "500",
  cursor: "pointer",
  ":hover": {
    color: "grey",
  },
  marginTop: "4px",
  alignSelf: "flex-end",
});

const InviteMessage = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  padding: "10px",
  borderRadius: "15px",
  marginTop: "6px",
});

const SeeInviteButton = styled(Typography)({
  textAlign: "center",
  color: "#4795DE",
  fontSize: "14px",
  cursor: "pointer",
  margin: "auto",
  marginTop: "10px",
});

const InvitesPending = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [data, setData] = useState([]);
  const toggleList = () => {
    const count = 0;
    API.getPendingInvites(count)
      .then((res) => {
        setData(res.data.receivedinvitations);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => toggleList(), []);
  return (
    <div>
      {!isExpanded && (
        <div className="AskActivity" onClick={() => setIsExpanded(true)}>
          <div className="usable-container">
            <img src={Invition} alt="Ask-icon" className="askicon" />
            <p className="ask mt-3">Invites Received</p>
          </div>
          <div className="usable-container">
            <span className="number ">{data.length}</span>
            <img src={upArrow} alt="DownArrow" className="DownArrow" />
          </div>
        </div>
      )}
      {isExpanded && (
        <div className="AskActivity_active">
          <div
            className="color-combination"
            onClick={() => setIsExpanded(false)}
          >
            <div className="usable-container">
              <img src={Invition} alt="Ask-icon" className="askicon" />
              <p className="ask mt-3">Invites Received</p>
            </div>
            <div className="usable-container">
              <span className="number ">{data.length}</span>
              <img src={upArrow} alt="DownArrow" className="DownArrow" />
            </div>
          </div>
          <div className="invite-received-postcard-main-container">
            {data.length > 0 ? (
              data.map((item, index) => (
                <Tile
                  data={item}
                  key={`key-id-${item.id}`}
                  toggleList={toggleList}
                />
              ))
            ) : (
              <div className="no-data-container">
                <p>No invites are available to show</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const Tile = (props) => {
  const dispatch = useDispatch();
  const { toggleList } = props;
  const [showMore, setShowMore] = useState(false);
  const [ignored, setIgnored] = useState(false);
  const [seeInvite, setSeeInvite] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [showJoinedMsg, setShowJoinedMsg] = useState(false);
  const [showInviteBtn, setShowInviteBtn] = useState(true);
  const [followStatusResponse, setFollowStatusResponse] = useState({});
  // const join = (communityId) => {
  //   API.joinCommunity(communityId)
  //     .then((res) => {
  //       setData(res.data.receivedinvitations);
  //       console.log(res);
  //     })
  //     .catch((error) => console.log(error));
  // };

  // const join = (communityId) => {
  //   API.joinCommunity(communityId)
  //     .then((res) => {
  //       // setData(res.data.receivedinvitations);
  //       console.log(res);
  //     })
  //     .catch((error) => console.log(error));
  // };

  // const join = async (id, name, status) => {
  //   let formdata = new FormData();
  //   formdata.append("communityID", id);
  //   formdata.append("communityName", name);
  //   // formdata.append("followStat", status === 0 ? 1 : 0);
  //   formdata.append("followStat", status === "Not Joined" ? 1 : 0);
  //   formdata.append("sourceApp", "WebApp");
  //   await API.followUnfollowCommunity(formdata)
  //   console.log(`join function called`)
  //     .then((res) => {
  //       console.log(`res`)
  //       console.log(res)
  //       console.log(`res over`)
  //       if (res.data.status === "Follow Done") {
  //         setFollowStatusResponse(res);
  //         alert(`You are now following ${name}`);
  //         setTimeout(() => {
  //           toggleList();
  //           dispatch(SignInActions.fetchloginUser());
  //         }, 1000);
  //       }
  //     })
  //     .catch((err) => {});
  // };
  const join = async (id, name, status) => {
    let formdata = new FormData();
    formdata.append("communityID", id);
    formdata.append("communityName", name);
    formdata.append("followStat", status === "Not Joined" ? 1 : 0);
    formdata.append("sourceApp", "WebApp");

    try {
      const res = await API.followUnfollowCommunity(formdata);
      if (res.data.status === "New Saved") {
        setFollowStatusResponse(res);
        alert(`You are now following ${name}`);
        setTimeout(() => {
          toggleList();
          dispatch(SignInActions.fetchloginUser());
        }, 1000);
      }
    } catch (err) {
      console.error("Error in join function:", err);
      alert("Failed to update follow status. Please try again.");
    }
  };

  const ignore = () => {
    setIgnored(true);
    setShowMore(false);
  };
  const openNotJoinedMsg = () => {
    setShowMsg(true);
    setShowInviteBtn(false);
  };
  const openJoinedMsg = () => {
    setShowJoinedMsg(true);
    setShowMsg(false);
  };
  const {
    inviteStatus,
    emailAddress,
    Message_Time,
    subject,
    message,
    TenantID,
    communityName,
  } = props.data;

  return (
    <div
      className={`invite-received-postcard ${
        inviteStatus !== "Not Joined" || ignored ? "match-color-card" : ""
      }`}
    >
      <div style={{ display: "flex", marginBottom: "6px" }}>
        <div style={{ flex: 1 }}>
          <Title>
            From:{" "}
            <span className="invite-received-email-text">{emailAddress}</span>
          </Title>
          <SubTitle>
            <span className="invite-received-time-text">
              {Message_Time} ago in the community
            </span>
          </SubTitle>
        </div>
        <InviteStatus>
          <span className="invite-received-invite-text">{inviteStatus}</span>
        </InviteStatus>
      </div>
      <Divider />
      {inviteStatus === "Not Joined" && !ignored && (
        <InviteMessage>
          <SubTitle>
            Subject:{" "}
            <span className="invite-received-subject-text">{subject}</span>
          </SubTitle>
          {showMore && (
            <SubTitle>
              <span>Message: </span>
              <span className="invite-received-subject-text">{message}</span>
            </SubTitle>
          )}
          <SeeMore onClick={() => setShowMore(!showMore)}>
            {showMore ? (
              <SubTitle className="invite-received-email-text">{""}</SubTitle>
            ) : (
              <SubTitle className="invite-received-email-text">
                See More
              </SubTitle>
            )}
          </SeeMore>
        </InviteMessage>
      )}
      {inviteStatus !== "Not Joined" && showJoinedMsg && (
        <InviteMessage>
          <SubTitle>
            Subject:{" "}
            <span className="invite-received-subject-text">{subject}</span>
          </SubTitle>
          {showMore && (
            <SubTitle>
              <span>Message: </span>
              <span className="invite-received-subject-text">{message}</span>
            </SubTitle>
          )}
          <SeeMore onClick={() => setShowMore(!showMore)}>
            {showMore ? (
              <SubTitle className="invite-received-email-text">{""}</SubTitle>
            ) : (
              <SubTitle className="invite-received-email-text">
                See More
              </SubTitle>
            )}
          </SeeMore>
        </InviteMessage>
      )}
      {ignored && showMsg && (
        <InviteMessage>
          <SubTitle>
            Subject:{" "}
            <span className="invite-received-subject-text">{subject}</span>
          </SubTitle>
          {showMore && (
            <SubTitle>
              <span>Message: </span>
              <span className="invite-received-subject-text">{message}</span>
            </SubTitle>
          )}
          <SeeMore onClick={() => setShowMore(!showMore)}>
            {showMore ? (
              <SubTitle className="invite-received-email-text">{""}</SubTitle>
            ) : (
              <SubTitle className="invite-received-email-text">
                See More
              </SubTitle>
            )}
          </SeeMore>
        </InviteMessage>
      )}
      {inviteStatus === "Not Joined" ? (
        <>
          {!ignored && (
            <ButtonContainer>
              <Button
                onClick={() => {
                  join(TenantID, communityName, inviteStatus);
                  // window.open(process.env.REACT_APP_SERVICES_BASE_URL, "_blank");
                }}
                style={{ marginRight: "10px" }}
              >
                <Typography>Join</Typography>
              </Button>

              <Button onClick={ignore} style={{ marginLeft: "10px" }}>
                <Typography>Ignore</Typography>
              </Button>
            </ButtonContainer>
          )}
          {ignored && showInviteBtn && (
            <SeeInviteButton onClick={openNotJoinedMsg}>
              See Invite
            </SeeInviteButton>
          )}
          {ignored && showMsg && (
            <Button
              onClick={() => {
                join(TenantID, communityName, inviteStatus);
                // window.open(process.env.REACT_APP_SERVICES_BASE_URL, "_blank");
              }}
              style={{ marginTop: "10px" }}
            >
              <Typography>Join</Typography>
            </Button>
          )}
        </>
      ) : (
        <SeeInviteButton onClick={openJoinedMsg}>See Invite</SeeInviteButton>
      )}
    </div>
  );
};

export default InvitesPending;
