// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.member-list-container {
  overflow-x: hidden;
}
.invite-button {
  color: white;
  border: none;
  padding: 10px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none !important;
  transition: background-color 0.3s;
}
.invite-member-container {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}
.profile-picture {
  margin-right: 15px !important;
  margin-left: 5px !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50px !important;
}

.invite-button.enabled {
  background: linear-gradient(to left, #1976d2, #1e88e5, #98b9d8);
  cursor: pointer;
}

.invite-button.disabled {
  background-color: #98b9d8;
  cursor: not-allowed !important;
}
.community-image-container{
  position: relative;
}
.community-card-img{
  width: 100%;
  height: 13vh;
  border-radius: 15px;
}
.crown-symbol-in-community-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  margin-left: 6px;
  margin-top: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/components/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,wBAAwB;EACxB,iCAAiC;AACnC;AACA;EACE,8BAA8B;EAC9B,2BAA2B;AAC7B;AACA;EACE,6BAA6B;EAC7B,2BAA2B;EAC3B,uBAAuB;EACvB,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,+DAA+D;EAC/D,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,8BAA8B;AAChC;AACA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".member-list-container {\n  overflow-x: hidden;\n}\n.invite-button {\n  color: white;\n  border: none;\n  padding: 10px;\n  border-radius: 18px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  outline: none !important;\n  transition: background-color 0.3s;\n}\n.invite-member-container {\n  margin-bottom: 10px !important;\n  margin-top: 10px !important;\n}\n.profile-picture {\n  margin-right: 15px !important;\n  margin-left: 5px !important;\n  height: 40px !important;\n  width: 40px !important;\n  border-radius: 50px !important;\n}\n\n.invite-button.enabled {\n  background: linear-gradient(to left, #1976d2, #1e88e5, #98b9d8);\n  cursor: pointer;\n}\n\n.invite-button.disabled {\n  background-color: #98b9d8;\n  cursor: not-allowed !important;\n}\n.community-image-container{\n  position: relative;\n}\n.community-card-img{\n  width: 100%;\n  height: 13vh;\n  border-radius: 15px;\n}\n.crown-symbol-in-community-card {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 25px;\n  height: 25px;\n  margin-left: 6px;\n  margin-top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
