import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import "src/Assets/css/Activity/ActivityScreen.css";
import Profile from "./Profile/Profile";
import Community from "./Community/Community";
import Activites from "./Activites/Activites";
import * as mapActions from "../../store/actions/mapactions/mapAction";
import styles from "src/Assets/css/settings/settings.module.css";
import Page, { PageEnum } from "src/layout/Page";
import { CommunityContext } from "src/context/CommunityContext/CommunityContext.js";
import API from "../../network";

function ActivityScreen() {
  const dispatch = useDispatch();
  const [isNotificationSelected, setIsNotificationSelected] = useState(false);
  const communityContext = useContext(CommunityContext);
  const settingsData = useSelector((state) => state.voteupReducer.settingsData);

  useEffect(() => {
    dispatch(mapActions.getNearByCommunitysData());
  }, []);

  const showHideNotifications = () => {
    setIsNotificationSelected(!isNotificationSelected);
  }

  const onSkopicClick = async () => {
    try {
      const response = await API.changeCommunity(settingsData?.userTenant, settingsData?.userTenantId);
      if (response) {
        communityContext?.onChangeCommunity({
          tenantId: settingsData?.userTenantId,
          tenantName: settingsData?.userTenant,
        });
      };
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Page page={PageEnum.Profile} onSkopicClick={onSkopicClick}>
      {/* {isNotificationSelected ? (
        <div className={`col-sm-3  ${styles.notifications}`}>
          <div className={styles.notificationscroll}>
            <Notifications />
          </div>
        </div>
      ) : null} */}
      <div>
        <Profile />
        <div className={styles.communityAndActivityMainContainer}>
          <Community />
          <Activites />
        </div>
      </div>
    </Page>
  );
}

export default ActivityScreen;
