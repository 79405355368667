// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Switch_tabs__tS5y4{
    width:100%;
    max-width: 300px;
    margin:60px auto 0 auto;
   
}
.Switch_darkmode__eELzD{
    background-color: #1a1919;
}
.Switch_tablist__exyZ0 { 
    display: flex; 
    justify-content: space-around;
    width:90%;
    /* padding:3px ; */
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px auto;
    background-color:#F3F2F0;
    border: none;
    border-radius: 20px;
    font-size: 1rem;
    font-weight: 300;
}
/* .footerElemnts{
    margin-top: 20px;
} */
/* Media Quries */
/* @media (max-width:1024px){
    .tablist{
        padding:3px ;
        padding-top:10px;
    }  
}
@media (max-width:768px){
    .tablist{
        padding:3px ;
    }  
} */
@media (max-width:425px){
    .Switch_tabs__tS5y4{
        width:100%;
        margin-top:0px;
        padding-top:60px;
    }
    /* .tablist{
        padding:3px ;
    }    */
}`, "",{"version":3,"sources":["webpack://./src/Assets/css/LandingPage/Switch.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,uBAAuB;;AAE3B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,6BAA6B;IAC7B,SAAS;IACT,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,wBAAwB;IACxB,wBAAwB;IACxB,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;AACA;;GAEG;AACH,iBAAiB;AACjB;;;;;;;;;;GAUG;AACH;IACI;QACI,UAAU;QACV,cAAc;QACd,gBAAgB;IACpB;IACA;;UAEM;AACV","sourcesContent":[".tabs{\r\n    width:100%;\r\n    max-width: 300px;\r\n    margin:60px auto 0 auto;\r\n   \r\n}\r\n.darkmode{\r\n    background-color: #1a1919;\r\n}\r\n.tablist { \r\n    display: flex; \r\n    justify-content: space-around;\r\n    width:90%;\r\n    /* padding:3px ; */\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin: 0 auto 20px auto;\r\n    background-color:#F3F2F0;\r\n    border: none;\r\n    border-radius: 20px;\r\n    font-size: 1rem;\r\n    font-weight: 300;\r\n}\r\n/* .footerElemnts{\r\n    margin-top: 20px;\r\n} */\r\n/* Media Quries */\r\n/* @media (max-width:1024px){\r\n    .tablist{\r\n        padding:3px ;\r\n        padding-top:10px;\r\n    }  \r\n}\r\n@media (max-width:768px){\r\n    .tablist{\r\n        padding:3px ;\r\n    }  \r\n} */\r\n@media (max-width:425px){\r\n    .tabs{\r\n        width:100%;\r\n        margin-top:0px;\r\n        padding-top:60px;\r\n    }\r\n    /* .tablist{\r\n        padding:3px ;\r\n    }    */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": `Switch_tabs__tS5y4`,
	"darkmode": `Switch_darkmode__eELzD`,
	"tablist": `Switch_tablist__exyZ0`
};
export default ___CSS_LOADER_EXPORT___;
