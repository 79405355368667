import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import backbutton from "src/Assets/images/locationtagbackbutton.png";

import styles from "src/Assets/css/home/post.module.css";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import Search from "../comboBox";
import Geocode from "react-geocode";
// import LocationActiveIcon from "../../../Assets/markers/locationActive.png";
import LocationIcon from "../../../Assets/markers/location.png";
import LiveLocationIcon from "../../../Assets/images/liveLocationIcon.png";
import LocationActiveIcon from "src/Assets/markers/locationActive.png";
import customMapStyles from "..//map/customMapStyles";
import axios from "axios";

const useStyles = makeStyles({
  paperWidthSm: {
    borderRadius: 25,
    width: 650,
    overflow: "hidden",
    maxWidth: "none",
  },
});

//maps functionality

function SimpleDialog(props) {
  const classes = useStyles();

  const [libraries] = useState(["places"]);
  Geocode.setApiKey("AIzaSyAXEHjz5uTArWEC0q8-zpu_xXuHXJ2zOl8");
  const mapContainerStyle = {
    // width: "45vw",
    height: "60vh",
    borderRadius: 25,
  };
  const center = {
    lat: 17.385044,
    lng: 78.486671,
  };
  const getMapTypeControls = () => {
    const defaultMapOptions = {
      styles: customMapStyles,
    };

    return {
      ...defaultMapOptions,
      mapTypeControl: false,
      rotateControl: false,
      fullscreenControl: false,
      zoomControl: true,
      streetViewControl: false,
      disableDefaultUI: false,
    };
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAXEHjz5uTArWEC0q8-zpu_xXuHXJ2zOl8",
    libraries: ["places", "drawing"],
  });

  const [markers, setMarkers] = useState({});
  const [selected, setSelected] = useState("");
  const [isAddress, setAddress] = useState("");
  const [mapClick, setMapClick] = useState(false);
  const [isEnable, setIsEnable] = useState(false);
  const [curLocation, setCurLocation] = useState({ lat: 0, lng: 0 });

  const onMapClick = (event) => {
    setMapClick(true);
    setIsEnable(true);
    setMarkers({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
      time: new Date(),
    });
    Geocode.fromLatLng(event.latLng.lat(), event.latLng.lng()).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(address);
        setAddress(address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const getCurrentLocation = async () => {
    const res = await axios.get("http://ip-api.com/json");
    if (res.status === 200)
      setCurLocation({ lat: res.data?.lat, lng: res.data?.lon });
  };

  const success = (position) => {
    const lat = position?.coords?.latitude;
    const lng = position?.coords?.longitude;
    setCurLocation({ lat, lng });
  };

  const error = (position) => {
    getCurrentLocation();
  };
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
    if (navigator?.geolocation) {
      navigator?.geolocation.getCurrentPosition(success, error);
    }
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  const { onClose, open, edit, isLng, isLat } = props;

  const handleClose = () => {
    onClose(false);
    setMarkers({});
    setIsEnable(false);
    setAddress("");
  };

  const onLocationSubmit = () => {
    // console.log(isAddress)
    props.isLocationSelect(isAddress);
    props.setLat(markers.lat);
    props.setLng(markers.lng);
    handleClose();
    setSelected(null);
    setMarkers({});
    setIsEnable(false);
    setAddress("");
    !edit && props.onLocationSubmit();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      key={`simpleDialog2${props.id}`}
      classes={{ paperWidthSm: classes.paperWidthSm }}
    >
      <div className={styles.mapcontainer}>
        <div className={styles.addlocation}>
          <a onClick={handleClose}>
            <img src={backbutton} alt="backbutton" />
          </a>
          <h5 className={styles.locationheading}>
            {edit ? "Edit " : "Add "}Location
          </h5>
          <button
            className={styles.locationsubmit}
            onClick={onLocationSubmit}
            style={{
              border: "none",
              background: "none",
              position: "relative",
              top: "6px",
              color: isAddress && "#4795DE",
            }}
            disabled={isAddress ? false : true}
          >
            Done
          </button>
        </div>
        <Search
          panTo={panTo}
          isLocationSelect={props.isLocationSelect}
          setLat={props.setLat}
          setLng={props.setLng}
          id={props.id}
          key={`LocationSearch${props.id}`}
          setAddress={setAddress}
          setMarkers={setMarkers}
          mapClick={mapClick}
          setMapClick={setMapClick}
          setIsEnable={setIsEnable}
        />
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={12}
            center={
              isAddress
                ? {
                    lat: parseFloat(markers.lat),
                    lng: parseFloat(markers.lng),
                  }
                : isLat !== "" && isLng !== ""
                ? { lat: parseFloat(isLat), lng: parseFloat(isLng) }
                : curLocation
            }
            options={getMapTypeControls()}
            onClick={onMapClick}
            onLoad={onMapLoad}
          >
            {isAddress ? (
              <Marker
                position={{
                  lat: parseFloat(markers.lat),
                  lng: parseFloat(markers.lng),
                }}
                // onMouseOver={() => {
                //   setSelected(markers);
                //   // console.log(markers.lat,markers.lng)
                // }}
                // onMouseOut={() => {
                //   setSelected("");
                // }}
                icon={{
                  url: LocationActiveIcon,
                  scaledSize: new window.google.maps.Size(30, 30),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(15, 15),
                }}
              />
            ) : (
              <Marker
                position={
                  isLat !== "" && isLng !== ""
                    ? { lat: parseFloat(isLat), lng: parseFloat(isLng) }
                    : curLocation
                }
                icon={{
                  url: LiveLocationIcon,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                // onClick={(e) => setShowInfoWindow(true)}
              />
            )}
            {isEnable ? (
              <InfoWindow
                position={{
                  lat: parseFloat(markers.lat),
                  lng: parseFloat(markers.lng),
                }}
                onCloseClick={() => {
                  setSelected("");
                  setIsEnable(false);
                }}
              >
                <div>
                  <p>{isAddress}</p>
                </div>
              </InfoWindow>
            ) : null}
          </GoogleMap>
        )}
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function LocationUpdate(props) {
  const { open, setOpen, edit, isLat, isLng } = props;
  //   const [open, setOpen] = React.useState(false);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* {
        <a className={`${!props.isPostings && styles.locationpinchildpost}`}>
          <img
            src={locationpinchildpost}
            onClick={handleClickOpen}
            className={styles.updatePopupLocationIcon}
          />
        </a>
      } */}
      <SimpleDialog
        open={open}
        onClose={handleClose}
        isLocationSelect={props?.isLocationSelect}
        setLat={props?.setLat}
        setLng={props?.setLng}
        id={props?.id}
        onLocationSubmit={props.onLocationSubmit}
        edit={edit}
        isLat={isLat}
        isLng={isLng}
      />
    </React.Fragment>
  );
}
