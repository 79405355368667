// import Header from "../Modules/header/header";
import { Link } from "react-router-dom";
import logo from "src/Assets/images/LandingPageImages/skopic-image.png";
import settingsStyles from "src/Assets/css/settings/settings.module.css";
const ModeratorGuidelines = () => {
  function UserGuideLines() {
    return (
      <div className={settingsStyles.headerContainer}>
        <div className={settingsStyles.headerContainer}>
          <Link to={"/"}>
            <div>
              <img src={logo} alt="Skopic-Logo" width={90} height={48} />
            </div>
          </Link>
        </div>
        <div className="innercontainer">
          <h3 className={settingsStyles.privacyheading}>
            Moderator Guidelines
          </h3>
          <div className={settingsStyles.TermsofService}>
            <p>
              In addition to reviewing Skopic privacy, terms of use, and user
              guidelines, please note these additional aspects for leading to
              moderate your community. We do our best to keep Skopic safe, but
              we cannot guarantee it. We need your help to do that, which
              includes the following commitments.
            </p>

            <p>
              The most important things to adopt to be a successful moderator
              are:
            </p>

            <p>
              1. You will not send or otherwise post unauthorized commercial
              communications (such as spam) on Skopic.
            </p>

            <p>
              2. You will not collect users' content or information, or
              otherwise access Skopic, using automated means (such as harvesting
              bots, robots, spiders, or scrapers) without our permission.
            </p>

            <p>
              3. You will not engage in unlawful multi-level marketing, such as
              a pyramid scheme, on Skopic.
            </p>

            <p>4. You will not upload viruses or other malicious code.</p>

            <p>
              5. You will not solicit login information or access an account
              belonging to someone else.
            </p>

            <p>6. You will not bully, intimidate, or harass any user.</p>

            <p>
              7. You will not post content that: is hateful, threatening, or
              pornographic; incites violence; or contains nudity or graphic or
              gratuitous violence.
            </p>

            <p>
              8. You will not develop or operate a third-party application
              containing alcohol-related or other mature content (including
              advertisements) without appropriate age-based restrictions.
            </p>

            <p>
              9. You will not use Skopic to do anything unlawful, misleading,
              malicious, or discriminatory.
            </p>

            <p>
              10. You will not do anything that could disable, overburden, or
              impair the proper working of Skopic, such as a denial of service
              attack.
            </p>

            <p>
              11. You will not facilitate or encourage any violations of this
              agreement.
            </p>

            <p>Thank You.</p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      {/* <Header /> */}
      {<UserGuideLines />}
    </div>
  );
};
export default ModeratorGuidelines;
