import React from "react";
import { Avatar, Box } from "@mui/material";
import { BsArrowRight } from "react-icons/bs";
import contributerstick from "src/Assets/images/contributerstick.svg";
import feedstyles from "src/Assets/css/home/feedpost.module.css";
import homeSidebarStyle from "src/Assets/css/home/Homepagerightsidebar.module.css";
import Contributors from "src/pages/HomePage/components/Contributors";
import styles from "./ViewActBeforeSignIn.module.css";
import useStyles from "./styles";
import forwardIcon from "src/Assets/images/Next-Button.svg";
import PrimaryModeratorCrown from "src/Assets/images/PrimaryModeratorCrown.svg";
import SecondaryModeratorCrown from "src/Assets/images/SecondaryModeratorCrown.svg";

const profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

function RightSide({
  contributors,
  handleClickEvent,
  showBio,
  contributorBio,
  isSignIn,
  isEnable,
  isTagTempData,
  isAskTempData,
  handleSeeAll,
}) {
  const { shareUpdate } = useStyles();

  return (
    <div>
      <span className={homeSidebarStyle.contributertick}>
        <p>Contributors</p>
        <img src={contributerstick} alt="tick" />
      </span>
      <div>
        <Contributors
          handleClickEvent={handleClickEvent}
          contributorsData={contributors}
        />
        {showBio && (
          <div className={homeSidebarStyle.profilehover}>
            <div className={feedstyles.profilehoverimageandcount}>
              <Avatar
                src={`${profileImagePath}${contributorBio?.uImage}`}
                className={styles.avatar}
              />
              <div>
                <p className={feedstyles.profilehovername}>
                  {contributorBio?.displayName}
                </p>
                <p className={feedstyles.profilecount}>
                  SAY {contributorBio?.say} | UPDATE {contributorBio?.iUpdate} |
                  #SAY {contributorBio?.hashSay}
                </p>
              </div>
            </div>
            <p className={feedstyles.profilehovercontent}>
              {contributorBio?.shortBio}
            </p>
          </div>
        )}
      </div>

      <Box
        className={shareUpdate}
        onClick={() => {}}
        style={{
          cursor: isSignIn && !isEnable ? "pointer" : "not-allowed",
        }}
        bgcolor="#ffffff"
      >
        <h5 className={`h17 bold dark-gray`}>Share UPDATE</h5>
        <a>
          <BsArrowRight />
        </a>
      </Box>
      <Box
        className={shareUpdate}
        onClick={() => {}}
        style={{
          cursor: isSignIn && !isEnable ? "pointer" : "not-allowed",
        }}
      >
        <h5 className={`h17 bold dark-gray`}>Create TAG</h5>
        <a>
          <BsArrowRight />
        </a>
      </Box>
      <div className={homeSidebarStyle.Trending}>
        <h2>See what is trending</h2>
        <div className={homeSidebarStyle.tagSection}>
          <h6>TAGs</h6>
          {isTagTempData && Object.keys(isTagTempData).length !== 0 ? (
            <div>
              {isTagTempData.map((trendinglist) => (
                <div
                  key={trendinglist.id}
                  className={homeSidebarStyle.trendingTagNameContainer}
                >
                  <p> {trendinglist.hash_tag_name} </p>
                  {trendinglist.Keyword_ID === "X" && (
                    <div className={homeSidebarStyle.trendingTagPrivateStatus}>
                      P
                    </div>
                  )}
                </div>
              ))}
              <a
                className={homeSidebarStyle.Seemore}
                onClick={() => handleSeeAll("tags")}
              >
                See ALL{" "}
                <img src={forwardIcon} alt="forward-Icon" className="ml-2" />
              </a>
            </div>
          ) : (
            <p className={homeSidebarStyle.message}>No Tags are Trending</p>
          )}
        </div>

        <div className={homeSidebarStyle.asksection}>
          <h6>ASKs</h6>
          {isAskTempData && Object.keys(isAskTempData).length !== 0 ? (
            isAskTempData?.map((trendinglist) => (
              <ul
                className={homeSidebarStyle.asks}
                key={`trendingask-key-${trendinglist?.id}`}
                onClick={() => handleSeeAll("asks")}
              >
                <li>
                  <div className={homeSidebarStyle.askImageContainer}>
                    <Avatar
                      variant="square"
                      src={`${profileImagePath}${trendinglist.uimage}`}
                      className={homeSidebarStyle.askavatar}
                    />
                    {(trendinglist.isModerator === "1" ||
                      trendinglist.isPM === "1") && (
                      <img
                        src={
                          trendinglist.isModerator === "1" &&
                          trendinglist.isPM === "1"
                            ? PrimaryModeratorCrown
                            : trendinglist.isModerator === "1"
                            ? SecondaryModeratorCrown
                            : null
                        }
                        className={homeSidebarStyle.crownSymbol}
                        alt="Moderator Crown"
                      />
                    )}
                  </div>
                  <div className={homeSidebarStyle.askprofilecontnet}>
                    <p className={homeSidebarStyle.askprofilecontnetName}>
                      {trendinglist.displayName}
                    </p>
                    <p className={homeSidebarStyle.askprofilecontnetMessage}>
                      {trendinglist?.Message}
                    </p>
                  </div>
                </li>
              </ul>
            ))
          ) : (
            <p>No Data</p>
          )}
          <a
            className={homeSidebarStyle.Seemore}
            onClick={() => handleSeeAll("asks")}
          >
            See ALL{" "}
            <img src={forwardIcon} alt="forward-Icon" className="ml-2" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default RightSide;
