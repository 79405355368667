import React, { useEffect, useState, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import "./invitations.css";
import gmail from "src/Assets/images/gmail.svg";
import facebook from "src/Assets/images/fb.svg";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
import MessageModal from "../../../../Utils/messageModal";
import { set } from "js-cookie";
import API from "../../../../network";
// import GmailContactsModal from "./GmailContactsModal";
import FacebookPost from "./FacebookPost";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {
  updateIsNewUser,
  fetchloginUser,
} from "src/store/actions/SignInActions/SignInAction";
import { emailRegex } from "src/Constants/regex";
import { Chip, CircularProgress } from "@material-ui/core";
import { SignupContext } from 'src/context/SignupContext/SignupContext';

var emailsParam = "";

const ModalType = {
  Invite: "INVITE",
  // Gmail: "GMAIL",
  Facebook: "FACEBOOK",
  Message: "MESSAGE",
};

function Invitation(props) {
  const userDetails = useSelector(
    (state) => state.SignInReducer?.userDetails || {}
  );
  const { inviteToNewCommunityData } = props;
  const isNewUser = useSelector((state) => state.SignInReducer?.isNewUser);
  const dispatch = useDispatch();
  const [mail, setMail] = useState("");
  const [subject, setSubject] = useState("");
  const [inviteMsg, setInviteMsg] = useState("");
  const [textmsg, setTextmsg] = useState("");
  const [msg, setMsg] = useState("");
  const [allEmails, setAllEmails] = useState([]);
  const [isLoading, setIsLaoding] = useState(false);
  // const [closeInvites, setCloseInvites] = useState(null);
  // const [messageShow, setMessageShow] = useState(false);
  // const [isVisibleGContsModal, setIsVisibleGContsModal] = useState(false);
  const [fbAccessToken, setFbAccessToken] = useState("");
  // const [isVisibleFbPostModal, setIsVisibleFbPostModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const fbappId = "skopic_dev_24";
  const signupContext = useContext(SignupContext)

  // useEffect(() => {
  //   dispatch(fetchloginUser());
  // }, []);

  useEffect(() => {
    if (props.show) {
      dispatch(fetchloginUser());
      setModalType(ModalType.Invite);
    } else {
      setModalType(null);
    }
  }, [props.show]);

  const emailChange = (e) => {
    setMail(e.target.value);
  };

  const onBlurEmailField = () => {
    validateEmail();
  };

  const handleKeyDown = (evt) => {
    if ([" ", ";", ","].includes(evt.key)) {
      evt.preventDefault();
      validateEmail();
    }
  };

  const validateEmail = () => {
    const newEmail = mail.trim();
    if (!newEmail) return;
    const isValid = emailRegex.test(newEmail);
    const id = `ID${(new Date()).getTime()}`;
    setAllEmails(
      (prevState) => [...prevState, { id, email: newEmail, isValid }]
    );
    setMail("");
  };

  // useEffect(() => {
  //   const {
  //     tenantName = "",
  //     tenantId = "",
  //     displayName = "",
  //   } = userDetails?.userData || {};
  //   if (props.invite) {
  //     setSubject(`Invitation to join ${tenantName} via Skopic`);
  //     setTextmsg(
  //       `Hi,%0A%0AI request you to join ${tenantName} on Skopic. It is quick and easy to Sign Up.%0A%0AClick the link below to join ${tenantName}.%0A${process.env.REACT_APP_SERVICES_API}user/communityurl.html?tid=${tenantId}%0A%0AThank you,%0A${displayName}`
  //     );
  //   }
  //   setInviteMsg(
  //     `Hi,\n\nI request you to join ${tenantName} on Skopic. It is quick and easy to Sign Up.\n\nClick the link below to join ${tenantName}.\n${process.env.REACT_APP_SERVICES_API}user/communityurl.html?tid=${tenantId}\n\nThank you,\n${displayName}`
  //   );
  // }, [props.invite, userDetails]);

  useEffect(() => {
    const {
      tenantName = "",
      tenantId = "",
      displayName = "",
    } = userDetails?.userData || {};

    const timer = setTimeout(() => {
      if (props.invite) {
        setSubject(`Invitation to join ${tenantName} via Skopic`);
        setTextmsg(
          `Hi,%0A%0AI request you to join ${tenantName} on Skopic. It is quick and easy to Sign Up.%0A%0AClick the link below to join ${tenantName}.%0A${process.env.REACT_APP_SERVICES_API}user/communityurl.html?tid=${tenantId}%0A%0AThank you,%0A${displayName}`
        );
      }

      setInviteMsg(
        `Hi,\n\nI request you to join ${tenantName} on Skopic. It is quick and easy to Sign Up.\n\nClick the link below to join ${tenantName}.\n${process.env.REACT_APP_SERVICES_API}user/communityurl.html?tid=${tenantId}\n\nThank you,\n${displayName}`
      );
    }, 100);

    return () => clearTimeout(timer); // Clean up the timeout on component unmount or dependencies change
  }, [props.invite, userDetails]);

  const closeHandler = () => {
    setMail("");
    setAllEmails([]);
    closeModal();
  };

  const closeModal = () => {
    setModalType(null);
    props?.onHide();
    dispatch(updateIsNewUser(false));
  };

  const onInviteHandler = async (e) => {
    if (isLoading) return;
    try {
      e.preventDefault();

      let validEmails = allEmails.filter((ele) => ele?.isValid).map((ele) => ele?.email);
      if (mail && mail.trim()) {
        validEmails = [...validEmails, mail];
      }

      emailsParam = validEmails.join(",");
      setIsLaoding(true);
      const res = await API.sendEmailInvite(emailsParam, textmsg, subject);
      setMsg(res.data);
      setModalType(ModalType.Message);
      setMail("");
      setAllEmails([]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLaoding(false);
    }
  };

  const isSumbitHandler = () => {
    const newEmail = mail?.trim();
    const hasValidEmail = allEmails?.some((ele) => ele?.isValid);
    if ((newEmail && emailRegex.test(newEmail)) || hasValidEmail) {
      return true;
    } else {
      return false;
    }
  };

  const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/contacts.readonly&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=https://dev.skopic.com/home&client_id=1096351929813-b3sqsgi9t95dfbr7jhsruvsfvh8q6iac.apps.googleusercontent.com`;

  const handleAuthRequest = () => {
    window.location.href = googleAuthUrl;
  };

  const onFetchGmailContacts = () => {
    // setModalType(ModalType.Gmail);
    handleAuthRequest();
  };

  const responseFacebook = (response) => {
    if (response?.accessToken) {
      setFbAccessToken(response.accessToken);
      setModalType(ModalType.Facebook);
    } else {
      console.error("Facebook login failed");
    }
  };

  const handleChipClick = () => {
    console.info('You clicked the Chip.');
  };

  const handleChipDelete = (id) => {
    const copiedEmails = [...allEmails];
    setAllEmails(copiedEmails.filter((ele) => ele?.id !== id) || []);
  };

  const isEnabaled = isSumbitHandler();

  return (
    <>
      <Modal
        {...props}
        show={modalType === ModalType.Invite}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="InviteStyles"
        backdrop="static"
      >
        <div className="Invitation-modal">
          <Modal.Header closeButton onClick={closeHandler}>
            <Modal.Title
              style={{
                marginTop:
                  isNewUser ||
                    (inviteToNewCommunityData?.isPM === "Y" &&
                      inviteToNewCommunityData?.invitetonewcommunity === "yes")
                    ? "15px"
                    : "0px",
              }}
              id="contained-modal-title-vcenter"
              className="title"
            >
              {isNewUser
                ? "Your Sign Up is successful. Invite friends to join your community."
                : inviteToNewCommunityData?.isPM === "Y" &&
                  inviteToNewCommunityData?.invitetonewcommunity === "yes"
                  ? `Your requested community ${userDetails?.userData?.tenantName || ""
                  } is active on Skopic. Invite friends to join your community.`
                  : "Invite members"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={onInviteHandler}>
              <div className="Invitaion">
                <div className="InvitationEmail">
                  <label className="mar-lft-1rem">Email:<span style={{ color: "red" }}>*</span></label>
                  <div className="emailChip">
                    {allEmails?.map((each) => (
                      <Chip
                        label={each?.email}
                        onClick={handleChipClick}
                        onDelete={() => handleChipDelete(each?.id)}
                        color={each?.isValid ? "primary" : "secondary"}
                        size="small"
                      />
                    ))}
                    <input
                      type="text"
                      value={mail}
                      onChange={emailChange}
                      onKeyDown={handleKeyDown}
                      onBlur={onBlurEmailField}
                      className="inputEmail"
                    />
                  </div>
                </div>
                <div className="InvitationTextaera">
                  <label className="mar-lft-1rem">Subject:<span style={{ color: "red" }}>*</span></label>
                  <div>
                    <textarea rows="2" cols="50" value={subject} />
                  </div>
                </div>
                <div className="InvitationTextaera">
                  <label className="mar-lft-1rem">Message:<span style={{ color: "red" }}>*</span></label>
                  <div>
                    <textarea rows="4" cols="50" defaultValue={inviteMsg} />
                  </div>
                </div>
                <div class="invite-align-parent">
                  <button
                    type="submit"
                    className="Invite-align"
                    disabled={!isEnabaled}
                  >
                    {isLoading ? <CircularProgress size={20} color="white" /> : "Invite"}
                  </button>
                </div>
              </div>
            </form>
            <p className="Change">or</p>
            <div className="InviteOptionsContainer_align">
              {/* <a
                href="https://dev.skopic.com/skopicportal/gmail/getGmailContactRedirectUrl.html?contacts=true"
                target="_blank"
              > */}
              <button
                className="GmailContacts_align"
                onClick={onFetchGmailContacts}
              >
                <img src={gmail} alt="gmailIcon" />
                <span> Invite Gmail contacts</span>
              </button>
              {/* </a> */}
              {/* <FacebookLogin
                appId={fbappId}
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                render={(renderProps) => (
                  <button
                    className="FacebookContacts_align"
                    onClick={renderProps.onClick}
                  >
                    <img src={facebook} alt="facebookIcon" />
                    <span>Share on Facebook</span>
                  </button>
                )}
              /> */}
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {modalType === ModalType.Message && (
        <MessageModal
          show={modalType === ModalType.Message}
          onHide={closeModal}
          message={msg}
        />
      )}

      {/* {modalType === ModalType.Gmail && (
        <GmailContactsModal
          open={modalType === ModalType.Gmail}
          handleClose={closeModal}
        />
      )} */}

      {/* {modalType === ModalType.Facebook && (
        <FacebookPost
          open={modalType === ModalType.Facebook}
          handleClose={closeModal}
          accessToken={fbAccessToken}
        />
      )} */}
    </>
  );
}

export default Invitation;
