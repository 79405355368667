import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Avatar } from "@material-ui/core";
import { Modal } from "react-bootstrap";

import aboutASK from "src/Assets/images/aboutask.png";
import updateIcon from "src/Assets/images/update.svg";
import closeIcon from "src/Assets/images/CloseButton.svg";
import tagPost from "src/Assets/images/tagPost.svg";
import redBubble from "src/Assets/images/Ellipse.svg";
import yellowBubble from "src/Assets/images/yellow-bubble.png";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import styles from "src/Assets/css/home/Feed.module.css";

import * as feedactions from "../../store/actions/feedactions/feedActionCreator";

import PostingOptionsModal from "../Reusuablecomponents/PostingOptionsModal";
import LocationTag from "../Reusuablecomponents/LocationTag.jsx";
import AsksModal from "../home/AsksModal.jsx";
import CalendarComponent from "../Reusuablecomponents/CalendarComponent";
import AutoCompleteTextField from "../Reusuablecomponents/AutoCompleteTextField";
import API from "../../network";
import * as SignInActions from "src/store/actions/SignInActions/SignInAction";
import AvatarBadge from "src/Components/AvatarBadge";
import { ignoreSpacesAndUrlsFromMsg } from "src/Utils/commonUtils";

export default function UpdateTagPostDataModal(props) {
  const {
    fetchFeedData,
    filterPosts,
    onHide,
    showInviteModalOnPrivateTagPost,
    fetchContributors,
  } = props;
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  // const usernameData = useSelector((state) => state.followReducer.usernameData)
  // const hashType = useSelector((state) => state.followReducer.hashType);
  const [newTagResponse, setNewTagResponse] = useState("");
  const [newUpdateResponse, setNewUpdateResponse] = useState("");
  // const updatePostData = useSelector(
  //   (state) => state.followReducer.updatePostData
  // );
  // const hashData = useSelector((state) => state.followReducer.hashData);

  const [isTagorUpdateText, setTagorUpdateText] = useState("");
  const [isPostOption, setPostOption] = useState("");
  const [isAdditionalCount, setAdditionalCount] = useState(true);
  const [isCharcount, setCharCount] = useState(0);
  const [setLocationSelect, isLocationSelect] = useState("");
  const [isLat, setLat] = useState("");
  const [isLng, setLng] = useState("");
  const [postingOption, setPostingOption] = useState("");
  const [isUserNameData, setUserNameData] = useState([]);
  const [isPlaceholderText, setPlaceholderText] = useState("");
  const [isTagType, setTagType] = useState("NewTag");
  const [isTagValid, setTagValid] = useState("");
  const [isUpdateTagValid, setUpdateTagValid] = useState("");
  const [isTenantId, setTenantId] = useState([]);
  const [isDefaultTenantId, setDefaultTenantID] = useState([]);
  const [isCharCountValid, setCharCountValid] = useState(true);
  const [publishRestrictionOpen, setPublishRestriction] = useState(false);
  // const [askAboutActive, setAskAboutActive] = useState(false)
  const [tagValidity, setTagValidity] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.modalShow) {
      setTagorUpdateText("");
      setPostOption("");
      // setAdditionalCount("");
      setCharCount(0);
      setTagType("NewTag");
      setTagValid("");
    }

    if (props.isModalType === "UPDATE") {
      setPlaceholderText("What important UPDATE do you want to share?");
    } else {
      setPlaceholderText("How do you want to TAG your activity?");
    }

    // if (hashType != "Available" || hashType != "SKIP") {
    //     dispatch(feedactions.getPostTag(`rtrValue=${hashType}`));
    // }
  }, [props.modalShow]);

  // useEffect(() => {
  //   if (newTagResponse) {
  //     if (newTagResponse === "Available") {
  //       setTagValid("This #TAG already exists");
  //     } else if (newTagResponse === "SKIP") {
  //       setTagValid("#TAG missing");
  //     } else {
  //       console.log('in else block');
  //       setTagValid(null);

  //       //TODO: fetchFeedData should be removed once subscriber is added
  //       //Subscriber should add new entry to existing FEED data list
  //       filterPosts("")
  //       // dispatch(feedactions.fetchFeedData("?startlimit=0"));

  //       props.setModalShow();
  //     }
  //     setCharCount(0);
  //   }
  // }, [newTagResponse]);

  useEffect(() => {
    if (newTagResponse) {
      switch (newTagResponse) {
        case "Available":
          setTagValid("This #TAG already exists");
          break;

        case "SKIP":
          setTagValid("#TAG missing");
          break;

        case "SKIPU":
          setTagValid("Please do not use # in between #TAG");
          break;

        case "SKIPN":
          setTagValid("Invalid #TAG format");
          break;

        case "SKIPh":
          setTagValid("Invalid #TAG, no spaces allowed after #");
          break;

        case "NA":
          setTagValid("Please use one #TAG at a time");
          break;

        default:
          console.log("in else block");
          setTagValid(null);

          // Optional: Filter posts and close modal if tag is valid
          filterPosts("");
          props.setModalShow();
          break;
      }

      setCharCount(0); // Reset character count
    }
  }, [newTagResponse]);

  useEffect(() => {
    if (newUpdateResponse) {
      if (newUpdateResponse.status === "New Hash") {
        setTagValid("You can't create new #TAG here ");
      } else {
        setTagValid(null);
        // alert("Your update has bee shared")

        //TODO: filterPosts should be removed once subscriber is added
        //Subscriber should add new entry to existing FEED data list
        filterPosts("");
        // setTimeout(() => {
        //   dispatch(feedactions.fetchFeedData("?startlimit=0"));
        // }, 500);

        props.setModalShow();
      }
      setCharCount(0);
    }
  }, [newUpdateResponse]);

  useEffect(() => {
    if (userDetails && Object.keys(userDetails).length !== 0) {
      // console.log(userDetails.userData.tenantId)
      setDefaultTenantID([userDetails.userData.tenantId]);
    }
  }, [userDetails]);

  var selectedTenantIds = isDefaultTenantId.concat(isTenantId);

  var inputDataLen;

  const ignoreSpaceandUrls = (mstrData, txtId) => {
    let inputData = mstrData;
    inputDataLen = inputData.length;
    let inputText = document.getElementById(txtId).value;
    inputText = inputText.replace(/\n/g, " ");
    let strArray = inputText.split(" ");
    let validlettercount = 0;
    let inputletters;
    for (inputletters = 0; inputletters < strArray.length; inputletters++) {
      if (
        strArray[inputletters].indexOf("http://") === -1 &&
        strArray[inputletters].indexOf("www.") === -1 &&
        strArray[inputletters].indexOf("https://") === -1 &&
        strArray[inputletters].indexOf("HTTP://") === -1 &&
        strArray[inputletters].indexOf("WWW.") === -1 &&
        strArray[inputletters].indexOf("HTTPS://") === -1
      ) {
        validlettercount = validlettercount + strArray[inputletters].length;
      } else if (
        strArray[inputletters].indexOf("http://") &&
        strArray[inputletters].indexOf("www.") &&
        strArray[inputletters].indexOf("https://") &&
        strArray[inputletters].indexOf("HTTP://") &&
        strArray[inputletters].indexOf("WWW.") &&
        strArray[inputletters].indexOf("HTTPS://")
      ) {
        validlettercount = validlettercount + strArray[inputletters].length;
      }
    }
    inputDataLen = validlettercount;
  };

  const textChangeHandler = (e, id) => {
    setTagValidity(false);
    // if(usernameData)
    // {
    //     // setUserNameData(usernameData)
    //     console.log(isUserNameData)

    // }
    let inputValue = id;
    let letterCounter;
    let letterCountAdd;

    var TotalLetters;
    if (isAdditionalCount) {
      letterCounter = "letterCounter";
      letterCountAdd = "letterCounterAdding";
      TotalLetters = 280;
    } else {
      letterCounter = "letterCounter140";
      letterCountAdd = "letterCounterAdding140";
      TotalLetters = 140;
    }

    let letters = document.getElementById(inputValue).value;

    setTagorUpdateText(e.target.value);
    // ignoreSpaceandUrls(letters, inputValue);
    const { msgLength } = ignoreSpacesAndUrlsFromMsg(letters);
    const chCount = msgLength;
    setCharCount(chCount);
    if (document.getElementById(letterCounter && letterCountAdd) !== null) {
      if (chCount > TotalLetters) {
        document.getElementById(letterCountAdd).style.color = "red";
        document.getElementById("postSubmitButton").disabled = true;
        document.getElementById("postSubmitButton").style.opacity = "0.4";

        setCharCountValid(false);
      } else {
        document.getElementById(letterCountAdd).style.color = "black";
        document.getElementById("postSubmitButton").disabled = false;
        document.getElementById("postSubmitButton").style.opacity = "2";
        setCharCountValid(true);
      }
    }

    if (chCount === 0) {
      document.getElementById("postSubmitButton").disabled = true;
      document.getElementById("postSubmitButton").style.opacity = "0.4";
    }
  };

  console.log(props.isModalType);
  const onTagSelection = (switchType) => {
    setTagType(switchType);
  };

  const postUpdateTagHandler = async (e) => {
    // e.preventDefault();
    // let formData = new FormData();
    if (isTagorUpdateText !== "" && props.isModalType === "TAG") {
      setTagValidity(true);
    }

    let lnglat;

    if ((isLat !== "") & (isLng !== "")) {
      lnglat = isLat + "," + isLng;
    } else {
      lnglat = "";
    }
    if (isCharcount <= 280 && isCharCountValid) {
      if (props.isModalType === "TAG") {
        // dispatch(
        //   feedactions.getHashCheck(
        //     `description=${isTagorUpdateText}&ident=${isTagType}&userLoc=&msgLoc=${lnglat}&locName=${setLocationSelect}`
        //   )
        // );
        try {
          let formData = new FormData();
          formData.append("description", isTagorUpdateText);
          formData.append("ident", isTagType);
          const response = await API.checkIfTagExists(
            `userLoc=&msgLoc=${lnglat}&locName=${setLocationSelect}`,
            formData
          );
          setNewTagResponse(response.data);
          const status = String(response.data);
          if (response && response.data && status.includes("E")) {
            setPublishRestriction(true);
          } else if (status && !isNaN(+status) && isTagType === "PrivateTag") {
            // invite member after posting private tag
            const { tenantId } = userDetails?.userData || {};
            showInviteModalOnPrivateTagPost &&
              showInviteModalOnPrivateTagPost(response?.data, tenantId);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          let formdata = new FormData();
          formdata.append("message", isTagorUpdateText);
          formdata.append("timeZone", "IST");
          const response = await API.postNewUpdate(
            formdata,
            lnglat,
            setLocationSelect,
            selectedTenantIds
          );
          setNewUpdateResponse(response.data);
          if (response?.data?.modelMapMulti?.length > 0) {
            if (response?.data?.modelMapMulti[0].updateStatus === "success&E") {
              setPublishRestriction(true);
            }
          }
        } catch (e) {
          console.log(e);
        }
        dispatch(feedactions.getModeratingCommunitys());
      }

      setLat("");
      setLng("");
      isLocationSelect("");
      setTagorUpdateText("");
      setTimeout(() => {
        fetchContributors && fetchContributors();
        dispatch(SignInActions.fetchloginUser());
      }, 1000);
      // setTagType(null);
    }

    // setLat("")
    // setLng("")
    // isLocationSelect("")

    // setTagorUpdateText("")
    // setTagType(null)

    // setTagValid("")
    // setPostOption("")

    // return () => clearTimeout(timer);
  };

  const isSubmitHandler = () => {
    if (
      isTagType === "NewTag" ||
      isTagType === "PrivateTag" ||
      props.isModalType === "UPDATE"
    ) {
      if (isTagorUpdateText === "") {
        return false;
      } else {
        return true;
      }
    }
  };
  const isEnabled = isSubmitHandler();
  const onAddSelection = () => {
    if (isCharcount <= 280) {
      document.getElementById("postSubmitButton").disabled = false;
      document.getElementById("postSubmitButton").style.opacity = "2";
      setCharCountValid(true);
    }
    setAdditionalCount(true);
  };
  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  const [isHide, setIsHide] = useState(false);

  const handleClose = () => {
    isLocationSelect("");
  };

  const communitySelected = userDetails?.userData?.communityFollowNames.find(
    (each) => each.id === userDetails?.userData?.tenantId
  );

  return (
    <React.Fragment>
      <div style={{ display: isHide ? "none !important" : "block !important" }}>
        <Modal
          {...props}
          size="md"
          className={isHide ? styles.modalHide : styles.modal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className={styles.modalbgcolor}>
            <Modal.Header
              onClick={handleClose}
            // closeButton={props.isModalType === "TAG" ? true : false}
            >
              {props.isModalType === "UPDATE" ? (
                <div className={styles.updateHeaderContainer}>
                  <div className={styles.updateHeaderImgContainer}>
                    <img
                      src={updateIcon}
                      alt="updateIcon"
                      className={styles.updateIcon}
                    />
                    <h1 className={styles.updateText}>UPDATE</h1>
                  </div>
                  <button
                    onClick={props.onHide}
                    className={styles.closeIconButton}
                  >
                    <img
                      src={closeIcon}
                      alt="closeIcon"
                      className={styles.closeIcon}
                    />
                  </button>
                </div>
              ) : props.isModalType === "TAG" ? (
                <div className={styles.tagDiv}>
                  <div className={styles.tagHeaderImgContainer}>
                    <img
                      src={tagPost}
                      alt="tagComposer"
                      className={styles.tagIconImage}
                    />
                    <button
                      onClick={props.onHide}
                      className={styles.tagCloseIconButton}
                    >
                      <img
                        src={closeIcon}
                        alt="closeIcon"
                      />
                    </button>
                  </div>
                  {(userDetails?.userData.moderatorStatus === "1" ||
                    userDetails?.userData.isAdmin === "1") && (
                      <div>
                        <button
                          id="NewTag"
                          className={`${styles.publicSwitch} ${isTagType === "NewTag"
                            ? styles.onPublicSwitchSelect
                            : null
                            }`}
                          onClick={() => onTagSelection("NewTag")}
                        >
                          Public
                        </button>
                        <button
                          id="PrivateTag"
                          className={`${styles.privateSwitch} ${isTagType === "PrivateTag"
                            ? styles.onPrivateSwitchSelect
                            : null
                            }`}
                          onClick={() => onTagSelection("PrivateTag")}
                        >
                          Private
                        </button>
                      </div>
                    )}
                </div>
              ) : null}
            </Modal.Header>

            <Modal.Body className={styles.modalbody}>
              {userDetails && Object.keys(userDetails).length !== 0 ? (
                <div className={styles.profilenameandimage}>
                  <div>
                    {/* <Avatar
                      src={`${profileImagePath}${userDetails.userData.uimage}`}
                      className={styles.profileavatar}
                    /> */}
                    <AvatarBadge
                      src={`${profileImagePath}${userDetails.userData.uimage}`}
                      alt={userDetails?.userData?.displayName}
                      className={styles.profileavatar}
                      isPrimaryModerator={communitySelected?.ispm === 1}
                      isModerator={
                        communitySelected?.moderatorStatus === 1 &&
                        communitySelected?.ispm === 0
                      }
                    />
                  </div>
                  <div className={styles.profilename}>
                    <p>{userDetails.userData.shortDisplayName}</p>
                    <div
                    // className={
                    //   props.isModalType === "UPDATE"
                    //     ? styles.postupdatedropdown
                    //     : styles.postdropdown
                    // }
                    >
                      <PostingOptionsModal
                        tenantName={userDetails.userData.tenantName}
                        pimage={userDetails.userData.tenantLogoThumbnail}
                        setPostingOption={setPostingOption}
                        isPostOption={props.isModalType}
                        setTenantId={setTenantId}
                        isTagorUpdateText={isTagorUpdateText}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={styles.askSayTextareaContainer}>
                <textarea
                  placeholder={isPlaceholderText}
                  className={styles.asksaytextarea}
                  value={isTagorUpdateText}
                  id="post"
                  onChange={(e) => textChangeHandler(e, "post")}
                />
                <img
                  src={
                    props.isModalType === "UPDATE" ? redBubble : yellowBubble
                  }
                  alt="redBubble"
                  className={styles.redBubble}
                />
              </div>
              {/* <AutoCompleteTextField/> */}

              {tagValidity && (
                <span className={styles.errorMessage}>{isTagValid}</span>
              )}

              <div className={styles.updatecount}></div>
              <div className={styles.locationtagandcharcount}>
                <div className={styles.locationtagicon}>
                  <LocationTag
                    isPostings={true}
                    isLocationSelect={isLocationSelect}
                    setLat={setLat}
                    setLng={setLng}
                    setLocationSelect={setLocationSelect}
                    setIsHide={setIsHide}
                    modalType={props.isModalType}
                  />

                  {props.isModalType === "UPDATE" ? (
                    <CalendarComponent />
                  ) : // <CustomDateTimePicker/>
                    null}
                </div>
                <div>
                  {isAdditionalCount ? (
                    <>
                      <label
                        id={`letterCounterAdding`}
                        className={`${isCharcount > 280 ? styles.overCount : null
                          }`}
                      >
                        {isCharcount}
                      </label>
                      <label id={`letterCounter`}>/280</label>
                    </>
                  ) : //(
                    // <span className={styles.initialcount}>
                    //   <label id={`letterCounterAdding140`}>{isCharcount}</label>
                    //   <label id={`letterCounter140`}>/140 </label>
                    // </span>
                    //)
                    null}

                  {/* {!isAdditionalCount ? (
                  <AddCircleOutlineIcon
                    onClick={onAddSelection}
                    className={styles.additionalcountadd}
                  />
                ) : null} */}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className={styles.modalfooter}>
              <Button
                id="postSubmitButton"
                onClick={postUpdateTagHandler}
                disabled={!isEnabled}
                // className={`${
                //   props.isModalType === "UPDATE"
                //     ? styles.updateSubmit
                //     : props.isModalType === "TAG"
                //     ? styles.tagSubmit
                //     : null
                // } ${!isEnabled ? styles.postbuttondisable : null}`}
                className={`${
                  props.isModalType === "UPDATE"
                    ? isEnabled
                      ? styles.updateSubmit
                      : styles.updateButtonDisable
                    : props.isModalType === "TAG"
                    ? isEnabled
                      ? styles.tagSubmit
                      : styles.tagButtonDisable
                    : ""
                }`}
              >
                POST
              </Button>
              <div className={styles.aboutAskInfo}>
                <img
                  src={aboutASK}
                  alt="aboutupdate"
                  className={styles.updateinfoicon}
                />
                {props.isModalType === "TAG" ? (
                  <div className={styles.askAbout}>
                    <span>Why create TAG</span>
                    <p>
                      Share and Organize an Activity, Topic, or a Group within
                      your Community. (EX: Share and learn matters concerning
                      #Stanford-PreMed students.)
                    </p>
                    <span>Follow the TAG</span>
                    <p>
                      Share, Learn, and Stay Connected with your favorite
                      Activities, Topics, and Groups.
                    </p>
                  </div>
                ) : props.isModalType === "UPDATE" ? (
                  <div className={styles.askAbout}>
                    <div className={styles.askAboutHeadingContainer}>
                      <h6>Why create UPDATE</h6>
                      <button
                        onClick={props.onHide}
                        className={styles.closeIconButton}
                      >
                        <img
                          src={closeIcon}
                          alt="closeIcon"
                          className={styles.AskAboutCloseIcon}
                        />
                      </button>
                    </div>

                    <p>
                      Share important events or incidents or a critical deadline
                      to notify your entire community. (EX: Neighborhood
                      Association Meeting today at 6PM. Please come over to Joe
                      Pisani Community Center.)
                    </p>
                    <h6>Notify your Community in real time</h6>
                    <p>
                      UPDATEs are broadcasted via Skopic, email, mobile, and
                      watch.
                    </p>
                  </div>
                ) : null}
              </div>
            </Modal.Footer>
          </div>
        </Modal>
        <Modal
          size="lg"
          show={publishRestrictionOpen}
          onHide={() => setPublishRestriction(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          centered // This prop will center the modal vertically and horizontally
          className={styles.restrictedModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Thank You
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {props.isModalType == "UPDATE"
              ? "Your UPDATE will publish as soon as the Community Moderator approves it."
              : "Your #TAG will be published as soon as the community moderator approves it."}
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
}
