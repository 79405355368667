// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.postPhotoItemStyle {

}

.postPhotoItemStyle .closerImage {
  width: 100%;
  display: inline-block;
  position: relative;
}

.postPhotoItemStyle .image {
  width: 100%;
  height: 220px;
  border-radius: 12px;
  object-fit: cover;
}

.postPhotoItemStyle .closeIcon {
  position: absolute;
  top: 4px;
  right: 4px;
}

.postPhotoItemStyle textarea{
    margin: 12px 0;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2) ;
    border-radius: 12px;
    padding: 8px;
    max-lines: 3;
}

.postPhotoItemStyle .bottom{
    display: flex;
    justify-content: space-between;
}

.postPhotoItemStyle .location{
    display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/styles/postPhotoItemStyle.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ;;AAEA;IACI,cAAc;IACd,WAAW;IACX,qCAAqC;IACrC,mBAAmB;IACnB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".postPhotoItemStyle {\n\n}\n\n.postPhotoItemStyle .closerImage {\n  width: 100%;\n  display: inline-block;\n  position: relative;\n}\n\n.postPhotoItemStyle .image {\n  width: 100%;\n  height: 220px;\n  border-radius: 12px;\n  object-fit: cover;\n}\n\n.postPhotoItemStyle .closeIcon {\n  position: absolute;\n  top: 4px;\n  right: 4px;\n}\n\n.postPhotoItemStyle textarea{\n    margin: 12px 0;\n    width: 100%;\n    border: 1px solid rgba(0, 0, 0, 0.2) ;\n    border-radius: 12px;\n    padding: 8px;\n    max-lines: 3;\n}\n\n.postPhotoItemStyle .bottom{\n    display: flex;\n    justify-content: space-between;\n}\n\n.postPhotoItemStyle .location{\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
