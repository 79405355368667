import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import sideArrow from "src/Assets/images/ForwordIcon.png";
import { useSelector } from "react-redux";
import backbutton from "src/Assets/images/locationtagbackbutton.svg";
import styles from "src/Assets/css/home/post.module.css";
import feedStyles from "src/Assets/css/home/Feed.module.css";
import Multiselect from "../Reusuablecomponents/Multiselect.jsx";
import { Divider, Avatar } from "@material-ui/core";
import closeButton from "src/Assets/images/CloseButton.svg";
import Popup from "reactjs-popup";

const useStyles = makeStyles({
  paperWidthSm: {
    borderRadius: 25,
    width: 560,
    overflow: "hidden",
    maxWidth: "none",
  },
  rounded: {
    borderRadius: 12,
  },
});

function SimpleDialog(props) {
  const settingsData = useSelector((state) => state.voteupReducer.settingsData);
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const classes = useStyles();
  const { onClose, open } = props;
  const [value, setValue] = React.useState(null);
  const [isCommunitySelectCount, setCommunitySelectCount] =
    React.useState(null);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleClose = () => {
    onClose(false);
  };
  const onPostOptionSubmit = () => {
    props.setPostingOption(value);
    handleClose();
    setValue(null);
  };

  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  return props.isPostOption === "UPDATE" &&
    userDetails.userData.moderatorStatus === "1" ? (
    <>
      <Multiselect
        tenantName={props.tenantName}
        setCommunitySelectCount={setCommunitySelectCount}
        setTenantId={props.setTenantId}
        open={open || false}
        closePopup={handleClose}
      />
    </>
  ) : <></>;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      key={`simpleDialog2${props.id}`}
      classes={{ paperWidthSm: classes.paperWidthSm }}
    >
      <div className={styles.mapcontainer}>
        <div className={styles.addlocation}>
          <a onClick={handleClose}>
            <img src={backbutton} alt="backbutton" />
          </a>
          <h5 className={styles.locationheading}>
            Who can see your <span>{props.isPostOption}</span>?
          </h5>
          <button
            className={
              isCommunitySelectCount > 0
                ? styles.locationSubmitAfterSelect
                : styles.locationSubmitBeforeSelect
            }
            onClick={onPostOptionSubmit}
          >
            Done
          </button>
        </div>
        <Divider style={{ margin: "0px -15px" }} />
        {settingsData && Object.keys(settingsData).length !== 0 ? (
          <>
            <div className={`${styles.postingoptionsradio}`}>
              <div className={styles.postingoptionschild}>
                <Avatar
                  src={`${profileImagePath}${props.pimage}`}
                  variant="rounded"
                  classes={{ rounded: classes.rounded }}
                />
                <div className={styles.postinfo}>
                  <div>
                    <p>{props.tenantName}</p>
                    <div>
                      {props.isPostOption === "UPDATE" &&
                        userDetails.userData.moderatorStatus === "1" ? (
                        <>
                          <Multiselect
                            tenantName={props.tenantName}
                            setCommunitySelectCount={setCommunitySelectCount}
                            setTenantId={props.setTenantId}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                  <p className={styles.aboutCommunity}>
                    Anyone in {props.tenantName}
                  </p>
                </div>
              </div>
              <label className={styles.container}>
                <input
                  type="radio"
                  name="radio"
                  className={styles.containerinput}
                  value=""
                  onChange={handleChange}
                />
                <span className={styles.checkmark}></span>
              </label>
            </div>

            {/* <div
              className={`${styles.postingoptionsradio} ${settingsData.fbPagePerms === ""
                ? styles.disableRadioOptions
                : null
                }`}
            >
              <div className={styles.postingoptionschild}>
                <Avatar
                  src={`${profileImagePath}${props.pimage}`}
                  variant="rounded"
                  classes={{ rounded: classes.rounded }}
                />
                <div className={styles.postinfo}>
                  {isCommunitySelectCount > 0 ? (
                    <p>
                      {props.tenantName} +{isCommunitySelectCount}, Facebook
                    </p>
                  ) : (
                    <p>{props.tenantName} + Facebook</p>
                  )}
                  <span>Anyone in {props.tenantName}, post to Facebook</span>
                  <br />
                  {settingsData.fbPagePerms === "" ? (
                    <span className={`alert alert-info ${styles.warningmsg}`}>
                      If you want to post on facebook you need to authenticate
                      first
                    </span>
                  ) : null}
                </div>
              </div>
              <label className={styles.container}>
                <input
                  type="radio"
                  name="radio"
                  className={styles.containerinput}
                  value="facebook"
                  onChange={handleChange}
                />
                <span className={styles.checkmark}></span>
              </label>
            </div> */}

            {/* <div
              className={`${styles.postingoptionsradio} ${settingsData.twitterPermission === ""
                ? styles.disableRadioOptions
                : null
                }`}
            >
              <div className={styles.postingoptionschild}>
                <Avatar
                  src={`${profileImagePath}${props.pimage}`}
                  variant="rounded"
                  classes={{ rounded: classes.rounded }}
                />
                <div className={styles.postinfo}>
                  {isCommunitySelectCount > 0 ? (
                    <p>
                      {props.tenantName} +{isCommunitySelectCount}, Twitter
                    </p>
                  ) : (
                    <p>{props.tenantName} + Twitter</p>
                  )}
                  <span>Anyone in {props.tenantName}, post to Twitter</span>
                  <br />
                  {settingsData.twitterPermission === "" ? (
                    <span
                      className={`alert alert-warning ${styles.warningmsg}`}
                    >
                      If you want to post on twitter you need to authenticate
                      first
                    </span>
                  ) : null}
                </div>
              </div>
              <label className={styles.container}>
                <input
                  type="radio"
                  name="radio"
                  className={styles.containerinput}
                  value="twitter"
                  onChange={handleChange}
                />
                <span className={styles.checkmark}></span>
              </label>
            </div> */}

            {/* <div
              className={`${styles.postingoptionsradio} ${settingsData.fbPagePerms === "" ||
                settingsData.twitterPermission === ""
                ? styles.disableRadioOptions
                : null
                }`}
            >
              <div className={styles.postingoptionschild}>
                <Avatar
                  src={`${profileImagePath}${props.pimage}`}
                  variant="rounded"
                  classes={{ rounded: classes.rounded }}
                />
                <div className={styles.postinfo}>
                  {isCommunitySelectCount > 0 ? (
                    <p>
                      {props.tenantName} +{isCommunitySelectCount}, Facebook ,
                      Twitter
                    </p>
                  ) : (
                    <p>{props.tenantName} + Facebook + Twitter</p>
                  )}
                  <span>
                    Anyone in {props.tenantName}, post to Facebook & Twitter
                  </span>
                  <br />
                  {settingsData.fbPagePerms === "" ||
                    settingsData.twitterPermission === "" ? (
                    <span className={`alert alert-info ${styles.warningmsg}`}>
                      If you want to post on facebook and twitter you need to
                      authenticate first
                    </span>
                  ) : null}
                </div>
              </div>
              <label className={styles.container}>
                <input
                  type="radio"
                  name="radio"
                  className={styles.containerinput}
                  value="fbandtwitter"
                  onChange={handleChange}
                />
                <span className={styles.checkmark}></span>
              </label>
            </div> */}
          </>
        ) : (
          <p>No Data</p>
        )}
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function PostingOptionsModal(props) {
  const { askorSayText } = props;
  const [open, setOpen] = React.useState(false);
  const [openAlertModal, setAlertModal] = React.useState(false);
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const { userData = {} } = userDetails;
  const { communityFollowNames = [] } = userData;
  const moderatorComunities = communityFollowNames && communityFollowNames.filter(each => each.moderatorStatus === 1);

  // const handleClickOpen = () => {
  //   if (props?.isPostOption) {
  //     if (props.isTagorUpdateText !== "") {
  //       setOpen(true);
  //     } else {
  //       setAlertModal(true);
  //     }
  //   }
  // };

  const handleClickOpen = () => {
    const hasTagOrUpdateText = props?.isTagorUpdateText?.trim() !== "";
    const hasAskorSayText = askorSayText?.trim() !== "";

    if (props?.isPostOption) {
      if (!hasTagOrUpdateText || !hasAskorSayText) {
        if (props.isPostOption === "UPDATE" && moderatorComunities && moderatorComunities.length > 1) {
          setAlertModal(true);
        }
      } else {
        if (props.isPostOption === "UPDATE" && moderatorComunities && moderatorComunities.length > 1) {
          setOpen(true);
        }
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div
      className={
        props.isPostOption === "UPDATE" && moderatorComunities && moderatorComunities.length > 1
          ? feedStyles.postupdatedropdown
          : feedStyles.postdropdown
      }
    >
      {/* <a className={styles.locationpinchildpost}><img src={locationpinchildpost} onClick={handleClickOpen} /></a> */}
      <div onClick={handleClickOpen}>
        <span>{props.tenantName}</span>
        {props?.isPostOption === 'UPDATE' && moderatorComunities && moderatorComunities.length > 1 ? <img src={sideArrow} style={{ height: '8px', width: '8px', marginLeft: '15px' }} /> : null}
      </div>

      <SimpleDialog
        open={open}
        onClose={handleClose}
        tenantName={props.tenantName}
        pimage={props.pimage}
        setPostingOption={props.setPostingOption}
        isPostOption={props.isPostOption}
        setTenantId={props.setTenantId}
      />
      <Popup
        open={openAlertModal}
        onClose={() => setAlertModal(false)}
        contentStyle={{ zIndex: 1400, width: "400px" }}
        overlayStyle={{ zIndex: 1300 }}
      >
        <div className={styles.alertModal}>
          <div>
            <img
              src={closeButton}
              onClick={() => setAlertModal(false)}
              alt="close-button"
            />
          </div>
          {props.isPostOption === "UPDATE" && moderatorComunities && moderatorComunities.length > 1 ? (
            <p>Please enter input to Share</p>
          ) : null}
        </div>
      </Popup>
    </div>
  );
}
