import {
  CircularProgress,
  Box,
  Avatar,
  Typography,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import like from "src/Assets/images/like.png";
import unlike from "src/Assets/images/unlike.png";
import Edit from "src/Assets/images/edit.png";
import Delete from "src/Assets/images/deleteicon.png";
import { Button } from "../../../Components/StyledComponents";
import API from "../../../network";
import SimpleDialogDemo from "../../../Modules/Reusuablecomponents/CustomizedDialogs";
import { Menu, MenuItem } from '@szhsin/react-menu';
import { MdKeyboardArrowDown } from "react-icons/md";
import PostMessageValidation from "../../../Modules/Reusuablecomponents/PostMessageValidation";
import RestrictionMsgModal from "src/Components/RestrictionMsgModal";
import HomeChildSay from "src/pages/HomePage/components/ChildSay";

const Name = styled(Typography)({
  fontWeight: "500",
  fontSize: "16px",
  flex: 1,
});

const Message = styled(Typography)({
  fontSize: "14px",
});

const LikeContainer = styled(Typography)({
  fontSize: "14px",
  color: "#878585",
});

const btnStyle = {
  textTransform: "none",
  borderRadius: "25px",
  color: "white",
  backgroundColor: "#36b549",
  border: 'none',
  outline: 'none',
  boxShadow: 'none',
  padding: '2px 16px',
};

const editSayInput = {
  width: '100%',
  borderRadius: '10px',
  padding: '8px 8px 10px 12px',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  border: '1px solid #707070',
  resize: 'none',
}

var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

const ChildSay = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [postMessage, setPostMessage] = useState("");
  const [postMsgCount, setPostMsgCount] = useState(0);
  const [childSaysData, setChildSaysData] = useState({});
  const { id } = props || {};
  const [visibleRestModal, setVisibleRestModal] = useState(false);
  let userDetails = useSelector((state) => state.SignInReducer.userDetails);

  // console.log("userDetails", userDetails);
  // console.log("props", props);

  useEffect(() => {
    let msg = postMessage || "";
    // msg = msg.replace(/\n/g, "");
    // msg = msg.replace(/\s/g, "");
    const count = msg.length;
    setPostMsgCount(count);
  }, [postMessage]);

  useEffect(() => {
    fetchChildSays();
  }, [id]);

  const fetchChildSays = async () => {
    try {
      setLoading(true);
      const res = await API.getProfileChildSays(id);
      setLoading(false);
      setChildSaysData(res.data);
    } catch (e) {
      setLoading(false);
    };
  };

  const postChildSay = async (id, msg, lnglat, locSelect) => {
    try {
      let formdata = new FormData();
      formdata.append("Parent_ID", id);
      formdata.append("Message", msg);
      const response = await API.postProfileChildSays(locSelect, formdata);
      const status = response?.data?.status || '';
      if (status === 'New Hash') {
        return 'New Hash';
      } else if (status === 'E_Say' || status === 'E') {
        setVisibleRestModal(true);
      } else {
        // this.fetchChildSaysData(id);
        // if (this.state.showData === 'tag' || this.state.showData === 'singleTrendingTag') {
        //   this.updateTagData(id, 'add');
        // } else {
        //   this.updateFeedData(id, 'add');
        // }
        fetchChildSays();
        props?.updateSayCount(id, "increment");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchVoteUpStatus = async (id, cache) => {
    try {
      await API.fetchVoteUpStatus(id, cache);
      const list = childSaysData.childSAYsMessageList || [];
      const newUpdatedList = list.map((item) => {
        if (item.id === id) {
          return { ...item, UserLikeStatus: "InActive", voteUp: item.voteUp - 1 + 2 }; // Update the message
        }
        return item; // Return unchanged items
      });
      setChildSaysData({
        ...childSaysData,
        childSAYsMessageList: newUpdatedList
      });
    } catch (e) {
      console.error(e);
    }
  };

  const deleteChildSay = (id, type, Parent_ID) => {
    let formdata = new FormData();
    formdata.append("messageID", id);
    API.deleteProfilePost(formdata)
      .then((res) => {
        const childList = childSaysData?.childSAYsMessageList || [];
        const newChildSays = [...childList];
        const index = newChildSays.findIndex((x) => x.id === id);
        if (index > -1) {
          newChildSays.splice(index, 1);
          setChildSaysData({
            ...childSaysData,
            childSAYsListSize: childSaysData?.childSAYsListSize - 1,
            childSAYsMessageList: newChildSays,
          });
        }
        props?.updateSayCount(props?.id, "decrement");
      })
      .catch((err) => { console.error(err) });
  };

  // const updateMessage = (id, message) => {
  //   const newData = [...childSays];
  //   const modifiedData = newData.find((x) => x.id === id);
  //   let formdata = new FormData();
  //   formdata.append("messageID", id);
  //   formdata.append("message", message);
  //   API.updateProfilePost(modifiedData.locName, formdata)
  //     .then((res) => {
  //       console.log(res);
  //       modifiedData.Message = message;
  //       setChildSays(newData);
  //       console.log(modifiedData);
  //     })
  //     .catch((err) => { });
  // };

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // const isPostBtnEnabled = (postMessage && postMsgCount > 0 && postMsgCount <= 280);

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <div style={{ width: '100%' }}>
          <PostMessageValidation
            id={id}
            type={"childSay"}
            userImageSrc={`${profileImagePath}${userDetails?.userData?.uimage}`}
            postChildSayData={postChildSay}
            //  fetchChildSaysData={fetchChildSaysData}
            refreshFeedData={() => { }}
            increaseSayCount={props.increaseSayCount}
          />
        </div>

        <HomeChildSay
          id={id}
          key={id}
          loading={loading}
          childSaysData={childSaysData}
          fetchVoteUpStatus={fetchVoteUpStatus}
          // fetchFeedData={fetchFeedData}
          deleteMessage={deleteChildSay}
          refreshFeedData={() => { }}
          decreaseSayCount={props.decreaseSayCount}
        />

        {/* <Tile
          childSays={childSays}
          likeChildSay={likeChildSay}
          deleteChildSay={deleteChildSay}
          updateMessage={updateMessage}
        /> */}
      </div>
      {visibleRestModal && (
        <RestrictionMsgModal
          open={visibleRestModal}
          onClose={() => setVisibleRestModal(false)}
          title="Thank you"
          message="Your SAY will publish as soon as the Community Moderator approves it."
        />
      )}
    </>
  );
};

// const Tile = (props) => {
//   const { childSays, deleteChildSay, likeChildSay, updateMessage } = props;
//   return (
//     <>
//       {childSays.map((item) => (
//         <ChildTile
//           key={`key-${item?.id}`}
//           item={item}
//           deleteChildSay={deleteChildSay}
//           likeChildSay={likeChildSay}
//           updateMessage={updateMessage}
//         />
//       ))}
//     </>
//   );
// };

// const ChildTile = (props) => {
//   const { item, deleteChildSay, likeChildSay, updateMessage } = props;
//   const [editSay, setEditSay] = useState(false);
//   const [message, setMessage] = useState(item.Message);
//   const [messageCount, setMessageCount] = useState(0);

//   useEffect(() => {
//     let msg = message || "";
//     // msg = msg.replace(/\n/g, "");
//     // msg = msg.replace(/\s/g, "");
//     const count = msg.length;
//     setMessageCount(count);
//   }, [message]);

//   const deleteSay = (id) => {
//     deleteChildSay(id);
//   };

//   const openEditSay = (id, msg) => {
//     setEditSay(true);
//     setMessage(msg);
//   };

//   return (
//     <>
//       <div style={{ display: "flex", marginTop: "20px" }}>
//         <Avatar
//           src={`${profileImagePath}${item.uimage}`}
//           variant="square"
//           style={{ borderRadius: "5px" }}
//         />
//         <div
//           style={{
//             flex: 1,
//             borderRadius: "20px",
//             border: "1px solid #707070",
//             marginLeft: "10px",
//             padding: "10px",
//             flexDirection: "column",
//             display: "flex",
//           }}
//         >
//           <div style={{ display: "flex" }}>
//             <Name>{item.displayName}</Name>
//             <div>
//               <Menu
//                 menuButton={
//                   <IconButton
//                     style={{ padding: 0, width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
//                   >
//                     <MdKeyboardArrowDown fontSize={25} />
//                   </IconButton>
//                 }
//                 transition
//                 direction="bottom"
//                 arrow
//                 menuStyle={{
//                   backgroundColor: 'white',
//                   borderRadius: 20,
//                 }}
//               >
//                 <MenuItem onClick={() => openEditSay(item.id, item?.Message)}>
//                   <img src={Edit} alt="edit" style={{ marginRight: 8 }} />
//                   <span style={{ fontSize: 14 }}>Edit Post</span>
//                 </MenuItem>
//                 <MenuItem onClick={() => deleteSay(item.id)}>
//                   <img src={Delete} alt="delete" style={{ marginRight: 8 }} />
//                   <span style={{ fontSize: 14 }}>Delete Post</span>
//                 </MenuItem>
//               </Menu>
//             </div>
//           </div>
//           {editSay ? (
//             <>
//               <textarea
//                 value={message}
//                 style={editSayInput}
//                 onChange={(e) => {
//                   e.stopPropagation();
//                   setMessage(e.target.value);
//                 }}
//               />
//               <div style={{ display: "flex", justifyContent: 'space-between' }}>
//                 <div style={{ display: "flex", marginTop: 6 }}>
//                   <Button
//                     variant="contained"
//                     style={{ ...btnStyle, opacity: (messageCount > 0 && messageCount <= 280) ? 1 : 0.6 }}
//                     onClick={(e) => {
//                       e.stopPropagation();
//                       updateMessage(item.id, message);
//                       setEditSay(false);
//                     }}
//                   >
//                     Save
//                   </Button>
//                   <Button
//                     variant="outlined"
//                     style={{ ...btnStyle, backgroundColor: 'transparent', color: 'black' }}
//                     onClick={(e) => {
//                       e.stopPropagation();
//                       setEditSay(false);
//                     }}
//                   >
//                     Cancel
//                   </Button>
//                 </div>
//                 <span style={{ color: messageCount > 280 ? 'red' : 'black', display: 'flex' }}>
//                   {messageCount}<span style={{ color: 'black' }}>/280</span>
//                 </span>
//               </div>
//             </>
//           ) : (
//             <Message>{item.Message}</Message>
//           )}
//         </div>
//       </div>
//       <div
//         style={{
//           marginLeft: "70px",
//           display: "flex",
//           alignItems: "center",
//         }}
//       >
//         <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
//           <Button
//             startIcon={
//               <img
//                 src={item.UserLikeStatus === "Active" ? like : unlike}
//                 alt="like"
//               />
//             }
//             onClick={(e) => {
//               e.stopPropagation();
//               return item.UserLikeStatus === "Active"
//                 ? likeChildSay(item.id, "cacheUpdate")
//                 : null;
//             }}
//           >
//             Like
//           </Button>
//           {item.voteUp !== 0 ? (
//             <SimpleDialogDemo
//               voteUp={item.voteUp}
//               id={item.id}
//               isVoteup={"true"}
//             />
//           ) : (
//             <LikeContainer>
//               <FiberManualRecordIcon sx={{ fontSize: "8px" }} /> {item.voteUp}{" "}
//               likes
//             </LikeContainer>
//           )}
//         </div>
//         <Message>{item.Message_Time}</Message>
//       </div>
//     </>
//   );
// };

export default ChildSay;
