export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59.769"
      height="19.993"
      viewBox="0 0 59.769 19.993"
    >
      <g
        id="Group_1845"
        data-name="Group 1845"
        transform="translate(-24.919 -659)"
      >
        <text
          id="SAY"
          transform="translate(59.688 674)"
          fill="#373334"
          fontSize="14"
          fontFamily="AcuminPro-Regular, Acumin Pro"
        >
          <tspan x="0" y="0">
            SAY
          </tspan>
        </text>
        <g
          id="Group_1720"
          data-name="Group 1720"
          transform="translate(-15636 -2479.442)"
        >
          <path
            id="Path_71"
            data-name="Path 71"
            d="M153.465,257.746l-10.547,11.992,11.185-5.219"
            transform="translate(15518.22 2888.698)"
            fill="#45a735"
          />
          <path
            id="Path_72"
            data-name="Path 72"
            d="M169.318,255.769c.089,4.9-5.832,9-13.224,9.16s-13.455-3.677-13.543-8.576,5.832-9,13.224-9.161S169.231,250.871,169.318,255.769Z"
            transform="translate(15518.368 2891.255)"
            fill="#45a735"
          />
          <path
            id="Path_73"
            data-name="Path 73"
            d="M174.421,258.414a2.066,2.066,0,1,1-2.061-1.917A1.992,1.992,0,0,1,174.421,258.414Z"
            transform="translate(15507.076 2889.493)"
            fill="#fff"
          />
          <path
            id="Path_74"
            data-name="Path 74"
            d="M156.412,258.337a2.065,2.065,0,1,1-2.061-1.916A1.992,1.992,0,0,1,156.412,258.337Z"
            transform="translate(15515.053 2889.521)"
            fill="#fff"
          />
          <path
            id="Path_75"
            data-name="Path 75"
            d="M165.463,258.337a2.066,2.066,0,1,1-2.061-1.916A1.992,1.992,0,0,1,165.463,258.337Z"
            transform="translate(15511.044 2889.521)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}