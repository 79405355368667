// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 20px;
  /* background-image: url("https://cdn.vectorstock.com/i/500p/81/59/404-error-page-not-found-tiny-people-vector-51588159.jpg");
  background-size: cover;
  background-position: center; */
}
@media (min-width: 576px) {
  .not-found-container {
    height: 100vh;
  }
}
.header-container{
  background-color: #ffffff;
}
.not-found-image {
  height: 50vh;
  margin-bottom: 30px;
}
.not-found-heading {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 28px;
  color: #334155;
  margin-bottom: -8px;
}
.not-found-description {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 18px;
  color: #475569;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50%;
}
.not-found-button {
  padding: 10px 20px;
  background-color: #3b82f6;
  border-width: 0px;
  border-radius: 5px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/NotFoundPage/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;EAClB;;gCAE8B;AAChC;AACA;EACE;IACE,aAAa;EACf;AACF;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".not-found-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 20px 20px;\n  /* background-image: url(\"https://cdn.vectorstock.com/i/500p/81/59/404-error-page-not-found-tiny-people-vector-51588159.jpg\");\n  background-size: cover;\n  background-position: center; */\n}\n@media (min-width: 576px) {\n  .not-found-container {\n    height: 100vh;\n  }\n}\n.header-container{\n  background-color: #ffffff;\n}\n.not-found-image {\n  height: 50vh;\n  margin-bottom: 30px;\n}\n.not-found-heading {\n  font-family: \"Roboto\";\n  font-weight: 500;\n  font-size: 28px;\n  color: #334155;\n  margin-bottom: -8px;\n}\n.not-found-description {\n  font-family: \"Roboto\";\n  font-weight: 500;\n  font-size: 18px;\n  color: #475569;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  width: 50%;\n}\n.not-found-button {\n  padding: 10px 20px;\n  background-color: #3b82f6;\n  border-width: 0px;\n  border-radius: 5px;\n  font-family: \"Roboto\";\n  font-weight: 500;\n  font-size: 13px;\n  color: #ffffff;\n  cursor: pointer;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
