// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GmailContactsModal_container__1ZJfY {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  background-color: white;
  border-radius: 20px;
}
.GmailContactsModal_list__TqFGg {
  width: 100%;
  overflow: scroll;
  height: 68vh;
}

@media all and (min-width: 992px) {
  .GmailContactsModal_container__1ZJfY {
    width: 60vw;
  }
}

.GmailContactsModal_searchWrapper__uIw7z {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  margin-bottom: 8px;
}
.GmailContactsModal_selectAllContainer__xDR5e {
  display: flex;
  align-items: center;
}
.GmailContactsModal_searchInput__2hQoE {
  width: 100%;
  border-radius: 10px;
  height: 38px;
  border: 1px solid grey;
  outline: none;
  padding: 0 6px;
}
.GmailContactsModal_buttonWrapper__pdhGM {
  display: flex;
  align-items: center;
  margin: 16px 0px;
}
.GmailContactsModal_closeBtn__3JdZ3 {
  margin-right: 16px !important;
  height: 38px;
  width: 100px;
  box-shadow: none;
}
.GmailContactsModal_inviteBtn__LpP-J {
  margin-left: 16px !important;
  height: 38px;
  width: 100px;
  box-shadow: none;
}
.GmailContactsModal_checkbox__M3-HT {
  height: 20px;
  width: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/components/Invitations/GmailContactsModal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,WAAW;EACX,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,cAAc;AAChB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,6BAA6B;EAC7B,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,4BAA4B;EAC5B,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".container {\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  width: 90vw;\r\n  background-color: white;\r\n  border-radius: 20px;\r\n}\r\n.list {\r\n  width: 100%;\r\n  overflow: scroll;\r\n  height: 68vh;\r\n}\r\n\r\n@media all and (min-width: 992px) {\r\n  .container {\r\n    width: 60vw;\r\n  }\r\n}\r\n\r\n.searchWrapper {\r\n  width: 100%;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  padding-left: 16px;\r\n  margin-bottom: 8px;\r\n}\r\n.selectAllContainer {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n.searchInput {\r\n  width: 100%;\r\n  border-radius: 10px;\r\n  height: 38px;\r\n  border: 1px solid grey;\r\n  outline: none;\r\n  padding: 0 6px;\r\n}\r\n.buttonWrapper {\r\n  display: flex;\r\n  align-items: center;\r\n  margin: 16px 0px;\r\n}\r\n.closeBtn {\r\n  margin-right: 16px !important;\r\n  height: 38px;\r\n  width: 100px;\r\n  box-shadow: none;\r\n}\r\n.inviteBtn {\r\n  margin-left: 16px !important;\r\n  height: 38px;\r\n  width: 100px;\r\n  box-shadow: none;\r\n}\r\n.checkbox {\r\n  height: 20px;\r\n  width: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GmailContactsModal_container__1ZJfY`,
	"list": `GmailContactsModal_list__TqFGg`,
	"searchWrapper": `GmailContactsModal_searchWrapper__uIw7z`,
	"selectAllContainer": `GmailContactsModal_selectAllContainer__xDR5e`,
	"searchInput": `GmailContactsModal_searchInput__2hQoE`,
	"buttonWrapper": `GmailContactsModal_buttonWrapper__pdhGM`,
	"closeBtn": `GmailContactsModal_closeBtn__3JdZ3`,
	"inviteBtn": `GmailContactsModal_inviteBtn__LpP-J`,
	"checkbox": `GmailContactsModal_checkbox__M3-HT`
};
export default ___CSS_LOADER_EXPORT___;
