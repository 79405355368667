import React, { useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@material-ui/core/Divider";
import * as ProfileInfo from "src/store/actions/EditProfile/EditProfileActions";
import Sidebaroptions from 'src/Components/SidebarOption';
import BasicProfile from "./BasicInfo";
import ContactProfile from "./ContactInfo";
import OtherInfo from "./Otherinfo";
import "./EditProfile.css";
import Page from "src/layout/Page";
import { CommunityContext } from "src/context/CommunityContext/CommunityContext.js";
import API from "../../network";

const defaultState = {
  basicInfo: false,
  contactInfo: false,
  otherInfo: false,
};

function EditProfile() {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const basicInfoRef = useRef(null);
  const contactInfoRef = useRef(null);
  const otherInfoRef = useRef(null);
  const communityContext = useContext(CommunityContext);
  const settingsData = useSelector((state) => state.voteupReducer.settingsData);

  const [isActive, setIsActive] = useState({
    ...defaultState,
    basicInfo: true,
  });

  useEffect(() => {
    if (userDetails && Object.keys(userDetails).length !== 0) {
      const userId = userDetails.userData.userId;
      dispatch(ProfileInfo.fetchProfileData(userId));
    }
  }, [dispatch, userDetails]);

  const clickHandler = (key) => {
    setIsActive({
      ...defaultState,
      [key]: true,
    });

    if (key === 'basicInfo' && basicInfoRef.current) {
      basicInfoRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (key === 'contactInfo' && contactInfoRef.current) {
      contactInfoRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (key === 'otherInfo' && otherInfoRef.current) {
      otherInfoRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [isNotificationSelected, setIsNotificationSelected] = useState(false);

  const showHideNotifications = () => {
    setIsNotificationSelected(!isNotificationSelected);
  }

  const onSkopicClick = async () => {
    try {
      const response = await API.changeCommunity(settingsData?.userTenant, settingsData?.userTenantId);
      if (response) {
        communityContext?.onChangeCommunity({
          tenantId: settingsData?.userTenantId,
          tenantName: settingsData?.userTenant,
        });
      };
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Page onSkopicClick={onSkopicClick}>
      <div className="EditProfile">
        <div className="sidebarParentoptions">
          <div className="sidebarParentoptions1">
            <Sidebaroptions
              active={isActive.basicInfo}
              text="Basic Information"
              onSidebaroptionClick={() => clickHandler('basicInfo')}
            />
            <Divider variant="fullWidth"></Divider>
            <Sidebaroptions
              active={isActive.contactInfo}
              text="Contact Information"
              onSidebaroptionClick={() => clickHandler('contactInfo')}
            />
            <Divider variant="fullWidth"></Divider>
            <Sidebaroptions
              active={isActive.otherInfo}
              text="Other Information"
              onSidebaroptionClick={() => clickHandler('otherInfo')}
            />
          </div>
        </div>
        <div className="sidebartoggleoptions">
          <BasicProfile ref={basicInfoRef} />
          <ContactProfile ref={contactInfoRef} />
          <OtherInfo ref={otherInfoRef} />
        </div>
        {/* {isNotificationSelected ? (
          <div className={`col-sm-3  ${styles.notifications}`}>
            <div className={styles.notificationscroll}>
              <Notifications />
            </div>
          </div>
        ) : null} */}
      </div>
    </Page>
  );
}

export default EditProfile;
