
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import * as moderatorActions from 'src/store/actions/Moderator/moderatoractions';
import 'src/Assets/css/Moderator/ModeratorHeader.css';

const ConfirmDialogDemo = (props) => {
  const dispatch = useDispatch();
  const moderatingCommunitysData = useSelector((state) => state.ModeratorReducer.moderatingCommunitysData)
  // const blockData=useSelector((state)=>state.ModeratorReducer.Blockedusersdata)
  const [visible, setVisible] = useState(false);
  // const toast = useRef(null);
  const [isBlockusers, setBlockusersArray] = useState([])
  // const data = useSelector(state => state.ManagemembersReducer.ManagemebersData)
  // const userDetails = useSelector((state) => state.SignInReducer.userDetails);

  useEffect(() => {
    let BlockUsers = [];
    if (moderatingCommunitysData && Object.keys(moderatingCommunitysData).length !== 0) {
      // moderatingCommunitysData.restrictions.filter(restrictions => restrictions.isPm === "1").map(moderatorId =>
      //   BlockUsers.push(moderatorId.tenantId)
      // );
      BlockUsers = moderatingCommunitysData.restrictions.filter(restrictions => restrictions.isPm === "1").map(moderatorId => moderatorId.tenantId);

      setBlockusersArray([...isBlockusers, BlockUsers]);
    }
  }, [moderatingCommunitysData]);

  const accept = () => {
    let id = props.id;
    const tenantId = props.tenantId;
    if (tenantId) {
      // // console.log(userDetails.userData.tenantId)
      // setTenantID([userDetails.userData.tenantId])
      dispatch(moderatorActions.fetchBlockMembersData(`?tenantId=${tenantId}&selectedIds=${id}`))
      // dispatch(moderatorActions.fetchBlockedUsersData())
      dispatch(moderatorActions.fetchManageMembersData(tenantId))
    }
    // document.getElementById(id);
  };

  return (
    <div>
      <div className='custom-confirm-dialog'>
        <ConfirmDialog />
        <ConfirmDialog className='custom-confirm-dialog' visible={visible} onHide={() => setVisible(false)} message={`Are you sure you want to ${props.isBlocked === 5 ? "Un" : ''}block the user?`}
          header="Block users confirmation" accept={accept} />
        <span>
          {/* <Button onClick={() => setVisible(true)} icon="pi pi-ban" label={props.isBlocked === 5 ? "UnblockUser" : "Blockuser"} className="blockuser-confirmbutton" /></span> */}
          <Button onClick={() => setVisible(true)} icon="pi pi-ban" label={props.isBlocked === 5 ? "UnblockUser" : "Blockuser"} /></span>
      </div>
    </div>
  )
}

export default ConfirmDialogDemo;
