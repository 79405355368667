// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-datepicker table td {
  padding: 0rem !important;
}
.p-datepicker .p-timepicker button {
  width: 1rem !important;
  height: 1rem !important;
}
.p-datepicker {
  border-radius: 20px !important;
  border-width: 0px !important;
  padding: 10px 5px !important;
  left: 4% !important;
}
@media screen and (min-width: 1100px){
  .p-datepicker {
    left: 10% !important;
    padding: 10px 20px !important;
  }
}
@media screen and (min-width: 1300px){
  .p-datepicker {
    left: 18% !important;
    padding: 10px 20px !important;
    top: 8.5% !important;
  }
}
@media screen and (min-width: 1440px){
  .p-datepicker {
    left: 20% !important;
    padding: 10px 20px !important;
    top: 2% !important;
    padding-top: 40px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/Assets/css/ReusuableComponents/CalendarComponent.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;AACA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,8BAA8B;EAC9B,4BAA4B;EAC5B,4BAA4B;EAC5B,mBAAmB;AACrB;AACA;EACE;IACE,oBAAoB;IACpB,6BAA6B;EAC/B;AACF;AACA;EACE;IACE,oBAAoB;IACpB,6BAA6B;IAC7B,oBAAoB;EACtB;AACF;AACA;EACE;IACE,oBAAoB;IACpB,6BAA6B;IAC7B,kBAAkB;IAClB,4BAA4B;EAC9B;AACF","sourcesContent":[".p-datepicker table td {\n  padding: 0rem !important;\n}\n.p-datepicker .p-timepicker button {\n  width: 1rem !important;\n  height: 1rem !important;\n}\n.p-datepicker {\n  border-radius: 20px !important;\n  border-width: 0px !important;\n  padding: 10px 5px !important;\n  left: 4% !important;\n}\n@media screen and (min-width: 1100px){\n  .p-datepicker {\n    left: 10% !important;\n    padding: 10px 20px !important;\n  }\n}\n@media screen and (min-width: 1300px){\n  .p-datepicker {\n    left: 18% !important;\n    padding: 10px 20px !important;\n    top: 8.5% !important;\n  }\n}\n@media screen and (min-width: 1440px){\n  .p-datepicker {\n    left: 20% !important;\n    padding: 10px 20px !important;\n    top: 2% !important;\n    padding-top: 40px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
