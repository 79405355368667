// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.my-modal .modal-content{
    width: 707px;
  
    background-color: #F3F2F0;
}
.my-modal .ModalPosts{
    height: 450px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color:#707070;
}
.my-modal .ModalFooter{
    margin: auto;
}
.my-modal  .PostCommunity{
    width: 183px;
    height:48px;
    margin-left: 10px;
    border:none;
    border-radius: 20px;
    color:white;
    cursor: pointer;
}
.my-modal .askCommunity{
    background-color: #2168B2;

}
.my-modal .sayCommunity{
    background-color:  #36B549;

}
.my-modal  .Message{
    text-align: center;
    margin-top:200px;
    font-size:20px;
}

.loader{
    width: 60px !important;
    height: 60px !important;
    position: absolute;
    top: 44%;
    left: 44%;
}
`, "",{"version":3,"sources":["webpack://./src/Assets/css/home/AsksModal.css"],"names":[],"mappings":";AACA;IACI,YAAY;;IAEZ,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,qBAAqB;IACrB,uBAAuB;AAC3B;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,WAAW;IACX,mBAAmB;IACnB,WAAW;IACX,eAAe;AACnB;AACA;IACI,yBAAyB;;AAE7B;AACA;IACI,0BAA0B;;AAE9B;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,QAAQ;IACR,SAAS;AACb","sourcesContent":["\n.my-modal .modal-content{\n    width: 707px;\n  \n    background-color: #F3F2F0;\n}\n.my-modal .ModalPosts{\n    height: 450px;\n    overflow-y: scroll;\n    scrollbar-width: thin;\n    scrollbar-color:#707070;\n}\n.my-modal .ModalFooter{\n    margin: auto;\n}\n.my-modal  .PostCommunity{\n    width: 183px;\n    height:48px;\n    margin-left: 10px;\n    border:none;\n    border-radius: 20px;\n    color:white;\n    cursor: pointer;\n}\n.my-modal .askCommunity{\n    background-color: #2168B2;\n\n}\n.my-modal .sayCommunity{\n    background-color:  #36B549;\n\n}\n.my-modal  .Message{\n    text-align: center;\n    margin-top:200px;\n    font-size:20px;\n}\n\n.loader{\n    width: 60px !important;\n    height: 60px !important;\n    position: absolute;\n    top: 44%;\n    left: 44%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
