export default function formattedCommunitiesList(data, countryCode) {
  let arrayList = [];
  let polygonArea = [];
  if (data?.physicalTenantList) {
    for (let i = 0; i < data?.physicalTenantList.length - 1; i++) {
      for (let j = 0; j < data?.tenantNeighberLats[i]?.length; j++) {
        if (!polygonArea[i]) {
          polygonArea[i] = [];
        }
        polygonArea[i].push({
          lat: Number(data["tenantNeighberLats"][i][j]),
          lng: Number(data["tenantNeighberLags"][i][j]),
        });
      }

      arrayList.push({
        communityName: data["physicalTenantList"][i],
        description: data["physicalTenantDescs"][i],
        countryCode: data["physicalTenantCountryId"][i],
        imageUrl: data["physicalTenantThumbLogos"][i],
        communityType: data["tenantType"][i],
        location: {
          lat: data["tenantCenterPointLatitudes"][i],
          lng: data["tenantCenterPointLongitudes"][i],
        },
        area: polygonArea[i],
        tenantId: data["physicalTenantIds"][i],
        // followStatus: countryCode ? "" : data["physicalTenantFollowStatus"][i],
        followStatus: data["physicalTenantFollowStatus"][i],
      });
    }

    const list = arrayList?.filter((item) => item.countryCode === countryCode);
    return countryCode ? list : arrayList;
  }
}
