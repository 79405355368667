import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import makedefault from "src/Assets/images/makedefault.png";
import useStyles from "./styles";
import API from "../../../network";
import "./index.css";

const CommunitiesList = ({
  data,
  handleMakeDefault,
  handleListItem,
  selectedItem,
  country,
  handleMouseOver,
  handleMouseOut,
  handleViewActivity,
  handleMakeDefaultAfterSignIn,
  handleMakeFollowUnfollow,
  signIn,
}) => {
  const imageServerURL = process.env.REACT_APP_SKOPIC_IMAGE_OTHER;
  const { buttonContainer, defaultButton, defaultImageButton } = useStyles();
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);

  const [list, setList] = useState([]);

  const location = useLocation();

  useEffect(() => {
    if (signIn) {
      const filteredData = data?.filter((item) => (item?.followStatus !== 1 && item?.followStatus !== 2));
      setList(filteredData);
    } else {
      setList(data);
    }
  }, [data]);

  // console.log(`list: `);
  // console.log(list);
  // console.log(`list: `);

  // const requestToFollow = (id) => {
  //   let formdata = new FormData();
  //   formdata.append("tenantId", id);
  //   API.requestToFollow(formdata)
  //   .then((res) => {
  //     alert(res.data.status);
  //   })
  // }

  const requestToFollow = (id) => {
    const formData = new FormData();
    formData.append("tenantId", id);

    API.requestToFollow(formData)
      .then((res) => {
        if (res?.data?.status) {
          alert(res.data.status);
        } else {
          alert("Unexpected response from server.");
        }
      })
      .catch((err) => {
        console.error("Error in requestToFollow:", err);
        alert("Failed to send follow request. Please try again.");
      });
  };

  return (
    <>
      {list?.length > 0 ? (
        list?.map((item, index) => {
          return (
            <div
              onClick={() => handleListItem(item?.tenantId, item?.location)}
              key={`key-${item?.tenantId}-${item?.communityName}`}
              onMouseOver={() =>
                handleMouseOver(item?.tenantId, item?.location)
              }
              onMouseOut={handleMouseOut}
            >
              <Grid
                container
                className={`default-community-list-card-container ${selectedItem === item?.tenantId ? "selected-community" : ""
                  }`}
                key={index}
              >
                <Grid item xs={3} style={{ display: "flex" }}>
                  {/* src={item?.imageUrl} */}
                  <img
                    src={`${imageServerURL}${item?.imageUrl}`}
                    className="default-community-list-card-image"
                    alt="defaultCommunityImage"
                  />
                </Grid>
                <Grid
                  item
                  xs={9}
                  className="default-community-list-card-button-container"
                >
                  <div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#373334",
                        height: '20px',
                        overflow: 'auto',
                      }}
                    >
                      {item?.communityName}
                    </div>
                    <div style={{
                      fontSize: "12px", color: "#707070", height: '20px',
                      overflow: 'auto',
                      marginBottom: "3px"
                    }}>
                      {item?.description}
                    </div>
                  </div>
                  {location?.pathname !== "/communities" ? (
                    <div className={buttonContainer}>
                      <div className={defaultButton}>
                        <Button
                          className="make-default-button-follow-more-community"
                          variant="contained"
                          onClick={() =>
                            handleMakeDefault(
                              item?.communityName,
                              item?.tenantId,
                              country
                            )
                          }
                        >
                          <div>
                            <img
                              src={makedefault}
                              className="make-default-image-follow-more-community"
                              alt="default-icon"
                            />
                            Make Default
                          </div>
                        </Button>
                      </div>
                      <div className={defaultButton}>
                        <Button
                          className="view-activity-button-follow-more-community"
                          variant="contained"
                          onClick={() =>
                            handleViewActivity(
                              item?.communityName,
                              item?.tenantId,
                              country
                            )
                          }
                        >
                          View Activity
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className={buttonContainer} style={{ gap: "5px" }}>
                      {item?.followStatus === 2 && (
                        <div className={defaultButton}>
                          <Button variant="contained" onClick={() => { }}>
                            This is your default community
                          </Button>
                        </div>
                      )}
                      {item?.followStatus === 0 &&
                        (item?.communityType === "Public" ||
                          item?.communityType === "public") && (
                          <div className={defaultButton}>
                            <Button
                              style={{ height: "30px" }}
                              variant="contained"
                              onClick={() =>
                                handleMakeFollowUnfollow(
                                  item?.tenantId,
                                  item?.communityName,
                                  item?.followStatus
                                )
                              }
                            >
                              <span style={{ fontSize: "10px" }}>Follow</span>
                            </Button>
                          </div>
                        )}
                      {item?.followStatus === 0 &&
                        item?.communityType === "private" && (
                          <div className={defaultButton}>
                            <Button
                              style={{ height: "30px" }}
                              variant="contained"
                              onClick={() => requestToFollow(item?.tenantId)}
                            >
                              <span style={{ fontSize: "10px" }}>Request to Follow</span>
                            </Button>
                          </div>
                        )}
                      {item?.followStatus === 1 && (
                        <div className={defaultButton}>
                          <Button
                            style={{ height: "30px" }}
                            variant="contained"
                            onClick={() =>
                              handleMakeFollowUnfollow(
                                item?.tenantId,
                                item?.communityName,
                                item?.followStatus
                              )
                            }
                          >
                            <span style={{ fontSize: "10px" }}>Unfollow</span>
                          </Button>
                        </div>
                      )}
                      {item?.followStatus !== 2 && (
                        <div className={defaultButton}>
                          <Button
                            style={{ height: "30px" }}
                            variant="contained"
                            onClick={() =>
                              handleViewActivity(
                                item?.communityName,
                                item?.tenantId,
                                country
                              )
                            }
                          >
                            <span style={{ fontSize: "10px" }}>View Activity</span>
                          </Button>
                        </div>
                      )}
                      {item?.followStatus !== 2 &&
                        (item?.communityType === "Public" ||
                          item?.communityType === "public") && (
                          <div className={defaultImageButton}>
                            <Button
                              style={{ height: "30px" }}
                              variant="contained"
                              onClick={() =>
                                handleMakeDefaultAfterSignIn(item?.tenantId)
                              }
                            >
                              <img
                                src={makedefault}
                                alt="make default"
                                width={"12px"}
                                height={"12px"}
                              />
                            </Button>
                          </div>
                        )}
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          );
        })
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          No Communities Found
        </div>
      )}
    </>
  );
};

export default CommunitiesList;
