import React from "react";
import backwardarrow from "src/Assets/images/backwardarrow.svg";
import Post from "src/pages/HomePage/components/Post";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "src/Assets/css/home/searchresult.module.css";
import "./index.css";

const SearchResultBeforeLogin = (props) => {
  const {
    isActionDisabled = false,
    flow,
    loading = false,
    searchData = {},
    handleBack = () => { },
    childSaysData,
    fetchChildSaysData = () => { },
    postChildSayData = () => { },
    fetchFollowData = () => { },
    fetchVoteUpStatus = () => { },
    fetchFeedData = () => { },
    deleteMessage = () => { },
    refreshFeedData = () => { },
  } = props;

  if (loading) {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <React.Fragment>
      {(searchData && Object.keys(searchData).length !== 0) ? (
        (searchData.totalList > 0) ? (
          <div className={styles.searchresult}>
            <div>
              <div className={styles.noofsearchresuts}>
                <a>
                  <img
                    src={backwardarrow}
                    alt="back-icon"
                    onClick={handleBack}
                    className="mr-3"
                  />
                </a>
                <p>
                  {searchData.totalList} Results for{" "}
                  <b>{searchData.searchText}</b>
                </p>
              </div>
              <Post
                loading={loading}
                listdata={searchData.messageList}
                data={searchData}
                childSaysData={childSaysData}
                fetchChildSaysData={fetchChildSaysData}
                postChildSayData={postChildSayData}
                // followData={followData}
                fetchFollowData={fetchFollowData}
                fetchVoteUpStatus={fetchVoteUpStatus}
                fetchFeedData={fetchFeedData}
                deleteMessage={deleteMessage}
                refreshFeedData={refreshFeedData}
                isActionDisabled={isActionDisabled}
                showData="search"
                flow={flow}
              />
            </div>
          </div>
        ) : (
          <div className={styles.searchresult}>
            <div>
              <div className={styles.noofsearchresuts}>
                <a><img src={backwardarrow} onClick={handleBack} /></a>
                <p>{searchData?.totalList || 0} Results for <b>{searchData?.searchText}</b></p>
              </div>
              <p style={{ textAlign: 'center', marginBottom: 30 }}>No results found matching your criteria</p>
            </div>
          </div>
        )
      ) : (
        <div className={styles.searchresult}>
          <div>
            <div className={styles.noofsearchresuts}>
              <a><img src={backwardarrow} onClick={handleBack} /></a>
              <p>0 Results found!</p>
            </div>
            <p style={{ textAlign: 'center', marginBottom: 30 }}>No results found matching your criteria</p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SearchResultBeforeLogin;
