import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  CircularProgress,
  IconButton,
  Box,
} from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { IoIosCloseCircleOutline } from "react-icons/io";
import axios from "axios";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Cookies from "js-cookie";
import SearchResultBeforeLogin from "../viewActivityBeforeIn/SearchResultBeforeSignup";
import Feed from "../feed";
import PhotoPreview from "../../PhotoPreview";
import SayAskPost from "./sayAskPost";
import LeftSideBar from "src/pages/HomePage/components/LeftSideBar";
import useStyles from "../viewActivityBeforeIn/styles";
import API from "../../../network";
import layoutStyles from "./ViewActAfterSignIn.module.css";
import RightSideBar from "src/pages/HomePage/components/RightSideBar";
import RestrictionMsgModal from "src/Components/RestrictionMsgModal";
import { fetchloginUser } from "src/store/actions/SignInActions/SignInAction";
import TrendingAsks from "src/pages/HomePage/components/TrendingAsks";
import TrendingTags from "src/pages/HomePage/components/TrendingTags";
import SingleTrendingTag from "src/pages/HomePage/components/SingleTrendingTag";
import Page from "src/layout/Page";
import SearchTagModal from "src/pages/HomePage/components/SearchedTagModal";
import { CommunityContext } from "src/context/CommunityContext/CommunityContext.js";

const ViewActivitySignIn = () => {
  // const feedReducerData = useSelector((state) => state.feedReducer.feedData);
  const imageServerURL = process.env.REACT_APP_SKOPIC_IMAGE_OTHER;
  const baseURL = process.env.REACT_APP_SERVICES_API_OTHER;
  const dispatch = useDispatch();
  const communityContext = useContext(CommunityContext);
  const settingsData = useSelector((state) => state.voteupReducer.settingsData);
  const {
    buttonContainer,
    shareUpdate,
    trending,
    seemore,
    feed,
    container,
    moderator,
    prospectimagesdiv,
    invitebutton,
    filterinput,
    post,
    postinput,
    roundcontent,
    asksayNav,
    askHoverImage,
    askSelectedImage,
    sayHoverImage,
    saySelectedImage,
    updateSelectedImage,
    updateHoverImage,
    tagHoverImage,
    tagSelectedImage,
    contributeContainer,
    asks,
    askprofilecontnet,
    askavatar,
    askprofilecontnetName,
    askprofilecontnetMessage,
    comunityProfileArrowIcon,
    loader,
    profileHover,
  } = useStyles();

  const location = useLocation();
  const history = useHistory();

  const [images, setImages] = useState({});
  const [selectedImage, setSelectedImage] = useState({});
  const [contributors, setContributors] = useState({});
  const [communityData, setCommunityData] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [feedData, setFeedData] = useState({});
  const [previewModal, setPreviewModal] = useState(false);
  const [contentType, setContentType] = useState("feed");
  const [filterPostType, setFilterPostType] = useState("");
  const [trendingAsks, setTrendingAsks] = useState([]);
  const [allTrendingAsks, setAllTrendingAsks] = useState({});
  const [selectedTagDetails, setSelectedTagDetails] = useState({});
  const [allTrendingTags, setAllTrendingTags] = useState({});
  const [trendingTags, setTrendingTags] = useState([]);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const listRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [saysId, setSaysId] = useState("");
  const [childSaysData, setChildSaysData] = useState([]);
  const [contributorBio, setContributorBio] = useState({});
  const [showBio, setShowBio] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [isEnable, setIsEnable] = useState(null);
  const [isSignIn, setIsSignIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedLoading, setFeedLoading] = useState(false);
  const [childSayLoading, setChildSayLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [searchData, setSearchData] = useState({});
  const [invitesShow, setInvitesShow] = React.useState(false);
  const [invite, setInvite] = useState(null);
  const [selectedCommunity, setSelectedCommunity] = useState({});
  const [feedPageNumber, setFeedPageNumber] = useState(1);
  const [voteUpStatus, setVoteUpStatus] = useState({});
  const [followData, setFollowData] = useState({});
  const [hasRestrictionSays, setHasRestrictionSays] = useState(false);
  const [asksToBeAnswered, setAsksToBeAnswered] = React.useState({});
  const [answersToYourAsk, setAnswersToYourAsk] = React.useState({});
  const [clearSearchHeader, setClearSearchHeader] = useState(false);
  const [showSearchedTagModal, setShowSearchedTagModal] = useState(false);
  const [hashTag, setHashTag] = useState("");

  const userDetails = useSelector((state) => state.SignInReducer.userDetails);

  useEffect(() => {
    dispatch(fetchloginUser());
  }, []);

  useEffect(() => {
    const { signIn, tenantId, community } = location?.state || {}
    setIsEnable(signIn);
    setSelectedCommunity({
      tenantId: tenantId,
      tenantName: community || "",
    });
  }, [location]);

  const onHashTagClick = (hashTag) => {
    setHashTag(hashTag);
    setShowSearchedTagModal(true);
  };

  const onCloseSearchTagModal = () => {
    setHashTag("");
    setShowSearchedTagModal(false);
  };

  const onInvitesSelction = (event) => {
    event.stopPropagation();
    setInvitesShow(true);
    setInvite(true);

    // Check if previewModal is currently open, then close it
    if (previewModal) {
      setPreviewModal(false);
    }
  };

  const fetchSearchData = async (params) => {
    setContentType("search");
    setSearchParam(params);
    setFeedLoading(true);
    try {
      const response = await API.getSearchData(params);
      setSearchData(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setFeedLoading(false);
    }
  };

  const handleMakeDefault = async () => {
    let formdata = new FormData();
    formdata.append("userDefaultTenantId", location?.state?.tenantId);
    await API.setDefaultTenant(formdata)
      .then((res) => {
        console.log("handleMakeDefault res", res);
        setIsEnable(false);
        setIsSignIn(true);
      })
      .catch((err) => { });
  };

  const handleFollow = async () => {
    let formdata = new FormData();
    formdata.append("communityID", location?.state?.tenantId);
    formdata.append("communityName", location?.state?.community);
    formdata.append("followStat", 1);
    formdata.append("sourceApp", "WebApp");
    await API.followUnfollowCommunity(formdata)
      .then((res) => {
        console.log("handleMakeFollowUnfollow res", res);
        setIsEnable(false);
        setIsSignIn(true);
      })
      .catch((err) => { });
  };

  const fetchCommunityData = async () => {
    await axios
      .request({
        url: `${baseURL}/jsonindex/community-url?src=inviteLink&tid=${location?.state?.tenantId
          }&id=${"Z2Vmb3lpNDMzOEBmcmFuZGluLmNvbQ=="}`,
        method: "Get",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Set-Cookie": Cookies.get("JSESSIONID"),
        },
        withCredentials: true,
      })
      .then((res) => {
        setCommunityData(res);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      fetchParamApi();
      fetchAllFeed();
      fetchContributeApi();
      fetchTrendingAsks();
      fetchTrendingTags();
    }, 500);

    if (!location?.state?.signIn) {
      fetchCommunityData();
    }
  }, [location?.state?.signIn]);

  const fetchContributeApi = async () => {
    try {
      const res = await API.fetchContributors();
      setContributors(res.data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchParamApi = async () => {
    await axios
      .get(`${baseURL}/jsonindex/main?logParam=restrict`)
      .then((res) => {
        setImages(res?.data);
      });
  };

  const filterPosts = (filterType) => {
    setFilterPostType(filterType);
    fetchAllFeed(filterType);
  };

  const fetchAllFeed = async (filterType) => {
    setFeedLoading(true);
    const newPageNumber = (feedPageNumber - 1) * 10;
    try {
      const response = await API.fetchFeedData(filterType, newPageNumber);
      setFeedData(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setFeedLoading(false);
    }
  };

  const setShowData = (contentType) => {
    let copiedContentType = contentType;
    //if there is need to display singleTrendingTag then remove if condition
    // if (contentType === "singleTrendingTag") {
    //   copiedContentType = "tag";
    // }

    if (copiedContentType === "ask") {
      fetchAllAsks();
    } else if (copiedContentType === "tag") {
      fetchAllTags();
    }
    setContentType(copiedContentType);
  };

  const fetchAllTags = async () => {
    setFeedLoading(true);
    try {
      const response = await API.fetchAllTags();
      setAllTrendingTags(response.data || {});
    } catch (e) {
      console.error(e);
    } finally {
      setFeedLoading(false);
    }
  };

  const fetchAllAsks = async () => {
    setFeedLoading(true);
    try {
      const response = await API.fetchAllAsks();
      setAllTrendingAsks(response.data || {});
    } catch (e) {
      console.error(e);
    } finally {
      setFeedLoading(false);
    }
  };

  const fetchSelectedTagDetails = async (tagId) => {
    try {
      const tenantId = selectedCommunity?.tenantId;
      const response = await API.fetchTagDetails(tenantId, tagId);
      setSelectedTagDetails(response?.data || {});
    } catch (e) {
      console.error('Error fetching tag details:', e);
    }
  };

  const fetchTrendingTags = async () => {
    try {
      const response = await API.fetchTrendingTags();
      setTrendingTags(response?.data?.trendingTags || []);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchTrendingAsks = async () => {
    try {
      const response = await API.fetchTrendingAsks();
      setTrendingAsks(response?.data?.trendingAsks || []);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (communityData?.status === 200) {
      setTimeout(() => {
        setIsFetching(false);
      }, 5000);
    }
  }, [communityData]);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = listRef.current;
    if (scrollTop + clientHeight === scrollHeight) {
      setIsFetching(true);
      setIsClicked(true);
      setPage(page + 10);
    }
  };

  useEffect(() => {
    const obj = images?.communities?.find(
      (item) => item?.id === location?.state?.tenantId
    );
    setSelectedImage(obj);
  }, [images]);

  useEffect(() => {
    fetchAllFeed(filterPostType);
  }, [filterPostType, feedPageNumber]);

  const onCommunityChange = async (id, name) => {
    setFeedPageNumber(1);
    setFeedData({});
    if (selectedCommunity && selectedCommunity.id !== id) {
      try {
        const response = await API.changeCommunity(name, id);
        setSelectedCommunity(response.data);
        if (response) {
          fetchAllFeed();
          if (
            contentType === "tag" ||
            contentType === "ask" ||
            contentType === "search"
          ) {
            setContentType("feed");
          }
          fetchContributeApi();
          fetchTrendingAsks();
          fetchTrendingTags();
          dispatch(fetchloginUser());
        }
      } catch (e) {
        console.error(e);
      }
      window.scrollTo(0, 0);
    }
  };

  const handleFilterClick = (filterType) => {
    setSaysId(""); //reset says id
    setFeedPageNumber(1); //reset pageNumber
    setFilterPostType(filterPostType === filterType ? "" : filterType);
  };

  const showPreview = (e) => {
    setPreviewModal(!previewModal);
  };

  const handleSeeAll = (id) => {
    setContentType(id);
    setSelectedId("");
  };

  const handleBack = () => {
    setContentType("feed");
    setPage(0);
    setClearSearchHeader(true);
    // fetchAskApi();
    // fetchTagsApi();
  };

  const handleBackArrow = () => {
    history.push("/communities", {
      countryCode: location?.state?.countryCode,
    });
  };

  const handleSayClick = async (e, id) => {
    e.preventDefault();
    if (saysId === id) {
      setSaysId("");
    } else {
      setSaysId(id);
      try {
        setChildSayLoading(true);
        let formdata = new FormData();
        formdata.append("id", id);
        formdata.append("in_pop_columns", "all");
        formdata.append("in_limit", 0);
        const res = await axios.post(`${baseURL}/jsonuser/child-says`, formdata);
        setChildSaysData(res?.data);
      } catch (e) {
        console.error(e);
      } finally {
        setChildSayLoading(false);
      }
    }
  };

  const handleClickEvent = (id, showBio) => {
    const data = contributors?.contribut.find((item) => {
      return item?.id === id;
    });
    setContributorBio(data);
    setShowBio(showBio);
  };

  const onUpdateSelection = (type) => {
    //TODO: need to revise if condition
    // if (isEnable) {
    setModalShow(true);
    setModalType(type);
    // }
  };

  const onHideUpdateModal = () => {
    setModalShow(false);
    setModalType(null);
  };

  const onModalSelect = () => {
    setModalShow(false);
  };

  const handleClose = () => {
    setIsEnable(false);
    setIsSignIn(true);
  };

  const deleteMessage = async (id, type, Parent_ID) => {
    console.log('Inside deleteMessage', id, type, Parent_ID);
    try {
      const response = await API.deleteMessage(id);
      if (response) {
        if (type === "ChildSay") {
          console.log("childSaysData", childSaysData);
          const updatedData = childSaysData.childSAYsMessageList.filter(
            (each) => each.id !== id
          );
          setChildSaysData({
            ...childSaysData,
            childSAYsMessageList: updatedData,
          });
          if (contentType === "tag" || contentType === "singleTrendingTag") {
            updateTrendingTagData(Parent_ID, "minus");
          } else if (contentType === "ask") {
            updateTrendingAskData(Parent_ID, "minus");
          } else if (contentType === "search") {
            updateSearchData(Parent_ID, "minus");
          } else {
            updateFeedData(Parent_ID, "minus");
          }
        } else {
          if (contentType === "feed") {
            const newData = [...feedData.feed];
            const modifiedData = newData.filter((each) => each.id !== id);
            const updatedFeedData = {
              ...feedData,
              breadCountLength: modifiedData.length,
              feed: [...modifiedData],
            };
            setFeedData(updatedFeedData);
          } else if (contentType === "ask") {
            const newData = [...allTrendingAsks?.trendingAsks];
            const modifiedData = newData.filter((each) => each.id !== id);
            setAllTrendingAsks(modifiedData);
          } else if (contentType === "tag") {
            const newData = [...allTrendingTags.trendingTags];
            const modifiedData = newData.filter((each) => each.id !== id);
            setAllTrendingTags(modifiedData);
          } else if (contentType === "search") {
            const newData = [...searchData.messageList];
            const modifiedData = newData.filter((each) => each.id !== id);
            setSearchData({
              ...searchData,
              totalList: modifiedData.length,
              messageList: modifiedData,
            });
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateFeedData = (id, operator) => {
    // updating sayCount in the list based on operator
    // operator = 'add' | 'minus'
    const feed = feedData?.feed || [];
    const copiedFeed = [...feed];
    const updatedFeed = copiedFeed.map((eachFeed) => {
      const sayCount =
        eachFeed?.id === id
          ? operator === "add"
            ? eachFeed?.sayCount + 1
            : eachFeed?.sayCount - 1
          : eachFeed.sayCount;
      return {
        ...eachFeed,
        sayCount,
      };
    });
    const updatedFeedData = { ...feedData, feed: updatedFeed };
    setFeedData(updatedFeedData);
  };

  const updateSearchData = (id, operator) => {
    // updating sayCount in the list based on operator
    // operator = 'add' | 'minus'
    const searchFeed = [...searchData.messageList] || [];
    const updatedSearchFeed = searchFeed.map((eachFeed) => {
      const sayCount =
        eachFeed?.id === id
          ? operator === "add"
            ? eachFeed?.sayCount + 1
            : eachFeed?.sayCount - 1
          : eachFeed.sayCount;
      return {
        ...eachFeed,
        sayCount: sayCount,
      };
    });
    const updatedData = { ...searchData, messageList: [...updatedSearchFeed] };
    setSearchData(updatedData);
  };

  //update trending tags or trendingSingle tag
  const updateTrendingTagData = (id, operator) => {
    // updating sayCount is the list based on operator
    // operator = 'add' | 'minus'
    const copiedTags = contentType === "singleTrendingTag"
      ? [...selectedTagDetails?.messageList]
      : [...allTrendingTags?.trendingTags];
    const updatedTags = copiedTags.map((eachTag) => {
      const sayCount =
        eachTag?.id === id
          ? operator === "add"
            ? eachTag?.sayCount + 1
            : eachTag?.sayCount - 1
          : eachTag.sayCount;
      return {
        ...eachTag,
        sayCount,
      };
    });

    if (contentType === "singleTrendingTag") {
      setSelectedTagDetails({ ...selectedTagDetails, messageList: [...updatedTags] });
    } else {
      setAllTrendingTags({ ...allTrendingTags, trendingTags: [...updatedTags] });
    }
  };

  //update trending asks
  const updateTrendingAskData = (id, operator) => {
    // updating sayCount is the list based on operator
    // operator = 'add' | 'minus'
    const copiedAsks = [...allTrendingAsks?.trendingAsks];
    const updatedAsks = copiedAsks.map((eachAsk) => {
      const sayCount =
        eachAsk?.id === id
          ? operator === "add"
            ? eachAsk?.sayCount + 1
            : eachAsk?.sayCount - 1
          : eachAsk.sayCount;
      return {
        ...eachAsk,
        sayCount,
      };
    });
    setAllTrendingAsks({ ...allTrendingAsks, trendingAsks: [...updatedAsks] });
  };

  const refreshFeedData = (isChildPost, id) => {
    console.log("refreshFeedData", contentType, id, isChildPost);
    if (contentType === "feed") {
      if (isChildPost) {
        fetchChildSaysData(id);
      } else {
        fetchAllFeed(filterPostType);
      }
    } else if (contentType === "tag") {
      if (isChildPost) {
        fetchChildSaysData(id);
      } else {
        fetchAllTags();
      }
    } else if (contentType === "ask") {
      if (isChildPost) {
        fetchChildSaysData(id);
      } else {
        fetchAllAsks();
      }
    } else if (contentType === "search") {
      if (isChildPost) {
        fetchChildSaysData(id);
      } else {
        fetchSearchData(searchParam);
      }
    }
  };

  const fetchChildSaysData = async (id, popCols, limit) => {
    setChildSayLoading(true);
    try {
      const response = await API.fetchChildSaysData(id, popCols, limit);
      setChildSaysData(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setChildSayLoading(false);
    }
  };

  const fetchVoteUpStatus = async (id, status) => {
    try {
      const response = await API.fetchVoteUpStatus(id, status);
      setVoteUpStatus(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFollowunfollow = async (id, status) => {
    try {
      const response = await API.fetchFollowUnfollowData(id, status);
      setFollowData(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const postChildSayData = async (id, msg, lnglat, locSelect) => {
    try {
      const formdata = new FormData();
      formdata.append("Message", msg);
      const response = await API.postChildSayData(
        id,
        formdata,
        lnglat,
        locSelect
      );
      const status = response?.data?.status || "";
      if (status === "New Hash") {
        return "New Hash";
      } else if (status === "E_Say" || status === "E") {
        setHasRestrictionSays(true);
      } else {
        fetchChildSaysData(id);
        if (
          contentType === "tag" ||
          contentType === "singleTrendingTag"
        ) {
          updateTrendingTagData(id, "add");
        } else if (contentType === "ask") {
          updateTrendingAskData(id, "add");
        } else if (contentType === "search") {
          updateSearchData(id, "add");
        } else {
          updateFeedData(id, "add");
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const clearAsksAndSays = () => {
    setAsksToBeAnswered({});
    setAnswersToYourAsk({});
  };

  const fetchSaysDataForAsks = async (msg, latLong, locSelection, postingOption) => {
    try {
      const formdata = new FormData();
      formdata.append("Message", msg);
      const response = await API.fetchAsksData(
        formdata,
        latLong,
        locSelection,
        postingOption
      );
      setAnswersToYourAsk(response.data || {});
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAsksToAnswer = async (msg, latLong, locSelection, postingOption) => {
    try {
      const formdata = new FormData();
      formdata.append("Message", msg);
      const response = await API.fetchSaysData(
        formdata,
        latLong,
        locSelection,
        postingOption
      );
      setAsksToBeAnswered(response.data || {});
    } catch (e) {
      console.error(e);
    }
  };

  const askYourCommunity = async (id) => {
    try {
      await API.askYourCommunity(id);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteAnswersToAsksDialogPost = (id) => {
    const newAnswersToYourAsksList = answersToYourAsk?.messageList?.filter(
      (item) => item.id !== id
    );

    // Update the state correctly
    setAnswersToYourAsk({
      ...answersToYourAsk, // Spread the existing answersToYourAsk properties
      messageList: newAnswersToYourAsksList, // Update the messageList with the filtered list
    });
  };

  const deleteAsksToBeAnsweredDialogPost = (id) => {
    const newAnswersToYourAsksList = asksToBeAnswered?.messageList?.filter(
      (item) => item.id !== id
    );

    // Update the state correctly
    setAsksToBeAnswered({
      ...asksToBeAnswered, // Spread the existing answersToYourAsk properties
      messageList: newAnswersToYourAsksList // Update the messageList with the filtered list
    });
  };

  const onSkopicClick = async () => {
    try {
      const response = await API.changeCommunity(settingsData?.userTenant, settingsData?.userTenantId);
      if (response) {
        communityContext?.onChangeCommunity({
          tenantId: settingsData?.userTenantId,
          tenantName: settingsData?.userTenant,
        });
      };
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Page fetchSearchData={fetchSearchData} clearSearchHeader={clearSearchHeader} onSkopicClick={onSkopicClick} pageType={'viewActivity'}>
      {isLoading ? (
        <CircularProgress className={loader} />
      ) : (
        <React.Fragment>
          <div className="container">
            <div className={`row ${layoutStyles.rowContainer}`} style={{ marginBottom: isEnable ? "110px" : "5px" }}>
              <div className={`col-lg-3 ${layoutStyles.leftside}`}>
                <LeftSideBar
                  onCommunityChange={onCommunityChange}
                  setShowData={() => { }}
                  selectedCommunity={selectedCommunity}
                  disableCommunityChange={false}
                  hideFooter={true}
                />
              </div>
              <div className={`col-lg-6 ${layoutStyles.middle}`}>
                {contentType === "feed" ? (
                  <>
                    <div
                      className={feed}
                      style={{
                        backgroundImage: `url(${imageServerURL}${userDetails?.userData?.tenantLogo})`,
                      }}
                    // onClick={(e) => showPreview(e)}
                    >
                      <div className={container}>
                        {userDetails.userData.tenantName ? (
                          <div className={moderator}>
                            <p
                              onClick={(e) => showPreview(e)}
                              style={{
                                fontSize: "20px",
                                color: "#373334",
                                margin: 0,
                                cursor: "pointer",
                              }}
                            >
                              {userDetails.userData.tenantName}
                            </p>
                            <MdOutlineArrowForwardIos
                              className={comunityProfileArrowIcon}
                              style={{ cursor: "pointer" }}
                              onClick={(e) => showPreview(e)}
                            />
                          </div>
                        ) : (
                          <div className={moderator}>{null}</div>
                        )}

                        <div className={prospectimagesdiv}>
                          <a
                            className={invitebutton}
                            onClick={onInvitesSelction}
                            style={{ cursor: "not-allowed" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.878"
                              height="15.779"
                              viewBox="0 0 15.878 15.779"
                            >
                              <g
                                id="Group_1924"
                                data-name="Group 1924"
                                transform="translate(-6714.617 -3413.368)"
                              >
                                <path
                                  id="Path_435"
                                  data-name="Path 435"
                                  d="M34.165,26.211a3.831,3.831,0,1,0-5.059,0A6.11,6.11,0,0,0,25.5,31.795c0,.726.376,1.728,2.179,2.379a11.545,11.545,0,0,0,3.957.6c4.533,0,6.135-1.6,6.135-2.98A6.11,6.11,0,0,0,34.165,26.211ZM31.635,21a2.329,2.329,0,1,1-2.329,2.329A2.329,2.329,0,0,1,31.635,21Zm0,12.27c-2.73,0-4.633-.776-4.633-1.477a4.633,4.633,0,0,1,9.266,0C36.268,32.5,34.365,33.272,31.635,33.272Z"
                                  transform="translate(6689.117 3394.369)"
                                  fill="#373334"
                                />
                                <path
                                  id="Path_436"
                                  data-name="Path 436"
                                  d="M72.931,19.277H71.9V18.25a.751.751,0,0,0-.751-.751.726.726,0,0,0-.751.751v1.027H69.35a.751.751,0,0,0-.751.751.726.726,0,0,0,.751.751H70.4v1.052a.726.726,0,0,0,.751.751.751.751,0,0,0,.751-.751V20.779h1.027a.726.726,0,0,0,.751-.751A.751.751,0,0,0,72.931,19.277Z"
                                  transform="translate(6656.813 3395.87)"
                                  fill="#373334"
                                />
                              </g>
                            </svg>
                            Invite
                          </a>
                        </div>
                      </div>
                      <PhotoPreview
                        open={previewModal}
                        handleClose={() => setPreviewModal(false)}
                        tenantId={location?.state?.tenantId}
                      />
                    </div>
                    <div>
                      <div className={filterinput}>
                        <SayAskPost
                          isSignIn={isSignIn}
                          isEnable={isEnable}
                          asksToBeAnswered={asksToBeAnswered}
                          answersToYourAsk={answersToYourAsk}
                          childSaysData={childSaysData}
                          clearAsksAndSays={clearAsksAndSays}
                          fetchSaysDataForAsks={fetchSaysDataForAsks}
                          fetchAsksToAnswer={fetchAsksToAnswer}
                          askYourCommunity={askYourCommunity}
                          fetchFeedData={fetchAllFeed}
                          fetchChildSaysData={fetchChildSaysData}
                          postChildSayData={postChildSayData}
                          deleteMessage={deleteMessage}
                          filterPosts={filterPosts}
                          fetchFollowData={handleFollowunfollow}
                          fetchVoteUpStatus={fetchVoteUpStatus}
                          deleteAnswersToAsksDialogPost={deleteAnswersToAsksDialogPost}
                          deleteAsksToBeAnsweredDialogPost={deleteAsksToBeAnsweredDialogPost}
                          onHashTagClick={onHashTagClick}
                        />
                        <div className={roundcontent}>
                          <div className={asksayNav}>
                            <ul>
                              <li
                                onClick={() => handleFilterClick("ask")}
                                className={filterPostType === "ask" ? askHoverImage : askSelectedImage}
                              >
                                <a><span>Ask</span></a>
                              </li>
                              <li
                                onClick={() => handleFilterClick("say")}
                                className={filterPostType === "say" ? sayHoverImage : saySelectedImage}
                              >
                                <a><span>Say</span></a>
                              </li>
                              <li
                                onClick={() => handleFilterClick("update")}
                                className={filterPostType === "update" ? updateHoverImage : updateSelectedImage}
                              >
                                <a><span>Update</span></a>
                              </li>
                              <li
                                onClick={() => handleFilterClick("hashtag")}
                                className={filterPostType === "hashtag" ? tagHoverImage : tagSelectedImage}
                              >
                                <a><span>Tag</span></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          background: "#E5E4E2",
                          border: "1px solid #DEE0E1",
                          borderRadius: "20px 0 0 0",
                          padding: "10px 20px 0 20px",
                          height: "calc(100vh - 90px)",
                          overflowY: "scroll",
                          scrollbarWidth: "thin",
                        }}
                        onScroll={handleScroll}
                        ref={listRef}
                      >
                        <Feed
                          data={feedData?.feed || []}
                          id={saysId}
                          childSaysData={childSaysData}
                          childSayLoading={childSayLoading}
                          feedLoading={feedLoading}
                          filterType={filterPostType}
                          handleFollowunfollow={handleFollowunfollow}
                          handleSayClick={handleSayClick}
                          deleteMessage={deleteMessage}
                          refreshFeedData={refreshFeedData}
                          fetchVoteUpStatus={fetchVoteUpStatus}
                          postChildSayData={postChildSayData}
                          fetchChildSaysData={fetchChildSaysData}
                          flow="afterSignIn"
                          feedPageNumber={feedPageNumber}
                          onHashTagClick={onHashTagClick}
                        />
                      </div>
                    </div>
                  </>
                ) : contentType === "search" ? (
                  <SearchResultBeforeLogin
                    loading={feedLoading}
                    searchData={searchData}
                    handleBack={handleBack}
                    childSaysData={childSaysData}
                    fetchChildSaysData={fetchChildSaysData}
                    postChildSayData={postChildSayData}
                    fetchFollowData={handleFollowunfollow}
                    fetchVoteUpStatus={fetchVoteUpStatus}
                    fetchFeedData={fetchAllFeed}
                    deleteMessage={deleteMessage}
                    refreshFeedData={refreshFeedData}
                    onHashTagClick={onHashTagClick}
                  />
                ) : contentType === "ask" ? (
                  <TrendingAsks
                    loading={feedLoading}
                    childSaysLoading={childSayLoading}
                    postChildSayData={postChildSayData}
                    fetchFeedData={fetchAllFeed}
                    allAsks={allTrendingAsks?.trendingAsks || []}
                    // allAsks={allTrendingAsks}
                    goBack={handleBack}
                    childSaysData={childSaysData}
                    fetchChildSaysData={fetchChildSaysData}
                    deleteMessage={deleteMessage}
                    fetchFollowData={handleFollowunfollow}
                    fetchVoteUpStatus={fetchVoteUpStatus}
                    refreshFeedData={refreshFeedData}
                    showData={contentType}
                    onHashTagClick={onHashTagClick}
                  />
                ) : contentType === "tag" ? (
                  <TrendingTags
                    loading={feedLoading}
                    childSaysLoading={childSayLoading}
                    postChildSayData={postChildSayData}
                    fetchFeedData={fetchAllFeed}
                    allTags={allTrendingTags?.trendingTags || []}
                    allTagData={allTrendingTags}
                    goBack={handleBack}
                    childSaysData={childSaysData}
                    fetchChildSaysData={fetchChildSaysData}
                    deleteMessage={deleteMessage}
                    fetchFollowData={handleFollowunfollow}
                    fetchVoteUpStatus={fetchVoteUpStatus}
                    refreshFeedData={refreshFeedData}
                    showData={contentType}
                    onHashTagClick={onHashTagClick}
                  />
                ) : contentType === "singleTrendingTag" ? (
                  <SingleTrendingTag
                    loading={feedLoading}
                    childSaysLoading={childSayLoading}
                    postChildSayData={postChildSayData}
                    fetchFeedData={fetchAllFeed}
                    allTags={selectedTagDetails?.messageList || []}
                    goBack={handleBack}
                    childSaysData={childSaysData}
                    fetchChildSaysData={fetchChildSaysData}
                    deleteMessage={deleteMessage}
                    fetchFollowData={handleFollowunfollow}
                    fetchVoteUpStatus={fetchVoteUpStatus}
                    refreshFeedData={refreshFeedData}
                    showData={contentType}
                    onHashTagClick={onHashTagClick}
                  />
                ) : null}
              </div>
              <div className={`col-lg-3 ${layoutStyles.rightside}`}>
                <RightSideBar
                  contributorsData={contributors}
                  trendingTags={trendingTags}
                  trendingAsks={trendingAsks}
                  setShowData={setShowData}
                  showModal={modalShow}
                  modalType={modalType}
                  fetchSelectedTagDetails={fetchSelectedTagDetails}
                  onUpdateSelection={onUpdateSelection}
                  hideUpdateModal={onHideUpdateModal}
                  fetchFeedData={fetchAllFeed}
                  filterPosts={filterPosts}
                />
              </div>
            </div>
          </div>

          {hashTag && showSearchedTagModal && (
            <SearchTagModal
              open={showSearchedTagModal}
              onClose={onCloseSearchTagModal}
              hashTag={hashTag}
            />
          )}

          {isEnable && (
            <Grid
              container
              style={{
                padding: "10px",
                background: "#4795DE",
                position: "fixed",
                bottom: 0,
                zIndex: 1,
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#ffffff",
                  fontSize: "16px",
                  fontWeight: "bold",
                  position: "relative",
                }}
              >
                {selectedImage?.type === "private" && (
                  <p>Request to follow and engage in this community</p>
                )}
                {(selectedImage?.type === "Public" ||
                  selectedImage?.type === "public") && (
                    <p>
                      Select Follow or Make Default to engage in this Community
                    </p>
                  )}
                <Box position="absolute" right={0}>
                  <IconButton onClick={() => setIsEnable(false)}>
                    <IoIosCloseCircleOutline color="white" />
                  </IconButton>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "10px",
                }}
              >
                <div
                  style={{ position: "relative", left: "-180px" }}
                  onClick={handleBackArrow}
                >
                  <ArrowCircleLeftIcon style={{ fill: "#ffffff" }} />
                </div>
                <div className={buttonContainer} style={{ display: "flex" }}>
                  {(selectedImage?.type === "Public" ||
                    selectedImage?.type === "public") && (
                      <Button variant="contained" onClick={handleFollow}>
                        Follow
                      </Button>
                    )}
                  {selectedImage?.type === "private" && (
                    <Button variant="contained" onClick={handleFollow}>
                      Request to Follow
                    </Button>
                  )}
                  {(selectedImage?.type === "Public" ||
                    selectedImage?.type === "public") && (
                      <div style={{ padding: "0 20px", color: "#ffffff" }}>
                        or
                      </div>
                    )}
                  {(selectedImage?.type === "Public" ||
                    selectedImage?.type === "public") && (
                      <Button variant="contained" onClick={handleMakeDefault}>
                        Make Default
                      </Button>
                    )}
                </div>
              </Grid>
            </Grid>
          )}

          {hasRestrictionSays && (
            <RestrictionMsgModal
              open={hasRestrictionSays}
              onClose={() => setHasRestrictionSays(false)}
              title="Thank you"
              message="Your SAY will publish as soon as the Community Moderator approves it."
            />
          )}
        </React.Fragment>
      )}
    </Page>
  );
};

export default ViewActivitySignIn;
