// import Header from "../Modules/header/header";
import { Link } from "react-router-dom";
import logo from "src/Assets/images/LandingPageImages/skopic-image.png";
import settingsStyles from "src/Assets/css/settings/settings.module.css";
const LoginUserGuidelines = () => {
  function UserGuideLines() {
    return (
      <div className={settingsStyles.headerContainer}>
        <div className={settingsStyles.headerContainer}>
          <Link to={"/"}>
            <div>
              <img src={logo} alt="Skopic-Logo" width={90} height={48} />
            </div>
          </Link>
        </div>
        <div className="innercontainer">
          <h3 className={settingsStyles.privacyheading}>User Guidelines</h3>
          <div className={settingsStyles.TermsofService}>
            We welcome your questions, comments and concerns. Our goal is to
            maintain a social platform for online local communities, where
            people can
            <br />
            share; knowledge, thoughts and timely information and ask helpful
            information.
            <br />
            Please review, understand and comply with terms of use provided on
            the website and mobile applications
            <br />
            Take active participation to contribute on timely and essential
            matters so your communities remain more engaged and informed. The
            more
            <br />
            knowledge or thoughts you share, the stronger would be your
            community knowledge base. Using Skopic’s Information Networking
            engine, your
            <br />
            peers can almost find instant answers if your community knowledge
            gets broader and deeper.
            <br />
            Do not post casual messages or any unrelated community matters.
            Skopic communities are strictly for sharing and networking with
            local and real
            <br />
            matters, it does not facilitate any form of social networking or
            presenting out of context matters.
            <br />
            We strictly discourage users initiating any continuous conversations
            as Skopic communities are a platform to share or connect with
            microblogs
            <br />
            that are informational in nature.
            <br />
            Please use your clear discretion in classifying as to whether your
            post belongs to; Question Category [ASK], a free expression of
            sharing
            <br />
            Knowledge [SAY], a direct Response to any ASK [SAY], Urgent or
            Important community Update [UPDATE] or a Post for a #TAG SAY.
            <br />
            Please do remember that when you share Updates or Create a new #TAG
            for any activity or topic, Skopic notifies all your community
            members,
            <br />
            so exercise your discretion and caution if you have made a choice
            when doing either kind.
            <br />
            BE RESPECTFUL. We are committed to respect for the dignity of
            others, so do not oppose or directly contradict on other posts or
            contributions,
            <br />
            please only express or share your thoughts or perspectives.
            <br />
            Do not post material that infringes on the rights of any third
            party, including intellectual property, privacy or publicity rights.
            <br />
            Do not post messages that are inappropriate, unlawful, obscene,
            defamatory, threatening or harassing about any individuals, groups,
            <br />
            organizations or beliefs.
            <br />
            Do not Post the same note in a repeated manner (“spam”).
            <br />
            Do not Post any content or material that can potentially lead to
            heated exchanges or debates or arguments with other users of Skopic.
            <br />
            Do not Impersonate another person.
            <br />
            Do not Allow any other person or entity to use your identification
            for posting or viewing.
            <br />
            All your posts are visible to all Skopic users irrespective of any
            community they may belong to, so there is no restriction to view or
            flag your post
            <br />
            by any other user.
            <br />
            We reserve the right to remove posts that do not comply with our
            community guidelines.
            <br />
            Community Moderator Role:
            <br />
            In addition to reviewing Skopic privacy, terms of use and user
            guidelines, please note these additional aspects for leading to
            moderate your
            <br />
            community.
            <br />
            The most important things to adopt to be a successful moderator are
            <br />
            1.Must be a good community actor, embrace the role of a deeply
            passionate community member in the community.
            <br />
            2.Keep researching communities you are moderating, on the internet,
            social media and other traditional media so you are well informed to
            <br />
            moderate. 3.Classify and organize the content most appropriately.
            <br />
            4.Get the best message out in 140 character Microblogs.
            Microblogging off long stories and articles is a powerful skill to
            develop and benefit your
            <br />
            communities. 5. Keep the users’ interest going by keeping these
            communities live and current with positive activity.
            <br />
            6.Remove all the posts that are flagged abusive either instantly or
            periodically upon your discretion at the time.
            <br />
            7.If this is an invite only community, then please approve or reject
            user invitations in a timely manner.
            <br />
            8.Choose responsible and engaged members as additional moderators.
            <br />
            9.Periodically revisit the rules for users to post messages, because
            the very fact Skopic is a community sourced information network is
            defined
            <br />
            by how much your users are allowed or not allowed to post without
            any pre-approval.
            <br />
            Quick directions for driving user engagement and participation.
            <br />
            1.Add Questions and / or Answers (ASKs and SAYs to ASKs) on a
            periodic basis, at least 3 to 4 times a week.
            <br />
            2.Create TAGS for key issues, topics, activities or sub-groups and
            post related information, at least 10 to 15 posts per tag (spread it
            over 3 to 5<br />
            days). 3.Try and create a new TAG in each community once in 5 to 7
            days.
            <br />
            4. Post 1 UPDATE each community every day, the updates must not
            exceed 3 per day as it could be intimidating to users. Do not forget
            to add
            <br />
            community reminders if updates involve deadlines.
            <br />
            5.Post SAYs to Express thoughts or share knowledge. This is
            extremely critical to finding instant answers to the most pressing
            questions either
            <br />
            in the current time or in the future. Try and add at least 50 SAY
            messages for this category.
            <br />
            6.Always, review, reach out and encourage your, top 1o contributors,
            top 25 people in SAY and ASK categories which are listed on your
            <br />
            Community main page.
            <br />
            7.Most importantly remember that UPDATES and #TAGS when created are
            sent out to all the community interest, so these features stickiness
            <br />
            and user engagement.
            <br />
            8.Best of all, you understand your community the best, so your
            discretion may overrule some or all of these stated guidelines.
            <br />
            Thank You.
            <br />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      {/* <Header /> */}
      {<UserGuideLines />}
    </div>
  );
};
export default LoginUserGuidelines;
