import api_sheet from "./api_sheet";
import axios from "axios";
import {CookieConfig} from "../Utils/CookieConfig";

export default class PhotoTimelineRepo {

    static uploadPhotoTimeline = async (
        tempImageId,
        tenantId,
        imgs,
        timelineDescription,
        timelineLocation,
        locationName
    ) => {

        const formData = new FormData();
        formData.append("tmlPhotoDescs", "[" + timelineDescription + "]");
        formData.append("tmlPhotoLoc", "[" + timelineLocation + "]");
        formData.append("locName", "[" + locationName + "]");
        formData.append("imgs", imgs);

        return await axios
            .post(
                api_sheet.UploadPhotoTimeline_URL + "?tenantId=" + tenantId,
                formData,
                CookieConfig
            ).then((res) => {
                console.log("res: " + JSON.stringify(res.data))
                if(res.data.status[0] === "Uploaded"){
                    return {'isSuccess': true, 'message': 'success', 'tempImageId': tempImageId}
                }else{
                    return {'isSuccess': false, 'message': res.data.status[0], 'tempImageId': tempImageId}
                }

            }).catch((error) => {
                console.log("PhotoTimelineRepo>uploadPhotoTimeline> Error: " + error);
                return {'isSuccess': false, 'message': "Server Error"};
            });
    };

    static deletePhotoTimeline = async (id, tenantId) => {
        return await fetch(
            api_sheet.DeletePhotoTimeline_URL +
            "?tenantId=" +
            tenantId +
            "&imageIds=" +
            id,
            {
                method: "DELETE",
            },
            CookieConfig
        )
            .then((res) => res.json())
            .then((data) => {
                console.log("SignUpRepo>requestSignUp> res: " + data);
                switch (data.status) {
                    case "success":
                        return {
                            isSuccess: true,
                            message: "OTP sent Successfully",
                            reSend: false,
                        };
                    case "SkopicUser":
                        return {
                            isSuccess: false,
                            message: "Already have an account",
                            reSend: false,
                        };
                    case "TempUser":
                        return {
                            isSuccess: true,
                            message: "OTP sent Successfully",
                            reSend: true,
                        };
                    default:
                        return {
                            isSuccess: false,
                            message: "Failed, Retry after sometime",
                            reSend: false,
                        };
                }
            })
            .catch((error) => {
                console.log("SignUpRepo>requestSignUp> Error: " + error);
                return {isSuccess: false, message: "Server Error"};
            });
    };

    // switch (data.status) {
    //   case "success":
    //     return {
    //       isSuccess: true,
    //       message: "OTP sent Successfully",
    //       imageId: tempImageId,
    //     };
    //   case "SkopicUser":
    //     return {
    //       isSuccess: false,
    //       message: "Already have an account",
    //       reSend: false,
    //     };
    //   case "TempUser":
    //     return {
    //       isSuccess: true,
    //       message: "OTP sent Successfully",
    //       reSend: true,
    //     };
    //   default:
    //     return {
    //       isSuccess: false,
    //       message: "Failed, Retry after sometime",
    //       reSend: false,
    //     };
    // }


}
