// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchresult_searchresult__FjDBl{
    box-shadow:-2px -3px 6px #FFFFFF, 0px 3px 6px #00000026;
    border-radius: 20px;
    padding: 20px 0 20px 0px;
}

.searchresult_searchresult__FjDBl>div{
box-sizing: border-box;
overflow-y: scroll;
height: 812px;
scrollbar-width: thin;
scrollbar-color:#6b71c9;
width: -moz-available;
}
.searchresult_searchresult__FjDBl::-webkit-scrollbar{
    
width: 4px;
    
}


  .searchresult_searchresult__FjDBl::-webkit-scrollbar-thumb {
    background: #707070; 
    border-radius: 10px;
    
  }
  .searchresult_searchresult__FjDBl::-webkit-scrollbar-thumb:hover {
    background: #6b71c9; 
  }
  .searchresult_loadingspinner__gnThm{
    position: absolute;
    top: 25%;
    left: 50%;
  }
  .searchresult_noofsearchresuts__AZeEd{
    display: flex;
    margin:0;
    align-items: center;
    margin-left: 20px;
  }
  .searchresult_noofsearchresuts__AZeEd>p{
  margin: 2px 0 0 0;
  font-size: 22px;
  color: #373334;
  }
  .searchresult_noofsearchresuts__AZeEd>a{
    margin-right: 13px;
  }`, "",{"version":3,"sources":["webpack://./src/Assets/css/home/searchresult.module.css"],"names":[],"mappings":"AAAA;IACI,uDAAuD;IACvD,mBAAmB;IACnB,wBAAwB;AAC5B;;AAEA;AACA,sBAAsB;AACtB,kBAAkB;AAClB,aAAa;AACb,qBAAqB;AACrB,uBAAuB;AACvB,qBAAqB;AACrB;AACA;;AAEA,UAAU;;AAEV;;;EAGE;IACE,mBAAmB;IACnB,mBAAmB;;EAErB;EACA;IACE,mBAAmB;EACrB;EACA;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;EACX;EACA;IACE,aAAa;IACb,QAAQ;IACR,mBAAmB;IACnB,iBAAiB;EACnB;EACA;EACA,iBAAiB;EACjB,eAAe;EACf,cAAc;EACd;EACA;IACE,kBAAkB;EACpB","sourcesContent":[".searchresult{\n    box-shadow:-2px -3px 6px #FFFFFF, 0px 3px 6px #00000026;\n    border-radius: 20px;\n    padding: 20px 0 20px 0px;\n}\n\n.searchresult>div{\nbox-sizing: border-box;\noverflow-y: scroll;\nheight: 812px;\nscrollbar-width: thin;\nscrollbar-color:#6b71c9;\nwidth: -moz-available;\n}\n.searchresult::-webkit-scrollbar{\n    \nwidth: 4px;\n    \n}\n\n\n  .searchresult::-webkit-scrollbar-thumb {\n    background: #707070; \n    border-radius: 10px;\n    \n  }\n  .searchresult::-webkit-scrollbar-thumb:hover {\n    background: #6b71c9; \n  }\n  .loadingspinner{\n    position: absolute;\n    top: 25%;\n    left: 50%;\n  }\n  .noofsearchresuts{\n    display: flex;\n    margin:0;\n    align-items: center;\n    margin-left: 20px;\n  }\n  .noofsearchresuts>p{\n  margin: 2px 0 0 0;\n  font-size: 22px;\n  color: #373334;\n  }\n  .noofsearchresuts>a{\n    margin-right: 13px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchresult": `searchresult_searchresult__FjDBl`,
	"loadingspinner": `searchresult_loadingspinner__gnThm`,
	"noofsearchresuts": `searchresult_noofsearchresuts__AZeEd`
};
export default ___CSS_LOADER_EXPORT___;
