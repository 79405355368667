import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import "./StartCommunities.css";
import PolygonMap from "../../../Moderator/EditCommunity/Polygon";
import StartMap from "../../../../Components/DefaultCommunity/startMap";
import axios from "axios";
import * as Cookies from "js-cookie";
import { Dialog, Button, DialogTitle, IconButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import CancelIcon from "@mui/icons-material/Cancel";
import CommunityImage from "src/Assets/images/ComImage.svg";
import MapIcon from "src/Assets/images/MapIcon.svg";
import Camera from "src/Assets/images/camera.svg";
import CloseBtn from "src/Assets/images/CloseButton.svg";
import API from "../../../../network";
import { compressFile } from "src/Utils/imageCompressionUtil";
import { emailRegex } from "src/Constants/regex";

const useStyles = makeStyles({
  paperWidthSm: {
    padding: "20px 15px 15px 15px",
    borderRadius: "25px",
    width: "800px",
    maxWidth: "800px",
    height: "500px",
    overflow: "hidden",
    maxWidth: "800px !important",
  },
});

function StartCommunities(props) {
  const { allCommunities } = props;
  const classes = useStyles();
  const [communityData, setcommunityData] = useState({});
  const [communities, setCommunities] = useState([]);
  const [error, setError] = useState(null);
  const [communityAlreadyExist, setCommunityAlreadyExist] = useState(false);

  // Function to fetch nearby communities
  const fetchNearbyCommunities = async () => {
    try {
      const response = await API.getNearByUserCommunities();
      setCommunities(response.data.physicalTenantList);
    } catch (err) {
      console.error("Failed to fetch communities:", err);
      setError("Unable to load communities");
    }
  };

  useEffect(() => {
    fetchNearbyCommunities();
  }, []);

  useEffect(() => {
    API.getCommunity().then((res) => {
      setcommunityData(res.data);
    });
  }, []);

  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [closeW, setCloseW] = useState(false);
  const [nameErrorMsg, setNameErrorMsg] = useState(false);
  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState(false);
  const [countryErrorMsg, setCountryErrorMsg] = useState(false);
  const [addressErrorMsg, setAddressErrorMsg] = useState(false);
  const [cityErrorMsg, setCityErrorMsg] = useState(false);
  const [stateErrorMsg, setStateErrorMsg] = useState(false);
  const [zipCodeErrorMsg, setZipCodeErrorMsg] = useState(false);
  const [submitErrMsg, setSubmitErrMsg] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tenantAddress, setTenantAddress] = useState("");
  const [tenantCity, setTenantCity] = useState("");
  const [tenantZipcode, setTenantZipcode] = useState("");
  const [tenantCountry, setTenantCountry] = useState("");
  const [tenantState, setTenantState] = useState("");
  const [tenantLat, setTenantLat] = useState("");
  const [tenantLng, setTenantLng] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [statesList, setStateList] = useState([]);
  const [communityType, setCommunityType] = useState("public");
  const [communityView, setCommunityView] = useState("view");
  const [emailID, setEmailID] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phonenum, setPhonenum] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  // const [logo, setLogo] = useState("");
  const [userGuidelineChecked, setUserGuidelineChecked] = useState(false);
  const [modGuidelineChecked, setModGuidelineChecked] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [responseData, setResponseData] = useState({});
  const [file, setFile] = useState();

  // const userDetails = useSelector((state) => state.SignInReducer.userDetails);

  useEffect(() => {
    setEmailID(communityData?.user?.email);
    setFirstname(communityData?.user?.firstName);
    setLastname(communityData?.user?.lastName);
    setPhonenum(communityData?.user?.phone);
    setPhonenum(communityData?.user?.phone);
    setAddress(communityData?.user?.address);
    setCity(communityData?.user?.address2);
    setZipcode(communityData?.user?.zipcode);
    setCountry(communityData?.country);
    setState(communityData?.state);
  }, [communityData?.user, communityData]);

  //   useEffect(() => {
  //     axios
  //       .request({
  //           url: `https://dev.skopic.com/skopicportal/jsonuser/tenant-map?mapStatus=IndexNear&requestCommunity=requestCommunity`,
  //         // url: `https://dev.skopic.com/skopicportal/jsonuser/tenant-list`,
  //         method: "Get",
  //         headers: {
  //           "Access-Control-Allow-Origin": "*",
  //           "Set-Cookie": Cookies.get("JSESSIONID"),
  //         },
  //         withCredentials: true,
  //       })
  //       .then((res) => {
  //         const obj = { country_code: "", latitude: "", country_name: "Select" };
  //         const data = res.data.countryList;
  //         data.unshift(obj);
  //         setCountryList(data);
  //       });
  //   }, []);
  //   example of state code written here

  useEffect(() => {
    API.getTenantList().then((res) => {
      if (res) {
        let countryArray = [];
        if (res && Object.keys(res.data.statesList).length !== 0) {
          setResponseData(res.data.statesList);
          Object.keys(res.data.statesList).map((country) => {
            countryArray.push(country);
          });
          setCountryList(countryArray);
        }
      }
    });
  }, []);

  useEffect(() => {
    const trimmedName = name.trim();

    const communityList = communities ?? allCommunities; // Use communities or fallback to allCommunities

    const exists = communityList?.some(
      (item) => item.trim().toLowerCase() === trimmedName.toLowerCase()
    );

    setCommunityAlreadyExist(exists);
  }, [communities, allCommunities, name]);

  const updateStateList = (evt) => {
    setTenantCountry(evt.target.value);
    setCountry(evt.target.value);
    setCountryErrorMsg(false);
    if (Object.keys(responseData).includes(evt.target.value)) {
      setStateList(responseData[evt.target.value]);
    }
    // setStateList(responseData[val])
  };
  const updateCommunityStateList = (evt) => {
    setTenantCountry(evt.target.value);
    setCountryErrorMsg(false);
    if (Object.keys(responseData).includes(evt.target.value)) {
      setStateList(responseData[evt.target.value]);
    }
    // setStateList(responseData[val])
  };

  //   example of state code written ends here

  const getLatLng = (data) => {
    if (data && Object.keys(data).length > 0) {
      setTenantLat(data.lat.toString());
      setTenantLng(data.lng.toString());
    }
  };

  const OpenModalHandler = () => {
    if (communityAlreadyExist) {
      alert(
        "This community already exists. Please Enter a different community name."
      );
      return;
    }
    // Checking if all required fields are filled
    if (
      name !== "" &&
      description !== "" &&
      tenantCountry !== "" &&
      tenantAddress !== "" &&
      tenantCity !== "" &&
      tenantState !== "" &&
      tenantZipcode !== ""
    ) {
      // If all fields are filled, setting open state to true
      setOpen(true);
      setCloseW(true);
    } else {
      // If any required field is empty, displaying error messages
      if (name === "") {
        setNameErrorMsg(true);
      }
      if (description === "") {
        setDescriptionErrorMsg(true);
      }
      if (tenantCountry === "") {
        setCountryErrorMsg(true);
      }
      if (tenantAddress === "") {
        setAddressErrorMsg(true);
      }
      if (tenantCity === "") {
        setCityErrorMsg(true);
      }
      if (tenantState === "") {
        setStateErrorMsg(true);
      }
      if (tenantZipcode === "") {
        setZipCodeErrorMsg(true);
      }
    }
  };

  const mapCommunity = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  let className = "Second";
  if (open) {
    className = "Second--active";
  }

  let block = "StartCommunity-Container";
  if (closeW) {
    block = "StartCommunity-ContainerNone";
  }

  const handleChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const compressedFile = await compressFile(file); //compress file
      const photoUrl = URL.createObjectURL(compressedFile);
      setFile(photoUrl);
    }
  };

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   const selectedFile = e.target.files[0];
  //   const photoUrl = URL.createObjectURL(e.target.files[0])
  //   setFile(photoUrl); // Update file state with the selected file
  // };

  const startCommunity = async () => {
    if (
      emailID &&
      firstname !== "" &&
      lastname !== "" &&
      phonenum !== "" &&
      address !== "" &&
      city !== "" &&
      zipcode !== "" &&
      state !== "" &&
      userGuidelineChecked === true &&
      modGuidelineChecked === true
    ) {
      let data = new FormData();
      data.append("modirate", "Y");
      data.append("communityView", communityView);
      data.append("name", name);
      data.append("description", description);
      data.append("tenantAddress", tenantAddress);
      data.append("tenantCity", tenantCity);
      data.append("tenantZipcode", tenantZipcode);
      data.append("communityType", communityType);
      data.append("tenantLatitude", tenantLat);
      data.append("tenantLangitude", tenantLng);
      data.append("tenantCountry", tenantCountry);
      data.append("tenantState", tenantState);
      data.append("emailID", emailID);
      data.append("firstname", firstname);
      data.append("lastname", lastname);
      data.append("phonenum", phonenum);
      data.append("city", city);
      data.append("address", address);
      data.append("zipcode", zipcode);
      data.append("state", state);
      data.append("logo", file);

      const res = await API.createNewCommunity(data);
      if (res.data.status === "Success") {
        setSubmitStatus(true);
        setSuccessMsg(res.data.message);
      }
    } else {
      setSubmitErrMsg(true);
    }
  };

  return (
    <>
      {communityData && Object.keys(communityData).length !== 0 ? (
        <>
          <div className="StartCommunityFirstPage">
            <div className={block}>
              <div className="StartCommunity-Header">
                <p className="StartCommunity-Header--Title">Start Community</p>
                <button
                  className="StartCommunity-Header--Close"
                  onClick={props.close}
                >
                  <img src={CloseBtn} alt="Close-Button" />
                </button>
              </div>
              <div className="StartCommunity-Body">
                <div className="StartCommunity-Info">
                  <span className="indicatorImportant">*</span>&nbsp;Indicates
                  required
                </div>
                <div className="StartCommunity-Body--Intialinfo">
                  <div className="StartCommunity-Body--CommunityImage">
                    <p>Community Picture:</p>
                    <div className="StartCommunity-CommunityImage">
                      <img
                        src={file !== undefined ? file : CommunityImage}
                        alt="Community-Image"
                      />
                      <label className="Camera-Icon">
                        <input
                          type="file"
                          name="file"
                          onChange={handleChange}
                        />
                        <img src={Camera} alt="Camera" />
                      </label>
                      {/* <button className="Camera-Icon">
                        <img src={Camera} alt="Camera" />
                      </button> */}
                    </div>
                  </div>
                  <div className="StartCommunity-Body--CommunityType">
                    <p>
                      Community Type:{" "}
                      <span className="indicatorImportant">*</span>
                    </p>
                    <div className="StartCommunity-CommunityOptions">
                      <div>
                        <input
                          type="radio"
                          name="communityType"
                          value={communityType}
                          checked={communityType === "public"}
                          onChange={() => setCommunityType("public")}
                        />
                        <p>
                          Public <br />
                          <span>Community accessible to all members</span>
                        </p>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="communityType"
                          value={communityType}
                          onChange={() => {
                            setCommunityType("private");
                            setCommunityView("view");
                          }}
                        />
                        <p>
                          Private <br />
                          <span>
                            Members join Community by invitation or by request
                          </span>
                        </p>
                      </div>
                    </div>
                    {communityType === "private" ? (
                      <div className="StartCommunity-PrivateCommunityOptions">
                        <div>
                          <input
                            type="radio"
                            name="communityView"
                            value={communityView}
                            checked={communityView === "view"}
                            onChange={() => setCommunityView("view")}
                          />
                          <p>
                            View Only <br />
                            <span>
                              Non members can view all activity and content
                            </span>
                          </p>
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="communityView"
                            value={communityView}
                            onChange={() => setCommunityView("hide")}
                          />
                          <p>
                            Hide <br />
                            <span>Community hidden from all non members</span>
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="StartCommunity-Body--CommunityForm">
                  <div className="EditInputfeild">
                    <label className="EditName">
                      Community Name:
                      <span className="indicatorImportant">*</span>
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        // Clear the error message when the user starts typing
                        setNameErrorMsg(false);
                      }}
                      maxLength={50}
                    />
                    {nameErrorMsg ? (
                      <p id="name" className="StartCommunityErrorMsg">
                        Please Enter Name
                      </p>
                    ) : null}
                    {(communities?.length > 0 || allCommunities?.length > 0) &&
                      name.trim() !== "" ? (
                      communityAlreadyExist ? (
                        <p style={{ color: "red", fontSize: "15px" }}>
                          This community Already Exists
                        </p>
                      ) : null
                    ) : null}
                  </div>

                  <label className="EditNameDesc">
                    Description:<span className="indicatorImportant">*</span>
                  </label>
                  <textarea
                    className="EditTextfeild"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      setDescriptionErrorMsg(false);
                    }}
                    maxLength={280}
                  ></textarea>
                  {descriptionErrorMsg ? (
                    <p id="" className="StartCommunityErrorMsg">
                      Please Enter Description
                    </p>
                  ) : null}

                  <div className="EditInputfeild">
                    <label className="EditName">
                      Country:<span className="indicatorImportant">*</span>
                    </label>
                    <select
                      value={tenantCountry}
                      className="EditSelectFeild"
                      onChange={updateCommunityStateList}
                    >
                      <>
                        <option></option>
                        {countryList.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </>
                    </select>
                    {countryErrorMsg ? (
                      <p id="name" className="StartCommunityErrorMsg">
                        Please Enter Country
                      </p>
                    ) : null}
                  </div>
                  <div className="address-city-input-main-container">
                    <div className="EditInputSubfeild">
                      <label className="EditName">
                        Address:<span className="indicatorImportant">*</span>
                      </label>
                      <input
                        type="text"
                        value={tenantAddress}
                        onChange={(e) => {
                          setTenantAddress(e.target.value);
                          setAddressErrorMsg(false);
                        }}
                        maxLength={50}
                      />
                      {addressErrorMsg ? (
                        <p id="name" className="StartCommunityErrorMsg">
                          Please Enter Address
                        </p>
                      ) : null}
                    </div>

                    <div className="EditInputSubfeild">
                      <label className="EditName">
                        City:<span className="indicatorImportant">*</span>
                      </label>
                      <input
                        type="text"
                        value={tenantCity}
                        onChange={(e) => {
                          setTenantCity(e.target.value);
                          setCityErrorMsg(false);
                        }}
                        maxLength={50}
                      />
                      {cityErrorMsg ? (
                        <p id="name" className="StartCommunityErrorMsg">
                          Please Enter City
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="address-city-input-main-container">
                    <div className="EditInputSubfeild">
                      <label className="EditName">
                        State:<span className="indicatorImportant">*</span>
                      </label>
                      <select
                        value={tenantState}
                        className="EditSelectFeild"
                        onChange={(e) => {
                          setTenantState(e.target.value);
                          setStateErrorMsg(false);
                        }}
                      >
                        <>
                          <option value="" disabled></option>
                          {statesList &&
                            statesList.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                        </>
                      </select>
                      {stateErrorMsg ? (
                        <p id="name" className="StartCommunityErrorMsg">
                          Please Enter State
                        </p>
                      ) : null}
                    </div>

                    <div className="EditInputSubfeild">
                      <label className="EditName">
                        Zip Code:<span className="indicatorImportant">*</span>
                      </label>
                      <input
                        type="text"
                        value={tenantZipcode}
                        onChange={(e) => {
                          setTenantZipcode(e.target.value);
                          setZipCodeErrorMsg(false);
                        }}
                        maxLength={10}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {zipCodeErrorMsg ? (
                        <p id="name" className="StartCommunityErrorMsg">
                          Please Enter Zip Code
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="StartCommunity-Footer">
                <div style={{ marginLeft: "20px" }}>
                  <Button
                    variant="text"
                    onClick={mapCommunity}
                    sx={{ textTransform: "none" }}
                  >
                    <img src={MapIcon} alt="Map-Icon" />
                    &nbsp; Map Community
                  </Button>
                </div>
                <div className="SubmitPageContainer">
                  <button
                    onClick={OpenModalHandler}
                    className={
                      name !== "" &&
                        description !== "" &&
                        tenantCountry !== "" &&
                        tenantAddress !== "" &&
                        tenantCity !== "" &&
                        tenantState !== "" &&
                        tenantZipcode !== ""
                        ? "afterSubmitFirstPage"
                        : "beforeSubmitFirstPage"
                    }
                  >
                    Submit
                  </button>
                  <span>1 of 2</span>
                </div>
                {isOpen ? (
                  <Dialog
                    open={isOpen}
                    onClose={handleClose}
                    classes={{ paperWidthSm: classes.paperWidthSm }}
                  >
                    <DialogTitle
                      disableTypography
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      sx={{ padding: 0 }}
                    >
                      <h5>Map Community</h5>
                      <IconButton onClick={handleClose}>
                        <CancelIcon />
                      </IconButton>
                    </DialogTitle>
                    <StartMap onMapClose={handleClose} getLatLng={getLatLng} />
                  </Dialog>
                ) : null}
              </div>
            </div>
          </div>
          <div className={className}>
            <div className="StartCommunity">
              <div className="StartCommunity-Container">
                <div className="StartCommunity-Header">
                  <p className="StartCommunity-Header--Title">
                    Start Community
                  </p>
                  <button
                    className="StartCommunity-Header--Close"
                    onClick={props.close}
                  >
                    <img src={CloseBtn} alt="Close-Button" />
                  </button>
                </div>
                <div className="StartCommunity-Body">
                  <div className="StartCommunity-Info">
                    <span className="indicatorImportant">*</span>&nbsp;Indicates
                    required
                  </div>
                  {props?.signIn ? (
                    <div className="StartCommunity-Body--Intialinfo">
                      <p>
                        Display Name:<span className="indicatorImportant">*</span>{" "}
                        <span style={{ color: "grey" }}>
                          {`${firstname || ""} ${lastname || ""}`}
                        </span>
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="StartCommunity-Body--PersonalForm">
                  <div className="EditInputfeild">
                    <label className="EditName">
                      Email:<span className="indicatorImportant">*</span>
                    </label>
                    <input
                      type="text"
                      value={emailID}
                      onChange={(e) => setEmailID(e.target.value)}
                      disabled={props?.signIn}
                      readOnly={props?.signIn}
                    />
                  </div>
                  <div className="EditInputfeild">
                    <label className="EditName">
                      First Name:<span className="indicatorImportant">*</span>
                    </label>
                    <input
                      type="text"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      maxLength={50}
                    />
                  </div>

                  <div className="EditInputfeild">
                    <label className="EditName">
                      Last Name:<span className="indicatorImportant">*</span>
                    </label>
                    <input
                      type="text"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      maxLength={50}
                    />
                  </div>
                  <div className="EditInputfeild">
                    <label className="EditName">
                      Phone :<span className="indicatorImportant">*</span>{" "}
                    </label>
                    <div className="phone-number-container">
                      <select className="EditSubSelectFeild">
                        <option value="1" className="EditSubSelectFeildOption">
                          +1 (USA)
                        </option>
                        <option value="2" className="EditSubSelectFeildOption">
                          +91
                        </option>
                      </select>
                      <input
                        type="text"
                        className="PhoneTextFeild"
                        value={phonenum}
                        onChange={(e) => setPhonenum(e.target.value)}
                        maxLength={10}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="EditInputfeild">
                    <label className="EditName">
                      Country:<span className="indicatorImportant">*</span>
                    </label>
                    <select
                      className="EditSelectFeild"
                      onChange={updateStateList}
                      value={country}
                    >
                      <>
                        <option></option>
                        {countryList.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </>
                    </select>
                  </div>
                  <div className="address-city-input-main-container">
                    <div className="EditInputSubfeild">
                      <label className="EditName">
                        Address:<span className="indicatorImportant">*</span>
                      </label>
                      <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        maxLength={50}
                      />
                    </div>

                    <div className="EditInputSubfeild">
                      <label className="EditName">
                        City:<span className="indicatorImportant">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        maxLength={50}
                      />
                    </div>
                  </div>
                  <div className="address-city-input-main-container">
                    <div className="EditInputSubfeild">
                      <label className="EditName">
                        State:<span className="indicatorImportant">*</span>
                      </label>
                      <select
                        value={state}
                        className="EditSelectFeild"
                        onChange={(e) => setState(e.target.value)}
                      >
                        <>
                          <option>{state}</option>
                          {statesList &&
                            statesList.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                        </>
                      </select>
                    </div>

                    <div className="EditInputSubfeild">
                      <label className="EditName">
                        Zip Code:<span className="indicatorImportant">*</span>
                      </label>
                      <input
                        type="text"
                        value={zipcode}
                        onChange={(e) => setZipcode(e.target.value)}
                        maxLength={10}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="StartCommunity-Footer">
                  <div className="CommunityGuidlines">
                    <div className="community-guidLine-checkbox-container">
                      <input
                        type="checkbox"
                        checked={userGuidelineChecked}
                        onChange={(e) =>
                          setUserGuidelineChecked(e.target.checked)
                        }
                      />
                      <p>
                        By starting a community, you are agreeing to Skopic
                        etiquette and{" "}
                        <span>
                          <Link to={"/LoginUserGuidelines"} target="_blank">
                            User Guidelines
                          </Link>
                        </span>
                        <span className="indicatorImportant">*</span>
                      </p>
                    </div>
                    <div className="community-guidLine-checkbox-container">
                      <input
                        type="checkbox"
                        checked={modGuidelineChecked}
                        onChange={(e) =>
                          setModGuidelineChecked(e.target.checked)
                        }
                      />
                      <p>
                        By accepting to be a Primary Moderator, you are agreeing
                        to{" "}
                        <span>
                          <Link to={"/ModeratorGuidelines"} target="_blank">
                            Moderator Guidelines
                          </Link>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="SubmitPageContainer">
                    <button
                      onClick={() => {
                        startCommunity(file);
                      }}
                      className={
                        emailRegex.test(emailID) &&
                          firstname !== "" &&
                          lastname !== "" &&
                          phonenum !== "" &&
                          address !== "" &&
                          city !== "" &&
                          zipcode !== "" &&
                          state !== "" &&
                          country !== "" &&
                          userGuidelineChecked === true &&
                          modGuidelineChecked === true
                          ? "afterSubmit"
                          : "beforeSubmit"
                      }
                    >
                      Submit
                    </button>
                    <span>2 of 2</span>
                  </div>
                  {submitErrMsg ? (
                    <p id="name" className="StartCommunityErrorMsg">
                      Please Enter All Details and accept User and Moderator
                      Guidelines to submit the new Community*
                    </p>
                  ) : null}
                  <Popup
                    open={submitStatus}
                    modal
                    contentStyle={{
                      borderRadius: "15px",
                      padding: "0px 10px",
                      /* Add any other styles you want to apply */
                    }}
                  >
                    <div className="success-popup">
                      <p>{successMsg}</p>
                      <button
                        className="StartCommunity-Header--Close"
                        onClick={() => {
                          setSubmitStatus(false);
                          props.close();
                        }}
                      >
                        <img src={CloseBtn} alt="Close-Button" />
                      </button>
                    </div>
                  </Popup>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
}
export default StartCommunities;
