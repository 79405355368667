import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Divider,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Button as MuiButton,
} from "@mui/material";
import ArrowBackIosIcon from "src/Assets/images/Backword Icon.png";
import ArrowForwardIosIcon from "src/Assets/images/ForwordIcon.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LocationOnIcon from "src/Assets/images/locationpin.png";
import CloseIcon from "src/Assets/images/CloseButton.svg";
import { styled } from "@mui/material/styles";
import { CenteredElements } from "../Components/StyledComponents";
import Crown from "src/Assets/images/crown.svg";
import Send from "src/Assets/images/sendemail.svg";
import Homepagesidebar from "../Modules/home/Homepagesidebar";
import API from "../network";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";

const Description = styled(Typography)({
  padding: "8px 15px 8px 15px",
  fontSize: "12px",
  wordBreak: "break-word",
});

const SubHeader = styled(Typography)({
  fontSize: "12px",
  marginBottom: "6px",
  color: "#707070",
  marginLeft: "30px"
});

const Name = styled(Typography)({
  fontSize: "12px",
});

const Container = styled("div")({
  position: "relative",
  minHeight: "220px",
  // display: "flex",
  // flexDirection: "column", // Change the layout to column
});

const TileSubHeader = styled(Typography)({
  fontSize: "11px",
  marginBottom: "6px",
  color: "#373334",
  marginLeft: "30px"
});

const goForward = {
  position: "absolute",
  top: "50%",
  right: "8px",
  transform: "translateY(-50%)",
  backgroundColor: "transparent",
  fontSize: "10px",
  outline: "none !important",
  border: "none !important",
  fontWeight: "1000",
};

const goBack = {
  position: "absolute",
  top: "50%",
  left: "8px",
  transform: "translateY(-50%)",
  backgroundColor: "transparent",
  fontSize: "10px",
  outline: "none !important",
  border: "none !important",
  fontWeight: "1000",
};

const closeIcon = {
  backgroundColor: "#F3F2F0 !important",
  fontSize: "10px",
  marginLeft: "10px",
  height: "28px",
  width: "28px",
  marginTop: "-10px !important",
  outline: "none !important",
};

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "50%",
//   height: "88%",
//   backgroundColor: "#ffffff",
//   borderRadius: "16px",
//   boxShadow: 24,
// };

const memberData = [
  {
    id: 1,
    image: "/images/1637236554131dev1832300035index.jpg",
    ask: 329,
    say: 344,
    update: 32,
    tag: 12,
    name: "Bruce Williams",
  },
];

const moderatorData = [
  {
    id: 1,
    image: "/images/1637236554131dev1832300035index.jpg",
    ask: 329,
    say: 344,
    update: 32,
    tag: 12,
    name: "Hanna Bartman",
  },
  {
    id: 2,
    image: "/images/1637236554131dev1832300035index.jpg",
    ask: 329,
    say: 344,
    update: 32,
    tag: 12,
    name: "Hanna Bartman",
  },
];

const PhotoPreview = (props) => {
  const { open, handleClose, tenantId, communityImage, communityDescription } =
    props;
  const [currIndex, setCurrIndex] = useState(0);
  const [isFirstClick, setIsFirstClick] = useState(true); // Track if it's the first click
  const [photoTimeLineInitialData, setPhotoTimeLineData] = useState([]);
  const [moderatorData, setmoderatorData] = useState("");
  const [locationName, setLocationName] = useState("");
  const [thumbnailSelected, setThumbnailSelected] = useState(false);

  useEffect(() => {
    setCurrIndex(0);
    setThumbnailSelected(false);
    API.getTimeLineImages(`tenantId=${tenantId}`)
      .then((res) => {
        setPhotoTimeLineData(res.data.imagesDetails);
      })
      .catch((err) => { });
  }, [tenantId]);

  const photoTimeLineData = photoTimeLineInitialData || [];
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const handleForwardClick = () => {
    if (isFirstClick && currIndex === 0) {
      // First click: reset to index 0
      setCurrIndex(0);
      setLocationName(photoTimeLineData[0]?.locName); // Set location for index 0
      setThumbnailSelected(true);
      setIsFirstClick(false); // Set to false after the first click
    } else {
      // Subsequent clicks: increment the index by 1
      const newIndex = currIndex + 1;
      if (newIndex < photoTimeLineData.length) {
        setCurrIndex(newIndex);
        setLocationName(photoTimeLineData[newIndex]?.locName); // Update location
        setThumbnailSelected(true);
      }
    }
  };

  const handleLocationClick = (locName) => {
    setLocationName(locName);
    // console.log(locName);
  };
  const imageServerURL = process.env.REACT_APP_SKOPIC_IMAGE_OTHER;
  return (
    <div>
      <Modal tenantId={tenantId} open={open} onClose={handleClose}>
        {photoTimeLineData.length ? (
          <Box className="photoPreviewContainer">
            <Container>
              <div className="location-close-button-container">
                {locationName.length > 0 && thumbnailSelected === true ? (
                  <>
                    <button variant="outlined" className="location-button">
                      <div className="location-content">
                        <img
                          src={LocationOnIcon}
                          alt="location-icon"
                          className="location-icon"
                        />
                        <p className="location-text">{locationName}</p>
                      </div>
                    </button>

                    <span></span>
                  </>
                ) : (
                  <span></span>
                )}
                <IconButton
                  sx={closeIcon}
                  aria-label="close"
                  size="large"
                  onClick={() => {
                    handleClose();
                    setThumbnailSelected(false);
                  }}
                >
                  <img src={CloseIcon} alt="crossIcon" className="cross-icon" />
                </IconButton>
              </div>
              <img
                src={
                  thumbnailSelected
                    ? `${imageServerURL}${photoTimeLineData[currIndex].timelineLogo}`
                    : `${imageServerURL}${communityImage}`
                }
                alt="CommunityImage"
                width="100%"
                height="220px"
                style={{
                  borderRadius: "20px",
                  marginBottom: "10px",
                  objectFit: "cover",
                }}
              />
              <div className="back-forward-container">
                <IconButton
                  sx={goBack}
                  aria-label="back"
                  size="small"
                  disabled={currIndex === 0}
                  onClick={() => {
                    const newIndex = currIndex - 1;
                    setCurrIndex(newIndex);
                    setLocationName(photoTimeLineData[newIndex].locName);
                    setThumbnailSelected(true);
                  }}
                  className="icon-button"
                >
                  <img
                    src={ArrowBackIosIcon}
                    alt="backArrow"
                    className="icon-arrow"
                  />
                </IconButton>

                <IconButton
                  sx={goForward}
                  aria-label="forward"
                  size="small"
                  disabled={currIndex === photoTimeLineData.length - 1}
                  onClick={handleForwardClick}
                  className="icon-button"
                >
                  <img
                    src={ArrowForwardIosIcon}
                    alt="forwardArrow"
                    className="icon-arrow"
                  />
                </IconButton>
              </div>
            </Container>
            <Description>
              {thumbnailSelected ? (
                photoTimeLineData[currIndex].tmlPhotoDesc ? (
                  <p className="community-description-text">{photoTimeLineData[currIndex].tmlPhotoDesc}</p>
                ) : (
                  <p style={{ color: "transparent", margin: "0px" }} className="community-description-text">
                    {communityDescription}
                  </p>
                )
              ) : (
                <p className="community-description-text">{communityDescription}</p>
              )}
            </Description>

            <Divider style={{ margin: "15px" }} />
            <div
              style={{
                padding: "8px 15px 8px 15px",
              }}
            >
              <div className="slider-container">
                <Slider {...settings} className="slider">
                  {photoTimeLineData.map((item, i) => {
                    return (
                      <div
                        key={`key-imageId-${item.imageId}`}
                        className="photoContainer"
                        onClick={() => {
                          setCurrIndex(i);
                          setLocationName(item.locName);
                          setThumbnailSelected(true);
                        }}
                      >
                        <img
                          src={`${imageServerURL}${item.timelineLogo}`}
                          alt={moderatorData.locName}
                          width="82px"
                          height="45px"
                          className={`slider-image ${currIndex === i && thumbnailSelected === true
                            ? "selected-image"
                            : ""
                            }`}
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>
              <SubHeader>Community Blog</SubHeader>
              <TileSubHeader>This is for community blog</TileSubHeader>
            </div>
          </Box>
        ) : (
          <Box className="photoPreviewContainer">
            <Container>
              <img
                src={`${imageServerURL}${communityImage}`}
                alt="Community Image"
                width="100%"
                height="220px"
                style={{ borderRadius: "16px" }}
              />
              <div className="location-close-button-container">
                <span></span>
                <IconButton
                  sx={closeIcon}
                  aria-label="close"
                  size="small"
                  onClick={handleClose}
                >
                  <img
                    src={CloseIcon}
                    alt="crossIcon"
                    style={{ height: "15px" }}
                  />
                </IconButton>
              </div>
            </Container>
            <Description>{communityDescription}</Description>
            <Divider style={{ marginLeft: "15px", marginRight: "15px" }} />
            <div
              style={{
                padding: "8px 15px 8px 15px",
                overflow: "auto",
                height: "230px",
              }}
            >
              <SubHeader>Community Blog</SubHeader>
              <TileSubHeader>This is for community blog</TileSubHeader>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
};

const Tile = (props) => {
  const { data } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!Array.isArray(data)) {
    return <div>No moderator data available.</div>;
  }
  const imageServerURL = process.env.REACT_APP_SKOPIC_IMAGE_OTHER;
  return (
    <>
      {data.map((item) => (
        <div style={{ display: "flex" }} key={item.id}>
          <div style={{ position: "relative", marginRight: "10px" }}>
            <Avatar src={`${imageServerURL}${item.image}`} />
            <img
              src={Crown}
              alt="Crown"
              style={{ position: "absolute", bottom: "0px", right: "0px" }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Name>{item.name}</Name>
            <TileSubHeader>
              ASK {item.ask} | SAY {item.say} | UPDATE {item.update} | TAG{" "}
              {item.tag}
            </TileSubHeader>
          </div>
          <IconButton onClick={handleClick} size="small">
            <KeyboardArrowDownIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => { }}>
              <img src={Send} alt="send" style={{ marginRight: "6px" }} /> Send
              email to {item.name}
            </MenuItem>
          </Menu>
        </div>
      ))}
    </>
  );
};

export default PhotoPreview;
