import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as moderatorActions from 'src/store/actions/Moderator/moderatoractions'
import Moderator from '../ManageMembers/ManagememModerator';
import Invitemembers from './Invitemembers';
import Members from './Members';

const ManageMembers = ({ subMenuItem, tenantId, tenantName }) => {
    // const userDetails = useSelector((state) => state.SignInReducer.userDetails)
    const dispatch = useDispatch();

    useEffect(() => {
        if (tenantId) {
            dispatch(moderatorActions.fetchManageMembersData(tenantId))
        }
    }, [dispatch, tenantId]);

    const Component = subMenuItem === "Invite Members"
        ? Invitemembers
        : subMenuItem === "Moderators"
            ? Moderator
            : Members;

    return (
        <div>
            <Component tenantId={tenantId} tenantName={tenantName} />
        </div>
    );
};

export default ManageMembers;
