import * as allActions from "../../actions/actions.constants";

const initialState = {
  isLoaded: false,
  PrivateTagsData: [],
};

const PrivateTagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case allActions.FETCH_PRIVATE_TAG_DATA:
      return { ...initialState };
    case allActions.RECIEVE_PRIVATE_TAG_DATA:
      if (action.payload && action.payload.privateTagList) {
        return {
          ...state,
          PrivateTagsData: [...action.payload.privateTagList],
          isLoaded: true,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default PrivateTagsReducer;
