import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import IvitionsPending from "src/Assets/images/ReciveInvitation.svg";
import DownArrow from "src/Assets/images/UpButton.png";
import "./Invites.css";
import { useSelector } from "react-redux";
import ReInvite from "../../ReInviteModal";
import Popup from "reactjs-popup";
import { IoMdClose } from "react-icons/io";
import NoDataFound from "src/Assets/images/data-not-found.svg";
import API from "../../../../network";

function Invites() {
  const ActivityInfo = useSelector(
    (state) => state.ActivityInfoReducer.activityInfo
  );
  const url = process.env.REACT_APP_SERVICES_API;
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);

  const [isListOpen, setisListOpen] = useState(false);
  const [invtesSentData, setInvitesSentData] = useState({
    invitationsSize: 0, // Set initial invitationsSize to 0
  });
  const [listData, setListData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [activeInvite, setActiveInvite] = useState(null);
  const [isSentInvitesOpen, setSentInvitesOpen] = useState(true);
  const [resMsg, setResMsg] = useState("");
  const [reInvitePopup, setReInvitePopup] = useState(false);

  useEffect(() => {
    // Fetch initial data when the component mounts
    const count = 0;
    API.getUserInvitations(count).then((res) => {
      setListData(res.data.invitations);
      setInvitesSentData(res.data);
    });
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const toggleList = () => {
    setisListOpen(!isListOpen);
    setSentInvitesOpen(false);
  };
  const toggeInviteSentOpen = () => {
    setisListOpen(!isListOpen);
    setSentInvitesOpen(true);
  };
  const viewInvite = (inviteId) => {
    setActiveInvite(inviteId);
    setShowInvite(true);
  };
  const reSendInvite = (inviteId) => {
    setOpenPopup(true);
    setActiveInvite(inviteId);
  };

  const hideInvite = () => {
    setShowInvite(false);
  };

  // Note:-  onReInvite function is not correct and not sending reinvite. should be fixed later
  const onReInvite = (email, subject, communityName, reinviteId) => {
    setOpenPopup(false);

    const newMessage = `Hi,%0A%0AI request you to join ${communityName} on Skopic. It is quick and easy to Sign Up.%0A%0AClick the link below to join ${communityName}.%0A${url}/user/communityurl.html?tid=${reinviteId}%0A%0AThank you,%0A${userDetails.userData.displayName}`;

    // const config = {
    //   headers: {
    //     "content-type": "multipart/form-data",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   withCredentials: true,
    // };

    // axios
    //   .get(
    //     `${baseURL}/user/inviteFriend.html?inviteEmailID=${email}&inviteMessage=${newMessage}&inviteSubject=${subject}&reInviteActivity=&reInviteId=${reinviteId}`,
    //     config
    //   )
    API.inviteFriend(email, newMessage, subject, reinviteId).then((res) => {
      setResMsg(res.data);
      alert(res.data);
    });
  };

  return (
    <>
      {isSentInvitesOpen && (
        <div className="AskActivity" onClick={toggleList}>
          <div className="usable-container">
            <img src={IvitionsPending} alt="Ask-icon" className="askicon" /> 
            <Typography className="ask">Invites Sent</Typography>
          </div>
          <div className="usable-container">
            {ActivityInfo && Object.keys(ActivityInfo).lenght !== 0 ? (
              // <span className="number">{ActivityInfo.sentInvitationsSize}</span>
              <span className="number">{invtesSentData.invitationsSize}</span>
            ) : (
              <></>
            )}
            <img src={DownArrow} alt="DownArrow" className="DownArrow" />
          </div>
        </div>
      )}

      {isListOpen && (
        <div role="list" className="AskActivity_active">
          <div
            onClick={toggeInviteSentOpen}
            className="color-combination"
          >
            <div className="usable-container">
              <img src={IvitionsPending} alt="Ask-icon" className="askicon" />
              <Typography className="ask">Invites Sent</Typography>
            </div>
            <div className="usable-container">
              {ActivityInfo && Object.keys(ActivityInfo).lenght !== 0 ? (
                // <span className="number">{ActivityInfo.sentInvitationsSize}</span>
                <span className="number">{invtesSentData.invitationsSize}</span>
              ) : (
                <></>
              )}
              <img src={DownArrow} alt="DownArrow" className="DownArrow" />
            </div>
          </div>
          {listData && listData.length !== 0 ? (
            <div className="invite-sent-posts-container">
              {listData.map((result) => (
                <div className="post-1 invites-post" key={`key-id-${result.id}`}>
                  <div className="SentInviteHeader">
                    <div className="">
                      <p className="invitesSentListEmail">
                        <span>To</span>: {result.emailAddress}
                      </p>
                      <p className="invitesSentListJoiningTime">
                        {" "}
                        {result.time} ago in community
                      </p>
                    </div>
                    <div className="Status">
                      <p>{result.inviteStatus}</p>
                    </div>
                  </div>

                  {activeInvite === result.id &&
                    openPopup &&
                    result.inviteStatus === "Not Joined" && (
                      <div className="invitesSentPopupCntainer">
                        <Popup
                          open={openPopup}
                          onClose={() => setOpenPopup(false)}
                          contentStyle={{
                            width: "80%",
                            maxWidth: "500px",
                            margin: "auto",
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                        >
                          <div className="invitesSentPopupContentContainer">
                            <div className="inviteSentCloseButtonContainer">
                              <h1>See Invite</h1>
                              <button
                                type="button"
                                onClick={() => setOpenPopup(false)}
                              >
                                <IoMdClose className="inviteSentCloseIcon" />
                              </button>
                            </div>
                            <div className="inviteSentEmailContainer">
                              <p>Email:</p>
                              <input
                                type="text"
                                placeholder="Enter Email"
                                value={result.emailAddress}
                              />
                            </div>
                            <div className="inviteSentEmailContainer">
                              <p>Subject:</p>
                              <input
                                type="text"
                                placeholder="Enter Subject"
                                value={result.subject}
                              />
                            </div>
                            <div className="inviteSentEmailContainer">
                              <p>Message:</p>
                              <textarea
                                placeholder="Enter Message"
                                className="inviteSentMessageTextArea"
                                rows={4}
                              >
                                {result.message}
                              </textarea>
                            </div>
                            <button
                              type="button"
                              className="inviteSentReinviteButton btn btn-primary"
                              // button is not going to click and not calling onReInvite function , it is going to call if I add button outside the popup and trigger
                              onClick={() => {
                                onReInvite(
                                  result.emailAddress,
                                  result.subject,
                                  result.communityName,
                                  result.TenantID
                                );
                                setReInvitePopup(true);
                              }}
                            >
                              Reinvite
                            </button>
                            {reInvitePopup && (
                              <Popup
                                // onOpen={reInvitePopup}
                                // onClose={() => setReInvitePopup(false)}
                                modal
                              >
                                <div className="sentInviteReinvitePopup">
                                  {resMsg ? (
                                    <p>{resMsg}</p>
                                  ) : (
                                    <p>Something Went Wrong</p>
                                  )}
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setReInvitePopup(false);
                                    }}
                                  >
                                    <IoMdClose className="inviteSentCloseIcon" />
                                  </button>
                                </div>
                              </Popup>
                            )}
                          </div>
                        </Popup>
                      </div>
                    )}

                  {activeInvite === result.id &&
                  showInvite &&
                  result.inviteStatus === "Joined" ? (
                    <>
                      <div className="SentInviteJoinedUserMessageInfoContainer">
                        <p className="SentInviteJoinedUserMessageInfoSubject">
                          <span className="SentInviteJoinedUserMessageInfoSubjectParagraph">
                            Subject:
                          </span>{" "}
                          {result.subject}
                        </p>
                        <span>
                          {" "}
                          <span className="SentInviteJoinedUserMessageInfoSubjectParagraph">
                            Message:
                          </span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: result.formatMsg,
                            }}
                            className="SentInviteJoinedUserMessageInfoSubject"
                          ></span>{" "}
                        </span>
                      </div>
                      <span onClick={hideInvite} className="Invite">
                        Hide Invite
                      </span>
                    </>
                  ) : (
                    <span
                      onClick={() =>
                        result.inviteStatus === "Not Joined"
                          ? reSendInvite(result.id)
                          : viewInvite(result.id)
                      }
                      className="Invite"
                    >
                      See Invite
                    </span>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="invite-sent-no-posts-container">
              <img
                src={NoDataFound}
                alt="NoDataFound"
                className="invite-sent-no-posts-image"
              />
              <p>No Invitations sent from you</p>
            </div>
          )}
        </div>
      )}

      <ReInvite show={openModal} onHide={() => setOpenModal(false)} />
    </>
  );
}

export default Invites;
