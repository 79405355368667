// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ViewActAfterSignIn_rowContainer__Mf4Lp {
  margin-top: 20px;
}

@media all and (max-width: 991px) {
  .ViewActAfterSignIn_rowContainer__Mf4Lp {
    display: flex;
    flex-direction: column;
  }
  .ViewActAfterSignIn_rowContainer__Mf4Lp .ViewActAfterSignIn_leftside__L3GPg {
    order: 3;
  }
  .ViewActAfterSignIn_rowContainer__Mf4Lp .ViewActAfterSignIn_middle__a\\+qix {
    order: 1;
  }
  .ViewActAfterSignIn_rowContainer__Mf4Lp .ViewActAfterSignIn_rightside__GcZRF {
    order: 2;
  }
}

.ViewActAfterSignIn_profilehover__pNczp {
  display: block;
  position: absolute;
  left: 10%;
  top: -9%;
  min-width: 240px;
  z-index: 10;
  background: white 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 15px;
  padding: 15px;
  font-size: 12px;
  font-weight: 400;
}

@media all and (min-width: 769px) and (max-width: 920px) {
  .ViewActAfterSignIn_profilehover__pNczp {
    left: -20%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/DefaultCommunity/ViewActivitySignIn/ViewActAfterSignIn.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;EACxB;EACA;IACE,QAAQ;EACV;EACA;IACE,QAAQ;EACV;EACA;IACE,QAAQ;EACV;AACF;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,gBAAgB;EAChB,WAAW;EACX,wDAAwD;EACxD,4CAA4C;EAC5C,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".rowContainer {\r\n  margin-top: 20px;\r\n}\r\n\r\n@media all and (max-width: 991px) {\r\n  .rowContainer {\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n  .rowContainer .leftside {\r\n    order: 3;\r\n  }\r\n  .rowContainer .middle {\r\n    order: 1;\r\n  }\r\n  .rowContainer .rightside {\r\n    order: 2;\r\n  }\r\n}\r\n\r\n.profilehover {\r\n  display: block;\r\n  position: absolute;\r\n  left: 10%;\r\n  top: -9%;\r\n  min-width: 240px;\r\n  z-index: 10;\r\n  background: white 0% 0% no-repeat padding-box !important;\r\n  box-shadow: 0px 3px 6px #00000029 !important;\r\n  border-radius: 15px;\r\n  padding: 15px;\r\n  font-size: 12px;\r\n  font-weight: 400;\r\n}\r\n\r\n@media all and (min-width: 769px) and (max-width: 920px) {\r\n  .profilehover {\r\n    left: -20%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowContainer": `ViewActAfterSignIn_rowContainer__Mf4Lp`,
	"leftside": `ViewActAfterSignIn_leftside__L3GPg`,
	"middle": `ViewActAfterSignIn_middle__a+qix`,
	"rightside": `ViewActAfterSignIn_rightside__GcZRF`,
	"profilehover": `ViewActAfterSignIn_profilehover__pNczp`
};
export default ___CSS_LOADER_EXPORT___;
