import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import ReactDOM from "react-dom";

import React, { Component, useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { Carousel } from "primereact/carousel";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";

import "src/Assets/css/home/CarouselDemo.css";
import styles from "src/Assets/css/home/post.module.css";
import feedstyles from "src/Assets/css/home/feedpost.module.css";
import Avatar from "@material-ui/core/Avatar";

const MultipleRows = (props) => {
  const contibutorsData = useSelector(
    (state) => state.feedReducer.contibutorsData
  );
  const src = process.env.REACT_APP_SKOPIC_IMAGE;
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // productService.getProductsSmall().then(data => setProducts(data.slice(0, 6)));
    if (props.contibutorsData) {
      console.log(contibutorsData);
      // console.log(props.contibutorsData);
      if (props.contibutorsData && Object.keys(props.contibutorsData).length !== 0) {
        if (props.contibutorsData.contribut) {
          setProducts(props.contibutorsData.contribut);
          // setContributors(contibutorsData.contribut.slice(7,10))
          console.log("hi im" + contibutorsData);
        }
      }
    }
  }, [props.contibutorsData]);

  const settings = {
    // className: "center",
    // centerMode: true,
    // infinite: true,
    // centerPadding: "60px",
    slidesToShow: 1,
    // speed: 500,
    rows: 2,
    slidesPerRow: 3,
  };
  return (
    <>
      <Slider {...settings}>
        {products && Object.keys(products).length !== 0 ? (
          products.map((product) => (
            <div className="carousel-demo">
              {/* <div className="card"> */}
              <div className="product-item">
                <div className="product-item-content">
                  <img
                    src={`${src}${product.uImage}`}
                    onError={(e) =>
                    (e.target.src =
                      "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                    }
                    alt={product.displayName}
                    className="product-image"
                    onMouseEnter={() => props.handleClickEvent(product.id, true)}
                    onMouseLeave={() => props.handleClickEvent(0, false)}
                  />
                  {/* <div className="hover-Box">
                    <div style={{display:"flex"}}>
                    <p >
                       SAY {product.say} | UPDATE{" "}
                      {product.iUpdate} | Bio {product.shortBio}
                    </p>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* </div> */}
            </div>
          ))
        ) : (
          <p>No Data</p>
        )}
      </Slider>
    </>
  );
};

export default MultipleRows;
