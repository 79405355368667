import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import { Menu, MenuItem } from '@szhsin/react-menu';
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";

import PostMessageValidation from "src/Modules/Reusuablecomponents/PostMessageValidation";
import SimpleDialogDemo from "src/Modules/Reusuablecomponents/CustomizedDialogs";
import ReusuableSendaMailModal from "src/Modules/Reusuablecomponents/ReusuableSendaMailModal";
import ReusuableReportDialogmodal from "src/Modules/Reusuablecomponents/ReusuableReportDialogmodal";
import follow from "src/Assets/images/follow.svg";
import unfollow from "src/Assets/images/unfollow.svg";
import Edit from "src/Assets/images/edit.svg";
import Delete from "src/Assets/images/Delete.svg";
import Location from "src/Assets/images/locationpin.png";
import messageImg from "src/Assets/images/noofsays.svg";
import activityStyle from "src/Assets/css/Activity/activity.module.css";

import ChildSay from "../ChildSay";
import AvatarBadge from "src/Components/AvatarBadge";

const PopupComponent = ({
  open,
  onClose,
  popupData,
  // setPopupData,
  followunfollow,
  postId,
  deletePopupMessage,
  filtertype,
}) => {
  const profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const [postData, setPostData] = useState({});
  const [popupChildSayId, setPopupChildSayId] = useState(null);
  const [editID, setEditID] = useState(null);

  useEffect(() => {
    setPostData(popupData);
  }, [popupData]);

  const onUpdateMessage = (id, message) => {
    const childQ = postData?.childQ || [];
    const updatedChildQ = childQ.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          Message: message,
        };
      } else {
        return { ...item };
      }
    });
    setPostData({ ...popupData, childQ: updatedChildQ });
  };

  const updateMessage = (id, message) => {
    onUpdateMessage(id, message);
    setEditID(null);
  };

  const cancelEdit = () => {
    setEditID(null);
  };

  const truncateLocationName = (locationName) => {
    const maxLength = 60; // set your desired max length
    if (locationName.length > maxLength) {
      return locationName.substring(0, maxLength) + "...";
    } else {
      return locationName;
    }
  };

  const updateSayCount = (id, operator) => {
    const childQ = postData?.childQ || [];
    const updatedChildQ = childQ.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          sayCount: operator === "increment" ? item.sayCount + 1 : item.sayCount - 1
        };
      } else {
        return { ...item };
      }
    });
    setPostData({ ...popupData, childQ: updatedChildQ });
  };

  return (
    <>
      <Popup
        modal
        key={postId}
        open={open}
        contentStyle={{ borderRadius: "10px", padding: "20px" }}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div>
          {postData && Array.isArray(postData?.childQ) ? (
            postData.childQ.map((item, index) => (
              <div key={index}>
                <div className={activityStyle.askTagPopupNameTimeMainContainer}>
                  <div>
                    <AvatarBadge
                      src={`${profileImagePath}${item?.uimage}`}
                      alt={item?.displayName}
                      isPrimaryModerator={item?.isModerator == 1}
                      isModerator={item?.isModerator == 0 && item?.moderatorStatus == 1}
                    />
                  </div>
                  <div className={activityStyle.askTagPopupNameTimeContainer}>
                    <p>{item.displayName}</p>
                    <span>
                      {item.Message_Time} in {item.Tenant_name}{" "}
                      {item.locName && (
                        <>
                          <img
                            src={Location}
                            style={{ margin: "4px", height: "12px" }}
                          />
                          <span>{truncateLocationName(item.locName)}</span>
                        </>
                      )}
                    </span>
                  </div>

                  <div className={activityStyle.askTagPopupDropdownButton}>
                    <IconButton
                      onClick={onClose}
                      style={{ padding: 0, width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', outline: 'none' }}
                    >
                      <IoIosCloseCircleOutline fontSize={25} />
                    </IconButton>
                    <Menu
                      menuButton={
                        <IconButton
                          style={{ padding: 0, width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', outline: 'none' }}
                        >
                          <MdKeyboardArrowDown fontSize={25} />
                        </IconButton>
                      }
                      transition
                      direction="bottom"
                      arrow
                      menuStyle={{
                        backgroundColor: 'white',
                        borderRadius: 20,
                      }}
                    >
                      {item.User_ID !==
                        userDetails.userData.User_ID ? (
                        <>
                          {item.moderatorStatus === "1" ? (
                            <>
                              <MenuItem onClick={() => deletePopupMessage && deletePopupMessage(item.id)}>
                                <img src={Delete} alt="delete" style={{ marginRight: 8 }} />
                                <span style={{ fontSize: 14 }}>Delete Post</span>
                              </MenuItem>
                              <MenuItem>
                                <ReusuableSendaMailModal
                                  displayName={item.displayName}
                                  id={item.id}
                                />
                              </MenuItem>
                            </>
                          ) : (
                            <>
                              <MenuItem>
                                <ReusuableReportDialogmodal
                                  id={item.id}
                                  param={"AskorSay"}
                                />
                              </MenuItem>
                              <MenuItem>
                                <ReusuableSendaMailModal
                                  displayName={item.displayName}
                                  id={item.id}
                                />
                              </MenuItem>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <MenuItem onClick={() => setEditID(item.id)}>
                            <img src={Edit} alt="edit" style={{ marginRight: 8 }} />
                            <span style={{ fontSize: 14 }}>Edit Post</span>
                          </MenuItem>
                          <MenuItem onClick={() => {
                            deletePopupMessage && deletePopupMessage(item.id);
                          }}>
                            <img src={Delete} alt="delete" style={{ marginRight: 8 }} />
                            <span style={{ fontSize: 14 }}>Delete Post</span>
                          </MenuItem>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
                <div className={activityStyle.activityPostDescriptionContainer}>
                  {editID === item?.id ? (
                    <PostMessageValidation
                      id={item.id}
                      Message={item.Message}
                      messageType={"feedTextArea"}
                      filtertype={filtertype}
                      post={item}
                      onCloseEdit={cancelEdit}
                      onMessagedUpdated={updateMessage}
                    />
                  ) : (
                    <Typography>{item?.Message}</Typography>
                  )}
                </div>
                <p className={activityStyle.askTagPopupFollowers}>
                  <SimpleDialogDemo
                    followCount={item?.followCount}
                    followData={item?.follweduserprofile}
                    id={item.id}
                    hashTagType={item?.hashTagType}
                  />
                </p>
                <hr className={activityStyle.askTagPopupHorizontalLine} />
                <PostActions
                  item={item}
                  popupChildSayId={popupChildSayId}
                  setPopupChildSayId={setPopupChildSayId}
                  followunfollow={followunfollow}
                />
                {popupChildSayId === item.id && (
                  <div style={{ height: "60vh", overflowY: "scroll" }}>
                    <ChildSay key={popupChildSayId} id={item?.id} item={item} updateSayCount={updateSayCount} />
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No data</p>
          )}
        </div>
      </Popup>
    </>
  );
};

const PostActions = ({
  item,
  popupChildSayId,
  setPopupChildSayId,
  followunfollow,
}) => {
  return (
    <div className="PostActions--Following">
      <div
        style={{ cursor: "pointer", marginTop: "0px", display: "flex", alignItems: "center" }}
        onClick={() =>
          setPopupChildSayId(item.id === popupChildSayId ? null : item.id)
        }
      >
        <img src={messageImg} alt="ChildSay" />&nbsp;{item.sayCount}&nbsp;
        {item.sayCount === 1 ? "SAY" : "SAYs"}
      </div>
      <div style={{ cursor: "pointer", marginTop: "5px" }}>
        {item.followStatus === "Active" || item.followStatus === "Y" ? (
          <div
            id={`1${item.id}`}
            onClick={(e) => {
              e.stopPropagation();
              followunfollow(item.id, "isFollow=0", "1");
            }}
          >
            <img src={unfollow} alt="UnFolow" />
            <span>&nbsp;Unfollow</span>
          </div>
        ) : (
          <div
            id={`0${item.id}`}
            onClick={(e) => {
              e.stopPropagation();
              followunfollow(item.id, "isFollow=1", "0");
            }}
          >
            <img src={follow} alt="Folow" />
            <span>&nbsp;Follow</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PopupComponent;
