// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommunityActions {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 440px; /* Sets the maximum width */
  height: 63px;
  margin-top: 20px;
  border-radius: 20px;
  background-color: #efeeed;
  box-shadow: 5px 5px 8px rgb(163, 177, 198, 0.6),
    -6px -6px 12px rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.CommunityActions img {
  width: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.CommunityActions p, .CommunityActions a {
  font-size: 18px;
  margin: 0;
  padding: 0 10px;
  flex: 1 1;
}

@media (min-width: 320px) {
  .CommunityActions {
    max-width: 310px;
  }
}

@media (min-width: 425px) {
  .CommunityActions {
    max-width: 380px;
  }
}

@media (min-width: 768px) {
  .CommunityActions {
    max-width: 350px;
  }
}

@media (min-width: 992px) {
  .CommunityActions {
    max-width: 440px;
  }
}


.communitiesMainContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Modules/Activity/Community/Community.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,gBAAgB,EAAE,2BAA2B;EAC7C,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB;2CACyC;EACzC,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,eAAe;EACf,SAAO;AACT;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;;AAGA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".CommunityActions {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  max-width: 440px; /* Sets the maximum width */\n  height: 63px;\n  margin-top: 20px;\n  border-radius: 20px;\n  background-color: #efeeed;\n  box-shadow: 5px 5px 8px rgb(163, 177, 198, 0.6),\n    -6px -6px 12px rgba(255, 255, 255, 0.5);\n  cursor: pointer;\n}\n\n.CommunityActions img {\n  width: 20px;\n  margin-right: 10px;\n  margin-left: 10px;\n}\n\n.CommunityActions p, .CommunityActions a {\n  font-size: 18px;\n  margin: 0;\n  padding: 0 10px;\n  flex: 1;\n}\n\n@media (min-width: 320px) {\n  .CommunityActions {\n    max-width: 310px;\n  }\n}\n\n@media (min-width: 425px) {\n  .CommunityActions {\n    max-width: 380px;\n  }\n}\n\n@media (min-width: 768px) {\n  .CommunityActions {\n    max-width: 350px;\n  }\n}\n\n@media (min-width: 992px) {\n  .CommunityActions {\n    max-width: 440px;\n  }\n}\n\n\n.communitiesMainContainer{\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
