// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommunityRules_activeTab__1P\\+2f {
    color: #1976d2 !important;
    border-color: #1976d2 !important;
    background-color: #eff5fb !important;
}
.CommunityRules_inactiveTab__sm5YP {
    color: #c4c4c4 !important;
    border-color: #c4c4c4 !important;
    background-color: #ffffff !important;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Moderator/EditCommunity/CommunityRules.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gCAAgC;IAChC,oCAAoC;AACxC;AACA;IACI,yBAAyB;IACzB,gCAAgC;IAChC,oCAAoC;AACxC","sourcesContent":[".activeTab {\r\n    color: #1976d2 !important;\r\n    border-color: #1976d2 !important;\r\n    background-color: #eff5fb !important;\r\n}\r\n.inactiveTab {\r\n    color: #c4c4c4 !important;\r\n    border-color: #c4c4c4 !important;\r\n    background-color: #ffffff !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activeTab": `CommunityRules_activeTab__1P+2f`,
	"inactiveTab": `CommunityRules_inactiveTab__sm5YP`
};
export default ___CSS_LOADER_EXPORT___;
