// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `AutoCompleteTextField.css .react-autocomplete-input {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  bottom: auto;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  display: block;
  font-size: 14px;
  list-style: none;
  padding: 1px;
  position: absolute;
  text-align: left;
  z-index: 20000;
}

.react-autocomplete-input > li {
  cursor: pointer;
  padding: 10px;
  min-width: 100px;
}

.react-autocomplete-input > li.active {
  background-color: #337ab7;
  color: #fff;
}

.p-button {
  color: black !important;
  background-color: transparent !important;
  border: none !important;
}
.p-button.p-button-icon-only {
  padding: 0 !important;
}
button.close {
  line-height: 0.8 !important;
}
`, "",{"version":3,"sources":["webpack://./src/Assets/css/ReusuableComponents/AutoCompleteTextField.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,sBAAsB;EACtB,qCAAqC;EACrC,YAAY;EACZ,2CAA2C;EAC3C,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,wCAAwC;EACxC,uBAAuB;AACzB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,2BAA2B;AAC7B","sourcesContent":["AutoCompleteTextField.css .react-autocomplete-input {\n  background-clip: padding-box;\n  background-color: #fff;\n  border: 1px solid rgba(0, 0, 0, 0.15);\n  bottom: auto;\n  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);\n  display: block;\n  font-size: 14px;\n  list-style: none;\n  padding: 1px;\n  position: absolute;\n  text-align: left;\n  z-index: 20000;\n}\n\n.react-autocomplete-input > li {\n  cursor: pointer;\n  padding: 10px;\n  min-width: 100px;\n}\n\n.react-autocomplete-input > li.active {\n  background-color: #337ab7;\n  color: #fff;\n}\n\n.p-button {\n  color: black !important;\n  background-color: transparent !important;\n  border: none !important;\n}\n.p-button.p-button-icon-only {\n  padding: 0 !important;\n}\nbutton.close {\n  line-height: 0.8 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
