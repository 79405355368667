export const ignoreSpacesAndUrlsFromMsg = (message) => {
  const msgStr = message ? message : "";
  let inputDataLen = msgStr.length;
  const inputText = msgStr?.replace(/\n/g, " ");
  const strArray = inputText.split(" ");
  let validLetterCount = 0;
  let i;

  for (i = 0; i < strArray.length; i++) {
    if (
      strArray[i].indexOf("http://") == -1 &&
      strArray[i].indexOf("www.") == -1 &&
      strArray[i].indexOf("https://") == -1 &&
      strArray[i].indexOf("HTTP://") == -1 &&
      strArray[i].indexOf("WWW.") == -1 &&
      strArray[i].indexOf("HTTPS://") == -1
    ) {
      validLetterCount = validLetterCount + strArray[i].length;
    } else if (
      strArray[i].indexOf("http://") &&
      strArray[i].indexOf("www.") &&
      strArray[i].indexOf("https://") &&
      strArray[i].indexOf("HTTP://") &&
      strArray[i].indexOf("WWW.") &&
      strArray[i].indexOf("HTTPS://")
    ) {
      validLetterCount = validLetterCount + strArray[i].length;
    }
  }

  inputDataLen = validLetterCount;

  return {
    msgLength: inputDataLen,
    hasMessage: msgStr?.length > 0,
  };
};