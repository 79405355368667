import React from "react";
import Feed from "./Feed";
import FeedPost from "./FeedPost";
import styles from "src/Assets/css/home/Content.module.css";
// import Asks from "src/Assets/images/searchask.png";
// import Updates from "src/Assets/images/searchupdate.png";
// import Tags from "src/Assets/images/searchhashtag.png";
// import Says from "src/Assets/images/searchsay.png";
import NoDataFound from "src/Assets/images/data-not-found.svg";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

const Tab = withStyles({
  root: {
    textTransform: "none"
  }
})(MuiTab);

function MiddleContent(props) {
  const {
    feedData,
    userDetails,
    filterPosts,
    filterType,
    loading,
    childSaysLoading,
    followData,
    answersToYourAsk,
    privateTagsData,
    asksToBeAnswered,
    childSaysData,
    fetchSaysDataForAsks,
    fetchAsksToAnswer,
    askYourCommunity,
    fetchFeedData,
    fetchChildSaysData,
    postChildSayData,
    fetchFollowData,
    fetchVoteUpStatus,
    fetchPrivateTagsData,
    deleteMessage,
    refreshFeedData,
    feedPageNumber,
    fetchPageFeed,
    value,
    changeValue,
    clearAsksAndSays,
    deleteAnswersToAsksDialogPost,
    deleteAsksToBeAnsweredDialogPost,
    fetchContributors,
    onHashTagClick,
    landApiData
  } = props;

  const onbuttonClick = (id) => {
    changeValue(0)
    // if any filter is selected & user click again on same filter - remove selected filter
    filterPosts(id === filterType ? '' : id);
  };

  const handleChange = (event, newValue) => {
    changeValue(newValue);
  };

  const showMsg = (type) => {
    console.log('showMsg invoked', type);
    if (type === "ask") {
      return 'No Community ASKs available at this time';
    } else if (type === "say") {
      return 'No Community SAYs available at this time';
    } else if (type === "update") {
      return 'No Community UPDATEs available at this time';
    } else if (type === "hashtag") {
      return 'No Community Hashtags available at this time';
    } else {
      return 'No Community data available at this time';
    }
  }

  return (
    <div className={styles.content}>
      <Feed
        loading={loading}
        answersToYourAsk={answersToYourAsk}
        asksToBeAnswered={asksToBeAnswered}
        fetchSaysDataForAsks={fetchSaysDataForAsks}
        fetchAsksToAnswer={fetchAsksToAnswer}
        askYourCommunity={askYourCommunity}
        fetchFeedData={fetchFeedData}
        postChildSayData={postChildSayData}
        deleteMessage={deleteMessage}
        filterPosts={filterPosts}
        childSaysData={childSaysData}
        fetchChildSaysData={fetchChildSaysData}
        fetchFollowData={fetchFollowData}
        fetchVoteUpStatus={fetchVoteUpStatus}
        clearAsksAndSays={clearAsksAndSays}
        deleteAnswersToAsksDialogPost={deleteAnswersToAsksDialogPost}
        deleteAsksToBeAnsweredDialogPost={deleteAsksToBeAnsweredDialogPost}
        fetchContributors={fetchContributors}
        onHashTagClick={onHashTagClick}
        landApiData={landApiData}
        userDetails={userDetails}
      />
      <div className={styles.container}>
        <div className={styles.roundcontentandroundborder}>
          <div className={styles.roundcontent}>
            <div className={styles.asksayNav}>
              <ul>
                <li className={styles.ask}>
                  <a
                    onClick={() => onbuttonClick("ask")}
                    id="ask"
                    className={` ${filterType === "ask" ? styles.askbuttonimage : ""
                      }`}
                  >
                    {" "}
                    <span>{filterType === "ask" ? 'Asks' : 'Ask'}</span>{" "}
                  </a>
                </li>
                <li className={styles.say}>
                  <a
                    onClick={() => onbuttonClick("say")}
                    id="say"
                    className={`${filterType === "say" ? styles.saybuttonimage : ""
                      }`}
                  >
                    {" "}
                    <span>{filterType === "say" ? 'Says' : 'Say'}</span>{" "}
                  </a>
                </li>
                <li className={styles.update}>
                  <a
                    onClick={() => onbuttonClick("update")}
                    id="update"
                    className={`${filterType === "update" ? styles.updatebuttonimage : ""
                      }`}
                  >
                    {" "}
                    <span>{filterType === "update" ? 'Updates' : 'Update'}</span>{" "}
                  </a>
                </li>
                <li className={styles.tag}>
                  <a
                    onClick={() => onbuttonClick("hashtag")}
                    id="hashtag"
                    className={`${filterType === "hashtag" ? styles.tagbuttonimage : ""
                      }`}
                  >
                    {" "}
                    <span>{filterType === "hashtag" ? 'Tags' : 'Tag'}</span>{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.roundborder}></div>
        </div>
        <div className={styles.rect}>
          {filterType === "ask" || filterType === "hashtag" ? (
            <Paper square className={styles.paperbgcolor}>
              <Tabs
                value={value}
                TabIndicatorProps={{ style: { background: "#4795DE", height: "3.5px" } }}
                textColor="primary"
                onChange={handleChange}
                variant="fullWidth"
                className={styles.tabsWrapper}
              >
                <Tab label="All" className={value === 0 ? styles.selectedTab : styles.tab} id="allTab" />

                <Tab label="Following" className={value === 1 ? styles.selectedTab : styles.tab} id="followTab" />
              </Tabs>
            </Paper>
          ) : (
            ""
          )}

          {!loading && feedData && Object.keys(feedData).length !== 0 ? (
            feedData.breadCountLength > 0 ? (
              <FeedPost
                loading={childSaysLoading}
                listData={feedData.feed}
                data={feedData}
                value={value}
                filtertype={filterType}
                followData={followData}
                childSaysData={childSaysData}
                privateTagsData={privateTagsData}
                fetchChildSaysData={fetchChildSaysData}
                postChildSayData={postChildSayData}
                fetchFollowData={fetchFollowData}
                fetchVoteUpStatus={fetchVoteUpStatus}
                fetchPrivateTagsData={fetchPrivateTagsData}
                fetchFeedData={fetchFeedData}
                deleteMessage={deleteMessage}
                refreshFeedData={refreshFeedData}
                feedPageNumber={feedPageNumber}
                fetchPageFeed={fetchPageFeed}
                onHashTagClick={onHashTagClick}
                fetchContributors={fetchContributors}
              />
            ) : (
              <div className={styles.nodata}>
                <img src={NoDataFound} alt="NoDataFound" style={{ width: 200, height: 200 }} />
                <p>{showMsg(filterType)}</p>
              </div>
            )
          ) : (
            <CircularProgress className={styles.loader} />
          )}
        </div>
      </div>
    </div>
  );
}

export default MiddleContent;
