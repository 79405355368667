import React, { useEffect, useState, useRef, useCallback } from "react";
import { Typography } from "@mui/material";
import Say from "src/Assets/images/say.svg";
import DownArrow from "src/Assets/images/UpButton.png";
import "./SaysActtivity.css";
import axios from "axios";
import * as Cookies from "js-cookie";
import ActivityPost from "../../ActivityPost";
import { CookieConfig } from "../../../../Utils/CookieConfig";
import SaysActivityUI from "../Says Activity/SaysActivityUI";
import API from "../../../../network";
import ConfirmationDialog from "src/Components/ConfirmationDialog";

function SaysActtivity(props) {
  const { activityCountRes, decreaseActivitySaysCount } = props;
  const [isListOpen, setisListOpen] = useState(false);
  const [listData, setListData] = useState([]);
  const [breadCountLength, setBreadCountLength] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const listRef = useRef(null);
  const [completeSaysListData, setCompleteSaysListData] = useState({});
  const [confModalVisible, setConfModalVisible] = useState(null);

  const toggleList = () => {
    setisListOpen(true);
  };

  const handleScroll = useCallback(() => {
    const { scrollTop, clientHeight, scrollHeight } = listRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  }, []);

  const getData = (page) => {
    API.getUserSays(breadCountLength, page).then((res) => {
      setListData((prevList) => [...prevList, ...res.data.hashSayList]);
      setCompleteSaysListData(res.data);
      setBreadCountLength(
        (prevBreadLength) => prevBreadLength + res.data.hashSayList.length
      );
    });
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber]);

  const decreaseSayListCount = () => {
    setCompleteSaysListData((prevData) => ({
      ...prevData,
      hashSaySize: prevData.hashSaySize - 1,
    }));
  };

  const onClose = () => {
    setConfModalVisible(null);
  };

  const confirmationForDelete = (id) => {
    setConfModalVisible({
      open: true,
      type: "warning",
      title: "Are you sure you want to delete this SAY?",
      rejectLabel: "Cancel",
      acceptLabel: "Yes",
      onReject: onClose,
      onAccept: () => deleteTag(id),
    });
  };

  const likeUnlike = (id) => {
    const newData = [...listData];
    const modifiedData = newData.find((x) => x.id === id);
    modifiedData.UserLikeStatus = "InActive";
    modifiedData.voteUp = modifiedData.voteUp - 1 + 2;
    setListData(newData);
  };

  const deleteTag = (id) => {
    let formdata = new FormData();
    formdata.append("messageID", id);
    API.deleteProfilePost(formdata)
      .then((res) => {
        const newList = [...listData];
        const index = newList.findIndex((x) => x.id === id);
        if (index > -1) {
          newList.splice(index, 1);
          setListData(newList);
          decreaseActivitySaysCount && decreaseActivitySaysCount();
        }
        setConfModalVisible(null);
      })
      .catch((err) => {});
  };

  const updateMessage = (id, message) => {
    const newData = [...listData].map((eachItem) => {
      if (eachItem?.id === id) {
        return {
          ...eachItem,
          Message: message,
        };
      }
      return { ...eachItem };
    });
    setListData(newData);
  };

  return (
    <>
      {!isListOpen && (
        <div className="SaysActivity" onClick={toggleList}>
          <div className="usable-container">
            <img src={Say} alt="Ask-icon" className="askicon" />
            <Typography className="ask">SAY</Typography>
          </div>
          <div className="usable-container">
            <span className="number">{activityCountRes.allsayscount}</span>
            <img src={DownArrow} alt="DownArrow" className="DownArrow" />
          </div>
        </div>
      )}

      {isListOpen && (
        <div className="AskActivity_active">
          <div
            className="color-combination"
            onClick={() => setisListOpen(false)}
          >
            <div className="usable-container">
              <img src={Say} alt="Ask-icon" className="askicon" />
              <Typography className="ask">SAY</Typography>
            </div>
            <div className="usable-container">
              <span className="number">{activityCountRes.allsayscount}</span>
              <img src={DownArrow} alt="DownArrow" className="DownArrow" />
            </div>
          </div>
          <div
            ref={listRef}
            onScroll={handleScroll}
            role="list"
            className="posts"
          >
            {listData && listData.length !== 0 ? (
              // <ActivityPost
              //   listData={listData}
              //   updateMessage={updateMessage}
              //   delete={deleteTag}
              //   likeUnlike={likeUnlike}
              // />
              <SaysActivityUI
                listData={listData}
                updateMessage={updateMessage}
                delete={confirmationForDelete}
                likeUnlike={likeUnlike}
              />
            ) : (
              <div className="no-data-container">
                <p>No Activity Says found</p>
              </div>
            )}
          </div>
        </div>
      )}
      {confModalVisible?.open && <ConfirmationDialog {...confModalVisible} />}
    </>
  );
}

export default SaysActtivity;
