// import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  searchBar: () => ({
    "& .MuiInputBase-root": {
      borderRadius: "25px",
      backgroundColor: "#ffffff !important",
      height: "35px",
      "& input": {
        height: "1em !important",
        height: "35px",
      },
    },
  }),
});

export default useStyles;
