import React from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import logo from "src/Assets/images/LandingPageImages/logo.png";
import HeaderAfterSignIn from "../Components/Header/header";
import "./index.css";

const NotFound = () => {
  const signInStatus = Cookies.get("signInStatus");
  return (
    <>
      {signInStatus === "true" ? (
        <HeaderAfterSignIn />
      ) : (
        <div className="Navbar header-container">
          <Link to="/">
            <div className="Logo">
              <img src={logo} alt="Skopic-Logo" width={90} height={48} />
            </div>
          </Link>
        </div>
      )}

      <div className="not-found-container">
        <img
          src="https://res.cloudinary.com/dsd24aeen/image/upload/v1696492953/bqgxlfjnylv2bk1pylmb.png"
          alt="not found"
          className="not-found-image"
        />
        <h1 className="not-found-heading">Page Not Found</h1>
        <p className="not-found-description">
          {/* we are sorry, the page you requested could not be found,Please go back
        to the homepage. */}
          Uh oh, we can’t seem to find the page you’re looking for. Try going
          back to the previous page.
        </p>
        <Link to="/">
          <button className="not-found-button " type="button">
            Go to your feed
          </button>
        </Link>
      </div>
    </>
  );
};

export default NotFound;
