import React from 'react'
import { Modal, Button } from "react-bootstrap";
import "src/Assets/css/MessageModal/MessageModal.css"

function MessageModal(props) {

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="MessageModal"
      style={{ backgroundColor: "transparent", color: "black", minWidth: "50px", border: "2px" }}
    >
      <Modal.Header closeButton style={{ backgroundColor: "white", maxWidth: 400, margin: "auto" }}>
        <Modal.Title id="contained-modal-title-vcenter" className="title">
          <p>
            {props.message}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      </Modal.Body>
    </Modal>
  );
}
export default MessageModal
