import React, { useEffect } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  TextField,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import useStyles from "./styles";
import styles from "src/Assets/css/home/post.module.css";

const Search = ({
  panTo,
  isLocationSelect,
  setLat,
  setLng,
  setAddress,
  setMarkers,
  mapClick,
  setMapClick,
  setIsEnable,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });

  const { searchBar } = useStyles();

  useEffect(() => {
    if (mapClick) {
      setValue("");
    }
  }, [mapClick]);

  const handleSelect =
    ({ description }) =>
    async () => {
      setValue(description, false);

      clearSuggestions();
      const results = await getGeocode({ address: description });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
      isLocationSelect(description);
      setAddress(description);
      setLat(lat);
      setLng(lng);
      setMarkers({
        lat: lat,
        lng: lng,
        time: new Date(),
      });
      setMapClick(false);
      setIsEnable(true);
    };

  const renderSuggestions = () => {
    return (
      <Box
        sx={{
          bgcolor: "#FFFFFF",
          width: "585px",
          position: "relative",
          left: "-6%",
          borderRadius: " 20px",
          padding: "0 20px",
        }}
      >
        <List>
          {data.map((suggestion) => (
            <ListItem
              disablePadding
              key={suggestion.place_id}
              onClick={handleSelect(suggestion)}
            >
              <ListItemButton>
                <ListItemText primary={suggestion.description} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className={styles.locationsearch}>
      <div className={searchBar}>
        <TextField
          fullWidth
          // label="Search Location"
          placeholder="Search Location"
          type="search"
          value={value}
          onChange={handleInput}
          variant="outlined"
          disabled={!ready}
        />
      </div>
      {status === "OK" && <ul>{renderSuggestions()}</ul>}
    </div>
  );
};

export default Search;
