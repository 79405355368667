import React, { useState, useEffect } from "react";
import { TextField, Select, MenuItem } from "@material-ui/core";
import { CountryPicker, getPickerData } from '../../../pages/Landing/components/CountryPicker';
import "./index.css";

const SearchBox = ({
  searchValue,
  handleSearch,
  handleClear,
  defaultCountry,
  onChangeCountry,
  countryList,
}) => {
  const [selectedCountry, setSelectedCountry] = useState({});
  const countryData = getPickerData();

  useEffect(() => {
    setSelectedCountry(defaultCountry);
  }, [defaultCountry]);

  const onCountryChange = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    onChangeCountry(country);
  };

  const getCountryFlag = (countryCode) => {
    const image = countryData.find(it => it.countryCode === countryCode)?.image ?? "";
    return image;
  };
  return (
    <div className="searchcontainer">
      <Select
        variant="outlined"
        value={selectedCountry}
        onChange={onCountryChange}
        className="select"
        renderValue={(selected) => {
          if (Object.keys(selected).length > 0) {
            return (
              <img
                src={getCountryFlag(selected?.country_code)}
                alt={selected?.country_name?.slice(0, 1)}
                width={"25px"}
                height={"15px"}
              />
            )
          } else {
            return null;
          }
        }}
      >
        {countryList?.map((country) => (
          <MenuItem key={country.country_code} value={country}>
            <div style={{ width: "165px", display: "flex", alignItems: "center" }}>
              <img
                src={getCountryFlag(country?.country_code)}
                alt={country?.country_name?.slice(0, 1)}
                width={"16px"}
                height={"16px"}
              />
              <div style={{ marginLeft: "8px" }}>{country?.country_name}</div>
            </div>
          </MenuItem>
        ))}
      </Select>
      <TextField
        variant="outlined"
        value={searchValue}
        placeholder="Search Community"
        onChange={handleSearch}
        className="custom-text-field search-box-choose-default-community-before-signIn"
      />
    </div>
  );
};

export default SearchBox;
