import React from "react";
import Header from "src/Components/Header/header";

function Page(props) {
  return (
    <div>
      <Header {...props} />
      {props.children}
    </div>
  );
};

export default Page;

export const PageEnum = {
  Home: "Home",
  Profile: "Profile",
  Moderator: "Moderator",
  Settings: "Settings",
  Notifications: "Notification",
};
