class CustomMethods {
    static getFilterCounts(text) {

        const list = text.split(" ");
        let filterCounts = {
            "tagCount": 0,
            "linkCount": 0,
            "trimCount": 0,
            "totalCount": 0
        }

        for (const l of list) {
            if (l.charAt(0) === '#') {
                filterCounts.tagCount +=l.length;
            } else if (l.includes("http")) {
                filterCounts.linkCount += l.length;
            } else {
                filterCounts.trimCount += l.length;
            }
            filterCounts.totalCount += l.length;
        }

        return filterCounts;
    }
}





