import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import 'src/Assets/css/settings/sidebaroptions.css';

function SidebarOption({ active, text, onSidebaroptionClick }) {
  return (
    <div className={`sidebarOption ${active && 'sidebarOption--active'} `}>
      <IconButton type="submit" className="sidebarbuttons" onClick={onSidebaroptionClick} >
        {text}
      </IconButton>
    </div>
  );
}

export default SidebarOption;
