//axios dependencies
import axios from "axios";

//Action imports
import * as childSayActions from "../../actions/searchactions/childsayActionCreator";
import * as allActions from "../../actions/actions.constants";

const childSayService = (store) => (next) => (action) => {
  next(action);
  const devURL = process.env.REACT_APP_SERVICES_API;

  switch (action.type) {
    case allActions.FETCH_CHILDSAY_DATA:
      axios
        .request({
          url: `${devURL}/jsonuser/childSays.html${action.payload}`,
          method: "POST",
          headers: {},
          withCredentials: true,
        })
        .then((response) => {
          next(childSayActions.receiveChildSayData(response.data));
        })
        .catch((error) => {
          // next({ type: "FETCH_CHILD_SAY_DATA_ERROR", error });
          console.log(error);
        });
      break;

    default:
      break;
  }
};
export default childSayService;
