// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
  display: flex;
  width: 943px;
  margin: auto;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .divider {
    width: 768px;
  }
}
@media (max-width: 425px) {
  .divider {
    width: 425px;
    display: flex;
    flex-direction: column;
    /* border:1px solid green; */
  }
}
@media (max-width: 320px) {
  .divider {
    width: 320px;
    display: flex;
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Assets/css/Activity/ActivityScreen.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,8BAA8B;AAChC;AACA;EACE;IACE,YAAY;EACd;AACF;AACA;EACE;IACE,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,4BAA4B;EAC9B;AACF;AACA;EACE;IACE,YAAY;IACZ,aAAa;IACb,sBAAsB;EACxB;AACF","sourcesContent":[".divider {\n  display: flex;\n  width: 943px;\n  margin: auto;\n  justify-content: space-between;\n}\n@media (max-width: 768px) {\n  .divider {\n    width: 768px;\n  }\n}\n@media (max-width: 425px) {\n  .divider {\n    width: 425px;\n    display: flex;\n    flex-direction: column;\n    /* border:1px solid green; */\n  }\n}\n@media (max-width: 320px) {\n  .divider {\n    width: 320px;\n    display: flex;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
