import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Divider } from "@material-ui/core";
import ActivityLogDropdown from "src/Assets/images/ActivityLogDropdown.svg";
import "src/Assets/css/Moderator/ModeratorHeader.css";
import * as moderatorActions from "src/store/actions/Moderator/moderatoractions";
// import CommunityInfo from "./EditCommunity/CommunityInfo";

export function ModeratorMenuItems(props) {
  // const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  // const ActionItems = useSelector(
  //   (state) => state.ModeratorReducer.ActionItemsData
  // );
  // const PrivateTagsData = useSelector(
  //   (state) => state.PrivateTagsReducer.PrivateTagsData
  // );

  // console.log("props==========", props);
  const dispatch = useDispatch();

  // const [isMenuItem] = useState(props.menuItem);
  const [tenantID, setTenantID] = useState(null);

  useEffect(() => {
    if (props.menuItem) {
      props.onSubItemChange(props.firstAction);
      props.onTypeChange("");
    }
  }, [props.menuItem]);

  useEffect(() => {
    if (props.tenantId) {
      setTenantID(props.tenantId);
    }
  }, [props.tenantId]);

  const onFirstOptionSelect = () => {
    props.onSubItemChange(props.firstAction);
    props.onTypeChange("");

    // // managemebers community moderator submenu first option (members)
    // if (props.firstAction === "Members") {
    //   if (userDetails && Object.keys(userDetails).length !== 0) {
    //     dispatch(moderatorActions.fetchManageMembersData(tenantID));
    //   }
    // }
    // // Manage content community moderation first option Actionitems
    // else if (props.firstAction === "ActionItems") {
    //   if (userDetails && Object.keys(userDetails).length !== 0) {
    //     dispatch(moderatorActions.fetchActionItemsData(tenantID));
    //     // console.log("hello from actionitems from moderator menu");
    //   }
    // }

    if (props.firstAction === "Members") {
      dispatch(moderatorActions.fetchManageMembersData(tenantID));
    } else if (props.firstAction === "Action Items") {
      dispatch(moderatorActions.fetchActionItemsData(tenantID));
    }
  };

  const onSecondOptionSelect = (e) => {
    props.onSubItemChange(props.secondAction);
    // console.log("ModeratorMenu.jsx", props);
    if (props.secondAction === "Photo Timeline") {
      dispatch(moderatorActions.fetchPhotoTimeLineList(`tenantId=${tenantID}`));
      dispatch(moderatorActions.fetchTenantList());
      // console.log(userDetails.userData.tenantId)

      //    managemebers community moderator submenu second option (moderator)
    } else if (props.secondAction === "Moderators") {
      dispatch(moderatorActions.fetchManageMembersData(tenantID));

      // Manage content community moderation second option Activity Log
    } else if (props.secondAction === "Activity Log") {
      if (e.target.innerText === "Flagged Posts") {
        props.onTypeChange("report");
      } else if (e.target.innerText === "Moderator History") {
        props.onTypeChange("moderator");
      } else if (e.target.innerText === "User Posts") {
        props.onTypeChange("message");
      } else {
        props.onTypeChange("message");
      }
    } else {
      return false;
    }
  };

  const onThirdOptionSelect = () => {
    props.onSubItemChange(props.thirdAction);
    props.onTypeChange("");

    // Manage content submenu third option privatetags
    // if (props.thirdAction === "Private Tags") {
    //   if (userDetails && Object.keys(userDetails).length !== 0) {
    //     // dispatch(moderatorActions.fetchPrivateTagsData(`tenantId=${tenantID}`))
    //     // dispatch(moderatorActions.fetchPrivateTagsData(tenantID))
    //     // dispatch(moderatorActions.fetchTenantList())
    //     // console.log("privatetags from moderator menu");
    //   } else {
    //     return false;
    //   }
    // }
  };

  // console.log(props.secondAction)

  const subMenuItemName = props.subMenuItem;
  const isFirstMenuSelected =
    subMenuItemName === "Action Items" ||
    subMenuItemName === "Members" ||
    subMenuItemName === "Community Info";
  const isSecondMenuSelected =
    subMenuItemName === "Activity Log" ||
    subMenuItemName === "Moderators" ||
    subMenuItemName === "Photo Timeline";
  const isThirdMenuSelected =
    subMenuItemName === "Private Tags" ||
    subMenuItemName === "Invite Members" ||
    subMenuItemName === "Community Rules";

  return (
    <React.Fragment>
      <div className="menuItems">
        <ul>
          <li
            onClick={onFirstOptionSelect}
            className={`${isFirstMenuSelected ? "FirstOption" : null}`}
          >
            <div className="action-item-count-container">
              {props.firstAction}
              {subMenuItemName === "Action Items" && (
                <div className="action-item-count">
                  <p>{props.actionItemsCount}</p>
                </div>
              )}
            </div>
          </li>
          <Divider variant="fullWidth" />
          <li
            onClick={onSecondOptionSelect}
            className={`${isSecondMenuSelected ? "FirstOption" : null}`}
          >
            {props.secondAction}
            {props.secondAction === "Activity Log" ? (
              <>
                <span>
                  <img src={ActivityLogDropdown} alt="ActivityLogDropdown" />
                </span>
                <div className="activityDropdownOption">
                  <li
                    value="message"
                    style={{
                      color: props.type === "message" ? "#4795de" : "black",
                    }}
                  >
                    User Posts
                  </li>
                  <li
                    value="report"
                    style={{
                      color: props.type === "report" ? "#4795de" : "black",
                    }}
                  >
                    Flagged Posts
                  </li>
                  <li
                    value="moderator"
                    style={{
                      color: props.type === "moderator" ? "#4795de" : "black",
                    }}
                  >
                    Moderator History
                  </li>
                </div>
              </>
            ) : null}
          </li>
          <Divider variant="fullWidth" />
          <li
            onClick={onThirdOptionSelect}
            className={`${isThirdMenuSelected ? "FirstOption" : null}`}
          >
            {props.thirdAction}
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

function ModeratorMenu(props) {
  return (
    <React.Fragment>
      {props.menuItem === "1" ? (
        <ModeratorMenuItems
          firstAction={"Action Items"}
          secondAction={"Activity Log"}
          thirdAction={"Private Tags"}
          // value="1"
          menuItem={props.menuItem}
          onSubItemChange={props.onSubMenuItemChange}
          onTypeChange={props.onTypeChange}
          type={props.type}
          subMenuItem={props.subMenuItem}
          tenantId={props.tenantId}
          actionItemsCount={props.actionItemsCount}
        />
      ) : props.menuItem === "2" ? (
        <ModeratorMenuItems
          firstAction={"Members"}
          secondAction={"Moderators"}
          thirdAction={"Invite Members"}
          menuItem={props.menuItem}
          onSubItemChange={props.onSubMenuItemChange}
          onTypeChange={props.onTypeChange}
          type={props.type}
          subMenuItem={props.subMenuItem}
          tenantId={props.tenantId}
        />
      ) : (
        <ModeratorMenuItems
          firstAction={"Community Info"}
          secondAction={"Photo Timeline"}
          thirdAction={"Community Rules"}
          menuItem={props.menuItem}
          onSubItemChange={props.onSubMenuItemChange}
          onTypeChange={props.onTypeChange}
          type={props.type}
          subMenuItem={props.subMenuItem}
          tenantId={props.tenantId}
        />
      )}
    </React.Fragment>
  );
}

export default ModeratorMenu;
