import React, { useState, useEffect, useContext } from "react";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import Select from "@material-ui/core/Select";
// import { Margin } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import * as CryptoJS from "crypto-js";
import apple from "src/Assets/images/LandingPageImages/apple.png";
// import google from "src/Assets/images/LandingPageImages/Google.svg";
// import facebook from "src/Assets/images/LandingPageImages/Facebook.svg";
import { calcMD5 } from "src/md5Forgot.js";
import DefaultTextBox from "src/Components/DefaultCommunity/textField/textField.js";
import SignUpRepo from "src/repo/signup_repo";
import Api from "src/network";
import EmailVerificationModal from "../EmailVerification";
import GoogleSignUp from "../GoogleSignUp";
import FbAuth from "../FbAuth";
import style from "./SignUp.module.css";
import { emailRegex } from "src/Constants/regex";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import CircularProgress from '@material-ui/core/CircularProgress';
import { SignupContext } from "src/context/SignupContext/SignupContext";
import { AppleButton } from "../SocialButtons";

const schema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, 'Please enter a valid email address')
    .required('Email address is required'),
  password: yup
    .string()
    .typeError('Please enter a valid password')
    .min(8, 'Password should be minimum 8 characters')
    .required('Password is required'),
  displayName: yup
    .string()
    .typeError('Please enter a valid display name')
    .min(4, 'Display name should be minimum 4 characters')
    .required('Display name is required'),
  selectedCommunity: yup
    .string()
    .typeError("Please select community")
    .required("Community is required")
});

function SignUp() {
  const history = useHistory();
  const location = useLocation();

  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [isPwdVisible, setIsPwdVisible] = useState(false);
  // const [displayName, setDisplayName] = useState("");
  // const [country, setCountry] = useState("Country");
  const [countryData, setCountryData] = useState([]);
  const [community, setCommunity] = useState("Default Community");
  const [communityData, setCommunityData] = useState([]);
  const [verificationModal, setVerificationModal] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  // const [selectedCommunity, setSelectedCommunity] = useState("Default Community");
  // const [selectedCommunityDisabled, setSelectedCommunityDisabled] = useState(true);
  const [tenantId, setTenantId] = useState("");
  const [loading, setLoading] = useState(false);

  const signupContext = useContext(SignupContext);

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    getValues,
    setValue,
    setError,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      displayName: "",
      selectedCommunity: "Default Community",
    },
    resolver: yupResolver(schema),
  });

  let errorStatus = false;
  // let emailErrorMsg = "";
  // let passwordErrorMsg = "";
  // let displaynameErrorMsg = "";
  // let countryErrorMsg = "";
  // let communityErrormsg = "";
  // let SignupStatus = 0;
  // let z = null;
  // let communityValue = "";

  let dispatch = useDispatch();
  let authenticationVerificationStatus = useSelector(
    (state) => state.SignInReducer.isAuthorized
  );

  // useEffect(() => {
  //   if (location?.state?.community) {
  //     // setSelectedCommunity(location?.state?.community);
  //     // setCountry(location?.state?.countryName);
  //     setTenantId(location?.state?.tenantId);
  //     // setEmail(location?.state?.email);
  //     // setPassword(location?.state?.password);
  //     // setDisplayName(location?.state?.displayName);

  //     reset({
  //       email: location?.state?.email,
  //       password: location?.state?.password,
  //       displayName: location?.state?.displayName,
  //       country: location?.state?.countryName,
  //       selectedCommunity: location?.state?.community,
  //     });
  //   }
  // }, [location]);

  useEffect(() => {
    const {
      signup,
      email,
      password,
      displayName,
      community,
      tenantId,
    } = signupContext?.signupState;
    if (signup) {
      setTenantId(tenantId);
      reset({
        email: email,
        password: password,
        displayName: displayName,
        selectedCommunity: community,
      });
    }
  }, []);

  useEffect(() => {
    history.replace({});
  }, []);

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const res = await Api.fetchCountry();
        setCountryData(res?.data?.countryList);
        setCommunityData(res?.data?.countryTenantList);
      } catch (e) {
        console.error(e);
      }
    };
    fetchCountryData();
  }, []);

  // const emailChangeHandler = (e) => {
  //   setEmail(e.target.value);
  // };
  // const passwordChangeHandler = (e) => {
  //   setPassword(e.target.value);
  // };
  // const displaynameChangeHandler = (e) => {
  //   setDisplayName(e.target.value);
  // };
  // const countryChnageHandler = (e) => {
  //   setCountry(e.target.value);
  //   setSelectedCommunity("");
  // };
  // const communityChangeHandler = (e) => {
  //   setCommunity(e.target.value);
  // };
  // const asignId = (id) => {
  //   return (communityValue = id);
  // };

  if (authenticationVerificationStatus) {
    return <Redirect to="/Home" />;
  }

  // bdehbcsdbjhbjbchwdbdcbc
  const validateEmailHandler = async () => {
    const isValid = await trigger("email");
    if (!isValid) return;
    try {
      let data = new FormData();
      data.append("mail", getValues().email);
      const res = await Api.validateTempUserEmail(data);
      if (res.data?.status === "TempUser") {
        // setVerficationModal(true);
      } else if (res.data?.status === "SkopicUser") {
        setError("email", { type: "required", message: "There is an existing account with this email address" })
      } else if (res.data?.status === "NewUser") {
        // errorStatus = false;
      }
    } catch (e) {
      console.error(e);
    }
  };

  // bwdbjbcbc
  // const clearMessage = () => {
  //   errorStatus = false;
  //   emailErrorMsg = "";
  // };

  const SignUpHandler = async (e, value) => {
    e.preventDefault();

    const { email, password, displayName } = getValues();

    let encodedEmail = CryptoJS.AES.encrypt(email, "skopic").toString();
    let encodePassword = calcMD5(password);
    let formData = new FormData();
    formData.append("email", encodedEmail);
    formData.append("displayName", displayName);
    formData.append("password", encodePassword);
    formData.append("password_c", encodePassword);
    formData.append("userTenant", community);

    try {
      const response = await Api.sendVerificationCode(formData, value);

      if (
        response.data.status === "TempUser" &&
        response.data.status === "success"
      ) {
        setVerificationModal(true);
      }
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  // const buttonDisablerHandler = () => {
  //   if (
  //     // !emailRegex.test(email) ||
  //     // password.length < 8 ||
  //     // displayName.length < 4 ||
  //     // country === "Country" ||
  //     // selectedCommunity === "Default Community" ||
  //     errorStatus
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  // let isEnabled = buttonDisablerHandler();
  // if (country === "country") {
  //   return (z = null);
  // } else {
  //   z = communityData[country];
  // }

  const SignUpClickHandler = async (data) => {
    const { email, password, displayName, selectedCommunity } = data || {};
    let hasError = false;

    if (selectedCommunity === "Default Community") {
      setError("selectedCommunity", { type: "required", message: "Please select community" });
      hasError = true;
    }
    if (hasError || !isValid) return;

    try {
      setLoading(true);
      const result = await SignUpRepo.requestSignUp(
        email,
        password,
        displayName,
        tenantId,
        selectedCommunity
      );
      if (result.isSuccess) {
        setVerificationModal(true);
        setResendOtp(result.reSend);
        //reset signup context data
        signupContext?.resetSignupState();
      } else {
        alert(result.message);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleLocation = () => {
    const { email, password, displayName } = getValues();
    history.push("/defaultCommunity", {
      email: email,
      password: password,
      displayName: displayName,
    });

    //set sign up data to the context
    signupContext?.setSignupState({
      signup: true, //set true to navigation purpose
      email: email,
      password: password,
      displayName: displayName,
    });
  };

  return (
    <>
      <div className={style.Signup}></div>
      <div className={style.SignupForm}>
        <form onSubmit={handleSubmit(SignUpClickHandler)}>
          <div className={style.SignupFormElemnts}>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="standard"
                  inputProps={{ maxLength: 50 }}
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={value}
                  onChange={onChange}
                  onBlur={validateEmailHandler}
                  // onKeyDown={clearMessage}
                  className={style.textField}
                  sx={{ fontSize: '16px' }}
                />
              )}
            />
          </div>
          {errors?.email && (
            <p className={style.ErrorMsg}>{errors?.email?.message}</p>
          )}
          <div className={style.SignupFormElemnts}>
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="standard"
                  inputProps={{ maxLength: 32 }}
                  type={isPwdVisible ? 'text' : 'password'}
                  placeholder="Password"
                  name="password"
                  value={value}
                  onChange={onChange}
                  className={style.textField}
                  sx={{ fontSize: '16px' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{ margin: 0, padding: 6, outline: 'none' }}
                          onClick={() => setIsPwdVisible(state => !state)}>
                          {isPwdVisible ? <IoEyeOffOutline /> : <IoEyeOutline />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          {errors?.password && (
            <p className={style.ErrorMsg}>{errors?.password?.message}</p>
          )}
          <div className={style.SignupFormElemnts}>
            <Controller
              control={control}
              name="displayName"
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="standard"
                  inputProps={{ maxLength: 32 }}
                  type="text"
                  placeholder="Display Name"
                  name="name"
                  value={value}
                  onChange={onChange}
                  className={style.textField}
                  sx={{ fontSize: '16px' }}
                />
              )}
            />
          </div>
          {errors?.displayName && (
            <p className={style.ErrorMsg}>{errors?.displayName?.message}</p>
          )}
          {/* <div className={style.SignupFormElemnts}>
            <Controller
              control={control}
              name="country"
              render={({ field: { onChange, value } }) => (
                <Select
                  variant="standard"
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setValue("selectedCommunity", "");
                    signupContext?.setSignupState({
                      tenantId: "",
                      community: "",
                    });
                  }}
                  className={style.textField}
                  sx={{ fontSize: '16px' }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "Country")
                      return <em style={{ color: '#999', fontStyle: 'normal', fontSize: "19px" }}>Country</em>;
                    return selected;
                  }}
                >
                  <MenuItem value="Country" style={{ color: '#999' }}>
                    <em style={{ fontStyle: 'normal' }}>Country</em>
                  </MenuItem>
                  {(countryData && countryData.length !== 0) && (
                    countryData.map((eachCountry) => (
                      <MenuItem
                        key={`country-code-${eachCountry.country_code}`}
                        value={eachCountry.country_name}
                      >
                        {eachCountry.country_name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              )}
            />
          </div>
          {errors?.country && (
            <p className={style.ErrorMsg}>{errors?.country?.message}</p>
          )} */}
          <div className={style.DefaultCommunity}>
            {/* <select
                            value={community}
                            onChange={(e) => communityChangeHandler(e)}
                        >
                            <option value="Default Community">Default Community</option>
                            {z === undefined ? (
                                <option value="Default Community">Default Community</option>
                            ) : (
                                z.map((communityList) =>
                                    communityList.type === "public" ? (
                                        <option
                                            key={communityList.id}
                                            value={communityList.name}
                                            onClick={() => asignId(communityList.id)}
                                        >
                                            {communityList.name}
                                        </option>
                                    ) : (
                                        <></>
                                    )
                                )
                            )}
                        </select> */}
            <DefaultTextBox
              value={getValues().selectedCommunity}
              handleLocation={handleLocation}
            // locationIconDisabled={selectedCommunityDisabled}
            />
          </div>
          {errors?.selectedCommunity && (
            <p className={style.ErrorMsg}>{errors?.selectedCommunity?.message}</p>
          )}
          <p>
            <div className={style.TandC}>
              By Signing Up, you accept to Skopic's{" "}
              <span> terms of service</span> and <span> privacy.</span>
            </div>
          </p>
          <button
            type="submit"
            className={style.SignupButton}
          // disabled={isEnabled}
          >
            {loading
              ? <CircularProgress style={{ width: "20px", height: "20px" }} />
              : " sign up"
            }
          </button>
        </form>
      </div>
      {errorStatus ? (
        <p className={style.ErrorMsg}>
          There is an existing account with this email address
        </p>
      ) : null}

      <div className={style.Changesignin}>
        <div className={style.line} />
        <span>or</span>
        <div className={style.line} />
      </div>

      <div className={style.SocialIcon}>
        {/* <img
            src={google_new}
            alt="googleImage"
            className={style.SocailImage}
          />
          <img
            src={facebook}
            alt="FacebookImage"
            className={style.SocailImage}
          /> */}

        <GoogleSignUp />
        <div style={{ margin: "10px 0px" }} />
        <AppleButton />
        {/* <FbAuth /> */}
        {/* <img
          src={apple}
          alt="AppleImage"
          style={{ width: 46, height: 48, marginBottom: "4px", marginLeft: "10px", objectFit: "contain" }}
          className={style.SocailImage}
        /> */}

        {/* <img src={apple} alt="AppleImage" className={style.SocailImage} /> */}
      </div>

      <div>
        <EmailVerificationModal
          show={verificationModal}
          onHide={() => setVerificationModal(false)}
          email={getValues().email}
          tentId={tenantId}
          password={getValues().password}
          resendOtp={resendOtp}
          dispatch={dispatch}
          tenantName={getValues().selectedCommunity}
          centered
        />
      </div>
    </>
  );
}

export default SignUp;
