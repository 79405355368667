import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CiWarning, CiCircleCheck, CiCircleInfo } from "react-icons/ci";
import { BiError } from "react-icons/bi";
import { Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ConfirmationDialog({
  open,
  handleClose,
  title,
  type = 'success' | 'info' | 'warning' | 'error',
  acceptLabel,
  onAccept,
  rejectLabel,
  onReject,
}) {

  let Icon = CiWarning;
  let color = 'grey';
  if (type === 'success') {
    Icon = CiCircleCheck;
    color = 'green';
  } else if (type === 'info') {
    Icon = CiCircleInfo;
    color = 'grey';
  } else if (type === 'error') {
    Icon = BiError;
    color = 'red';
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Icon fontSize={50} color={color} />
        </Box>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
          {title}
        </Typography>
        <Box display='flex' alignItems='center' justifyContent='space-evenly'>
          {rejectLabel && onReject && (
            <Button
              type='button'
              color='primary'
              variant='text'
              onClick={onReject}
              disableRipple={false}
            >
              {rejectLabel}
            </Button>
          )}
          {acceptLabel && onAccept && (
            <Button
              type='button'
              color='primary'
              variant='text'
              onClick={onAccept}
              disableRipple={false}
            >
              {acceptLabel}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
