import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button as MuiButton, Switch, Typography, Radio } from "@mui/material";
import { styled } from "@mui/material/styles";
import Api from "src/network";
import classes from "./CommunityRules.module.css";

const Button = styled(MuiButton)({
  borderRadius: "0px",
  marginBottom: "10px",
  fontSize: "12px",
  padding: "0px",
  paddingLeft: "12px",
  paddingRight: "12px",
  textTransform: "none",
  ":focus": {
    outline: "none !important",
  },
});

const Header = styled(Typography)({
  fontSize: "16px",
  fontWeight: "500",
  marginBottom: "10px",
});

const Types = styled(Typography)({
  fontSize: "15px",
});

const Container = styled("div")({
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "20px",
  marginBottom: "20px",
});

const ButtonContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

const PermissionnContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const CommunityRules = (props) => {
  const moderatingCommunitysData = useSelector(
    (state) => state.ModeratorReducer.moderatingCommunitysData
  );

  const [initialCommunityData, setInitialCommunityData] = useState(
    moderatingCommunitysData
  );

  // Use local state for the current community
  const [currentCommunity, setCurrentCommunity] = useState(null);

  useEffect(() => {
    const fetchAndUpdateCommunity = async () => {
      try {
        // Step 2: Fetch the latest data from the API
        const response = await Api.fetchModeratingCommunityList();
        console.log(`moderator data`);
        console.log(response);
        console.log(`moderator data over`);

        // Step 3: Find the community data corresponding to the current tenantId
        const community = response?.data?.restrictions?.find(
          (x) => x.tenantId === props.tenantId
        );

        // Step 4: Update the currentCommunity state with the fetched data
        setCurrentCommunity(community);
      } catch (error) {
        console.error("Error fetching community data:", error);
      }
    };

    // Call the async function to fetch and update community data
    fetchAndUpdateCommunity();
  }, [props.tenantId]); // Dependencies: run when tenantId changes

  // const permissionsArray = [
  //   { id: 1, title: "Post ASKs", name: "ask" },
  //   { id: 2, title: "Post SAYs", name: "say" },
  //   { id: 3, title: "Post UPDATEs", name: "bottomSay" },
  //   { id: 4, title: "Create TAGs", name: "hashSay" },
  //   { id: 5, title: "Post SAYs to ASKs", name: "hashTag" },
  //   { id: 6, title: "Post SAYs to TAGs", name: "update" },
  //   { id: 7, title: "Add Moderators", name: "moderator" },
  // ];
  const permissionsArray = [
    { id: 1, title: "Post ASKs", name: "ask" },
    { id: 2, title: "Post SAYs", name: "say" },
    { id: 3, title: "Post UPDATEs", name: "update" },
    { id: 4, title: "Create TAGs", name: "hashTag" },
    { id: 5, title: "Post SAYs to ASKs", name: "bottomSay" },
    { id: 6, title: "Post SAYs to TAGs", name: "hashSay" },
    { id: 7, title: "Add Moderators", name: "moderator" },
  ];

  // const setModeratorPermission = async (name) => {
  //   try {
  //     const updatedCommunity = { ...currentCommunity };
  //     updatedCommunity[name] = updatedCommunity[name] === "1" ? "0" : "1";

  //     // Update local state
  //     setCurrentCommunity(updatedCommunity);

  //     // Prepare the reordered permissions array
  //     const orderedPermissionsArray = [
  //       permissionsArray.find((item) => item.id === 1),
  //       permissionsArray.find((item) => item.id === 2),
  //       permissionsArray.find((item) => item.id === 5),
  //       permissionsArray.find((item) => item.id === 6),
  //       permissionsArray.find((item) => item.id === 4),
  //       permissionsArray.find((item) => item.id === 3),
  //       permissionsArray.find((item) => item.id === 7),
  //     ];

  //     // Prepare formData
  //     const formData = new FormData();
  //     formData.append("tenantIds", props.tenantId);
  //     formData.append(
  //       "rules",
  //       orderedPermissionsArray
  //         .map((item) => updatedCommunity[item.name])
  //         .join(",")
  //     );

  //     // Make API call to update server-side data
  //     const response = await Api.setModeratorPermission(formData);
  //     // console.log(response)

  //     // Update the initialCommunityData with the modified community
  //     setInitialCommunityData((prevCommunityData) => ({
  //       ...prevCommunityData,
  //       restrictions: prevCommunityData?.restrictions?.map((community) =>
  //         community.tenantId === props.tenantId ? updatedCommunity : community
  //       ),
  //     }));
  //   } catch (error) {
  //     // Handle error
  //     console.error("Error updating moderator permission:", error);
  //   }
  // };

  const setModeratorPermission = async (name) => {
    try {
      const updatedCommunity = { ...currentCommunity };
      updatedCommunity[name] = updatedCommunity[name] === "1" ? "0" : "1";

      // Updating the local state with the new community data
      setCurrentCommunity(updatedCommunity);

      // Preparing the reordered permissions array
      const orderedPermissionsArray = [
        permissionsArray.find((item) => item.id === 1),
        permissionsArray.find((item) => item.id === 2),
        permissionsArray.find((item) => item.id === 5),
        permissionsArray.find((item) => item.id === 6),
        permissionsArray.find((item) => item.id === 4),
        permissionsArray.find((item) => item.id === 3),
        permissionsArray.find((item) => item.id === 7),
      ];

      // checking reordered permissions array for debugging
      console.log("Ordered Permissions Array:", orderedPermissionsArray);

      // Preparing the form data
      const formData = new FormData();
      formData.append("tenantIds", props.tenantId);

      // Generating the rules string from the updated community data
      const rulesString = orderedPermissionsArray
        .map((item) => updatedCommunity[item.name] || "0") // Default to "0" if permission is not set
        .join(",");

      // checking rules string for debugging
      console.log("Rules String:", rulesString);

      formData.append("rules", rulesString);

      const response = await Api.setModeratorPermission(formData);

      console.log("API Response:", response);

      // Updating the initialCommunityData with the modified community data
      setInitialCommunityData((prevCommunityData) => ({
        ...prevCommunityData,
        restrictions: prevCommunityData?.restrictions?.map((community) =>
          community.tenantId === props.tenantId ? updatedCommunity : community
        ),
      }));
    } catch (error) {
      console.error("Error updating moderator permission:", error);
    }
  };

  const setNewsLetterType = async (value) => {
    const newNewsLetterType = value.toLowerCase();
    let formData = new FormData();
    formData.append("tenantId", props.tenantId);
    formData.append("newsletterFrequency", newNewsLetterType);
    try {
      await Api.scheduleNewsletter(formData);
    } catch (e) {
      console.error(e);
    }

    // Update local state
    setCurrentCommunity((prevCommunity) => ({
      ...prevCommunity,
      newsletterFrequency: newNewsLetterType,
    }));

    // We can update the Redux store here if needed
    setInitialCommunityData((prevCommunityData) => ({
      ...prevCommunityData,
      restrictions: prevCommunityData?.restrictions?.map((community) =>
        community.tenantId === props.tenantId
          ? { ...community, newsletterFrequency: newNewsLetterType }
          : community
      ),
    }));
  };

  const setCommunityType = async (value) => {
    let formData = new FormData();
    formData.append("tenantId", props.tenantId);
    formData.append("communityType", value);
    formData.append("communityView", currentCommunity.communityView);

    try {
      await Api.setCommunityType(formData);
    } catch (e) {
      console.error(e);
    }

    // Update local state
    setCurrentCommunity((prevCommunity) => ({
      ...prevCommunity,
      communityType: value,
    }));

    // We can update the Redux store here if needed
    setInitialCommunityData((prevCommunityData) => ({
      ...prevCommunityData,
      restrictions: prevCommunityData?.restrictions?.map((community) =>
        community.tenantId === props.tenantId
          ? { ...community, communityType: value }
          : community
      ),
    }));
  };

  const setCommunityView = async (value) => {
    let formData = new FormData();
    formData.append("tenantId", props.tenantId);
    formData.append("communityType", currentCommunity.communityType);
    formData.append("communityView", value);

    try {
      await Api.setCommunityType(formData);
    } catch (e) {
      console.error(e);
    }

    // Update local state
    setCurrentCommunity((prevCommunity) => ({
      ...prevCommunity,
      communityView: value,
    }));

    // We can update the Redux store here if needed
    setInitialCommunityData((prevCommunityData) => ({
      ...prevCommunityData,
      restrictions: prevCommunityData?.restrictions?.map((community) =>
        community.tenantId === props.tenantId
          ? { ...community, communityView: value }
          : community
      ),
    }));
  };

  return (
    <>
      <Container>
        <Header>Newsletter Schedule</Header>
        <div>
          <NewLetterButtons
            title="Monthly"
            styles={{
              borderTopLeftRadius: "20px",
              borderBottomLeftRadius: "20px",
              marginLeft: "10px",
            }}
            value="Monthly"
            type={currentCommunity && currentCommunity.newsletterFrequency}
            onClick={setNewsLetterType}
          />
          <NewLetterButtons
            title="Bimonthly"
            styles={{}}
            value="Bimonthly"
            type={currentCommunity && currentCommunity.newsletterFrequency}
            onClick={setNewsLetterType}
          />
          <NewLetterButtons
            title="Weekly"
            styles={{
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
            value="Weekly"
            type={currentCommunity && currentCommunity.newsletterFrequency}
            onClick={setNewsLetterType}
          />
        </div>
        <Header>Moderator Restrictions</Header>
        <div style={{ marginLeft: "10px" }}>
          {permissionsArray.map((permission) => (
            <PermissionnContainer key={permission.id}>
              <Types>{permission.title}</Types>
              <Switch
                checked={
                  currentCommunity && currentCommunity[permission.name] === "1"
                }
                onChange={() => setModeratorPermission(permission.name)}
              />
            </PermissionnContainer>
          ))}
        </div>
      </Container>
      <Container>
        <Header>Community Type</Header>
        <ButtonContainer>
          <RadioButton
            title="Public"
            value="public"
            description="Community accessible to all members"
            setType={setCommunityType}
            type={currentCommunity && currentCommunity.communityType}
          />
          <RadioButton
            title="Private"
            value="private"
            description="Members join Community by invitation or by request"
            setType={setCommunityType}
            type={currentCommunity && currentCommunity.communityType}
          />
        </ButtonContainer>
        {currentCommunity && currentCommunity.communityType === "private" && (
          <ButtonContainer style={{ marginLeft: "50px" }}>
            <RadioButton
              title="View Only"
              value="view"
              description="Non members can view all activity and content"
              setType={setCommunityView}
              type={currentCommunity.communityView}
            />
            <RadioButton
              title="Hide"
              value="hide"
              description="Community hidden from all non members"
              setType={setCommunityView}
              type={currentCommunity.communityView}
            />
          </ButtonContainer>
        )}
      </Container>
    </>
  );
};

const RadioButton = (props) => {
  const { type, value, setType, title, description } = props;
  return (
    <div style={{ display: "flex", marginBottom: "10px" }}>
      <Radio
        checked={type === value}
        onClick={() => setType(value)}
        sx={{
          marginTop: "-16px",
        }}
        size="small"
      />
      <div>
        <Typography style={{ fontSize: "14px" }}>{title}</Typography>
        <Types style={{ color: "#444444" }}>{description}</Types>
      </div>
    </div>
  );
};

const NewLetterButtons = (props) => {
  const { title, value, type, styles, onClick } = props;
  const isActive = type?.toLowerCase() === value?.toLowerCase();
  return (
    <Button
      type="button"
      style={{
        ...styles,
        border: "1px solid",
        fontSize: "14px",
        fontWeight: "400",
      }}
      className={isActive ? classes.activeTab : classes.inactiveTab}
      onClick={() => onClick(value)}
    >
      {title}
    </Button>
  );
};

export default CommunityRules;
