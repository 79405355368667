import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  PolylineF,
  DrawingManager,
  Circle,
  Polygon,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import LocationIcon from "src/Assets/markers/location.png";
import LocationActiveIcon from "src/Assets/markers/locationActive.png";
import customMapStyles from "./customMapStyles";
import { libraries } from "../constants";
import useStyles from "./styles";

const containerStyle = {
  width: "100%",
  height: "80vh",
};

const indiaCenter = {
  lat: 20.5937,
  lng: 78.9629,
};

const usCenter = {
  lat: 39.30071338365015,
  lng: -105.22967386366022,
};

const ausCenter = {
  lat: -25.358054473007794,
  lng: 133.78014455113046,
};

const jCenter = {
  lat: 36.58555063657604,
  lng: 139.28893315677468,
};

const mCenter = {
  lat: 23.675383823109872,
  lng: -102.37291391348354,
};

const moCenter = {
  lat: 46.70860451049151,
  lng: 103.63960601861254,
};

const scCenter = {
  lat: 57.080130805793296,
  lng: -4.337505014321898,
};

const siCenter = {
  lat: 1.312163183490345,
  lng: 103.81853278581212,
};

const Map = ({
  markers,
  country,
  countryCode,
  selectedItem,
  setMap,
  map,
  showTooltip,
  selectedMarker,
}) => {
  console.log("markers", markers);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAXEHjz5uTArWEC0q8-zpu_xXuHXJ2zOl8",
    libraries: libraries,
  });

  const { googleMapStyle } = useStyles();
  // const [map, setMap] = useState(null);
  // const [showTooltip, setShowTooltip] = useState(false);
  // const [selectedMarker, setSelectedMarker] = useState('');

  // const findLocation = async () => {
  //     if (window.google && window.google.maps) {
  //         const { Geocoder } = await window.google.maps.importLibrary("geocoding")
  //         // let geocoder = new window.google.maps.Geocoder();
  //         Geocoder.geocode({ 'address': country }, function (results, status) {
  //             if (status == window.google.maps.GeocoderStatus.OK) {
  //                 map.setCenter(results[0].geometry.location);
  //             }
  //         });
  //     }
  // }

  // useEffect(() => {
  //     if (country) {
  //         findLocation()
  //     }
  // }, [country]);

  const getMapTypeControls = () => {
    const defaultMapOptions = {
      styles: customMapStyles,
    };

    return {
      ...defaultMapOptions,
      mapTypeControl: false,
      rotateControl: false,
      fullscreenControl: false,
      zoomControl: true,
      streetViewControl: false,
      disableDefaultUI: false,
    };
  };

  const handlePolygonComplete = () => {};

  // const handleMouseOver = (index) => {
  //     setShowTooltip(true)
  //     setSelectedMarker(index)
  // }

  // const handleMouseOut = () => {
  //     setShowTooltip(false)
  //     setSelectedMarker('')
  // }

  const options = {
    drawingControl: false,
    drawingControlOptions: {
      drawingModes: [
        window &&
          window.google &&
          window.google.maps &&
          window.google.maps.drawing &&
          window.google.maps.drawing.OverlayType.POLYGON,
      ],
    },
    polygonOptions: {
      fillColor: "#707070",
      fillOpacity: 0.5,
      strokeWeight: 1,
      strokeColor: "#373334",
      clickable: false,
      editable: false,
      geodesic: false,
      visible: true,
      zIndex: 1,
    },
  };
  // selectedItem === singleMarker?.tenantId ? LocationActiveIcon : LocationActiveIcon
  const getMarkerIcon = (id) => {
    return selectedItem === id ? LocationActiveIcon : LocationIcon;
  };

  const getMapCenter = () => {
    if (country) {
      return {
        lat: !isNaN(+country?.latitude) ? +country?.latitude : 0,
        lng: !isNaN(+country?.longitude) ? +country?.longitude : 0,
      };
    }
    return indiaCenter;
  };

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        // center={markers?.length > 0 ? map?.panTo(markers && markers[0]?.location) : center}
        center={getMapCenter()}
        zoom={4}
        onLoad={setMap}
        options={getMapTypeControls()}
        mapContainerClassName={googleMapStyle}
        key={countryCode}
      >
        <DrawingManager
          drawingMode={null}
          onPolygonComplete={handlePolygonComplete}
          options={options}
        />
        {markers?.map((singleMarker, index) => {
          return (
            <>
              <Marker
                icon={{
                  url: getMarkerIcon(singleMarker?.tenantId),
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                position={singleMarker?.location}
                key={index}
                // onMouseOver={() => handleMouseOver(index)}
                // onMouseOut={handleMouseOut}
                zIndex={selectedItem === singleMarker?.tenantId ? 1000 : 100}
              >
                {showTooltip &&
                  singleMarker?.location &&
                  selectedMarker === singleMarker?.tenantId && (
                    <InfoWindow key={index} position={singleMarker?.location}>
                      <div>
                        <div>{singleMarker?.communityName}</div>
                      </div>
                    </InfoWindow>
                  )}
              </Marker>
              {selectedItem === singleMarker?.tenantId &&
                singleMarker?.area?.length >= 3 && (
                  <Polygon
                    key={index}
                    path={singleMarker?.area}
                    options={{
                      fillColor: "#707070",
                      fillOpacity: 0.5,
                      strokeWeight: 1,
                      strokeColor: "#373334",
                      clickable: false,
                      editable: false,
                      geodesic: false,
                      visible: true,
                      zIndex: 1,
                    }}
                  />
                )}
            </>
          );
        })}
      </GoogleMap>
    )
  );
};

export default Map;
