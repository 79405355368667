import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./Invitations/invitations.css";
import { useDispatch, useSelector } from "react-redux";
import * as moderatorActions from "../../store/actions/Moderator/moderatoractions";
import axios from "axios";
import * as Cookies from "js-cookie";
import { Checkbox, InputAdornment } from "@mui/material";
import Searchicon from "src/Assets/images/search.svg";
import Input from "@material-ui/core/Input";
import MessageModal from "../utils/messageModal";

function InviteFollowers(props) {
  const data = useSelector(
    (state) => state.PrivateTagsReducer.PrivateTagsData || []
  );

  const privateTagData = data.find((item) => item.messageId === props.msgid);
  const messageId = privateTagData?.messageId;
  const [membersList, setMembersList] = useState([]);
  const [isInvited, setInvite] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [messageShow, setMessageShow] = useState(false);
  const [response, setResponse] = useState("");
  const [filteredMembersList, setFilteredMembersList] = useState([]);
  const dispatch = useDispatch();
  const src = process.env.REACT_APP_SKOPIC_IMAGE;

  const getCommunityMembers = () => {
    const config = {
      headers: {
        "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Set-Cookie": Cookies.get("JSESSIONID"),
      },
      withCredentials: true,
    };
    let data = new FormData();
    data.append("messageId", messageId);
    axios
      .post(
        `${process.env.REACT_APP_SERVICES_API}jsonuser/members-of-community`,
        data,
        config
      )
      .then((res) => {
        setMembersList(res.data.communityMemberList);
        setFilteredMembersList(res.data.communityMemberList);
      });
  };

  useEffect(() => {
    if (props.invite) {
      dispatch(
        moderatorActions.fetchPrivateTagsData(
          `tenantID=${props.tenantid}&limiter=PRI&startlimit=0`
        )
      );
    }
  }, [props.invite]);

  useEffect(() => {
    if (messageId) {
      getCommunityMembers();
    }
  }, [messageId]);

  // useEffect(() => {
  //   console.log("isInvited", isInvited);
  // }, [isInvited]);

  const handleChange = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setInvite((invites) => [...invites, Number(id)]);
    } else {
      setInvite(isInvited.filter((invitedId) => invitedId !== Number(id)));
    }
  };

  const handleInviteAll = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      membersList.map((member) => {
        setInvite((invites) => [...invites, member.User_ID]);
      });
    } else {
      setInvite([]);
    }
  };

  const handleSearchInput = (e) => {
    if (e.target.value === "") {
      setFilteredMembersList([...membersList]);
    } else {
      const result = membersList.filter((member) => {
        return member.displayName
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase());
      });
      setFilteredMembersList(result);
    }
  };

  const closeInviteHandler = () => {
    props.onHide();
    setMessageShow(true);
  };

  const onInviteHandler = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Set-Cookie": Cookies.get("JSESSIONID"),
      },
      withCredentials: true,
    };
    let data = new FormData();
    data.append("messageId", messageId);
    data.append("tenantId", props.tenantid);
    data.append("userIds", isInvited.toString());
    data.append("inviteType", "invite");
    axios
      .post(
        `${process.env.REACT_APP_SERVICES_API}jsonuser/follow-private-hashtag`,
        data,
        config
      )
      .then((res) => {
        setResponse(res.data.status);
        setInvite([]);
        setFilteredMembersList([...membersList]);
        setSelectAll(false);
      });
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        arialabelledby="contained-modal-title-vcenter"
        centered
        className="InviteStyles"
      >
        <div className="Invitation-modal">
          <Modal.Header closeButton onClick={props.onHide}>
            <Modal.Title id="contained-modal-title-vcenter" className="title">
              Invite members
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {filteredMembersList && Array.isArray(filteredMembersList) ? (
              <form onSubmit={onInviteHandler}>
                <div className="SearchInput">
                  <Input
                    id="search"
                    placeholder="search by name"
                    fullWidth
                    onChange={(e) => handleSearchInput(e)}
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          src={Searchicon}
                          alt="search"
                          style={{ marginLeft: "20px" }}
                        />
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="InviteAllMembers">
                  <label>
                    <Checkbox
                      onChange={(e) => handleInviteAll(e)}
                      id="select-all"
                      label="select-all"
                      checked={selectAll}
                    />
                    Select All
                  </label>
                </div>
                <div className="MemberList">
                  {filteredMembersList.map((member) => (
                    <div className="InviteMember">
                      <label key={member.User_ID}>
                        <Checkbox
                          onChange={(e) => handleChange(e)}
                          id={member.User_ID}
                          label={member.displayName}
                          checked={isInvited.includes(member.User_ID)}
                        />
                        <img
                          src={`${src}${member.userImage}`}
                          alt={member.displayName}
                          className="userImg"
                        />
                        {member.displayName}
                      </label>
                    </div>
                  ))}
                </div>
                <div class="invite-align-parent">
                  <button
                    type="submit"
                    className="Invite-align"
                    onClick={closeInviteHandler}
                    disabled={isInvited.length >= 1 ? false : true}
                  >
                    Invite
                  </button>
                </div>
              </form>
            ) : (
              <p className="nodata">No data found</p>
            )}
          </Modal.Body>
        </div>
      </Modal>
      <MessageModal
        show={messageShow}
        onHide={() => setMessageShow(false)}
        message={response}
      />
    </>
  );
}

export default InviteFollowers;
