import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LocationUpdate from "../Components/DefaultCommunity/locationUpdate";
import ModeratorLocationIcon from "src/Assets/images/moderatorLocationIcon.svg";
import "./index.css";

const EditLocation = (props) => {
  const { postLattitude, postLongitude, onLocationChange } = props;
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const handleLocationSubmit = (lat, lng, address) => {
    if (onLocationChange) {
      onLocationChange(
        selectedAddress || address,
        latitude || lat,
        longitude || lng
      );
    }
    setDialogOpen(false);
  };

  return (
    <div>
      <img
        src={ModeratorLocationIcon}
        onClick={() => setDialogOpen(true)}
        className="location-icon"
        alt="location-icon"
      />
      <LocationUpdate
        // data={postData}
        open={isDialogOpen}
        setOpen={setDialogOpen}
        isLocationSelect={setSelectedAddress}
        setLng={setLongitude}
        setLat={setLatitude}
        id="unique-dialog-id"
        onUpdatedLocationSubmit={handleLocationSubmit}
        edit={true}
        isLat={postLattitude}
        isLng={postLongitude}
      />
    </div>
  );
};

export default EditLocation;
