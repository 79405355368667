// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  background-color: #ffffff;
  margin-left: 26px;
  width: 95%;
  border: 1px solid #c4c4c4;
  border-radius: 28px;
  margin-bottom: 5px;
  padding: 5px 25px;
}

.input-container::placeholder {
  color: #a0a0a0 !important; /* Set placeholder text color */
  font-size: 10px !important; /* Set placeholder text size */
  font-weight: 400 !important; /* Optional: Set font weight */
}

.edit-icon {
  margin-right: 15px;
  height: 22px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/DefaultCommunity/viewActivityBeforeIn/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,UAAU;EACV,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB,EAAE,+BAA+B;EAC1D,0BAA0B,EAAE,8BAA8B;EAC1D,2BAA2B,EAAE,8BAA8B;AAC7D;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".input-container {\n  background-color: #ffffff;\n  margin-left: 26px;\n  width: 95%;\n  border: 1px solid #c4c4c4;\n  border-radius: 28px;\n  margin-bottom: 5px;\n  padding: 5px 25px;\n}\n\n.input-container::placeholder {\n  color: #a0a0a0 !important; /* Set placeholder text color */\n  font-size: 10px !important; /* Set placeholder text size */\n  font-weight: 400 !important; /* Optional: Set font weight */\n}\n\n.edit-icon {\n  margin-right: 15px;\n  height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
