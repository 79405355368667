import React, { useEffect, useState } from "react";
// import profileImg from "src/Assets/images/profileImg.png"
import Edit from "src/Assets/images/edit.svg";
import Delete from "src/Assets/images/Delete.svg";
// import Paper from "src/Assets/images/paper.svg"
// import DownArrow from "src/Assets/images/UpButton.png";
// import dot from "src/Assets/images/dot.png";
// import location from "src/Assets/images/userlocationtag.png";
import saysoutlineimage from "src/Assets/images/noofsays.svg";
import follow from "src/Assets/images/follow.svg";
import unfollow from "src/Assets/images/unfollow.svg";
// import like from "src/Assets/images/like.png";
// import unlike from "src/Assets/images/unlike.png";
import { Avatar, Typography } from "@mui/material";
import ChildSay from "./Activites/ChildSay";
import activityStyle from "src/Assets/css/Activity/activity.module.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import Location from "src/Assets/images/locationpin.png";
import API from "../../network";
import { useSelector } from "react-redux";
import ReusuableSendaMailModal from "../../Modules/Reusuablecomponents/ReusuableSendaMailModal";
import ReusuableReportDialogmodal from "../../Modules/Reusuablecomponents/ReusuableReportDialogmodal";
import SimpleDialogDemo from "../../Modules/Reusuablecomponents/CustomizedDialogs";
import shareIcon from "src/Assets/images/update-sare-icon.png";
import unShareIcon from "src/Assets/images/update-unsare-icon.png";
import IconButton from "@material-ui/core/IconButton";
import { Menu, MenuItem } from "@szhsin/react-menu";
import PostMessageValidation from "../Reusuablecomponents/PostMessageValidation";
import AvatarBadge from "src/Components/AvatarBadge";
import AsksModal from "../../pages/HomePage/components/AsksModal";
import { replaceTagAndUrl } from "src/Utils/commonUtils";
import ReusuableDeleteDialogmodal from "../Reusuablecomponents/ReusuableDeleteModal";

function ActivityPost(props) {
  const {
    decreaseHashCount,
    deleteActivityTagPost,
    decreaseActivityAskCount,
    onHashTagClick,
    deleteActivityAskPost,
    setPageNumber,
    getData
  } = props;
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;
  const [editId, setEditID] = useState(null);
  const [childSayId, setChildSayId] = useState(null);
  const [listData, setListData] = useState([]);
  const [answersToYourAsk, setAnswersToYourAsk] = useState({});
  const [SaysShow, setSaysShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageId, setMessageId] = useState(null)

  useEffect(() => {
    setListData(props.listData);
  }, [props.pageNumber, props.listData]);

  const getListData = () => {
    setListData([]);
    setPageNumber && setPageNumber(1);
    getData && getData(1);
  }

  const followunfollow = (id, followstatus, filterType) => {
    props.updateList(id, followstatus);

    let formData = new FormData();
    formData.append("messageID", id);
    formData.append("isFollow", parseInt(followstatus.split("=")[1], 10));

    API.activityFollowUnfollowData(formData);
    decreaseHashCount && decreaseHashCount();
    decreaseActivityAskCount && decreaseActivityAskCount();
    deleteActivityTagPost && deleteActivityTagPost(id);
    deleteActivityAskPost && deleteActivityAskPost(id);
  };

  const onLikeUnlikeSelection = (id, cache) => {
    props.likeUnlike(id);
    API.fetchVoteUpStatus(id, cache);
  };

  const onHideAsksModal = () => {
    setSaysShow(false);
  };

  const updateMessage = (id, message) => {
    props.updateMessage(id, message);
    cancelEdit();
  };

  const cancelEdit = () => {
    setEditID(null);
  };

  const onShareUnshare = (id, shareStatus) => {
    const newStatus = shareStatus === "1" ? "N" : "Y";
    const newListData = listData.map((item) => {
      if (item.id === id) {
        return { ...item, announceStatus: newStatus };
      } else {
        return item;
      }
    });
    setListData(newListData);
    API.shareUnshareUpdate(id, shareStatus);
  };

  const updateSayCount = (parentId, operator) => {
    const newListData = listData.map((item) => {
      if (item.id === parentId) {
        const filterType = props?.filterType;
        const key = filterType === "activityTag" ? "postCount" : "sayCount";
        const count = item[key] || 0;
        return {
          ...item,
          [key]: operator === "increment" ? count + 1 : count - 1,
        };
      } else {
        return { ...item };
      }
    });
    setListData(newListData);
  };

  const askYourCommunity = async (id) => {
    try {
      await API.askYourCommunity(id);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSaysDataForAsks = async (
    id
  ) => {
    setLoading(true);
    try {
      let formdata = new FormData();
      formdata.append("id", id);
      const response = await API.fetchAsksSearchResultData(
        formdata
      );
      setAnswersToYourAsk({ answersToYourAsk: response.data });
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const postAskHandler = (Message, msgLat, msgLng, locName, id) => {
    // let formData = new FormData();
    let lnglat;
    const postingOption = "";
    if ((msgLat !== "") & (msgLng !== "")) {
      lnglat = msgLat + "," + msgLng;
    } else {
      lnglat = "";
    }
    fetchSaysDataForAsks(id);
    setSaysShow(true);
    setMessageId(id);

    // setTimeout(() => {
    //   fetchContributors && fetchContributors();
    //   dispatch(SignInActions.fetchloginUser());
    // }, 1000);
  };

  console.log(`tag list`);
  console.log(listData);
  console.log(`answers to asks:`, answersToYourAsk);
  console.log(`tag list end`);

  return (
    <>
      {listData.map((result) => (
        <div className={activityStyle.activityPostCard} key={result.id}>
          <div className="profile-info">
            {result.Keyword_ID === "hashTagFollow" ? (
              <div
                className={activityStyle.activityPostImageDropdownMainContainer}
              >
                <div
                  className={activityStyle.activityPostImageProfileContainer}
                >
                  <div className={activityStyle.profileimg}>
                    <AvatarBadge
                      src={`${profileImagePath}${result?.mainuserdata?.uimage}`}
                      alt={result?.mainuserdata?.displayName}
                      isPrimaryModerator={result?.isPM == 1}
                      isModerator={
                        result?.isModerator == 1 && result?.isPM == 0
                      }
                    />
                  </div>
                  <div
                    style={{ flex: 1 }}
                    className={activityStyle.profilename}
                  >
                    <p>{result?.mainuserdata?.displayName}</p>
                    {
                      <div className={activityStyle.profilehover}>
                        <div
                          className={activityStyle.profilehoverimageandcount}
                        >
                          <div>
                            <AvatarBadge
                              src={`${profileImagePath}${result?.mainuserdata?.uimage}`}
                              alt={result?.mainuserdata?.displayName}
                              isPrimaryModerator={result?.isPM == 1}
                              isModerator={
                                result?.isModerator == 1 && result?.isPM == 0
                              }
                              size={35}
                            />
                          </div>
                          <div>
                            <p className={activityStyle.profilehovername}>
                              {result?.mainuserdata?.displayName}
                            </p>
                            <p className={activityStyle.profilecount}>
                              ASK {result?.mainuserdata?.askcount} | SAY{" "}
                              {result?.mainuserdata?.saycount} | UPDATE{" "}
                              {result?.mainuserdata?.updatecount} | TAG{" "}
                              {result?.mainuserdata?.hashtagcount}
                            </p>
                          </div>
                        </div>
                        <p className={activityStyle.profilehovercontent}>
                          {result?.mainuserdata?.shortBio}
                        </p>
                      </div>
                    }
                    <span className="MessgeDetils">
                      {result.Message_Time} in {result.Tenant_name}
                      {/* {(result.locName==="")?null: result.locName} */}
                    </span>
                  </div>
                </div>
                <div
                  className={
                    activityStyle.activityPostCardImagePopupMainContainer
                  }
                >
                  <Menu
                    menuButton={
                      <IconButton
                        style={{
                          padding: 0,
                          width: 30,
                          height: 30,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          outline: "none",
                        }}
                        disabled={
                          result.communityFollowStatus === 0 &&
                          result.tenantType === "private"
                        }
                      >
                        <MdKeyboardArrowDown fontSize={25} />
                      </IconButton>
                    }
                    transition
                    direction="bottom"
                    arrow
                    menuStyle={{
                      backgroundColor: "white",
                      borderRadius: 20,
                    }}
                  >
                    {result.User_ID !== userDetails?.userData?.User_ID ? (
                      <>
                        {result.moderatorStatus === "1" ? (
                          <>
                            <MenuItem>
                              <ReusuableDeleteDialogmodal
                                label={"Delete Post"}
                                type={props?.postType}
                                id={result.id}
                                deleteMessage={() => props?.onDelete && props.onDelete(result.id)}
                                fetchContributors={() => { }}
                              />
                            </MenuItem>
                            <MenuItem>
                              <ReusuableSendaMailModal
                                displayName={result.displayName}
                                id={result.id}
                              />
                            </MenuItem>
                          </>
                        ) : (
                          <>
                            <MenuItem>
                              <ReusuableReportDialogmodal
                                id={result.id}
                                param={"AskorSay"}
                              />
                            </MenuItem>
                            <MenuItem>
                              <ReusuableSendaMailModal
                                displayName={result.displayName}
                                id={result.id}
                              />
                            </MenuItem>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <MenuItem onClick={() => setEditID(result.id)}>
                          <img src={Edit} alt="edit" />
                          <span style={{ fontSize: 14, marginLeft: 8 }}>
                            Edit Post
                          </span>
                        </MenuItem>
                        <MenuItem>
                          <ReusuableDeleteDialogmodal
                            label={"Delete Post"}
                            type={props?.postType}
                            id={result.id}
                            deleteMessage={() => props?.onDelete && props.onDelete(result.id)}
                            fetchContributors={() => { }}
                          />
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            ) : (
              <div
                className={activityStyle.activityPostImageDropdownMainContainer}
              >
                <div
                  className={activityStyle.activityPostImageProfileContainer}
                >
                  <div className={activityStyle.profileimg}>
                    <AvatarBadge
                      src={`${profileImagePath}${result?.mainuserdata?.uimage}`}
                      alt={result?.mainuserdata?.displayName}
                      isPrimaryModerator={result?.isPM == 1}
                      isModerator={
                        result?.isModerator == 1 && result?.isPM == 0
                      }
                    />
                  </div>
                  <div
                    style={{ flex: 1 }}
                    className={activityStyle.profilename}
                  >
                    <p>{result?.mainuserdata?.displayName}</p>
                    {
                      <div className={activityStyle.profilehover}>
                        <div
                          className={activityStyle.profilehoverimageandcount}
                        >
                          <div>
                            <AvatarBadge
                              src={`${profileImagePath}${result?.mainuserdata?.uimage}`}
                              alt={result?.mainuserdata?.displayName}
                              isPrimaryModerator={result?.isPM == 1}
                              isModerator={
                                result?.isModerator == 1 && result?.isPM == 0
                              }
                              size={35}
                            />
                          </div>
                          <div>
                            <p className={activityStyle.profilehovername}>
                              {result?.mainuserdata?.displayName}
                            </p>
                            <p className={activityStyle.profilecount}>
                              ASK {result?.mainuserdata?.askcount} | SAY{" "}
                              {result?.mainuserdata?.saycount} | UPDATE{" "}
                              {result?.mainuserdata?.updatecount} | TAG{" "}
                              {result?.mainuserdata?.hashtagcount}
                            </p>
                          </div>
                        </div>
                        <p className={activityStyle.profilehovercontent}>
                          {result?.mainuserdata?.shortBio}
                        </p>
                      </div>
                    }
                    <div className="MessgeDetils">
                      <span>
                        {result.Message_Time} in {result.Tenant_name}
                      </span>
                      {result.locName && (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <img src={Location} style={{ height: "12px" }} />
                          <span className={activityStyle.locationContent}>
                            {result.locName}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    activityStyle.activityPostCardImagePopupMainContainer
                  }
                >
                  <Menu
                    menuButton={
                      <IconButton
                        style={{
                          padding: 0,
                          width: 30,
                          height: 30,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          outline: "none",
                        }}
                        disabled={
                          result.communityFollowStatus === 0 &&
                          result.tenantType === "private"
                        }
                      >
                        <MdKeyboardArrowDown fontSize={25} />
                      </IconButton>
                    }
                    transition
                    direction="bottom"
                    arrow
                    menuStyle={{
                      backgroundColor: "white",
                      borderRadius: 20,
                    }}
                  >
                    {result.userDetails.User_ID !==
                      userDetails?.userData?.User_ID ? (
                      <>
                        {result.moderatorStatus === "1" ? (
                          <>
                            <MenuItem>
                              <ReusuableDeleteDialogmodal
                                label={"Delete Post"}
                                type={props?.postType}
                                id={result.id}
                                deleteMessage={() => props?.onDelete && props.onDelete(result.id)}
                                fetchContributors={() => { }}
                              />
                            </MenuItem>
                            <MenuItem>
                              <ReusuableSendaMailModal
                                displayName={result.userDetails.displayName}
                                id={result.id}
                              />
                            </MenuItem>
                          </>
                        ) : (
                          <>
                            <MenuItem>
                              <ReusuableReportDialogmodal
                                id={result.id}
                                param={"AskorSay"}
                              />
                            </MenuItem>
                            <MenuItem>
                              <ReusuableSendaMailModal
                                displayName={result.userDetails.displayName}
                                id={result.id}
                              />
                            </MenuItem>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <MenuItem onClick={() => setEditID(result.id)}>
                          <img src={Edit} alt="edit" />
                          <span style={{ fontSize: 14, marginLeft: 8 }}>
                            Edit Post
                          </span>
                        </MenuItem>
                        <MenuItem>
                          <ReusuableDeleteDialogmodal
                            label={"Delete Post"}
                            type={props?.postType}
                            id={result.id}
                            deleteMessage={() => props?.onDelete && props.onDelete(result.id)}
                            fetchContributors={() => { }}
                          />
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            )}
          </div>
          <>
            <div className={activityStyle.activityPostDescriptionContainer}>
              {editId === result?.id ? (
                <PostMessageValidation
                  id={result.id}
                  Message={result.Message}
                  messageType={"feedTextArea"}
                  filtertype={props?.filterType}
                  post={result}
                  onCloseEdit={cancelEdit}
                  onMessagedUpdated={updateMessage}
                />
              ) : (
                <Typography whiteSpace="pre-wrap">
                  {replaceTagAndUrl(
                    result.Message,
                    result.msg,
                    result.tag,
                    result.url,
                    onHashTagClick
                  )}
                  {/* {result?.Message} */}
                </Typography>
              )}
            </div>
          </>

          <div className="PostCounts">
            {result.Keyword_ID === "hashTagFollow" ||
              result.Keyword_ID === "actASK" ? (
              <>
                <SimpleDialogDemo
                  followCount={result?.followCount}
                  followData={result?.follweduserprofile}
                  id={result.id}
                  // status={followData}
                  hashTagType={result?.hashTagType}
                />
              </>
            ) : (
              <>
                {" "}
                {result.voteUp !== 0 ? (
                  <SimpleDialogDemo
                    voteUp={result.voteUp}
                    id={result.id}
                    isVoteup={"true"}
                  />
                ) : (
                  <span>
                    {result.voteUp === 1
                      ? `${result.voteUp} Like`
                      : `${result.voteUp} Likes`}
                  </span>
                )}
              </>
            )}
            {result.communityFollowStatus === 0 &&
              result.tenantType === "private" && (
                <p className={activityStyle.activityPostWarning}>
                  This is a private community, No action is permitted for non
                  member
                </p>
              )}
          </div>

          <div className="PostActions">
            {result.Keyword_ID === "hashTagFollow" ||
              result.Keyword_ID === "actASK" ? (
              <>
                {result?.isOpeASk === "0" ? (
                  <div className={activityStyle.activityAskPostButtonContainer}>
                    <button
                      className={activityStyle.activityAskPostButton}
                      type="button"
                      onClick={() =>
                        postAskHandler(
                          result.Message,
                          result.msgLat,
                          result.msgLng,
                          result.locName,
                          result.id
                        )
                      }
                    >
                      POST
                    </button>
                    <AsksModal
                      loading={loading}
                      // childSaysLoading={childSaysLoading}
                      show={SaysShow}
                      onHide={onHideAsksModal}
                      isPostOption={"ASK"}
                      askType={"ActivityAskPost"}
                      // setPostOption={setPostOption}
                      setSaysShow={setSaysShow}
                      answersToYourAsk={answersToYourAsk?.answersToYourAsk}
                      // asksToBeAnswered={asksToBeAnswered}
                      askYourCommunity={askYourCommunity}
                      messageId={messageId}
                      getListData={getListData}
                    // fetchFeedData={fetchFeedData}
                    // postChildSayData={postChildSayData}
                    // deleteMessage={deleteMessage}
                    // filterPosts={filterPosts}
                    // childSaysData={childSaysData}
                    // fetchChildSaysData={fetchChildSaysData}
                    // fetchFollowData={fetchFollowData}
                    // fetchVoteUpStatus={fetchVoteUpStatus}
                    // deleteAnswersToAsksDialogPost={deleteAnswersToAsksDialogPost}
                    // deleteAsksToBeAnsweredDialogPost={
                    //   deleteAsksToBeAnsweredDialogPost
                    // }
                    // fetchContributors={fetchContributors}
                    // onHashTagClick={onHashTagClick}
                    />
                  </div>
                ) : (
                  <>
                    <div className="PostActions--Following">
                      <div
                        style={{ cursor: "pointer", marginTop: "5px" }}
                        onClick={(e) => {
                          if (
                            result.communityFollowStatus === 0 &&
                            result.tenantType === "private"
                          ) {
                            return; // Preventing the function from running
                          }
                          e.stopPropagation();
                          setChildSayId(
                            result.id === childSayId ? null : result.id
                          );
                        }}
                      >
                        <img src={saysoutlineimage} alt="numberof says" />{" "}
                        {result.sayCount === 1 || result.postCount === 1 ? (
                          <span>
                            {props?.filterType === "activityTag"
                              ? result?.postCount
                              : result?.sayCount}{" "}
                            SAY
                          </span>
                        ) : result.sayCount === 0 || result.postCount === 0 ? (
                          <span>SAY</span>
                        ) : (
                          <span>
                            {props?.filterType === "activityTag"
                              ? result?.postCount
                              : result?.sayCount}{" "}
                            SAYs
                          </span>
                        )}
                      </div>
                      <div style={{ cursor: "pointer", marginTop: "5px" }}>
                        {result.followStatus === "Active" ||
                          result.followStatus === "Y" ? (
                          <div
                            id={`1${result.id}`}
                            onClick={(e) => {
                              if (
                                result.communityFollowStatus === 0 &&
                                result.tenantType === "private"
                              ) {
                                return; // Preventing the function from running
                              }
                              e.stopPropagation();
                              followunfollow(
                                result.id,
                                "isFollow=0",
                                props?.filterType
                              );
                            }}
                          >
                            <img src={unfollow} alt="UnFolow" />
                            <span>Unfollow </span>
                          </div>
                        ) : (
                          <div
                            id={`0${result.id}`}
                            onClick={(e) => {
                              if (
                                result.communityFollowStatus === 0 &&
                                result.tenantType === "private"
                              ) {
                                return; // Preventing the function from running
                              }
                              e.stopPropagation();
                              followunfollow(
                                result.id,
                                "isFollow=1",
                                props?.filterType
                              );
                            }}
                          >
                            <img src={follow} alt="Folow" />
                            <span>Follow </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div
                  style={{
                    cursor: "pointer",
                    marginTop: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {result.announceStatus === "Y" ? (
                    <button
                      onClick={() => onShareUnshare(result.id, "1")}
                      className={activityStyle.activityPostButtonContainer}
                      type="button"
                      disabled={
                        result.communityFollowStatus === 0 &&
                        result.tenantType === "private"
                      }
                    >
                      <>
                        <img src={shareIcon} alt="shareImage" />
                        <p>Share</p>
                      </>
                    </button>
                  ) : (
                    <button
                      onClick={() => onShareUnshare(result.id, "0")}
                      className={activityStyle.activityPostButtonContainer}
                      type="button"
                      disabled={
                        result.communityFollowStatus === 0 &&
                        result.tenantType === "private"
                      }
                    >
                      <>
                        <img src={unShareIcon} alt="unShareImage" />
                        <p>Unshare</p>
                      </>
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
          <div style={{ position: "relative" }}>
            {childSayId === result.id && (
              <ChildSay
                key={childSayId}
                id={result?.id}
                item={result}
                updateSayCount={updateSayCount}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default ActivityPost;
