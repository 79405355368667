import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { styled } from '@material-ui/core/styles';
import primaryModerator from 'src/Assets/images/headers/primarymoderator.svg';
import moderator from 'src/Assets/images/headers/moderator.svg';

const defaultStyle = {
  borderRadius: '40%',
  width: '50px',
  height: '50px'
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 43,
    right: 8,
    padding: '0 4px',
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const AvatarBadge = ({ src, alt, isPrimaryModerator, isModerator, isChild, size }) => {
  const crown = isPrimaryModerator
    ? primaryModerator
    : isModerator
      ? moderator
      : "";

  const avatarStyle = size
    ? { ...defaultStyle, width: size, height: size }
    : { ...defaultStyle };

  return (
    <>
      {isChild ? (
        <StyledBadge
          badgeContent={
            crown
              ? <SmallAvatar alt="crown" src={crown} />
              : null
          }>
          <Avatar
            src={src}
            style={avatarStyle}
            variant="square"
            alt={alt || "Avatar"}
          />
        </StyledBadge>
      ) : (
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            crown
              ? <SmallAvatar alt="crown" src={crown} />
              : null
          }
        >
          <Avatar
            src={src}
            style={avatarStyle}
            variant="square"
            alt={alt || "Avatar"}
          />
        </Badge>
      )}
    </>
  )
};

export default AvatarBadge;
