import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import feedstyles from "src/Assets/css/home/feedpost.module.css";
import sampledialogcancel from "src/Assets/images/Add.png";
import dot from "src/Assets/images/dot.png";
import { blue } from "@material-ui/core/colors";
import { Divider } from "@material-ui/core";
import API from '../../network';
import AvatarBadge from "src/Components/AvatarBadge";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  // const classes = useStyles();
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(false);
  };

  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;
  // var profileimagelocalPath = "http://localhost:8080/skopicimage";

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      key={`simpleDialog${props.id}`}
      maxWidth='md'
      PaperProps={{
        style: { borderRadius: 20 }
      }}
    >
      <div className={feedstyles.materialdialog}>
        {props.Followers ? (
          <div className={feedstyles.sampledialogcountandcancel}>
            <p className={feedstyles.sampledialogtotalcount}>
              <b>{props.Followers} following</b>
            </p>
            <a onClick={handleClose}>
              <img
                src={sampledialogcancel}
                style={{
                  backgroundColor: "#F3F2F0",
                  borderRadius: "50%",
                  padding: "8%",
                }}
              />
            </a>
          </div>
        ) : (
          <div className={feedstyles.sampledialogcountandcancel}>
            <p className={feedstyles.sampledialogtotalcount}>
              {props.likes === 1 ? (
                <b>{props.likes} Like</b>
              ) : (
                <b>{props.likes} Likes</b>
              )}
            </p>
            <a onClick={handleClose}>
              <img src={sampledialogcancel} />
            </a>
          </div>
        )}
        {props.followersData ? (
          props.followersData.map((followersresult, index) => (
            <>
              <div key={`followersData${index}${followersresult?.saycount}`} className={feedstyles.followersmodal}>
                <AvatarBadge
                  src={`${profileImagePath}${followersresult.uimage}`}
                  className={feedstyles.sampledialogvatar}
                  isPrimaryModerator={followersresult.isPM == 1}
                  isModerator={followersresult.isModerator == 1 && followersresult.isPM == 0}
                />
                <div style={{ marginLeft: '10px' }}>
                  <p className={feedstyles.sampledialogdisplayname}>
                    {followersresult.displayName}
                  </p>
                  <p className={feedstyles.sampledialgoxtext}>
                    ASK {followersresult.askcount} | SAY{" "}
                    {followersresult.saycount} | UPDATE{" "}
                    {followersresult.updatecount} | TAG{" "}
                    {followersresult.hashtagcount}
                  </p>
                </div>
                {/* <Divider/> */}
              </div>
              <Divider className={feedstyles.divider} />
            </>
          ))
        ) : props.likesData ? (
          props.likesData.map((likesresult, index) => (
            <>
              <div key={`likesData${index}${likesresult?.saycount}`} className={feedstyles.followersmodal}>
                <AvatarBadge
                  src={`${profileImagePath}${likesresult.uimage}`}
                  className={feedstyles.sampledialogvatar}
                  isPrimaryModerator={likesresult.isPM == 1}
                  isModerator={likesresult.isModerator == 1 && likesresult.isPM == 0}
                />
                <div style={{ marginLeft: '10px' }}>
                  <p className={feedstyles.sampledialogdisplayname}>
                    {likesresult.displayName}
                  </p>
                  <p className={feedstyles.sampledialgoxtext}>
                    ASK {likesresult.askcount} | SAY {likesresult.saycount} |
                    UPDATE {likesresult.updatecount} | TAG{" "}
                    {likesresult.hashtagcount}
                  </p>
                </div>
                {/* <Divider/> */}
              </div>
              <Divider className={feedstyles.divider} />
            </>
          ))
        ) : (
          <span>NO Data</span>
        )}
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function SimpleDialogDemo(props) {
  // const followerCountData = useSelector(
  //   (state) => state.followReducer.FollowerCountData
  // );
  const [followerCountData, setFollowerCountData] = useState(0);

  // const [isCountData, setCountData] = useState(props.voteUp);
  // const [isFollowCountData, setFollowCountData] = useState(props.followCount);

  // useEffect(() => {
  //         // if (props.isVoteup==="1") {
  //         //     setCountData(isCountData + 1)
  //         // }
  //         // console.log(props.isVoteup)
  // if(props.isFollow==="followed")
  // {
  //     setFollowCountData(isFollowCountData+1)
  // }
  //     else if(props.isFollow==="followed"{
  //         setFollowCountData(isFollowCountData-1)
  //     }
  // console.log(isFollowCountData)
  // }, []);
  // console.log(followerCountData)
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = async (id, type) => {
    setOpen(true);
    // dispatch(feedactions.fetchFollowerCount(id, type));
    const response = await API.fetchFollowerCount(id, type);
    if (response) {
      setFollowerCountData(response.data);
    }
  };

  const handleClose = () => {
    setOpen(false);
    // setCountData(prev=>prev+1)
  };

  return (
    <div key={`followerorlikes${props.id}`}>
      {props.followCount || props.followCount === 0 ? (
        <a
          onClick={() => {
            if (props.followCount > 0) {
              handleClickOpen(props.id, "follow")
            }
          }}
          // id={props.id}
          className={feedstyles.followerbgcolor}
        >
          {props.followCount === 1 ? `${props.followCount} follower` : `${props.followCount} followers`}
        </a>
      ) : (
        <a
          variant="outlined"
          color="primary"
          onClick={() => {
            if (props.voteUp > 0)
              handleClickOpen(props.id, "like")
          }}
          // id={props.id}
          className={feedstyles.followerbgcolor}
        >
          {props.voteUp === 1 ? `${props.voteUp} like` : `${props.voteUp} likes`}
        </a>
      )}

      {props.hashTagType === "private" && (
        <span className={feedstyles.hashTagType}>
          <span>
            <img src={dot} alt="dot" />
          </span>{" "}
          Private
        </span>
      )}

      {!!followerCountData && Object.keys(followerCountData).length !== 0 && (
        <SimpleDialog
          open={open}
          onClose={handleClose}
          Followers={props.followCount}
          followersData={followerCountData.follweduserprofile}
          key={props.id}
          likes={props.voteUp}
          likesData={followerCountData.likeduserprofile}
        />
      )}
    </div>
  );
}
