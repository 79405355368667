import React from "react";
import { useGoogleLogin } from "react-google-login";
import { useHistory } from "react-router-dom";
import Api from "src/network";
import { GoogleButton } from "./SocialButtons";
const clientId = "1096351929813-b3sqsgi9t95dfbr7jhsruvsfvh8q6iac.apps.googleusercontent.com";

function GoogleSignIn() {
  const history = useHistory();

  const onSuccess = async (res) => {
    console.log(res)
    const googleresponse = {
      Name: res.profileObj.name,
      email: res.profileObj.email,
      token: res.tokenId,
      Image: res.profileObj.imageUrl,
      ProviderId: "Google",
    };

    try {
      const result = await Api.validateGoogleToken(googleresponse.token);
      const { status } = result?.data || {};
      if (status && String(status).toLowerCase() === ("Thank you for Signing using your Gmail account").toLowerCase()) {
        await Api.getUserData(result.data?.userTenantId, result.data?.userTenant);
        let responseJson = result;
        sessionStorage.setItem("userData", JSON.stringify(result));
        history.push("/Home");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onFailure = (res) => {
    console.log("Login failed: res:", res);
    // alert(`Failed to login. `);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    clientId,
    onFailure,
  });

  return (
    <GoogleButton onClick={() => signIn()} />
  );
}

export default GoogleSignIn;
