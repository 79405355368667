
import { Modal } from "react-bootstrap";
// import styles from 'src/Assets/css/settings/Modal.css';

function TwitterModal(props) {
  const url = process.env.REACT_APP_SERVICES_API + 'twitter/redirecttoTwitter.htm';
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="SocailModals"
    >
      <Modal.Body>
        <p>You want to tweet on Twitter</p>
        <div>
          <a href={url} target="_self"><button>Continue</button></a>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default TwitterModal;
