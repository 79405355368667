import React, { useEffect, useState, useRef, useCallback } from "react";
import { Typography } from "@mui/material";
import Upadate from "src/Assets/images/update.svg";
import DownArrow from "src/Assets/images/UpButton.png";
import "./UpdateActtivity.css";
import ActivityPost from "../../ActivityPost";
import API from "../../../../network";
import ConfirmationDialog from "src/Components/ConfirmationDialog";

function UpdateActtivity() {
  const [isListOpen, setisListOpen] = useState(false);
  const [listData, setListData] = useState([]);
  const [breadCountLength, setBreadCountLength] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [completeUpdateListData, setCompleteUpdateListData] = useState({});
  const listRef = useRef(null);
  const [confModalVisible, setConfModalVisible] = useState(null);

  const toggleList = () => {
    setisListOpen(true);
  };

  const handleScroll = useCallback(() => {
    const { scrollTop, clientHeight, scrollHeight } = listRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  }, []);

  // const getData = (page) => {
  //   API.getUserUpdates(breadCountLength, page).then((res) => {
  //     setListData((prevList) => [...prevList, ...res.data.announcements]);
  //     setCompleteUpdateListData(res.data);
  //     setBreadCountLength(
  //       (prevBreadLength) => prevBreadLength + res.data.announcements.length
  //     );
  //   });
  // };

  const getData = async (page) => {
    try {
      const res = await API.getUserUpdates(breadCountLength, page);

      if (res?.data?.announcements && res.data.announcements.length > 0) {
        setListData((prevList) => [...prevList, ...res.data.announcements]);
        setBreadCountLength(
          (prevBreadLength) => prevBreadLength + res.data.announcements.length
        );
      } else {
        console.warn("No announcements found.");
      }

      setCompleteUpdateListData(res.data || {});
    } catch (error) {
      console.error("Error fetching data:", error);
      // alert("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber]);

  const decreaseUpdateListCount = () => {
    setCompleteUpdateListData((prevData) => ({
      ...prevData,
      announcementsSize: prevData.announcementsSize - 1,
    }));
  };

  const onClose = () => {
    setConfModalVisible(null);
  };

  const confirmationForDelete = (id) => {
    setConfModalVisible({
      open: true,
      type: "warning",
      title: "Are you sure you want to delete this UPDATE?",
      rejectLabel: "Cancel",
      acceptLabel: "Yes",
      onReject: onClose,
      onAccept: () => deleteTag(id),
    });
  };

  const likeUnlike = (id) => {
    const newData = [...listData];
    const modifiedData = newData.find((x) => x.id === id);
    modifiedData.UserLikeStatus = "InActive";
    modifiedData.voteUp = modifiedData.voteUp - 1 + 2;
    setListData(newData);
  };

  const deleteTag = (id) => {
    let formdata = new FormData();
    formdata.append("messageID", id);
    API.deleteProfilePost(formdata)
      .then((res) => {
        const newList = [...listData];
        const index = newList.findIndex((x) => x.id === id);
        if (index > -1) {
          newList.splice(index, 1);
          setListData(newList);
          decreaseUpdateListCount();
        }
        setConfModalVisible(null);
      })
      .catch((err) => {});
  };

  const updateMessage = (id, message) => {
    const newData = [...listData].map((eachItem) => {
      if (eachItem?.id === id) {
        return {
          ...eachItem,
          Message: message,
        };
      }
      return { ...eachItem };
    });
    setListData(newData);
    // const newData = [...listData];
    // const modifiedData = newData.find((x) => x.id === id);
    // let formdata = new FormData();
    // formdata.append("messageID", id);
    // formdata.append("message", message);
    // API.updateProfilePost(modifiedData.locName, formdata)
    //   .then((res) => {
    //     modifiedData.Message = message;
    //     setListData(newData);
    //   })
    //   .catch((err) => {});
  };

  return (
    <>
      {!isListOpen && (
        <div className="UpdateActtivity" onClick={toggleList}>
          <div className="usable-container">
            <img src={Upadate} alt="Ask-icon" className="askicon" />
            <Typography className="ask">UPDATE</Typography>
          </div>
          <div className="usable-container">
            <span className="number">
              {completeUpdateListData.announcementsSize}
            </span>
            <img src={DownArrow} alt="DownArrow" className="DownArrow" />
          </div>
        </div>
      )}

      {isListOpen && (
        <div className="AskActivity_active">
          <div
            className="color-combination"
            onClick={() => setisListOpen(false)}
          >
            <div className="usable-container">
              <img src={Upadate} alt="Ask-icon" className="askicon" />
              <Typography className="ask">UPDATE</Typography>
            </div>
            <div className="usable-container">
              <span className="number">
                {completeUpdateListData.announcementsSize}
              </span>
              <img src={DownArrow} alt="DownArrow" className="DownArrow" />
            </div>
          </div>
          <div
            ref={listRef}
            onScroll={handleScroll}
            role="list"
            className="posts"
          >
            {listData && listData.length !== 0 ? (
              <ActivityPost
                listData={listData}
                updateMessage={updateMessage}
                delete={confirmationForDelete}
                likeUnlike={likeUnlike}
                pageNumber={pageNumber}
                filterType="activityUpdate"
              />
            ) : (
              <div className="no-data-container">
                <p>No Activity Updates found</p>
              </div>
            )}
          </div>
        </div>
      )}
      {confModalVisible?.open && <ConfirmationDialog {...confModalVisible} />}
    </>
  );
}

export default UpdateActtivity;
