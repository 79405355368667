import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from "@material-ui/core/Checkbox";
import sampledialogcancel from "src/Assets/images/Add.png";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import feedstyles from "src/Assets/css/home/feedpost.module.css";
import report from "src/Assets/images/report.svg";
import API from "../../network";

const defaultState = {
  OffensiveorInappropriate: false,
  Unrelated: false,
  duplicate: false,
  other: false,
};

function ReportDialog(props) {
  const [state, setState] = useState({ ...defaultState });
  const [charCount, setCharCount] = useState(0);
  const [reason, setReason] = useState("");
  const [isReportParams, setReportParams] = useState("");
  const { onClose, open } = props;

  useEffect(() => {
    setCharCount(0);
    setState({ ...defaultState });
    setReason("");
  }, []);

  useEffect(() => {
    let msg = reason || "";
    // msg = msg.replace(/\n/g, "");
    // msg = msg.replace(/\s/g, "");
    const count = msg.length;
    setCharCount(count);
  }, [reason]);

  const handleClose = () => {
    onClose(false);
  };

  const onTextChange = (value) => {
    // let reportpostid = `report${id}`
    // let buttonid = `button${id}`
    setReason(value);
    // if (document.getElementById(reportpostid).value !== '') {
    //     document.getElementById(buttonid).disabled = false
    //     document.getElementById(buttonid).style.backgroundColor = "#127ADD"
    // } else {
    //     document.getElementById(buttonid).disabled = true
    //     document.getElementById(buttonid).style.backgroundColor = "#60A2E0"
    // }
  };

  const handleChange = (event, id) => {
    const name = event?.target?.name;
    const checked = event?.target?.checked;
    setState({
      ...defaultState,
      [name]: checked,
    });
    setReason(name != "other" ? name : "");

    // setState({ ...state, [event.target.name]: event.target.checked });
    // let reportother = `report${id}`
    // let buttonid = `button${id}`
    // if (event.target.name === "other") {
    //     if (event.target.checked === true) {
    //         document.getElementById(reportother).style.display = "block"
    //     } else {
    //         document.getElementById(reportother).style.display = "none"
    //     }
    // }
    // if (event.target.name === "OffensiveorInappropriate" || event.target.name === "Unrelated" || event.target.name === "duplicate") {
    //     if (event.target.checked === true) {
    //         document.getElementById(buttonid).disabled = false
    //         document.getElementById(buttonid).style.backgroundColor = "#127ADD"
    //         setReason(event.target.name)
    //     } else {
    //         document.getElementById(buttonid).disabled = true
    //         document.getElementById(buttonid).style.backgroundColor = "#60A2E0""#127ADD"
    //     }
    // }
  };

  const onReportSubmit = async (id) => {
    // setReportParams(`?messageid=${id}&reason=${reason}&param=AskorSay`)
    // console.log(isReportParams)
    // dispatch(feedactions.fetchReportData(isReportParams))
    await API.reportPost(id, reason);
    handleClose();
  };

  const isEnabled = () => {
    if (state?.other) {
      return reason && charCount > 0 && charCount <= 280;
    }
    return state.OffensiveorInappropriate || state.Unrelated || state.duplicate;
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div className={feedstyles.reportdialog}>
        {props.statusdata === "notAbused" ? (
          <>
            <div className={feedstyles.sampledialogcountandcancel}>
              <p className={feedstyles.reportheading}>
                <b>Report this post</b>
              </p>
              <a onClick={handleClose}>
                <img src={sampledialogcancel} />
              </a>
            </div>

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.OffensiveorInappropriate}
                    onChange={(e) => handleChange(e, props.id)}
                    name="OffensiveorInappropriate"
                    color="primary"
                  />
                }
                label={
                  <p className={feedstyles.reportoption}>
                    Offensive or Inappropriate
                  </p>
                }
                className={feedstyles.offensiveorinappropriate}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.Unrelated}
                    onChange={(e) => handleChange(e, props.id)}
                    name="Unrelated"
                    color="primary"
                  />
                }
                label={<p className={feedstyles.reportoption}>Unrelated</p>}
                className={feedstyles.unrelated}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.duplicate}
                    onChange={(e) => handleChange(e, props.id)}
                    name="duplicate"
                    color="primary"
                  />
                }
                label={<p className={feedstyles.reportoption}>Duplicate</p>}
                className={feedstyles.duplicate}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.other}
                    onChange={(e) => handleChange(e, props.id)}
                    name="other"
                    color="primary"
                  />
                }
                label={<p className={feedstyles.reportoption}>Other</p>}
                className={feedstyles.other}
              />
              {state?.other && (
                <div id={`report${props.id}`}>
                  <textarea
                    className={feedstyles.reporttextarea}
                    value={reason}
                    onChange={(e) => onTextChange(e.target.value)}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      textAlign: "right",
                    }}
                  >
                    <label style={{ color: charCount > 280 ? "red" : "black" }}>
                      {charCount}
                    </label>
                    <label>/280</label>
                  </div>
                </div>
              )}
              <div>
                <button
                  type="button"
                  className={feedstyles.reportsubmit}
                  id={`button${props.id}`}
                  onClick={() => onReportSubmit(props.id)}
                  disabled={!isEnabled()}
                  style={{
                    backgroundColor: !isEnabled() ? "#60A2E0" : "#127ADD",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className={feedstyles.sampledialogcountandcancel}>
            <p> This post has already been flagged abusive</p>
            <a onClick={handleClose}>
              <img src={sampledialogcancel} />
            </a>
          </div>
        )}
      </div>
    </Dialog>
  );
}

ReportDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function ReusuableReportDialogmodal(props) {
  const [open, setOpen] = React.useState(false);
  const [reportStatusData, setReportStatusData] = useState("");
  const handleClickOpen = async (id, param) => {
    const response = await API.getReportPostStatus(id, param);
    setReportStatusData(response.data);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <a onClick={() => handleClickOpen(props.id, props.param)} key={props.id}>
        <img src={report} style={{ marginRight: 8 }} />
        <span style={{ fontSize: 14 }}>Report this post</span>
      </a>
      {reportStatusData ? (
        <ReportDialog
          open={open}
          onClose={handleClose}
          statusdata={reportStatusData}
          id={props.id}
        />
      ) : (
        ""
      )}
    </div>
  );
}
