import * as allActions from "../actions.constants";

export function fetchProfileData() {
  return {
    type: allActions.FETCH_PROFILE_DATA,
  };
}

export function receiveProfileData(data) {
  return {
    type: allActions.RECIEVE_PROFILE_DATA,
    payload: data,
  };
}


export const updateProfileInfo = (newData) => ({
  type: allActions.UPDATE_PROFILE_INFO,
  payload: {
    newData,
  },
});
export const receiveUpdatedProfileInfo = (updatedProfileInfo) => ({
  type: allActions.RECEIVE_UPDATED_PROFILE_INFO,
  payload: updatedProfileInfo,
});
