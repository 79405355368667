import { useState, React } from "react";
import { CommunityContext, defaultState } from "./CommunityContext";

function CommunityContextProvider({ children }) {
  const [state, setState] = useState({ ...defaultState });

  const onChangeState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const onResetState = () => {
    setState({ ...defaultState });
  };

  return (
    <CommunityContext.Provider value={{
      community: state,
      onChangeCommunity: onChangeState,
      onResetCommunity: onResetState,
    }}>
      {children}
    </CommunityContext.Provider>
  );
}

export default CommunityContextProvider;
