import axios from "axios";
import Cookies from "js-cookie";

import * as SignInActions from "../../actions/SignInActions/SignInAction";
import * as feedactions from "../../actions/feedactions/feedActionCreator";

import * as allActions from "../../actions/actions.constants";
import API from "../../../network";

const SignInservice = (store) => (next) => (action) => {
  const url = process.env.REACT_APP_SERVICES_API;
  let isNewUser = false;
  if (action.payload instanceof FormData) {
    isNewUser = action.payload.get("isNewUser");
    action.payload.delete("isNewUser");
  }
  next(action);
  next(SignInActions.loading());
  switch (action.type) {
    case allActions.DO_SIGNIN:
      axios
        .request({
          url: `${url}/jsonindex/securityCheck.html?source=null`,
          method: "POST",
          data: action.payload,
          withCredentials: true,
        })
        .then((res) => {
          // API.setApiHeaders(sessionId);
          const signStatus = res.data.status === "AUTHORIZED";
          // Cookies.set("signInStatus", signStatus, { expires: 1 });
          Cookies.set("signInStatus", signStatus);
          next(SignInActions.receivesigninUserData(res.data));
          if (res.data.status === "AUTHORIZED") {
            // next(SignInActions.authUser())
            axios
              .request({
                url: `${url}/jsonuser/land?redirectTenantId=${res.data.userTenantId}&name=${res.data.userTenant}`,
                method: "POST",
                withCredentials: true,
              })
              .then((res) => {
                next(SignInActions.authUser());
                next(SignInActions.updateIsNewUser(isNewUser || false));
                if (res.data.userCommunityStatus === "Y") {
                  axios
                    .request({
                      url: `${url}/jsonuser/getUserData`,
                      method: "GET",
                      withCredentials: true,
                    })
                    .then((res) => {
                      next(SignInActions.loginUser(res.data));
                    });
                }
              });
          } else if (res.data.status === "NewUser") {
            next(SignInActions.newUser());
          } else {
            next(SignInActions.unauthUser());
          }
          next(SignInActions.stopLoading());
        });
      break;

    case allActions.SiGNoUT_STATUS:
      axios
        .request({
          url: `${url}/jsonindex/signOut`,
          method: "GET",
          headers: {},
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.status === "SignedOut") {
            next(SignInActions.signOutData());
          }
          next(SignInActions.stopLoading());
        });
      break;
    default:
      break;
  }
};
export default SignInservice;
