import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import style from "src/Assets/css/LandingPage/Switch.module.css"
import Tab from "../Tab";
import { SignupContext } from 'src/context/SignupContext/SignupContext';

const TabSwitch = (props) => {
    const history = useHistory()
    const [selectedLabel, setSelectedLabel] = useState();
    const signupContext = useContext(SignupContext)

    useEffect(() => {
        setSelectedLabel(props?.children[0].props.label)
    }, [])

    useEffect(() => {
        if (signupContext?.signupState?.signup || signupContext?.signupState?.googleSignUp || signupContext?.signupState?.appleSignUp) {
            setSelectedLabel(props?.children[1].props.label)
        }
    }, [signupContext?.signupState])

    const onClickTabItem = (tab) => {
        history.replace({})
        setSelectedLabel(tab);
        signupContext?.resetSignupState();
    }

    return (
        <>
            <div className={style.tabs}>
                <div className={style.tablist}>
                    {props?.children.map((child) => {
                        const { label } = child.props;

                        return (
                            <Tab
                                activeTab={selectedLabel}
                                key={label}
                                label={label}
                                onClick={onClickTabItem}
                            />
                        );
                    })}
                </div>
                <div className="tab-content">
                    {props?.children.map((child) => {
                        if (child.props.label !== selectedLabel) return undefined;
                        return child.props.children;
                    })}
                </div>

            </div>

        </>
    );
}
export default TabSwitch