// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Country Picker css */
.Country_inputContainer__8nGCp {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.89vh;
    width: 23.1vw;
    padding: 0.1vh 0.89vh;
    height: 5.8vh;
    font-size: 2.78vh;
    border-radius: 0.5vh;
    border: 1px solid #A6A6A6;
}
.Country_flagImg__ozJJT {
    height: 3.5vh;
    width: 3.5vh;
    margin-right: 1vw;
}
.Country_countryTxt__wOCY1 {
    white-space: nowrap;
    overflow: hidden;
}

/* Country Options css */
.Country_optionsContainer__wNHiZ {
    width: 24vw;
    height: 50vh;
    overflow-y: scroll;
    background: white;
}
.Country_countryRow__YBVmS {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5vh 1vh;
    cursor: pointer;
}
.Country_imageWrapper__EG2f7 { 
    width: 13%;
}
.Country_dialCodeContainer__l2i5v {
    width: 20%;
}
.Country_isoContainer__SqxXi {
    width: 10%;
}
.Country_lableContainer__\\+iTRf {
    width: 56%;
}
.Country_imageStyle__3RsXW {
    width: 2vw;
    height: 2vw;
}
.Country_dialText__RAiI7 {
    font-size: 2vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/Landing/components/CountryPicker/Country.module.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,qBAAqB;IACrB,aAAa;IACb,qBAAqB;IACrB,aAAa;IACb,iBAAiB;IACjB,oBAAoB;IACpB,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA,wBAAwB;AACxB;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;IACV,WAAW;AACf;AACA;IACI,cAAc;AAClB","sourcesContent":["/* Country Picker css */\n.inputContainer {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-bottom: 0.89vh;\n    width: 23.1vw;\n    padding: 0.1vh 0.89vh;\n    height: 5.8vh;\n    font-size: 2.78vh;\n    border-radius: 0.5vh;\n    border: 1px solid #A6A6A6;\n}\n.flagImg {\n    height: 3.5vh;\n    width: 3.5vh;\n    margin-right: 1vw;\n}\n.countryTxt {\n    white-space: nowrap;\n    overflow: hidden;\n}\n\n/* Country Options css */\n.optionsContainer {\n    width: 24vw;\n    height: 50vh;\n    overflow-y: scroll;\n    background: white;\n}\n.countryRow {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 0.5vh 1vh;\n    cursor: pointer;\n}\n.imageWrapper { \n    width: 13%;\n}\n.dialCodeContainer {\n    width: 20%;\n}\n.isoContainer {\n    width: 10%;\n}\n.lableContainer {\n    width: 56%;\n}\n.imageStyle {\n    width: 2vw;\n    height: 2vw;\n}\n.dialText {\n    font-size: 2vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `Country_inputContainer__8nGCp`,
	"flagImg": `Country_flagImg__ozJJT`,
	"countryTxt": `Country_countryTxt__wOCY1`,
	"optionsContainer": `Country_optionsContainer__wNHiZ`,
	"countryRow": `Country_countryRow__YBVmS`,
	"imageWrapper": `Country_imageWrapper__EG2f7`,
	"dialCodeContainer": `Country_dialCodeContainer__l2i5v`,
	"isoContainer": `Country_isoContainer__SqxXi`,
	"lableContainer": `Country_lableContainer__+iTRf`,
	"imageStyle": `Country_imageStyle__3RsXW`,
	"dialText": `Country_dialText__RAiI7`
};
export default ___CSS_LOADER_EXPORT___;
