import React from "react";
import { Modal } from "react-bootstrap";
import * as CryptoJS from "crypto-js";
import { Redirect } from "react-router-dom";
import * as SignInActions from "src/store/actions/SignInActions/SignInAction";
// import SignInReducer from "src/store/reducer/SignInReducer/SignInReducer";
import { calcMD5 } from "src/md5Forgot";
import Api from "src/network";
import "./EmailverficationModal.css";
import { CircularProgress } from "@material-ui/core";

class EmailVerificationModal extends React.Component {
  state = {
    code: ["", "", "", "", "", ""],
    isAuthenticated: false,
    errorMessage: "",
    message: "",
    isLoading: false,
  };

  updateCode = (e, index) => {
    const { value } = e.target;

    if (value.match(/^\d*$/)) {
      // Handle regular digit input
      const updatedCode = [...this.state.code];
      updatedCode[index] = value;
      this.setState({ code: updatedCode }, () => {
        if (index < 5 && this.state.code[index] !== "") {
          this[`digit${index + 1}`].focus();
        }
      });
    }
  };

  handleKeyDown = (e, index) => {
    if (e.keyCode === 8) {
      // Handle backspace to move back to the previous input
      const updatedCode = [...this.state.code];
      updatedCode[index] = ""; // Clear the current digit
      this.setState({ code: updatedCode }, () => {
        if (index > 0) {
          this[`digit${index - 1}`].focus();
        }
      });
    }
  };

  codeSubmitHandler = async (e) => {
    e.preventDefault();
    // const tenantId = this.props.tentId;
    // const tenantName = this.props.tenantName;
    let formData = new FormData();
    let encodedEmail = CryptoJS.AES.encrypt(
      this.props.email,
      "skopic"
    ).toString();
    let encodedPassword = calcMD5(this.props.password);
    let dispatch = this.props.dispatch;

    formData.append("j_username", encodedEmail);

    try {
      this.setState({ isLoading: true, errorMessage: "", message: "" });
      const response = await Api.createSixdigitcodeUser(
        formData,
        this.state.code.join("")
      );
      console.log(response.data);
      if (response.data === "Registered" || response.data === "SkopicUser") {
        let formData = new FormData();
        formData.append("j_username", encodedEmail);
        formData.append("j_password", encodedPassword);
        formData.append("si_remember", "_spring_security_remember_me=true");
        formData.append("isNewUser", true);
        dispatch(SignInActions.signinUser(formData));
      } else {
        this.setState({
          errorMessage: "The code you entered is incorrect.",
          message: "", // Clear the resend message
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isLoading: false });
    }

  };

  ResendCode = async () => {
    this.setState({
      message: "",
      errorMessage: "",
      code: ["", "", "", "", "", ""],
    });

    let formData = new FormData();
    var encodedemail = CryptoJS.AES.encrypt(
      this.props.email,
      "skopic"
    ).toString();
    formData.append("j_username", encodedemail);
    let currentComponent = this;

    try {
      const response = await Api.resendVerificationCode(formData);
      if (response.data?.status === "success") {
        this.setState({
          message: "The code has been sent to your Email. The code expires in 24hrs",
          errorMessage: "", //clear error message
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  closeHandler = () => {
    console.log("Closing modal");
    this.setState({
      message: null,
      code: ["", "", "", "", "", ""],
      errorMessage: null,
    });
    // this.props.onHide();
  };

  closeModal = () => {
    this.props.onHide();
  };

  render() {
    const { code } = this.state;
    const isEnabled = code.join("").length === 6;

    if (this.state.isAuthenticated) {
      return <Redirect to="/Home" />;
    }

    return (
      <Modal
        {...this.props}
        onHide={this.closeHandler}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="emailModal"
      >
        <div className="email-verification-container">
          <Modal.Header style={{ position: 'relative' }}>
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ color: "#373334", fontSize: "20px", fontWeight: 800 }}
            >
              Email Verification
            </Modal.Title>
            <button
              type="button"
              onClick={this.closeModal}
              className="close-button"
              aria-label="Close"
            >
              &times;
            </button>
          </Modal.Header>
          <Modal.Body style={{ paddingTop: 0 }}>
            <p style={{ textAlign: "center" }} className="text">
              Please enter the 6-digit code sent to {this.props.email}.
            </p>
            <form onSubmit={this.codeSubmitHandler}>
              <div className="btnWrapper">
                <div className="EnterCode" style={{ textAlign: "center" }}>
                  {code.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      name={`digit${index}`}
                      value={digit}
                      onChange={(e) => this.updateCode(e, index)}
                      onKeyDown={(e) => this.handleKeyDown(e, index)}
                      ref={(input) => (this[`digit${index}`] = input)} // create refs for each input
                      style={{
                        width: "28px",
                        margin: "8px 8px 4px 8px",
                      }}
                    // placeholder="0"
                    />
                  ))}
                </div>
                <p className="errorMessage" style={{ textAlign: "center" }}>
                  {this.state.errorMessage}
                </p>
                <button
                  className="ResendCode"
                  style={{ textAlign: "center" }}
                  type="button"
                  onClick={this.ResendCode}
                >
                  Resend Code
                </button>
                <button
                  type="submit"
                  style={{
                    background: isEnabled
                      ? "linear-gradient(123deg, #6ca8e1 0%, #4795de 49%, #0071dd 100%)"
                      : "#FFFFFF",
                    color: isEnabled ? "#FFFFFF" : "#7A7A7A",
                  }}
                  disabled={!isEnabled}
                  className="FinishSignUp"
                >
                  {this.state.isLoading
                    ? <CircularProgress style={{ width: "20px", height: "20px" }} />
                    : "Finish Sign Up"
                  }
                </button>
                {this.state.message && (
                  <p className="ResendMessage" style={{ textAlign: "center" }}>
                    {this.state.message}
                  </p>
                )}
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}

export default EmailVerificationModal;
