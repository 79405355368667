import React from "react";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import backwardarrow from "../../../Assets/images/backwardarrow.png";
import SimpleDialogDemo from "../../../Modules/Reusuablecomponents/CustomizedDialogs";
import NoDataFound from "../../../Assets/images/data-not-found.svg";
import location from "../../../Assets/images/userlocationtag.png";
// import dropdownarrow from "../../../Assets/images/dropdownarrow.png";
import saysoutlineimage from "../../../Assets/images/noofsays.svg";
import follow from "../../../Assets/images/follow.svg";
// import unfollow from "../../../Assets/images/unfollow.svg";
import privateinvite from "../../../Assets/images/privateinvite.png";
import like from "../../../Assets/images/like.svg";
import unlike from "../../../Assets/images/unlike.svg";
import dotImage from "../../../Assets/images/dot.png";
// import smallike from "../../../Assets/images/smallike.png";
import PostMessageValidation from "../../../Modules/Reusuablecomponents/PostMessageValidation";
import useStyles from "./styles";
import ChildSay from "src/pages/HomePage/components/ChildSay";
import AvatarBadge from "src/Components/AvatarBadge";

const TrendingTags = ({
  data,
  handleBack,
  isAsksClicked,
  handleSayClick,
  id,
  childSaysData,
  handleFollowunfollow,
  isLoading,
  isActionDisabled = false,
  fetchVoteUpStatus = () => {},
  fetchFeedData = () => {},
  deleteMessage = () => {},
  refreshFeedData = () => {},
}) => {
  const imageServerURL = process.env.REACT_APP_SKOPIC_IMAGE_OTHER;
  const {
    feedContainer,
    feedMessage,
    profileName,
    avatarDetails,
    avatar,
    locationandtime,
    followerandcount,
    askcount,
    saycount,
    updatecount,
    tagcount,
    followcount,
    saysandfollow,
    noOfsays,
    noOffollows,
    followtext,
    priavteinvite,
    likeunlike,
    nodata,
  } = useStyles();
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);

  return (
    <>
      <div
        style={{
          background: "rgb(229, 228, 226)",
          border: "1px solid rgb(222, 224, 225)",
          borderRadius: "20px",
          padding: "20px 0 20px 20px",
        }}
      >
        <div style={{ marginBottom: "10px" }}>
          <a onClick={handleBack}>
            <img src={backwardarrow} />
          </a>
          <span style={{ fontSize: "20px", marginLeft: "10px" }}>{`Trending ${
            isAsksClicked !== "asks" ? "TAGs" : "ASKs"
          }`}</span>
        </div>
        <div
          style={{
            height: "calc(-180px + 100vh)",
            overflowY: "scroll",
            scrollbarWidth: "thin",
          }}
        >
          {data?.length > 0 ? (
            data?.map((item, index) => {
              return (
                <div className={feedContainer} key={index}>
                  <div className={avatarDetails}>
                    <div className={avatarDetails}>
                      <div>
                        <AvatarBadge
                          src={`${imageServerURL}${item?.uimage}`}
                          alt={item?.displayName}
                          isPrimaryModerator={item?.isModerator == 1}
                          isModerator={
                            item?.isModerator == 0 && item?.moderatorStatus == 1
                          }
                        />
                      </div>
                      <div style={{ paddingLeft: 5 }}>
                        <span className={profileName}>{item?.displayName}</span>
                        {item?.locName ? (
                          <div className={locationandtime}>
                            <span>
                              {item?.Message_Time} {item?.TenantName}
                            </span>
                            <img
                              src={dotImage}
                              alt="dot"
                              style={{ marginRight: "0", marginLeft: "3px" }}
                            />
                            <span>
                              <img src={location} alt="location" />
                              {item?.locName}
                            </span>
                          </div>
                        ) : (
                          <div className={locationandtime}>
                            <span>
                              {item?.Message_Time} {item?.TenantName}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className={feeddropdown}>
                      <button className={feeddropdownbutton}>
                        <img src={dropdownarrow} alt="dropdown" />
                      </button>
                    </div> */}
                  </div>
                  <div
                    style={{
                      paddingTop: "5px",
                      fontSize: "14px",
                      fontWeight: "normal",
                      paddingBottom: "5px",
                    }}
                  >
                    <div
                      className={feedMessage}
                      dangerouslySetInnerHTML={{ __html: item?.Message }}
                      id={`hide${item?.id}`}
                    />
                  </div>
                  <div className={followerandcount}>
                    {item?.Keyword_ID === "Open ASK" ||
                    item?.Keyword_ID === "OpenASK" ||
                    item?.Keyword_ID === "#tag" ||
                    item?.Keyword_ID === "hashTAG" ||
                    item?.Keyword_ID === "ASK" ||
                    item?.Keyword_ID === "H" ||
                    item?.Keyword_ID === "TrendingASK" ? (
                      <div>
                        {item?.followCount !== 0 ? (
                          <div>
                            <SimpleDialogDemo
                              followCount={item?.followCount}
                              followData={item?.follweduserprofile}
                              id={item?.id}
                            />
                          </div>
                        ) : (
                          <div>
                            <span className={followcount}>
                              {item?.followCount} followers
                            </span>
                          </div>
                        )}
                      </div>
                    ) : item?.voteUp !== 0 ? (
                      <div>
                        <SimpleDialogDemo
                          voteUp={item?.voteUp}
                          id={item?.id}
                          likesData={item?.likeduserprofile}
                        />
                      </div>
                    ) : (
                      <div>
                        <span className={followcount}>
                          {item?.voteUp === 1
                            ? `${item?.voteUp} like`
                            : `${item?.voteUp} likes`}
                        </span>
                      </div>
                    )}
                    {item?.Keyword_ID === "Open ASK" ||
                    item?.Keyword_ID === "OpenASK" ||
                    item?.Keyword_ID === "ASK" ||
                    item?.Keyword_ID === "TrendingASK" ? (
                      <div className={askcount}>
                        {/* {item?.followCount} */}
                      </div>
                    ) : item?.Keyword_ID === "SAY" ||
                      item?.Keyword_ID === "OpenSAY" ? (
                      <div className={saycount}></div>
                    ) : item?.Keyword_ID === "Important Update" ||
                      item?.Keyword_ID === "impupdate" ? (
                      <div className={updatecount}></div>
                    ) : item?.Keyword_ID === "#tag" ||
                      item?.Keyword_ID === "hashTAG" ||
                      item?.Keyword_ID === "H" ? (
                      <div className={tagcount}>
                        {/* {item?.followCount} */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div style={{ border: "1px solid #C4C4C4" }} />
                  {item.Keyword_ID === "Open ASK" ||
                  item.Keyword_ID === "OpenASK" ||
                  item.Keyword_ID === "hashTAG" ||
                  item.Keyword_ID === "#tag" ||
                  item?.Keyword_ID === "ASK" ||
                  item.Keyword_ID === "H" ||
                  item?.Keyword_ID === "TrendingASK" ? (
                    <div className={saysandfollow}>
                      <div className={noOfsays}>
                        <a onClick={(e) => handleSayClick(e, item?.id)}>
                          <img src={saysoutlineimage} alt="numberof says" />
                          {item?.sayCount === 1 || item?.postCount === 1 ? (
                            <span>{item?.sayCount} SAY</span>
                          ) : item?.sayCount === 0 || item?.postCount === 0 ? (
                            <span>SAY</span>
                          ) : (
                            <span>{item?.sayCount} SAYs</span>
                          )}
                        </a>
                      </div>
                      {item?.followStatus === "Active" ||
                      item?.followStatus === "Y" ? (
                        <div className={noOffollows} id={`1${item?.id}`}>
                          <a
                            className={followtext}
                            onClick={() =>
                              handleFollowunfollow(item?.id, "isFollow=0")
                            }
                          >
                            {/* <img src={unfollow} alt="unfollow" />
                            <span>Unfollow</span> */}
                            <img src={follow} alt="follow" />
                            <span>Follow</span>
                          </a>
                        </div>
                      ) : (
                        <div id={`0${item?.id}`} className={noOffollows}>
                          <a
                            className={followtext}
                            onClick={() =>
                              handleFollowunfollow(item?.id, "isFollow=1")
                            }
                          >
                            <img src={follow} alt="follow" />
                            <span>Follow</span>
                          </a>
                        </div>
                      )}
                      {item?.hashTagType === "private" ? (
                        <div className={priavteinvite}>
                          <img src={privateinvite} alt="Private Invite" />
                          <span
                            //   onClick={() => onInviteSelection(item?.TenantID || item?.tenantID, item?.id)}
                            style={{ cursor: "pointer" }}
                          >
                            Invite
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className={likeunlike}>
                      {item?.UserLikeStatus === "Active" ? (
                        <>
                          <a
                            //   onClick={() =>
                            //     onLikeUnlikeSelection(item?.id, "cacheUpdate")
                            //   }
                            id={`like${item?.id}`}
                          >
                            <img src={like} alt="like" />
                            Like
                          </a>
                        </>
                      ) : (
                        <a>
                          <img src={unlike} alt="unlike" />
                          Like
                        </a>
                      )}
                    </div>
                  )}

                  {item?.id === id && (
                    <div style={{ paddingTop: "10px" }}>
                      <PostMessageValidation
                        id={item?.id}
                        type={"childSay"}
                        userImageSrc={`${imageServerURL}${item?.mainuserdata?.uimage}`}
                        readOnly={true}
                      />
                      <ChildSay
                        id={item.id}
                        key={item.id}
                        loading={isLoading}
                        childSaysData={childSaysData || {}}
                        fetchVoteUpStatus={fetchVoteUpStatus}
                        fetchFeedData={fetchFeedData}
                        deleteMessage={deleteMessage}
                        refreshFeedData={refreshFeedData}
                        isActionDisabled={isActionDisabled}
                        flow="beforeSignIn"
                      />
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className={nodata}>
              <img src={NoDataFound} alt="NoDataFound" width={"30%"} />
              <p>No records found</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TrendingTags;
