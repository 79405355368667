import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import clsx from "clsx";
import Input from "@material-ui/core/Input";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Emailicon from "src/Assets/images/emailicon.svg";
import Socialmedia from "src/Assets/images/socialmedia.png";
import PasswordIcon from "src/Assets/images/password.png";
import Signouticon from "src/Assets/images/signout.png";
import Notificationicon from "src/Assets/images/bell.svg";
import darkmodeicon from "src/Assets/images/darkmodeicon.png";
import * as SigInOutActions from "src/store/actions/SignInActions/SignInAction.js";
import styles from "src/Assets/css/settings/settings.module.css";
import { calcMD5 } from "src/md5Forgot.js";
import SidebarOption from "src/Components/SidebarOption";
import Api from "src/network";
import FBModal from "./FBModal.js";
import TwitterModal from "./TwitterModal.js";
import Cookies from 'js-cookie';
import Page, { PageEnum } from "src/layout/Page.jsx";

const defaultState = {
  'emailNotification': false,
  'socialMedia': false,
  'darkMode': false,
  'passwordChange': false,
};

function Settings() {
  const dispatch = useDispatch();
  const logOutStatus = useSelector((state) => state.SignInReducer?.isLogout);
  console.log(logOutStatus);

  const [isActive, setIsActive] = useState({
    ...defaultState,
    emailNotification: true,
  });

  const emailNotificationRef = useRef(null);
  const socialMediaRef = useRef(null);
  const darkModeRef = useRef(null);
  const passwordChangeRef = useRef(null);

  const [isChange, setChange] = useState(false);
  // const [logOut, setLogOut] = useState(false);
  const [username, setUsername] = useState("");
  const [pwd, setpwd] = useState("");

  const [isNotificationSelected, setIsNotificationSelected] = useState(false);

  const [checkboxes, setCheckboxes] = useState({
    newsLetter: 'N',
    monthlySpotLight: 'N',
    inactivityReminder: 'N',
    asksYouAreFollowing: 'N',
    usersFollowingYourAsks: 'N',
    newTags: 'N',
    tagsYouAreFollowing: 'N',
    usersFollowingYourTags: 'N',
    updates: 'N',
    likes: 'N',
    receiveEmailFromUsers: 'N',
    facebook: 'N',
    twitter: 'N',
    linkedin: 'N',
  });

  const onThemeChange = () => {
    setChange(!isChange);
  };

  const onClickSidebarOption = (key) => {
    setIsActive({
      ...defaultState,
      [key]: true,
    });

    if (key === 'emailNotification' && emailNotificationRef.current) {
      emailNotificationRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (key === 'socialMedia' && socialMediaRef.current) {
      socialMediaRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (key === 'darkMode' && darkModeRef.current) {
      darkModeRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (key === 'passwordChange' && passwordChangeRef.current) {
      passwordChangeRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const res = await Api.fetchUserSettings();
        setUsername(res.data.username);
        setpwd(res.data.userPassword);
        setCheckboxes({
          newsLetter: res.data?.newsLetter === 'Y' ? 'Y' : 'N',
          monthlySpotLight: res.data?.monSpotLite === 'Y' ? 'Y' : 'N',
          inactivityReminder: res.data?.missYou === 'Y' ? 'Y' : 'N',
          asksYouAreFollowing: res.data?.opq === 'Y' ? 'Y' : 'N',
          usersFollowingYourAsks: res.data?.ufAsk === 'Y' ? 'Y' : 'N',
          newTags: res.data?.newHash === 'Y' ? 'Y' : 'N',
          tagsYouAreFollowing: res.data?.hash === 'Y' ? 'Y' : 'N',
          usersFollowingYourTags: res.data?.ufHash === 'Y' ? 'Y' : 'N',
          updates: res.data?.update === 'Y' ? 'Y' : 'N',
          likes: res.data?.like === 'Y' ? 'Y' : 'N',
          receiveEmailFromUsers: res.data?.usermes === 'Y' ? 'Y' : 'N',
          // facebook: res.data?.fbPermission === 'Y' ? 'Y' : 'N',
          // twitter: res.data?.twitterPermission === 'Y' ? 'Y' : 'N',
          // linkedin: res.data?.linkedinPermission === 'Y' ? 'Y' : 'N',
        });
      } catch (error) {
        console.log(error);
      };
    };

    fetchUserSettings();
  }, []);

  const newsLetterChange = async () => {
    try {
      let formdata = new FormData();
      formdata.append("notificationStatus", checkboxes.newsLetter === 'Y' ? 'N' : 'Y');
      formdata.append("cnfrm", "newsLetter");
      await Api.updateUserSettings(formdata);

      setCheckboxes(prevState => ({
        ...prevState,
        newsLetter: prevState.newsLetter === 'Y' ? 'N' : 'Y',
      }));
    } catch (e) {
      console.error(e);
    }
  };

  //MonthlySpotLight
  const monSpotLiteChange = async () => {
    try {
      let formdata = new FormData();
      formdata.append("notificationStatus", checkboxes.monthlySpotLight === 'Y' ? 'N' : 'Y');
      formdata.append("cnfrm", "monSpotLite");
      await Api.updateUserSettings(formdata);

      setCheckboxes(prevState => ({
        ...prevState,
        monthlySpotLight: prevState.monthlySpotLight === 'Y' ? 'N' : 'Y',
      }));
    } catch (e) {
      console.error(e);
    }
  };

  // Inactivity Reminder
  const inavityRemdChange = async () => {
    try {
      let formdata = new FormData();
      formdata.append("notificationStatus", checkboxes.inactivityReminder === 'Y' ? 'N' : 'Y');
      formdata.append("cnfrm", "missYou");
      await Api.updateUserSettings(formdata);

      setCheckboxes(prevState => ({
        ...prevState,
        inactivityReminder: prevState.inactivityReminder === 'Y' ? 'N' : 'Y',
      }));
    } catch (e) {
      console.error(e);
    }
  };

  //ASKs you are following
  const AsksFollowChange = async () => {
    try {
      let formdata = new FormData();
      formdata.append("notificationStatus", checkboxes.asksYouAreFollowing === 'Y' ? 'N' : 'Y');
      formdata.append("cnfrm", "opq");
      await Api.updateUserSettings(formdata);

      setCheckboxes(prevState => ({
        ...prevState,
        asksYouAreFollowing: prevState.asksYouAreFollowing === 'Y' ? 'N' : 'Y',
      }));
    } catch (e) {
      console.error(e);
    }
  };

  //Users following your ASKs
  const UsersFollowAsksChange = async () => {
    try {
      let formdata = new FormData();
      formdata.append("notificationStatus", checkboxes.usersFollowingYourAsks === 'Y' ? 'N' : 'Y');
      formdata.append("cnfrm", "ufAsk");
      await Api.updateUserSettings(formdata);

      setCheckboxes(prevState => ({
        ...prevState,
        usersFollowingYourAsks: prevState.usersFollowingYourAsks === 'Y' ? 'N' : 'Y',
      }));

    } catch (e) {
      console.error(e);
    }
  };

  //New#Tag
  const NewTagChange = async () => {
    try {
      let formdata = new FormData();
      formdata.append("notificationStatus", checkboxes.newTags === 'Y' ? 'N' : 'Y');
      formdata.append("cnfrm", "newHash");
      await Api.updateUserSettings(formdata);

      setCheckboxes(prevState => ({
        ...prevState,
        newTags: prevState.newTags === 'Y' ? 'N' : 'Y',
      }));
    } catch (e) {
      console.error(e);
    }
  };

  //#TAGS you are following
  const TagFollowingChange = async () => {
    try {
      let formdata = new FormData();
      formdata.append("notificationStatus", checkboxes.tagsYouAreFollowing === 'Y' ? 'N' : 'Y');
      formdata.append("cnfrm", "hash");
      await Api.updateUserSettings(formdata);

      setCheckboxes(prevState => ({
        ...prevState,
        tagsYouAreFollowing: prevState.tagsYouAreFollowing === 'Y' ? 'N' : 'Y',
      }));
    } catch (e) {
      console.error(e);
    }
  };

  //Users following your #TAGS
  const UserFollowTagChange = async () => {
    try {
      let formdata = new FormData();
      formdata.append("notificationStatus", checkboxes.usersFollowingYourTags === 'Y' ? 'N' : 'Y');
      formdata.append("cnfrm", "ufHash");
      await Api.updateUserSettings(formdata);

      setCheckboxes(prevState => ({
        ...prevState,
        usersFollowingYourTags: prevState.usersFollowingYourTags === 'Y' ? 'N' : 'Y',
      }));
    } catch (e) {
      console.error(e);
    }
  };

  //UPDATES
  const UpdateChange = async () => {
    try {
      let formdata = new FormData();
      formdata.append("notificationStatus", checkboxes.updates === 'Y' ? 'N' : 'Y');
      formdata.append("cnfrm", "upnot");
      await Api.updateUserSettings(formdata);

      setCheckboxes(prevState => ({
        ...prevState,
        updates: prevState.updates === 'Y' ? 'N' : 'Y',
      }));
    } catch (e) {
      console.error(e);
    }
  };

  //Likes
  const LikesChange = async () => {
    try {
      let formdata = new FormData();
      formdata.append("notificationStatus", checkboxes.likes === 'Y' ? 'N' : 'Y');
      formdata.append("cnfrm", "like");
      await Api.updateUserSettings(formdata);

      setCheckboxes(prevState => ({
        ...prevState,
        likes: prevState.likes === 'Y' ? 'N' : 'Y',
      }));
    } catch (e) {
      console.error(e);
    }
  };

  //Receive messages from users
  const ReciveMailChange = async () => {
    try {
      let formdata = new FormData();
      formdata.append("notificationStatus", checkboxes.receiveEmailFromUsers === 'Y' ? 'N' : 'Y');
      formdata.append("cnfrm", "usermes");
      await Api.updateUserSettings(formdata);

      setCheckboxes(prevState => ({
        ...prevState,
        receiveEmailFromUsers: prevState.receiveEmailFromUsers === 'Y' ? 'N' : 'Y',
      }));
    } catch (e) {
      console.error(e);
    }
  };

  // const setfbshow = () => {
  //   setCheckboxes(prevState => ({
  //     ...prevState,
  //     facebook: prevState.facebook === 'Y' ? 'N' : 'Y',
  //   }));
  // };

  // const setTweetShow = () => {
  //   setCheckboxes(prevState => ({
  //     ...prevState,
  //     twitter: prevState.twitter === 'Y' ? 'N' : 'Y',
  //   }));
  // };

  // const setLinkedinShow = () => {
  //   setCheckboxes(prevState => ({
  //     ...prevState,
  //     linkedin: prevState.linkedin === 'Y' ? 'N' : 'Y',
  //   }));
  // };

  //Styles
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: "17ch",
      height: "30px",
      borderBottom: "1px solid gray",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: "4px"
    },
  }));
  // console.log(pwd)
  const classes = useStyles();
  //Password State
  const [pwdCr, setpwdCr] = React.useState({
    password: "",
    showPassword: false,
  });
  const [pwdNw, setpwdNw] = React.useState({
    password: "",
    showPassword: false,
  });
  const [pwdCn, setpwdCn] = React.useState({
    password: "",
    showPassword: false,
  });

  const [msgCr, setMsgCr] = useState("");
  const [msgNw, setMsgNw] = useState("");
  const [msgCn, setMsgCn] = useState("");
  const [msg, setMsg] = useState("");

  const handleChange1 = (prop) => (event) => {
    setpwdCr({ ...pwdCr, [prop]: event.target.value });
  };

  const handleClickShowPassword1 = () => {
    setpwdCr({ ...pwdCr, showPassword: !pwdCr.showPassword });
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handleChange2 = (prop) => (event) => {
    setpwdNw({ ...pwdNw, [prop]: event.target.value });
  };

  const handleClickShowPassword2 = () => {
    setpwdNw({ ...pwdNw, showPassword: !pwdNw.showPassword });
  };

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const handleChange3 = (prop) => (event) => {
    setpwdCn({ ...pwdCn, [prop]: event.target.value });
  };

  const handleClickShowPassword3 = () => {
    setpwdCn({ ...pwdCn, showPassword: !pwdCn.showPassword });
  };

  const handleMouseDownPassword3 = (event) => {
    event.preventDefault();
  };

  const passwordChangehandler = () => {
    let hasError = false;
    let encodedNewPassword = calcMD5(pwdNw.password);
    let encodedCurrentPassword = calcMD5(pwdCr.password);
    let formdata = new FormData();
    formdata.append("password_n", encodedNewPassword);
    formdata.append("userEmail", username);
    formdata.append("password_c", encodedCurrentPassword);

    if (pwdCr.password === "") {
      setMsgCr("Please enter current password");
    } else if (pwdCr.password !== "") {
      if (encodedCurrentPassword !== pwd) {
        setMsgCr("Invalid current password.");
      }
    } else {
      setMsgCr("");
    }
    if (pwdNw.password === "") {
      setMsgNw("Please enter new password.");
      hasError = true;
    } else if (pwdNw.password.length < 8) {
      setMsgNw("Specify a password with eight characters or more.");
      hasError = true;
    } else if (pwdCr.password === pwdNw.password) {
      setMsgNw("New Password should not be same as Old Password");
      hasError = true;
    } else if (pwdNw.password.charAt(0) === "") {
      setMsgNw("Does not allow a data with first character as a blank.");
      hasError = true;
    } else {
      setMsgNw("");
    }
    if (pwdCn.password === "") {
      setMsgCn("Please enter confirm password");
      hasError = true;
    } else if (pwdCn.password.length < 8) {
      setMsgCn("Specify a password with eight characters or more.");
      hasError = true;
    } else if (pwdNw.password !== pwdCn.password) {
      setMsgCn("New and confirm New Password have to be same");
      hasError = true;
    } else {
      setMsgCn("");
    }
    if (!hasError) {
      onUpdatePassword(formdata);
    }
  };

  const onUpdatePassword = async (payload) => {
    try {
      const res = await Api.updatePassword(payload);
      if (res.data?.status === "Success") {
        setMsg("Password changed successfully");
      } else {
        setMsg(null);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const signOut = () => {
    dispatch(SigInOutActions.signOut());
    Cookies.remove("signInStatus");
  };

  if (logOutStatus) {
    window.scrollTo(0, 0);
    return <Redirect to="/" />;
  }

  const showHideNotifications = () => {
    setIsNotificationSelected(!isNotificationSelected);
  }

  return (
    <Page page={PageEnum.Settings}>
      <div className={` ${styles.body} ${isChange && styles.darkthemebody}`}>
        <div className={styles.settingsMainContainer}>
          {/* {isNotificationSelected && (
            <div className={styles.settingPageNotificationContainer}>
              <div className={`${styles.notifications} `}>
                <div className={styles.notificationscroll}>
                  <Notifications />
                </div>
              </div>
            </div>
          )} */}

          <div
            className={`${styles.sidebarParentoptions} ${isChange ? styles.sidebarParentoptionsdarkTheme : ""
              }`}
          >
            <div className={styles.sidebarParentoptions1}>
              <SidebarOption
                active={isActive.emailNotification}
                text="Email Notification"
                onSidebaroptionClick={() => onClickSidebarOption('emailNotification')}
              />
              <Divider variant="fullWidth"></Divider>
              {/* <SidebarOption
                active={isActive.socialMedia}
                text="Social Media"
                onSidebaroptionClick={() => onClickSidebarOption('socialMedia')}
              /> */}
              <Divider variant="fullWidth"></Divider>
              <SidebarOption
                active={isActive.darkMode}
                text="Dark Mode"
                onSidebaroptionClick={() => onClickSidebarOption('darkMode')}
              />
              <Divider variant="fullWidth"></Divider>
              <SidebarOption
                active={isActive.passwordChange}
                text="Password Change"
                onSidebaroptionClick={() => onClickSidebarOption('passwordChange')}
              />
            </div>
          </div>

          <div className={styles.settingPagePermitionsMainContainer}>
            <div
              ref={emailNotificationRef}
              className={`${styles.sidebarchildoptions} ${isActive.emailNotification && styles.focusapperance
                } ${isChange ? styles.sidebarchildoptionsdarkTheme : ""}`}
            >
              <div className={styles.emailnotify}>
                <div className={styles.emailicon}>
                  <img
                    src={Emailicon}
                    alt="email"
                    className={styles.settingsicon}
                  />
                  <img
                    src={Notificationicon}
                    alt="notification"
                    className={styles.emailnotification}
                  />
                </div>
                <p className={styles.sidebarchildoptionsheader}>
                  <b>Email Notification</b>
                </p>
              </div>

              <span className={styles.emailnotificationsoptions}>
                <p>Newsletter </p>
                <input
                  type="checkbox"
                  id="Newsletter"
                  value={checkboxes.newsLetter}
                  onChange={newsLetterChange}
                  checked={checkboxes.newsLetter === 'Y'}
                />
                <label className={styles.inputlabel} htmlFor="Newsletter">
                  Toggle
                </label>
              </span>

              <span className={styles.emailnotificationsoptions}>
                <p>Monthly SpotLight</p>
                <input
                  type="checkbox"
                  id="MonthlySpotLight"
                  value={checkboxes.monthlySpotLight}
                  onClick={monSpotLiteChange}
                  checked={checkboxes.monthlySpotLight === 'Y'}
                />
                <label className={styles.inputlabel} htmlFor="MonthlySpotLight">
                  Toggle
                </label>
              </span>
              <span className={styles.emailnotificationsoptions}>
                <p>Inactivity Reminder</p>
                <input
                  type="checkbox"
                  id="Inactivity"
                  value={checkboxes.inactivityReminder}
                  onClick={inavityRemdChange}
                  checked={checkboxes.inactivityReminder === 'Y'}
                />
                <label className={styles.inputlabel} htmlFor="Inactivity">
                  Toggle
                </label>
              </span>
              <Divider variant="inset" className={styles.divider}></Divider>

              <span className={styles.emailnotificationsoptions}>
                <p>ASKs you are following</p>
                <input
                  type="checkbox"
                  id="ASKs you are following"
                  value={checkboxes.asksYouAreFollowing}
                  onClick={AsksFollowChange}
                  checked={checkboxes.asksYouAreFollowing === 'Y'}
                />
                <label
                  className={styles.inputlabel}
                  htmlFor="ASKs you are following"
                >
                  Toggle
                </label>
              </span>
              <span className={styles.emailnotificationsoptions}>
                <p>Users following your ASKs</p>
                <input
                  type="checkbox"
                  id="Users following your ASKs"
                  value={checkboxes.usersFollowingYourAsks}
                  onClick={UsersFollowAsksChange}
                  checked={checkboxes.usersFollowingYourAsks === 'Y'}
                />
                <label
                  className={styles.inputlabel}
                  htmlFor="Users following your ASKs"
                >
                  Toggle
                </label>
              </span>
              <span className={styles.emailnotificationsoptions}>
                <p>New TAGs</p>
                <input
                  type="checkbox"
                  id="New #TAGs"
                  value={checkboxes.newTags}
                  onClick={NewTagChange}
                  checked={checkboxes.newTags === 'Y'}
                />
                <label className={styles.inputlabel} htmlFor="New #TAGs">
                  Toggle
                </label>
              </span>
              <span className={styles.emailnotificationsoptions}>
                <p>TAGs you are following</p>
                <input
                  type="checkbox"
                  id="#TAGs you are following"
                  value={checkboxes.tagsYouAreFollowing}
                  onClick={TagFollowingChange}
                  checked={checkboxes.tagsYouAreFollowing === 'Y'}
                />
                <label
                  className={styles.inputlabel}
                  htmlFor="#TAGs you are following"
                >
                  Toggle
                </label>
              </span>
              <span className={styles.emailnotificationsoptions}>
                <p>Users following your TAGs</p>
                <input
                  type="checkbox"
                  id="Users following your #TAGs"
                  value={checkboxes.usersFollowingYourTags}
                  onClick={UserFollowTagChange}
                  checked={checkboxes.usersFollowingYourTags === 'Y'}
                />
                <label
                  className={styles.inputlabel}
                  htmlFor="Users following your #TAGs"
                >
                  Toggle
                </label>
              </span>
              <span className={styles.emailnotificationsoptions}>
                <p>UPDATEs</p>
                <input
                  type="checkbox"
                  id="UPDATEs"
                  value={checkboxes.updates}
                  onClick={UpdateChange}
                  checked={checkboxes.updates === 'Y'}
                />
                <label className={styles.inputlabel} htmlFor="UPDATEs">
                  Toggle
                </label>
              </span>
              <span className={styles.emailnotificationsoptions}>
                <p>Likes</p>
                <input
                  type="checkbox"
                  id="Likes"
                  value={checkboxes.likes}
                  onClick={LikesChange}
                  checked={checkboxes.likes === 'Y'}
                />
                <label className={styles.inputlabel} htmlFor="Likes">
                  Toggle
                </label>
              </span>
              <span
                className={`${styles.emailnotificationsoptions} ${styles.lastoption}`}
              >
                <p>Receive email from users</p>
                <input
                  type="checkbox"
                  id="Receive email from users"
                  value={checkboxes.receiveEmailFromUsers}
                  onClick={ReciveMailChange}
                  checked={checkboxes.receiveEmailFromUsers === 'Y'}
                />
                <label
                  className={styles.inputlabel}
                  htmlFor="Receive email from users"
                >
                  Toggle
                </label>
              </span>
            </div>
            {/* <div
              ref={socialMediaRef}
              className={`${styles.sidebarchildoptions} ${isActive.socialMedia && styles.focusapperance
                } ${isChange && styles.sidebarchildoptionsdarkTheme}`}
            >
              <div className={styles.sidebarchildoptionsocialmediapswdchange}>
                <img
                  src={Socialmedia}
                  alt="socailmedia"
                  className={`${styles.settingsicon} ${styles.socialmedia} ${isChange && styles.buttontextdarkTheme
                    }`}
                />
                <p className={styles.sidebarchildoptionsheader1}>
                  <b>Social Media </b>
                </p>
              </div>
              <span className={styles.emailnotificationsoptions}>
                <p>Facebook </p>
                <input
                  type="checkbox"
                  id="Facebook"
                  value={checkboxes.facebook}
                  onClick={setfbshow}
                  checked={checkboxes.facebook === 'Y'}
                />
                <label className={styles.inputlabel} htmlFor="Facebook">
                  Toggle
                </label>
              </span>

              <span className={styles.emailnotificationsoptions}>
                <p>Twitter</p>
                <input
                  type="checkbox"
                  id="Twitter"
                  value={checkboxes.twitter}
                  onClick={setTweetShow}
                  checked={checkboxes.twitter === 'Y'}
                />
                <label className={styles.inputlabel} htmlFor="Twitter">
                  Toggle
                </label>
              </span>
              <span
                className={`${styles.emailnotificationsoptions} ${styles.lastoption}`}
              >
                <p>LinkedIn</p>
                <input
                  id="LinkedIn"
                  type="checkbox"
                  value={checkboxes.linkedin}
                  onClick={setLinkedinShow}
                  checked={checkboxes.linkedin === 'Y'}
                />
                <label className={styles.inputlabel} htmlFor="LinkedIn">
                  Toggle
                </label>
              </span>

              <FBModal show={checkboxes.facebook === 'Y'} onHide={setfbshow} />
              <TwitterModal show={checkboxes.twitter === 'Y'} onHide={setTweetShow} />
            </div> */}

            <div
              ref={darkModeRef}
              className={`${styles.sidebarchildoptions}  ${isActive.darkMode && styles.focusapperance
                } ${isChange && styles.sidebarchildoptionsdarkTheme}`}
            >
              <div className={styles.sidebarchildoptionsocialmediapswdchange}>
                <img
                  src={darkmodeicon}
                  alt="darkmode"
                  className={`${styles.settingsicon} ${styles.socialmedia} ${isChange && styles.buttontextdarkTheme
                    }`}
                />
                <p className={styles.sidebarchildoptionsheader1}>
                  <b>Darkmode</b>
                </p>
              </div>
              <span className={styles.emailnotificationsoptions}>
                <p>Enable </p>
                <input type="checkbox" id="Enable" onChange={onThemeChange} />
                <label className={styles.inputlabel} htmlFor="Enable">
                  Toggle
                </label>
              </span>
            </div>

            <div
              ref={passwordChangeRef}
              className={`${styles.sidebarchildoptions} ${isActive.passwordChange && styles.focusapperance
                } ${isChange ? styles.sidebarchildoptionsdarkTheme : ""}`}
            >
              <div className={styles.sidebarchildoptionsocialmediapswdchange}>
                <img
                  src={PasswordIcon}
                  alt="Passwordchange"
                  className={`${styles.settingsicon} ${styles.password} ${isChange && styles.buttontextdarkTheme
                    }`}
                />

                <p className={styles.sidebarchildoptionsheader2}>
                  <b>Password Change</b>
                </p>
              </div>

              <span className={styles.resetpassword}>
                <p className={styles.passwordlabel}>Current Password</p>
                <div>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                  >
                    <Input
                      helpertext="incorrect input"
                      type={pwdCr.showPassword ? "text" : "password"}
                      value={pwdCr.password}
                      inputProps={{ maxLength: 50 }}
                      onChange={handleChange1("password")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword1}
                            className={styles.eyeshowhide}
                          >
                            {pwdCr.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </span>
              <p className={styles.message}>{msgCr}</p>
              <span className={styles.resetpassword}>
                <p className={styles.passwordlabel}>New Password</p>
                <div>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                  >
                    <Input
                      type={pwdNw.showPassword ? "text" : "password"}
                      value={pwdNw.password}
                      inputProps={{ maxLength: 50 }}
                      onChange={handleChange2("password")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword2}
                          >
                            {pwdNw.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </span>
              <p className={styles.message}>{msgNw}</p>
              <span className={styles.resetpassword}>
                <p className={styles.passwordlabel}>Confirm New Password</p>
                <div> <FormControl
                  className={clsx(classes.margin, classes.textField)}
                >
                  <Input
                    type={pwdCn.showPassword ? "text" : "password"}
                    value={pwdCn.password}
                    inputProps={{ maxLength: 50 }}
                    onChange={handleChange3("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword3}
                          onMouseDown={handleMouseDownPassword3}
                        >
                          {pwdCn.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                </div>
              </span>
              <p className={styles.message}>{msgCn}</p>
              <Button className={styles.submit} onClick={passwordChangehandler}>
                Save
              </Button>

              <p className={styles.message}>{msg}</p>
            </div>
            <div
              className={`${styles.sidebarchildoptions} ${styles.signoutbutton
                } ${isChange && styles.sidebarchildoptionsdarkTheme}`}
            >
              <IconButton
                type="submit"
                className={`${styles.signout} ${isChange ? styles.submitdarkTheme : ""
                  }`}
                onClick={signOut}
              >
                <img
                  src={Signouticon}
                  className={isChange ? styles.buttontextdarkTheme : ""}
                  alt="SignOut"
                />
                &nbsp;Sign Out
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Settings;
