import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Redirect } from "react-router-dom";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import * as CryptoJS from "crypto-js";
import { calcMD5 } from "src/md5Forgot.js";
import Api from 'src/network/index.js';
import ForgotPassword from "../ForgotPassword";
import FbAuth from "../FbAuth.js";
import apple from "src/Assets/images/LandingPageImages/apple.png";
import * as SignInActions from "src/store/actions/SignInActions/SignInAction.js";
import { emailRegex } from "src/Constants/regex";
import style from "./SignIn.module.css";
import AppleLogin from 'react-apple-login';
import "src/Assets/css/LandingPage/LandingPage.css";
import GoogleSignIn from "../GoogleSignIn.js";
import { AppleButton } from "../SocialButtons";

// const AppleClientId = 'com.skopic.web'; // Service Id
// const AppleRedirectUri = 'https://dev.skopic.com/About' // Your redirect URI

// const AppleClientId = 'com.iphone.skopic.service';
// const AppleRedirectUri = 'https://skopic.com/apple/redirect_url.html';

const AppleClientId = 'com.skopic.stagetest.service'; // Service Id
const AppleRedirectUri = 'https://dev.skopic.com/About' // Your redirect URI


const schema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, 'Please enter a valid email address')
    .required('Email address is required'),
  password: yup
    .string()
    .typeError('Please enter a valid password')
    .min(8, 'Password should be minimum 8 characters')
    .required('Password is required'),
});

function SignIn() {
  const dispatch = useDispatch();
  const SignInReducer = useSelector((state) => state.SignInReducer);
  const {
    isloading: loadingStatus,
    errorMessage: message,
    isAuthorized: authenticationVerficationStatus
  } = SignInReducer || {};

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const methods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });
  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = methods;

  const [isForgotPswdClicked, setIsForgotPswdClicked] = useState(false);

  const activiationCheckHandler = async () => {
    try {
      const isValid = await trigger("email");
      if (!isValid) return;
      const email = getValues()?.email;
      if (email) {
        await Api.validateActivationStatus(email);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const submitHandler = (data) => {
    const { email, password } = data || {};
    // e.preventDefault();
    let encodedEmail = CryptoJS.AES.encrypt(email, "skopic").toString();
    let encodedPassword = calcMD5(password);
    let formData = new FormData();
    formData.append("j_username", encodedEmail);
    formData.append("j_password", encodedPassword);
    formData.append("si_remember", "_spring_security_remember_me=true");

    dispatch(SignInActions.signinUser(formData));
  };

  // const buttonDisablerHandler = () => {
  //   if (
  //     !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ||
  //     pwdCr.password.length < 8
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // let isEnabled = buttonDisablerHandler();

  const handlePasswordVisibility = () => {
    setIsPasswordVisible((isPasswordVisible) => !isPasswordVisible);
  };

  const onMouseDown = (event) => {
    event.preventDefault();
  };

  const handleAppleSignIn = () => {
    // appleKeyId=8F33F9LRD3
    // appleTeamId=WUZBEW4298
    // appleClientId =com.skopic.ios
    const scope = 'name email'; // Adjust as necessary
    const state = Math.random().toString(36).substring(2); // Random state for CSRF protection
    const responseType = 'code'; // Can also be 'id_token'
    const responseMode = "form_post";
    const url = `https://appleid.apple.com/auth/authorize?response_type=${responseType}&client_id=${AppleClientId}&redirect_uri=${AppleRedirectUri}&state=${state}&scope=${scope}&response_mode=${responseMode}`;
    window.location.href = url; // Redirect to Apple Sign In
  };

  const onSuccess = (response) => {
    console.log('Apple Sign In Success:', response);
    // Send the response to your backend for further processing
  };

  const onError = (error) => {
    console.error('Apple Sign In Error:', error);
  };

  if (authenticationVerficationStatus) {
    return <Redirect to="/Home" />;
  }

  return (
    <React.Fragment>
      <div className={style.Signin}>
        <div className={style.SigninFrom}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className={style.SigninFromEmail}>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={value}
                    onChange={onChange}
                    onBlur={activiationCheckHandler}
                    className={style.textField}
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </div>
            {errors?.email && (
              <p className={style.ErrorMsg}>{errors?.email?.message}</p>
            )}
            <div className={style.SigninFromPassword}>
              <Controller
                name="password"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={value}
                    onChange={onChange}
                    // onFocus={activiationCheckHandler}
                    className={style.textField}
                    inputProps={{ maxLength: 32 }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handlePasswordVisibility}
                          onMouseDown={onMouseDown}
                          edge="end"
                        >
                          {isPasswordVisible ? <IoEyeOffOutline /> : <IoEyeOutline />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
            </div>
            {errors?.password && (
              <p className={style.ErrorMsg}>{errors?.password?.message}</p>
            )}
            <p className={style.ForgotPassowrd}>
              <ForgotPassword setIsForgotPswdClicked={setIsForgotPswdClicked} />
            </p>
            <button
              type="submit"
              // disabled={isEnabled}
              // disabled={!isValid}
              className={style.SigninButton}
            >
              {loadingStatus && !isForgotPswdClicked ? (
                <CircularProgress style={{ width: "20px", height: "20px" }} />
              ) : (
                <>Sign In</>
              )}
            </button>
            <p className={style.StatusMessage}>{message}</p>
          </form>
        </div>
        <div className={style.Changesignin}>
          <div className={style.line} />
          <span>or</span>
          <div className={style.line} />
        </div>
        <div className={style.SocialIcon}>
          {/* <img
            src={google_new}
            alt="googleImage"
            className={style.SocailImage}
          />
          <img
            src={facebook}
            alt="FacebookImage"
            className={style.SocailImage}
          /> */}
          <GoogleSignIn />
          <div style={{ margin: "10px 0px" }} />
          <AppleButton onClick={handleAppleSignIn} />
          {/* <AppleLogin
            clientId={AppleClientId} // Your Services ID
            redirectURI={AppleRedirectUri} // Your redirect URI
            responseType="code" // Or 'id_token'
            responseMode="form_post"
            onSuccess={onSuccess}
            onError={onError}
          /> */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default SignIn;
