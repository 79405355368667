import React from "react";
import { CommunityContext } from "src/context/CommunityContext/CommunityContext";

export function withCommunity(WrappedComponent) {
  return function WithCommunity(props) {
    return (
      <CommunityContext.Consumer>
        {(contexts) => <WrappedComponent {...props} communityContext={{ ...contexts }} />}
      </CommunityContext.Consumer>
    )
  }
}
