// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-community-list-card-container {
  background: #ffffff;
  border-radius: 28px;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
  /* min-height: 8vh; */
  height: 14vh;
}

.default-community-list-card-button-container {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 10px;
}

.selected-community {
  border: 3.5px solid #4795de;
}

.make-default-button-follow-more-community {
  height: 30px;
  border-radius: 35px;
  text-transform: none !important;
  margin-right: 15px !important;
  outline: none !important;
}

.view-activity-button-follow-more-community {
  height: 30px;
  border-radius: 35px;
  text-transform: none !important;
  outline: none !important;
}

.make-default-image-follow-more-community {
  margin-right: 2px;
  height: 11px;
  margin-bottom: 3px;
  outline: none !important;
}

.default-community-list-card-image {
  border-radius: 28px;
  max-width: 100%;
  min-height: 14vh;
}`, "",{"version":3,"sources":["webpack://./src/Components/DefaultCommunity/communitiesList/index.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,0CAA0C;EAC1C,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,iCAAiC;EACjC,2BAA2B;EAC3B,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,+BAA+B;EAC/B,6BAA6B;EAC7B,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,+BAA+B;EAC/B,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".default-community-list-card-container {\r\n  background: #ffffff;\r\n  border-radius: 28px;\r\n  margin-bottom: 10px;\r\n  cursor: pointer;\r\n  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);\r\n  /* min-height: 8vh; */\r\n  height: 14vh;\r\n}\r\n\r\n.default-community-list-card-button-container {\r\n  display: flex;\r\n  flex-direction: column !important;\r\n  justify-content: flex-start;\r\n  align-items: flex-start;\r\n  padding: 5px 10px;\r\n}\r\n\r\n.selected-community {\r\n  border: 3.5px solid #4795de;\r\n}\r\n\r\n.make-default-button-follow-more-community {\r\n  height: 30px;\r\n  border-radius: 35px;\r\n  text-transform: none !important;\r\n  margin-right: 15px !important;\r\n  outline: none !important;\r\n}\r\n\r\n.view-activity-button-follow-more-community {\r\n  height: 30px;\r\n  border-radius: 35px;\r\n  text-transform: none !important;\r\n  outline: none !important;\r\n}\r\n\r\n.make-default-image-follow-more-community {\r\n  margin-right: 2px;\r\n  height: 11px;\r\n  margin-bottom: 3px;\r\n  outline: none !important;\r\n}\r\n\r\n.default-community-list-card-image {\r\n  border-radius: 28px;\r\n  max-width: 100%;\r\n  min-height: 14vh;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
