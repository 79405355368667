import axios from "axios";
import * as Cookies from "js-cookie";
import {
  asksData,
  auth,
  feedData,
  saysData,
  tagsData,
  invite,
  notifications,
  searchData,
  moderator,
  communities,
  follows,
  votes,
  updatesData,
  posts,
  profile,
  community,
  settings,
  footer,
  activity,
  timeline,
  privateTags,
  appleUrls,
} from "./configuration";

const client = axios.create({
  baseURL: process.env.REACT_APP_SERVICES_API,
});
// client.defaults.withCredentials = false;
// client.defaults.timeout = 35000;
// client.defaults.headers = {
//   "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//   "Access-Control-Allow-Origin": "*",
//   // "Set-Cookie": Cookies.get("JSESSIONID"),
// };

client.defaults.withCredentials = true;
client.defaults.timeout = 35000;
// client.defaults.headers = {
//   // "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//   "Access-Control-Allow-Origin": "*",
//   "Set-Cookie": Cookies.get("JSESSIONID"),
// };

class Requests {
  static setApiHeaders(sessionId) {
    console.log("setApiHeaders sessionId", sessionId);
    // const arr = sessionId.split('=');
    //remove comments once backend api issue is fixed
    client.defaults.headers["Set-Cookie"] = sessionId;
    client.defaults.headers["Jsession-Id"] = sessionId;
  }

  static verifyEmail = (payload) => client.post(auth.verifyEmail, payload);

  static fetchFeedData = (filterType, startLimit) => {
    let url = `${feedData.fetchFeedData}?startlimit=${startLimit}`;
    if (filterType) {
      url = `${feedData.fetchFeedData}?filtertype=${filterType}&startlimit=${startLimit}`;
    }
    return client.get(url);
  };

  static fetchUserSettings = () => client.get(feedData.fetchUserSettings);

  static fetchTrendingTags = () => client.post(tagsData.fetchTrendingTags);

  static fetchTrendingAsks = () => client.post(asksData.fetchTrendingAsks);

  static fetchUserNames = (startingChar) =>
    client.get(feedData.fetchUserNames + startingChar);

  static fetchContributors = () => client.get(feedData.fetchContributors);

  static inviteGmailContacts = (emailParam, textMsg, subject) =>
    client.get(
      `${invite.inviteGmailContacts}?inviteEmailID=${emailParam}&inviteMessage=${textMsg}&inviteSubject=${subject}&reInviteActivity=&reInviteId=`
    );

  static fetchAsksData = (data, latLong, loc, postOption) => {
    const url = `${asksData.fetchAsksData}?userLoc=&msgLoc=${latLong}&locName=${loc}&posttype=${postOption}`;
    return client.post(url, data);
  };

  static fetchAsksSearchResultData = (payload) => {
    const url = asksData.fetchAsksSearchResultData
    return client.post(url, payload);
  };

  static fetchSaysData = (data, latLong, loc, postOption) => {
    const url = `${saysData.fetchSaysData}?&userLoc=&msgLoc=${latLong}&locName=${loc}&onlyASK=YES&posttype=${postOption}`;
    return client.post(url, data);
  };

  static fetchAllTags = () => client.post(tagsData.fetchAllTags);

  static fetchAllAsks = () => client.post(asksData.fetchAllAsks);

  static fetchNotifications = (filterType) => {
    if (filterType) {
      return client.get(
        `${notifications.fetchNotifications}?filtertype=${filterType}`
      );
    }
    return client.get(notifications.fetchNotifications);
  };

  static getNotificationStatus = (id) =>
    client.get(notifications.getNotificationStatus + id);

  static removeNotification = (id) =>
    client.get(notifications.removeNotification + id);

  static unfollowNotification = (id) =>
    client.get(notifications.unfollowNotification + id);

  static getSearchData = (payload) =>
    client.post(searchData.getSearchData, payload);

  static fetchTagDetails = (tenantId, id) =>
    client.get(
      `${tagsData.fetchTagDetails}${id}&tenantID=${tenantId}&id=${id}`
    );

  //no clarity on these api, so using reducer for now
  static fetchModeratingCommunityList = (payload) =>
    client.post(moderator.fetchModeratingCommunityList, payload);

  // static fetchNearByCommunitysData = (params) => client.get(communities.fetchNearByCommunities + params);

  static changeCommunity = (name, id) =>
    client.post(
      `${communities.changeCommunity}name=${name}&redirectTenantId=${id}`
    );

  static fetchLoginUser = () => client.get(auth.fetchLoginUser);

  static fetchChildSaysData = (id, popCols, limit) => {
    if (popCols && limit) {
      return client.post(
        `${saysData.fetchChildSaysData}?id=${id}&in_pop_columns=all&in_limit=0`
      );
    } else {
      return client.post(`${saysData.fetchChildSaysData}?id=${id}`);
    }
  };

  static fetchFollowUnfollowData = (id, status) =>
    client.post(`${follows.fetchFollowUnfollowData}?messageID=${id}&${status}`);

  static activityFollowUnfollowData = (payload) =>
    client.post(follows.fetchFollowUnfollowData, payload);

  // static activityFollowUnfollowAskData = () =>
  //   client.get(follows.fetchFollowUnfollowAskData);

  static fetchVoteUpStatus = (id, status) =>
    client.post(`${votes.fetchVoteUpStatus}id=${id}&&cacheStatus=${status}`);

  static editUpdate = (id, msg) =>
    client.post(
      `${updatesData.editUpdate}announceID=${id}&message=${msg}&ident=actUpdate&argval=share&timeZone=IST`
    );

    static editUpdateLocation = (payload) =>
      client.post(
        `${updatesData.editUpdate}ident=actUpdate&argval=share&timeZone=IST`, payload
      );
  

  static editPostLocation = (payload) => {
    return client.post(feedData.editPost, payload);
  };

  
  static editPost = (id, msg) => {
    return client.post(`${feedData.editPost}messageID=${id}&message=${msg}`);
  };

  static askYourCommunity = (id) =>
    client.post(`${communities.askYourCommunity}${id}`);

  static fetchPrivateTagsData = (id, limiter, params) => {
    if (params) {
      return client.get(`${tagsData.fetchPrivateTagsData}${params}`);
    } else if (limiter) {
      return client.get(
        `${tagsData.fetchPrivateTagsData}tenantId=${id}&limiter=all`
      );
    } else {
      return client.get(`${tagsData.fetchPrivateTagsData}tenantId=${id}`);
    }
  };

  static getCommunityMembers = (data) =>
    client.post(communities.getCommunityMembers, data);

  static sendInvite = (data) => client.post(invite.sendInvite, data);

  static sendEmailInvite = (emailsParam, textmsg, subject) =>
    client.post(
      `${invite.sendEmailInvite}${emailsParam}&inviteMessage=${textmsg}&inviteSubject=${subject}&reInviteActivity=&reInviteId=`
    );

  static postChildSayData = (id, data, lnglat, locSelect) => {
    const url = `${saysData.postChildSayData}?Parent_ID=${id}&userLoc=&msgLoc=${lnglat}&locName=${locSelect}`;
    return client.post(url, data);
  };

  static editBio = (text) => client.post(`${feedData.editBio}${text}`);

  static postMail = (id, comment) =>
    client.post(
      `${invite.postMail}?mid=${id}&comment=${comment}&checkHsh=message`
    );

  static checkIfTagExists = (url, payload) =>
    client.post(`${tagsData.checkIfTagExists}${url}`, payload);

  static postNewTag = (data) => client.post(`${tagsData.postNewTag}${data}`);
  // static requestToFollow = (id) => client.post(`${invite.requestToFollow}?mid=${id}&comment=${comment}&checkHsh=message`);
  static requestToFollow = (payload) =>
    client.post(invite.requestToFollow, payload);

  static checkIfContainsTag = (msg, lnglat, locSelect, tenantIds) =>
    client.post(
      `${updatesData.checkIfContainsTag}message=${msg}&ident=impupdate&updateReminderTime=&jsReminderTime=&timeZone=IST&userLoc=&msgLoc=${lnglat}&locName=${locSelect}&reqTenantIds=${tenantIds}&msgType=I`
    );

  static postNewUpdate = (data, lnglat, locSelect, tenantIds) => {
    const url = `${updatesData.postNewUpdate}ident=impupdate&updateReminderTime=&jsReminderTime=&timeZone=IST&userLoc=&msgLoc=${lnglat}&locName=${locSelect}&reqTenantIds=${tenantIds}&msgType=I`;
    return client.post(url, data);
  };

  static deleteMessage = (id) =>
    client.post(`${posts.deleteMessage}?messageID=${id}`);
  static deleteAskTagPostUnderSayActivity = (payload) =>
    client.post(posts.deleteAskTagPostUnderSayActivity, payload);
  static getActivityPostCount = () => client.get(posts.getActivityPostCount);

  static getReportPostStatus = (id, param) =>
    client.post(`${posts.getReportPostStatus}?messageid=${id}&param=${param}`);

  static reportPost = (id, reason) =>
    client.post(
      `${posts.reportPost}?messageid=${id}&reason=${reason}&param=AskorSay`
    );

  static validateTempUserEmail = (payload) =>
    client.post(auth.validateTempUserEmail, payload);

  static validateActivationStatus = (email) =>
    client.post(`${auth.validateActivationStatus}${email}`);

  static fetchCountry = () => client.get(auth.fetchCountry);

  static fetchCountryWithCommunity = () => client.get(auth.fetchCountryWithCommunity);

  static sendVerificationCode = (payload, redirectTenantId) =>
    client.post(`${auth.sendVerificationCode}${redirectTenantId}`, payload);

  static resendVerificationCode = (payload) =>
    client.post(auth.resendVerificationCode, payload);

  static createSixdigitcodeUser = (payload, sixDigitCode) =>
    client.post(
      auth.createSixdigitcodeUser.replace("{{sixDigitCode}}", sixDigitCode),
      payload
    );

  static validateFBToken = (token) =>
    client.post(`${auth.validateFBToken}${token}`);

  static facebookLogin = () => client.get(auth.facebookLogin);

  static validateGoogleToken = (googleToken) =>
    client.post(
      auth.validateGoogleToken.replace("{{googleToken}}", googleToken)
    );

  static getUserData = (userTenantId, userTenant) => {
    let url = auth.getUserData.replace("{{userTenantId}}", userTenantId);
    url = url.replace("{{userTenant}}", userTenant);
    return client.post(url);
  };

  static updateContactInfo = (payload) =>
    client.post(profile.updateContactInfo, payload);

  static updateOptionalInfo = (payload) =>
    client.post(profile.updateOptionalInfo, payload);

  static followCommunity = (payload) =>
    client.post(community.followCommunity, payload);

  static updateDefaultTenant = (payload) =>
    client.post(community.updateDefaultTenant, payload);

  static makeAsModerator = (payload) =>
    client.post(moderator.makeAsModerator, payload);

  static getFollowingUsers = (payload) =>
    client.post(moderator.getFollowingUsers, payload);

  static getCommunity = () => client.get(community.getCommunity);

  static getTenantList = () => client.get(community.getTenantList);

  static createNewCommunity = (payload) =>
    client.post(community.createNewCommunity, payload);

  static getUserAsks = (count) =>
    client.post(
      `${profile.getUserAsks}${count}&isNewUiVersion=yes`
    );

  static getUserInvitations = (count) =>
    client.post(`${profile.getUserInvitations}${count}`);

  static inviteFriend = (email, msg, subject, id) =>
    client.post(
      `${profile.inviteFriend}inviteEmailID=${email}&inviteMessage=${msg}&inviteSubject=${subject}&reInviteActivity=&reInviteId=${id}`
    );

  static getPendingInvites = (count) =>
    client.get(`${profile.getPendingInvites}${count}`);

  static joinCommunity = (id) => client.get(`${profile.joinCommunity}${id}`);

  static getUserTags = (count) =>
    client.post(`${profile.getUserTags}breadCountLength=${count}`);

  static getUserUpdates = (count, page) =>
    client.post(
      `${profile.getUserUpdates}breadCountLength=${count}&page=${page}`
    );

  static getProfileChildSays = (id) =>
    client.post(`${profile.getProfileChildSays}id=${id}`);

  static postProfileChildSays = (loc, payload) =>
    client.post(
      `${profile.postProfileChildSays
      }userLoc=${""}&msgLoc=${""}&locName=${loc}`,
      payload
    );

  static getChildHashTag = (payload) =>
    client.post(`${profile.getChildHashTag}`, payload);

  static getUserSays = (count, page) =>
    client.post(
      `${profile.getUserSays}breadCountLength=${count}&isNewUiVersion=yes&page=${page}`
    );

  static deleteProfilePost = (payload) =>
    client.post(profile.deleteProfilePost, payload);
  static shareUnshareUpdate = (id, shareStatus) =>
    client.post(
      `${profile.shareUnshareUpdate}announceID=${id}&isShare=${shareStatus}`
    );

  static updateProfilePost = (locName, payload) =>
    client.post(
      `${profile.updateProfilePost
      }userLoc=${""}&msgLoc=${""}&locName=${locName}`,
      payload
    );

  static updateUserSettings = (payload) =>
    client.post(settings.updateUserSettings, payload);

  static updatePassword = (payload) =>
    client.post(settings.updatePassword, payload);

  static fetchFollowerCount = (id, type) =>
    client.get(`${follows.fetchFollowerCount}${id}/type/${type}`);

  static postContact = (payload) => client.post(footer.postContact, payload);

  static getTimeLineImages = (params) =>
    client.post(`${settings.getTimeLineImages}?${params}`);

  static setModeratorPermission = (payload) =>
    client.post(moderator.setModeratorPermission, payload);

  static scheduleNewsletter = (payload) =>
    client.post(moderator.scheduleNewsletter, payload);

  static setCommunityType = (payload) =>
    client.post(moderator.setCommunityType, payload);

  static deleteImages = (imageIds, tenantId) =>
    client.delete(
      `${settings.getTimeLineImages}?imageIds=${imageIds}&tenantId=${tenantId}`
    );

  static updateTimelinePhotoDesc = (imageIds, tenantId, tmlPhotoDesc) =>
    client.post(
      `${moderator.updateTimelinePhotoDesc}?imageId=${imageIds}&tenantId=${tenantId}&tmlPhotoDesc=${tmlPhotoDesc}`
    );

  static submitBoundary = (payload) =>
    client.post(moderator.submitBoundary, payload);

  static inviteFriend = (inviteEmailID, inviteMessage, inviteSubject) =>
    client.get(
      `${moderator.inviteFriend}?inviteEmailID=${inviteEmailID}&inviteMessage=${inviteMessage}&inviteSubject=${inviteSubject}`
    );

  static getModeratorActionItems = (id) =>
    client.get(`${moderator.getModeratorActionItems}${id}`);

  static moderatorApproveDeny = (payload) =>
    client.post(moderator.moderatorApproveDeny, payload);

  static fetchActivityLogData = (id, type) =>
    client.get(
      `${moderator.fetchActivityLogData}tenantId=${id}&type=${type}&isNewUiVersion=yes`
    );

  static itemPostApproveDeny = (payload) =>
    client.post(moderator.itemPostApproveDeny, payload);

  static getModeratingCommunities = () =>
    client.post(moderator.getModeratingCommunities);

  static getNearByUserCommunities = () =>
    client.get(communities.getNearByUserCommunities);

  static getNearByCommunities = () =>
    client.get(communities.getNearByCommunities);

  static getCommunitiesAfterSignIn = () =>
    client.get(communities.getCommunitiesAfterSignIn);

  static setDefaultTenant = (payload) =>
    client.post(communities.setDefaultTenant, payload);

  static followUnfollowCommunity = (payload) =>
    client.post(communities.followUnfollowCommunity, payload);

  static getTenantDetails = (id) =>
    client.get(
      `${auth.getTenantDetails
      }src=inviteLink&tid=${id}&id=${"Z2Vmb3lpNDMzOEBmcmFuZGluLmNvbQ=="}`
    );

  static fetchImages = () => client.get(activity.fetchImages);

  static fetchActivityFeed = (page) =>
    client.get(`${activity.fetchActivityFeed}startlimit=${page}&limiter=all`);

  static fetchActivityAsks = (page) =>
    client.post(
      `${activity.fetchActivityAsks}task=asks&more=all&breadCountLength=${page}`
    );

  static fetchActivityTags = (page) =>
    client.post(
      `${activity.fetchActivityAsks}task=tags&more=all&breadCountLength=${page}`
    );

  static fetchActivityChildSays = (payload) =>
    client.post(activity.fetchActivityChildSays, payload);

  static fetchActivity = () => client.get(activity.fetchActivity);

  static uploadImage = (tenantId, payload) =>
    client.post(`${moderator.uploadImages}?tenantId=${tenantId}`, payload);

  static deleteTimeLineImage = (params, payload) =>
    client.post(`${timeline.deleteTimeLineImage}?${params}`, payload);

  static getMembersData = (id) =>
    client.post(`${moderator.getMembersData}${id}`);

  static blockUser = (data) => client.post(`${moderator.blockUser}${data}`);

  static getBlockedUsers = (data) =>
    client.post(moderator.getBlockedUsers, data);

  static warnUser = (data) => client.post(moderator.warnUser, data);

  static fetchInviteMembersData = (data) =>
    client.post(moderator.fetchInviteMembersData, data);

  static inviteAsModerator = (data) =>
    client.post(moderator.inviteAsModerator, data);

  static updateProfileInfo = (data) =>
    client.post(profile.updateProfileInfo, data);

  static getProfile = () => client.get(profile.getProfile);

  static updateCommunityInfo = (data) =>
    client.post(timeline.updateCommunityInfo, data);

  static deletePrivateTag = (data) =>
    client.delete(privateTags.deletePrivateTag + data);

  static editPrivateTag = (data, userLoc = "", msgLoc = "", locName = "") =>
    client.post(
      `${privateTags.editPrivateTag}?userLoc=${userLoc}&msgLoc=${msgLoc}&locName=${locName}`,
      data
    );

  static raiseModeratorRequest = (data) =>
    client.post(moderator.raiseModeratorRequest, data);
  static editMessageActivityChildQ = (locName = "", payload) =>
    client.post(
      `${activity.editMessageActivityChildQ
      }userLoc=${""}&msgLoc=${""}&locName=${locName}`,
      payload
    );

  static updateProfilePic = (data) =>
    client.post(profile.updateProfilePic, data);

  static unfollowActivityTag = (id) =>
    client.post(
      `${profile.unfollowActivityTag}?messageID=${id}&cacheStatus=cacheUpdate`
    );

  static tagSearch = (payload) => client.post(feedData.tagSearch, payload);

  static fetchGmailContacts = (authCode) =>
    client.get(`${auth.fetchGmailContacts}?code=${authCode}&app_type=web`);

  static appleSignup = (payload) =>
    client.post(appleUrls.signUp, payload);

  static uploadCommunityImage = (tenantId, payload) =>
    client.post(`${moderator.uploadCommunityImage}${tenantId}`, payload);
}

export default Requests;
