import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import * as ForgotpassAction from "src/store/actions/Forgotpassword/ForgotpassAction";
import "../ForgotPassword/ForgotPassword.css";
import { Controller, useForm } from "react-hook-form";
import { emailRegex } from "src/Constants/regex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "16px",
  textAlign: "center",
  // boxShadow: 2,
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 3,
};

const schema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, 'Please enter a valid email address')
    .required('Email address is required'),
});

const ForgotPassword = (props) => {
  const responseErrorMsg = useSelector(
    (state) => state.NewforgotpassReducer?.message
  );

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [maskedEmail, setMaskedEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    if (responseErrorMsg !== "" && responseErrorMsg) {
      if (responseErrorMsg === "Fail") {
        setErrorMessage("There is no account associated with this email.");
      } else if (responseErrorMsg === "invalid") {
        setErrorMessage("Please enter valid email.");
      } else {
        setSuccess(true);
        setErrorMessage("");
      }
    }
  }, [responseErrorMsg]);

  useEffect(() => {
    if (!open) {
      setErrorMessage("");
      setMaskedEmail("");
      setSuccess(false);
    }
  }, [open],);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onResetPassword = (data) => {
    const { email } = data || {};
    props.setIsForgotPswdClicked(true);
    dispatch(ForgotpassAction.NewForgotpass(email));
    const maskedEmail = maskEmail(email);
    setMaskedEmail(maskedEmail);
  };

  const maskEmail = (email) => {
    const str = email.split("");
    let finalArr = [];
    const len = str.indexOf("@");
    str.forEach((item, pos) => {
      pos >= 1 && pos <= len - 2 ? finalArr.push("*") : finalArr.push(str[pos]);
    });
    finalArr.join("");
    return finalArr;
  };

  return (
    <>
      <span variant="contained" onClick={handleOpen}>
        Forgot Password?
      </span>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        {isSuccess ? (
          <SuccessContent handleClose={handleClose} maskedEmail={maskedEmail} />
        ) : (
          <Form
            handleClose={handleClose}
            onResetPassword={onResetPassword}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        )}
      </Modal >
    </>
  );
};

export default ForgotPassword;

const Form = ({ handleClose, onResetPassword, errorMessage, setErrorMessage }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(schema),
  });

  const errorMsg = errors?.email
    ? errors?.email?.message
    : errorMessage
      ? errorMessage
      : "";

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      e.stopPropagation();
      handleSubmit(onResetPassword)();
    }}>
      <Box sx={{ ...style, width: 380, backgroundColor: "#EFEEED", paddingTop: "52px", paddingBottom: "4px" }}>
        <h4 id="parent-modal-title">Forgot password?</h4>
        <p id="parent-modal-description">
          Enter your email to reset password
        </p>

        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value } }) => (
            <TextField
              id="standard-basic"
              variant="standard"
              type="text"
              placeholder="Email"
              name="email"
              value={value}
              onChange={(event) => {
                onChange(event);
                setErrorMessage("");
              }}
              sx={{
                borderBottom: "1px solid grey",
                width: "75%",
              }}
              inputProps={{ maxLength: 50 }}
            />
          )}
        />
        {errorMsg && (
          <p style={{ fontSize: "14px" }} className="errorpop">
            {errorMsg}
          </p>
        )}
        <Button
          // id="postSubmitButton"
          type="submit"
          size="large"
          className="resetButtonEnabled"
          variant="text"
        >
          Reset Password
          <hr />
        </Button>
        <br />

        <p style={{ color: "grey", marginTop: "10px", cursor: "pointer" }} onClick={handleClose}>
          Cancel
        </p>
      </Box>
    </form>
  );
}

const SuccessContent = ({ handleClose, maskedEmail }) => {
  return (
    <Box sx={{ ...style, width: 410, height: 230 }}>
      <h5 id="child-modal-title">
        A temporary password
        <br /> has been sent to
      </h5>

      <p>{maskedEmail}</p>
      <Button
        variant="text"
        style={{ color: "grey", fontSize: "0.938rem" }}
        onClick={handleClose}
      >
        Ok
      </Button>
    </Box>
  );
}
