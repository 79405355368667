import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useGoogleLogin } from "react-google-login";
import { SignupContext } from "src/context/SignupContext/SignupContext";
import Api from "src/network";
import SignUpRepo from "src/repo/signup_repo";
import { GoogleButton } from "./SocialButtons";
import DefaultCommModal from "./DefaultCommModal";
import EmailVerificationModal from "./EmailVerification";

const clientId = "1096351929813-b3sqsgi9t95dfbr7jhsruvsfvh8q6iac.apps.googleusercontent.com";

const defaultState = {
  email: "",
  password: "",
  displayName: "",
  community: "Default Community",
  tenantId: ""
};

function GoogleSignUp() {
  const [loading, setLoading] = useState(false);
  const [showDefaultCommModal, setShowDefaultCommModal] = useState(false);
  const [state, setState] = useState({ ...defaultState });
  const [verificationModal, setVerificationModal] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);

  const dispatch = useDispatch();
  const signupContext = useContext(SignupContext);

  useEffect(() => {
    const { googleSignUp, email, password, displayName, community, tenantId, } = signupContext?.signupState;
    if (googleSignUp) {
      setState((prevState) => ({
        ...prevState,
        email: email,
        password: password,
        displayName: displayName,
        community: community,
        tenantId: tenantId,
      }));
      setShowDefaultCommModal(true);
    }
  }, []);

  const onSuccess = async (res) => {
    console.log(res)
    const googleResponse = {
      name: res.profileObj.name,
      email: res.profileObj.email,
      tokenId: res.tokenId,
      accessToken: res.accessToken,
      givenName: res.profileObj.givenName,
      familyName: res.profileObj.familyName,
      imageUrl: res.profileObj.imageUrl,
    };
    console.log('googleResponse->', googleResponse);

    try {
      const result = await Api.validateGoogleToken(googleResponse.tokenId);
      const { status } = result?.data || {}
      if (status && String(status).toLowerCase() === ("Thank you for SignUp using your Gmail account, Skopic has created a profile for you and a temporary Skopic password has been emailed.").toLowerCase()) {
        signupContext?.setSignupState({
          googleSignUp: true, //set true to navigation purpose
          email: googleResponse?.email,
          password: "",
          displayName: googleResponse?.name,
          community: "",
          tenentId: "",
        });
        setState({
          ...defaultState,
          email: googleResponse?.email,
          displayName: googleResponse?.name,
        });
        setShowDefaultCommModal(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onFailure = (error) => {
    console.error("login failed: ", error);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
  });

  const onCloseModal = () => {
    setShowDefaultCommModal(false);
    //reset all values
    setState({ ...defaultState });
    //reset signup context data
    signupContext?.resetSignupState();
  };

  const onSignUp = async () => {
    console.log(state);
    // return;
    if (state?.community === "Default Community" || loading) return;

    try {
      setLoading(true);
      const result = await SignUpRepo.requestSignUp(
        state?.email,
        state?.password,
        state?.displayName,
        state?.tenantId,
        state?.community,
      );
      if (result.isSuccess) {
        setVerificationModal(true);
        setResendOtp(result.reSend);
        setShowDefaultCommModal(false);
        // reset signup context data
        signupContext?.resetSignupState();
      } else {
        alert(result.message);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <GoogleButton onClick={() => {
        console.log("CLICKED Continue with Google BUTTON")
        signIn();
      }}
      />
      {showDefaultCommModal && (
        <DefaultCommModal
          open={showDefaultCommModal}
          onClose={onCloseModal}
          loading={loading}
          email={state?.email}
          password={state?.password}
          community={state?.community}
          displayName={state?.displayName}
          onSignUp={onSignUp}
        />
      )}

      <EmailVerificationModal
        centered
        show={verificationModal}
        onHide={() => setVerificationModal(false)}
        dispatch={dispatch}
        email={state?.email}
        tentId={state?.tenantId}
        password={state?.password}
        tenantName={state?.community}
        resendOtp={resendOtp}
      />
    </>
  );
}

export default GoogleSignUp;
