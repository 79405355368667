// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-list-item {
  padding: 3px 20px;
  cursor: pointer;
  border:4px solid transparent;
  width: 50%;
  line-height: 1.5;
  text-align: center;
}
.tab-list-active {

   border-radius: 30px;
   color:#7A7A7A; 
   background: transparent; 
   border:4px solid #4795DE;
   font-weight: 600;
   }
   /* Media Quries */
   @media (max-width:1024px){
     .tab-list-item {
         padding: 10px 20px;
     }  
 }`, "",{"version":3,"sources":["webpack://./src/Assets/css/LandingPage/Tab.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,4BAA4B;EAC5B,UAAU;EACV,gBAAgB;EAChB,kBAAkB;AACpB;AACA;;GAEG,mBAAmB;GACnB,aAAa;GACb,uBAAuB;GACvB,wBAAwB;GACxB,gBAAgB;GAChB;GACA,iBAAiB;GACjB;KACE;SACI,kBAAkB;KACtB;CACJ","sourcesContent":[".tab-list-item {\n  padding: 3px 20px;\n  cursor: pointer;\n  border:4px solid transparent;\n  width: 50%;\n  line-height: 1.5;\n  text-align: center;\n}\n.tab-list-active {\n\n   border-radius: 30px;\n   color:#7A7A7A; \n   background: transparent; \n   border:4px solid #4795DE;\n   font-weight: 600;\n   }\n   /* Media Quries */\n   @media (max-width:1024px){\n     .tab-list-item {\n         padding: 10px 20px;\n     }  \n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
