import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Dropdown, Tabs, Tab } from "react-bootstrap";
import notificationstyles from "src/Assets/css/notications/notifications.module.css";
import styles from "src/Assets/css/home/post.module.css";
import dropdownarrow from "src/Assets/images/pulldown.svg";
import removenotitifcationicon from "src/Assets/images/removenotitifcationicon.svg";
import unfollowtagicon from "src/Assets/images/unfollow.svg";
import { Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Avatar, CircularProgress } from "@material-ui/core";
import API from "../../network";
import * as notificationActions from "src/store/actions/notificationsactions/notificationActionCreator";
import AvatarBadge from "src/Components/AvatarBadge";

const Notifications = (props) => {
  const notificationReducerData = useSelector(
    (state) => state.notificationReducer.notificationData
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("show:all");
  const [notificationData, setNotificationData] = useState(notificationReducerData);
  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async (filterType) => {
    try {
      setLoading(true);
      const response = await API.fetchNotifications(filterType);
      setNotificationData(response.data);
      setLoading(false);
      dispatch(notificationActions(response.data));
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const filterNotifications = (event) => {
    if (event.target.value === "all") {
      fetchNotifications();
    } else {
      fetchNotifications(event.target.value);
    }
  };

  const onFilterSelection = (e) => {
    e.preventDefault();
    setFilter(e.target.value);
    filterNotifications(e);
  };

  const viewNotification = async (status, id) => {
    if (status === "unread" || status === null) {
      try {
        const response = await API.getNotificationStatus(id);
        const updatedNotifications = notificationData.map((each) => {
          if (each.id === id) {
            return {
              ...each,
              notificationStatus: response.data,
            };
          } else {
            return each;
          }
        });
        setNotificationData(updatedNotifications);
        dispatch(notificationActions(updatedNotifications));
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onRemoveNotification = async (id) => {
    try {
      await API.removeNotification(id);
      const updatedNotifications = notificationData.filter(
        (each) => each.id !== id
      );
      setNotificationData(updatedNotifications);
      dispatch(notificationActions(updatedNotifications));
    } catch (e) {
      console.error(e);
    }
  };

  const onUnfollowNotification = async (id) => {
    try {
      const unfollowResponse = await API.unfollowNotification(id);
      alert(unfollowResponse.data.status)
    } catch (e) {
      console.error(e);
    }
  };

  const onViewStatus = (status, id) => {
    viewNotification(status, id);
    document.getElementById(id).style.backgroundColor =
      "rgb(243 242 240 / 43%)";
  };

  const onRemove = (id) => {
    onRemoveNotification(id);
    document.getElementById(id).style.display = "none";
    setOpen(false);
  };

  const onUnfollow = (id) => {
    onUnfollowNotification(id);
    setOpen(false);
  };

  const sortedNotifications = notificationData.notificationList
    ? [...notificationData.notificationList].sort((a, b) => new Date(b.Message_Time) - new Date(a.Message_Time))
    : [];

  return (
    <>
      {!loading && notificationData && Object.keys(notificationData).length !== 0 ? (
        sortedNotifications.length > 0 ? (
          <div className={notificationstyles.notificationMainContainer}>
            <div className={notificationstyles.filterdropdown}>
              <h5>Notifications</h5>
              <select
                onChange={(e) => onFilterSelection(e)}
                value={filter}
                className={notificationstyles.selectpulldown}
              >
                <option value="all">Show: All</option>
                <option value="ask">ASK</option>
                <option value="say">SAY</option>
                <option value="update">UPDATE</option>
                <option value="tag">TAG</option>
                <option value="moderator">Moderator</option>
              </select>
            </div>

            {sortedNotifications.length !== 0 ? (
              sortedNotifications.map((notificationdata) => (
                <div
                  className={`${notificationdata.viewstatus === "read"
                    ? notificationstyles.notificationbody
                    : notificationstyles.unreadnotification
                    }`}
                  onClick={() => onViewStatus(notificationdata.viewstatus, notificationdata.id)}
                  key={notificationdata.id}
                  id={notificationdata.id}
                >
                  <div className={notificationstyles.notificationmessagebody}>
                    <div>
                      <AvatarBadge
                        src={`${profileImagePath}${notificationdata.uimage}`}
                        variant="rounded"
                        className={notificationstyles.avatarimage}
                        isPrimaryModerator={notificationdata?.mainuserdata?.isModerator == 1 && notificationdata?.mainuserdata?.isPM == 1}
                        isModerator={notificationdata?.mainuserdata?.isModerator == 1 && notificationdata?.mainuserdata?.isPM != 1}
                      />

                      {notificationdata.keyword === "A" ? (
                        <div className={notificationstyles.askcount}>
                          <span></span>
                        </div>
                      ) : notificationdata.keyword === "S" || notificationdata.keyword === "P" ? (
                        <div className={notificationstyles.saycount}>
                          <span></span>
                        </div>
                      ) : notificationdata.keyword === "I" ? (
                        <div className={notificationstyles.updatecount}>
                          <span></span>
                        </div>
                      ) : notificationdata.keyword === "H" || notificationdata.keyword === "X" ? (
                        <div className={notificationstyles.tagcount}>
                          <span></span>
                        </div>
                      ) : ("")}
                    </div>
                    <div className={styles.mesageandtime}>
                      {/* <p
                        dangerouslySetInnerHTML={{ __html: notificationdata.Message }}
                        className={notificationstyles.msgTxt}
                      ></p> */}
                      <p className={notificationstyles.msgTxt}>{notificationdata.Message} by "{notificationdata.displayName}"</p>
                      <p className={notificationstyles.messagetime}>
                        {notificationdata.Message_Time} ago in {notificationdata.tenantName}
                      </p>
                    </div>
                  </div>

                  <div className={`${styles.dropdown}`}>
                    <a
                      className={`${notificationdata.viewstatus === "read"
                        ? notificationstyles.notificationdropdown
                        : notificationstyles.unreadnotificationdropdown
                        }`}
                      id={notificationdata.id}
                    >
                      <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className={notificationstyles.dropDownBtn}
                      >
                        <img src={dropdownarrow} alt="dropdown" />
                      </button>
                    </a>

                    {open && (
                      <div className={`${styles.dropdownlinks} ${notificationstyles.notificationdropdownlinks}`}>
                        <ul>
                          <li>
                            <a
                              className={notificationstyles.removenotifiction}
                              onClick={() => onRemove(notificationdata.id)}
                            >
                              <img src={removenotitifcationicon} alt="removenotification" />
                              <span>Remove this notification </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p style={{ paddingLeft: 10 }}>No data</p>
            )}
          </div>
        ) : (
          <p style={{ textAlign: 'center' }}>No Data</p>
        )
      ) : (
        <CircularProgress className={notificationstyles.loader} />
      )}
    </>
  );
};

export default Notifications;
