import React from "react";
import { useSelector } from "react-redux";
import style from "src/Assets/css/home/TrendingTagResults.module.css";
import NoDataFound from "src/Assets/images/data-not-found.svg";
import Post from "../components/Post";
import CircularProgress from "@material-ui/core/CircularProgress";
import backwardarrow from "src/Assets/images/backwardarrow.png";

const TrendingAsks = (props) => {
  const {
    allAsks,
    goBack,
    loading,
    childSaysLoading,
    postChildSayData,
    fetchFeedData,
    deleteMessage,
    childSaysData,
    fetchChildSaysData,
    fetchFollowData,
    fetchVoteUpStatus,
    refreshFeedData,
    showData,
  } = props;

  if (loading) {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {allAsks && Object.keys(allAsks).length > 0 ? (
        <div className={style.TagResults}>
          <div className={style.TagResultContainer}>
            <a>
              <img src={backwardarrow} onClick={goBack} />
            </a>
            <span className={style.trendingTagHeading}>Trending ASKs</span>
          </div>
          <Post
            loading={childSaysLoading}
            deleteMessage={deleteMessage}
            fetchFeedData={fetchFeedData}
            listdata={allAsks}
            data={allAsks}
            postChildSayData={postChildSayData}
            childSaysData={childSaysData}
            fetchChildSaysData={fetchChildSaysData}
            fetchFollowData={fetchFollowData}
            fetchVoteUpStatus={fetchVoteUpStatus}
            refreshFeedData={refreshFeedData}
            showData={showData}
          />
        </div>
      ) : (
        <div className={style.nodata}>
          <img src={NoDataFound} alt="NoDataFound" width="200" />
          <p>No Data Found</p>
        </div>
      )}
    </>
  );
};

export default TrendingAsks;
