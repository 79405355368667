import React from "react";
import { CircularProgress } from "@material-ui/core";
import google from "src/Assets/images/google.svg";
import apple from "src/Assets/images/LandingPageImages/apple.png";

const btnStyle = {
  backgroundColor: "#efeeed",
  borderRadius: "25px",
  border: "none",
  display: "flex",
  gap: "10px",
  minWidth: "240px",
  height: "45px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const txtStyle = {
  color: "rgb(112, 112, 112)",
};

export function GoogleButton({ onClick, loading }) {
  return (
    <button style={btnStyle} onClick={onClick} disabled={loading}>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <img
          src={google}
          alt="google"
          style={{ height: 25, width: 25 }}
          className="icon"
        />
      )}
      <span style={txtStyle}>
        Continue with Google
      </span>
    </button>
  );
};

export function AppleButton({ onClick, loading }) {
  return (
    <button style={btnStyle} onClick={onClick} disabled={loading}>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <img
          src={apple}
          alt="apple"
          style={{ height: 28, width: 25 }}
          className="icon"
        />
      )}
      <span style={txtStyle}>
        Continue with Apple
      </span>
    </button>
  );
};
