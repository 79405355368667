import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import { Button as MuiButton, Dialog, DialogTitle, IconButton } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import EditMap from "src/Components/DefaultCommunity/editMap";
import MapIcon from "src/Assets/images/MapIcon.svg";
import * as moderatorActions from "src/store/actions/Moderator/moderatoractions";
import Api from "src/network";
import CommunityImage from "src/Assets/images/ComImage.svg";
import Camera from "src/Assets/images/camera.svg";
import styles from "./CommunityInfo.module.css";
import { compressFile } from "src/Utils/imageCompressionUtil";

const Button = styled(MuiButton)({
  paddingLeft: "25px",
  paddingRight: "25px",
  marginTop: "20px",
  marginBottom: "20px",
  alignSelf: "center",
});

const useStyles = makeStyles({
  paperWidthSm: {
    padding: '20px 15px 15px 15px',
    borderRadius: '25px',
    width: '800px',
    // maxWidth: '800px',
    height: '500px',
    overflow: "hidden",
    maxWidth: "800px !important",
  },
});

function CommunityInfoInput({
  placeholdertext,
  labelText,
  styleClass,
  parentStyleClass,
  country_list,
  state_list,
  value,
  onChangeHandler,
  isInputTextHandler,
  charLength
}) {

  const onValChange = (e) => {
    const newValue = e.target?.value || "";
    let value;
    if (labelText === "Zip Code") {
      value = newValue; //newValue.replace(/\D/g, ""); // Remove non-numeric characters
    } else {
      value = newValue; // Allow all characters for other inputs
    }
    onChangeHandler(value);
  };

  return (
    <div
      className={`${parentStyleClass ? parentStyleClass : "communityInfo-input"}`}
    >
      <form>
        <p>
          {labelText}:<span>*</span>
        </p>
        {isInputTextHandler ? (
          <textarea
            placeholder={placeholdertext}
            className={styleClass}
            value={value}
            onChange={onValChange}
            maxLength={charLength}
          />
        ) : labelText === "Country" ? (
          <Dropdown
            className="country-dropdown"
            options={country_list}
            onChange={onValChange}
            value={value}
          />
        ) : labelText === "State" ? (
          <Dropdown
            className="country-dropdown"
            options={state_list}
            onChange={onValChange}
            value={value}
          />
        ) : (
          <input
            placeholder={placeholdertext}
            className={styleClass}
            value={value}
            maxLength={labelText === "Zip Code" ? 15 : 280}
          />
        )}
      </form>
    </div>
  );
}

const CommunityInfo = (props) => {
  const classes = useStyles();
  const [countries, setCountries] = React.useState([]);
  const [statesList, setStatesList] = React.useState([]);
  const [comName, setComName] = React.useState("");
  const [comDescription, setComDescription] = React.useState("");
  const [comAddress, setComAddress] = React.useState("");
  const [comCity, setComCity] = React.useState("");
  const [comZipcode, setComZipCode] = React.useState("");
  const [comState, setComState] = React.useState("");
  const [comCountry, setComCountry] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [editCommunityItem, setEditCommunityItem] = useState({});
  const [tenant_list, setTenant_list] = useState({});
  const [community_tenant_list, setCommunity_tenant_list] = useState([]);
  const [tenantLat, setTenantLat] = useState("");
  const [tenantLng, setTenantLng] = useState("");
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const ImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const res = await Api.getTenantList();
        setCommunity_tenant_list(res?.data?.tenantList || []);
        setTenant_list(res?.data?.statesList || {});
      } catch (e) {
        console.error(e);
      }
    }

    fetchApi();
    setFile(null);
  }, [props.tenantId]);

  useEffect(() => {
    if (community_tenant_list) {
      const filtered = community_tenant_list.filter(
        (item) => Number(item.id) === props.tenantId
      )[0];
      setEditCommunityItem(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community_tenant_list, props.tenantId]);

  useEffect(() => {
    const {
      name,
      description,
      city,
      address1,
      zipcode,
      country,
      state,
      tenantLatitude,
      tenantLangitude,
    } = editCommunityItem || {};
    setComName(name || "");
    setComAddress(address1 || "");
    setComCity(city || "");
    setComDescription(description || "");
    setComZipCode(zipcode || "");
    setComCountry(country || "");
    setComState(state || "");
    setTenantLat(tenantLatitude || "");
    setTenantLng(tenantLangitude || "");
  }, [editCommunityItem]);

  useEffect(() => {
    let countryArray = [];
    if (tenant_list && Object.keys(tenant_list).length !== 0) {
      // eslint-disable-next-line array-callback-return
      Object.keys(tenant_list).map((country) => {
        countryArray.push(country);
      });
    }
    setCountries([...countryArray]);
  }, [tenant_list]);

  useEffect(() => {
    if (tenant_list && Object.keys(tenant_list).length !== 0) {
      setStatesList(tenant_list[comCountry]);
    }
  }, [comCountry]);

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const mapCommunity = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onCountryChange = (value) => {
    setComCountry(value);
  }

  const onStateChange = (value) => {
    setComState(value);
  }

  const getLatLng = (data) => {
    if (data && Object.keys(data).length > 0) {
      setTenantLat(data.lat.toString());
      setTenantLng(data.lng.toString());
    }
  };

  const onSave = () => {
    let formdata = new FormData();
    const { id } = editCommunityItem;
    formdata.append("id", id);
    formdata.append("name", comName);
    formdata.append("description", comDescription);
    formdata.append("tenantLatitude", tenantLat);
    formdata.append("tenantLangitude", tenantLng);
    formdata.append("city", comCity);
    formdata.append("state", comState);
    formdata.append("country", comCountry);
    formdata.append("zipcode", comZipcode);
    formdata.append("address1", comAddress);
    Api.updateCommunityInfo(formdata)
      .then(res => {
        if (res.data.status === 'failed') {
          alert('Community Info Update Failed');
        } else {
          alert('Community Info Updated Successfully');
        }
      }).catch(e => {
        console.log(e);
      });
    setTimeout(() => {
      dispatch(moderatorActions.fetchModeratingCommunityList(""));
    }, 1000);
  }

  const onClickCommImgUpload = () => {
    // Trigger the click event on the hidden file input
    fileInputRef.current.click();
  };

  const handleImageChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      try {
        const compressedFile = await compressFile(file); //compress file
        const photoUrl = URL.createObjectURL(compressedFile);
        setFile(photoUrl);

        const formData = new FormData();
        formData.append("img", compressedFile);
        const { id } = editCommunityItem;
        await Api.uploadCommunityImage(id, formData);
        alert("Community image updated successfully");
        dispatch(moderatorActions.fetchModeratingCommunityList(""));
      } catch (err) {
        console.error(err);
      }
    }
  };

  const communityImg = file
    ? file
    : editCommunityItem?.thumbnailLogo
      ? `${ImagePath}${editCommunityItem?.thumbnailLogo}`
      : CommunityImage;

  return (
    <>
      <form onSubmit={onSubmit}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <CommunityInfoInput
            placeholdertext={"Enter Community Name"}
            labelText="Community Name"
            onChangeHandler={setComName}
            styleClass="communityNameInput"
            value={comName}
            isInputTextHandler={true}
            charLength={50}
          />
          <CommunityInfoInput
            placeholdertext={"Enter Description"}
            labelText="Description"
            onChangeHandler={setComDescription}
            styleClass="communityNameInput communityDescriptionInput"
            value={comDescription}
            isInputTextHandler={true}
            charLength={280}
          />

          <CommunityInfoInput
            labelText="Country"
            country_list={countries}
            onChangeHandler={onCountryChange}
            value={comCountry}
          />

          <div className="addressandcity">
            <CommunityInfoInput
              placeholdertext={"18900 Prospect Road"}
              labelText="Address"
              onChangeHandler={setComAddress}
              styleClass="communityNameInput"
              parentStyleClass="mediumInput1"
              value={comAddress}
              isInputTextHandler={true}
              charLength={50}
            />
            <CommunityInfoInput
              placeholdertext={"18900 Prospect Road"}
              labelText="City"
              onChangeHandler={setComCity}
              styleClass="communityNameInput"
              parentStyleClass="mediumInput2"
              value={comCity}
              isInputTextHandler={true}
              charLength={50}
            />
          </div>
          <div className="addressandcity">
            {tenant_list && Object.keys(tenant_list).length !== 0 ? (
              <CommunityInfoInput
                labelText="State"
                parentStyleClass="mediumInput1"
                state_list={statesList}
                value={comState}
                onChangeHandler={onStateChange}
              />
            ) : null}
            <CommunityInfoInput
              placeholdertext={"Enter Zip code"}
              labelText="Zip Code"
              onChangeHandler={setComZipCode}
              styleClass="communityNameInput"
              parentStyleClass="mediumInput2"
              value={comZipcode}
              isInputTextHandler={true}
              charLength={15}
            />
          </div>
          <div>
            <Button onClick={mapCommunity} style={{ marginBottom: "10px" }}>
              <img src={MapIcon} alt="Map-Icon" />
              &nbsp;Edit Community Map
            </Button>
          </div>

          <div>
            <Button className={styles.editCommBtn} onClick={onClickCommImgUpload}>
              <img src={communityImg} alt="Map-Icon" className={styles.editCommImg} />
              &nbsp;Edit Community Picture
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageChange}
              style={{ visibility: "hidden" }}
            />
          </div>

          <Button type="submit" variant="contained" onClick={onSave} className={styles.button}>
            Save
          </Button>
        </div>
      </form>

      {isOpen ? (
        <Dialog
          open={isOpen}
          onClose={handleClose}
          classes={{ paperWidthSm: classes.paperWidthSm }}
        >
          <DialogTitle disableTypography style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }} sx={{ padding: 0 }}>
            <h5>Map Community</h5>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <EditMap {...editCommunityItem} onMapClose={handleClose} getLatLng={getLatLng} />
        </Dialog>
      ) : null}
    </>
  );
};

export default CommunityInfo;
