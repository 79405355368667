import React from "react";
import { useLocation } from "react-router-dom";
import ViewActivitySignIn from "../ViewActivitySignIn";
import ViewActivityBeforeIn from "../viewActivityBeforeIn";

const ViewActivity = () => {
  const location = useLocation();

  return (
    <>
      {location?.state?.signIn ? (
        <ViewActivitySignIn />
      ) : (
        <ViewActivityBeforeIn />
      )}
    </>
  );
};

export default ViewActivity;
