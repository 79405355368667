import React, { useState, useEffect } from "react";
import "./Activites.css";
import AsksActivity from "./Asks Activity/AsksActivity";
import InvitesPending from "./Invites Pending/InvitesPending";
import Invites from "./Invites/Invites";
import SaysActivity from "./Says Activity/SaysActivity";
import TagActtivity from "./Tag Activity/TagActtivity";
import UpdateActtivity from "./Update Activity/UpdateActtivity";
import { useDispatch } from "react-redux";
import * as ActivityActions from "../../../store/actions/Activity/ActivityAction";
import API from "../../../network";

function Activites() {
  const [activityCountRes, setActivityCountRes] = useState({});
  const dispatch = useDispatch;

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await API.getActivityPostCount();
        setActivityCountRes(res.data);
      } catch (error) {
        console.error("Error fetching user tags:", error);
      }
    };

    getData();
  }, []);

  const decreaseHashCount = () => {
    if (activityCountRes?.hashListSize > 0) {
      const newActivityHashCount = activityCountRes.hashListSize - 1;
  
      setActivityCountRes((prevState) => ({
        ...prevState,
        hashListSize: newActivityHashCount,
      }));
    }
  };

  const decreaseActivitySaysCount = () => {
    if (activityCountRes?.allsayscount > 0) {
      const newActivitySayCount = activityCountRes.allsayscount - 1;
  
      setActivityCountRes((prevState) => ({
        ...prevState,
        allsayscount: newActivitySayCount,
      }));
    }
  };

  const decreaseActivityAskCount = () => {
    if (activityCountRes?.allasksactivitycount > 0) {
      const newActivityAskCount = activityCountRes.allasksactivitycount	 - 1;
  
      setActivityCountRes((prevState) => ({
        ...prevState,
        allasksactivitycount	: newActivityAskCount,
      }));
    }
  };

  return (
    <div className="Activites">
      <div>
        <p className="head">Activity </p>
        <AsksActivity activityCountRes={activityCountRes} decreaseActivityAskCount={decreaseActivityAskCount}/>
        <SaysActivity activityCountRes={activityCountRes} decreaseActivitySaysCount={decreaseActivitySaysCount}/>
        <UpdateActtivity />
        <TagActtivity activityCountRes={activityCountRes} decreaseHashCount={decreaseHashCount}/>
        <InvitesPending />
        <Invites />
      </div>
    </div>
  );
}
export default Activites;
