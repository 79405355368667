// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @font-face {
  font-family: 'Helvetica';
  src: url('fonts/Helvetica-Bold.eot');
  src: url('fonts/Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
       url('fonts/Helvetica-Bold.woff2') format('woff2'),
       url('fonts/Helvetica-Bold.woff') format('woff'),
       url('fonts/Helvetica-Bold.ttf')  format('truetype'),
       url('fonts/Helvetica-Bold.svg#Helvetica') format('svg');
}
@font-face {
  font-family: 'Helvetica';
  src: url('fonts/Helvetica.eot');
  src: url('fonts/Helvetica.eot?#iefix') format('embedded-opentype'),
       url('fonts/Helvetica.woff2') format('woff2'),
       url('fonts/Helvetica.woff') format('woff'),
       url('fonts/Helvetica.ttf')  format('truetype'),
       url('fonts/Helvetica.svg#Helvetica') format('svg');
} */
.footer-links a{
  color: #7A7A7A;
}`, "",{"version":3,"sources":["webpack://./src/Assets/css/Gobal.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;GAiBG;AACH;EACE,cAAc;AAChB","sourcesContent":["/* @font-face {\n  font-family: 'Helvetica';\n  src: url('fonts/Helvetica-Bold.eot');\n  src: url('fonts/Helvetica-Bold.eot?#iefix') format('embedded-opentype'),\n       url('fonts/Helvetica-Bold.woff2') format('woff2'),\n       url('fonts/Helvetica-Bold.woff') format('woff'),\n       url('fonts/Helvetica-Bold.ttf')  format('truetype'),\n       url('fonts/Helvetica-Bold.svg#Helvetica') format('svg');\n}\n@font-face {\n  font-family: 'Helvetica';\n  src: url('fonts/Helvetica.eot');\n  src: url('fonts/Helvetica.eot?#iefix') format('embedded-opentype'),\n       url('fonts/Helvetica.woff2') format('woff2'),\n       url('fonts/Helvetica.woff') format('woff'),\n       url('fonts/Helvetica.ttf')  format('truetype'),\n       url('fonts/Helvetica.svg#Helvetica') format('svg');\n} */\n.footer-links a{\n  color: #7A7A7A;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
