import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Sidebaroptions from "src/Components/SidebarOption";
import settingsStyles from "src/Assets/css/settings/settings.module.css";
// import { StylesContext } from '@material-ui/styles';
import Notifications from "src/pages/Notifications";
import HeaderAfterSignIn from "../../Components/Header/header";
import logo from "src/Assets/images/LandingPageImages/skopic-image.png";
import Cookies from "js-cookie";
import "./index.css";

const defaultState = {
  says: false,
  asks: false,
  postingResponses: false,
  updates: false,
  tags: false,
  following: false,
  contributors: false,
  moderators: false,
  other: false,
};

function Help() {
  const saysRef = useRef(null);
  const asksRef = useRef(null);
  const postingResponsesRef = useRef(null);
  const updatesRef = useRef(null);
  const tagsRef = useRef(null);
  const followingRef = useRef(null);
  const contributorsRef = useRef(null);
  const moderatorsRef = useRef(null);
  const otherRef = useRef(null);

  const [isActive, setIsActive] = useState({
    ...defaultState,
    says: true,
  });
  const [signInStatus, setSignInStatus] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const status = Cookies.get("signInStatus");
    setSignInStatus(status);
  }, []);

  const clickHandler = (key) => {
    setIsActive({
      ...defaultState,
      [key]: true,
    });

    scrollToView(key);
  };

  const scrollToView = (key) => {
    if (key === "says" && saysRef.current) {
      saysRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "asks" && asksRef.current) {
      asksRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "postingResponses" && postingResponsesRef.current) {
      postingResponsesRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "updates" && updatesRef.current) {
      updatesRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "tags" && tagsRef.current) {
      tagsRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "following" && followingRef.current) {
      followingRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "contributors" && contributorsRef.current) {
      contributorsRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "moderators" && moderatorsRef.current) {
      moderatorsRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (key === "other" && otherRef.current) {
      otherRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [isNotificationSelected, setIsNotificationSelected] = useState(false);

  const showHideNotifications = () => {
    setIsNotificationSelected(!isNotificationSelected);
  };

  return (
    <div>
      {isNotificationSelected ? (
        <div className={`col-sm-3  ${settingsStyles.notifications}`}>
          <div className={settingsStyles.notificationscroll}>
            <Notifications />
          </div>
        </div>
      ) : null}
      <div>
        {signInStatus === "true" ? (
          <HeaderAfterSignIn />
        ) : (
          <div className={settingsStyles.headerContainer}>
            <Link to={"/"}>
              <div>
                <img src={logo} alt="Skopic-Logo" width={90} height={48} />
              </div>
            </Link>
          </div>
        )}
        <div className="careers-main-container">
          <div className="page-navigation-options-container">
            {/* <h3>Help</h3> */}
            <div className="main-heading-container">
              <h3 className="main-heading">Help</h3>
            </div>
            <div className="page-navigation-options">
              <div className="page-options-content">
                <Sidebaroptions
                  active={isActive.says}
                  text="SAYs"
                  onSidebaroptionClick={() => clickHandler("says")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.asks}
                  text="ASKs"
                  onSidebaroptionClick={() => clickHandler("asks")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.postingResponses}
                  text="Postings Responses"
                  onSidebaroptionClick={() => clickHandler("postingResponses")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.updates}
                  text="UPDATEs"
                  onSidebaroptionClick={() => clickHandler("updates")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.tags}
                  text="TAGs"
                  onSidebaroptionClick={() => clickHandler("tags")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.following}
                  text="Following"
                  onSidebaroptionClick={() => clickHandler("following")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.contributors}
                  text="Contributors"
                  onSidebaroptionClick={() => clickHandler("contributors")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.moderators}
                  text="Moderators"
                  onSidebaroptionClick={() => clickHandler("moderators")}
                />
                <Divider variant="fullWidth"></Divider>
                <Sidebaroptions
                  active={isActive.other}
                  text="Other"
                  onSidebaroptionClick={() => clickHandler("other")}
                />
              </div>
            </div>
          </div>
          <div className="careers-content-container">
            <div
              ref={saysRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                }  ${isActive.says && settingsStyles.focusapperance}`}
            >
              {/* <div className={settingsStyles.help}> */}
              <b>SAYS: (140-char message)</b>
              <br />
              You can share knowledge and express thoughts that could benefit
              your community.
              <br />
              This information develops into your community knowledge as members
              continue to share and
              <br />
              contribute their thoughts or opinions. Most importantly, your
              expressions could be a match or a<br />
              direct answer to questions posted by your peers. This feed is also
              available on the user home to
              <br />
              encourage the social learning aspect. When you SAY, you will also
              be presented with a set of
              <br />
              questions requiring answers that you may choose to respond.
              However, it is optional to answer these questions.
              <br />
              {/* </div> */}
            </div>
            <div
              ref={asksRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                }  ${isActive.asks && settingsStyles.focusapperance}`}
            >
              {/* <div className={settingsStyles.sidebarchildoptionsocialmediapswdchange}> */}
              {/* <div> */}
              ASKS: (140-char message)
              <br />
              ASK a question in your community, Skopic provides instant matches
              by searching through
              <br />
              people’s expressions / the community knowledge developed (SAYs)
              and also previously posted
              <br />
              responses posted (SAYs) to similar questions in your community on
              the related subject.
              <br />
              <b>SEE EXAMPLE BELOW:</b>
              <br />
              You could move it to an open pool of questions if no results are
              found or if the results don’t
              <br />
              meet your expectations. You will be notified via email and the
              mobile application when other
              <br />
              community peers post responses.
              <br />
              <b>SEE EXAMPLE BELOW:</b>
              <br />
              {/* </div> */}
              {/* </div> */}
            </div>
            <div
              ref={postingResponsesRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                } ${isActive.postingResponses && settingsStyles.focusapperance}`}
            >
              {/* <div> */}
              <b>
                POSTING RESPONSES, a.k.a SAY to Open Questions, a.k.a ASKs in
                your community:
              </b>
              <br />
              <br />
              You can choose to respond a.k.a SAY to any Open Question a.k.a ASK
              by clicking on the SAY
              <br />
              option or alternatively click on SAYs to view previously posted
              answers and then share your best
              <br />
              informed answer not exceeding 140 chat limit. Also, note URL or
              web links can be embedded
              <br />
              but they would not counted towards the max character limit.
              <br />
              {/* </div> */}
            </div>

            <div
              ref={updatesRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                } ${isActive.updates && settingsStyles.focusapperance}`}
            >
              {/* <div> */}
              <b>UPDATES: (140-char message)</b>
              <br />
              Users can broadcast a message to the whole community through
              important UPDATES to notify
              <br />
              something that is important to all members of a community.
              Whenever an UPDATE is posted on
              <br />
              your default community and communities you’re following, you
              receive notifications on your
              <br />
              email and on mobile apps or soon to be available apple watch.
              <br />
              <b>SEE EXAMPLE BELOW:</b>
              {/* </div> */}
            </div>

            <div
              ref={tagsRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                } ${isActive.tags && settingsStyles.focusapperance}`}
            >
              {/* <div> */}
              <b>TAGS: (140-char message)</b>
              <br />
              Users can create #TAG topics to stay focused on specific interests
              and events in the community.
              <br />
              This feature is highly encouraged if the community wants to share,
              communicate and
              <br />
              collaborate on a specific activity or topic.
              <br />
              <br />
              The entire community is notified of any new TAG created, but the
              member could choose or not
              <br />
              choose to Follow. If followed, all associated posts will be
              notified via email and mobile push
              <br />
              notifications (only if turned on)
              <br />
              Please note when you add a new post to #TAG, you’ll be following
              the new #TAG by default.
              <br />
              <b>SEE EXAMPLE BELOW:</b>
              <br />
              {/* </div> */}
            </div>

            <div
              ref={followingRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                } ${isActive.following && settingsStyles.focusapperance}`}
            >
              {/* <div> */}
              <b>FOLLOWING:</b>
              <br />
              1. Follow #TAG:
              <br />
              You can follow #TAG posts to stay connected with all related
              messages in a hashtag by sending
              <br />
              you notifications when other members post to the #TAG.
              <br />
              2. Follow ASK:
              <br />
              On the user landing/community home page, you may click Follow to
              follow any Open ASK that
              <br />
              is of interest to you. You will be notified when responses are
              posted.
              <br />
              3. Follow community:
              <br />
              You can select communities to follow from your Settings page to
              receive notifications from
              <br />
              communities such as #TAG creations and UPDATES.
              <br />
              {/* </div> */}
            </div>

            <div
              ref={contributorsRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                } ${isActive.contributors && settingsStyles.focusapperance}`}
            >
              {/* <div> */}
              <b>CONTRIBUTORS:</b>
              <br />
              The top 10 users who contributed to the keeping the community
              engaged and information
              <br />
              networked by Sharing knowledge, Expressing Thoughts, answering
              peer questions or posting
              <br />
              important updates.
              <br />
              <b>SEE EXAMPLE BELOW:</b>
              <br />
              {/* </div> */}
            </div>

            <div
              ref={moderatorsRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                } ${isActive.moderators && settingsStyles.focusapperance}`}
            >
              <b>MODERATORS:</b>
              <br />
              Becoming a Moderator:
              <br />
              1. You must be following the community you want to moderate.
              <br />
              2. Go to the settings page, click on request to be a moderator in
              the section, Your Communities
              <br />
              on Skopic.
              <br />
              3. You will be approved or rejected by your Primary Moderator, if
              approved, review moderator
              <br />
              guidelines and then you are good to go.
              <br />
              Primary Moderator:
              <br />
              The person starting your community is designated Primary
              Moderator. All other subsequently
              <br />
              approved by the Primary are called, Moderators.
              <br />
              Primary Moderator actions:
              <br />
              1. Setup publishing rules for key content types such as, ASKs,
              SAYs, UPDATES, Creating HASH
              <br />
              TAGS and #SAYs.
              <br />
              2. Assign/Remove other Moderators.
              <br />
              3. Approve, Reject or Hold off on div-published and the user
              flagged published content.
              <br />
              4. Update or Edit the Community info such as: name, description,
              address, boundaries and
              <br />
              timeline photos.
              <br />
              5. Transfer the Primary role to another individual within the same
              community.
              <br />
              Moderator actions:
              <br />
              1. Approve, Reject or Hold off on div-published and the user
              flagged published content.
              <br />
              2. Update or Edit the Community info such as: name, description,
              address, boundaries
              <br />
              and timeline photos.
              <br />
              5. Transfer the Primary role to another individual within the same
              community.
              <br />
              3. Unsubscribe from the Moderator role.
              <br />
            </div>

            <div
              ref={otherRef}
              className={`${settingsStyles.sidebarChildContentOptions} ${settingsStyles.help
                } ${isActive.other && settingsStyles.focusapperance}`}
            >
              <b>Others:</b>
              <br />
              Like:
              <br />
              You can like instant answers or matches a.k.s SAY posts for your
              Questions, thoughts or opinions
              <br />
              a.k.a SAYs expressed by peers and responses a.k.a. SAYs posted for
              questions a.k.aASKs.
              <br />
              Facebook or LinkedIn Sign-in:
              <br />
              Users can choose to sign-in with a one-step process via their
              Facebook or LinkedIn accounts.
              <br />
              When signing in, the information from your LinkedIn and Facebook
              pages will be used to
              <br />
              complete your basic profile on Skopic.
              <br />
              Community web link:
              <br />
              1. On Settings page, in Your Communities on Skopic section, click
              on the Copy Link (paper clip
              <br />
              icon) to access the public web link of a community and use it as
              necessary when communicating
              <br />
              to friends and network about that specific Community. Ex: Post the
              URL on your Facebook
              <br />
              groups or wall or when sending out emails etc so users can easily
              access the community page to
              <br />
              Sign-up or Sign-in. 2. When sending invites to friends from within
              Skopic application, the community public web
              <br />
              link is default available in the footer area of the message
              <br />
              {/* Popular Social Media Integrations:
              <br /> */}
              {/* On the Settings page, you can enable your Skopic posts to be
              posted on Facebook (Groups,
              <br />
              Pages, and your personal Timeline), LinkedIn, and Twitter.
              <br /> */}
              Default community:
              <br />
              When first signing up, you will be asked to select your default
              community. The default
              <br />
              community page becomes the page you Sign Into every time you are
              on Skopic. You will receive
              <br />
              notifications when new #TAGS and UPDATES are created by your peers
              in this community.
              <br />
              Send Message to the User:
              <br />
              On user’s SAYs and #TAG posts, you can send a private message
              directly to the user to either
              <br />
              thank them for their help or to connect with them offline.
              <br />
              Report abuse:
              <br />
              You can flag report abuse on any post by stating the reason for
              flagging so. Skopic admin and
              <br />
              the user who authored the post will be notified of this event.
              <br />
              Start Community:
              <br />
              Users can request for starting a new community on Skopic by
              completing a quick online form
              <br />
              accessible on the landing and inside pages of www.skopic.com or
              via the landing or inside
              <br />
              settings page on IOS and Android apps. Once, the request is
              submitted, the user needs to
              <br />
              validate the request by clicking on link sent via email so the
              Skopic admin can approve it to
              <br />
              make your community available on Skopic.
              <br />
              Important Note:
              <br />
              Message character limit: To provide clutter-free, community
              knowledge, all posts such as SAYs,
              <br />
              ASKs, UPDATES, and #TAGS are limited to 140 characters.Also, note
              URL or web links can be
              <br />
              embedded but they would not counted towards the max character
              limit and no document or
              <br />
              photo or video uploads are allowed at this time.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
