// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-text-field .MuiOutlinedInput-root {
  /* border-radius: 40px !important; */
  /* Custom border radius */
  /* padding-left: 10px; */
  border-top-right-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  height: 35px;
}

.custom-text-field .MuiOutlinedInput-notchedOutline {
  border: none;
  /* Remove outline */
}

.custom-text-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none;
  /* Ensure no outline when focused */
}

.searchcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  height: 35px;
}

.select {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 6px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 40px !important;
  border-bottom-left-radius: 40px !important;
  border: none !important;
  outline: none;
  height: 35px;
}`, "",{"version":3,"sources":["webpack://./src/Components/DefaultCommunity/search/index.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,wBAAwB;EACxB,wCAAwC;EACxC,2CAA2C;EAC3C,sCAAsC;EACtC,yCAAyC;EACzC,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,8BAA8B;EAC9B,0CAA0C;EAC1C,uCAAuC;EACvC,0CAA0C;EAC1C,uCAAuC;EACvC,0CAA0C;EAC1C,uBAAuB;EACvB,aAAa;EACb,YAAY;AACd","sourcesContent":[".custom-text-field .MuiOutlinedInput-root {\r\n  /* border-radius: 40px !important; */\r\n  /* Custom border radius */\r\n  /* padding-left: 10px; */\r\n  border-top-right-radius: 40px !important;\r\n  border-bottom-right-radius: 40px !important;\r\n  border-top-left-radius: 0px !important;\r\n  border-bottom-left-radius: 0px !important;\r\n  height: 35px;\r\n}\r\n\r\n.custom-text-field .MuiOutlinedInput-notchedOutline {\r\n  border: none;\r\n  /* Remove outline */\r\n}\r\n\r\n.custom-text-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {\r\n  border: none;\r\n  /* Ensure no outline when focused */\r\n}\r\n\r\n.searchcontainer {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  margin-bottom: 15px;\r\n  height: 35px;\r\n}\r\n\r\n.select {\r\n  background: rgb(255, 255, 255);\r\n  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 6px;\r\n  border-top-right-radius: 0px !important;\r\n  border-bottom-right-radius: 0px !important;\r\n  border-top-left-radius: 40px !important;\r\n  border-bottom-left-radius: 40px !important;\r\n  border: none !important;\r\n  outline: none;\r\n  height: 35px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
