import { useState, React } from "react";
import { SignupContext } from "./SignupContext";

const defaultState = {
  signup: false,
  email: "",
  password: "",
  displayName: "",
  community: "",
  tenantId: "",

  //google signup flag
  googleSignUp: false,
};

function SignupContextProvider({ children }) {
  const [signupState, setSignupState] = useState({ ...defaultState });

  const onChangeSignupState = (newState) => {
    setSignupState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const onResetSignupState = () => {
    setSignupState({ ...defaultState });
  };

  return (
    <SignupContext.Provider value={{
      signupState,
      setSignupState: onChangeSignupState,
      resetSignupState: onResetSignupState,
    }}>
      {children}
    </SignupContext.Provider>
  );
}

export default SignupContextProvider;
