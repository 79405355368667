import React from "react";
import Phototimeline from "./Phototimeline";
import CommunityInfo from "./CommunityInfo";
import CommunityRules from "./CommunityRules";
import "src/Assets/css/Moderator/EditCommunitys.css";

const EditCommunity = ({ subMenuItem, tenantId, tenantName }) => {
  return (
    <React.Fragment>
      <div className="EditCommunityContent">
        {subMenuItem === "Community Rules" ? (
          <CommunityRules tenantId={tenantId} tenantName={tenantName} />
        ) : subMenuItem === "Photo Timeline" ? (
          <Phototimeline tenantId={tenantId} tenantName={tenantName} />
        ) : (
          <CommunityInfo tenantId={tenantId} tenantName={tenantName} />
        )}
      </div>
    </React.Fragment>
  );
};

export default EditCommunity;
