import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import axios from "axios";
import { Button, Avatar } from "@material-ui/core";
import { Modal } from "react-bootstrap";
// import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
// import bg1 from "src/Assets/images/Golfing-Couple-v2.jpg";
// import Invite from "src/Assets/images/Invitemember.png";
// import Crown from "src/Assets/images/moderatorcrown.png";
// import compose from "src/Assets/images/compose.png";
import aboutASK from "src/Assets/images/aboutask.svg";
// import { CookieConfig } from "../../../Utils/CookieConfig";
import styles from "src/Assets/css/home/Feed.module.css";
import Invitation from "./Invitations/Invitations";
// import * as feedactions from "../../../store/actions/feedactions/feedActionCreator";
import PostingOptionsModal from "../../../Modules/Reusuablecomponents/PostingOptionsModal";
import ToggleSwitch from "../../../Modules/Reusuablecomponents/ToggleSwitch";
import LocationTag from "../../../Modules/Reusuablecomponents/LocationTag.jsx";
import AsksModal from "./AsksModal";
// import { makeStyles } from "@material-ui/core/styles";
// import { green, red } from "@material-ui/core/colors";
// import { TrendingUpOutlined } from "@material-ui/icons";
import PrimaryModeratorCrown from "src/Assets/images/PrimaryModeratorCrown.svg";
import SecondaryModeratorCrown from "src/Assets/images/SecondaryModeratorCrown.svg";
// import { setLanguage } from 'react-geocode';
import PhotoPreview from "../../../Components/PhotoPreview";
// import CustomMethods from "../../../Constants/CustomMethods.js";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { fetchloginUser } from "../../../store/actions/SignInActions/SignInAction";
import AvatarBadge from "src/Components/AvatarBadge";
import { ignoreSpacesAndUrlsFromMsg } from "src/Utils/commonUtils";
import * as SignInActions from "src/store/actions/SignInActions/SignInAction";

// const useStyles = makeStyles({
//   heading: {
//     fontSize: 16,
//     color: "#000000a1",
//     paddingBottom: 0,
//   },
// });

const TotalLetters = 280;

function AskSayPostDataModal(props) {
  const {
    // loading,
    // childSaysLoading,
    fetchSaysDataForAsks,
    fetchAsksToAnswer,
    answersToYourAsk,
    asksToBeAnswered,
    // askYourCommunity,
    // fetchFeedData,
    // postChildSayData,
    // deleteMessage,
    filterPosts,
    // childSaysData,
    // fetchChildSaysData,
    // fetchFollowData,
    // fetchVoteUpStatus,
    isPostOption,
    setPostOption,
    // setSaysShow,
    setModalVisibility,
    fetchContributors
  } = props;
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const dispatch = useDispatch();
  // const usernameData = useSelector((state) => state.followReducer.usernameData)

  // const AskCanAns = useSelector((state) => state.followReducer.sayData);
  // const saysCanAns = useSelector((state) => state.followReducer.askData);
  // console.log("Initial tenantSayStatus:", asksToBeAnswered.tenantSayStatus);
  // console.log("Initial messageListSize:", asksToBeAnswered.messageListSize);

  const [askorSayText, setAskorSayText] = useState("");
  // const [SaysShow, setSaysShow] = useState(false);
  // const [isPostOption, setPostOption] = useState("");
  const [isAdditionalCount, setAdditionalCount] = useState(false);
  const [isValidCount, setValidCount] = useState(true);
  const [isCharcount, setCharCount] = useState(0);
  const [setLocationSelect, isLocationSelect] = useState("");
  const [isLat, setLat] = useState("");
  const [isLng, setLng] = useState("");
  const [postingOption, setPostingOption] = useState("");
  const [isNewHash, setNewHashMessage] = useState("");
  const [isHide, setIsHide] = useState(false);
  // const [lgShow, setLgShow] = useState(false);

  // const [isUserNameData, setUserNameData] = useState([])
  // const dispatch = useDispatch();

  useEffect(() => {
    if (props.modalShow) {
      setAskorSayText("");
      setPostOption("");
      setAdditionalCount("");
      setCharCount(0);
      setNewHashMessage("");
    }
    // if (answersToYourAsk && Object.keys(answersToYourAsk).length > 0 || asksToBeAnswered && Object.keys(asksToBeAnswered).length > 0) {
    //     if(answersToYourAsk.messageList||asksToBeAnswered.messageList){
    //         setSaysShow(true)
    //     }
    // }
  }, [props.modalShow]);

  useEffect(() => {
    if (answersToYourAsk && Object.keys(answersToYourAsk).length > 0) {
      if (answersToYourAsk?.status === "New Hash") {
        setNewHashMessage("You can't create new #TAG here");
        setModalVisibility(true, false);
      } else if (answersToYourAsk?.messageList) {
        setModalVisibility(false, true);
      } else {
        setModalVisibility(false, false);
        // dispatch(feedactions.fetchFeedData("?startlimit=0"));
      }
    }
  }, [answersToYourAsk]);

  useEffect(() => {
    if (asksToBeAnswered && Object.keys(asksToBeAnswered).length > 0) {
      if (asksToBeAnswered?.status === "New Hash") {
        setNewHashMessage("You can't create new #TAG here");
        setModalVisibility(true, false);
      } else if (
        asksToBeAnswered.tenantSayStatus === "E" &&
        asksToBeAnswered.messageListSize === 0
      ) {
        // setLgShow(true);
        setModalVisibility(false, false, true);
      } else if (
        asksToBeAnswered?.messageList &&
        asksToBeAnswered.messageList.length > 0
      ) {
        // props.setModalShow(false);
        // setSaysShow(true);
        setModalVisibility(false, true);
      } else {
        setModalVisibility(false, false);
        setTimeout(() => {
          filterPosts("");
        }, 500);
      }
    }
  }, [asksToBeAnswered]);

  var inputDataLen;

  const ignoreSpaceandUrls = (mstrData, txtId) => {
    let inputData = mstrData;
    inputDataLen = inputData.length;
    let inputText = document.getElementById(txtId).value;
    inputText = inputText.replace(/\n/g, " ");
    let strArray = inputText.split(" ");
    let validlettercount = 0;
    let inputletters;
    for (inputletters = 0; inputletters < strArray.length; inputletters++) {
      if (
        strArray[inputletters].indexOf("http://") == -1 &&
        strArray[inputletters].indexOf("www.") == -1 &&
        strArray[inputletters].indexOf("https://") == -1 &&
        strArray[inputletters].indexOf("HTTP://") == -1 &&
        strArray[inputletters].indexOf("WWW.") == -1 &&
        strArray[inputletters].indexOf("HTTPS://") == -1
      ) {
        validlettercount = validlettercount + strArray[inputletters].length;
      } else if (
        strArray[inputletters].indexOf("http://") &&
        strArray[inputletters].indexOf("www.") &&
        strArray[inputletters].indexOf("https://") &&
        strArray[inputletters].indexOf("HTTP://") &&
        strArray[inputletters].indexOf("WWW.") &&
        strArray[inputletters].indexOf("HTTPS://")
      ) {
        validlettercount = validlettercount + strArray[inputletters].length;
      }
    }
    inputDataLen = validlettercount;
  };

  const textChangeHandler = (e, id) => {
    // if(usernameData)
    // {
    //     // setUserNameData(usernameData)
    //     console.log(isUserNameData)

    // }

    let inputValue = id;
    let letterCounter;
    let letterCountAdd;

    if (isAdditionalCount) {
      letterCounter = "letterCounter";
      letterCountAdd = "letterCounterAdding";
    } else {
      letterCounter = "letterCounter140";
      letterCountAdd = "letterCounterAdding140";
    }

    let letters = document.getElementById(inputValue).value;

    setAskorSayText(e.target.value);
    // ignoreSpaceandUrls(letters, inputValue);
    const { msgLength } = ignoreSpacesAndUrlsFromMsg(letters);
    const chCount = msgLength;
    setCharCount(chCount);
    if (document.getElementById(letterCounter && letterCountAdd) !== null) {
      if (chCount > TotalLetters) {
        document.getElementById(letterCountAdd).style.color = "red";
        document.getElementById("postSubmitButton").disabled = true;
        document.getElementById("postSubmitButton").style.opacity = "0.4";
        setValidCount(false);
      } else {
        document.getElementById(letterCountAdd).style.color = "black";
        document.getElementById("postSubmitButton").disabled = false;
        document.getElementById("postSubmitButton").style.opacity = "2";
        setValidCount(true);
      }
      // if (document.getElementById("postSubmitButton").disabled = true) {
      //     setValidCount(false)

      // }
      // else {
      //     setValidCount(true)

      // }
    }
  };

  const postAskHandler = (e) => {
    e.preventDefault();
    // let formData = new FormData();
    let lnglat;
    if (isPostOption === "ASK") {
      if ((isLat !== "") & (isLng !== "")) {
        lnglat = isLat + "," + isLng;
      } else {
        lnglat = "";
      }
      if (isCharcount && isCharcount <= TotalLetters && isValidCount) {
        fetchSaysDataForAsks(
          askorSayText,
          lnglat,
          setLocationSelect,
          postingOption
        );
        // dispatch(
        //   feedactions.fetchSayAskData(
        //     `Message=${askorSayText}&userLoc=&msgLoc=${lnglat}&locName=${setLocationSelect}&posttype=${postingOption}`
        //   )
        // );
        setLat("");
        setLng("");
        isLocationSelect("");

        setAskorSayText("");
      }
    } else {
      let saylatlng;
      if ((isLat !== "") & (isLng !== "")) {
        saylatlng = isLat + "," + isLng;
      } else {
        saylatlng = "";
      }
      if (isCharcount && isCharcount <= TotalLetters && isValidCount) {
        // if (
        //   asksToBeAnswered.tenantSayStatus === "E" &&
        //   asksToBeAnswered.messageListSize === 0
        // ) {
        //   setLgShow(true);
        //   props.setModalShow(false);
        // } else {
        //   setTimeout(() => {
        //     filterPosts("say");
        //   }, 500);
        // }

        fetchAsksToAnswer(
          askorSayText,
          saylatlng,
          setLocationSelect,
          postingOption
        );
        // dispatch(
        //   feedactions.fetchAskSayData(
        //     `Message=${askorSayText}&userLoc=&msgLoc=${saylatlng}&locName=${setLocationSelect}&onlyASK=YES&posttype=${postingOption}`
        //   )
        // );
        // formData.append("onlyASK", "YES");
        setLat("");
        setLng("");
        isLocationSelect("");

        setAskorSayText("");
      }
    }

    setTimeout(() => {
      fetchContributors && fetchContributors();
      dispatch(SignInActions.fetchloginUser());
    }, 1000);

    // setLat("")
    // setLng("")
    // isLocationSelect("")

    // setAskorSayText("")

    // setPostOption("")

    // return () => clearTimeout(timer);
  };

  const isSubmitHandler = () => {
    if (isPostOption === "ASK" || isPostOption === "SAY") {
      if (askorSayText === "" || isCharcount === 0) {
        return false;
      } else {
        return true;
      }
    }
  };
  const isEnabled = isSubmitHandler();
  const onAddSelection = () => {
    if (isCharcount <= 280) {
      document.getElementById("postSubmitButton").disabled = false;
      document.getElementById("postSubmitButton").style.opacity = "2";
    }
    setAdditionalCount(true);
    setValidCount(true);
  };
  var profileImagePath = process.env.REACT_APP_SKOPIC_IMAGE;

  const handleClose = () => {
    isLocationSelect("");
  };
  const communitySelected = userDetails?.userData?.communityFollowNames.find(
    (each) => each.id === userDetails?.userData?.tenantId
  );

  return (
    <React.Fragment>
      <Modal
        {...props}
        size="md"
        className={isHide ? styles.modalHide : styles.modal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className={styles.modalbgcolor}>
          <Modal.Header onClick={handleClose} closeButton>
            {isPostOption === "ASK" ? (
              <h6 className={styles.asksayselection}>
                {isPostOption !== "ASK" && isPostOption !== "SAY"
                  ? "What would you like to ASK?"
                  : ""}
              </h6>
            ) : isPostOption === "SAY" ? (
              <h6 className={styles.asksayselection}>
                {isPostOption !== "ASK" && isPostOption !== "SAY"
                  ? "What would you like to SAY?"
                  : ""}
              </h6>
            ) : (
              <h6 className={styles.asksayselection}>
                {isPostOption !== "ASK" && isPostOption !== "SAY"
                  ? "Do you want to ASK or SAY?"
                  : ""}
              </h6>
            )}
          </Modal.Header>

          <ToggleSwitch
            leftLabel="ASK"
            rightLabel="SAY"
            setPostOption={setPostOption}
          />

          <Modal.Body className={styles.modalbody}>
            {userDetails && Object.keys(userDetails).length !== 0 ? (
              <div className={styles.profilenameandimage}>
                <div>
                  {/* <Avatar
                    src={`${profileImagePath}${userDetails.userData.uimage}`}
                    className={styles.profileavatar}
                  /> */}
                  <AvatarBadge
                    src={`${profileImagePath}${userDetails.userData.uimage}`}
                    alt={userDetails?.userData?.displayName}
                    className={styles.profileavatar}
                    isPrimaryModerator={communitySelected?.ispm === 1}
                    isModerator={
                      communitySelected?.moderatorStatus === 1 &&
                      communitySelected?.ispm === 0
                    }
                  />
                </div>
                <div className={styles.profilename}>
                  <p>{userDetails.userData.shortDisplayName}</p>
                  <div className={styles.postdropdown}>
                    <PostingOptionsModal
                      tenantName={userDetails.userData.tenantName}
                      pimage={userDetails.userData.tenantLogoThumbnail}
                      setPostingOption={setPostingOption}
                      isPostOption={isPostOption}
                      askorSayText={askorSayText}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <textarea
              placeholder={`${
                isPostOption === "ASK"
                  ? "What would you like to ASK?"
                  : isPostOption === "SAY"
                  ? "What would you like to SAY"
                  : "What's on your mind?"
              }`}
              className={styles.asksaytextarea}
              value={askorSayText}
              id="post"
              onChange={(e) => textChangeHandler(e, "post")}
              disabled={isPostOption !== "ASK" && isPostOption !== "SAY"}
            />

            <span className={styles.hashValidationMessage}>{isNewHash}</span>

            <div className={styles.locationtagandcharcount}>
              <div className={styles.locationtagicon}>
                <a>
                  {userDetails && Object.keys(userDetails).length !== 0 ? (
                    <>
                      <LocationTag
                        isPostings={true}
                        isLocationSelect={isLocationSelect}
                        setLat={setLat}
                        setLng={setLng}
                        id={userDetails.userData.User_ID}
                        setIsHide={setIsHide}
                        setLocationSelect={setLocationSelect}
                      />
                      <p>{userDetails.User_ID}</p>
                    </>
                  ) : null}
                </a>
              </div>
              <div>
                {isAdditionalCount ? (
                  <>
                    <label
                      id={`letterCounterAdding`}
                      className={`${
                        isCharcount > 280 ? styles.overCount : null
                      }`}
                    >
                      {isCharcount}
                    </label>
                    <label id={`letterCounter`}>/280</label>
                  </>
                ) : (
                  <span className={styles.initialcount}>
                    <label id={`letterCounterAdding140`}>{isCharcount}</label>
                    <label id={`letterCounter140`}>/280 </label>
                  </span>
                )}
                {/* 
                {!isAdditionalCount ? (
                  <AddCircleOutlineIcon
                    onClick={onAddSelection}
                    className={styles.additionalcountadd}
                  />
                ) : null} */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className={styles.modalfooter}>
            <Button
              id="postSubmitButton"
              onClick={postAskHandler}
              disabled={!isEnabled}
              className={`${styles.PostSubmit} ${
                isPostOption === "ASK"
                  ? isEnabled
                    ? styles.askpost
                    : styles.askButtonDisable
                  : isPostOption === "SAY"
                  ? isEnabled
                    ? styles.saypost
                    : styles.sayButtonDisable
                  : ""
              }`}
            >
              POST
            </Button>

            {/* <Button onClick={props.onHide}>Close</Button> */}
            <div className={styles.aboutAskInfo}>
              <img
                src={aboutASK}
                alt="aboutask"
                className={styles.aboutAskInfoicon}
              />

              {isPostOption === "ASK" ? (
                <div className={styles.askAbout}>
                  <span>Why create ASK</span>
                  <p>
                    ASK something relevant and pressing to you or your
                    community. (EX: Can I take AP college board exam without
                    taking an AP class at my school?)
                  </p>
                  <span>ASK your Community</span>
                  <p>
                    Review all matching results to see if you can find your best
                    answer, if not move it to open pool for your peers to
                    answer.
                  </p>
                </div>
              ) : isPostOption === "SAY" ? (
                <div className={styles.askAbout}>
                  <span>Why create SAY</span>
                  <p>
                    Share your knowledge or express your thoughts to benefit
                    your community. (EX: Ms. Peck teaches Calculus great. Her
                    tests and grading are tough, but she offers one make up
                    test.)
                  </p>
                  <span>Build your community knowledge</span>
                  <p>
                    Your SAY could be the best answer yours peers may be looking
                    for, so keep sharing your knowledge and thoughts.
                  </p>
                </div>
              ) : null}
            </div>
            {isPostOption !== "ASK" && isPostOption !== "SAY" && (
              <div className={styles.postSelectionWarning}>
                <p>
                  You must select ASK or SAY in order to start writing your post
                </p>
              </div>
            )}
          </Modal.Footer>
        </div>
      </Modal>

      {/* <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        centered // This prop will center the modal vertically and horizontally
        className={styles.restrictedModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Thank You</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your SAY will publish as soon as the Community Moderator approves it.
        </Modal.Body>
      </Modal> */}

      {/* <AsksModal
        loading={loading}
        childSaysLoading={childSaysLoading}
        show={SaysShow}
        onHide={() => setSaysShow(false)}
        isPostOption={isPostOption}
        setSaysShow={setSaysShow}
        answersToYourAsk={answersToYourAsk}
        asksToBeAnswered={asksToBeAnswered}
        askYourCommunity={askYourCommunity}
        fetchFeedData={fetchFeedData}
        postChildSayData={postChildSayData}
        deleteMessage={deleteMessage}
        filterPosts={filterPosts}
        childSaysData={childSaysData}
        fetchChildSaysData={fetchChildSaysData}
        fetchFollowData={fetchFollowData}
        fetchVoteUpStatus={fetchVoteUpStatus}
      /> */}
    </React.Fragment>
  );
}

function Feed(props) {
  const {
    loading,
    childSaysLoading,
    answersToYourAsk,
    asksToBeAnswered,
    fetchSaysDataForAsks,
    fetchAsksToAnswer,
    askYourCommunity,
    fetchFeedData,
    postChildSayData,
    deleteMessage,
    filterPosts,
    childSaysData,
    fetchChildSaysData,
    fetchFollowData,
    fetchVoteUpStatus,
    clearAsksAndSays,
    showData,
    deleteAnswersToAsksDialogPost,
    deleteAsksToBeAnsweredDialogPost,
    fetchContributors
  } = props;
  const userDetails = useSelector((state) => state.SignInReducer.userDetails);
  const isNewUser = useSelector((state) => state.SignInReducer.isNewUser);
  const dispatch = useDispatch();
  console.log("userDetails", userDetails);
  // const Tag = useSelector((state) => state.TagDisplayReducer.displayTag);
  // const [open, setOpen] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [invitesShow, setInvitesShow] = React.useState(false);
  const [invite, setInvite] = useState(null);
  const [previewModal, setPreviewModal] = useState(false);
  const [isPostOption, setPostOption] = useState("");
  const [SaysShow, setSaysShow] = useState(false);
  const [saysPublishRestrictionOpen, setSaysPublishRestriction] =
    useState(false);

  useEffect(() => {
    if (isNewUser) {
      onInvitesSelction();
    }
  }, [isNewUser]);

  const onInvitesSelction = (event) => {
    event && event?.stopPropagation();
    setInvitesShow(true);
    setInvite(true);

    // Check if previewModal is currently open, then close it
    if (previewModal) {
      setPreviewModal(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchloginUser());
    }, 1000);
  }, []);

  const onHideModalShow = () => {
    setModalShow(false);
    clearAsksAndSays && clearAsksAndSays();
  };

  const showPreview = (e) => {
    e.stopPropagation(); // Stop the event from reaching the container
    setPreviewModal(!previewModal);
  };

  const onHideAsksModal = () => {
    setSaysShow(false);
    clearAsksAndSays && clearAsksAndSays();
  };

  const setModalVisibility = (parentModal, childModal, saysPublishRest) => {
    setModalShow(parentModal || false);
    setSaysShow(childModal || false);
    setSaysPublishRestriction(saysPublishRest || false);
    if (!parentModal && !childModal) {
      clearAsksAndSays && clearAsksAndSays();
    }
  };
  const imageServerURL = process.env.REACT_APP_SKOPIC_IMAGE_OTHER;
  return (
    <React.Fragment>
      {userDetails && Object.keys(userDetails).length !== 0 ? (
        <>
          <div
            className={styles.feed}
            // onClick={(e) => showPreview(e)}
          >
            <div>
              <img
                src={`${imageServerURL}${userDetails.userData.tenantLogo}`}
                alt="Tenant Logo"
                className={styles.backgroundImage}
                onClick={(e) => showPreview(e)}
              />
              <span className={styles.crown}>
                {userDetails && Object.keys(userDetails).length !== 0 ? (
                  userDetails.userData.moderatorStatus === "1" ||
                  userDetails.userData.isAdmin === "1" ? (
                    <img
                      src={`${
                        userDetails.userData.isCurrentCommunityPm === "1"
                          ? PrimaryModeratorCrown
                          : SecondaryModeratorCrown
                      }`}
                    />
                  ) : null
                ) : null}
              </span>
            </div>

            <div className={styles.container}>
              {userDetails.userData.tenantName ? (
                <div
                  className={styles.moderator}
                  onClick={(e) => showPreview(e)}
                >
                  <p onClick={(e) => showPreview(e)}>
                    {userDetails.userData.tenantName}
                  </p>
                  <MdOutlineArrowForwardIos
                    className={styles.comunityProfileArrowIcon}
                    onClick={(e) => showPreview(e)}
                  />
                </div>
              ) : null}

              <div className={styles.prospectimagesdiv}>
                <a className={styles.invitebutton} onClick={onInvitesSelction}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.878"
                    height="15.779"
                    viewBox="0 0 15.878 15.779"
                  >
                    <g
                      id="Group_1924"
                      data-name="Group 1924"
                      transform="translate(-6714.617 -3413.368)"
                    >
                      <path
                        id="Path_435"
                        data-name="Path 435"
                        d="M34.165,26.211a3.831,3.831,0,1,0-5.059,0A6.11,6.11,0,0,0,25.5,31.795c0,.726.376,1.728,2.179,2.379a11.545,11.545,0,0,0,3.957.6c4.533,0,6.135-1.6,6.135-2.98A6.11,6.11,0,0,0,34.165,26.211ZM31.635,21a2.329,2.329,0,1,1-2.329,2.329A2.329,2.329,0,0,1,31.635,21Zm0,12.27c-2.73,0-4.633-.776-4.633-1.477a4.633,4.633,0,0,1,9.266,0C36.268,32.5,34.365,33.272,31.635,33.272Z"
                        transform="translate(6689.117 3394.369)"
                        fill="#373334"
                      />
                      <path
                        id="Path_436"
                        data-name="Path 436"
                        d="M72.931,19.277H71.9V18.25a.751.751,0,0,0-.751-.751.726.726,0,0,0-.751.751v1.027H69.35a.751.751,0,0,0-.751.751.726.726,0,0,0,.751.751H70.4v1.052a.726.726,0,0,0,.751.751.751.751,0,0,0,.751-.751V20.779h1.027a.726.726,0,0,0,.751-.751A.751.751,0,0,0,72.931,19.277Z"
                        transform="translate(6656.813 3395.87)"
                        fill="#373334"
                      />
                    </g>
                  </svg>
                  Invite
                </a>
              </div>
            </div>
          </div>
          {showData === "singleTrendingTag" ? (
            <div>{""}</div>
          ) : (
            <div className={styles.filterinput}>
              <div className={styles.post}>
                <input
                  className={styles.postinput}
                  placeholder="      ASK or SAY something..."
                  readOnly
                  onClick={() => setModalShow(true)}
                />
                {/* <button className={styles.postbutton} onClick={handleClickOpen}>Post</button> */}

                <AskSayPostDataModal
                  loading={loading}
                  childSaysLoading={childSaysLoading}
                  show={modalShow}
                  onHide={onHideModalShow}
                  modalShow={modalShow}
                  // setModalShow={onSetModalShow}
                  setModalVisibility={setModalVisibility}
                  answersToYourAsk={answersToYourAsk}
                  asksToBeAnswered={asksToBeAnswered}
                  fetchSaysDataForAsks={fetchSaysDataForAsks}
                  fetchAsksToAnswer={fetchAsksToAnswer}
                  askYourCommunity={askYourCommunity}
                  fetchFeedData={fetchFeedData}
                  postChildSayData={postChildSayData}
                  deleteMessage={deleteMessage}
                  filterPosts={filterPosts}
                  childSaysData={childSaysData}
                  fetchChildSaysData={fetchChildSaysData}
                  fetchFollowData={fetchFollowData}
                  fetchVoteUpStatus={fetchVoteUpStatus}
                  isPostOption={isPostOption}
                  setPostOption={setPostOption}
                  fetchContributors={fetchContributors}
                  // setSaysShow={onSetSaysShow}
                />

                <AsksModal
                  loading={loading}
                  childSaysLoading={childSaysLoading}
                  show={SaysShow}
                  onHide={onHideAsksModal}
                  isPostOption={isPostOption}
                  setPostOption={setPostOption}
                  setSaysShow={(value) => setModalVisibility(false, value)}
                  answersToYourAsk={answersToYourAsk}
                  asksToBeAnswered={asksToBeAnswered}
                  askYourCommunity={askYourCommunity}
                  fetchFeedData={fetchFeedData}
                  postChildSayData={postChildSayData}
                  deleteMessage={deleteMessage}
                  filterPosts={filterPosts}
                  childSaysData={childSaysData}
                  fetchChildSaysData={fetchChildSaysData}
                  fetchFollowData={fetchFollowData}
                  fetchVoteUpStatus={fetchVoteUpStatus}
                  deleteAnswersToAsksDialogPost={deleteAnswersToAsksDialogPost}
                  deleteAsksToBeAnsweredDialogPost={
                    deleteAsksToBeAnsweredDialogPost
                  }
                  fetchContributors={fetchContributors}
                />

                <SaysPublishRestriction
                  open={saysPublishRestrictionOpen}
                  setOpen={setSaysPublishRestriction}
                />

                <svg
                  id="noun_compose_72548"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.426"
                  height="15.435"
                  viewBox="0 0 15.426 15.435"
                >
                  <path
                    id="Path_432"
                    data-name="Path 432"
                    d="M16.3,3.5,15.249,4.553a.315.315,0,0,1-.448,0l-1.78-1.78a.316.316,0,0,1,0-.45l1.051-1.052a.713.713,0,0,1,1.009,0L16.3,2.492A.716.716,0,0,1,16.3,3.5ZM14.42,5.3,8.6,11.122a.272.272,0,0,1-.448.085l-1.78-1.78a.318.318,0,0,1,0-.45l5.82-5.824a.318.318,0,0,1,.448,0l1.78,1.781C14.545,5.058,14.545,5.26,14.42,5.3ZM7.612,11.944l-2.525.865-.047.016a.239.239,0,0,1-.222-.064.235.235,0,0,1-.062-.222l.015-.046.865-2.527.016-.047A.229.229,0,0,1,5.7,9.851a.239.239,0,0,1,.337,0l1.691,1.692a.239.239,0,0,1,0,.336.21.21,0,0,1-.068.048ZM9.328,3.731H2.679V14.9h11.17V8.253a.8.8,0,0,1,1.6,0V15.7a.8.8,0,0,1-.8.8H1.881a.8.8,0,0,1-.8-.8V2.934a.8.8,0,0,1,.8-.8H9.328a.8.8,0,1,1,0,1.6Z"
                    transform="translate(-1.083 -1.063)"
                    fill="#707070"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          )}
          <PhotoPreview
            open={previewModal}
            handleClose={() => setPreviewModal(false)}
            tenantId={userDetails.userData.tenantId}
            communityImage={userDetails.userData.tenantLogo}
            communityDescription={userDetails.userData.tenantDescription}
          />
        </>
      ) : null}
      {invitesShow && (
        <Invitation
          show={invitesShow}
          onHide={() => setInvitesShow(false)}
          invite={invite}
        />
      )}
    </React.Fragment>
  );
}

export default Feed;

const SaysPublishRestriction = ({ open, setOpen }) => {
  return (
    <Modal
      size="lg"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="example-modal-sizes-title-lg"
      centered // This prop will center the modal vertically and horizontally
      className={styles.restrictedModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Thank You</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Your SAY will publish as soon as the Community Moderator approves it.
      </Modal.Body>
    </Modal>
  );
};

export { AskSayPostDataModal, SaysPublishRestriction };
