// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebarOption{
    align-items: center;
    cursor: pointer;
    margin-bottom: 0px !important;
}

.sidebarOption>.sidebarbuttons:hover{
background-color:white;
border-radius: 30px;
color: #50b7f5;
transition: color 100ms ease-out;
border: none;
}


.sidebarOption>.sidebarbuttons{
border-radius: 15px;
font-weight: 500;
font-size: 1.0625rem;
margin-right: 20px;
border: none;
outline: none;
}

.sidebarOption--active>.sidebarbuttons{
color: #4795DE;
font-weight: bold;
border: none;
}
`, "",{"version":3,"sources":["webpack://./src/Assets/css/settings/sidebaroptions.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;IACf,6BAA6B;AACjC;;AAEA;AACA,sBAAsB;AACtB,mBAAmB;AACnB,cAAc;AACd,gCAAgC;AAChC,YAAY;AACZ;;;AAGA;AACA,mBAAmB;AACnB,gBAAgB;AAChB,oBAAoB;AACpB,kBAAkB;AAClB,YAAY;AACZ,aAAa;AACb;;AAEA;AACA,cAAc;AACd,iBAAiB;AACjB,YAAY;AACZ","sourcesContent":[".sidebarOption{\n    align-items: center;\n    cursor: pointer;\n    margin-bottom: 0px !important;\n}\n\n.sidebarOption>.sidebarbuttons:hover{\nbackground-color:white;\nborder-radius: 30px;\ncolor: #50b7f5;\ntransition: color 100ms ease-out;\nborder: none;\n}\n\n\n.sidebarOption>.sidebarbuttons{\nborder-radius: 15px;\nfont-weight: 500;\nfont-size: 1.0625rem;\nmargin-right: 20px;\nborder: none;\noutline: none;\n}\n\n.sidebarOption--active>.sidebarbuttons{\ncolor: #4795DE;\nfont-weight: bold;\nborder: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
