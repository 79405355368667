import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import styles from "src/Assets/css/home/home.module.css";
import LeftSideBar from "../components/LeftSideBar";
import SearchResults from "../components/SearchResults";
import TrendingTags from "../components/TrendingTags";
import TrendingAsks from "../components/TrendingAsks";
import SingleTrendingTag from "../components/SingleTrendingTag"
import Content from "../components/Content";
import RightSideBar from "../components/RightSideBar";
import RestrictionMsgModal from "../../../Components/RestrictionMsgModal";
import Page, { PageEnum } from "src/layout/Page";
import GmailContactsModal from "../components/Invitations/GmailContactsModal";
import SearchTagModal from "../components/SearchedTagModal";

function ViewRenderer(props) {
  const {
    loading,
    childSaysLoading,
    feedData,
    userDetails,
    contributors,
    trendingAsks,
    trendingTags,
    allTags,
    allTagData,
    allAsks,
    filterType,
    showData,
    selectedCommunity,
    searchData,
    followData,
    selectedTagDetails,
    showModalForUpdate,
    modalTypeForUpdate,
    childSaysData,
    answersToYourAsk,
    asksToBeAnswered,
    showHideNotifications,
    privateTagsData,
    filterPosts,
    fetchSelectedTagDetails,
    setShowData,
    goBack,
    onUpdateSelection,
    hideUpdateModal,
    fetchSearchData,
    onCommunityChange,
    fetchChildSaysData,
    postChildSayData,
    fetchFollowData,
    fetchVoteUpStatus,
    fetchSaysDataForAsks,
    fetchAsksToAnswer,
    askYourCommunity,
    fetchFeedData,
    fetchPrivateTagsData,
    deleteMessage,
    refreshFeedData,
    feedPageNumber,
    fetchPageFeed,
    value,
    changeValue,
    clearAsksAndSays,
    hasRestrictionSays,
    resetHasRestrictionSays,
    clearSearchHeader,
    deleteAnswersToAsksDialogPost,
    deleteAsksToBeAnsweredDialogPost,
    fetchContributors,
    landApiData
  } = props;
  const [showGmailContactsModal, setShowGmailContactsModal] = useState(false);
  const [gAuthCode, setGAuthCode] = useState('');
  const [showSearchedTagModal, setShowSearchedTagModal] = useState(false);
  const [hashTag, setHashTag] = useState("");
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const authCode = urlParams.get('code');
    const state = urlParams.get('state');

    if (authCode) {
      // Handle the auth code, e.g., send it to your server to get tokens
      console.log('Authorization Code:', authCode);
      setGAuthCode(authCode);
      setShowGmailContactsModal(true);
    } else {
      console.error('Authentication failed');
    }
  }, [location]);

  const onHashTagClick = (hashTag) => {
    setHashTag(hashTag);
    setShowSearchedTagModal(true);
  };

  const onCloseSearchTagModal = () => {
    setHashTag("");
    setShowSearchedTagModal(false);
  };

  return (
    <Page
      fetchSearchData={fetchSearchData}
      filterPosts={filterPosts}
      clearSearchHeader={clearSearchHeader}
      goBack={goBack}
      page={PageEnum.Home}
    >
      <div className={`container ${styles.homeContainer}`}>
        <div className={`row ${styles.homeRow}`}>
          <div className={`col-lg-3 ${styles.leftColumn}`}>
            <LeftSideBar
              onCommunityChange={onCommunityChange}
              selectedCommunity={selectedCommunity}
              setShowData={setShowData}
            />
          </div>
          {showData === "search" ? (
            <div className={`col-lg-6 ${styles.midColumn}`}>
              <SearchResults
                loading={loading}
                childSaysLoading={childSaysLoading}
                searchData={searchData}
                goBack={goBack}
                childSaysData={childSaysData}
                fetchChildSaysData={fetchChildSaysData}
                postChildSayData={postChildSayData}
                fetchFollowData={fetchFollowData}
                fetchVoteUpStatus={fetchVoteUpStatus}
                fetchFeedData={fetchFeedData}
                deleteMessage={deleteMessage}
                refreshFeedData={refreshFeedData}
                fetchContributors={fetchContributors}
                onHashTagClick={onHashTagClick}
              />
            </div>
          ) : showData === "tag" ? (
            <div className={`col-lg-6 ${styles.midColumn}`}>
              <TrendingTags
                loading={loading}
                childSaysLoading={childSaysLoading}
                postChildSayData={postChildSayData}
                fetchFeedData={fetchFeedData}
                allTags={allTags}
                allTagData={allTagData}
                goBack={goBack}
                childSaysData={childSaysData}
                fetchChildSaysData={fetchChildSaysData}
                deleteMessage={deleteMessage}
                fetchFollowData={fetchFollowData}
                fetchVoteUpStatus={fetchVoteUpStatus}
                refreshFeedData={refreshFeedData}
                showData={showData}
                hasRestrictionSays={hasRestrictionSays}
                fetchContributors={fetchContributors}
                onHashTagClick={onHashTagClick}
              />
            </div>
          ) : showData === "ask" ? (
            <div className={`col-lg-6 ${styles.midColumn}`}>
              <TrendingAsks
                childSaysLoading={childSaysLoading}
                postChildSayData={postChildSayData}
                fetchFeedData={fetchFeedData}
                loading={loading}
                allAsks={allAsks}
                goBack={goBack}
                childSaysData={childSaysData}
                fetchChildSaysData={fetchChildSaysData}
                deleteMessage={deleteMessage}
                fetchFollowData={fetchFollowData}
                fetchVoteUpStatus={fetchVoteUpStatus}
                refreshFeedData={refreshFeedData}
                showData={showData}
                fetchContributors={fetchContributors}
                onHashTagClick={onHashTagClick}
              />
            </div>
          ) : showData === "singleTrendingTag" ? (
            <div className={`col-lg-6 ${styles.midColumn}`}>
              <SingleTrendingTag
                loading={loading}
                childSaysLoading={childSaysLoading}
                postChildSayData={postChildSayData}
                fetchFeedData={fetchFeedData}
                allTags={selectedTagDetails}
                goBack={goBack}
                childSaysData={childSaysData}
                fetchChildSaysData={fetchChildSaysData}
                deleteMessage={deleteMessage}
                fetchFollowData={fetchFollowData}
                fetchVoteUpStatus={fetchVoteUpStatus}
                refreshFeedData={refreshFeedData}
                showData={showData}
                hasRestrictionSays={hasRestrictionSays}
                fetchContributors={fetchContributors}
                onHashTagClick={onHashTagClick}
              />
            </div>
          ) : (
            <div className={`col-lg-6 ${styles.midColumn}`}>
              <Content
                loading={loading}
                childSaysLoading={childSaysLoading}
                feedData={feedData}
                filterPosts={filterPosts}
                filterType={filterType}
                followData={followData}
                privateTagsData={privateTagsData}
                answersToYourAsk={answersToYourAsk}
                asksToBeAnswered={asksToBeAnswered}
                childSaysData={childSaysData}
                fetchSaysDataForAsks={fetchSaysDataForAsks}
                fetchAsksToAnswer={fetchAsksToAnswer}
                askYourCommunity={askYourCommunity}
                fetchFeedData={fetchFeedData}
                fetchChildSaysData={fetchChildSaysData}
                postChildSayData={postChildSayData}
                fetchFollowData={fetchFollowData}
                fetchVoteUpStatus={fetchVoteUpStatus}
                fetchPrivateTagsData={fetchPrivateTagsData}
                deleteMessage={deleteMessage}
                refreshFeedData={refreshFeedData}
                feedPageNumber={feedPageNumber}
                fetchPageFeed={fetchPageFeed}
                value={value}
                changeValue={changeValue}
                clearAsksAndSays={clearAsksAndSays}
                deleteAnswersToAsksDialogPost={deleteAnswersToAsksDialogPost}
                deleteAsksToBeAnsweredDialogPost={deleteAsksToBeAnsweredDialogPost}
                fetchContributors={fetchContributors}
                onHashTagClick={onHashTagClick}
                landApiData={landApiData}
                userDetails={userDetails}
              />
            </div>
          )}
          <div className={`col-lg-3 ${styles.rightColumn}`}>
            <RightSideBar
              contributorsData={contributors}
              trendingTags={trendingTags}
              trendingAsks={trendingAsks}
              setShowData={setShowData}
              showModal={showModalForUpdate}
              modalType={modalTypeForUpdate}
              fetchSelectedTagDetails={fetchSelectedTagDetails}
              onUpdateSelection={onUpdateSelection}
              hideUpdateModal={hideUpdateModal}
              fetchFeedData={fetchFeedData}
              filterPosts={filterPosts}
              showData={showData}
              fetchContributors={fetchContributors}
              pageType={"homePage"}
            />
          </div>
        </div>
      </div>

      <RestrictionMsgModal
        open={hasRestrictionSays}
        onClose={resetHasRestrictionSays}
        title="Thank you"
        message="Your SAY will publish as soon as the Community Moderator approves it."
      />

      {(showGmailContactsModal && gAuthCode) && (
        <GmailContactsModal
          open={showGmailContactsModal}
          handleClose={() => {
            setShowGmailContactsModal(false);
            setGAuthCode(null);
          }}
          authCode={gAuthCode}
        />
      )}

      {hashTag && showSearchedTagModal && (
        <SearchTagModal
          open={showSearchedTagModal}
          onClose={onCloseSearchTagModal}
          hashTag={hashTag}
        />
      )}
    </Page>
  );
}

export default ViewRenderer;
