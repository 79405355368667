import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import sampledialogcancel from "src/Assets/images/Add.png";
import deleteicon from "src/Assets/images/Delete.svg";
import API from "../../network";
import * as SignInActions from "src/store/actions/SignInActions/SignInAction";
import feedstyles from "src/Assets/css/home/feedpost.module.css";

function DeleteDialog(props) {
  // const deleteStatusData = useSelector((state) => state.followReducer.DeleteStatus)

  const {
    onClose,
    open,
    deletePost,
    deleteSayCount,
    deleteAnswersToAsksDialogPost,
    deleteAsksToBeAnsweredDialogPost,
    decreaseSayCount,
    fetchContributors
  } = props;
  const handleClose = () => {
    onClose(false);
  };
  const dispatch = useDispatch();
  const onDeletePost = (id) => {
    // if (document.getElementById(id).style.display = "block") {
    //     document.getElementById(id).style.display = "none"
    // }
    // else {
    //     document.getElementById(id).style.display = "block"

    // }
    // let messageid = `?messageID=${id}`
    // let deletePostId = `FeedDataDelete${id}`
    // dispatch(feedactions.fetchDeleteStatus(messageid))
    // API.deleteMessage(id, props.type);
    props.deleteMessage(id, props.type, props.Parent_ID);
    // props.setDeletePostId(deletePostId)
    // props.setDeletePost(true)
    // dispatch(feedactions.fetchFeedData('?startlimit=0'))
    onClose(false);
    deleteAnswersToAsksDialogPost && deleteAnswersToAsksDialogPost(id);
    deleteAsksToBeAnsweredDialogPost && deleteAsksToBeAnsweredDialogPost(id);
    decreaseSayCount && decreaseSayCount(id);
    // below functionality will not work here
    // deletePost(id)
    deleteSayCount && deleteSayCount(props.Parent_ID);
    setTimeout(() => {
      fetchContributors && fetchContributors();
      dispatch(SignInActions.fetchloginUser());
    }, 1000);
  };
  // console.log(deleteStatusData)

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      key={props.id}
    >
      <div className={feedstyles.materialdialog}>
        <div className={feedstyles.sampledialogcountandcancel}>
          <p>
            <b>Delete Confirmation</b>
          </p>
          <a onClick={handleClose}>
            <img src={sampledialogcancel} />
          </a>
        </div>
        <div>
          <p>
            Are you sure you want to delete this{" "}
            {props.type === "ChildSay" ? "Say" : props.type}?
          </p>
          <button
            onClick={() => onDeletePost(props.id)}
            className={feedstyles.dialogdeletebutton}
          >
            Delete
          </button>
        </div>
      </div>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function ReusuableDeleteDialogmodal(props) {
  const [open, setOpen] = useState(false);
  const [msgtype, setmsgtype] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
    if (props.type === "OpenASK") {
      // msgtype="Ask"
      setmsgtype("Ask");
    } else if (props.type === "OpenSAY") {
      // msgtype="Say"
      setmsgtype("Say");
    } else if (props.type === "impupdate") {
      // msgtype="Update"
      setmsgtype("Update");
    } else if (props.type === "hashTAG") {
      //    msgtype="Tag"
      setmsgtype("Tag");
    } else if (props.type === "SAY") {
      setmsgtype("ChildSay");
    } else {
      setmsgtype(props.type);
      // msgtype=props.type
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div key={props.id}>
      <a onClick={() => handleClickOpen()}>
        <img src={deleteicon} style={{ marginRight: 8 }} />
        <span style={{ fontSize: 14 }}>{props?.label ? props?.label : 'Delete'}</span>
      </a>
      <DeleteDialog
        open={open}
        onClose={handleClose}
        id={props.id}
        Parent_ID={props.Parent_ID}
        type={msgtype}
        deleteMessage={props.deleteMessage}
        deletePost={props.deletePost}
        deleteSayCount={props.deleteSayCount}
        deleteAnswersToAsksDialogPost={props.deleteAnswersToAsksDialogPost}
        deleteAsksToBeAnsweredDialogPost={
          props.deleteAsksToBeAnsweredDialogPost
        }
        decreaseSayCount={props.decreaseSayCount}
        fetchContributors={props.fetchContributors}
      // setDeletePost={props.setDeletePost}
      // setDeletePostId={props.setDeletePostId}
      />
    </div>
  );
}
